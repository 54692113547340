import React, { FC } from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import DepositForm from './form/DepositForm'
import Payment from '../payment/Payment'
import DepositCorrectionForm from './correction/DepositCorrectionForm'
import DivisionDepositForm from './division/DivisionDepositForm'
import { PAYMENT_URL_DEPOSIT } from '../../constants/PaymentConstants'

const DepositRoutes: FC<RouteComponentProps> = ({
  match
}) => (
  <Switch>
    <Route path={`${match.url}/nouveau`} render={() => <DepositForm isNew />} />
    <Route path={`${match.url}/:id/edition`} component={DepositForm} />
    <Route
      path={`${match.url}/:id/demande-correction`}
      render={(props) => <DepositCorrectionForm idTransaction={props.match.params.id} />}
    />
    <Route
      path={`${match.url}/:id/paiement/:state`}
      render={(props) => <Payment id={props.match.params.id} state={props.match.params.state} matchUrl={PAYMENT_URL_DEPOSIT} />}
    />
    <Route
      path={`${match.url}/:idTransaction/proposition-division/:idNotification`}
      render={(props) => <DivisionDepositForm idTransaction={props.match.params.idTransaction} idNotification={props.match.params.idNotification} />}
    />
  </Switch>
)

export default DepositRoutes
