import React, { FC, MouseEvent } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

interface HeaderNavItemProps extends RouteComponentProps {
  url?: string,
  onClick?: (event: MouseEvent) => void,
  label?: string,
  img?: string,
  className?: string,
  noRedirection?: boolean,
  infoHelp?: any,
  targetBlank?: boolean
}

const HeaderNavItem: FC<HeaderNavItemProps> = ({
  url = '',
  onClick,
  label,
  img,
  location,
  className = '',
  noRedirection,
  infoHelp,
  targetBlank
}) => (
  <li className={`nav-item ${className} ${url && location.pathname.startsWith(url) ? 'active' : ''}`}>
    <a
      className='nav-link'
      href={!noRedirection ? url : '#'}
      onClick={onClick}
      title={infoHelp}
      target={targetBlank ? '_blank' : ''}
      rel={targetBlank ? 'noopener noreferrer' : ''}
    >
      {img && <span><img src={img} alt={img} /></span>}
      {label && <span><FormattedMessage id={label} /></span>}
    </a>
  </li>
)

export default withRouter(HeaderNavItem)
