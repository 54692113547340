const Message = {

  logo_alt: 'Logo Inpi',
  module_name: 'Front-office',
  module_service: 'Dessins et Modèles',

  help_hover: 'Aide',
  contact_hover: 'Envoyer un message à l\'INPI',
  user_hover: 'Mon compte :',
  logout_hover: 'Déconnexion',

  required_field: 'Champ obligatoire',
  required_checkbox_field: 'Cette case doit être cochée',
  placeholder_select: 'Sélectionner ...',
  autocomplete_no_resultat: 'Aucun résultat disponible',
  error_field_date: 'Champs date sous la forme JJ/MM/AAAA',
  error_email_format: 'L\'email renseigné n\'est pas au bon format',
  error_phone_format: 'Numéro de téléphone invalide',
  common_yes: 'Oui',
  common_no: 'Non',
  common_download: 'Télécharger',
  or: 'ou',
  and_or: 'et/ou',

  // Login
  login_username_label: 'Email',
  login_username_placeholder: 'Email',
  login_password_label: 'Mot de passe',
  login_password_placeholder: 'Mot de passe',
  login_connexion_button_label: 'Connexion',
  login_invalid_couple: 'Le couple identifiant / mot de passe est inconnu',
  login_inaccessible_user: 'Impossible de récupérer les informations de l\'utilisateurs',

  // Buttons
  button_cancel: 'Annuler',
  button_save: 'Enregistrer',
  button_continue: 'Continuer',
  button_confirm: 'Confirmer',
  button_next_step: 'Étape suivante',
  button_previous_step: 'Étape précédente',
  button_return: 'Retour',
  button_validate: 'Valider',
  button_validate_model: 'Valider et enregistrer le modèle',
  button_change: 'Modifier',
  button_ok: 'Ok',

  breadcrumb_home: 'Accueil',
  breadcrumb_contact: 'Contact',

  // Procédure
  procedure_depot: 'Dépôt',
  procedure_inscription: 'Inscription',
  procedure_prorogation: 'Prorogation',
  procedure_appeal: 'Relevé de déchéance',

  // Demande sur les comptes ETS
  ets_account_request_procedure_select: 'Sélectionner un type de procédure',
  ets_account_request_procedure_label: 'Type de procédure',
  ets_account_request_contributor_type_label: 'J\'agis en qualité de',
  ets_account_request_procedure_numNat: 'N° national / n° de publication',
  ets_account_request_procedure_inscription_numNat: 'N° d\'ordre / N° d\'inscription',
  ets_account_request_procedure_prorogation_numNat: 'N° de prorogation',
  ets_account_request_procedure_appeal_numNat: 'N° de relevé de déchéance',
  ets_account_request_inscription_number: 'Numéro CPI',
  ets_account_request_power_file: 'Bon pour pouvoir',
  ets_account_request_proof_file: 'Joindre un fichier',
  ets_account_request_is_recipient: 'Je suis également le destinataire des correspondances afférentes à l’enregistrement ou au maintien en vigueur du titre concerné',
  ets_account_request_manageable_quality_agent: 'Mandataire',
  ets_account_request_manageable_quality_owner: 'Titulaire',
  ets_account_request_success_request: 'Votre demande de rattachement a bien été prise en compte.',
  ets_account_request_new_agent: 'Je suis nouveau mandataire',
  ets_account_request_update_agent_infos: 'Je suis mandataire et mes informations ont changé',
  ets_account_request_remove_agent: 'Je ne suis plus mandataire',
  ets_account_request_attach_pm: 'Je modifie les dossiers attachés à une personne morale',
  ets_account_request_ask_for_access: 'Demander ou modifier un accès',
  ets_account_request_credentials: 'J\'ai reçu un code d\'accès',
  ets_account_request_credentials_code: 'Code',
  ets_account_request_credentials_password: 'Mot de passe',
  ets_account_request_pm_current_account: 'Compte actuel',
  ets_account_request_pm_new_account: 'Assigner au compte',
  ets_account_request_pm_no_account: 'A noter : une première connexion au portail Dessins & Modèles est nécessaire pour apparaître dans la liste.',
  ets_account_request_pm_picker: 'Attacher les dossiers à une autre personne',
  ets_account_request_pm_picker_button: 'Assigner la sélection',
  ets_record_holders: 'Titre appartenant à ',

  // header pricipal
  header_label_depot: 'Dépôt en ligne',
  header_label_proroger: 'Proroger un dépôt',
  header_label_inscription: 'Demander une inscription',

  // Recherche
  header_search_placeholder: 'Rechercher dans mes dossiers',

  // Footer
  footer_personal_datas: 'Données personnelles',
  footer_cookies: 'Cookies',
  footer_legal_notice: 'Mentions légales',
  footer_cgu: 'CGU',
  footer_cpu: 'CPU',
  footer_accessibility: 'Accessibilité',
  footer_site_map: 'Plan du site',
  footer_contact_us: 'Contactez-nous',

  // Home
  home_title: 'Bienvenue sur le portail des Dessins & Modèles',
  home_description: 'Déposez, prorogez ou inscrivez vos dessins et modèles et suivez l\'avancement de vos démarches',
  home_button_inscription: 'Inscription',
  home_button_disclaimer: 'Renonciation',
  home_button_prorogation: 'Prorogation',
  home_button_appeal: 'Relevé de déchéance',
  home_button_official_documents: 'Demande de Documents Officiels',
  home_button_depot: 'Dépôt',
  rgpd_title: 'Données personnelles',
  ets_account_request: 'Demande de rattachement',

  // Dashboard
  dashboard_projets_sauvegardes: 'Projets et sauvegardes',
  dashboard_projets_sauvegardes_subtitle: 'Seules les demandes mises à jour dans les {draftDisplayPeriodValue} derniers jours sont affichées.',
  dashboard_demande_en_cours: 'Demandes en cours d\'examen',
  dashboard_repondre_notifications: 'Notifications en attente',
  dashboard_declarations_publiee: 'Demandes enregistrées & Déclarations publiées',
  dashboard_demandes_ajournees: 'Demandes ajournées & simplifiées en attente',
  dashboard_demandes_dechues: 'Demandes déchues',
  dashboard_demandes_rejetees: 'Demandes rejetées ou irrecevables',
  dashboard_demandes_retirees: 'Demandes retirées',
  dashboard_demandes_prorogeables: 'Demandes prorogeables',
  dashboard_titres: 'Titres',
  dashboard_documents_fournis: 'Documents officiels fournis par l\'INPI',

  // Contact
  contact_firstname_label: 'Prénom',
  contact_lastname_label: 'Nom',
  contact_phone_number_label: 'N° de téléphone',
  contact_email_label: 'Email',
  contact_call_back_tab: 'Être rappelé',
  contact_written_request_tab: 'Envoyer un message',
  contact_object_label: 'Objet',
  contact_message_label: 'Message',
  contact_title: 'Envoyer un message',
  contact_send_button: 'Envoyer à l\'INPI',
  contact_choose_object: 'Veuillez sélectionner votre type de demande',
  contact_success_message: 'Votre demande a bien été envoyée.',

  // Type de dépôt
  deposit_type_depot_classique: 'Dépôt classique',
  deposit_type_depot_ajourne: 'Dépôt ajourné',
  deposit_type_depot_simplifie: 'Dépôt simplifié',

  // Listes de demandes
  placeholder_file_search: 'Rechercher un dossier',
  request_no_result: 'Aucune demande',
  placeholder_procedure_type_option: 'Type de procédure',
  placeholder_subtype_option: 'Type',
  request_creation_time: 'Date de création',
  request_updated_time: 'Date de modification',
  request_validity_end_date_time: 'Date de fin de validité',
  request_deposit_time: 'Date de dépôt',
  request_notif_deadline_time: 'Date d\'échéance',
  request_notif_sending_time: 'Date d\'envoi',
  request_publication_time: 'Date de publication',
  request_INPI_ref: 'Références INPI',
  request_depositor_name: 'Nom / Raison sociale',
  request_label_deposit_type: 'Type de dépôt',
  request_label_type: 'Type',
  request_last_prorogation_number: 'Dernier numéro de prorogation',
  request_last_registration_number: 'Dernier numéro d\'inscription',
  request_customer_ref: 'Référence client',
  request_national_number: 'Numéro national',
  request_international_number: 'Numéro d\'enregistrement',
  request_origin: 'Origine',
  request_origin_FR: 'FR',
  request_origin_INT: 'International',
  request_number: 'Numéro',
  request_modal_delete: 'Etes-vous sûr de vouloir supprimer ce dossier ?',
  request_create_prorogation: 'Proroger les titres sélectionnés',
  request_create_appeal: 'Demande de relevé de déchéance',
  request_create_official_document: 'Demande de Documents Officiels',
  request_create_inscription: 'Demande d\'inscription',
  request_siren_not_found: 'Le siren n\'existe pas',
  request_siren_invalid: 'Numéro de siren invalide',
  request_public_siren_invalid: 'Le SIREN ne correspond pas à celui d\'une Personne morale de droit public',
  request_date_time: 'Date de la demande',
  request_numnat: 'N° de la demande',
  request_applicant: 'Demandeur',
  request_status: 'Statut',
  button_delete: 'Suppression',

  request_10_rows: 'Afficher 10 résultats',
  request_25_rows: 'Afficher 25 résultats',
  request_50_rows: 'Afficher 50 résultats',

  request_title: 'Demande ',
  request_title_inscription: 'Inscription ',
  request_title_prorogation: 'Prorogation ',
  request_title_appeal: 'Relevé de déchéance ',
  request_title_do: 'Document officiel ',
  request_transform_tn_ta_inscription: 'Passer la demande en traitement accéléré',
  request_transform_tn_ta_inscription_modal_title: 'Passer en traitement accéléré',
  request_transform_tn_ta_inscription_modal_content: 'Êtes-vous sûr de vouloir passer la demande en traitement accéléré ?',
  request_go_edit: 'Reprendre le dossier',

  // Dépot dessins et modèle
  breadcrumb_new_depot: 'Nouveau dépôt',
  depot_step_list_title: 'Dépôt de dessins et modèles',

  // Dépôt formulaire : types de dépot
  select_depot_type_title: 'Type de dépôt',
  select_depot_type_description: 'Sélectionnez le type de dépôt de dessins et modèles',
  select_depot_type_classique_title: 'Dépôt classique',
  select_depot_type_classique_resume: 'Le dépôt classique est la procédure standard ouverte à tous et qui est soumise au respect de certaines règles. ' +
  'Elle comprend <b>la publication immédiate</b> de l\'ensemble des reproductions jointes au dépôt au Bulletin Officiel de la Propriété Industrielle.',
  select_depot_type_ajourne_title: 'Dépôt ajourné',
  select_depot_type_ajourne_resume: 'L\'ajournement de la publication vous permet de garder au secret votre dépôt tout en prenant le temps de réfléchir ' +
  'à l\'opportunité de rendre publiques vos créations. Dans ce cas, <b>la publication aura lieu 3 ans après le dépôt.</b> ' +
  'Toutefois, vous pouvez la demander à tout moment avant la fin de ce délai. Cette demande de publication portera sur l\'ensemble des reproductions jointes au dépôt.',
  select_depot_type_simplifie_title: 'Dépôt simplifié',
  select_depot_type_simplifie_resume: 'Le dépôt simplifié est une procédure allégée sur le plan formel, ' +
  'qui permet de payer certaines redevances ultérieurement et qui se caractérise par une publication effectuée uniquement sur demande du déposant. ' +
  'Cette procédure est réservée aux seuls créateurs et entreprises renouvelant fréquemment la forme ou le décor de leurs produits. ' +
  'Dans ce cas vous disposez de <b>30 mois au maximum</b> après le dépôt pour demander la publication. ' +
  'Cette demande peut porter sur l\'ensemble ou sur une partie des reproductions jointes au dépôt.',
  select_depot_type_required: 'Sélectionnez un type de dépôt',

  // Dépôt formulaire : Intervenant
  intervenant_form_title: 'Identification des intervenants',
  intervenant_form_description: 'Cette rubrique vous permet d\'identifier les différents intervenants de votre demande. ' +
  'A minima les onglets déposants, destinataire et signataire devront être remplis. <a>Cliquez ici pour en savoir plus sur les intervenants.</a>',
  intervenant_copier_donnees: 'Copier les données d\'un autre intervenant',
  intervenant_auto_deposant: 'Je suis le déposant',
  intervenant_onglet_deposant: 'Déposant',
  prorogation_onglet_depositor: 'Déclarant',
  intervenant_onglet_co_deposant: 'Co-déposants',
  intervenant_onglet_co_declarant: 'Co-déclarants',
  intervenant_onglet_destinataire: 'Destinataire',
  intervenant_onglet_mandataire: 'Mandataire',
  intervenant_onglet_createur: 'Créateurs',
  intervenant_onglet_signatory: 'Signataire',
  intervenant_auto_co_deposant: 'Je suis le co-déposant',
  intervenant_auto_co_declarant: 'Je suis le co-déclarant',
  intervenant_add_coDepositors: 'Ajouter un co-déposant',
  intervenant_add_applicants: 'Ajouter un demandeur',
  intervenant_add_otherApplicants: 'Ajouter une autre partie à l\'acte',
  intervenant_add_coDepositorsProrogation: 'Ajouter un co-déclarant',
  intervenant_save_co_deposant: 'Enregistrer le co-déposant',
  intervenant_save_co_declarant: 'Enregistrer le co-déposant',
  intervenant_coDepositors_edit: 'Co-déposant',
  intervenant_reinitialiser: 'Réinitialiser les champs',
  intervenant_personne_morale_publique: 'Personne morale de droit public',
  intervenant_personne_physique_formation: 'J\'agis au nom et pour le compte d\'une entité en cours de formation',
  intervenant_destinataire_titre: 'Destinataire des correspondances',
  intervenant_auto_destinataire: 'Je suis le destinataire des correspondances',
  intervenant_auto_mandataire: 'Je suis le mandataire',
  intervenant_add_mantataire: 'Ajouter un mandataire',
  intervenant_onglet_mandataire_subtitle: 'Il s\'agit de la personne chargée de représenter le ou les déposants devant l\'INPI. Il peut être obligatoire. Pour savoir dans quels cas et qui peut être manadataire, <a>consulter l\'aide en ligne.</a>',
  intervenant_remove_mandataire: 'Supprimer le mandataire',
  intervenant_error_bad_siren: 'Le siren ne doit pas être vide et doit contenir 9 chiffres',
  intervenant_error_api_error: 'Service momentanément indisponible',
  intervenant_nationalite: 'Nationalité',

  // Champs de formulaires
  field_creators_placeholder: 'Renseigner les créateurs ...',
  field_internal_reference_label: 'Référence client',
  field_creators_label: 'Si vous souhaitez indiquer un ou plusieurs créateur(s), saisissez leurs noms et prénoms dans cette zone',

  // Dépôt formulaire : Options
  options_depot_form_title: 'Options',

  options_depot_form_prorogation_title: 'Prorogation',
  options_depot_form_prorogation: 'Votre dépôt vous offre une protection pour une durée initiale de 5 ans qui peut être prolongée par tranche de 5 ans, ' +
  'jusqu\'à une période maximale de 25 ans. Cependant, il est possible de demander dès le dépôt une durée de protection de 10 ans. ' +
  'Si vous souhaitez obtenir une première période de protection de 10 ans, cochez la case.',
  options_depot_form_prorogation_checkbox_label: 'Le déposant a la faculté de requérir ici la première prorogation du dépôt',

  options_depot_form_extensions_title: 'Extensions de protections',
  options_depot_form_extensions_title_nb: 'Dépôt n°',
  options_depot_form_extensions_subtitle: 'Extension de la protection',
  options_depot_form_extensions_description: 'Le présent dépôt ne vous donne pas de protection pour la Polynésie française. Vous pouvez demander l’extension à ce territoire en cochant la case ci-dessous. Cette demande ne peut se faire qu’au moment du dépôt. Pour en savoir plus, <a>cliquez sur ce lien.</a>',
  options_prorogation_form_extensions_description: 'Pour conserver la protection de votre DM à la PF cocher la case ci-dessous. Attention : le maintien de la protection n’est possible que pour les DM qui y sont déjà protégés. En cas de rejet de votre demande, seul  l’office polynésien pourra vous rembourser. Pour en savoir plus, <a>suivez ce lien</a> ',

  options_depot_form_priorites_title: 'Priorités',
  options_depot_form_priorites_description: 'Si vous avez déjà déposé vos dessins ou modèles dans un pays membre de l\'Union de Paris ' +
  'ou de l\'Organisation mondiale du commerce (OMC), vous avez la possibilité, pendant une période de 6 mois à compter de la date du premier dépôt, ' +
  'd\'étendre votre protection en France, tout en bénéficiant de la date de ce dépôt.',
  options_depot_form_priorites: 'Priorité N°',
  options_depot_form_priorites_button_add: 'Ajouter une priorité',
  options_depot_form_priorites_button_validate: 'Ajouter la priorité',
  options_depot_form_priorites_button_change: 'Modifier la priorité',
  options_depot_form_priorites_button_delete: 'Supprimer la priorité',

  field_pays_organisme_label: 'Pays ou organisme',
  field_pays_organisme_placeholder: 'Sélectionner un pays',
  pays_1: 'Pays 1',
  pays_2: 'Pays 2',
  organisme_1: 'Organisme 1',
  field_numero_depot_origine_label: 'Numéro du dépôt d\'origine',
  field_date_depot_origine_label: 'Date du dépôt d\'origine',
  field_nom_deposant_origine_label: 'Nom du déposant d\'origine',
  field_document_priorites_label: 'Documents ou priorités',
  button_find_file: 'Choisir un fichier',

  // Dépôt formulaire : Modèles
  models_deposit_form_title: 'Modèles',
  models_deposit_form_subtitle: 'Afin d’éviter les irrégularités les plus fréquentes relatives à le représentation des modèles, <a>vous pouvez consulter la fiche d\'aide</a>.',

  model_deposit_form_number: 'Modèle N°',
  field_model_designation_label: 'Désignation du modèle',
  field_model_designation_info: 'La désignation du modèle a pour but de déterminer la catégorie de produit à laquelle le dessin ou modèle appartient. Elle est constituée par le nom usuel du produit. Utiliser les termes choisis dans la classification de Locarno (Référentiel) permet de valider plus rapidement votre dépôt.',
  field_model_classification_label: 'Classification',
  placeholder_locarno_search1: 'Rechercher ...',
  mass_import_modeles: 'Importez une archive concernant vos modèles',
  mass_import_modeles_description: 'Importez une archive zip contenant vos reproductions. Pour chaque reproduction un modèle sera créé.',
  mass_import_modeles_info: ' modèles vont être créés',
  mass_import_error_zero_designation: 'Les modèles doivent avoir une désignation',
  select_priority: 'Selectionnez',
  error_locarno_category: 'Toutes les désignations de modèle doivent appartenir à la même classe principale',
  button_locarno_popup: 'Parcourir le referentiel',
  field_number_priority_label: 'Numéro de priorité :',
  delete_all_reproductions: 'Supprimer toutes les reproductions de ce modèle',
  delete_all_reproductions_body: 'Êtes-vous sur de vouloir supprimer toutes les reproductions de ce modèle ?',
  error_number_reproductions: 'La demande doit contenir au minimum une reproduction et au maximum 100 reproductions',
  error_unvalidate_reproductions: 'Toutes les reproductions du modèle doivent être valides',
  error_global_models: 'Veuillez renseigner les informations de chaque reproduction',
  modification_discontinuation: 'Attention',
  modification_discontinuation_popin_content: 'Un modèle est en cours de modification, si vous continuez, les modifications seront perdues.',
  modal_repro_preview: 'Aperçu agrandi',

  // Dépôt formulaire : Reproductions
  reproductions_title: 'Intitulé de la reproduction',
  reproductions_subtitle: 'L’intitulé c’est-à-dire l’angle ou le type de vue (ex : vue de dessus, vue en perspective, vue de gauche, vue de détail, vue d’ensemble, vue explosée) est obligatoire. Pour un dessin, l’intitulé sera constitué par la désignation (ex : pour un logo, l’intitulé sera Logo).',
  request_number_reproduction: 'reproduction',
  reproductions_form_label: 'Intitulé de la reproduction',
  mass_reproductions_form_label: 'Intitulé des reproductions',
  reproductions_form_label_colors: 'Couleur de la reproduction',
  reproductions_form_radio_colors: 'Reproduction en couleur',
  reproductions_form_radio_no_colors: 'Reproduction en noir et blanc',
  reproductions_form_description: 'Description (facultative)',
  reproductions_form_description_info: 'Elle est facultative et ne doit comprendre que des éléments descriptifs (pas de mesures, de variantes de couleurs et/ou de matériaux). Elle peut préciser l’exclusion visuelle figurant sur la reproduction (ex : les éléments figurant en pointillés sur la reproduction sont exclus de la protection).',
  reproductions_form_next: 'Reproduction suivante ',

  button_add_new_model: 'Ajouter un nouveau modèle',

  error_number_models: 'Votre dossier doit contenir entre 1 et 100 modèles',

  // Dépôt formulaire : Récapitulatif
  overview_deposit_form_short_title: 'Récapitulatif',
  overview_deposit_pdf_title: 'recapitulatif',
  overview_deposit_form_title: 'Récapitulatif avant paiement',
  overview_deposit_overview_download: 'Télécharger le récapitulatif',

  overview_deposit_card_general_info_title: 'Informations générales',
  overview_deposit_type_deposit_title: 'Type de dépôt',
  overview_deposit_internal_reference_title: 'Référence interne',
  overview_deposit_date: 'Date de dépôt',

  overview_deposit_card_options_title: 'Options',
  overview_deposit_prorogation_title: 'Prorogation',
  overview_deposit_no_boolean: 'Non',
  overview_deposit_prorogation: 'Prorogation au dépôt',
  overview_deposit_protection_extension_title: 'Extensions',
  overview_prorogation_no_boolean: 'Non',
  overview_prorogation_EXTENDABLE_10_YEARS: 'Prorogé 10 ans',
  overview_prorogation_EXTENDABLE_15_YEARS: 'Prorogé 15 ans',
  overview_prorogation_EXTENDABLE_20_YEARS: 'Prorogé 20 ans',
  overview_prorogation_EXTENDABLE_25YEARS: 'Prorogé 25 ans',

  overview_deposit_card_intervenant_title: 'Intervenants',
  overview_deposit_card_intervenant_title_corrected: ' En attente de validation de correction',
  overview_deposit_depositor_title: 'Déposant',
  overview_deposit_codepositor_title: 'Co-déposants ',
  overview_deposit_receiver_title: 'Destinataire',
  overview_deposit_mandatary_title: 'Mandataire',
  overview_deposit_creators_title: 'Créateurs',
  overview_deposit_signatory_title: 'Signataire',
  overview_deposit_other_applicant_title: 'Autre(s) partie(s) à l\'acte',

  overview_deposit_card_models_title: 'Modèles et reproductions',
  overview_deposit_regularize_reproductions: 'Régulariser des reproductions',
  overview_deposit_regularize_reproductions_save: 'Vos reproductions ont bien été enregistrées et transmises à l\'équipe INPI.',
  overview_deposit_priority_title: 'Priorité : ',
  overview_deposit_card_models_btn_mode_select: 'Effectuer une action sur mes modèles',
  request_reproduction_toogle_open: 'Tout déplier',
  request_reproduction_toogle_close: 'Tout plier',
  overview_deposit_card_models_btn_mode_overview: 'Mode visualisation',

  overview_deposit_card_amount_title: 'Montant des redevances',
  overview_deposit_service_title: 'Prestation',
  overview_deposit_rate_title: 'Tarif',
  overview_deposit_quantity_title: 'Quantité',
  overview_deposit_total_title: 'Total',
  overview_deposit_action_title: 'Action',

  overview_deposit_service_design_model: 'Demande Dessins et Modèles',

  overview_deposit_amount_total: 'Total à payer',

  overview_deposit_form_checkbox_similar_information: 'Je confirme que les données ci-dessus sont conformes à mes attentes',

  overview_deposit_card_notification_title: 'Notifications',
  overview_deposit_notification_received: 'Reçu le',
  overview_deposit_notification_due_to: 'Echéance',
  overview_deposit_notification_amount_to_pay: 'Montant à payer',
  overview_deposit_notification_download: 'Télécharger l\'intégralité du courrier',
  overview_deposit_notification_comment_title: 'Commentaire (Facultatif)',
  overview_deposit_notification_agreement_title: 'Bon pour accord',
  overview_deposit_notification_confirm_response_title: 'Confirmer réception',
  overview_deposit_notification_payement_button: 'Accéder au paiement',
  overview_deposit_notification_send_button: 'Envoyer',
  overview_deposit_notification_answer_button: 'Envoyer la réponse',
  overview_deposit_notification_title_label: 'Titre',
  overview_deposit_notification_status_label: 'Statut',
  overview_deposit_notification_content_label: 'Contenu de la notification',
  overview_deposit_notification_irregularity_regularisation: 'Ajouter des reproductions à régulariser',
  overview_deposit_notification_status_TAB_DRAFT: 'Brouillon',
  overview_deposit_notification_status_TAB_TO_SEND: 'A envoyer',
  overview_deposit_notification_status_TAB_WAITING_ANSWER: 'En attente de réponse',
  overview_deposit_notification_status_TAB_REPLIED: 'Répondue',
  overview_deposit_notification_status_TAB_CLOSED_REPLIED: 'Fermée répondue',
  overview_deposit_notification_status_TAB_CLOSED_NO_REPLIED: 'Fermée non répondue',
  overview_deposit_notification_status_TAB_CONFIRMED_RECEIPT: 'Réception confirmée',
  overview_deposit_notification_status_TAB_EXPIRED_PERIOD: 'Délai expiré',
  overview_deposit_notification_filename: 'Notification',
  overview_deposit_notification_status_TAB_NO_RESPONSE_EXPECTED: 'Sans réponse attendue',
  overview_deposit_notification_error_empty_document: 'Au moins un document au format PDF est nécessaire',
  overview_deposit_notification_error_document_format: 'Les fichiers doivent être au format PDF.',
  overview_deposit_notification_modal_send_message: 'Vous êtes sur le point de répondre à une notification',
  overview_deposit_notification_supporting_document_label: 'Document(s) justificatif(s)',
  overview_deposit_notification_one_payment: 'Paiement d\'une notification',
  overview_deposit_notification_payment: 'Paiement de la notification',
  overview_deposit_notification_answer: 'Réponse à la notification',
  overview_deposit_notification_answer_receipt: 'Réception confirmée le ',
  overview_deposit_notification_success_answer: 'Votre réponse à la notification a été envoyée',
  overview_deposit_notification_already_paid: 'Cette notification a été payée',
  overview_deposit_notification_open: 'Lire la notification',
  overview_deposit_notification_close: 'Fermer',
  overview_deposit_notification_ellipsis: 'Veuillez télécharger le PDF pour voir l\'intégralité de la notification',

  overview_deposit_publications: 'Publications',
  overview_deposit_publications_num_repro: 'Numéro de reproduction',
  overview_deposit_publications_id_publication: 'Numéro de publication',
  overview_deposit_publications_date: 'Date de publication',
  overview_deposit_publications_bopi: 'Numéro du BOPI',

  // Inscription dessins et modèle
  breadcrumb_new_inscription: 'Nouvelle inscription',
  inscription_step_list_title: 'Inscription de dessins et modèles',

  // Inscription formulaire : types d'inscription
  select_inscription_type_title: 'Type d\'inscription',
  select_inscription_type_description: 'Sélectionnez le type d\'inscription',
  select_type_inscription_correction_title: 'Inscription de rectification',
  select_type_inscription_correction: 'Rectification',
  select_type_inscription_correction_resume: 'Inscrire un changement d\'adresse, de nom ou/et de forme juridique, ou encore une immatriculation de société ou une correction d\'erreur matérielle',
  select_type_inscription_ownership_title: 'Inscription d\'actes affectant la propriété ou la jouissance d\'un titre',
  select_type_inscription_ownership: 'Actes',
  select_type_inscription_ownership_resume: 'Inscrire des contrats relatifs à des transmissions de propriété, des licences, des gages, des saisies...',
  select_type_inscription_disclaimer_title: 'Inscription de renonciation',
  select_type_inscription_disclaimer_resume: 'Inscrire une renonciation totale ou partielle à un titre',
  select_type_inscription_disclaimer: 'Renonciation',
  select_type_inscription_office: 'Office',
  select_inscription_type_required: 'Sélectionnez un type d\'inscription',
  select_inscription_no_disclaimer: 'Une inscription de renonciation ne peut concerner qu\'un seul titre',
  select_inscription_correction_title: 'Rectification',
  select_inscription_correction_description: 'Nature de la rectification à inscrire',
  select_inscription_ownership_title: 'Acte',
  select_inscription_ownership_description: 'Nature de l\'acte à inscrire',
  linked_inscription_message: 'Cette demande fait partie d\'une suite d\'inscriptions',
  position_inscription_message: 'Veuillez indiquer sa position (ex: 1 / 3)',
  previous_inscriptions_label: 'Inscriptions précédentes :',

  // Inscription formulaire
  correction_form_nature_block_title: 'Nature de la rectification',
  correction_form_nature_error: 'Veuillez sélectionner au moins un type de rectification',
  correction_form_replace_text_error: 'Veuillez renseigner au moins un des deux champs ci-dessus.',
  ownership_form_nature_block_title: 'Sélectionner la nature de l\'opération',
  ownership_form_nature_error: 'Veuillez sélectionner au moins un type d\'acte',
  inscription_form_document_error: 'Veuillez renseigner au moins un document communicable',
  inscription_form_document_label_error: 'Veuillez renseigner tous les champs du document',

  // Bloc énoncé
  correction_form_state_block_title: 'Enoncé de la rectification',
  correction_form_state_block_change_this: 'Remplacer ce texte',
  correction_form_state_block_by_this: 'Par ce texte',
  correction_form_state_block_comment: 'Commentaire',

  // Bloc EM : Pièce à rectifier
  correction_form_part_rectified_block_title: 'Pièce à rectifier',
  correction_form_part_rectified_block_label: 'Cocher la ou les cases désignant la nature des pièces à rectifier',
  correction_form_part_rectified_block_deposit_label: 'Pièce(s) du dépôt',
  correction_form_part_rectified_block_act_label: 'Acte inscrit au registre',
  correction_form_part_rectified_block_act_date: 'Date d\'inscription',
  correction_form_part_rectified_block_act_number: 'Numéro d\'inscription',
  correction_form_part_rectified_block_prorogation_declaration: 'Déclaration de prorogation',
  correction_form_part_rectified_block_prorogation_number: 'Numéro de prorogation',

  // Bloc documents
  inscription_form_documents_block_title: 'Pièce(s) produite(s)',
  inscription_form_documents_attached_files: 'fichier(s) joint(s)',
  inscription_form_documents_block_proof: 'Justification de redevance prescrite',
  inscription_form_documents_block_other_proof: 'Autre(s) pièce(s) (précisez la nature de celle(s)-ci)',
  inscription_form_documents_block_agent_proof: 'S\'il y a lieu, le pouvoir du mandataire ou copie du pouvoir permanent (sauf conseil en propriété industrielle ou avocat)',
  inscription_form_documents_block_ownership_edit: 'Copie ou extrait de l\'acte constatant la modification de la propriété ou de la jouissance',
  inscription_form_documents_block_no_proof: 'Le cas échéant, justification de l\'impossibilité matérielle de produire une copie de l\'acte dont résulte l\'opération',
  inscription_form_documents_block_documents_communicable: 'Communicable',
  inscription_form_documents_block_documents_non_communicable: 'Non communicable',
  inscription_form_documents_success: 'Vos fichiers ont bien été mis à jour',

  // Inscription formulaire : selection des titres
  records_inscription: 'Selection des titres',
  disclaimer_inscription: 'Renonciation',
  disclaimer_inform_publication_ids_label: 'Indiquez le(s) numéro(s) de publication au(x)quel(s) vous renoncez',
  overview_records_inscription: 'Titre(s) sélectionné(s)',
  records_inscription_title: 'Dessin(s) et modèle(s) concerné(s) par votre inscription',
  records_request_title: 'Dessin(s) et modèle(s) concerné(s) par votre demande',
  records_request_subtitle: 'Renseignez les dessins et modèles concernés par votre demande',
  records_inscription_subtitle: 'Renseignez les dessins et modèles concernés par votre inscription',
  records_inscription_disclaimer_is_published: 'Votre titre est-il publié ?',
  records_inscription_disclaimer_title: 'Titre concerné par votre inscription',
  records_inscription_disclaimer_switch_label: 'Votre dépôt est-il publié ?',
  records_inscription_disclaimer_transaction_not_published: 'Si votre titre n\'est pas encore publié, vous pouvez effectuer une demande de retrait via le portail des dessins et des modèles : « Dépôt de dessins et modèles », « Effectuer une action sur mes modèles »',
  records_inscription_upload_csv: 'Charger une liste de titres',
  records_inscription_download_csv_explain: 'Charger une liste de titres. Le fichier à charger doit impérativement être un csv. Le nombre de titres pour une demande électronique ne peut excéder 1000.',
  records_inscription_download_csv_label: 'Liste de titres',
  records_inscription_origin_label: 'Origine',
  records_inscription_origin_fr: 'D&M Français',
  records_inscription_origin_int_fr: 'D&M International',
  records_inscription_deposit_date_label: 'Date de dépôt',
  records_inscription_numnat_label: 'Numéro',
  records_inscription_model_label: 'N° modèle',
  records_inscription_information_from_label: 'Rechercher',
  records_inscription_information_from_num_nat: 'Rechercher un titre',
  records_inscription_information_from_model: 'Rechercher un modèle',
  records_inscription_information_from_publication: 'Rechercher une publication',
  records_inscription_information_from_interval: 'Intervalle de reproductions',
  records_inscription_information_from_interval_from: 'De',
  records_inscription_information_from_interval_to: 'A',
  records_inscription_search: 'Rechercher',
  records_inscription_search_public: 'Accéder à la base de données Dessins & Modèles',
  records_inscription_publication_model_label: 'N° de modèle',
  records_inscription_id_publication_label: 'Numéro de publication',
  records_inscription_publication_model_add_button: 'Ajouter un modèle',
  records_inscription_publication_label: 'Numéro(s) de publication',
  records_inscription_publication_add_button: 'Ajouter un numéro de publication',
  records_inscription_valid_form: 'Vérifier l\'existence des publications',
  records_inscription_submit_form: 'Valider le titre',
  records_inscription_warning_existence: 'Certains numéros de publication ne sont pas reconnus',
  overview_records_inscription_show_more: 'Voir plus',
  overview_records_inscription_show_less: 'Voir moins',
  process_inscription: 'Je souhaite un traitement accéléré de ma demande',
  records_inscription_download_csv: 'Télécharger cette liste de titres',
  records_inscription_add: 'Ajouter un titre',
  do_inscription: 'Effectuer une inscription sur ce titre',
  do_official_document: 'Demander un Document officiel',
  do_appeal: 'Effectuer un relevé de déchéance sur ce titre',
  warning_get_titles_null_result: 'Aucun titre trouvé',
  warning_model_not_found: 'Aucune publication avec ce n° de modèle trouvée',
  warning_at_least_one_title: 'Au moins 1 titre est requis',
  warning_only_one_family: 'Tous les documents officiels doivent avoir la même famille',
  title_modal_select_title: 'Sélectionner un titre',

  // Inscription et Prorogation : Sélection des transactions
  records_inscription_request_date_label: 'Date de la demande',
  records_inscription_order_num_label: 'N° d\'ordre',
  records_inscription_prorogation_num_label: 'N° de prorogation',
  warning_transaction_not_found: 'Aucune {procedureType} avec un titre avec ce n° national trouvée',
  transaction_selection_modal_select_transaction_title: 'Sélectionner une transaction',

  // Inscription formulaire : Intervenant
  intervenant_form_description_inscription_appeal: 'Cette rubrique vous permet d\'identifier les différents intervenants de votre demande. A minima les onglets demandeur(s), destinataire et signataire devront être remplis.',
  intervenant_applicants: 'Demandeur(s)',
  intervenant_applicant: 'Demandeur',
  intervenant_other: 'Autre(s) partie(s) à l\'acte',
  intervenant_warning_applicant_needed: 'Au moins un demandeur est nécessaire',
  intervenant_warning_other_applicant_needed: 'Au moins une autre partie à l\'acte est nécessaire',

  // Inscription formulaire : Codes Nature
  overview_inscription: 'Inscription',
  overview_type_inscription: 'Type de l\'inscription',
  overview_nature_inscription: 'Nature de l\'inscription',

  // Inscription formulaire: Associations
  overview_association: 'Associations',
  ownership_appeal_decommission: 'Cette demande d\'inscription accompagne-t-elle un recours en restauration ou demande de relevé de déchéance ?',
  ownership_prorogation: 'Cette demande d\'inscription accompagne-t-elle une demande de prorogation de Dessins et modèles ?',
  ownership_is_appeal_decommission: 'Cette demande d\'inscription accompagne un recours en restauration ou une demande de relevé de déchéance',
  ownership_is_prorogation: 'Cette demande d\'inscription accompagne une demande de prorogation de Dessins et modèles',

  // Prorogation formulaire
  breadcrumb_new_prorogation: 'Nouvelle prorogation',
  prorogation_step_list_title: 'Prorogation de dessins et modèles',
  prorogation_request_details_title: 'Détails de la demande',
  prorogation_protection_extensions_title: 'Extensions de protections',
  prorogation_additional_parts: 'Pièces Complémentaires',
  prorogation_linked_details_title: 'Relevés de déchéance et inscriptions associées',
  prorogation_appeal_decommission: 'Cette demande accompagne-t-elle un recours en restauration ou demande de relevé de déchéance ?',
  appeal_decommission_number: 'N° de demande de relevé de déchéance',
  prorogation_details_order_number: 'N° d\'ordre TA/TN-AAAA-NNNNN',
  prorogation_inscription_request: 'Cette demande accompagne-t-elle une demande d\'inscription de transfert de propriété?',
  prorogation_different_depositor: 'Si vous n\'êtes pas le déposant d\'origine, indiquer le n° d’ordre ou le n° d\'inscription du transfert de propriété',
  prorogation_order_number_table: 'N° d\'ordre (TN-AAAA-NNNNN)',
  prorogation_inscription_number_table: 'N° d\'inscription (NNNNNNN)',
  prorogation_document: 'Document',
  prorogation_new_inscription_request_button: 'Ajouter une demande d\'inscription',
  prorogation_inscription_request_missing_inscription_request: 'Il faut renseigner au minimum une demande d\'inscription',
  prorogation_inscription_request_missing_number: 'Il faut renseigner le numéro d\'ordre et/ou le numéro d\'inscription',
  prorogation_file_title: 'Fichier',

  // Prorogation formulaire : selection des titres
  records_prorogation: 'Selection des titres',
  records_prorogation_title: 'Dessins et modèles concernés',
  records_prorogation_subtitle: 'Renseignez les dessins et modèles concernés par votre prorogation',
  records_prorogation_manual: 'Renseignez manuellement un titre',
  records_prorogation_search: 'Rechercher un titre',
  records_prorogation_return_search: 'Retour à la recherche',
  records_prorogation_search_numNat: 'N° national du dépôt',
  records_prorogation_search_numModel: 'N° de modèle',
  records_prorogation_search_idPublication: 'N° de publication d\'une reproduction',
  records_prorogation_isScopePartial: 'Souhaitez vous proroger ',
  records_prorogation_scope_label: 'Type de prorogation',
  records_prorogation_overview_download: 'Télécharger le récapitulatif',
  records_prorogation_add_taxe: 'Si le délai de prorogation du titre est dépassé, vous devez vous acquitter d\'une taxe supplémentaire. Cochez cette case pour ajouter la taxe en question.',

  // Prorogation formulaire : recherche
  records_prorogation_search_result: 'Résultats de la recherche',
  records_prorogation_search_result_subtitle_byNumNat: 'Attention vous trouverez ici les informations concernant la reproduction n°1 du modèle n°1 recherché',
  records_prorogation_search_result_subtitle_byIdPublication: 'Attention vous trouverez ici les informations concernant la reproduction recherché',
  records_prorogation_search_result_partial: 'Proroger une partie du dépôt',
  records_prorogation_search_result_total: 'Proroger l\'intégralité du dépôt',
  records_prorogation_return_select: 'Retour à la sélection',

  // Prorogation formulaire : selection (partielle)
  records_prorogation_select_result: 'Modèle & reproduction : selectionner les modèles ou reproductions concernés',
  records_prorogation_select_all: 'Tout sélectionner',
  records_prorogation_to_late: 'La période de prorogation est dépassée',
  records_prorogation_not_yet: 'La période de prorogation n\'est pas atteinte',
  records_prorogation_max_prorogation: 'Le nombre maximum de prorogation est atteint',
  records_prorogation_is_already_ask: 'Une prorogation est déjà en cours sur ce titre. Pour plus d\'informations, veuillez contacter l\'INPI.',

  // Prorogation formulaire : Intervenant
  intervenant_form_description_prorogation: 'Cette rubrique vous permet d\'identifier les différents intervenants de votre demande. A minima les onglets déclarant, destinataire et signataire devront être remplis.',
  intervenant_beneficiary: 'Bénéficiaire',
  intervenant_depositor: 'Déposant',
  intervenant_coDepositors: 'Co-déposants',

  // Prorogation formulaire : Récapitulatif
  selected_records_prorogation: 'Titres sélectionnés',
  selected_record_prorogation: 'Titre sélectionné',
  record_prorogation: 'Dépôt n°',
  total_prorogation: 'Prorogation totale',
  partial_prorogation: 'Prorogation partielle',
  details_prorogation_form_extensions_subtitle: 'Extension de la protection',
  prorogation_appeal_decommission_recap: 'Cette demande accompagne un recours en restauration ou une demande de relevé de déchéance',
  prorogation_appeal_decommission_with_number_recap: 'Cette demande accompagne le recours en restauration ou la demande de relevé de déchéance portant le n° ',
  prorogation_with_inscription_requests_recap: 'Cette demande accompagne les demandes d\'inscription suivantes : ',
  prorogation_order_number: 'N° d\'ordre',
  prorogation_inscription_number: 'N° d\'inscription',
  prorogation_numnat: 'N° d\'enregistrement {numnat} : ',

  // Relevé de déchéance formulaire
  breadcrumb_new_appeal: 'Nouveau relevé de déchéance',
  appeal_step_list_title: 'Relevé de déchéance de dessins et modèles',
  appeal_details: 'Demande relevé de déchéance',

  // Relevé de déchéance formulaire : selection des titres
  records_appeal: 'Selection des titres',
  records_appeal_subtitle: 'Renseignez les dessins et modèles concernés par votre relevé de déchéance',

  // Relevé de déchéance formulaire : Détails
  appeal_details_title: 'Relevé de déchéance',
  appeal_inscription_rndm: 'Inscription au RNDM en cours',
  appeal_inscription_rndm_label: 'N° d\'ordre de l\'inscription',
  appeal_type_label: 'Type de relevé de déchéance',
  appeal_relievedOfForfeiture_label: 'Saisir la demande de relevé de déchéance',
  appeal_relievedOfForfeiture_document_label: 'Dépôt d\'un document',
  appeal_relievedOfForfeiture_error: 'Vous devez obligatoirement saisir la demande de relevé de déchéance ou déposer un document',
  appeal_relievedOfForfeiture_info: 'Veuillez saisir votre demande OU ajouter un document',
  appeal_document_acte_label: 'L\'acte à accomplir',
  appeal_document_acte: 'Acte ',
  appeal_document_supporting_label: 'Pièces justificatives',
  appeal_document_supporting: 'Pièce ',

  // Relevé de déchéance formulaire : Récapitulatif
  overview_applicant_depositor: 'Demandeur',
  overview_details_appeal: 'Demande de relevé de déchéance',
  appeal_with_inscription_requests_recap: 'Cette demande accompagne la demande d\'inscription suivante : ',
  appeal_form_documents_attached_files: 'fichier(s) joint(s)',
  overview_appeal_details_title: 'Demande',
  overview_appeal_details_document: 'Documents',

  // Document officiel formulaire
  breadcrumb_new_official_document: 'Nouveau document officiel',
  official_document_required_type: 'Veuillez sélectionner le type de document officiel',
  official_document_step_list_title: 'Documents Officiels de dessins et modèles',
  official_document_asked: 'Documents demandés',
  official_document_type: 'Type de document',
  official_document_type_OFFICIAL_COPY_FRENCH_DESIGN: 'Copie officielle d\'un dessin et modèle français',
  official_document_type_CERTIFICATE_OF_IDENTITY_FRENCH_DESIGN: 'Certificat d\'identité d\'un dessin et modèle français',
  official_document_type_STATUS_INSCRIPTION: 'Etat des inscriptions d’un dessin et modèle au registre national',
  official_document_type_COPY_OF_DEPOSIT: 'Copie de dossier du dépôt dessin et modèle',
  official_document_type_COPY_OF_INSCRIPTION: 'Copie de dossier d\'inscription dessin et modèle',
  official_document_type_COPY_OF_PROROGATION: 'Copie de dossier de prorogation dessin et modèle',
  official_document_numnat: 'N° d\'enregistrement',
  official_document_model_number: 'N° de modèle',
  official_document_quantity: 'Quantité',
  official_document_format: 'Envoi',
  official_document_is_administration: 'Je suis une administration',
  official_document_justificatif_file: 'Pièce justificative',
  intervenant_form_description_official_document: 'Cette rubrique vous permet d\'identifier le demandeur de votre demande.',
  overview_official_document_filename: 'Document-Officiel',
  official_document_sending_BY_MAIL: 'Courrier',
  official_document_sending_BY_PORTAL: 'Portail',
  official_document_sending_BY_DAS: 'DAS',
  official_document_read: 'Marquer comme lu: ',
  official_document_read_modal_title: 'Marquer comme lu ?',
  official_document_read_modal_body: 'Ce document ne sera plus disponible après validation',
  official_document_no_result: 'Aucun document disponible',

  // Document officiel formulaire : selection des titres
  records_official_document: 'Selection des titres',
  records_official_document_subtitle: 'Renseignez les dessins et modèles concernés par votre document officiel',
  records_official_document_service_label: 'Prestation',
  records_official_document_service_type: 'Type de document officiel',
  records_official_document_service_quantity: 'Quantité',
  records_official_document_service_quantity_error: 'La quantité doit être supérieure à 0',
  records_official_document_service_copyOfficialByPortal: 'Mise à disposition sur le portail (PDF)',
  records_official_document_service_copyOfficialByMail: 'Envoi papier par courrier',
  records_official_document_service_copyOfficialByDAS: 'Envoi DAS',
  records_official_document_service_copyNum_copy_of_inscription: 'Numéro d\'ordre',
  records_official_document_service_copyNum_copy_of_prorogation: 'Numéro de prorogation',
  records_official_document_service_delay: 'Délai de traitement : ',
  records_official_document_record_label: 'Dessins et modèles',
  records_official_document_model_label: 'Numéro(s) de modèle',
  records_official_document_model_add_button: 'Ajouter un numéro de modèle',
  records_official_document_error_familly: 'Vous ne pouvez pas faire cette demande de documents officiels car certains documents ne sont pas compatibles en une unique demande.',

  // Paiement
  payment: 'Paiement',
  payment_method: 'Moyen de paiement',
  payment_bank_card: 'Carte bancaire',
  payment_manageable_memory: 'Mémoire administratif',
  payment_manageable_memory_info: 'Ce mode de paiement est réservé aux collectivités territoriales et aux établissements publics',
  payment_form_account_title: 'Compte professionnel INPI',
  payment_form_account: 'N° de compte professionnel',
  payment_access_button: 'Payer',
  payment_success: 'Le paiement de votre dépôt a été réalisé avec succès',
  payment_success_VIR: 'Votre dépôt a été confirmé',
  payment_waiting: 'En attente de la validation du paiement',
  payment_inscription_success: 'Votre inscription a été transmise',
  payment_prorogation_success: 'Votre demande de prorogation a été validée',
  payment_appeal_success: 'Votre relevé de déchéance a été validé',
  payment_official_document_success: 'Votre demande de document officiel a été validée',
  payment_text_success: 'Un email va vous être envoyé contenant le numéro national de votre dépôt. N\'hésitez pas à consulter votre dossier.',
  payment_text_success_VIR: 'Dans l\'attente du virement pour attribuer un numéro national. N\'hésitez pas à consulter votre dossier.',
  payment_text_inscription_success: 'Un email va vous être envoyé contenant le numéro d\'ordre de votre inscription. N\'hésitez pas à consulter votre dossier.',
  payment_text_prorogation_success: 'Un email va vous être envoyé contenant le numéro national de votre prorogation. N\'hésitez pas à consulter votre dossier.',
  payment_text_appeal_success: 'Un email va vous être envoyé contenant le numéro national de votre relevé de déchéance. N\'hésitez pas à consulter votre dossier.',
  payment_text_official_document_success: 'Un email va vous être envoyé contenant le numéro national de votre demande de document officiel. N\'hésitez pas à consulter votre dossier.',
  payment_error: 'Erreur lors de la réalisation du paiement de votre demande',
  payment_text_error: 'Une erreur est survenue lors de la validation de votre paiement. N\'hésitez pas à réessayer ulterieurement.',
  payment_annulation: 'Vous avez annulé le paiement de votre demande',
  payment_text_annulation: 'Vous êtes intervenu lors de la validation de votre paiement. N\'hésitez pas à réessayer ulterieurement.',
  payment_redirection: 'Aller sur le dossier',
  payment_redirection_multi: 'Consulter les demandes sur le portail',
  payment_new_inscription: 'Continuer la suite d\'inscription liée',
  payment_not_payer: 'Vous n\'êtes pas le payeur ?',
  payment_create_payer: 'Création d\'un payeur',
  payment_notification_success: 'Le paiement de votre notification a été réalisé avec succès',
  payment_notification_text_success: 'Vous pouvez toujours répondre à votre notification tant que celle-ci n\'est pas fermée. N\'hésitez pas à consulter votre dossier.',
  payment_notification_error: 'Erreur lors de la réalisation du paiement de votre notification',
  payment_notification_text_error: 'Une erreur est survenue lors de la validation de votre notification N\'hésitez pas à réessayer ulterieurement.',
  payment_notification_annulation: 'Vous avez annulé le paiement de votre notification',
  payment_notification_text_annulation: 'Vous êtes intervenu lors de la validation de votre notification. N\'hésitez pas à réessayer ulterieurement.',
  payment_division_success: 'Le paiement de votre division a été réalisé avec succès',
  payment_division_text_success: 'N\'hésitez pas à consulter vos dossiers.',
  payment_division_error: 'Erreur lors de la réalisation du paiement de votre division',
  payment_division_text_error: 'Une erreur est survenue lors de la validation de votre division. N\'hésitez pas à réessayer ulterieurement.',
  payment_division_annulation: 'Vous avez annulé le paiement de votre division',
  payment_division_text_annulation: 'Vous êtes intervenu lors de la validation de votre division. N\'hésitez pas à réessayer ulterieurement.',
  payment_prompt: 'Le paiement est toujours en cours de validation, êtes-vous sûr de vouloir quitter la page?',
  payment_transformation_tn_ta_success: 'Le paiement de votre passage en traitement accéléré a été réalisé avec succès',
  payment_transformation_tn_ta_text_success: 'N\'hésitez pas à consulter vos dossiers.',
  payment_transformation_tn_ta_error: 'Erreur lors de la réalisation du paiement de votre passage en traitement accéléré',
  payment_transformation_tn_ta_text_error: 'Une erreur est survenue lors de la validation de votre passage en traitement accéléré. N\'hésitez pas à réessayer ulterieurement.',
  payment_transformation_tn_ta_annulation: 'Vous avez annulé le paiement de votre passage en traitement accéléré',
  payment_transformation_tn_ta_text_annulation: 'Vous êtes intervenu lors de la validation de votre passage en traitement accéléré. N\'hésitez pas à réessayer ulterieurement.',
  payment_confirm_free_INSCRIPTION_TYPE: 'En validant la page, vous consentez à la demande d\'inscription. Il sera ensuite impossible de l\'abandonner ou de la modifier.',
  payment_confirm_free_APPEAL_TYPE: 'Si vous n’acquittez pas la redevance de relevé de déchéance, vous pourrez recevoir une notification vous demandant de la régler sous peine d’irrecevabilité.',
  payment_confirm_free_OFFICIAL_DOCUMENTS_TYPE: 'En validant la page, vous consentez à la demande de document officiel. Il sera ensuite impossible de l\'abandonner ou de la modifier.',
  payment_ask_free_appeal: 'Je demande un relevé de déchéance gracieux',
  payment_ask_free_appeal_error: 'Veuillez cocher cette case',
  payment_free: 'Aucune redevance',

  // Formulaire de demande de retrait de reproductions
  withdraw_reproduction_button: 'Retirer les reproductions',
  withdraw_reproduction_modal_title: 'Retrait de reproductions',
  withdraw_reproduction_warning: 'Attention, le retrait constitue une renonciation définitive au(x) modèle(s) ou reproduction(s). ' +
  'Ne pas passer par un retrait pour régulariser une reproduction.',
  withdraw_reproduction_button_delete: 'Retirer les reproductions sélectionnées',
  withdraw_reproduction_success: 'Les reproductions sélectionnées ont été retirées',
  reproduction_withdrawn: 'Retirée',

  // Demande de publication de reproductions
  publish_reproduction_button: 'Publier les reproductions',
  publish_reproduction_modal_title: 'Publication de reproductions',
  publish_reproduction_modal_content: 'Êtes-vous sûr de vouloir demander la publication des reproductions ci-dessous ?',
  publish_reproduction_success: 'Les reproductions sélectionnées ont été soumises à une demande de publication',
  publish_reproduction_button_select: 'Publier les reproductions demandées',
  publish_reproduction_button_all: 'Publier toutes les reproductions',

  // Liste de reproductions
  select_all: 'Tout sélectionner',
  reproduction_table_numero: 'N°',
  reproduction_table_image: 'Image',
  reproduction_table_title: 'Intitulé',
  reproduction_table_description: 'Description',
  reproduction_table_state: 'État',
  reproduction_model_number: 'Modèle n°',

  // Type d'aide
  help_procedure_type_deposit: 'Dépôt',
  help_procedure_type_prorogation: 'Prorogation',
  help_procedure_type_inscription: 'Inscription',
  help_procedure_type_decision: 'Décisions du Directeur',

  // Dépôt : demande de correction matérielle
  request_correction_button: 'Demander une correction d\'erreur matérielle',
  request_correction_title: 'Demande de correction d\'erreur matérielle',
  request_correction_supporting_documents: 'Documents justificatifs',
  request_correction_deleted_intervenant: 'Intervenant supprimé',
  request_correction_empty_intervenant: 'Intervenant non défini',
  request_correction_error_empty_mandataire: 'Un mandataire doit être spécifié',
  request_correction_error_undeletable_agent: 'Le mandataire ne peut être supprimé que s\'il n\'y a aucun co-déposant',

  // Dépôt : Ajout de documents FrontOffice
  request_add_document_button: 'Ajouter un document',
  request_document_title: 'Documents ajoutés',
  request_add_document_label: 'Document à ajouter',
  request_add_document_type_label: 'Type du document à ajouter',
  request_add_document_communicability_label: 'Communicable',
  request_type_pdf: 'Le format de votre fichier n\'est pas compatible avec le type de document selectionné',
  request_document_createdAt: 'Déposé le',
  request_document_name: 'Nom',
  request_document_type: 'Type',
  request_add_document_success: 'Votre document a bien été ajouté à votre dossier et transmis à l\'équipe INPI.',
  request_reproduction_completion_title: 'Complétion de reproductions',
  request_reproduction_completion_description: 'Certaines reproductions sélectionnées sont incomplètes. ' +
  'Veuillez remplir ci-dessous les informations manquantes pour chaque reproduction :',
  request_reproduction_completion_save: 'Enregistrer les reproductions',

  // Dossiers
  record_breadcrumb_list: 'Liste des dossiers',

  // Dépôt : proposition de division de dépôt
  request_notification_division_btn: 'Accéder à la proposition',
  request_division_title: 'Proposition de division',
  request_division_parent_title: 'Dépôt parent ',
  request_division_child_title: 'Dépôt enfant n°',
  request_division_reproduction_withdraw: 'Retirer la reproduction',
  request_division_reproduction_withdraw_all: 'Retirer toutes les reproductions',
  request_division_reproduction_withdrawn: 'Retirée',
  request_division_notification_not_found: 'La notification de proposition de division est introuvable',
  request_division_modal_pay_title: 'Paiement de la division',
  request_division_btn_accept_pay: 'Accepter et payer',
  request_division_btn_accept: 'Accepter',

  // Aide
  help_breadcrumb_title: 'Aide en ligne',

  // Erreurs WS
  error_creation_deposit: 'Erreur lors de la création du dépôt, veuillez réessayer',
  error_update_deposit: 'Erreur lors de la modification du dépôt, aucun dossier associé',
  error_creation_inscription: 'Erreur lors de la création de l\'inscription, veuillez réessayer',
  error_creation_prorogation: 'Erreur lors de la création de la prorogation, veuillez réessayer',
  error_creation_appeal: 'Erreur lors de la création du relevé de déchéance, veuillez réessayer',
  error_creation_officialDocument: 'Erreur lors de la création du document officiel, veuillez réessayer',
  error_update_inscription: 'Erreur lors de la modification de l\'inscription',
  error_update_prorogation: 'Erreur lors de la modification de la prorogation',
  error_update_appeal: 'Erreur lors de la modification du relevé de déchéance',
  error_update_officialDocument: 'Erreur lors de la modification du document officiel',
  error_get_titles_from_numnat_inscription: 'Erreur lors de la récupération des titres depuis ce numéro national',
  error_get_editorial_block: 'Erreur lors de la récupération d\'un bloc éditorial',
  error_get_sien_from_denomination: 'Erreur lors de la récupération de la liste de siren associée à une dénomination',

  select_placeholder: 'Sélectionner ...',

  fo_version_api: 'API :',
  fo_version_front: 'FO :'
}

export default Message
