import Message from './Message'

export const RECORD_FROM_OPEN_DATA_PI = 'OpenDataPi'
export const RECORD_FROM_INSCRIPTION = 'Inscription'

export const INFORMATION_FROM_INTERVAL = 'INTERVAL'

export const INFORMATION_FROM_MODEL = 'MODEL'

export const INFORMATION_FROM_NUM_NAT = 'NUM_NAT'

export const INFORMATION_FROM_PUBLICATION = 'PUBLICATION'

export const RECORD_INSCRIPTION_INFORMATION_FROM_SELECT = [{
  label: Message.records_inscription_information_from_num_nat,
  value: INFORMATION_FROM_NUM_NAT
}, {
  label: Message.records_inscription_information_from_model,
  value: INFORMATION_FROM_MODEL
}, {
  label: Message.records_inscription_information_from_interval,
  value: INFORMATION_FROM_INTERVAL
}, {
  label: Message.records_inscription_information_from_publication,
  value: INFORMATION_FROM_PUBLICATION
}]
