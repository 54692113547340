import React, { FC, MouseEvent, ReactNode } from 'react'

interface CardBlockProps {
  className?: string,
  shadow?: boolean,
  onClick?: (event: MouseEvent) => void,
  header?: ReactNode,
  headerClassName?: string
  bodyClassName?: string,
  flexBody?: boolean
}

const CardBlock: FC<CardBlockProps> = ({
  className = '',
  shadow = false,
  children,
  onClick,
  header,
  headerClassName = '',
  bodyClassName = '',
  flexBody = true
}) => (
  <div
    className={`card ${header ? 'no-border' : ''}  ${onClick ? 'cursor-pointer' : ''} ${shadow ? 'shadow' : ''} ${className}`}
    onClick={onClick}
  >
    {header &&
      <div className={`card-header ${headerClassName}`}>
        {header}
      </div>}
    {children &&
      <div className={`${flexBody && 'card-body'} ${bodyClassName}`}>
        {children}
      </div>}
  </div>
)

export default CardBlock
