import { containsErrors, IntervenantValidator } from '@inpi-dm/components'
import { storeProrogationFieldStatusUpdate } from '../../../../../../store/prorogation/prorogationActions'
import store from '../../../../../../store/store'

class SignatoryValidator {
  validateOnChangeStep = () => {
    const prorogation = store.getState().prorogation

    const fieldStatus = IntervenantValidator.validateSignatory(prorogation.signatory)
    store.dispatch(storeProrogationFieldStatusUpdate({
      signatory: fieldStatus
    }))

    return !containsErrors(fieldStatus)
  }
}

export default new SignatoryValidator()
