import React, { FC, useEffect, useState } from 'react'
import { Payment } from '../../../../interfaces/DepositInterfaces'
import OverviewRoyalties from '../../../payment/OverviewRoyalties'
import { PAYMENT_OBJECT } from '../../../../constants/DepositConstants'
import { FormattedMessage } from 'react-intl'
import PaymentForm from '../../../payment/PaymentForm'
import { Deposit } from '@inpi-dm/components'
import DepositService from '../../../../services/transaction/DepositService'
import { PAYMENT_URL_DEPOSIT } from '../../../../constants/PaymentConstants'

interface PaymentDepositProps {
    deposit: Deposit
}

const PaymentDeposit: FC<PaymentDepositProps> = ({
  deposit
}) => {
  const [infoPrice, setInfoPrice] = useState<Payment>()

  useEffect(() => {
    // Récupération des informations concernant le paiement
    DepositService.getPayment(deposit.idTransaction)
      .then((payment) => {
        return payment && setInfoPrice(payment)
      }
      )
  }, [deposit])

  return (
    <PaymentForm
      label='Demande initiale'
      overviewPrices={<OverviewRoyalties infoPrice={infoPrice} />}
      object={deposit}
      urlType={PAYMENT_URL_DEPOSIT}
      objectName={PAYMENT_OBJECT.TRANSACTION}
      title={<FormattedMessage id='payment' />}
      totalAmount={infoPrice?.totalAmount}
    />
  )
}

export default PaymentDeposit
