import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage, IntlProvider, WrappedComponentProps } from 'react-intl'
import {
  AddressApiResult,
  AddressFields,
  AutocompleteResult, Country,
  DepositIntervenant,
  EventType,
  FieldStatus, isFilled,
  PERSONNE_MORALE, PERSONNE_PHYSIQUE,
  PERSONNE_TYPES,
  PersonneContactFields,
  PersonneMoraleFields,
  PersonnePhysiqueFields,
  SelectButton,
  SelectField
} from '..'
import { Button } from 'react-bootstrap'
import Message from '../constants/Message'
import CheckboxField from '../form/fields/CheckboxField'
import SubtitleIntervenant from './intervenant/SubtitleIntervenant'

interface RecipientFormProps extends WrappedComponentProps {
  foDisplay?: boolean | null,
  boDisplay?: boolean | null,
  isInscriptionForm?: boolean | null,
  idCurrentUser?: number | null,
  depositRecipient: DepositIntervenant,
  intervenants?: AutocompleteResult<DepositIntervenant>[] | null,
  handleSelectIntervenant?: (intervenant: DepositIntervenant) => void,
  handleRecipientForm?: (recipient: DepositIntervenant) => void,
  handleSelfRecipient?: () => void,
  handleResetRecipient?: () => void,
  handleChange?: (event: EventType) => void,
  fieldStatus: FieldStatus,
  searchAddress: (query: string, type?: string|null, limit?: number|null) => Promise<void | AddressApiResult[]>,
  getCountries: () => Promise<void | Country[]>,
  checkIsRecipientNeeded? : boolean | null
}

const RenderRecipientForm: FC<RecipientFormProps> = ({
  handleChange,
  intl,
  intervenants,
  handleSelectIntervenant,
  handleSelfRecipient,
  depositRecipient,
  idCurrentUser,
  handleResetRecipient,
  fieldStatus,
  handleRecipientForm,
  foDisplay,
  boDisplay,
  searchAddress,
  getCountries,
  isInscriptionForm,
  checkIsRecipientNeeded
}) => {
  const [currentRecipient, setCurrentRecipient] = useState(depositRecipient)

  useEffect(() => {
    setCurrentRecipient(depositRecipient)
  }, [depositRecipient])

  /**
   * Enregistrement des modifications du destinataire
   * @param event
   */
  const handleChanges = (event: EventType) => {
    const { name, value } = event.target

    const recipientPatches = {
      ...currentRecipient,
      [name]: value
    }
    if (recipientPatches.type === PERSONNE_PHYSIQUE.value) {
      recipientPatches.companyName = undefined
      recipientPatches.legalForm = undefined
      recipientPatches.siren = undefined
    }
    setCurrentRecipient(recipientPatches)
    if (foDisplay) {
      handleChange && handleChange(event)
    }
  }

  return (
    <IntlProvider locale='fr' messages={Message}>
      <div className='form-header'>
        <div className='d-flex'>
          <h2 className={`mb-0 ${boDisplay && !foDisplay ? 'h4' : ''}`}>
            <FormattedMessage id='intervenant_destinataire_titre' />
          </h2>
          <div className='col options px-0 my-auto'>
            {isFilled(intervenants) && (
              <SelectButton
                className='btn btn-link text-primary'
                options={intervenants}
                onSelect={handleSelectIntervenant}
              >
                <FormattedMessage id='intervenant_copier_donnees' />
              </SelectButton>
            )}
            {
              foDisplay &&
                <>
                  <div className='separator' />
                  {currentRecipient && idCurrentUser !== currentRecipient.idUser && (
                    <button className='btn btn-link text-primary' onClick={handleSelfRecipient}>
                      <FormattedMessage id='intervenant_auto_destinataire' />
                    </button>
                  )}
                  {currentRecipient && idCurrentUser === currentRecipient.idUser && (
                    <button className='btn btn-link text-primary' onClick={handleResetRecipient}>
                      <FormattedMessage id='intervenant_reinitialiser' />
                    </button>
                  )}
                </>
            }
          </div>
        </div>
        {foDisplay &&
          <SubtitleIntervenant idText='intervenant_destinataire_subtitle' />}
      </div>

      <div className='header-field'>
        <FormattedMessage id='field_personne_type_label' />
      </div>

      <div className='row'>
        <div className='col-12 col-md-4'>
          <SelectField
            inputId='type'
            placeholder={intl.formatMessage({ id: 'placeholder_select' })}
            value={currentRecipient.type || ''}
            options={PERSONNE_TYPES}
            onChange={handleChanges}
            fieldStatus={fieldStatus}
            required
          />
        </div>
      </div>

      {currentRecipient.type === PERSONNE_MORALE.value && (
        <PersonneMoraleFields
          intervenant={currentRecipient}
          onChange={handleChanges}
          fieldStatus={fieldStatus}
          intl={intl}
          isRecipient
        />
      )}

      <PersonnePhysiqueFields
        intervenant={currentRecipient}
        onChange={handleChanges}
        fieldStatus={fieldStatus}
        intl={intl}
      />

      <PersonneContactFields
        intervenant={currentRecipient}
        onChange={handleChanges}
        fieldStatus={fieldStatus}
        isEmailReadyOnly
      />

      {
        ((boDisplay && isInscriptionForm) || checkIsRecipientNeeded) &&
          <div className='col-12'>
            <CheckboxField
              inputId='isRecipient'
              label={<FormattedMessage id='field_agent_is_recipient' />}
              checked={currentRecipient.isRecipient}
              onChange={handleChanges}
              className='mb-0'
            />
            {
              currentRecipient.isRecipient &&
                <span className='text-warning'>
                  <FormattedMessage id='intervenant_warning_agent_is_recipient' />
                </span>
            }
          </div>
      }

      <AddressFields
        onChange={handleChanges}
        address={(currentRecipient && currentRecipient.address) || undefined}
        fieldStatus={fieldStatus}
        intl={intl}
        searchAddress={searchAddress}
        getCountries={getCountries}
      />
      {
        boDisplay &&
          <div className='d-flex justify-content-around mt-2'>
            <Button className='primary' onClick={() => handleRecipientForm && handleRecipientForm(currentRecipient)}>
              <FormattedMessage id='common_validate' />
            </Button>
          </div>
      }
    </IntlProvider>
  )
}

export default RenderRecipientForm
