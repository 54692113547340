import React, { FC, useState } from 'react'
import { FormattedMessage, injectIntl, useIntl, WrappedComponentProps } from 'react-intl'
import {
  buildEventType,
  EventType,
  ModalComponent,
  SelectField,
  SubmitButton,
  TextField,
  ModalSelectTransaction,
  SelectOption,
  Transaction,
  OfficialDocumentType,
  COPY_OF_INSCRIPTION,
  INSCRIPTION_TYPE,
  PROROGATION_TYPE
} from '@inpi-dm/components'
import {
  INFORMATION_FROM_NUM_NAT,
  INFORMATION_FROM_PUBLICATION
} from '../../constants/InscriptionConstants'
import TransactionService from 'services/transaction/TransactionService'
import { TransactionSearchParameters, TransactionSearchResult } from 'interfaces/TransactionInterfaces'
import { toast } from 'react-toastify'

interface SearchTransactionsFormProps extends WrappedComponentProps {
  handleSearchResult: (transaction: Transaction) => void,
  handleChange: (e: EventType) => void,
  optionSearch: SelectOption[],
  officialDocumentType: OfficialDocumentType
  numNat: string|undefined,
  setNumNat: (numNat: string|undefined) => void
}

const SearchTransactionsForm: FC<SearchTransactionsFormProps> = ({
  handleSearchResult,
  handleChange,
  optionSearch,
  officialDocumentType,
  numNat,
  setNumNat
}) => {
  const intl = useIntl()
  const [informationFrom, setInformationFrom] = useState<string>(INFORMATION_FROM_NUM_NAT)
  const [idPublication, setIdPublication] = useState()
  const [transactions, setTransactions] = useState<Transaction[]>()
  const [showSelectTransactionModal, setShowSelectTransactionModal] = useState(false)

  const searchTransaction = () => {
    if (numNat) {
      const params: TransactionSearchParameters = {
        procedure: officialDocumentType.code === COPY_OF_INSCRIPTION ? INSCRIPTION_TYPE.value : PROROGATION_TYPE.value,
        titleNumNat: [numNat],
        sortBy: 'createdAt',
        order: 'ASC'
      }
      return TransactionService.searchTransaction(params).then((result: TransactionSearchResult) => {
        if (result && result.transactions) {
          // Si plusieurs transactions sont liées au numNat, l'utilisateur FO va alors devoir choisir sur laquelle se rattacher
          if (result.transactions.length > 1) {
            setTransactions(result.transactions.filter(transaction => transaction.numNat))
            setShowSelectTransactionModal(true)
          } else if (result.transactions.length > 0) {
            handleSearchResult(result.transactions[0])
          } else {
            toast.warning(intl.formatMessage(
              { id: 'warning_transaction_not_found' },
              { procedureType: officialDocumentType.code === COPY_OF_INSCRIPTION ? 'inscription' : 'prorogation' }
            ))
          }
        }
      })
    }
  }

  return (
    <>
      <div>
        <div className='row m-0 align-items-end'>
          <SelectField
            inputId='information_from'
            label={<FormattedMessage id='records_inscription_information_from_label' />}
            value={informationFrom}
            classNameFormGroup='col-4'
            onChange={(event: EventType) => {
              const { value } = event.target
              handleChange(buildEventType('publications', []))
              setNumNat(undefined)
              setIdPublication(undefined)
              setInformationFrom(value)
            }}
            options={optionSearch}
          />
          {
            informationFrom === INFORMATION_FROM_NUM_NAT &&
              <div className='col-3'>
                <TextField
                  inputId='numNat'
                  label={<FormattedMessage id='records_inscription_numnat_label' />}
                  value={numNat}
                  onChange={(event: EventType) => setNumNat(event.target.value)}
                />
              </div>
          }
          {
            informationFrom === INFORMATION_FROM_PUBLICATION &&
              <div className='col-3'>
                <TextField
                  inputId='idPublication'
                  label={<FormattedMessage id='records_inscription_id_publication_label' />}
                  value={idPublication}
                  onChange={(event: EventType) => setIdPublication(event.target.value)}
                />
              </div>
          }
          <div className='form-group'>
            <SubmitButton
              onClick={searchTransaction}
              className='btn-outline-primary'
            >
              <FormattedMessage id='records_inscription_search' />
            </SubmitButton>
          </div>
        </div>
        <div className='mx-3 mt-n2 mb-3'>
          <a
            href='https://data.inpi.fr/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <FormattedMessage id='records_inscription_search_public' />
          </a>
        </div>
      </div>
      {transactions &&
        <ModalComponent
          title={<FormattedMessage id='transaction_selection_modal_select_transaction_title' />}
          show={showSelectTransactionModal}
          customContent={() =>
            <ModalSelectTransaction
              transactions={transactions}
              selectTransaction={handleSearchResult}
              setShowModalSelectTransaction={setShowSelectTransactionModal}
            />}
          handleClose={() => setShowSelectTransactionModal(false)}
          hideFooter
        />}
    </>
  )
}

export default injectIntl(SearchTransactionsForm)
