import React, { FC } from 'react'
import {
  CardBlock,
  CheckboxField,
  DATE_ISO,
  DateField,
  DateUtils,
  EventType,
  TextField,
  Transaction
} from '@inpi-dm/components'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { useDispatch } from 'react-redux'
import { storeInscriptionUpdate } from '../../../../store/inscription/inscriptionActions'

interface CorrectionPartRectifiedProps extends WrappedComponentProps {
  transaction: Transaction
}

const CorrectionPartRectified: FC<CorrectionPartRectifiedProps> = ({ transaction }) => {
  const dispatch = useDispatch()

  return (
    <CardBlock
      header={<FormattedMessage id='correction_form_part_rectified_block_title' />}
      className='mb-3'
      shadow
    >
      <FormattedMessage id='correction_form_part_rectified_block_label' />
      <div className='mt-3'>
        <div className='col-xl-6 mb-1'>
          <CheckboxField
            inputId='isDepositPartRectified'
            label={<FormattedMessage id='correction_form_part_rectified_block_deposit_label' />}
            checked={transaction.inscription?.isDepositPartRectified}
            onChange={(event: EventType) => dispatch(storeInscriptionUpdate({ inscription: { ...transaction.inscription, isDepositPartRectified: event.target.value } }))}
          />
        </div>
        <div className='col-xl-6 mb-1'>
          <CheckboxField
            inputId='isActPartRectified'
            label={<FormattedMessage id='correction_form_part_rectified_block_act_label' />}
            checked={transaction.inscription?.isActPartRectified}
            onChange={(event: EventType) => dispatch(storeInscriptionUpdate({ inscription: { ...transaction.inscription, isActPartRectified: event.target.value } }))}
          />
          {transaction.inscription?.isActPartRectified && (
            <div className='d-flex align-items-center'>
              <TextField
                inputId='actPartRectifiedNumber'
                classNameFormGroup='w-50 mr-3'
                label={<FormattedMessage id='correction_form_part_rectified_block_act_number' />}
                value={transaction.inscription?.actPartRectifiedNumber}
                onChange={(event: EventType) => dispatch(storeInscriptionUpdate({ inscription: { ...transaction.inscription, actPartRectifiedNumber: event.target.value } }))}
              />
              <DateField
                inputId='actPartRectifiedDate'
                label={<FormattedMessage id='correction_form_part_rectified_block_act_date' />}
                value={DateUtils.formateDateToFormat(transaction.inscription?.actPartRectifiedDate, DATE_ISO)}
                onChange={(event: EventType) => dispatch(storeInscriptionUpdate({ inscription: { ...transaction.inscription, actPartRectifiedDate: event.target.value } }))}
              />
            </div>
          )}
        </div>
        <div className='col-xl-6'>
          <CheckboxField
            inputId='isProrogationDeclaration'
            label={<FormattedMessage id='correction_form_part_rectified_block_prorogation_declaration' />}
            checked={transaction.inscription?.isProrogationDeclaration}
            onChange={(event: EventType) => dispatch(storeInscriptionUpdate({ inscription: { ...transaction.inscription, isProrogationDeclaration: event.target.value } }))}
          />
          {transaction.inscription?.isProrogationDeclaration && (
            <TextField
              inputId='prorogationDeclarationNumber'
              classNameFormGroup='w-50 mr-3'
              label={<FormattedMessage id='correction_form_part_rectified_block_prorogation_number' />}
              value={transaction.inscription?.prorogationDeclarationNumber}
              onChange={(event: EventType) => dispatch(storeInscriptionUpdate({ inscription: { ...transaction.inscription, prorogationDeclarationNumber: event.target.value } }))}
            />
          )}
        </div>
      </div>

    </CardBlock>
  )
}

export default injectIntl(CorrectionPartRectified)
