import {
  CONTRIBUTORS_TITLE_UPDATE,
  CONTRIBUTORS_TITLE_REMOVE,
  ContributorsTitleStoreAction
} from './contributorsTitleStoreTypes'
import { infosByNumnat } from '@inpi-dm/components'

export function reducerContributorsTitleState (state = {}, action: ContributorsTitleStoreAction): infosByNumnat {
  switch (action.type) {
    case CONTRIBUTORS_TITLE_UPDATE:
      return {
        ...action.infosByNumNat
      }
    case CONTRIBUTORS_TITLE_REMOVE:
      return {}
    default:
      return state
  }
}
