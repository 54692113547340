import { Country, ManageableQuality, ProrogationDelay } from '@inpi-dm/components'

export const COUNTRIES_UPDATE = 'COUNTRY_UPDATE'
export const MANAGEABLE_QUALITIES_UPDATE = 'MANAGEABLE_QUALITIES_UPDATE'
export const PROROGATION_DELAYS_UPDATE = 'PROROGATION_DELAYS_UPDATE'

export interface ContentStoreAction {
  type: string,
  countries?: Country[],
  qualities?: ManageableQuality[],
  prorogationDelays ?: ProrogationDelay[]
}
