import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface OverviewLabelTextProps {
  className?: string,
  idLabel?: string,
}

const OverviewLabelText : FC<OverviewLabelTextProps> = ({
  idLabel,
  className = '',
  children
}) => (
  <div className={`col-12 mb-3 ${className}`}>
    <h4><FormattedMessage id={idLabel} /></h4>
    <div className='subtitle'>
      {children}
    </div>
  </div>
)

export default OverviewLabelText
