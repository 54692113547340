import React, { FC, useEffect, useState } from 'react'
import {
  AutocompleteResult,
  containsErrors,
  DEFAULT_INTERVENANT,
  DepositIntervenant,
  EventType,
  INTERVENANT_CO_DEPOSANT,
  INTERVENANT_CREATEUR,
  INTERVENANT_DEPOSANT,
  INTERVENANT_DESTINATAIRE,
  INTERVENANT_MANDATAIRE,
  INTERVENANT_SIGNATAIRE,
  ModalComponent,
  RenderAgentForm,
  RenderCoDepositorOrApplicantForm,
  RenderCreatorForm,
  RenderDepositorForm,
  RenderRecipientForm,
  RenderSignatoryForm,
  DepositDocument,
  PERSONNE_PHYSIQUE,
  SelectOption,
  DepositCorrection,
  DOCUMENT_COMMUNICABILITIES,
  DOCUMENT_STATUS,
  IntervenantValidator,
  DOCUMENT_TYPES, Transaction, PERSONNE_MORALE, ManageableQuality, INSCRIPTION_TYPE
} from '@inpi-dm/components'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { RootStateOrAny, useSelector } from 'react-redux'
import IntervenantService from '../../../services/transaction/IntervenantService'
import DepositCorrectionValidator from './validator/DepositCorrectionValidator'
import CorrectionService from '../../../services/transaction/CorrectionService'
import AddressApiService from '../../../services/content/AddressApiService'
import ContentService from '../../../services/content/ContentService'
import OpenDataRNCSService from '../../../services/content/OpenDataRNCSService'

interface ModalEditIntervenantProps extends WrappedComponentProps {
    typeIntervenant: SelectOption,
    intervenant?: DepositIntervenant|string,
    onChange: (intervenant: DepositIntervenant|string, agentDocument?: DepositDocument[]) => void,
    onClose: () => void,
    showModal?: boolean,
    deposit: Transaction,
    correction: DepositCorrection,
    documents?: DepositDocument[],
}

const ModalEditIntervenant: FC<ModalEditIntervenantProps> = ({
  typeIntervenant,
  intervenant,
  onChange,
  onClose,
  showModal = false,
  deposit,
  intl,
  correction,
  documents
}) => {
  const user = useSelector((state : RootStateOrAny) => state.user.user)
  const [fieldStatus, setFieldStatus] = useState({})
  const [idCurrentUser, setIdCurrentUser] = useState<string | number>(intervenant?.idUser || -1)
  const [editedIntervenant, setEditedIntervenant] = useState(intervenant)
  const [intervenants, setIntervenants] = useState<AutocompleteResult<DepositIntervenant>[]>([])
  const [agentDocuments, setAgentDocuments] = useState<DepositDocument[]>([])
  const [manageableQualities, setManageableQualities] = useState<ManageableQuality[]>()

  useEffect(() => {
    // Récupération des intervenants disponibles pour le destinataire ou mandataire
    let intervenantFound

    if (typeIntervenant === INTERVENANT_DESTINATAIRE) {
      intervenantFound = IntervenantService.findIntervenants(
        CorrectionService.mergeCorrectionInDeposit(deposit, correction).file,
        ['depositor', 'coDepositors'])
    } else if (typeIntervenant === INTERVENANT_MANDATAIRE) {
      intervenantFound = IntervenantService.findIntervenants(
        CorrectionService.mergeCorrectionInDeposit(deposit, correction).file,
        ['depositor', 'coDepositors', 'recipient'])
    }

    if (intervenantFound) {
      setIntervenants(intervenantFound.map(intervenant => ({
        label: IntervenantService.getName(intervenant),
        value: intervenant
      })))
    }

    // Récupération du documents de pouvoir pour le mandataire
    // Soit des documents indiqués par leur type, soit des documents à uploader
    if (typeIntervenant === INTERVENANT_MANDATAIRE && !correction.agentDeleted) {
      const agentDocuments = documents?.filter(doc => doc.type === DOCUMENT_TYPES.DEPOSIT_AGENT_DOCUMENT && doc.status !== DOCUMENT_STATUS.REJECTED)
      setAgentDocuments(agentDocuments || [])
    }

    // initialisation du signataire avec l'utilisateur courant si vide
    if (typeIntervenant === INTERVENANT_SIGNATAIRE && !intervenant) {
      setEditedIntervenant({
        type: PERSONNE_PHYSIQUE.value,
        civilityCode: user.civilityCode,
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email
      })
    }
  }, [typeIntervenant, correction, deposit, documents, intervenant, user])

  useEffect(() => {
    ContentService.getManageableQualities().then(r => setManageableQualities(r))
  }, [])

  /**
     * Remplissage du formulaire avec les informations de l'utilisateur connecté
     */
  const handleSelfIntervenant = () => {
    if (user) {
      IntervenantService.buildIntervenantFromUser(user).then(intervenantFromUser => {
        setEditedIntervenant({
          ...intervenantFromUser,
          id: intervenant?.id
        })
        setIdCurrentUser(user.id)
      })
    }
  }

  /**
     * Réinitialisation des champs du formulaire
     */
  const handleResetIntervenant = () => {
    setEditedIntervenant({ ...intervenant } || DEFAULT_INTERVENANT)
    setIdCurrentUser(intervenant?.idUser || -1)
  }

  /**
     * Copie d'un intervenant dans le correspondant
     */
  const handleSelectIntervenant = (intervenant: DepositIntervenant) => {
    if (intervenant) {
      IntervenantService.validateCountryOfContributor(intervenant).then(newIntervenant => {
        setEditedIntervenant({
          ...newIntervenant,
          id: editedIntervenant?.id,
          type: editedIntervenant?.type,
          address: {
            ...newIntervenant.address
          },
          nationality: undefined
        })
        setIdCurrentUser(intervenant?.idUser)
      })
    }
  }

  /**
   * Vérifie l'intervenant et le soumet si aucune erreur n'a été trouvée
   * @param updatedIntervenant
   */
  const handleOnChange = (updatedIntervenant: DepositIntervenant|string) => {
    setEditedIntervenant(IntervenantValidator.cleanIntervenant(updatedIntervenant, typeIntervenant.value === INTERVENANT_DESTINATAIRE.value))
    const newFieldStatus = DepositCorrectionValidator.validateIntervenant(typeIntervenant, deposit, updatedIntervenant, agentDocuments)
    setFieldStatus(newFieldStatus)

    if (!containsErrors(newFieldStatus)) {
      onChange(updatedIntervenant, agentDocuments)
    }
  }

  /**
     * Supprime un document de pouvoir du mandataire
     */
  const handleDeleteAgentDocument = () => {
    setAgentDocuments([])
  }

  /**
     * Ajoute un document de pouvoir au mandataire
     * @param event
     */
  const handleAddAgentDocument = (event: EventType) => {
    const { value } = event.target

    setAgentDocuments([{
      type: DOCUMENT_TYPES.DEPOSIT_AGENT_DOCUMENT,
      status: DOCUMENT_STATUS.NEW,
      archivability: true,
      communicability: DOCUMENT_COMMUNICABILITIES.COMMUNICABLE,
      name: value.name,
      internalName: '',
      format: value.format,
      file: value
    }])
  }

  /**
   * Fait appel à l'opendata et met à jour l'intervenant
   * @param siren
   */
  const fillInfosFromOpenData = (siren) => {
    OpenDataRNCSService.getContributorInfos(siren).then((intervenant) => {
      setEditedIntervenant({ ...editedIntervenant, type: PERSONNE_MORALE.value, ...intervenant })
    })
  }

  /**
     * Render du formulaire du type d'intervenant sélectionné
     * @constructor
     */
  const Form = () => {
    switch (typeIntervenant) {
      case INTERVENANT_DEPOSANT:
        return (
          <div className='form-intervenant is-validated'>
            <RenderDepositorForm
              idCurrentUser={idCurrentUser}
              searchAddress={AddressApiService.searchAddress}
              getCountries={ContentService.getCountries}
              depositDepositor={editedIntervenant || {}}
              handleSelfDepositor={handleSelfIntervenant}
              handleResetDepositor={handleResetIntervenant}
              handleDepositorForm={handleOnChange}
              fillContributorInfo={(siren) => fillInfosFromOpenData(siren)}
              findListSirenByNamePromise={(name: string) => OpenDataRNCSService.findListSirenByName(name)}
              fieldStatus={fieldStatus}
              boDisplay
              foDisplay
              intervenantType={INTERVENANT_DEPOSANT.value}
            />
          </div>)
      case INTERVENANT_CO_DEPOSANT:
        return (
          <div className='form-intervenant is-validated'>
            <RenderCoDepositorOrApplicantForm
              depositCoDepositor={editedIntervenant || {}}
              handleResetCoDepositor={handleResetIntervenant}
              handleSelfCoDepositor={handleSelfIntervenant}
              idCurrentUser={idCurrentUser}
              parentFieldStatus={fieldStatus}
              onUdpateCoDepositor={onClose}
              handleSubmitCoDepositor={handleOnChange}
              fillContributorInfo={(siren) => fillInfosFromOpenData(siren)}
              findListSirenByNamePromise={(name: string) => OpenDataRNCSService.findListSirenByName(name)}
              searchAddress={AddressApiService.searchAddress}
              getCountries={ContentService.getCountries}
              foDisplay
              intervenantType={typeIntervenant}
            />
          </div>)
      case INTERVENANT_DESTINATAIRE:
        return (
          <div className='form-intervenant is-validated'>
            <RenderRecipientForm
              depositRecipient={editedIntervenant || {}}
              handleRecipientForm={handleOnChange}
              searchAddress={AddressApiService.searchAddress}
              getCountries={ContentService.getCountries}
              fieldStatus={fieldStatus}
              handleResetRecipient={handleResetIntervenant}
              handleSelfRecipient={handleSelfIntervenant}
              handleSelectIntervenant={handleSelectIntervenant}
              intervenants={intervenants}
              idCurrentUser={idCurrentUser}
              intl={intl}
              boDisplay
              foDisplay
              isInscriptionForm={deposit.procedureType === INSCRIPTION_TYPE.value}
            />
          </div>)
      case INTERVENANT_MANDATAIRE:
        return (
          <div className='form-intervenant is-validated'>
            <RenderAgentForm
              depositAgent={editedIntervenant || {}}
              handleAgentForm={handleOnChange}
              fieldStatus={fieldStatus}
              handleResetAgent={handleResetIntervenant}
              handleSelfAgent={handleSelfIntervenant}
              handleSelectIntervenant={handleSelectIntervenant}
              intervenants={intervenants}
              idCurrentUser={idCurrentUser}
              searchAddress={AddressApiService.searchAddress}
              getCountries={ContentService.getCountries}
              addAgentDocument={handleAddAgentDocument}
              agentDocuments={agentDocuments}
              deleteAgentDocument={handleDeleteAgentDocument}
              user={user}
              intl={intl}
              boDisplay
              foDisplay
              manageableQualities={manageableQualities}
            />
          </div>)
      case INTERVENANT_SIGNATAIRE:
        return (
          <div className='form-intervenant is-validated'>
            <RenderSignatoryForm
              handleSignatoryForm={handleOnChange}
              fieldStatus={fieldStatus}
              depositSignatory={editedIntervenant || {}}
              intl={intl}
              boDisplay
              foDisplay
              manageableQualities={manageableQualities}
            />
          </div>)
      case INTERVENANT_CREATEUR:
        return (
          <div className='form-intervenant is-validated'>
            <RenderCreatorForm
              fieldStatus={fieldStatus}
              depositCreators={editedIntervenant || ''}
              handleCreatorsForm={handleOnChange}
              intl={intl}
              boDisplay
              foDisplay
            />
          </div>)
      default:
        return null
    }
  }

  /**
     * Récupère le titre de la modale correspondant au type
     * d'intervenant modifié
     */
  const getModalTitle = () => {
    switch (typeIntervenant) {
      case INTERVENANT_DEPOSANT:
        return <FormattedMessage id='overview_deposit_depositor_title' />
      case INTERVENANT_CO_DEPOSANT:
        return <FormattedMessage id='overview_deposit_codepositor_title' />
      case INTERVENANT_DESTINATAIRE:
        return <FormattedMessage id='overview_deposit_receiver_title' />
      case INTERVENANT_MANDATAIRE:
        return <FormattedMessage id='overview_deposit_mandatary_title' />
      case INTERVENANT_SIGNATAIRE:
        return <FormattedMessage id='overview_deposit_signatory_title' />
      case INTERVENANT_CREATEUR:
        return <FormattedMessage id='overview_deposit_creators_title' />
      default:
        return ''
    }
  }

  return (
    <ModalComponent
      title={getModalTitle()}
      show={showModal}
      customContent={() => <Form />}
      handleClose={onClose}
      size='xl'
      hideFooter
    />
  )
}

export default injectIntl(ModalEditIntervenant)
