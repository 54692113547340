import React, { FC } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import {
  EventType,
  FieldStatus,
  Transaction,
  SelectOption,
  OfficialDocumentType,
  OfficialDocumentRequest
} from '@inpi-dm/components'
import SearchTransactionsForm from './SearchTransactionsForm'
import TransactionFormManual from './TransactionFormManual'

interface SearchAndManualTransactionsFormProps extends WrappedComponentProps {
  request: OfficialDocumentRequest
  setRequest: (request: OfficialDocumentRequest) => void,
  officialDocumentType: OfficialDocumentType,
  fieldStatus: FieldStatus,
  setFieldStatus: (fieldStatus: FieldStatus) => void,
  setCurrentContributors: (curentContributors: any) => void,
  optionSearch: SelectOption[],
  classNameCol?: string,
  numNat: string|undefined,
  setNumNat: (numNat: string|undefined) => void
}

const SearchAndManualTransactionsForm: FC<SearchAndManualTransactionsFormProps> = ({
  request,
  setRequest,
  officialDocumentType,
  fieldStatus,
  setCurrentContributors,
  optionSearch,
  classNameCol,
  numNat,
  setNumNat
}) => {
  /**
   * Enregistrement des données dans le state à la saisie
   * @param event
   */
  const handleChange = (event: EventType) => {
    const { name, value } = event.target

    let updatedRequest = {}
    updatedRequest = {
      ...request,
      [name]: value
    }
    setRequest(updatedRequest)
  }

  const handleSearchResult = (transaction: Transaction) => {
    setCurrentContributors({
      agent: transaction.agent || null,
      depositor: transaction.depositor || null,
      recipient: transaction.recipient || null
    })
    const updatedRequest: OfficialDocumentRequest = {
      ...request,
      arrivalDate: transaction.arrivalDate,
      copyNum: transaction.numNat
    }
    setRequest(updatedRequest)
  }

  return (
    <>
      <SearchTransactionsForm
        handleChange={handleChange}
        handleSearchResult={handleSearchResult}
        optionSearch={optionSearch}
        officialDocumentType={officialDocumentType}
        numNat={numNat}
        setNumNat={setNumNat}
      />
      <div className='row m-0'>
        <TransactionFormManual
          request={request}
          handleChange={handleChange}
          fieldStatus={fieldStatus}
          classNameCol={classNameCol}
          officialDocumentType={officialDocumentType}
        />
      </div>
    </>
  )
}

export default injectIntl(SearchAndManualTransactionsForm)
