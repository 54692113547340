import http from 'network/http-common'
import store from '../../store/store'
import { toast } from 'react-toastify'
import { createIntl } from 'react-intl'
import Message from '../../constants/Message'
import axios from 'axios'
import {
  Transaction,
  PROROGATION_FIELD,
  DateUtils,
  DOCUMENT_TYPES,
  DepositDocument,
  PROROGATION_DELAY_LABEL_END_GRACE,
  PROROGATION_DELAY_LABEL_START_PROROGATION,
  TYPE_DELAY_AFTER_GRACE,
  TYPE_DELAY_BEFORE_PROROGATION,
  TYPE_DELAY_GRACE,
  TYPE_DELAY_PROROGATION,
  RECORD_PROROGATION_SCOPE_PARTIAL,
  RECORD_PROROGATION_SCOPE_TOTAL,
  ModelDeposit,
  Record
} from '@inpi-dm/components'
import { storeProrogationUpdate } from '../../store/prorogation/prorogationActions'
import { Payment } from '../../interfaces/DepositInterfaces'
import ContentService from '../content/ContentService'
import TransactionService from './TransactionService'
import cloneDeep from 'lodash.clonedeep'
import { INSCRIPTION_REQUESTS_PATCH } from '../../constants/ProrogationConstants'

/**
 * Classe permettant de faire des opérations sur une prorogation
 */
class ProrogationService {
  constructor () {
    this.intl = createIntl({ locale: 'fr', messages: Message })
    this.source = axios.CancelToken.source()
  }

  /**
   * Création d'une nouvelle prorogation
   * @returns Promise<Transaction|null>
   */
  createProrogation = async (): Promise<Transaction|null> => {
    try {
      const prorogationPost: Transaction = await http.post('/api/prorogations')
      const prorogation = { ...prorogationPost, ...store.getState().prorogation }
      store.dispatch(storeProrogationUpdate(prorogation))
      return Promise.resolve(prorogation)
    } catch (error) {
      toast.error(this.intl.formatMessage({ id: 'error_creation_prorogation' }))
      return Promise.reject(error)
    }
  }

  /**
   * Appel serveur pour mettre à jour la prorogation en bdd
   * @param id id de la transaction
   * @param patches liste des modifications
   * @returns Promise<Transaction|null>
   */
  updateProrogation = async (id: string, patches: any): Promise<Transaction|null> => {
    try {
      const prorogation: Transaction = await http.put(`/api/prorogations/${id}`,
        patches
      )
      store.dispatch(storeProrogationUpdate(prorogation))
      return Promise.resolve(prorogation)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Mise à jour des propriétés d'une prorogation correspondant à une étape du formulaire
   * @returns Promise<Transaction|null>
   */
  updateProrogationBDDFromStore = async (propertyNames: string[], inscriptionRequestsDocuments?: DepositDocument[], additionalParts?: DepositDocument[]): Promise<Transaction|null> => {
    const prorogation = store.getState().prorogation
    let areInscriptionRequestsAlreadyUpdated = false
    if (prorogation.id) {
      const patches = {}
      let newDocuments: DepositDocument[] = []
      if (inscriptionRequestsDocuments && inscriptionRequestsDocuments.length > 0) {
        const newDocs = []
        const updatedInscriptionRequests = cloneDeep(prorogation.prorogation.inscriptionRequests)
        for (const [index, inscriptionRequestsDocument] of inscriptionRequestsDocuments.entries()) {
          if (inscriptionRequestsDocument && inscriptionRequestsDocument.file && inscriptionRequestsDocument.needPost) {
            const newDoc = await TransactionService.postDocumentFile(prorogation.id, DOCUMENT_TYPES.DEPOSIT_CORRECTION_SUPPORT, inscriptionRequestsDocument.file)
            newDocs.push(newDoc)
            updatedInscriptionRequests[index].documentInternalName = newDoc.internalName
            delete (updatedInscriptionRequests[index].document)
          }
        }
        newDocuments = newDocuments.concat(newDocs)
        patches.inscriptionRequests = updatedInscriptionRequests
        areInscriptionRequestsAlreadyUpdated = true
      }
      if (additionalParts && additionalParts.length > 0) {
        const newParts = []
        for (const additionalPart of additionalParts) {
          if (additionalPart && additionalPart.file) {
            const newDoc = await TransactionService.postDocumentFile(prorogation.id, DOCUMENT_TYPES.DEPOSIT_CORRECTION_SUPPORT, additionalPart.file)
            newParts.push(newDoc)
          }
        }
        newDocuments = newDocuments.concat(newParts)
      }
      propertyNames.forEach((propertyName) => {
        if (PROROGATION_FIELD.includes(propertyName)) {
          if (propertyName !== INSCRIPTION_REQUESTS_PATCH || (propertyName === INSCRIPTION_REQUESTS_PATCH && !areInscriptionRequestsAlreadyUpdated)) {
            patches[propertyName] = prorogation.prorogation[propertyName]
          }
        } else {
          patches[propertyName] = prorogation[propertyName]
        }
      })
      if (newDocuments.length > 0) {
        const existingDocs = prorogation.documents ? [...prorogation.documents] : []
        patches.documents = existingDocs.concat(newDocuments)
      }
      // On ajoute la référence client saisie par l'utilisateur
      if (prorogation.internalReference) {
        patches.internalReference = prorogation.internalReference
      }
      try {
        const result = await this.updateProrogation(prorogation.id, patches)
        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    } else {
      toast.error(this.intl.formatMessage({ id: 'error_update_prorogation' }))
      return Promise.reject(new Error(this.intl.formatMessage({ id: 'error_update_prorogation' })))
    }
  }

  /**
   * Récupère les label de la porté
   * @param isScopePartial
   */
  getScopeLabel = (isScopePartial?: boolean) : string => {
    switch (isScopePartial) {
      case true:
        return RECORD_PROROGATION_SCOPE_PARTIAL.label
      case false:
        return RECORD_PROROGATION_SCOPE_TOTAL.label
      default:
        return ''
    }
  }

  /**
   * Appel serveur afin de connaitre les information concernant le paiement
   * @param idTransaction
   */
  getPayment = async (idTransaction: string, hasSupplementExcluded: Boolean = false): Promise<Payment|null> => {
    try {
      return await http.get(`/api/prorogations/${idTransaction}/paiement/${hasSupplementExcluded}`)
    } catch (error) {
      return Promise.resolve(null)
    }
  }

  /**
   * Calcul d'une date de fin de validité depuis certain modèle sélectionné
   * @param modelsSelected
   * @param recordSelected
   */
  getValidityEndDate = (modelsSelected: ModelDeposit[], recordSelected: Record|null = null): string => {
    let distantValidityEndDate = null
    modelsSelected.forEach(modelRecord => {
      modelRecord.reproductions.forEach(reproductionRecord => {
        if (!distantValidityEndDate || (reproductionRecord.validityEndDate && reproductionRecord.validityEndDate > distantValidityEndDate)) {
          distantValidityEndDate = reproductionRecord.validityEndDate
        }
      })
    })
    if (!distantValidityEndDate && recordSelected) {
      distantValidityEndDate = recordSelected.validityEndDate
    }
    return DateUtils.formatToEndOfDay(distantValidityEndDate)
  }

  /**
   * Récupère le type de délai concerné par ce titre
   * @param modelsSelected
   * @param recordSelected
   */
  getTypeDelay = async (modelsSelected: ModelDeposit[], recordSelected: Record|null = null): Promise<string | null> => {
    let typeDelay = null

    const validityEndDate = this.getValidityEndDate(modelsSelected, recordSelected)

    // Récupération du type de délais depuis la date de fin de validité
    if (validityEndDate) {
      const delays = await ContentService.getProrogationDelays()
      const nbMonthsProrogationDelay = parseInt(delays.find(delay => delay.label === PROROGATION_DELAY_LABEL_START_PROROGATION)?.value || '0')
      const nbMonthsGraceDelay = parseInt(delays.find(delay => delay.label === PROROGATION_DELAY_LABEL_END_GRACE)?.value || '0')

      const today = DateUtils.now()
      const lastMonth = DateUtils.subtractMonthsAndFormat(today, 1)
      const lastDayOfLastMonth = DateUtils.formatToEndOfMonth(lastMonth)
      const startDayProrogationDelay = DateUtils.addMonthsAndFormat(lastDayOfLastMonth, nbMonthsProrogationDelay)
      const endDayGraceDelay = DateUtils.subtractMonthsAndFormat(lastDayOfLastMonth, nbMonthsGraceDelay)

      if (DateUtils.isBefore(validityEndDate, endDayGraceDelay)) {
        // après le délai de grâce
        typeDelay = TYPE_DELAY_AFTER_GRACE
      } else if (DateUtils.isBefore(validityEndDate, lastDayOfLastMonth)) {
      // dans le délai de grâce
        typeDelay = TYPE_DELAY_GRACE
      } else if (DateUtils.isBefore(validityEndDate, startDayProrogationDelay)) {
      // dans le délai de prorogation
        typeDelay = TYPE_DELAY_PROROGATION
      } else if (DateUtils.isBefore(startDayProrogationDelay, validityEndDate)) {
        // avant délai de prorogation
        typeDelay = TYPE_DELAY_BEFORE_PROROGATION
      }
    }
    return typeDelay
  }

  /**
   * Annule une requête en attente
   */
  cancelRequest = () => {
    this.source.cancel()
    this.source = axios.CancelToken.source()
  }
}

export default new ProrogationService()
