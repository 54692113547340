import React, { FC, MutableRefObject, useState, useEffect } from 'react'
import { NotificationInterface } from '@inpi-dm/components/src/interfaces/DepositInterfaces'
import {
  DateUtils,
  DepositFile,
  EventType,
  FileBrowserField,
  FormatUtils,
  NOTIF_STATUS,
  MAIL_MODELS, SubmitButton, Transaction,
  DOCUMENT_TYPES
} from '@inpi-dm/components'
import { FormattedMessage, useIntl } from 'react-intl'
import { faCreditCard, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NotificationService from '../../../../../services/transaction/NotificationService'
import ModalComponent from '@inpi-dm/components/src/modal/ModalComponent'
import fileDownload from 'js-file-download'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import PriceService from '../../../../../services/content/PriceService'
import {
  NOTIF_TYPE_AGREEMENT_NOTIFICATION,
  NOTIF_TYPE_NO_RESPONSE_EXPECTED,
  NOTIF_STATUS_WAITING_ANSWER
} from '@inpi-dm/components/src/constants/NotificationsConstants'

interface OverviewNotificationsAnswerProps {
  transaction: Transaction,
  initialNotification: NotificationInterface,
  index: number,
  setReload?: ()=>void,
  reproReference?: MutableRefObject<null>,
  setIsEditableRepro?: (isEditableRepro: boolean) => void
}

const OverviewNotificationsAnswer: FC<OverviewNotificationsAnswerProps> = ({
  transaction,
  initialNotification,
  index,
  setReload,
  reproReference,
  setIsEditableRepro
}) => {
  const [answerComment] = useState<string>(initialNotification.answerComment || '')
  const [fieldStatus, setFieldStatus] = useState({})
  const [supportingDocuments, setSupportingDocuments] = useState(initialNotification.supportingDocuments)
  const [notification, setNotification] = useState<NotificationInterface>(initialNotification)
  const [showSendModal, setShowSendModal] = useState(false)
  const [isEditableResponse, setIsEditableResponse] = useState(initialNotification.status === NOTIF_STATUS.NOTIF_STATUS_WAITING_ANSWER)
  const [agreementSent, setAgreementSent] = useState(false)
  const history = useHistory()
  const intl = useIntl()

  /**
   * Enregistrement des documents dans une notification
   * @param event
   */
  const handleAddNotificationDocuments = (event: EventType) => {
    const { value } = event.target
    const newSupportingDocument = {
      name: value.name,
      file: value
    }
    setSupportingDocuments([...supportingDocuments, newSupportingDocument])
  }

  useEffect(() => {
    setSupportingDocuments(initialNotification.supportingDocuments)
  }, [initialNotification])

  /**
   * Supprime un document d'une notification
   * @param event
   */
  const handleDeleteNotificationDocuments = (event: EventType) => {
    setSupportingDocuments(supportingDocuments.filter((supportingDocument) => supportingDocument.name !== event.target.value.name))
  }

  /**
   * Permet de vérifier si la réponse comporte au moins 1 fichier pdf et d'afficher la modal de confirmation si c'est le cas
   */
  const verifAndShowModal = (isAgreement = false) => {
    setFieldStatus({})
    if (notification.type !== NOTIF_TYPE_NO_RESPONSE_EXPECTED && !isAgreement) {
      if (supportingDocuments && supportingDocuments.length > 0 && supportingDocuments.every((supportingDocument) =>
        (supportingDocument.file && supportingDocument.file.type === 'application/pdf') ||
        (supportingDocument.type && supportingDocument.type === DOCUMENT_TYPES.DOCUMENT_TYPE_REGULARIZE_REPRO))) {
        setShowSendModal(true)
      } else {
        setFieldStatus({
          [`documents-${index}`]: <FormattedMessage id='overview_deposit_notification_error_document_format' />
        })
      }
    } else {
      setAgreementSent(true)
      setShowSendModal(true)
    }
  }

  /**
   * Gère l'envoi de la réponse à la notification
   */
  const handleSend = async () => {
    const answerNotification = {
      answerComment
    }
    const documentsToUpload = supportingDocuments?.filter((supportingDocument) => !supportingDocument.internalName) || []
    for (const supportingDocument of documentsToUpload) {
      await NotificationService.uploadNotificationFile(transaction.id, notification.id, supportingDocument.file)
    }
    return NotificationService.answerNotification(transaction.id, notification.id, answerNotification).then((newNotification) => {
      toast.success(intl.formatMessage({ id: 'overview_deposit_notification_success_answer' }))
      setNotification(newNotification)
      setSupportingDocuments(newNotification.supportingDocuments)
      setIsEditableResponse(false)
      setReload && setReload()
      setShowSendModal(false)
    })
  }

  const handleDownload = (document: DepositFile) => {
    const downloadRequest = NotificationService.downloadNotificationFile(transaction.id, notification.id, document.internalName)

    downloadRequest.then(data => fileDownload(data, document.name, document.format))
  }

  const isNotificationClosed = () : boolean => {
    return notification.status === NOTIF_STATUS.NOTIF_STATUS_CLOSED
  }

  const responseIsNeeded = () : boolean => {
    return notification.type !== NOTIF_TYPE_NO_RESPONSE_EXPECTED
  }

  /**
   * Permet de gerer l'affichage du boutton "Bon pour accord" pour les notifications d'offices
   */
  const showAgreementButton = () : boolean => {
    return !isNotificationClosed() && responseIsNeeded() && isEditableResponse && notification.type === NOTIF_TYPE_AGREEMENT_NOTIFICATION
  }

  return notification.codeMailModel === MAIL_MODELS.MAIL_MODEL_PROPOSAL_DIVISION_CODE &&
  notification.status === NOTIF_STATUS.NOTIF_STATUS_WAITING_ANSWER
    ? (
      <div className='mt-4'>
        <button
          className='btn btn-primary'
          onClick={() => history.push(`/depots/${transaction.id}/proposition-division/${notification.id}`)}
        >
          <FormattedMessage id='request_notification_division_btn' />
        </button>
      </div>
    ) : (
      <form className='is-validated'>
        {
          !isNotificationClosed() &&
        notification.paymentAttached &&
        PriceService.getTotalAmount(notification.prices) !== 0 &&
        notification.codeMailModel !== MAIL_MODELS.MAIL_MODEL_PROPOSAL_DIVISION_CODE &&
        (
          <div className='border-bottom mb-3 pb-3'>
            <h3><FormattedMessage id='overview_deposit_notification_payment' /></h3>
            <p className='font-weight-bold'>
              <FormattedMessage id='overview_deposit_notification_amount_to_pay' /> :  {FormatUtils.formatPrice(PriceService.getTotalAmount(notification.prices))} €
            </p>
            {
              notification.paid
                ? <p><FormattedMessage id='overview_deposit_notification_already_paid' /></p>
                : (
                  <div className='d-flex justify-content-center'>
                    <button type='button' className='btn btn-primary mr-2' onClick={() => history.push(`/paiement/${transaction.id}/notifications/${notification.id}`)}>
                      <FormattedMessage id='overview_deposit_notification_payement_button' />
                      <FontAwesomeIcon className='ml-2' icon={faCreditCard} />
                    </button>
                  </div>
                )
            }
          </div>
        )
        }
        <div className='row m-0'>
          <h3><FormattedMessage id='overview_deposit_notification_answer' /></h3>
          {
            !isNotificationClosed() && !isEditableResponse && !agreementSent &&
              <FontAwesomeIcon className='text-primary ml-3' icon={faEdit} onClick={() => setIsEditableResponse(true)} />
          }
        </div>
        {
          isNotificationClosed() && !responseIsNeeded() &&
            <div><FormattedMessage id='overview_deposit_notification_answer_receipt' />{DateUtils.formatDateFr(notification.sendingDate)}</div>
        }
        {
          notification.hasReproRegularization && notification.status === NOTIF_STATUS_WAITING_ANSWER &&
            <SubmitButton
              className='btn-link-primary'
              onClick={() => {
                // on scroll jusqu'à bloc de repro
                if (reproReference && reproReference.current) {
                  window.scrollTo({
                    top: reproReference.current.offsetParent.offsetParent.offsetTop,
                    behavior: 'smooth'
                  })
                  // passage en mode régularisation
                  setIsEditableRepro && setIsEditableRepro(true)
                }
              }}
            >
              <FormattedMessage id='overview_deposit_notification_irregularity_regularisation' />
            </SubmitButton>
        }
        {
          showAgreementButton() &&
            <div>
              <div className='d-flex justify-content-around mt-3'>
                <button type='button' className='btn btn-primary' onClick={() => verifAndShowModal(true)}>
                  <FormattedMessage id='overview_deposit_notification_agreement_title' />
                </button>
              </div>
              <div className='d-flex justify-content-around text-uppercase my-3'>
                <FormattedMessage id='or' />
              </div>
            </div>
        }
        {
          responseIsNeeded() &&
            <FileBrowserField
              inputId={`documents-${index}`}
              label={<FormattedMessage id='overview_deposit_notification_supporting_document_label' />}
              butonLabel={
                <div className='border'>
                  <div className='text-center my-1'>
                    <FormattedMessage id='button_find_file' />
                  </div>
                </div>
              }
              acceptApplication='application/pdf'
              value={supportingDocuments}
              onChange={handleAddNotificationDocuments}
              onDelete={handleDeleteNotificationDocuments}
              handleDownload={handleDownload}
              readonly={isNotificationClosed() || !isEditableResponse}
              fieldStatus={fieldStatus}
              isSavedFilesDeletable={false}
            />
        }
        {!isNotificationClosed() && responseIsNeeded() && isEditableResponse
          ? (
            <div className='d-flex justify-content-around pt-2'>
              <button type='button' className='btn btn-primary' onClick={() => verifAndShowModal()}>
                <FormattedMessage id='overview_deposit_notification_answer_button' />
              </button>
            </div>
          )
          : null}
        {!isNotificationClosed() && !responseIsNeeded()
          ? (
            <div className='d-flex justify-content-around mt-3'>
              <button type='button' className='btn btn-primary' onClick={() => verifAndShowModal()}>
                <FormattedMessage id='overview_deposit_notification_confirm_response_title' />
              </button>
            </div>
          )
          : null}
        <ModalComponent
          title={<FormattedMessage id='overview_deposit_notification_send_button' />}
          customContent={() => <div><FormattedMessage id='overview_deposit_notification_modal_send_message' /> ?</div>}
          handleClose={() => setShowSendModal(false)}
          show={showSendModal}
          onClick={handleSend}
        />
      </form>
    )
}

export default OverviewNotificationsAnswer
