import React, { FC, useState } from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import {
  buildEventType,
  EventType,
  ModalComponent,
  SelectField,
  SubmitButton,
  TextField,
  Record,
  ModalSelectTitle,
  TitleInfos, SelectOption
} from '@inpi-dm/components'
import {
  INFORMATION_FROM_INTERVAL,
  INFORMATION_FROM_MODEL,
  INFORMATION_FROM_NUM_NAT,
  INFORMATION_FROM_PUBLICATION
} from '../../constants/InscriptionConstants'
import ContentService from '../../services/content/ContentService'
import RecordService from '../../services/record/RecordService'
import TitleService from '../../services/transaction/TitleService'

interface SearchRecordsFormProps extends WrappedComponentProps {
  handleSearchResult: (titleInfos: TitleInfos) => void,
  handleChange: (e: EventType) => void,
  setIdRecordFO?: (idRecord: string|undefined) => void,
  optionSearch: SelectOption[],
  allRecordRequested?: boolean
}

const SearchRecordsForm: FC<SearchRecordsFormProps> = ({
  handleSearchResult,
  handleChange,
  setIdRecordFO,
  optionSearch,
  allRecordRequestedForPublication = false
}) => {
  const [informationFrom, setInformationFrom] = useState<string>(INFORMATION_FROM_NUM_NAT)
  const [numNat, setNumNat] = useState()
  const [numModel, setNumModel] = useState()
  const [idPublication, setIdPublication] = useState()
  const [intervalFrom, setIntervalFrom] = useState<string>()
  const [intervalTo, setIntervalTo] = useState<string>()
  const [records, setRecords] = useState<Record[]>()
  const [showSelectTitleModal, setShowSelectTitleModal] = useState(false)

  /**
   * @param idRecordFO: Id du record sélectionné par l'utilisateur FO dans le cas où plusieurs titres correspondent à la recherche
   * @param origin: origin du record sélectionné par l'utilisateur FO dans le cas où plusieurs titres correspondent à la recherche mais qu'ils n'ont pas d'id
   */
  const searchTitle = (idRecordFO?: string, origin?: string) => {
    setIdRecordFO && setIdRecordFO(idRecordFO)
    let model = numModel
    let numnat = numNat
    if (numModel && numModel.indexOf('-') !== -1) {
      const splittedModel: string[] = numModel.split('-')
      numnat = splittedModel[0]
      setNumNat(splittedModel[0])
      model = splittedModel[1]
    }
    const params = {
      numNat: numnat,
      numModel: model,
      idRecordFO: idRecordFO,
      idPublication: idPublication || intervalFrom,
      allRecordRequested: idPublication && allRecordRequestedForPublication,
      origin: origin
    }
    return TitleService.getTitleInfos(params).then((result) => {
      // Si plusieurs records sont liés au numNat, l'utilisateur FO va alors devoir choisir sur lequel se rattacher
      if (result && result.records && result.records.length > 1) {
        setRecords(result.records)
        setShowSelectTitleModal(true)
      } else {
        if (informationFrom === INFORMATION_FROM_INTERVAL) {
          result = getPublicationFromRecordFromIntervals(result)
        }
        handleSearchResult(result)
      }
    })
  }

  /**
   * Récupère les idPublications depuis les intervalles
   */
  const getPublicationFromRecordFromIntervals = (result: TitleInfos|null) => {
    if (result && intervalFrom && intervalTo) {
      const intervalFromInt: number = parseInt(intervalFrom)
      const intervalToInt: number = parseInt(intervalTo)
      let idPublicationToAdd = intervalFromInt
      const idPublicationArray = []

      while (idPublicationToAdd <= intervalToInt) {
        idPublicationArray.push(idPublicationToAdd.toString().padStart(intervalFrom.length, '0'))
        idPublicationToAdd++
      }

      return {
        ...result,
        publications: idPublicationArray.map((id) => ({ idPublication: id }))
      }
    }
  }

  return (
    <>
      <div>
        <div className='row m-0 align-items-end'>
          <SelectField
            inputId='information_from'
            label={<FormattedMessage id='records_inscription_information_from_label' />}
            value={informationFrom}
            classNameFormGroup='col-4'
            onChange={(event: EventType) => {
              const { value } = event.target
              handleChange(buildEventType('publications', []))
              setNumModel(undefined)
              setNumNat(undefined)
              setIdPublication(undefined)
              setInformationFrom(value)
            }}
            options={optionSearch}
          />
          {
            informationFrom === INFORMATION_FROM_NUM_NAT &&
              <div className='col-3'>
                <TextField
                  inputId='numNat'
                  label={<FormattedMessage id='records_inscription_numnat_label' />}
                  value={numNat}
                  onChange={(event: EventType) => setNumNat(event.target.value)}
                />
              </div>
          }
          {
            informationFrom === INFORMATION_FROM_MODEL &&
              <div className='col-3'>
                <TextField
                  inputId='numModel'
                  label={<FormattedMessage id='records_inscription_publication_model_label' />}
                  value={numModel}
                  onChange={(event: EventType) => setNumModel(event.target.value)}
                />
              </div>
          }
          {
            informationFrom === INFORMATION_FROM_INTERVAL &&
              <>
                <div className='col-3'>
                  <TextField
                    inputId='intervalFrom'
                    label={<FormattedMessage id='records_inscription_information_from_interval_from' />}
                    value={intervalFrom}
                    onChange={(event: EventType) => setIntervalFrom(event.target.value)}
                  />
                </div>
                <div className='col-3'>
                  <TextField
                    inputId='intervalTo'
                    label={<FormattedMessage id='records_inscription_information_from_interval_to' />}
                    value={intervalTo}
                    onChange={(event: EventType) => setIntervalTo(event.target.value)}
                  />
                </div>
              </>
          }
          {
            informationFrom === INFORMATION_FROM_PUBLICATION &&
              <div className='col-3'>
                <TextField
                  inputId='idPublication'
                  label={<FormattedMessage id='records_inscription_id_publication_label' />}
                  value={idPublication}
                  onChange={(event: EventType) => setIdPublication(event.target.value)}
                />
              </div>
          }
          <div className='form-group'>
            <SubmitButton
              onClick={searchTitle}
              className='btn-outline-primary'
            >
              <FormattedMessage id='records_inscription_search' />
            </SubmitButton>
          </div>
        </div>
        <div className='mx-3 mt-n2 mb-3'>
          <a
            href='https://data.inpi.fr/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <FormattedMessage id='records_inscription_search_public' />
          </a>
        </div>
      </div>
      <ModalComponent
        size='xl'
        title={<FormattedMessage id='title_modal_select_title' />}
        show={showSelectTitleModal}
        customContent={() => <ModalSelectTitle records={records} selectTitle={searchTitle} setShowModalSelectTitle={setShowSelectTitleModal} getCountries={ContentService.getCountries} getRecord={RecordService.getRecord} />}
        handleClose={() => setShowSelectTitleModal(false)}
        hideFooter
      />
    </>
  )
}

export default injectIntl(SearchRecordsForm)
