import React, { FC } from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import {
  DATE_ISO,
  DateField,
  DateUtils,
  EventType,
  FieldStatus,
  TextField,
  OfficialDocumentType,
  OfficialDocumentRequest,
  COPY_OF_INSCRIPTION
} from '@inpi-dm/components'

interface TransactionFormManualProps extends WrappedComponentProps {
  request: OfficialDocumentRequest,
  handleChange: (event: EventType) => void,
  fieldStatus: FieldStatus,
  classNameCol?: string,
  officialDocumentType: OfficialDocumentType
}

const TransactionFormManual: FC<TransactionFormManualProps> = ({
  request,
  handleChange,
  fieldStatus,
  classNameCol = 'col-12 col-md-3',
  officialDocumentType
}) => {
  return (
    <>
      <div className={classNameCol}>
        <DateField
          inputId='arrivalDate'
          label={<FormattedMessage id='records_inscription_request_date_label' />}
          value={request.arrivalDate ? DateUtils.formateDateToFormat(request.arrivalDate, DATE_ISO) : ''}
          onChange={handleChange}
          fieldStatus={fieldStatus}
          required
        />
      </div>
      <div className={classNameCol}>
        <TextField
          inputId='copyNum'
          label={<FormattedMessage id={officialDocumentType.code === COPY_OF_INSCRIPTION ? 'records_inscription_order_num_label' : 'records_inscription_prorogation_num_label'} />}
          value={request.copyNum}
          onChange={handleChange}
          fieldStatus={fieldStatus}
          required
        />
      </div>
    </>
  )
}

export default injectIntl(TransactionFormManual)
