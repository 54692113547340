import {
  DEPOSIT_FIELD_STATUS_RESET,
  DEPOSIT_FIELD_STATUS_UPDATE,
  DEPOSIT_REMOVE,
  DEPOSIT_UPDATE,
  DepositFieldStatusStoreAction,
  DepositStoreAction
} from './depositStoreTypes'

/**
 * @param deposit Une partie de Deposit
 */
export const storeDepositUpdate = (deposit: any): DepositStoreAction => ({
  type: DEPOSIT_UPDATE,
  deposit
})

export const storeDepositRemove = () => ({
  type: DEPOSIT_REMOVE
})

/**
 * @param fieldStatus Une partie des fieldStatus de DepositFieldStatus
 */
export const storeDepositFieldStatusUpdate = (fieldStatus: any): DepositFieldStatusStoreAction => ({
  type: DEPOSIT_FIELD_STATUS_UPDATE,
  fieldStatus
})

export const storeDepositFieldStatusReset = () => ({
  type: DEPOSIT_FIELD_STATUS_RESET
})
