import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

type ButtonProps = {
  className?: string,
  onClick?: () => any|Promise<any>,
  disabled?: boolean,
  classNameLoader?: string,
  replaceOnLoad?: boolean
};

type ButtonState = {
  isSubmitting: boolean,
}

class SubmitButton extends Component<ButtonProps, ButtonState> {
  _isMounted = false

  constructor (props: ButtonProps) {
    super(props)
    this.state = {
      isSubmitting: false
    }
  }

  static defaultProps = {
    className: '',
    onClick: () => Promise.resolve(),
    disabled: false,
    classNameLoader: ''
  }

  componentDidMount (): void {
    this._isMounted = true
  }

  componentWillUnmount (): void {
    this._isMounted = false
  }

  handleOnClick = (event: any) => {
    const { onClick } = this.props
    event.preventDefault()
    event.stopPropagation()

    if (onClick) {
      this.setIsSubmitting(true)

      Promise.all([onClick()]).then(
        () => this.setIsSubmitting(false)
      ).catch(
        () => this.setIsSubmitting(false)
      )
    }
  }

  setIsSubmitting = (isSubmitting: boolean) => {
    if (this._isMounted) {
      this.setState({ isSubmitting })
    }
  }

  render () {
    const { isSubmitting } = this.state
    const { children, className, disabled, classNameLoader, replaceOnLoad = false } = this.props

    return (
      <button
        className={`btn ${className}`}
        onClick={(e) => {
          e.stopPropagation()
          this.handleOnClick(e)
        }}
        disabled={disabled || isSubmitting}
      >
        {isSubmitting && <FontAwesomeIcon className={`loader mr-1 ${classNameLoader}`} icon={faSpinner} />}
        {(!replaceOnLoad || (replaceOnLoad && !isSubmitting)) && children}
      </button>
    )
  }
}

export default SubmitButton
