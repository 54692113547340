import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage, IntlProvider, WrappedComponentProps } from 'react-intl'
import { Button } from 'react-bootstrap'
import {
  AutocompleteResult,
  CPI,
  DepositIntervenant,
  EventType,
  FieldStatus, isFilled, ManageableQuality,
  ManageableQualitySelectField,
  PersonnePhysiqueFields, SelectButton,
  TextField
} from '..'
import Message from '../constants/Message'
import SubtitleIntervenant from './intervenant/SubtitleIntervenant'
import { PROCESS_OFFICE } from '../constants/InscriptionConstants'

interface SignatoryFormProps extends WrappedComponentProps {
  foDisplay?: boolean | null
  boDisplay? : boolean | null
  depositSignatory: DepositIntervenant
  fieldStatus: FieldStatus,
  handleSignatoryForm?: (signatory: DepositIntervenant) => void | null
  handleChange?: (event: EventType) => void,
  manageableQualities?: ManageableQuality[],
  intervenants?: AutocompleteResult<DepositIntervenant>[] | null,
  handleSelectIntervenant?: (intervenant: DepositIntervenant) => void,
  process?: string
}

const RenderSignatoryForm: FC<SignatoryFormProps> = ({
  handleChange,
  intl,
  depositSignatory,
  fieldStatus,
  foDisplay,
  boDisplay,
  handleSignatoryForm,
  manageableQualities,
  intervenants,
  handleSelectIntervenant,
  process
}) => {
  const [currentSignatory, setCurrentSignatory] = useState(depositSignatory)

  useEffect(() => {
    setCurrentSignatory(depositSignatory)
  }, [depositSignatory])

  /**
   * Enregistrement des modifications du depositeur
   * @param event
   */
  const handleChanges = (event: EventType) => {
    const { name, value } = event.target

    const depositorPatches = {
      ...currentSignatory,
      [name]: value
    }
    setCurrentSignatory(depositorPatches)
    if (foDisplay) {
      handleChange && handleChange(event)
    }
  }

  return (
    <IntlProvider locale='fr' messages={Message}>
      <div className='form-header'>
        <h2 className={`mb-0 ${boDisplay && !foDisplay ? 'h4' : ''}`}>
          <FormattedMessage id='intervenant_onglet_signatory' />
        </h2>
        {foDisplay &&
          <SubtitleIntervenant idText='intervenant_signatory_subtitle' />}
        <div className='col options px-0 my-auto'>
          {isFilled(intervenants) && (
            <SelectButton
              className='btn btn-link text-primary'
              options={intervenants}
              onSelect={handleSelectIntervenant}
            >
              <FormattedMessage id='intervenant_copier_donnees' />
            </SelectButton>
          )}
        </div>
      </div>

      <div className='header-field'>
        <FormattedMessage id='field_personne_type_label' />
      </div>

      <PersonnePhysiqueFields
        onChange={handleChanges}
        fieldStatus={fieldStatus}
        intervenant={currentSignatory}
      />

      <div className='row'>
        <div className='col-12 col-md-4'>
          <TextField
            type='email'
            inputId='email'
            label={<FormattedMessage id='field_email_label' />}
            value={currentSignatory.email || ''}
            onChange={handleChanges}
            fieldStatus={fieldStatus}
            readOnly={!process || process !== PROCESS_OFFICE}
          />
        </div>

        <div className='col-12 col-md-4'>
          <ManageableQualitySelectField
            inputId='manageableQuality'
            fieldStatus={fieldStatus}
            value={currentSignatory.manageableQuality || ''}
            onChange={handleChanges}
            label={<FormattedMessage id='field_manageable_qualities_label' />}
            placeholder={intl.formatMessage({ id: 'placeholder_select' })}
            manageableQualities={manageableQualities}
            required
          />
        </div>
        {
          (currentSignatory.manageableQuality === CPI) &&
            <div className='col-12 col-md-4'>
              <TextField
                inputId='inscriptionNumber'
                label={<FormattedMessage id='field_inscription_number' />}
                fieldStatus={fieldStatus}
                value={currentSignatory.inscriptionNumber}
                onChange={handleChanges}
                required
              />
            </div>
        }
      </div>
      {
        boDisplay &&
          <div className='d-flex justify-content-around mt-2'>
            <Button className='primary' onClick={() => handleSignatoryForm && handleSignatoryForm(currentSignatory)}>
              <FormattedMessage id='common_validate' />
            </Button>
          </div>
      }
    </IntlProvider>
  )
}

export default RenderSignatoryForm
