import store from '../../../../../store/store'
import { containsErrors, IntervenantValidator } from '@inpi-dm/components'
import { storeInscriptionFieldStatusUpdate } from '../../../../../store/inscription/inscriptionActions'
import Message from '../../../../../constants/Message'

class OtherApplicantValidator {
  validateOnChangeStep = () => {
    const inscription = store.getState().inscription
    const otherApplicants = inscription.otherApplicants || []
    let hasNoError = true

    // Il doit y avoir au moins 1 autre partie à l'acte
    let allFieldStatus
    if (otherApplicants.length === 0) {
      const fieldStatus = IntervenantValidator.validateIntervenant({})
      allFieldStatus = [{ ...fieldStatus, notFound: Message.intervenant_warning_other_applicant_needed }]
      hasNoError = hasNoError && !containsErrors(fieldStatus)
    } else {
      allFieldStatus = otherApplicants.map(applicant => {
        const fieldStatus = IntervenantValidator.validateIntervenant(applicant)
        hasNoError = hasNoError && !containsErrors(fieldStatus)
        return fieldStatus
      })
    }

    store.dispatch(storeInscriptionFieldStatusUpdate({
      otherApplicants: allFieldStatus
    }))

    return hasNoError
  }
}

export default new OtherApplicantValidator()
