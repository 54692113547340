import React, { FC, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import ApplicantForm from './ApplicantForm'
import { storeDepositFieldStatusUpdate } from '../../store/deposit/depositActions'
import { storeInscriptionFieldStatusUpdate } from '../../store/inscription/inscriptionActions'
import {
  Deposit,
  DepositIntervenant,
  FieldStatus,
  RenderCoDepositorOrApplicantListForm,
  Country,
  DEPOSIT_TYPE,
  INSCRIPTION_TYPE,
  Transaction,
  containsErrors,
  ErrorField,
  PROROGATION_TYPE,
  INTERVENANT_CO_DEPOSANT,
  INTERVENANT_APPLICANTS,
  INTERVENANT_OTHER_APPLICANTS, INTERVENANT_CO_DECLARANT
} from '@inpi-dm/components'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import InternalReferenceField from '../internalReference/InternalReferenceField'
import ContentService from '../../services/content/ContentService'
import HeaderIntervenant from './HeaderIntervenant'
import { storeProrogationFieldStatusUpdate } from '../../store/prorogation/prorogationActions'
import StoreService from '../../services/StoreService'

interface ApplicantListFormProps extends WrappedComponentProps {
  object : Transaction|Deposit,
  fieldStatus: FieldStatus[],
  procedureType: string,
  applicantList: DepositIntervenant[],
  isOtherApplicant?: boolean,
  defaultIntervenantlist?: DepositIntervenant[]
}

const ApplicantListForm: FC<ApplicantListFormProps> = ({
  children,
  intl,
  object,
  fieldStatus,
  procedureType,
  applicantList,
  isOtherApplicant,
  defaultIntervenantlist = []
}) => {
  const dispatch = useDispatch()
  const [editedIndex, setEditedIndex] = useState(-1)
  const [countries, setCountries] = useState<Country[]>([])
  const [manageableQualities, setManageableQualities] = useState<Country[]>([])
  const [intervenantType, setIntervenantType] = useState(INTERVENANT_APPLICANTS.value)

  useEffect(() => {
    ContentService.getManageableQualities().then(result => {
      setManageableQualities(result)
    })

    ContentService.getCountries().then(result =>
      setCountries(result)
    )

    // sur une inscription, on ouvre le formulaire par défaut si il n'y a pas de demandeur
    if (editedIndex === -1 && procedureType === INSCRIPTION_TYPE.value && applicantList.length === 0) {
      setEditedIndex(0)
    }

    setIntervenantType(getIntervenantType())

    return () => {
      ContentService.cancelRequest()
    }
  }, [isOtherApplicant])

  /**
   * Met a jour le store
   * @param users
   */
  const changeStore = (users: DepositIntervenant[]) => {
    if (procedureType === DEPOSIT_TYPE.value) {
      StoreService.changeStore(procedureType, { coDepositors: users })
    } else if (procedureType === INSCRIPTION_TYPE.value) {
      if (isOtherApplicant) {
        StoreService.changeStore(procedureType, { otherApplicants: users })
      } else {
        StoreService.changeStore(procedureType, { applicants: users })
      }
    } else if (procedureType === PROROGATION_TYPE.value) {
      StoreService.changeStore(procedureType, { coDepositors: users })
    }
  }

  /**
   * Met a jour les erreurs dans le store
   * @param errors
   */
  const changeFieldStore = (errors: FieldStatus[]) => {
    if (procedureType === DEPOSIT_TYPE.value) {
      dispatch(storeDepositFieldStatusUpdate({
        coDepositors: errors
      }))
    } else if (procedureType === INSCRIPTION_TYPE.value) {
      if (isOtherApplicant) {
        dispatch(storeInscriptionFieldStatusUpdate({
          otherApplicants: errors
        }))
      } else {
        dispatch(storeInscriptionFieldStatusUpdate({
          applicants: errors
        }))
      }
    } else if (procedureType === PROROGATION_TYPE.value) {
      dispatch(storeProrogationFieldStatusUpdate({
        coDepositors: errors
      }))
    }
  }

  /**
   * récupère le bon type d'intervenant
   */
  const getIntervenantType = (): string => {
    if (procedureType === DEPOSIT_TYPE.value) {
      return INTERVENANT_CO_DEPOSANT.value
    } else if (procedureType === INSCRIPTION_TYPE.value && !isOtherApplicant) {
      return INTERVENANT_APPLICANTS.value
    } else if (procedureType === INSCRIPTION_TYPE.value && isOtherApplicant) {
      return INTERVENANT_OTHER_APPLICANTS.value
    } else if (procedureType === PROROGATION_TYPE.value) {
      return INTERVENANT_CO_DECLARANT.value
    } else {
      return INTERVENANT_APPLICANTS.value
    }
  }

  const handleDeleteApplicant = (index: number) => {
    const updatedApplicants = [...applicantList]
    updatedApplicants.splice(index, 1)
    changeStore(updatedApplicants)

    const updatedFieldStatus = [...fieldStatus]
    updatedFieldStatus.splice(index, 1)
    changeFieldStore(updatedFieldStatus)
  }

  /**
   * Sauvegarde un co-déposant/demandeur modifié, et retourne sur la liste si
   * aucune erreur n'a été trouvée.
   * Envoyer un co-déposant/demandeur null retourne sur la liste sans modif
   *
   * @param applicant
   * @param error
   */
  const handleSaveApplicant = (applicant?: DepositIntervenant, error?: FieldStatus) => {
    if (applicant) {
      const updatedApplicants = [...applicantList]
      updatedApplicants[editedIndex] = applicant
      changeStore(updatedApplicants)

      const updatedFieldStatus = [...fieldStatus]
      updatedFieldStatus[editedIndex] = error || {}
      changeFieldStore(updatedFieldStatus)
    }

    setEditedIndex(-1)
  }

  return (
    editedIndex === -1
      ? (
        <div className='view'>
          <div>
            <div className='row'>
              <HeaderIntervenant procedureType={procedureType} />
              <InternalReferenceField
                value={object.internalReference}
                procedureType={procedureType}
                className='col-4 mb-4'
              />
            </div>
            <RenderCoDepositorOrApplicantListForm
              depositCoDepositors={applicantList}
              setEditedIndex={setEditedIndex}
              handleDeleteCoDepositor={handleDeleteApplicant}
              children={children}
              foDisplay
              intl={intl}
              countries={countries}
              qualities={manageableQualities}
              procedureType={procedureType}
              isOtherApplicant={isOtherApplicant}
              intervenantType={intervenantType}
            />
          </div>
          <div className='row justify-content-center py-3 mb-4'>
            <button className='col-4 btn btn-outline-primary' onClick={() => setEditedIndex(applicantList.length)}>
              <FormattedMessage id={`intervenant_add_${intervenantType}`} />
            </button>
          </div>
          {
            fieldStatus.length > 0 && fieldStatus[0] && containsErrors(fieldStatus[0]) &&
              <ErrorField message={fieldStatus[0].notFound} />
          }
        </div>
      ) : (
        <ApplicantForm
          object={object}
          initialApplicant={applicantList[editedIndex]}
          intervenantType={getIntervenantType()}
          initialFieldStatus={fieldStatus[editedIndex]}
          onUpdateApplicant={handleSaveApplicant}
          procedureType={procedureType}
          isOtherApplicant={isOtherApplicant}
          defaultIntervenantlist={defaultIntervenantlist}
        />
      )
  )
}

export default injectIntl(ApplicantListForm)
