import React, { FC } from 'react'
import {
  CardBlock, CheckboxField,
  Preview, ReproductionLightDeposit
} from '@inpi-dm/components'
import RecordService from '../../../../services/record/RecordService'

interface OneReproductionSearchSelectionProps {
  reproduction: ReproductionLightDeposit,
  idRecord: string,
  idModel: string
  indexModel: number,
  indexRepro: number,
  checkPublication: (indexModel: number|null, indexReproduction: number|null, value: boolean) => void,
  publicationSelected: string[]
}

const OneReproductionSearchSelection: FC<OneReproductionSearchSelectionProps> = ({
  reproduction,
  idRecord,
  idModel,
  indexModel,
  indexRepro,
  checkPublication,
  publicationSelected
}) => {
  /**
   * Vérifie si la reproduction est check
   */
  const isCheck = () : boolean => {
    for (const publication of reproduction.publications || []) {
      if (publication.idPublication && publicationSelected.includes(publication.idPublication)) {
        return true
      }
    }
    return false
  }

  return (
    <CardBlock
      shadow
      className='p-0 mb-3'
      bodyClassName='p-0 pl-3 pr-3'
    >
      <div className='row m-0 d-flex align-items-center'>
        <div className='col-1'>
          <CheckboxField
            inputId={`selectRepro-${indexModel}-${indexRepro}`}
            label=''
            className='m-auto'
            checked={isCheck()}
            onChange={() => checkPublication(indexModel, indexRepro, !isCheck())}
          />
        </div>
        <div className='col-1 reproduction'>
          <Preview
            className='img-thumbnail m-auto'
            file={() => RecordService.getReproductionPreview(reproduction, idRecord, idModel)}
          />
        </div>
        <div className='col-4 font-weight-bold'>
          <div>
            {`${indexModel + 1}.${indexRepro + 1} ${reproduction.label}`}
          </div>
        </div>
        <div className='col-4'>
          <div>
            {reproduction.publications?.find(publication => publication.idPublication)?.idPublication}
          </div>
        </div>
      </div>
    </CardBlock>
  )
}

export default OneReproductionSearchSelection
