import React, { FC, ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faCheck } from '@fortawesome/free-solid-svg-icons'
import { Badge } from '@inpi-dm/components'

export interface StepListItemContent {
  /** Identifiant de l'étape, doit être unique dans toute la liste */
  id: string,

  /** Contenu à afficher dans la liste d'étapes */
  label: ReactNode,

  /** Contenu à afficher dans le badge de l'étape */
  badge?: ReactNode,

  /** Profondeur de l'étape */
  level?: number,

  /** Permet de savoir si une étape est valide pour afficher l'icône check dans le menu */
  isValidated?: boolean,

  /** Permet de savoir si l'on affiche ou non cet item */
  condition?: boolean
}

export interface StepListItemProps extends StepListItemContent {
  idActive: string,
  index: number,
  onClick?: (index: number) => void,
}

const StepListItem: FC<StepListItemProps> = ({
  id,
  idActive,
  label,
  level = 0,
  badge = '',
  index,
  isValidated = false,
  onClick = () => {}
}) => (
  <div
    className={`list-action cursor-pointer ${idActive.startsWith(id) ? 'active' : ''} level-${level}`}
    onClick={() => onClick(index)}
  >
    <div className='d-flex flex-row align-items-center text-break'>
      {badge && <Badge className={isValidated ? 'text-primary svg' : ''}>{!isValidated ? badge : <FontAwesomeIcon icon={faCheck} />}</Badge>}
      <div>{label}</div>
    </div>
    {idActive === id && (
      <span className='float-right icone'>
        <FontAwesomeIcon icon={faChevronRight} />
      </span>
    )}
  </div>
)

export default StepListItem
