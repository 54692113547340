import React, { FC } from 'react'
import { SubmitButton } from '../index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft, faAngleLeft, faAngleRight, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'

interface PaginationProps {
  nbPage: number,
  currentPage: number,
  onChange: (newPage: number) => any|Promise<any>,
}

const Pagination: FC<PaginationProps> = ({
  nbPage,
  currentPage,
  onChange
}) => (
  <nav>
    <ul className='pagination'>
      <li className='page-item'>
        <SubmitButton
          className='btn-block btn-outline-gris'
          onClick={() => onChange(1)}
          disabled={currentPage === 1}
        >
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </SubmitButton>
      </li>
      <li className='page-item ml-1'>
        <SubmitButton
          className='btn-block btn-outline-gris'
          onClick={() => onChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </SubmitButton>
      </li>
      <li className={`page-item ml-1 ${currentPage > 1 ? '' : 'd-none'}`}>
        <SubmitButton
          className='btn-block btn-outline-gris'
          onClick={() => onChange(currentPage - 1)}
        >
          {currentPage - 1}
        </SubmitButton>
      </li>

      <li className='page-item ml-1'>
        <SubmitButton
          className='btn-block btn-outline-primary'
        >
          {currentPage}
        </SubmitButton>
      </li>

      <li className={`page-item ml-1 ${currentPage < nbPage ? '' : 'd-none'}`}>
        <SubmitButton
          className='btn-block btn-outline-gris'
          onClick={() => onChange(currentPage + 1)}
        >
          {currentPage + 1}
        </SubmitButton>
      </li>

      <li className='page-item ml-1'>
        <SubmitButton
          className='btn-block btn-outline-gris'
          onClick={() => onChange(currentPage + 1)}
          disabled={currentPage === nbPage}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </SubmitButton>
      </li>
      <li className='page-item ml-1'>
        <SubmitButton
          className='btn-block btn-outline-gris'
          onClick={() => onChange(nbPage)}
          disabled={currentPage === nbPage}
        >
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        </SubmitButton>
      </li>
    </ul>
  </nav>
)

export default Pagination
