import React, { FC, ReactNode } from 'react'
import { EventType, FieldStatus } from '../FormInterfaces'
import { buildEventType, DepositFile, ErrorField, isFilled } from '../../index'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface FileBrowserFieldProps {
  inputId:string,
  ulClassName?:string,
  label?:ReactNode,
  className?: string,
  butonLabel:ReactNode,
  value?:File[]|DepositFile[],
  onChange?:(event:EventType) => void,
  onDelete?:(event:EventType, index:number) => void,
  fieldStatus?:FieldStatus | any,
  acceptApplication?:string,
  maxNumberFile?:number,
  required?:boolean,
  handleDownload?:(document:DepositFile) => void,
  readonly?: boolean,
  isSavedFilesDeletable?:boolean,
}

const FileBrowserField:FC<FileBrowserFieldProps> = ({
  inputId,
  ulClassName,
  className = '',
  label,
  butonLabel,
  value = [],
  fieldStatus = {},
  acceptApplication,
  onChange,
  onDelete,
  required = false,
  maxNumberFile,
  handleDownload,
  readonly = false,
  isSavedFilesDeletable = true
}) => {
  const handleChange = (event:EventType) => {
    const { files } = event.target
    onChange && onChange(buildEventType(inputId, files && files[0]))
  }

  const handleDelete = (index:number) => {
    onDelete && onDelete(buildEventType(inputId, value[index]), index)
  }

  return (
    <div className={`form-group file-browser-field ${className}`}>
      {label &&
        <label className='form-label d-block' htmlFor={inputId}>
          {label}
          {required && <span className='text-danger'> *</span>}
        </label>}
      {isFilled(value) &&
        <ul className={`${ulClassName || ''}`}>
          {
            value.map((oneDocument, index) => (
              <li key={index}>
                <div>
                  {handleDownload && oneDocument.internalName
                    ? (
                      <button
                        type='button' className='attribute-btn btn btn-link'
                        onClick={() => handleDownload(oneDocument)}
                      >{oneDocument.name || oneDocument.filename}
                      </button>
                    )
                    : (
                      <span className='d-block one-line-ellipsis' title={oneDocument.name || oneDocument.filename}>
                        {oneDocument.name || oneDocument.filename}
                      </span>
                    )}
                  {(onDelete && !readonly && (isSavedFilesDeletable || !oneDocument.internalName)) && (
                    <span className='icon-delete cursor-pointer text-danger' onClick={() => handleDelete(index)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </span>
                  )}
                </div>
              </li>
            ))
          }
        </ul>}
      {(
        !maxNumberFile || !value ||
      value.length < maxNumberFile) && !readonly && (
        <>
          <input
            type='file'
            className='d-none'
            id={inputId}
            onChange={handleChange}
            accept={acceptApplication}
          />
          <label htmlFor={inputId} className='m-0 d-block'>
            {butonLabel}
          </label>
        </>
      )}

      <ErrorField message={fieldStatus[inputId]} />
    </div>
  )
}

export default FileBrowserField
