import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  BASIC_DATE,
  CardBlock,
  DateUtils, Preview,
  Record, TITLE_PROROGATION_ORIGIN_FR,
  TITLE_PROROGATION_ORIGIN_INT
} from '@inpi-dm/components'
import RecordService from '../../../../services/record/RecordService'

interface OneRecordResultProps {
  record: Record,
  indexRecord: number,
  indexRecordSelected: number,
  setIndexRecordSelected: (index: number) => void
  publicationsId: (string|undefined)[],
  setCurrentContributors: (contributors: any) => void
}

const OneRecordSearchResult: FC<OneRecordResultProps> = ({
  record,
  indexRecord,
  publicationsId,
  indexRecordSelected,
  setIndexRecordSelected,
  setCurrentContributors
}) => {
  /**
   * Récupère la 1ere image de ce record lié à cette recherche
   */
  const getFirstReproduction = async () => {
    for (const model of record.models) {
      for (const reproduction of model.reproductions) {
        for (const publication of reproduction.publications || []) {
          if (publication.idPublication && publicationsId.includes(publication.idPublication)) {
            return RecordService.getReproductionPreview(reproduction, record.id, model.id)
          }
        }
      }
    }
  }

  const getOrigin = () => {
    if (record.country === TITLE_PROROGATION_ORIGIN_FR) {
      return <FormattedMessage id='request_origin_FR' />
    }
    if (record.country === TITLE_PROROGATION_ORIGIN_INT) {
      return <FormattedMessage id='request_origin_INT' />
    }
    return record.country
  }

  return (
    <CardBlock
      shadow
      className='mb-3 no-border'
      bodyClassName={indexRecordSelected === indexRecord ? 'border rounded border-color-primary' : ''}
      onClick={() => {
        setCurrentContributors({
          agent: record?.agent,
          holders: record?.holders,
          recipient: record?.recipient
        })
        setIndexRecordSelected(indexRecord)
      }}
    >
      <div className='row'>
        <div className='col-1 reproduction'>
          <Preview
            className='img-thumbnail m-auto'
            file={() => getFirstReproduction()}
          />
        </div>

        <div className='col-2'>
          <div className='text-gris'>
            <FormattedMessage id={`${record.country === TITLE_PROROGATION_ORIGIN_FR ? 'request_national_number' : 'request_international_number'}`} />
          </div>
          <div className='w-100 form-group'>
            <div>
              {record.numNat}
            </div>
          </div>
        </div>

        <div className='col-2'>
          <div className='text-gris'>
            <FormattedMessage id='request_origin' />
          </div>
          <div className='w-100 form-group'>
            <div>
              {getOrigin()}
            </div>
          </div>
        </div>

        <div className='col-2'>
          <div className='text-gris'>
            <FormattedMessage id='overview_deposit_date' />
          </div>
          <div className='w-100 form-group'>
            <div>
              {DateUtils.formateDateToFormat(record.depositDate, BASIC_DATE)}
            </div>
          </div>
        </div>

        <div className='col-5'>
          <div className='text-gris'>
            <FormattedMessage id='overview_deposit_publications' />
          </div>
          <div className='w-100 form-group'>
            <div className='text-truncate'>
              {record.publicationIds?.filter(publicationId => publicationsId.includes(publicationId)).join(', ')}
            </div>
          </div>
        </div>
      </div>
    </CardBlock>
  )
}

export default OneRecordSearchResult
