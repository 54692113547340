import http from '../../network/http-common'
import { storeDepositUpdate } from '../../store/deposit/depositActions'
import store from '../../store/store'
import {
  DepositPriorityOptions,
  Payment
} from '../../interfaces/DepositInterfaces'
import { toast } from 'react-toastify'
import { createIntl } from 'react-intl'
import Message from '../../constants/Message'
import {
  Deposit,
  Transaction,
  ReproductionLightDeposit,
  ModelDeposit,
  Country,
  DOCUMENT_TYPES,
  DOCUMENT_STATUS
} from '@inpi-dm/components'
import axios from 'axios'
/* global FormData */

/**
 * Classe permettant de faire des opérations sur un dépôt
 */
class DepositService {
  constructor () {
    this.intl = createIntl({ locale: 'fr', messages: Message })
    this.source = axios.CancelToken.source()
  }

  /**
   * Création d'un nouveau dépôt
   * @returns Promise<Deposit|null>
   */
  createDeposit = async (): Promise<Deposit|null> => {
    try {
      const deposit: Transaction = await http.post('/api/deposits')
      const depositStore = { ...deposit.file, idTransaction: deposit.id }
      store.dispatch(storeDepositUpdate(depositStore))
      return Promise.resolve(depositStore)
    } catch (error) {
      toast.error(this.intl.formatMessage({ id: 'error_creation_deposit' }))
      return Promise.reject(error)
    }
  }

  /**
   * Mise à jour des propriétés du dépôt correspondant à une étape du formulaire
   * @returns Promise<Deposit|null>
   */
  updateDepositorBDDFromStore = async (propertyName): Promise<Deposit|null> => {
    const deposit = store.getState().deposit

    if (deposit.idTransaction) {
      const patches = {}
      patches[propertyName] = deposit[propertyName]
      // On ajoute la référence client saisie par l'utilisateur
      if (deposit.internalReference) {
        patches.internalReference = deposit.internalReference
      }

      try {
        const result = await this.updateDeposit(deposit.idTransaction, patches)
        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    } else {
      toast.error(this.intl.formatMessage({ id: 'error_update_deposit' }))
      return Promise.reject(new Error('Erreur lors de la modification du dossier'))
    }
  }

  /**
   * Appel serveur pour mettre à jour le dépôt en bdd
   * @param id id de la transaction de dépôt
   * @param patches liste des modifications
   * @returns Promise<Deposit|null>
   */
  updateDeposit = async (id: string, patches: any): Promise<Deposit|null> => {
    try {
      const deposit: Transaction = await http.put(`/api/deposits/${id}`,
        patches
      )
      const depositStore = { ...deposit.file, idTransaction: deposit.id }
      store.dispatch(storeDepositUpdate(depositStore))
      return Promise.resolve(deposit)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Ajoute un modèle (sans reproduction) dans un dossier
   * @param idTransaction
   * @param model
   */
  addModelToDeposit = async (idTransaction:string, model: ModelDeposit): Promise<Deposit|null> => {
    const modelWithoutRepro = { ...model, reproductions: [] }
    try {
      const deposit: Transaction = await http.post(`/api/deposits/${idTransaction}/models`, modelWithoutRepro)
      return Promise.resolve(deposit)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Modifie un modèle (sans reproduction) dans un dossier
   * @param idTransaction
   * @param model
   */
  updateModelToDeposit = async (idTransaction:string, model: ModelDeposit): Promise<Deposit|null> => {
    const modelWithoutRepro = { ...model, reproductions: [] }
    try {
      const deposit: Transaction = await http.put(`/api/deposits/${idTransaction}/models/${model.id}`, modelWithoutRepro)
      return Promise.resolve(deposit)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Supprime un modèle (sans reproduction) dans un dossier
   * @param idTransaction
   * @param idModel
   */
  deleteModel = async (idTransaction:string, idModel: string): Promise<Deposit|null> => {
    try {
      const deposit: Transaction = await http.delete(`/api/deposits/${idTransaction}/models/${idModel}`)
      return Promise.resolve(deposit)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Ajoute une reproduction à un modèle
   * @param idTransaction
   * @param idModel
   * @param reproduction
   */
  addReproductionToModel = async (idTransaction: string, idModel:string, reproduction: ReproductionLightDeposit) => {
    const formData = new FormData()
    reproduction.color && formData.append('color', reproduction.color)
    reproduction.label && formData.append('label', reproduction.label)
    reproduction.description && formData.append('description', reproduction.description)
    formData.append('position', reproduction.position)
    formData.append('file', reproduction.file)

    try {
      const deposit: Transaction = await http.post(`/api/deposits/${idTransaction}/models/${idModel}/reproductions`, formData)
      return Promise.resolve(deposit)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Ajoute une reproduction lors d'un import de masse
   * @param idTransaction
   * @param model
   */
  addReproductionFromMassiveImport = async (idTransaction: string, model: ModelDeposit) => {
    const formData = new FormData()
    formData.append('position', JSON.stringify(model.position))
    formData.append('designation', JSON.stringify(model.modelDesignation))
    formData.append('file', model.reproductions[0].file, model.reproductions[0].file.name)
    model.reproductions[0].color && formData.append('color', model.reproductions[0].color)
    model.reproductions[0].label && formData.append('label', model.reproductions[0].label)

    try {
      const deposit: Transaction = await http.post(`/api/deposits/${idTransaction}/reproductions`, formData)
      return Promise.resolve(deposit)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Récuperation de la reproduction
   * @param idTransaction
   * @param idModel
   * @param internalName
   */
  getReproduction = async (idTransaction: string, idModel:string, internalName: string) : Promise<any|null> => {
    try {
      const result = await http.get(`/api/deposits/${idTransaction}/models/${idModel}/reproductions/${internalName}`)
      return Promise.resolve(result)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Supprime une reproduction d'un model
   * @param idTransaction
   * @param idModel
   * @param internalName
   */
  deleteReproduction = async (idTransaction: string, idModel:string, internalName: string) : Promise<Transaction|null> => {
    try {
      const result = await http.delete(`/api/deposits/${idTransaction}/models/${idModel}/reproductions/${internalName}`)
      return Promise.resolve(result)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Modifie les informations de la reproduction
   * @param idTransaction
   * @param idModel
   * @param internalName
   * @param indexRepro
   * @param reproduction
   */
  updateReproduction = async (idTransaction: string, idModel:string, internalName: string, indexRepro: string, reproduction: ReproductionLightDeposit) : Promise<any|null> => {
    const reproductionWithPosition = { ...reproduction, position: indexRepro }
    try {
      const result = await http.put(`/api/deposits/${idTransaction}/models/${idModel}/reproductions/${internalName}`, reproductionWithPosition)
      return Promise.resolve(result)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Effectue une demande de retrait sur une reproduction
   *
   * @param idTransaction Identifiant de la transaction
   * @param idModel Identifiant du modèle
   * @param internalName Nom interne du fichier de reproduction
   */
  requestWithdrawRepoduction = async (idTransaction: string, idModel:string, internalName: string) : Promise<Transaction> => {
    try {
      return await http.put(`/api/deposits/${idTransaction}/models/${idModel}/reproductions/${internalName}/withdraw`)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  resizeFile = async (formData : FormData) : Promise<any|null> => {
    try {
      const result = await http.post('/api/deposits/reproductions/bopi', formData)
      return Promise.resolve(result)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  resizeDocument = async (idTransaction: string, internalName: string) : Promise<any|null> => {
    try {
      const result = await http.post(`/api/deposits/${idTransaction}/documents/${internalName}/bopi`)
      return Promise.resolve(result)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Appel serveur afin de connaitre les information concernant le paiement
   * @param idTransaction
   */
  getPayment = async (idTransaction: string): Promise<Payment|null> => {
    try {
      return await http.get(`/api/deposits/${idTransaction}/paiement`)
    } catch (error) {
      return Promise.resolve(null)
    }
  }

  /**
   * Récupère le paiement simulé des reproductions de la transaction paramétrées.
   */
  getReproductionsSimulatedPayment = async (idTransaction: string, internalNames: string[]): Promise<Payment[]|null> => {
    try {
      return await http.get(`/api/deposits/${idTransaction}/payments/reproductions/simulation`, {
        params: {
          internalNames: internalNames
        }
      })
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Créée une priorité sur une transaction
   * @param idTransaction
   * @param priority
   */
  postDepositPriority = async (idTransaction: number, priority: DepositPriorityOptions): Promise<Transaction> => {
    try {
      return await http.post(`/api/deposits/${idTransaction}/priorities`, priority)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Met à jour une priorité sur une transaction
   * @param idTransaction
   * @param idPriority
   * @param priority
   */
  updateDepositPriority = async (idTransaction: number, idPriority: string, priority: DepositPriorityOptions): Promise<Transaction> => {
    try {
      return await http.put(`/api/deposits/${idTransaction}/priorities/${idPriority}`, priority)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Supprime une priorité sur une transaction
   * @param idTransaction
   * @param idPriority
   */
  deleteDepositPriority = async (idTransaction: number, idPriority: string): Promise<Transaction> => {
    try {
      return await http.delete(`/api/deposits/${idTransaction}/priorities/${idPriority}`)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Upload un fichier de priorité pour le dépôt courant
   * @param idTransaction
   * @param idPriority Id de la priorité contenant le fichier
   * @param file Le fichier à uploader
   */
  uploadDepositPriorityFile = async (idTransaction: string, idPriority: string, file: File): Promise<Transaction> => {
    try {
      const formData = new FormData()
      formData.append('file', file)

      return await http.post(`/api/deposits/${idTransaction}/priorities/${idPriority}/files`, formData)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  getModelPriorityName = (priorityId: string, countries: Country[], priorities?: DepositPriorityOptions[]) => {
    if (priorities) {
      const priority = priorities.find(element => element.id === priorityId)
      const matchedCountry = priority ? countries.find(country => country.code === priority.countryOrOrganization) : ''
      return matchedCountry && priority ? `${matchedCountry.label} - ${priority.originalDepositNumber}` : ''
    } else {
      return ''
    }
  }

  /**
   * Récupère la couleur du header d'un bloc selon le type du dépôt
   */
  getColor = (depositType?: string): string => {
    switch (depositType) {
      case 'DEPOT_SIMPLIFIE':
        return 'bg-simplified'
      case 'DEPOT_AJOURNE':
        return 'bg-ajourned'
      case 'DEPOT_CLASSIQUE':
        return 'bg-classique'
      default:
        return ''
    }
  }

  /**
   * Récupération du nom du dernier recap PDF généré
   * @param deposit
   * @returns {string}
   */
  getLastRecapPDFName = (deposit: Transaction): string => {
    const recaps = deposit.documents.filter(document => document.type === 'Récapitulatif(s)').sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    return recaps && recaps[0] ? recaps[0].internalName : `${Message.overview_deposit_pdf_title}.pdf`
  }

  /**
   * Récupération du document de pouvoir d'un agent
   * @param deposit
   * @returns {string}
   */
  getAgentDocument = (deposit: Transaction): string => {
    return deposit.documents?.find(document => document.type === DOCUMENT_TYPES.DEPOSIT_AGENT_DOCUMENT && document.status !== DOCUMENT_STATUS.REJECTED)
  }

  /**
   * Permet de récupérer la date de publication
   * @param deposit
   */
  getLastPublicationDate = (deposit: Transaction): Date|null => {
    const publications = deposit.publications
    let lastPublicationDate: Date|null = null
    publications && publications.forEach((publication) => {
      const tmpPublicationDate = new Date(publication.date)
      if (publication.date && (!lastPublicationDate || tmpPublicationDate > lastPublicationDate)) {
        lastPublicationDate = tmpPublicationDate
      }
    })
    return lastPublicationDate
  }

  /**
   * Annule une requête en attente
   */
  cancelRequest = () => {
    this.source.cancel()
    this.source = axios.CancelToken.source()
  }
}

export default new DepositService()
