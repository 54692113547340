import { Address, isCodePostalValid, isFilled } from '@inpi-dm/components'
import Message from '../../../constants/Message'
import { FRANCE, messageIsFilled } from '../../..'

class AddressFieldsValidator {
  validateCodePostal = (address: Address) => {
    const { zipCode = '', country = '' } = address

    if (!isFilled(zipCode) && country === FRANCE) {
      return Message.required_field
    } else if (!isCodePostalValid(zipCode) && country === FRANCE) {
      return Message.error_code_postal_format
    }
    return ''
  }

  validateMandatory = (address: Address) => {
    return {
      label: messageIsFilled(address.label),
      zipCode: this.validateCodePostal(address),
      city: messageIsFilled(address.city),
      country: messageIsFilled(address.country)
    }
  }
}

export default new AddressFieldsValidator()
