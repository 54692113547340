import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import {
  CardBlock,
  containsErrors,
  EventType,
  FieldStatus,
  SubmitButton,
  TextField
} from '@inpi-dm/components'
import ETSAccountService from '../../services/transaction/ETSAccountService'
import { ETSAccountNavInterface, ETSAccountRequestCredentials } from '../../interfaces/ETSAccountInterface'
import ETSAccountRequestCredentialsValidator from './validator/ETSAccountRequestCredentialsValidator'

export interface ETSAccountCredentialsInterface extends WrappedComponentProps{
    ETSAccountRequestNavItem: ETSAccountNavInterface
}

const ETSAccountCredentials = (props: ETSAccountCredentialsInterface) => {
  const [redirect, setRedirect] = useState<string|null>(null)
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>({})
  const [credentials, setCredentials] = useState<ETSAccountRequestCredentials>(
    {
      code: '',
      password: ''
    }
  )

  const {
    ETSAccountRequestNavItem
  } = props

  /**
     * Validation du formulaire de rattachement
     */
  const handleSubmit = async () => {
    const fieldStatus = ETSAccountRequestCredentialsValidator.validateForm(credentials)
    if (containsErrors(fieldStatus)) {
      return setFieldStatus(fieldStatus)
    } else {
      setFieldStatus({})

      return ETSAccountService.postETSAccountRequestCredentials(credentials).then(() => {
        toast.success(props.intl.formatMessage({ id: 'ets_account_request_success_request' }))
        setRedirect('/')
      })
    }
  }

  return (
    <>
      {redirect &&
        <Redirect to={{
          pathname: redirect
        }}
        />}
      <div className='d-flex mt-4 justify-content-center'>
        <CardBlock
          className='col-6 p-0'
          header={ETSAccountRequestNavItem.label}
          shadow
        >
          <div className='is-validated row w-100'>
            <div className='col-12'>
              <TextField
                inputId='code'
                label={<FormattedMessage id='ets_account_request_credentials_code' />}
                fieldStatus={fieldStatus}
                value={credentials.code}
                onChange={(event: EventType) => setCredentials({ ...credentials, code: event.target.value })}
                required
              />
            </div>
            <div className='col-12'>
              <TextField
                inputId='password'
                label={<FormattedMessage id='ets_account_request_credentials_password' />}
                fieldStatus={fieldStatus}
                value={credentials.password}
                onChange={(event: EventType) => setCredentials({ ...credentials, password: event.target.value })}
                required
              />
            </div>
            <div className='w-100 row'>
              <div className='col'>
                <div className='d-flex justify-content-end'>
                  <SubmitButton
                    children={<FormattedMessage id='button_confirm' />}
                    className='btn-primary'
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </CardBlock>
      </div>
    </>
  )
}

export default injectIntl(ETSAccountCredentials)
