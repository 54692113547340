import store from '../../../../../store/store'
import { storeDepositFieldStatusUpdate } from '../../../../../store/deposit/depositActions'
import { containsErrors, IntervenantValidator } from '@inpi-dm/components'

class RecipientValidator {
  validateOnChangeStep = () => {
    const deposit = store.getState().deposit

    const fieldStatus = IntervenantValidator.validateRecipient(deposit.recipient)
    store.dispatch(storeDepositFieldStatusUpdate({
      recipient: fieldStatus
    }))

    return !containsErrors(fieldStatus)
  }
}

export default new RecipientValidator()
