import {
  INSCRIPTION_FIELD_STATUS_RESET,
  INSCRIPTION_FIELD_STATUS_UPDATE,
  INSCRIPTION_REMOVE,
  INSCRIPTION_UPDATE,
  InscriptionFieldStatusStoreAction,
  InscriptionStoreAction
} from './inscriptionStoreTypes'
import { DEFAULT_INTERVENANT, Transaction } from '@inpi-dm/components'
import { InscriptionFieldStatus } from '../../interfaces/InscriptionInterfaces'

const initialInscriptionState: Transaction = {
  recipient: DEFAULT_INTERVENANT
}

/* Contient les erreurs liées au formulaire d'inscription */
const initialInscriptionStatusState: InscriptionFieldStatus = {}

export function reducerInscriptionState (state = initialInscriptionState, action: InscriptionStoreAction): Transaction {
  switch (action.type) {
    case INSCRIPTION_UPDATE:
      return {
        ...state,
        ...action.inscription,
        inscription: {
          ...state.inscription,
          ...action.inscription.inscription
        }
      }
    case INSCRIPTION_REMOVE:
      return initialInscriptionState
    default:
      return state
  }
}

export function reducerInscriptionFieldStatusState (state = initialInscriptionStatusState, action: InscriptionFieldStatusStoreAction): InscriptionFieldStatus {
  switch (action.type) {
    case INSCRIPTION_FIELD_STATUS_UPDATE:
      return {
        ...state,
        ...action.fieldStatus
      }
    case INSCRIPTION_FIELD_STATUS_RESET:
      return initialInscriptionStatusState
    default:
      return state
  }
}
