import Message from './Message'

export const TABLE_TITLE_SIREN_DENOMINATION = [
  {
    label: Message.intervenant_table_label_siren
  },
  {
    label: Message.intervenant_table_label_denomination
  }
]
