import {
  APPEAL_TYPE,
  Deposit,
  DEPOSIT_TYPE,
  INSCRIPTION_TYPE, OFFICIAL_DOCUMENTS_TYPE,
  PROROGATION_TYPE,
  Transaction
} from '@inpi-dm/components'
import { storeDepositUpdate } from '../store/deposit/depositActions'
import { storeInscriptionUpdate } from '../store/inscription/inscriptionActions'
import { storeProrogationUpdate } from '../store/prorogation/prorogationActions'
import store from '../store/store'
import { storeAppealUpdate } from '../store/appeal/appealActions'
import { storeOfficialDocumentUpdate } from '../store/officialDocument/officialDocumentActions'

class StoreService {
    /**
     * Met a jour le store
     * @param procedureType
     * @param object
     */
    changeStore = (procedureType: string, object: Transaction|Deposit) => {
      if (procedureType === DEPOSIT_TYPE.value) {
        store.dispatch(storeDepositUpdate(object))
      } else if (procedureType === INSCRIPTION_TYPE.value) {
        store.dispatch(storeInscriptionUpdate(object))
      } else if (procedureType === PROROGATION_TYPE.value) {
        store.dispatch(storeProrogationUpdate(object))
      } else if (procedureType === APPEAL_TYPE.value) {
        store.dispatch(storeAppealUpdate(object))
      } else if (procedureType === OFFICIAL_DOCUMENTS_TYPE.value) {
        store.dispatch(storeOfficialDocumentUpdate(object))
      }
    }
}

export default new StoreService()
