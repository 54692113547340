import http from '../../network/http-common'
import { toast } from 'react-toastify'
import { Configuration } from 'interfaces/ConfigurationInterface'

class ConfigurationService {
    /**
     * Récupère le délai maximum pour l'affichage d'une transaction en Brouillon
     */
    getDraftDisplayPeriod = async (): Promise<Configuration | null> => {
      try {
        return await http.get('/api/configurations/draft-display-period')
      } catch (error) {
        toast.error(error.message)
        return null
      }
    }
}

export default new ConfigurationService()
