import React, { FC, useState } from 'react'
import { InpiAccount, PaymentParameters } from '../../interfaces/DepositInterfaces'
import {
  CardBlock,
  containsErrors,
  EventType,
  FieldStatus,
  messageIsFilled,
  SubmitButton,
  TextField
} from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'
import { PAYMENT_METHOD } from '../../constants/DepositConstants'

interface InpiAccountFormProps {
    onSubmit: (payment: PaymentParameters) => void
}

const InpiAccountForm: FC<InpiAccountFormProps> = ({
  onSubmit
}) => {
  const [account, setAccount] = useState<InpiAccount>({})
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>({})

  const handleChange = (event: EventType) => {
    const { name, value } = event.target

    const updatedAccount = {
      ...account,
      [name]: value
    }

    setAccount(updatedAccount)
  }

  const handleSubmit = () => {
    const updatedFieldStatus = {
      login: messageIsFilled(account.login),
      password: messageIsFilled(account.password)
    }
    setFieldStatus(updatedFieldStatus)

    if (!containsErrors(updatedFieldStatus)) {
      return onSubmit({
        method: PAYMENT_METHOD.INPI_ACCOUNT,
        inpiAccount: account
      })
    }
    return null
  }

  return (
    <CardBlock
      bodyClassName='is-validated py-3'
      header={<FormattedMessage id='payment_form_account_title' />}
      shadow
    >
      <div className='row justify-content-center h-100'>
        <div className='col-12'>
          <TextField
            inputId='login'
            label={<FormattedMessage id='payment_form_account' />}
            value={account.login || ''}
            onChange={handleChange}
            fieldStatus={fieldStatus}
            required
          />
        </div>
        <div className='col-12'>
          <TextField
            type='password'
            inputId='password'
            label={<FormattedMessage id='login_password_label' />}
            value={account.password || ''}
            onChange={handleChange}
            fieldStatus={fieldStatus}
            required
          />
        </div>
        <div className='d-flex align-self-end justify-content-center'>
          <SubmitButton onClick={handleSubmit} className='btn-block btn-primary w-25'>
            <FormattedMessage id='payment_access_button' />
          </SubmitButton>
        </div>
      </div>
    </CardBlock>
  )
}

export default InpiAccountForm
