import React, { FC, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

interface OverviewTableRowProps {
    label: ReactNode,
    amount: number,
    quantity: number,
}

const OverviewTableRow : FC<OverviewTableRowProps> = ({ label, amount, quantity }) => {
  /**
     * Formatte les prix qui sont en centimes
     * @param number
     * @returns {number}
     */
  const formatAmount = (number: number) => {
    return number ? (number / 100) : 0
  }

  return (
    <tr className='d-flex'>
      <td className='d-none d-md-table-cell col-6'>{label}</td>
      <td className='d-none d-md-table-cell col'>{formatAmount(amount)}€</td>
      <td className='d-none d-md-table-cell col'>{quantity}</td>
      <td className='d-none d-md-table-cell col'>{formatAmount(amount * quantity)}€</td>
      <td className='d-block d-md-none'>
        <h4>{label}</h4>
        <ul>
          <li><FormattedMessage id='overview_deposit_rate_title' />{` : ${formatAmount(amount)}€`}</li>
          <li><FormattedMessage id='overview_deposit_quantity_title' />{` : ${quantity}`}</li>
          <li><FormattedMessage id='overview_deposit_total_title' />{` : ${formatAmount(amount * quantity)}€`}</li>
        </ul>
      </td>
    </tr>

  )
}

export default OverviewTableRow
