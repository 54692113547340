import Message from './Message'

export const INSCRIPTION_CORRECTION = {
  code: 'INSCRIPTION_CORRECTION',
  title: 'select_type_inscription_correction_title',
  resume: 'select_type_inscription_correction_resume'
}

export const INSCRIPTION_OWNERSHIP = {
  code: 'INSCRIPTION_OWNERSHIP',
  title: 'select_type_inscription_ownership_title',
  resume: 'select_type_inscription_ownership_resume'
}

export const INSCRIPTION_DISCLAIMER = {
  code: 'INSCRIPTION_DISCLAIMER',
  title: 'select_type_inscription_disclaimer_title',
  resume: 'select_type_inscription_disclaimer_resume'
}

export const INSCRIPTION_OFFICE = {
  code: 'INSCRIPTION_OFFICE',
  title: 'select_type_inscription_office_title'
}

export const INSCRIPTION_TYPES = [
  INSCRIPTION_CORRECTION,
  INSCRIPTION_OWNERSHIP
]

export const DISCLAIMER_PARTIAL_TYPE = {
  code: 'RP',
  value: 'partial'
}

export const DISCLAIMER_TOTAL_TYPE = {
  code: 'RT',
  value: 'total'
}

export const SUBDIVISION_TABLE_TITLES = [
  {
    label: 'Numnat',
    className: 'text-gris text-left border-0 w-15'
  }, {
    label: 'Titulaires',
    className: 'text-gris text-left border-0'
  }, {
    label: 'Numéros de publication',
    className: 'text-gris text-left border-0'
  }, {
    label: '',
    className: 'border-0 w-7'
  }
]

export const INSCRIPTION_TYPES_OPTIONS = [
  {
    value: INSCRIPTION_CORRECTION.code,
    label: Message.select_type_inscription_correction_title
  },
  {
    value: INSCRIPTION_OWNERSHIP.code,
    label: Message.select_type_inscription_ownership_title
  },
  {
    value: INSCRIPTION_DISCLAIMER.code,
    label: Message.select_type_inscription_disclaimer_title
  }
]

export const INSCRIPTION_FIELD = [
  'titles',
  'natureCodes',
  'changeText',
  'byText',
  'isAppealOrDecommission',
  'isProrogation',
  'inscriptionSuite',
  'comment',
  'isDepositPartRectified',
  'isActPartRectified',
  'actPartRectifiedDate',
  'actPartRectifiedNumber',
  'isProrogationDeclaration',
  'prorogationDeclarationNumber'
]

export const STATUS_INSCRIPTION = {
  /** Brouillon client */
  DRAFT_CLIENT: 'draft_client_inscription',
  /** Brouillon */
  DRAFT: 'draft_inscription',
  /** A attribuer */
  TO_ATTRIBUTE: 'to_attribute_inscription',
  /** A examiner */
  TO_REVIEW: 'to_review_inscription',
  /** En validation */
  IN_VALIDATION: 'in_validation_inscription',
  /** Suspendu */
  SUSPENDED: 'suspended_inscription',
  /** Suspendu */
  IN_CLAIM: 'in_claim_inscription',
  /** A publier */
  TO_PUBLISH: 'to_publish_inscription',
  /** A annuler */
  TO_CANCEL: 'to_cancel_inscription',
  /** Annulé */
  CANCELLED: 'cancelled_inscription',
  /** A rejeter */
  TO_REJECT: 'to_reject_inscription',
  /** A rapporter */
  TO_REPORT: 'to_report_inscription',
  /** Publié */
  PUBLISHED: 'published_inscription',
  /** Rejeté */
  REJECTED: 'rejected_inscription',
  /** Retiré */
  WITHDRAWN: 'withdrawn_inscription',
  /** Bermudes */
  BERMUDA: 'bermuda_inscription',
  /** Errata */
  ERRATA: 'errata_inscription'
}

export const STATUS_INSCRIPTION_FINAL = [
  STATUS_INSCRIPTION.PUBLISHED,
  STATUS_INSCRIPTION.REJECTED,
  STATUS_INSCRIPTION.WITHDRAWN,
  STATUS_INSCRIPTION.BERMUDA,
  STATUS_INSCRIPTION.CANCELLED
]

export const PROCESS_NORMAL = 'TN'
export const PROCESS_EXPEDITED = 'TA'
export const PROCESS_OFFICE = 'OF'

export const PROCESS_OPTIONS = [
  {
    value: PROCESS_NORMAL,
    label: Message.select_process_inscription_tn
  },
  {
    value: PROCESS_EXPEDITED,
    label: Message.select_process_inscription_ta
  }
]

export const TITLE_INSCRIPTION_ORIGIN_FR = 'FR'
export const TITLE_INSCRIPTION_ORIGIN_INTER = 'INT-FR'

export const DEFAULT_TITLE_INSCRIPTION = {
  origin: TITLE_INSCRIPTION_ORIGIN_FR
}

export const RECORD_INSCRIPTION_ORIGIN = [{
  label: Message.records_inscription_origin_fr,
  value: TITLE_INSCRIPTION_ORIGIN_FR
}, {
  label: Message.records_inscription_origin_int_fr,
  value: TITLE_INSCRIPTION_ORIGIN_INTER
}]
