import React, { FC } from 'react'
import { CheckboxField, DateUtils, PROCEDURE_TYPE, Transaction } from '@inpi-dm/components'

interface ToAssignTableBodyProps {
    list: Transaction[],
    onSelect: (idRequest: string) => void,
    checkedList: string[]
}

const ToAssignTableBody: FC<ToAssignTableBodyProps> = ({
  list,
  onSelect,
  checkedList
}) => {
  return (
    <tbody>
      {list.map((elem: Transaction) => {
        const procedureType = PROCEDURE_TYPE.find(procedure => procedure.value === elem.procedureType)
        return (
          <tr key={elem.id}>
            <td
              onClick={(e) => {
                e.stopPropagation()
              }}
              className='border-0 table-checkbox'
            >
              <CheckboxField
                inputId={`check-${elem.id}`}
                label=''
                onChange={() => onSelect(elem.id)}
                checked={checkedList.includes(elem.id)}
              />
            </td>
            <td className='border-0 text-center'>{DateUtils.formatDateFr(elem.lastUpdate)}</td>
            <td className='border-0 text-center'>{elem.numNat}</td>
            <td className='border-0 text-center'>{elem.internalReference}</td>
            <td className='border-0 d-none d-sm-table-cell procedure-type'>
              <div className={`text-truncate text-white text-center ${procedureType.value}`}>
                <span className='px-2 rounded'>
                  {procedureType.label}
                </span>
              </div>
            </td>
          </tr>
        )
      })}
    </tbody>
  )
}

export default ToAssignTableBody
