import React, { FC, useEffect, useState } from 'react'
import { SelectField, SelectOption, FieldProps, FieldStatus } from '@inpi-dm/components'
import { ManageableQuality } from '../..'

interface ManageableQualitySelectFieldProps extends FieldProps {
    required?: boolean,
    fieldStatus?: FieldStatus | any,
    manageableQualities?: ManageableQuality[],
    getManageableQualities?: () => Promise<void | ManageableQuality[]>
}

const ManageableQualitySelectField: FC<ManageableQualitySelectFieldProps> = (props) => {
  const [qualities, setQualities] = useState<SelectOption[]>([])

  useEffect(() => {
    if (!props.manageableQualities) {
      let unmounted = false
      props.getManageableQualities && props.getManageableQualities()
        .then(result => {
          if (!unmounted) {
            setQualities(
              result.map(quality => ({
                value: quality.code,
                label: quality.label
              }))
            )
          }
        })
      return () => {
        unmounted = true
      }
    } else {
      setQualities(props.manageableQualities.map(quality => ({
        value: quality.code,
        label: quality.label
      })))
    }
  }, [props.manageableQualities])

  return (
    <SelectField
      {...props}
      options={qualities}
    />
  )
}

export default ManageableQualitySelectField
