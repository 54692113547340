import React, { FunctionComponent } from 'react'
import ErrorField from '../errors/ErrorField'
import { EventType, FieldProps } from '../FormInterfaces'

export interface DateFieldProps extends FieldProps {
    type?: string,
    required?: boolean,
    minDate?: string
    maxDate?: string
}

const DateField: FunctionComponent<DateFieldProps> = ({
  className = '',
  classNameFormGroup = '',
  inputId,
  minDate,
  maxDate,
  label,
  fieldStatus = {},
  value = '',
  type = 'date',
  onChange,
  placeholder = '',
  disabled = false,
  readOnly = false,
  required = false
}) => {
  const handleChange = (event: EventType) => {
    // Permet de retourner null à la place d'une string vide pour un champ date
    // -> Le serializer côté symfony interprète une string vide par une nouvelle instance de DateTime (soit la date d'aujourd'hui)
    // alors qu'on veut une date null si la date est vide
    if (event.target.value === '') {
      onChange && onChange({ target: { value: null, name: event.target.name } })
      return
    }
    onChange && onChange(event)
  }
  return (
    <div className={`form-group ${classNameFormGroup}`}>
      {label &&
        <label className='form-label' htmlFor={inputId}>
          {label}
          {required && <span className='text-danger'> *</span>}
        </label>}
      <input
        type={type}
        id={inputId}
        name={inputId}
        className={`form-control ${className}`}
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
        min={minDate}
        max={maxDate}
      />

      <ErrorField message={fieldStatus[inputId]} />
    </div>
  )
}

export default DateField
