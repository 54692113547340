import http from 'network/http-common'
import store from '../../store/store'
import { toast } from 'react-toastify'
import { createIntl } from 'react-intl'
import Message from '../../constants/Message'
import axios from 'axios'
import {
  DOCUMENT_TYPES,
  OfficialDocumentPatch,
  OFFICIAL_DOCUMENT_FIELD,
  Transaction
} from '@inpi-dm/components'
import { storeOfficialDocumentUpdate } from '../../store/officialDocument/officialDocumentActions'
import { Payment } from '../../interfaces/DepositInterfaces'
import TransactionService from './TransactionService'

/**
 * Classe permettant de faire des opérations sur un relevé de déchéance
 */
class OfficialDocumentService {
  constructor () {
    this.intl = createIntl({ locale: 'fr', messages: Message })
    this.source = axios.CancelToken.source()
  }

  /**
   * Création d'un nouveau document officiel
   * @returns Promise<Transaction|null>
   */
  createOfficialDocument = async (): Promise<Transaction|null> => {
    try {
      const officialDocumentPost: Transaction = await http.post('/api/official-documents')
      const officialDocument = { ...officialDocumentPost, ...store.getState().officialDocument }
      store.dispatch(storeOfficialDocumentUpdate(officialDocument))
      return Promise.resolve(officialDocument)
    } catch (error) {
      toast.error(this.intl.formatMessage({ id: 'error_creation_officialDocument' }))
      return Promise.reject(error)
    }
  }

  /**
   * Appel serveur pour mettre à jour le document officiel en bdd
   * @param id id de la transaction
   * @param patches liste des modifications
   * @returns Promise<Transaction|null>
   */
  updateOfficialDocument = async (id: string, patches: any): Promise<Transaction|null> => {
    try {
      const officialDocument : Transaction = await http.put(`/api/official-documents/${id}`,
        patches
      )
      store.dispatch(storeOfficialDocumentUpdate(officialDocument))
      return Promise.resolve(officialDocument)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Mise à jour des propriétés d'un document officiel correspondant à une étape du formulaire
   * @returns Promise<Transaction|null>
   */
  updateOfficialDocumentBDDFromStore = async (propertyNames: string[]): Promise<Transaction|null> => {
    const officialDocument: Transaction = store.getState().officialDocument
    if (officialDocument.id) {
      const patches: OfficialDocumentPatch = {}
      propertyNames.forEach((propertyName: string) => {
        if (OFFICIAL_DOCUMENT_FIELD.includes(propertyName)) {
          patches[propertyName] = officialDocument.officialDocument[propertyName]
        } else {
          patches[propertyName] = officialDocument[propertyName]
        }
      })
      // On ajoute la référence client saisie par l'utilisateur
      if (officialDocument.internalReference) {
        patches.internalReference = officialDocument.internalReference
      }
      if (officialDocument.documents && officialDocument.documents.length > 0 && officialDocument.documents[0].file) {
        await TransactionService.postDocumentFile(officialDocument.id, DOCUMENT_TYPES.DEPOSIT_CORRECTION_SUPPORT, officialDocument.documents[0].file)
      }
      try {
        const result = await this.updateOfficialDocument(officialDocument.id, patches)
        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    } else {
      toast.error(this.intl.formatMessage({ id: 'error_update_officialDocument' }))
      return Promise.reject(new Error(this.intl.formatMessage({ id: 'error_update_officialDocument' })))
    }
  }

  /**
   * Appel serveur afin de connaitre les information concernant le paiement
   * @param idTransaction
   * @param isAdministration
   */
  getPayment = async (idTransaction: string, isAdministration?: boolean): Promise<Payment|null> => {
    try {
      return await http.get(`/api/official-documents/${idTransaction}/paiement`, {
        params: {
          isAdministration: isAdministration
        }
      })
    } catch (error) {
      return Promise.resolve(null)
    }
  }

  /**
   * Annule une requête en attente
   */
  cancelRequest = () => {
    this.source.cancel()
    this.source = axios.CancelToken.source()
  }
}

export default new OfficialDocumentService()
