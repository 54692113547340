import React, { FC } from 'react'
import {
  DateUtils,
  Transaction,
  PROCEDURE_TYPE,
  DEPOSIT_TYPE,
  INSCRIPTION_TYPE,
  PROROGATION_TYPE,
  CheckboxField, APPEAL_TYPE, OFFICIAL_DOCUMENTS_TYPE
} from '@inpi-dm/components'
import { useHistory } from 'react-router'
import { RequestType } from '../../interfaces/RequestInterfaces'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'
import DepositService from '../../services/transaction/DepositService'
import { REQUEST_TYPES } from '../../constants/RequestConstants'
import InscriptionService from '../../services/transaction/InscriptionService'

type RequestListTableBodyProps = {
  requestType?: RequestType
  list: Transaction[],
  onDelete?: (id: string) => void,
  selectedLines: string[]
  selectOneElem: (index: string) => void
};

const RequestListTableBody : FC<RequestListTableBodyProps> = ({
  list,
  requestType,
  onDelete,
  selectedLines,
  selectOneElem
}) => {
  const history = useHistory()

  const goTo = (requestType: RequestType, procedureType: string, elem: Transaction) => {
    if (procedureType === INSCRIPTION_TYPE) {
      history.push(`/liste-demandes/${requestType.urlParam}/inscription/${elem.id}`)
    } else if (procedureType === PROROGATION_TYPE) {
      history.push(`/liste-demandes/${requestType.urlParam}/prorogation/${elem.id}`)
    } else if (procedureType === APPEAL_TYPE) {
      history.push(`/liste-demandes/${requestType.urlParam}/releves-de-decheance/${elem.id}`)
    } else if (procedureType === OFFICIAL_DOCUMENTS_TYPE) {
      history.push(`/liste-demandes/${requestType.urlParam}/documents-officiels/${elem.id}`)
    } else {
      history.push(`/liste-demandes/${requestType.urlParam}/demande/${elem.id}`)
    }
  }

  /**
   * Récupération de la notification envoyées la plus récemment
   * @param transaction
   * @returns NotificationInterface
   */
  const findLastNotif = (transaction) => {
    return transaction.notifications.sort((a, b) => {
      if (!b.sendingDate) {
        return a
      }
      if (!a.sendingDate) {
        return b
      }
      return new Date(b.sendingDate) - new Date(a.sendingDate)
    })[0]
  }

  /**
   * Savoir si la date d'échéance de la notification la plus récente est dépassée
   * @param transaction
   * @returns {boolean}
   */
  const isExpired = (transaction) => {
    const lastNotif = findLastNotif(transaction)

    return new Date(lastNotif.deadline) < new Date()
  }

  /**
   * Savoir quel type de délai est associé (à noter que le 6 mois est paramétrable dans l'administration des délais de prorogation) :
   * -  (bandeau verticale vert) délai de prorogation : entre 6 mois + 1 jour avant la fin du mois de la date de fin de validité du dépôt et la fin du mois de la date de fin de validité
   *          Ex pour un titre à fin de validité le 10/07/2021 : prorogeable entre le 01/02/2021 et le 31/07/2021
   * - (bandeau verticale orange) délai de grâce : 6 mois à partir du mois suivant la fin de validité du titre
   *          Ex pour un titre à fin de validité le 10/07/2021 : prorogeable entre le 01/08/2021 et le 31/01/2021
   * @param validityEndDateStr
   */
  const isProrogationPeriod = (validityEndDateStr: string) => {
    const today = DateUtils.now()
    const lastMonth = DateUtils.subtractMonthsAndFormat(today, 1)
    const lastDayOfLastMonth = DateUtils.formatToEndOfMonth(lastMonth)
    const validityEndDate = DateUtils.formatToEndOfDay(validityEndDateStr)

    return DateUtils.isBefore(lastDayOfLastMonth, validityEndDate)
  }

  return (
    <tbody>
      {list.map((elem) => {
        const procedureType = PROCEDURE_TYPE.find(procedure => procedure.value === elem.procedureType)
        if (!requestType) {
          requestType = REQUEST_TYPES.find((status) => elem.status && status.statusFiltres.includes(elem.status))
        }

        return (
          <tr
            key={elem.id} className={requestType.isNotif && isExpired(elem) ? 'expired-notifications cursor-pointer' : 'cursor-pointer'}
            onClick={() => requestType && goTo(requestType, procedureType, elem)}
          >
            {
              requestType && requestType.isDocument
                ? (
                  <>
                    <td className='border-0 text-center'>
                      {DateUtils.formatDateFr(elem.arrivalDate)}
                    </td>
                    <td className='border-0 text-center'>
                      {elem.numNat}
                    </td>
                    <td className='border-0 text-center'>
                      {elem.internalReference}
                    </td>
                    <td className='border-0 text-center'>
                      {elem.depositor?.lastname && `${elem.depositor.lastname}`}
                      {elem.depositor?.firstname && ` ${elem.depositor.firstname}`}
                      {elem.depositor?.companyName && `${elem.depositor.lastname && elem.depositor.firstname ? ' / ' : ''}${elem.depositor.companyName}`}
                    </td>
                    <td className='border-0 text-center'>
                      {/* ToDo: Statut à spécifier plus tard */}
                      -
                    </td>
                  </>
                )
                : (
                  <>
                    {requestType?.isTitle &&
                      <>
                        {requestType?.isForProrogation && (
                          <td className={`p-0 m-0 border-0 ${isProrogationPeriod(elem.validityEndDate) ? 'bg-success' : 'bg-warning'}`} />
                        )}
                        <td
                          className='table-checkbox border-0 text-center'
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                        >
                          <CheckboxField
                            inputId={`check-${elem.id}`}
                            label=''
                            onChange={() => { selectOneElem(elem.id) }}
                            checked={selectedLines.includes(elem.id)}
                          />
                        </td>
                      </>}
                    {requestType.isNotif &&
                      <td className='border-0 text-center'>
                        {elem.notifications && DateUtils.formatDateFr(findLastNotif(elem).deadline)}
                      </td>}
                    {
                      !requestType?.isTitle && !requestType?.isNotif &&
                        <td className='border-0 text-center procedure-type '>
                          <div className={`text-truncate ${procedureType?.value}`}>
                            <span className='rounded px-2'>{procedureType?.label}</span>
                          </div>
                        </td>
                    }
                    {requestType.isNotif
                      ? <td className='border-0 text-center'>{elem.notifications && DateUtils.formatDateFr(findLastNotif(elem).sendingDate)}</td>
                      : !requestType?.isTitle || requestType?.isForProrogation
                        ? <td className='border-0 text-center'>{elem.file?.depositDate ? DateUtils.formatDateFr(elem.file.depositDate) : DateUtils.formatDateFr(elem.lastUpdate)}</td>
                        : <td className='border-0 text-center'>{DateUtils.formatDateFr(DepositService.getLastPublicationDate(elem))}</td>}
                    {
                      !requestType?.isDraft &&
                        <td className='border-0 text-center'>{elem.numNat}</td>
                    }
                    <td className='border-0 text-center'>{elem.internalReference}</td>
                    <td className='border-0 text-center'>
                      {
                        elem.procedureType === DEPOSIT_TYPE.value &&
                          <>
                            {elem.file?.depositor?.lastname && `${elem.file?.depositor.lastname}`}
                            {elem.file?.depositor?.firstname && ` ${elem.file?.depositor.firstname}`}
                            {elem.file.depositor?.companyName && `${elem.file.depositor?.lastname && elem.file.depositor?.firstname ? ' / ' : ''}${elem.file.depositor.companyName}`}
                          </>
                      }
                      {
                        elem.procedureType === INSCRIPTION_TYPE.value && elem.applicants && elem.applicants.length > 0 &&
                          <>
                            {elem.applicants[0].lastname && `${elem.applicants[0].lastname}`}
                            {elem.applicants[0].firstname && ` ${elem.applicants[0].firstname}`}
                            {elem.applicants[0].companyName && `${elem.applicants[0].lastname && elem.applicants[0].firstname ? ' / ' : ''}${elem.applicants[0].companyName}`}
                          </>
                      }
                      {
                        (elem.procedureType === PROROGATION_TYPE.value || elem.procedureType === APPEAL_TYPE.value || elem.procedureType === OFFICIAL_DOCUMENTS_TYPE.value) && elem.depositor &&
                          <>
                            {elem.depositor.lastname && `${elem.depositor.lastname}`}
                            {elem.depositor.firstname && ` ${elem.depositor.firstname}`}
                            {elem.depositor.companyName && `${elem.depositor.lastname && elem.depositor.firstname ? ' / ' : ''}${elem.depositor.companyName}`}
                          </>
                      }
                      {
                        elem.procedureType == null && elem.holders && elem.holders.length > 0 &&
                          <>
                            {
                              elem.holders[0].companyName
                                ? elem.holders[0].companyName
                                : `${elem.holders[0].lastname && elem.holders[0].lastname} ${elem.holders[0].firstname && elem.holders[0].firstname}`
                            }
                          </>
                      }
                    </td>
                    {!requestType?.isTitle &&
                      <>
                        <td className='border-0 d-none d-sm-table-cell deposit-type'>
                          <div className='text-truncate text-white text-center'>
                            {
                              elem.procedureType === DEPOSIT_TYPE.value &&
                                <span className={`px-2 rounded ${DepositService.getColor(elem.file?.depositType)}`}>
                                  {
                                    elem.file?.depositType &&
                                      <FormattedMessage id={`deposit_type_${elem.file?.depositType.toLocaleLowerCase()}`} />
                                  }
                                </span>
                            }
                            {
                              elem.procedureType === INSCRIPTION_TYPE.value &&
                                <span className={`px-2 rounded ${InscriptionService.getColor(elem.subProcedureType)}`}>
                                  {
                                    elem.subProcedureType &&
                                      <FormattedMessage id={`select_type_${elem.subProcedureType.toLocaleLowerCase()}`} />
                                  }
                                </span>
                            }
                            {requestType.isNotif && elem.procedureType !== DEPOSIT_TYPE.value && elem.procedureType !== INSCRIPTION_TYPE.value &&
                              <div className='procedure-type'>
                                <div className={procedureType?.value}>
                                  <span className='rounded px-2'>{procedureType?.label}</span>
                                </div>
                              </div>}
                          </div>
                        </td>
                      </>}
                    {
                    requestType?.isForProrogation &&
                      <td className='border-0 text-center'>
                        {DateUtils.formatDateFr(elem.validityEndDate)}
                      </td>
                    }
                    {onDelete && requestType?.isDraft &&
                      <td className='border-0 text-center text-secondary action-button-request'>
                        <FontAwesomeIcon
                          icon={faTrash} onClick={(e) => {
                            e.stopPropagation()
                            onDelete(elem.id)
                          }}
                        />
                      </td>}
                  </>
                )
            }
          </tr>
        )
      })}
    </tbody>
  )
}

export default RequestListTableBody
