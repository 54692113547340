import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { Badge, CardBlock } from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

interface DashboardItemProps {
  url: string,
  label: string,
  badge?: number,
}

const DashboardItem: FC<DashboardItemProps> = ({
  url,
  label,
  badge = 0
}) => (
  <div className='col-12 col-sm-6 col-md-4 col-lg-3 my-3'>
    <Link to={url} className='text-decoration-none'>
      <CardBlock shadow className='cursor-pointer theme-secondary'>
        <div className='item d-flex justify-content-between'>
          <span className='text-body my-auto'>
            <FormattedMessage id={label} />
          </span>
          <Badge className={`my-auto ml-1 ${badge > 0 ? 'bg-secondary' : 'bg-light'}`}>
            {badge === -1
              ? <FontAwesomeIcon className='loader mr-1' icon={faSpinner} />
              : <span>{badge}</span>}
          </Badge>
        </div>
      </CardBlock>
    </Link>
  </div>
)

export default DashboardItem
