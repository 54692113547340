import { combineReducers } from 'redux'
import {
  reducerDepositFieldStatusState,
  reducerDepositState
} from './deposit/depositReducers'
import { reduceUserState } from './user/userReducers'
import { reducerContentState } from './content/contentReducers'
import {
  reducerInscriptionFieldStatusState,
  reducerInscriptionState
} from './inscription/inscriptionReducers'
import { reducerContributorsTitleState } from './contributorsTitle/contributorsTitleReducers'
import { reducerProrogationFieldStatusState, reducerProrogationState } from './prorogation/prorogationReducers'
import { reducerAppealFieldStatusState, reducerAppealState } from './appeal/appealReducers'
import {
  reducerOfficialDocumentFieldStatusState,
  reducerOfficialDocumentState
} from './officialDocument/officialDocumentReducers'

const reducers = combineReducers({
  deposit: reducerDepositState,
  depositFieldStatus: reducerDepositFieldStatusState,
  user: reduceUserState,
  adminContent: reducerContentState,
  inscription: reducerInscriptionState,
  inscriptionFieldStatus: reducerInscriptionFieldStatusState,
  prorogation: reducerProrogationState,
  prorogationFieldStatus: reducerProrogationFieldStatusState,
  appeal: reducerAppealState,
  appealFieldStatus: reducerAppealFieldStatusState,
  officialDocument: reducerOfficialDocumentState,
  officialDocumentFieldStatus: reducerOfficialDocumentFieldStatusState,
  contributorsTitle: reducerContributorsTitleState
})

export default reducers
