import http from 'network/http-common'
import store from '../../store/store'
import { toast } from 'react-toastify'
import { createIntl } from 'react-intl'
import Message from '../../constants/Message'
import axios from 'axios'
import { storeInscriptionUpdate } from '../../store/inscription/inscriptionActions'
import {
  INSCRIPTION_CORRECTION,
  INSCRIPTION_DISCLAIMER,
  INSCRIPTION_FIELD,
  INSCRIPTION_OWNERSHIP,
  NatureCode,
  Payment,
  Title,
  Transaction,
  TitleFromCsv, DepositDocument
} from '@inpi-dm/components'
import DocumentValidator from '../../component/inscription/form/documents/validator/DocumentValidator'
import TransactionService from './TransactionService'

/**
 * Classe permettant de faire des opérations sur une inscription
 */
class InscriptionService {
  constructor () {
    this.intl = createIntl({ locale: 'fr', messages: Message })
    this.source = axios.CancelToken.source()
  }

  /**
   * Création d'une nouvelle inscription dépôt
   * @returns Promise<Transaction|null>
   */
  createInscription = async (): Promise<Transaction|null> => {
    try {
      const inscription: Transaction = await http.post('/api/inscriptions')
      store.dispatch(storeInscriptionUpdate(inscription))
      return Promise.resolve(inscription)
    } catch (error) {
      toast.error(this.intl.formatMessage({ id: 'error_creation_inscription' }))
      return Promise.reject(error)
    }
  }

  /**
   * Appel serveur pour mettre à jour l'inscription en bdd
   * @param id id de la transaction
   * @param patches liste des modifications
   * @returns Promise<Transaction|null>
   */
  updateInscription = async (id: string, patches: any): Promise<Transaction|null> => {
    try {
      const inscription: Transaction = await http.put(`/api/inscriptions/${id}`,
        patches
      )
      store.dispatch(storeInscriptionUpdate(inscription))
      return Promise.resolve(inscription)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Permet de récupérer seulement le code des codes natures
   * @param natureCodes
   */
  retrieveOnlyNatureCodes = (natureCodes: NatureCode[]) => {
    const onlyNatureCodes: string[] = []
    natureCodes.forEach((natureCode) => onlyNatureCodes.push(natureCode.code))
    return onlyNatureCodes
  }

  /**
   * Mise à jour des propriétés d'une inscription correspondant à une étape du formulaire
   * @returns Promise<Transaction|null>
   */
  updateInscriptionBDDFromStore = async (propertyNames: string[], inscriptionProofDocuments?: DepositDocument[][]|undefined): Promise<Transaction|null> => {
    const inscription = store.getState().inscription
    if (inscriptionProofDocuments) {
      await this.updateDocument(inscriptionProofDocuments, inscription)
    }
    if (inscription.id) {
      const patches = {}
      propertyNames.forEach((propertyName) => {
        if (INSCRIPTION_FIELD.includes(propertyName)) {
          patches[propertyName] = inscription.inscription[propertyName]
        } else {
          patches[propertyName] = inscription[propertyName]
        }
      })
      // On ajoute la référence client saisie par l'utilisateur
      if (inscription.internalReference) {
        patches.internalReference = inscription.internalReference
      }
      try {
        const result = await this.updateInscription(inscription.id, patches)
        // On récupère la transaction avec toute les modifications apportées
        TransactionService.getTransaction(inscription.id).then(transaction => {
          store.dispatch(storeInscriptionUpdate({ documents: transaction.documents }))
        })
        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    } else {
      toast.error(this.intl.formatMessage({ id: 'error_update_inscription' }))
      return Promise.reject(new Error(this.intl.formatMessage({ id: 'error_update_inscription' })))
    }
  }

  /**
   * Permet de mettre à jour les documents de l'inscription
   * @param inscriptionProofDocuments
   * @param transaction
   */
  updateDocument = async (inscriptionProofDocuments: DepositDocument[][], transaction: Transaction) => {
    // Vérification des champs obligatoires
    if (DocumentValidator.validateDocuments(inscriptionProofDocuments.flat(), transaction.subProcedureType === INSCRIPTION_OWNERSHIP.code)) {
      for (const document of inscriptionProofDocuments.flat()) {
        if (document.toDelete) {
          await TransactionService.deleteDocumentFile(transaction.id, document.internalName)
        } else if (document.toEdit || document.internalName) {
          await TransactionService.updateDocument(transaction.id, document.internalName, document)
        } else if (document.file) {
          await TransactionService.postDocumentFile(transaction.id, document.type, document.file, undefined, undefined, document.communicability, document.description)
        }
      }
    }
  }

  /**
   * Récupère les titres contenue dans un Csv
   * @param formData
   */
  getTitlesFromCsv = async (formData: FormData) : Promise<Title[]> => {
    try {
      const response : TitleFromCsv = await http.post('/api/inscriptions/titles', formData)
      if (response.errors && response.errors.length > 0) {
        return Promise.reject(response)
      } else {
        return Promise.resolve(response.titles)
      }
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Récupère les label de l'origine
   * @param origin
   */
  getOriginLabel = (origin: string) : string => {
    switch (origin) {
      case 'FR':
        return Message.records_inscription_origin_fr
      case 'INT-FR':
        return Message.records_inscription_origin_int_fr
      default:
        return ''
    }
  }

  /**
   * Récupère la couleur du header d'un bloc selon le type du dépôt
   */
  getColor = (inscriptionType?: string): string => {
    switch (inscriptionType) {
      case INSCRIPTION_DISCLAIMER.code:
        return 'bg-simplified'
      case INSCRIPTION_CORRECTION.code:
        return 'bg-ajourned'
      case INSCRIPTION_OWNERSHIP.code:
        return 'bg-classique'
      default:
        return 'bg-classique'
    }
  }

  /**
   * Récupération des informations concernant le paiement
   * @param idTransaction
   */
  getPayment = async (idTransaction: string): Promise<Payment|null> => {
    try {
      return await http.get(`/api/inscriptions/${idTransaction}/paiement`)
    } catch (error) {
      return Promise.resolve(null)
    }
  }

  /**
   * Récupération des informations concernant le paiement dans le cas d'un passage en traitement accéléré
   * @param idTransaction
   */
  getPaymentTransformation = async (idTransaction: string): Promise<Payment|null> => {
    try {
      return await http.get(`/api/inscriptions/${idTransaction}/paiement/transformation`)
    } catch (error) {
      return Promise.resolve(null)
    }
  }

  /**
   * Création d'un csv à partir d'une liste de titres
   * @param titles
   */
  createCSVFromTitles = async (titles: Title[]): Promise<Payment|null> => {
    try {
      return await http.post('/api/inscriptions/titles/file', titles)
    } catch (error) {
      return Promise.resolve(null)
    }
  }

  /**
   * Annule une requête en attente
   */
  cancelRequest = () => {
    this.source.cancel()
    this.source = axios.CancelToken.source()
  }
}

export default new InscriptionService()
