import React, { FC, ReactElement } from 'react'
import { CardBlock } from '@inpi-dm/components'
import CountItems from '../home/dashboard/CountItems'

interface RequestsProps {
    currentIdBlock ?: string
    children ?: ReactElement
}

const Requests : FC<RequestsProps> = ({
  currentIdBlock,
  children
}) => {
  return (
    <div className='row my-4'>
      <div className='col-md-3'>
        <CardBlock>
          <div className='row request'>
            <CountItems currentIdBlock={currentIdBlock} />
          </div>
        </CardBlock>
      </div>
      <div className='col-md-9'>
        <CardBlock>
          {children}
        </CardBlock>
      </div>
    </div>
  )
}

export default Requests
