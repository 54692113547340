import store from '../../../../../store/store'
import { containsErrors, IntervenantValidator } from '@inpi-dm/components'
import { storeInscriptionFieldStatusUpdate } from '../../../../../store/inscription/inscriptionActions'

class SignatoryValidator {
  validateOnChangeStep = () => {
    const inscription = store.getState().inscription

    const fieldStatus = IntervenantValidator.validateSignatory(inscription.signatory)
    store.dispatch(storeInscriptionFieldStatusUpdate({
      signatory: fieldStatus
    }))

    return !containsErrors(fieldStatus)
  }
}

export default new SignatoryValidator()
