import React, { FC, useEffect, useState, ReactNode } from 'react'
import {
  BASIC_DATE,
  CardBlock, containsErrors, DateUtils,
  DepositDocument,
  DepositDocumentType, Transaction, DOCUMENT_COMMUNICABILITIES, DOCUMENT_TYPES,
  EventType, FieldStatus,
  FileBrowserField,
  ModalComponent, RequestListTable,
  SelectField, SubmitButton, DEPOSIT_TYPE, INSCRIPTION_TYPE
} from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'
import { DOCUMENTS_TABLE_TITLES } from '../../constants/RequestConstants'
import TransactionService from '../../services/transaction/TransactionService'
import moment from 'moment'
import { toast } from 'react-toastify'
import Message from '../../constants/Message'
import DocumentService from '../../services/transaction/DocumentService'
import { faDownload, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import fileDownload from 'js-file-download'
import DocumentBlockValidator from './validator/DocumentBlockValidator'
import { DOCUMENT_COMMUNICABLE_OPTIONS } from 'constants/DocumentConstants'

export const APPLICATION_PDF = 'application/pdf'

interface DocumentBlockProps {
  transaction: Transaction,
  setReload?: () => void,
  blockTitle: ReactNode,
  updateStore?: (documents: DepositDocument[]) => void
}

const DocumentBlock: FC<DocumentBlockProps> = ({
  transaction,
  setReload,
  blockTitle,
  updateStore
}) => {
  const [showAddDocument, setShowAddDocument] = useState(false)
  const [documentToAdd, setDocumentToAdd] = useState<DepositDocument>()
  const [documentToAddList, setDocumentToAddList] = useState<DepositDocument[]>([])
  const [typeDocumentList, setTypeDocumentList] = useState<DepositDocumentType[]>()
  const [documents, setDocuments] = useState<DepositDocument[]>([])
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>()

  const [authorizedDocumentMimeType, setAuthorizedDocumentMimeType] = useState(APPLICATION_PDF)

  useEffect(() => {
    // Récupération des types de documents
    TransactionService.getDocumentTypes(transaction.procedureType).then((result) => {
      // Si cela fait plus de 3 mois que le dépot existe, alors pas le droit d'ajouter un document de type priorites
      if (transaction.procedureType === DEPOSIT_TYPE.value && moment(new Date(transaction.file.depositDate)).diff(moment(), 'month') > 3) {
        setTypeDocumentList(result.filter(element => element.typeName !== DOCUMENT_TYPES.DOCUMENTS_PRIORITES))
      } else {
        setTypeDocumentList(result)
      }
    })

    return () => {
      TransactionService.cancelRequest()
    }
  }, [transaction.procedureType])

  useEffect(() => {
    // Récuération de tous les documents du dépots
    const newDocuments = DocumentService.getDocumentsFromDeposit(transaction)
    setDocuments(newDocuments)
    return () => {
      TransactionService.cancelRequest()
    }
  }, [transaction])

  /**
   * En fct du type de document choisis préselectionne le bon format de document
   */
  useEffect(() => {
    if (documentToAdd?.type === DOCUMENT_TYPES.DOCUMENT_TYPE_REGULARIZE_REPRO && authorizedDocumentMimeType === APPLICATION_PDF) {
      setAuthorizedDocumentMimeType('image/png, image/jpg, image/jpeg')
    } else if (documentToAdd?.type !== DOCUMENT_TYPES.DOCUMENT_TYPE_REGULARIZE_REPRO && authorizedDocumentMimeType !== APPLICATION_PDF) {
      setAuthorizedDocumentMimeType(APPLICATION_PDF)
    }
  }, [documentToAdd?.type])

  /**
   * Modifie le document
   * @param event
   */
  const handleChange = (event: EventType) => {
    const { name, value } = event.target

    setDocumentToAdd(oldDocument => ({
      ...oldDocument,
      [name]: value
    }))
  }

  /**
   * Ajoute dans la liste local des documents a ajouter le document selectionné
   */
  const handleAddOneDocument = () => {
    const newFieldStatus = DocumentBlockValidator.handleAddOneDocument(documentToAdd)
    setFieldStatus(newFieldStatus)
    if (!containsErrors(newFieldStatus)) {
      setDocumentToAddList((allDocument) => [...allDocument, documentToAdd])
      setShowAddDocument(false)
      setDocumentToAdd(undefined)
    }
  }

  /**
   * Supprime dans la liste local le document de la liste à ajouter
   * @param indexDocumentToDelete
   */
  const handleDeleteOneDocument = (indexDocumentToDelete: number) => {
    setDocumentToAddList(oldDocuments => oldDocuments.filter((element, index) => (index !== indexDocumentToDelete)))
  }

  /**
   * Ajoute les documents (les uns àa la suite des autres)
   */
  const uploadDocument = async () => {
    for (const [index, elementToAdd] of documentToAddList.entries()) {
      await TransactionService.postDocumentFile(transaction.id, elementToAdd.type, elementToAdd.file, undefined, index, elementToAdd.communicability)
    }
  }

  /**
   * Post tous les document à ajouter
   */
  const handleAddAllDocument = () => {
    return uploadDocument().then(() => {
      setReload && setReload()
      if (updateStore && transaction.id) {
        TransactionService.getTransaction(transaction.id).then((r: Transaction) => updateStore(r.documents || []))
      }
      setDocumentToAddList([])
      toast.success(Message.request_add_document_success)
    })
  }

  /**
   * Contenus de la modal
   */
  const addDocumentContent = () => (
    <div className='is-validated'>
      <FileBrowserField
        label={<FormattedMessage id='request_add_document_label' />}
        inputId='file'
        acceptApplication={authorizedDocumentMimeType}
        value={documentToAdd?.file ? [documentToAdd.file] : []}
        onChange={handleChange}
        maxNumberFile={1}
        butonLabel={
          <div className='form-control placeholder'>
            <FormattedMessage id='button_find_file' />
          </div>
        }
        required
        fieldStatus={fieldStatus}
      />
      <SelectField
        label={<FormattedMessage id='request_add_document_type_label' />}
        inputId='type'
        value={documentToAdd?.type}
        onChange={handleChange}
        options={typeDocumentList?.map(type => ({
          label: type.typeName,
          value: type.typeName
        })) || []}
        required
        fieldStatus={fieldStatus}
      />
      {transaction.procedureType === INSCRIPTION_TYPE.value &&
        <SelectField
          label={<FormattedMessage id='request_add_document_communicability_label' />}
          value={documentToAdd?.communicability || DOCUMENT_COMMUNICABILITIES.COMMUNICABLE}
          onChange={handleChange}
          inputId='communicability'
          options={DOCUMENT_COMMUNICABLE_OPTIONS}
          fieldStatus={fieldStatus}
          required
        />}
    </div>
  )

  return (
    <CardBlock
      shadow
      header={blockTitle}
    >
      <div>
        {
          documents &&
            <RequestListTable
              tableTitle={DOCUMENTS_TABLE_TITLES}
              showPagination={false}
            >
              <thead>
                {
                  documents.filter(element => element.communicability !== DOCUMENT_COMMUNICABILITIES.INTERNAL)
                    .sort((a, b) => {
                      if (a.createdAt && b.createdAt) {
                        if (a.createdAt !== b.createdAt) {
                          return a.createdAt < b.createdAt ? 1 : -1
                        } else {
                          return a.order && b.order && a.order > b.order ? 1 : -1
                        }
                      }
                      return 1
                    })
                    .map(oneDocument => (
                      <tr key={oneDocument.internalName} className={oneDocument.idCorrection ? 'text-primary' : ''}>
                        <td>{DateUtils.formatDateFr(oneDocument.createdAt)}</td>
                        <td>{oneDocument.name}</td>
                        <td>{oneDocument.type}</td>
                        <td>
                          <FontAwesomeIcon
                            className='text-primary cursor-pointer' icon={faDownload} onClick={() => {
                              TransactionService.getDocumentFile(transaction.id, oneDocument.internalName).then(data => {
                                fileDownload(data, oneDocument.name, oneDocument.format)
                              })
                            }}
                          />
                        </td>
                      </tr>
                    ))
                }
              </thead>
              <thead>
                {
                  documentToAddList && documentToAddList?.map((oneDoc, index) => (
                    <tr key={index}>
                      <td>{DateUtils.formateDateToFormat(new Date(), BASIC_DATE)}</td>
                      <td>{oneDoc?.file?.name}</td>
                      <td>{oneDoc.type}</td>
                      <td>
                        <FontAwesomeIcon
                          className='text-secondary cursor-pointer'
                          icon={faTrash}
                          onClick={() => handleDeleteOneDocument(index)}
                        />
                      </td>
                    </tr>
                  ))
                }
              </thead>
            </RequestListTable>
        }
        <div className='d-flex justify-content-end'>
          <div>
            <button
              className='btn btn-block btn-outline-primary'
              onClick={() => setShowAddDocument(true)}
            >
              <FormattedMessage id='request_add_document_button' />
            </button>
          </div>
          {
            documentToAddList.length > 0 &&
              <div className='col-3'>
                <SubmitButton
                  className='btn btn-block btn-primary'
                  onClick={handleAddAllDocument}
                >
                  <FormattedMessage id='button_validate' />
                </SubmitButton>
              </div>
          }
        </div>

        <ModalComponent
          show={showAddDocument}
          handleClose={() => setShowAddDocument(false)}
          title={<FormattedMessage id='request_add_document_button' />}
          customContent={addDocumentContent}
          onClick={handleAddOneDocument}
        />
      </div>
    </CardBlock>
  )
}

export default DocumentBlock
