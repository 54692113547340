import store from '../../../../../store/store'
import { storeDepositFieldStatusUpdate } from '../../../../../store/deposit/depositActions'
import { Deposit, isFilled } from '@inpi-dm/components'
import Message from '../../../../../constants/Message'

class SelectDepositTypeValidator {
  validateDepositType = (deposit: Deposit) => {
    if (!isFilled(deposit.depositType)) {
      return Message.select_depot_type_required
    }
    return ''
  }

  validateOnChangeStep = () => {
    const deposit = store.getState().deposit

    const fieldStatus = {
      depositType: this.validateDepositType(deposit)
    }

    store.dispatch(storeDepositFieldStatusUpdate(fieldStatus))

    return !fieldStatus.depositType
  }
}

export default new SelectDepositTypeValidator()
