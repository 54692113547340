import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { PAYMENT_METHOD } from '../../constants/DepositConstants'
import InpiAccountForm from './InpiAccountForm'
import {
  AutocompleteResult,
  CardBlock, containsErrors,
  Country,
  DepositIntervenant, INTERVENANT_PAYER, IntervenantValidator,
  ModalComponent,
  OverviewIntervenant, RenderPayerForm,
  SubmitButton,
  PERSONNE_MORALE,
  Transaction,
  Deposit, OFFICIAL_DOCUMENTS_TYPE
} from '@inpi-dm/components'
import { PaymentParameters } from '../../interfaces/DepositInterfaces'
import ContentService from '../../services/content/ContentService'
import IntervenantService from '../../services/transaction/IntervenantService'
import AddressApiService from '../../services/content/AddressApiService'

interface SelectPaymentBlocksProps extends WrappedComponentProps {
    manageableMemoryAccessible?: boolean,
    className?: string,
    onStartPayment: (payment: PaymentParameters) => void,
    object: Deposit|Transaction
}

const SelectPaymentBlocks: FC<SelectPaymentBlocksProps> = ({
  manageableMemoryAccessible = false,
  className = '',
  onStartPayment,
  object,
  intl
}) => {
  const [showPayerModal, setShowPayerModal] = useState<boolean>(false)
  const [payerIntervenant, setPayerIntervenant] = useState<DepositIntervenant>()
  const [currentIntervenant, setCurrentIntervenant] = useState<DepositIntervenant>({})
  const [fieldStatus, setFieldStatus] = useState({ payer: {} })
  const [countries, setCountries] = useState<Country[]>()
  const [manageableQualities, setManageableQualities] = useState<Country[]>()
  const [intervenants, setIntervenants] = useState<AutocompleteResult<DepositIntervenant>[]>([])

  const initPayer = () => {
    // Le payeur est par défaut le destinataire si il ne s'agit pas d'une demande de document officiel, sinon c'est le demandeur
    const payer = !object.procedureType || (object.procedureType && object.procedureType !== OFFICIAL_DOCUMENTS_TYPE.value) ? { ...object.recipient } : { ...object.depositor }
    // Si le destinataire est une personne morale, on supprime les données physiques
    if (payer.type === PERSONNE_MORALE.value) {
      delete payer.civilityCode
      delete payer.firstname
      delete payer.lastname
    }

    setPayerIntervenant(payer)
  }

  useEffect(() => {
    initPayer()

    // Récupération des déposants et co-déposants
    const listIntervenants = IntervenantService.findIntervenants(object, ['depositor', 'coDepositors', 'recipient', 'agent', 'applicants', 'otherApplicants'])
      .map(intervenant => ({
        label: IntervenantService.getName(intervenant),
        value: intervenant
      }))
    setIntervenants(listIntervenants)

    ContentService.getManageableQualities().then(result => {
      setManageableQualities(result)
    })

    ContentService.getCountries().then(result =>
      setCountries(result)
    )
  }, [])

  /**
     * Valide le formulaire du payeur et fill le state payerIntervenant quand tout est ok
     */
  const handlePayer = (intervenant: DepositIntervenant) => {
    const updatedFieldStatus = IntervenantValidator.validateIntervenant(intervenant)
    if (!containsErrors(updatedFieldStatus)) {
      setFieldStatus({ payer: {} })
      setPayerIntervenant(intervenant)
      setShowPayerModal(false)
    } else {
      setFieldStatus({ payer: updatedFieldStatus })
    }
  }

  /**
     * Copie d'un intervenant dans le state du currentIntervenant
     */
  const handleSelectIntervenant = (intervenant: DepositIntervenant) => {
    setCurrentIntervenant(intervenant)
  }

  return (
    <div className={`row ${className} mb-3`}>
      <div className='col-5'>
        <CardBlock
          bodyClassName='py-3'
          header={<FormattedMessage id='payment_bank_card' />}
          shadow
        >
          <div className='row justify-content-center h-100'>
            <div className='mt-2 col-8'>
              <div className='text-gris'>
                <div>{INTERVENANT_PAYER.label}</div>
              </div>
              <OverviewIntervenant
                intervenant={payerIntervenant}
                countries={countries || []}
                qualities={manageableQualities}
              />
              <button
                className='btn btn-link-primary mt-2'
                onClick={() => setShowPayerModal(true)}
              >
                <FormattedMessage id='payment_not_payer' />
              </button>
            </div>
            <div className='mt-2 col-4 d-flex flex-column justify-content-center align-items-center'>
              <img src='/img/visa-logo.png' alt='' className='img-fluid mb-1 img-payment' />
              <img src='/img/mc-logo.png' alt='' className='img-fluid mt-1 img-payment' />
            </div>
            <div className='d-flex align-self-end justify-content-center'>
              <SubmitButton
                className='btn-block btn-primary w-25'
                onClick={() => onStartPayment({
                  method: PAYMENT_METHOD.BLUE_CARD,
                  payer: payerIntervenant
                })}
              >
                <FormattedMessage id='payment_access_button' />
              </SubmitButton>
            </div>
          </div>
        </CardBlock>
      </div>

      <div className='col-4'>
        <InpiAccountForm onSubmit={onStartPayment} />
      </div>

      <div className='col-3'>
        <CardBlock
          bodyClassName='py-3'
          header={<FormattedMessage id='payment_manageable_memory' />}
          shadow
        >
          <div className='row justify-content-center h-100'>
            <div className='col-12'>
              <div className='alert alert-warning'>
                <FormattedMessage id='payment_manageable_memory_info' />
              </div>
            </div>
            <div className='d-flex align-self-end justify-content-center'>
              <SubmitButton
                className={`btn-block ${!manageableMemoryAccessible ? 'btn-outline-gris' : 'btn-primary'} w-25`}
                onClick={() => onStartPayment({ method: PAYMENT_METHOD.MANAGEABLE_MEMORY })}
                disabled={!manageableMemoryAccessible}
              >
                <FormattedMessage id='payment_access_button' />
              </SubmitButton>
            </div>
          </div>
        </CardBlock>
      </div>

      <ModalComponent
        title={<FormattedMessage id='payment_create_payer' />}
        show={showPayerModal}
        customContent={() =>
          <div className='form-intervenant is-validated'>
            <RenderPayerForm
              depositPayer={currentIntervenant}
              intervenants={intervenants}
              handleSelectIntervenant={handleSelectIntervenant}
              handlePayerForm={(intervenant: DepositIntervenant) => handlePayer(intervenant)}
              fieldStatus={fieldStatus.payer}
              intl={intl}
              searchAddress={AddressApiService.searchAddress}
              getCountries={ContentService.getCountries}
            />
          </div>}
        handleClose={() => setShowPayerModal(false)}
        size='xl'
        hideFooter
      />
    </div>
  )
}

export default injectIntl(SelectPaymentBlocks)
