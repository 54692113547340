import React, { FC, ReactNode } from 'react'
import {
  CheckboxField,
  EventType,
  SelectField,
  SelectOption,
  SubmitButton
  , TableOrderBy, TableTitleItem, ORDER_ASC, ORDER_DESC, ROWS
} from '..'
import Pagination from '../navigation/Pagination'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

type RequestListTableProps = {
  tableTitle: TableTitleItem[],
  onSort?: (value?: string) => void,
  totalPage?: number,
  totalSize?: number,
  actualPage?: number,
  onChangePagination?: (page: number) => any | Promise<any>,
  nbShown?: SelectOption[],
  valueSelect?: number,
  onChangeSelect?: (event: EventType) => void,
  sortBy?: TableOrderBy,
  tableClassName?: string,
  selectAll?: boolean,
  onSelectAll?: (value: boolean) => void,
  labelSelectAll?: ReactNode,
  showPagination?: boolean,
  id?: string,
  keyTable?:string
};

const RequestListTable: FC<RequestListTableProps> = ({
  tableTitle,
  onSort,
  totalPage = 0,
  totalSize,
  actualPage,
  onChangePagination,
  nbShown,
  valueSelect,
  onChangeSelect,
  children,
  sortBy,
  tableClassName = 'table-striped table-hover',
  selectAll = false,
  onSelectAll,
  labelSelectAll = '',
  showPagination = true,
  id,
  keyTable = null
}) => {
  return (
    <div>
      <table className={`table ${tableClassName}`}>
        <thead>
          <tr>
            {onSelectAll && (
              <th scope='col' className='table-checkbox border-0'>
                <CheckboxField
                  inputId={`tableCheckAll${id || ''}`}
                  label={labelSelectAll}
                  onChange={() => onSelectAll(!selectAll)}
                  checked={selectAll}
                />
              </th>
            )}
            {tableTitle.map((title, index) => (
              <th key={`${keyTable}-${index}` || title.label} scope='col' className={`border-0 ${title.className || ''}`}>
                <SubmitButton
                  className={`btn-text text-left fit-parent ${title.className && (title.className.indexOf('w-') < 0)
                    ? title.className : ''}`}
                  onClick={() => onSort && onSort(title.value)}
                >
                  <span>{title.label}</span>
                  <span className='ml-1'>
                    {sortBy &&
                    sortBy.field === title.value &&
                    sortBy.order === ORDER_ASC &&
                    (<FontAwesomeIcon icon={faChevronUp} />)}
                    {sortBy &&
                    sortBy.field === title.value &&
                    sortBy.order === ORDER_DESC &&
                    (<FontAwesomeIcon icon={faChevronDown} />)}
                  </span>
                </SubmitButton>
              </th>
            ))}
          </tr>
        </thead>
        {children}
      </table>
      {
        showPagination &&
          <div className='row justify-content-end'>
            <div className='col-md-4 col-12' />
            <div className='col-md-3 col-12'>
              {totalPage > 0 && actualPage && onChangePagination &&
                <Pagination
                  nbPage={totalPage}
                  currentPage={actualPage}
                  onChange={onChangePagination}
                />}
            </div>
            <div className='col-md-4 col-12 d-flex justify-content-end align-items-center'>
              {totalSize &&
                <div className='mb-3 mr-2 text-primary font-weight-bold'>
              Transactions de {(actualPage - 1) * valueSelect + 1} à {Math.min(actualPage * valueSelect, totalSize)} sur {totalSize}
                </div>}
              {nbShown &&
                <SelectField
                  inputId='rows'
                  options={nbShown}
                  value={!ROWS.find(v => v.value === valueSelect) ? -1 : valueSelect}
                  onChange={onChangeSelect}
                  required
                />}
            </div>
          </div>
      }
    </div>
  )
}

export default RequestListTable
