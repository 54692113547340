import React, { FunctionComponent, ReactNode } from 'react'
import ErrorField from '../errors/ErrorField'
import { FieldStatus } from '../FormInterfaces'
import Switch from 'react-switch'
import variables from '../../styles/variables.scss'

export interface SwitchFieldProps {
    fieldStatus?: FieldStatus | any,
    onChange: (
        checked: boolean,
        event: React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent,
        id: string
    ) => void,
    inputId: string,
    label?: ReactNode,
    value?: any,
    className?: string,
    disabled?: boolean,
    onColor?: string,
    offColor?: string,
    uncheckedIcon?: boolean,
    checkedIcon?: boolean,
    nameFieldStatus?: string,
    height?: number,
    width?: number,
    divClassName?: string
}

const SwitchField: FunctionComponent<SwitchFieldProps> = ({
  divClassName,
  className = '',
  inputId,
  label,
  fieldStatus = {},
  value = false,
  onChange,
  onColor = variables.primary,
  offColor = variables.secondary,
  disabled = false,
  uncheckedIcon = false,
  nameFieldStatus,
  checkedIcon = false,
  height = 20,
  width = 40
}) => {
  return (
    <div className={`form-group d-flex ${divClassName || 'flex-column'}`}>
      {label && <label className='form-label' htmlFor={inputId}>{label}</label>}
      <Switch
        id={inputId}
        name={inputId}
        className={`${className}`}
        onChange={onChange}
        checked={value}
        onColor={onColor}
        offColor={offColor}
        uncheckedIcon={uncheckedIcon}
        checkedIcon={checkedIcon}
        height={height}
        width={width}
        disabled={disabled}
      />
      <ErrorField message={fieldStatus[nameFieldStatus || inputId]} />
    </div>
  )
}

export default SwitchField
