import React, { FC, useEffect, useState } from 'react'
import { useSelector, RootStateOrAny } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import {
  AutocompleteResult,
  Deposit,
  DepositDocument,
  DepositIntervenant,
  DOCUMENT_TYPES,
  EventType, INSCRIPTION_TYPE,
  isFilled, ManageableQuality,
  PERSONNE_PHYSIQUE,
  RenderAgentForm, Transaction,
  DOCUMENT_STATUS,
  PROROGATION_TYPE
} from '@inpi-dm/components'
import IntervenantService from '../../services/transaction/IntervenantService'
import InternalReferenceField from '../internalReference/InternalReferenceField'
import TransactionService from '../../services/transaction/TransactionService'
import AddressApiService from '../../services/content/AddressApiService'
import ContentService from '../../services/content/ContentService'
import { InscriptionFieldStatus } from '../../interfaces/InscriptionInterfaces'
import { DepositFieldStatus } from '../../interfaces/DepositInterfaces'
import HeaderIntervenant from '../intervenant/HeaderIntervenant'
import { ProrogationFieldStatus } from '../../interfaces/ProrogationInterfaces'
import StoreService from '../../services/StoreService'

interface AgentFormProps extends WrappedComponentProps {
  object : Transaction|Deposit,
  fieldStatus: InscriptionFieldStatus| DepositFieldStatus | ProrogationFieldStatus,
  procedureType: string,
  findIntervenantsList: string[],
  idTransaction: string,
  defaultIntervenantlist?: DepositIntervenant[]
}

const AgentForm : FC<AgentFormProps> = ({
  object,
  fieldStatus,
  procedureType,
  findIntervenantsList,
  idTransaction,
  defaultIntervenantlist = [],
  intl
}) => {
  const user = useSelector((state : RootStateOrAny) => state.user.user)
  const depositAgent = object.agent || {}
  const [idCurrentUser, setIdCurrentUser] = useState(depositAgent.idUser || -1)
  const [intervenants, setIntervenants] = useState<AutocompleteResult<DepositIntervenant>[]>([])
  const [agentDocuments, setAgentDocuments] = useState<DepositDocument[]>([])
  const [manageableQualities, setManageableQualities] = useState<ManageableQuality[]>()

  useEffect(() => {
    // Récupération des demandeur et destinataire
    const listIntervenants = IntervenantService.removeDuplicateIntervenants([...defaultIntervenantlist, ...IntervenantService.findIntervenants(object, findIntervenantsList)])
      .map(intervenant => ({
        label: `${intervenant.numNat ? `${intervenant.numNat} - ` : ''}${IntervenantService.getName(intervenant)}`,
        value: intervenant
      }))
    setIntervenants(listIntervenants)

    // Récupération des documents de pouvoir
    const documents = object.documents?.filter(doc => doc.type === DOCUMENT_TYPES.DEPOSIT_AGENT_DOCUMENT && doc.status !== DOCUMENT_STATUS.REJECTED) || []
    setAgentDocuments(documents)
  }, [object])

  useEffect(() => {
    ContentService.getManageableQualities().then(r => setManageableQualities(r))
  }, [])

  /**
     * Enregistrement des données dans le store à la saisie
     * @param event
     */
  const handleChange = (event: EventType) => {
    const { name, value } = event.target

    const updatedAgent = {
      ...depositAgent,
      type: PERSONNE_PHYSIQUE.value,
      [name]: value
    }

    // Si la checkbox est coché en inscription alors on met a jours également le recipient
    let updatedStore = { agent: updatedAgent }
    if (updatedAgent.isRecipient && procedureType === INSCRIPTION_TYPE.value) {
      updatedStore = { ...updatedStore, recipient: updatedAgent }
    }
    StoreService.changeStore(procedureType, updatedStore)
  }

  /**
     * Remplissage du formulaire avec les informations de l'utilisateur connecté
     */
  const handleSelfAgent = () => {
    if (user) {
      IntervenantService.buildIntervenantFromUser(user).then(intervenantFromUser => {
        const agent = {
          ...intervenantFromUser,
          id: depositAgent.id,
          firstname: user.firstname,
          lastname: user.lastname,
          civilityCode: user.civilityCode
        }

        StoreService.changeStore(procedureType, { agent: agent })
        setIdCurrentUser(user.id)
      })
    }
  }

  /**
     * Réinitialisation des champs du formulaire
     */
  const handleResetAgent = () => {
    StoreService.changeStore(procedureType, { agent: { type: PERSONNE_PHYSIQUE.value } })
    setIdCurrentUser(-1)
  }

  /**
     * Copie d'un intervenant dans le correspondant
     */
  const handleSelectIntervenant = (intervenant: DepositIntervenant) => {
    if (intervenant) {
      IntervenantService.validateCountryOfContributor(intervenant).then(newIntervenant => {
        StoreService.changeStore(procedureType, {
          agent: {
            ...newIntervenant,
            id: depositAgent.id,
            address: {
              ...newIntervenant.address
            },
            nationality: undefined
          }
        })
        setIdCurrentUser(intervenant.idUser)
      })
    }
  }

  /**
     * Enregistre un document de pouvoir en BDD
     */
  const addAgentDocument = async (event: EventType) => {
    const { value } = event.target

    // Suppression du document de pouvoir existant pour le remplacer par le nouveau
    if (isFilled(agentDocuments) && agentDocuments[0].internalName) {
      TransactionService.deleteDocumentFile(idTransaction, agentDocuments[0].internalName)
    }

    await TransactionService.postDocumentFile(idTransaction, DOCUMENT_TYPES.DEPOSIT_AGENT_DOCUMENT, value)
    const result = await TransactionService.getTransaction(idTransaction)
    StoreService.changeStore(procedureType, { documents: result.documents })
  }

  /**
     * Supprime le documents de pouvoir associé au dépôt
     */
  const deleteAgentDocument = async () => {
    await TransactionService.deleteDocumentFile(idTransaction, agentDocuments[0].internalName)
    const result = await TransactionService.getTransaction(idTransaction)
    StoreService.changeStore(procedureType, { documents: result.documents })
  }

  return (
    <div className='form-intervenant is-validated'>
      <div className='row'>
        <HeaderIntervenant procedureType={procedureType} />
        <InternalReferenceField
          value={object.internalReference}
          procedureType={procedureType}
          className='col-4 mb-4'
        />
      </div>
      <RenderAgentForm
        intervenants={intervenants}
        handleSelectIntervenant={handleSelectIntervenant}
        user={user}
        idCurrentUser={idCurrentUser}
        depositAgent={depositAgent}
        fieldStatus={fieldStatus.agent}
        handleChange={handleChange}
        addAgentDocument={addAgentDocument}
        searchAddress={AddressApiService.searchAddress}
        getCountries={ContentService.getCountries}
        deleteAgentDocument={deleteAgentDocument}
        handleResetAgent={handleResetAgent}
        handleSelfAgent={handleSelfAgent}
        intl={intl}
        agentDocuments={agentDocuments}
        foDisplay
        manageableQualities={manageableQualities}
        checkIsRecipientNeeded={procedureType === INSCRIPTION_TYPE.value || procedureType === PROROGATION_TYPE.value}
      />
    </div>
  )
}

export default injectIntl(AgentForm)
