import React, { FC, useEffect, useState } from 'react'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import ErrorField from '../form/errors/ErrorField'
import { EventType, ModalComponent } from '..'
import variables from '../styles/variables.scss'
import FullScreenButton from './FullScreenButton'
/* global FileReader */

interface EditorComponentProps {
  text: string,
  onBlur?: (value: string) => void,
  label?: any,
  inputId?: string,
  required?: boolean,
  fieldStatus?: any,
  className?: string,
  showFullScreenBtn?: boolean|null,
  disabled?: boolean
}

const EditorComponent: FC<EditorComponentProps> = ({
  text,
  onBlur,
  label,
  inputId,
  required,
  fieldStatus,
  className,
  showFullScreenBtn,
  disabled
}) => {
  const [editorState, setEditorState] = useState()
  const [fullScreenDisplay, setFullScreenDisplay] = useState<boolean>(false)

  useEffect(() => {
    if (!text) {
      setEditorState(text)
    } else {
      setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(text).contentBlocks)))
    }
  }, [text])

  const onEditorBlur = (event: EventType, valueOnBlurred: any) => {
    const rawContentState = convertToRaw(valueOnBlurred.getCurrentContent())
    const value: string = draftToHtml(rawContentState)
    onBlur && onBlur(value)
  }

  const uploadCallback = (file) => {
    return new Promise(
      (resolve) => {
        if (file) {
          const reader = new FileReader()
          reader.onload = (e) => {
            resolve({ data: { link: e.target.result } })
          }
          reader.readAsDataURL(file)
        }
      }
    )
  }

  const renderEditor = () => {
    return disabled
      ? <span dangerouslySetInnerHTML={{ __html: text }} /> : (
        <Editor
          editorState={editorState}
          toolbar={{
            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'image', 'history'],
            inline: { inDropdown: false },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            image: {
              urlEnabled: true,
              uploadEnabled: true,
              alignmentEnabled: true,
              uploadCallback: uploadCallback,
              previewImage: true,
              inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg'
            },
            colorPicker: {
              colors: [variables.RgbPrimary, variables.RgbSecondary, variables.RgbInpi, variables.RgbBlack]
            },
            history: {
              inDropdown: false,
              options: ['undo', 'redo']
            }
          }}
          toolbarCustomButtons={showFullScreenBtn ? [<FullScreenButton key='fullscreen' fullScreenDisplay={fullScreenDisplay} setFullScreenDisplay={setFullScreenDisplay} />] : []}
          onEditorStateChange={setEditorState}
          onBlur={onEditorBlur}
          editorStyle={{
            margin: '10px',
            paddingBottom: '10px'
          }}
          disabled={disabled}
        />
      )
  }

  return (
    <div className='form-group' id='editorComponent'>
      {label && <label className='form-label' htmlFor={inputId}>{label}{required && <span className='text-danger'> *</span>}</label>}
      <div className={`shadow ${className}`}>
        {!fullScreenDisplay && renderEditor()}
        <ModalComponent
          title=''
          dialogClassName='modal-full-width'
          show={fullScreenDisplay}
          customContent={() => renderEditor()}
          handleClose={() => setFullScreenDisplay(false)}
          onClick={() => setFullScreenDisplay(false)}
          size='xl'
          hideHeader
        />
      </div>
      {fieldStatus && inputId && <ErrorField message={fieldStatus[inputId]} />}
    </div>
  )
}

export default EditorComponent
