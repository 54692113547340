import http from '../../network/http-common'
import { AddressApiResult } from '@inpi-dm/components'
import { API_ADDRESS_SEARCH } from '../../constants'

type AddressType = 'housenumber' // précision au numéro de rue
                  |'street' // précision à la rue
                  |'locality' // précision au lieu dit
                  |'municipality' // précision à la commune
                  |undefined; // pas de précision particulière

interface GeoCodeProperties {
  label: string, // libellé complet de l’adresse
  housenumber: string, // numéro de maison
  id: string, // identifiant au sein de l'api gouv
  type: string, // type de résultat trouvé
  name: string, // numéro éventuel et nom de voie ou lieu dit
  postcode: string, // code postal
  citycode: string, // code INSEE de la commune
  city: string, // nom de la commune
  context: string, // n° de département, nom de département et de région
  street: string, // nom de la rue
}

interface GeoCodeGeometry {
  coordinates: number[],
}

interface GeoCodeFeature {
  geometry: GeoCodeGeometry,
  properties: GeoCodeProperties,
}

interface GeoCodeJSON {
  limit: number,
  query: string|null,
  features: GeoCodeFeature[],
}

class AddressApiService {
  /* Création d'une adresse à partir des données renvoyées par l'API */
  mapResultToAddressResult = (geoJson: GeoCodeJSON) : AddressApiResult[] => {
    return geoJson.features
      .map(({ properties, geometry }) => ({
        label: properties.label,
        value: {
          building: '',
          label: properties.housenumber && properties.street ? `${properties.housenumber} ${properties.street}` : properties.name,
          complement: '',
          zipCode: properties.postcode,
          city: properties.city,
          country: 'FR',
          longitude: geometry.coordinates[1],
          latitude: geometry.coordinates[0]
        }
      }))
  }

  /* Recherche d'une adresse via l'api data gouv */
  searchAddress = async (query: string, type = 'housenumber', limit = 5): Promise<AddressApiResult[]|void> => {
    // Autocomplétion à partir de 4 caractères uniquement
    if (!query || query.length < 4) {
      return Promise.resolve()
    }

    const paramQuery = `?q=${query}`
    const paramLimit = `&limit=${limit}`
    const paramType = type ? `&type=${type}` : ''

    try {
      const response : GeoCodeJSON = await http.get(`${API_ADDRESS_SEARCH}${paramQuery}${paramLimit}${paramType}`)
      return this.mapResultToAddressResult(response)
    } catch (error) {
      return []
    }
  }
}

export default new AddressApiService()
