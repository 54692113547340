import moment from 'moment'
import 'moment/locale/fr'
import {
  DAY_IN_MILLISECONDS, DAYS, HOURS,
  HOUR_IN_MILLISECONDS,
  MINUTE_IN_MILLISECONDS,
  MINUTES, MONTH_IN_MILLISECONDS,
  SECOND_IN_MILLISECONDS,
  SECONDS, YEARS, YEAR_IN_MILLISECONDS, MONTHS, BASIC_DATE, BASIC_DATE_ALT
} from '../constants/DateFormat'

class DateUtils {
  /**
   * Permet de réinitialiser une date à 00:00:00
   * @param date
   */
  formatToBeginOfDay = (date: string|null|undefined): string => {
    if (date) {
      return moment(date).startOf('day').format()
    }
    return ''
  }

  /**
   * Permet de réinitialiser une date à 23:59:59
   * @param date
   */
  formatToEndOfDay = (date: string|null|undefined): string => {
    if (date) {
      return moment(date).endOf('day').format()
    }
    return ''
  }

  /**
   * Permet d'avoir la date du jour
   */
  now = (): string => {
    return moment().format()
  }

  /**
   * Permet d'avoir la date de la fin du moi
   * @param date
   */
  formatToEndOfMonth = (date: string|null|undefined): string => {
    if (date) {
      return moment(date).endOf('months').format()
    }
    return ''
  }

  /**
   * Formatte une date au format dd/MM/yyyy
   *
   * @param date Une chaine de caractère au format ISO-8601,
   *             un objet Date,
   *             un entier représentant un timestamp exprimé en milliseconde
   */
  formatDateFr = (date: string|Date|number|null|undefined): string => {
    if (!date) {
      return ''
    }
    return moment(date).format(BASIC_DATE)
  }

  /**
   * Formatte une date au format dd-MM-yyyy
   *
   * @param date Une chaine de caractère au format ISO-8601,
   *             un objet Date,
   *             un entier représentant un timestamp exprimé en milliseconde
   */
  formatDateFrAlt = (date: string|Date|number|null|undefined): string => {
    if (!date) {
      return ''
    }
    return moment(date).format(BASIC_DATE_ALT)
  }

  /**
   * Vérification et formattage d'une date en locale
   * @param date
   * @param format format souhaité
   */
  formateDateToFormat = (date: Date | string | undefined, format?: string|undefined) => {
    if (!date) {
      return null
    }

    const momentDate = moment(date)

    if (momentDate.isValid()) {
      return momentDate.format(format)
    }
    return null
  }

  /**
   * Ajout nbDays jours et formate la date
   * @param date
   * @param nbDays
   * @param format
   */
  addDaysAndFormat = (date: Date|string|undefined, nbDays: number, format: string): string => {
    return moment(date).add(nbDays, 'days').format(format)
  }

  /**
   * Ajout nbMonths jours et formate la date
   * @param date
   * @param nbMonths
   */
  addMonthsAndFormat = (date: Date|string|undefined, nbMonths: number): string => {
    return moment(date).add(nbMonths, 'months').format()
  }

  /**
   * Supprime nbMonths jours et formate la date
   * @param date
   * @param nbMonths
   */
  subtractMonthsAndFormat = (date: Date|string|undefined, nbMonths: number): string => {
    return moment(date).subtract(nbMonths, 'months').format()
  }

  /**
   * Vérifie que first précède second
   * @param first
   * @param second
   */
  isBefore = (first: Date|string|undefined, second: Date|string|undefined): boolean => {
    return moment(first).isBefore(moment(second))
  }

  /**
   * Ajoute le format du temps donné en paramètre (seconde, minute, etc..)
   * @param timeDifference - Différence en milliseconde entre une date et aujourd'hui
   * @param timeDifferenceFormat - Différence en milliseconde entre une date et aujourd'hui avec un format
   * @param timeFormat
   * @param currentDate - date actuelle
   * @param logDate - date du passé que l'on compare
   * @param label - Label affiché (seconde, minute ...)
   */
  addTimeElapsedFormat = (timeDifference: number,
    timeDifferenceFormat: string,
    timeFormat: number,
    currentDate: any,
    logDate: any,
    label: string) => {
    let time = currentDate.diff(logDate, timeDifferenceFormat) + label

    if (timeDifference > timeFormat * 2) {
      time += 's'
    }
    return time
  }

  /**
   * Récupère le temps écoulé depuis une date donnée en paramètre
   * @param date
   */
  getTimeElapsed = (date: Date) => {
    const logDate = moment(date)
    const currentDate = moment()

    const timeDifference = currentDate.diff(logDate)

    if (timeDifference < MINUTE_IN_MILLISECONDS) {
      return this.addTimeElapsedFormat(timeDifference, SECONDS, SECOND_IN_MILLISECONDS, currentDate, logDate, ' seconde')
    } else if (timeDifference < HOUR_IN_MILLISECONDS) {
      return this.addTimeElapsedFormat(timeDifference, MINUTES, MINUTE_IN_MILLISECONDS, currentDate, logDate, ' minute')
    } else if (timeDifference < DAY_IN_MILLISECONDS) {
      return this.addTimeElapsedFormat(timeDifference, HOURS, HOUR_IN_MILLISECONDS, currentDate, logDate, ' heure')
    } else if (timeDifference < MONTH_IN_MILLISECONDS) {
      return this.addTimeElapsedFormat(timeDifference, DAYS, DAY_IN_MILLISECONDS, currentDate, logDate, ' jour')
    } else if (timeDifference < YEAR_IN_MILLISECONDS) {
      return currentDate.diff(logDate, MONTHS) + ' mois'
    } else {
      return this.addTimeElapsedFormat(timeDifference, YEARS, MONTH_IN_MILLISECONDS, currentDate, logDate, ' année')
    }
  }

  /**
   * Récupère le nombre de jours entre maintenant et la date en paramètre
   * @param date
   */
  nbDaysFromNow = (date: Date|string|number|undefined): number => {
    return moment().diff(moment(date, 'x'), 'days')
  }
}

export default new DateUtils()
