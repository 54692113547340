import { DEPOSIT_TYPE } from '../..//constants/RequestConstant'
import { Transaction } from '../../interfaces/TransactionInterfaces'
import { INTERVENANT_CO_DEPOSANT, INTERVENANT_DEPOSANT } from '../..//constants/DepositConstants'

/**
 * Vérifie s'il s'agit bien d'un co-déponsant ou déposant lors d'un dépôt
 */
export const isCoOrDepositDepositor = <T extends Transaction>(transactionOrProcedureType?: T | T['procedureType'], intervenantType?: string): boolean => {
  const procedureType = typeof transactionOrProcedureType === 'object' ? transactionOrProcedureType.procedureType : transactionOrProcedureType
  return procedureType === DEPOSIT_TYPE.value && (intervenantType === INTERVENANT_DEPOSANT.value || intervenantType === INTERVENANT_CO_DEPOSANT.value)
}
