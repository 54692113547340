import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { FormattedMessage, IntlProvider } from 'react-intl'
import Message from '../constants/Message'
import { ErrorField } from '../index'
import JSZip from 'jszip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileUpload, faSpinner } from '@fortawesome/free-solid-svg-icons'
/* global File */
/* global Blob */

const baseStyle = {
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#DFDFE0',
  borderStyle: 'dashed',
  color: '#171F2A',
  outline: 'none',
  transition: 'border .24s ease-in-out'
}

const activeStyle = {
  borderColor: '#656665'
}

const acceptStyle = {
  borderColor: '#656665'
}

const rejectStyle = {
  borderColor: '#FF1744'
}

function DropArea (props) {
  const [isLoading, setIsLoading] = useState(false)
  /**
   * Au moment de faire le drop, on traite les fichiers acceptés
   */
  const onDropAccepted = useCallback(acceptedFiles => {
    setIsLoading(true)
    // Traitement spécifique pour les fichiers zip, il faut extraire les fichiers et vérifier qu'ils sont conformes
    const zipFiles = acceptedFiles.filter(file => (!file.type || process.env.REACT_APP_ACCEPTED_ZIP_FORMAT?.indexOf(file.type) !== -1))
    if (zipFiles && zipFiles.length) {
      // Liste des fichiers non zippés
      const filesList = acceptedFiles.filter(file => (file.type && process.env.REACT_APP_ACCEPTED_ZIP_FORMAT?.indexOf(file.type) === -1))
      // Récupération du contenu des fichiers zip
      zipFiles.map(file => {
        getFileContent(file).then(files => {
          setIsLoading(false)
          props.onDrop([...filesList, ...files])
        })
      })
    } else {
      const errors = []
      // Pour un fichier pas dans un zip, on vérifie si le poids est autorisé
      acceptedFiles.forEach((file: File) => {
        if (props.maxSize && file.size > props.maxSize) {
          errors.push({ file: { path: file.name }, errors: [{ code: 'file-too-large' }] })
        }
      })
      setFileErrors(fileErrors => [...fileErrors, ...errors])
      if (props.maxSize) {
        acceptedFiles = acceptedFiles.filter((file : File) => file.size <= props.maxSize)
      }
      setIsLoading(false)
      props.onDrop(acceptedFiles)
    }
  }, [])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    fileRejections
  } = useDropzone({
    accept: props.accept,
    onDropAccepted
  })
  const [fileErrors, setFileErrors] = useState([])

  useEffect(() => {
    setFileErrors(fileErrors => [...fileErrors, ...fileRejections])
  }, [fileRejections])

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ])

  /**
     * Récupération du contenu d'un fichier zip
     * @param zipFile
     * @returns {Array}
     */
  const getFileContent = async (zipFile) => {
    const listOfFiles = []
    const errors = [...fileErrors]
    const newZip = new JSZip()

    // Récupération du contenu
    const zip = await newZip.loadAsync(zipFile)

    // Pour chaque fichier du zip, on vérifie si l'extension et le poids sont autorisés
    for (const filename of Object.keys(zip.files)) {
      // Ignore les dossiers
      if (filename.endsWith('/') || filename.endsWith('\\')) {
        continue
      }

      // Ignore le contenu du dossier "__MACOSX" présent dans les archives générées par Mac
      if (filename.startsWith('__MACOSX')) {
        continue
      }

      const extension = filename.substr(filename.lastIndexOf('.') + 1)
      if (process.env.REACT_APP_ACCEPTED_IMAGE_EXTENSION.indexOf(extension) !== -1) {
        // Récupération du contenu du fichier
        const fileData = await zip.files[filename].async('array')
        const blob = new Blob([new Uint8Array(fileData)])
        if (blob.size <= process.env.REACT_APP_ACCEPTED_IMAGE_SIZE) {
          try {
            listOfFiles.push(new File([blob], filename))
          } catch (e) {
            // La création d'un fichier à partir d'un blob ne fonctionne pas sur IE
            // on ajoute les métadonnées d'un fichier sur le blob
            blob.lastModifiedDate = new Date()
            blob.name = filename
            listOfFiles.push(blob)
          }
        } else {
          errors.push({
            file: { path: filename },
            errors: [{
              code: 'file-too-large'
            }]
          })
        }
      } else {
        errors.push({
          file: { path: filename },
          errors: [{
            code: 'file-invalid-type'
          }]
        })
      }
    }
    setFileErrors(fileErrors => [...fileErrors, ...errors])
    return listOfFiles
  }

  // Affichage des erreurs pour les fichiers rejetés (poids, format...)
  const fileRejectionItems = fileErrors.map(({ file, errors }) => (
    <div key={file.path} className='d-flex'>
      <span className='validation-error'>{file.path} :&nbsp;</span>
      {errors.map((e, index) => {
        return (
          <React.Fragment key={e.code}>
            <span className='validation-error'>{index > 0 ? '\xA0/\xA0' : ''}</span>
            <ErrorField message={<FormattedMessage key={e.code} id={e.code} />} />
          </React.Fragment>
        )
      })}
    </div>
  ))

  return (
    <IntlProvider locale='fr' messages={Message}>
      <section className='dropzone-section my-4 is-validated'>
        {isLoading ? (
          <div className='d-flex justify-content-center'>
            <FontAwesomeIcon className='loader' icon={faSpinner} />
          </div>
        ) : (
          <div>
            <div className='dropzone-area' {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <p> <FontAwesomeIcon className='text-primary' icon={props.icon || faFileUpload} /><FormattedMessage id={props.text || 'drop_files'} /></p>
            </div>
            <div>
              {fileRejectionItems}
            </div>
          </div>
        )}
      </section>
    </IntlProvider>
  )
}

export default DropArea
