import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { storeDepositUpdate } from '../../../../store/deposit/depositActions'
import { buildEventType, CheckboxField, Transaction, EventType } from '@inpi-dm/components'
import OverviewRoyalties from '../../../payment/OverviewRoyalties'
import OverviewDeposit from './OverviewDeposit'
import DepositService from '../../../../services/transaction/DepositService'
import DownloadLink from '../../download/DownloadLink'
import { Payment } from '../../../../interfaces/DepositInterfaces'
import Message from '../../../../constants/Message'
import TransactionService from '../../../../services/transaction/TransactionService'

const OverviewFormDeposit = () => {
  const dispatch = useDispatch()
  const deposit = useSelector((state : RootStateOrAny) => state.deposit)
  const fieldStatus = useSelector((state : RootStateOrAny) => state.depositFieldStatus)
  const [transaction, setTransaction] = useState<Transaction>()
  const [infoPrice, setInfoPrice] = useState<Payment>({ lines: [], totalAmount: 0 })

  /**
     * Enregistrement des données dans le store à la saisie
     * @param event
     */
  const handleChange = (event: EventType) => {
    const { value } = event.target

    dispatch(storeDepositUpdate({
      overviewSimilarInformation: value
    }))
  }

  useEffect(() => {
    // Reset case à cocher
    handleChange(buildEventType('overviewSimilarInformation', false))

    TransactionService.getTransaction(deposit.idTransaction)
      .then(setTransaction)

    // Récupération des informations concernant le paiement
    if (deposit.idTransaction) {
      DepositService.getPayment(deposit.idTransaction)
        .then((payment) =>
          payment && setInfoPrice(payment)
        )
    }

    return () => {
      DepositService.cancelRequest()
    }
  }, [])

  return (
    <div>
      {transaction &&
        <>
          <div className='row mx-3 d-flex justify-content-between'>
            <h1><FormattedMessage id='overview_deposit_form_title' /></h1>
            <DownloadLink id={transaction.id} generate name={`${Message.overview_deposit_pdf_title}.pdf`} internalName={DepositService.getLastRecapPDFName(transaction)} />
          </div>

          <div className='col mt-3'>
            <OverviewDeposit transaction={transaction} />

            <div className='row'>
              <div className='col-12 mb-3'>
                <OverviewRoyalties infoPrice={infoPrice} />
              </div>
            </div>

            <div className='d-flex mt-3'>
              <CheckboxField
                className='mx-auto'
                labelClassName='font-weight-bold'
                inputId='overviewSimilarInformation'
                label={<FormattedMessage id='overview_deposit_form_checkbox_similar_information' />}
                checked={deposit.overviewSimilarInformation}
                onChange={handleChange}
                fieldStatus={fieldStatus.overviewSimilarInformation}
              />
            </div>
          </div>
        </>}
    </div>
  )
}

export default OverviewFormDeposit
