import { toast } from 'react-toastify'
import http from '../../network/http-common'
import {
  DepositCorrection,
  Transaction, DOCUMENT_TYPES,
  IntervenantValidator,
  STATUS_CORRECTION
} from '@inpi-dm/components'
import { Payment } from '../../interfaces/DepositInterfaces'
/* global FormData */

class CorrectionService {
    /**
     * Créer une nouvelle demande de correction
     *
     * @param idTransaction Identifiant de la transaction
     * @param correction Informations de la demande de correction
     */
    createCorrection = async (idTransaction: string, correction: any): Promise<DepositCorrection> => {
      try {
        return await http.post(`/api/transactions/${idTransaction}/corrections`, correction)
      } catch (error) {
        toast.error(error.message)
        return Promise.reject(error)
      }
    }

    /**
     * Upload un document sur une correction de transaction
     *
     * @param idTransaction Identifiant de la transaction
     * @param idCorrection Identifiant de la correction
     * @param file Fichier à uploader
     * @param type Type de document uploadé
     */
    uploadDocumentToCorrection = async (idTransaction: string, idCorrection: string, file: File, type: string): Promise<DepositCorrection> => {
      try {
        const data = new FormData()
        data.append('type', type)
        data.append('file', file)

        return await http.post(`/api/transactions/${idTransaction}/corrections/${idCorrection}/documents`, data)
      } catch (error) {
        toast.error(error.message)
        return Promise.reject(error)
      }
    }

    /**
     * Récupère la taxe à payer pour une demande de correction.
     * @param idTransaction
     */
    getPayment = async (idTransaction: string): Promise<Payment> => {
      try {
        return await http.get(`/api/transactions/${idTransaction}/corrections/payment`)
      } catch (error) {
        toast.error(error.message)
        return Promise.reject(error)
      }
    }

    /**
     * Retourne la première correction à valider dans une transaction
     *
     * @param transaction
     */
    findCorrectionInReview = (transaction?: Transaction) => {
      return transaction?.correctionRequests?.find(correction => correction.status === STATUS_CORRECTION.IN_REVIEW)
    }

    /**
     * Retourne la liste de documents issu de l'application d'une correction sur un dépôt
     *
     * @param deposit
     * @param correction
     */
    mergeDocumentsCorrectionInDeposit = (deposit?: Transaction, correction?: DepositCorrection) => {
      let depositDocuments = deposit?.documents || []

      if (correction?.agentDeleted || IntervenantValidator.containsAgentDocument(correction)) {
        // On retire les documents du mandataire si ils sont modifiés ou supprimés dans la correction
        depositDocuments = depositDocuments.filter(doc => doc.type !== DOCUMENT_TYPES.DEPOSIT_AGENT_DOCUMENT)
      }

      return [
        ...depositDocuments,
        ...(correction?.documents || [])
      ]
    }

    /**
     * Retourne le résultat de l'application d'une correction sur un dépôt
     *
     * @param deposit Le dépôt sur lequel appliqué la correction
     * @param correction La correction à appliquer
     */
    mergeCorrectionInDeposit = (deposit: Transaction, correction: DepositCorrection): Transaction => {
      return {
        ...deposit,
        file: {
          ...deposit.file,
          depositor: correction.depositor ? correction.depositor : deposit.file.depositor,
          coDepositors: correction.coDepositors ? correction.coDepositors : deposit.file.coDepositors,
          recipient: correction.recipient ? correction.recipient : deposit.file.recipient,
          agent: correction.agent
            ? correction.agent
            : (correction.agentDeleted ? undefined : deposit.file.agent),
          creators: correction.creators ? correction.creators : deposit.file.creators,
          signatory: correction.signatory ? correction.signatory : deposit.file.signatory
        },
        documents: this.mergeDocumentsCorrectionInDeposit(deposit, correction)
      }
    }
}

export default new CorrectionService()
