import Message from './Message'

export const PROROGATION_FIELD = [
  'titles',
  'protectionExtensions',
  'isAppealOrDecommission',
  'appealDecommissionNumber',
  'inscriptionRequests',
  'isNewDepositor'
]

export const STATUS_PROROGATION = {
  /** Brouillon client */
  DRAFT_CLIENT: 'draft_client_prorogation',
  /** Brouillon */
  DRAFT: 'draft_prorogation',
  /** A attribuer */
  TO_ATTRIBUTE: 'to_attribute_prorogation',
  /** A examiner */
  TO_REVIEW: 'to_review_prorogation',
  /** Suspendu */
  SUSPENDED: 'suspended_prorogation',
  /** A refuser */
  TO_REFUSE: 'to_refuse_prorogation',
  /** A rapporter */
  TO_REPORT: 'to_report_prorogation',
  /** Irrecevable */
  NOT_ACCEPTED: 'not_accepted_prorogation',
  /** A publier */
  TO_PUBLISH: 'to_publish_prorogation',
  /** Publié */
  PUBLISHED: 'published_prorogation',
  /** Rejeté */
  REJECTED: 'rejected_prorogation',
  /** A retirer */
  TO_WITHDRAW: 'to_withdraw_prorogation',
  /** Retiré */
  WITHDRAWN: 'withdrawn_prorogation',
  /** Bermudes */
  BERMUDA: 'bermuda_prorogation',
  /** Errata */
  ERRATA: 'errata_prorogation'
}

export const PROROGATION_DELAY_LABEL_START_PROROGATION = 'Délai précédant la fin de validité'
export const PROROGATION_DELAY_LABEL_END_GRACE = 'Délai suivant la fin de validité'

export const TYPE_DELAY_BEFORE_PROROGATION = 'TYPE_DELAY_BEFORE_PROROGATION'
export const TYPE_DELAY_PROROGATION = 'TYPE_DELAY_PROROGATION'
export const TYPE_DELAY_GRACE = 'TYPE_DELAY_GRACE'
export const TYPE_DELAY_AFTER_GRACE = 'TYPE_DELAY_AFTER_GRACE'

export const TITLE_PROROGATION_SCOPE_PARTIAL = 'PARTIAL'
export const TITLE_PROROGATION_SCOPE_TOTAL = 'TOTAL'

export const RECORD_PROROGATION_SCOPE_TOTAL = {
  label: Message.records_prorogation_isScopePartial_no,
  value: TITLE_PROROGATION_SCOPE_TOTAL
}

export const RECORD_PROROGATION_SCOPE_PARTIAL = {
  label: Message.records_prorogation_isScopePartial_yes,
  value: TITLE_PROROGATION_SCOPE_PARTIAL
}

export const RECORD_PROROGATION_SCOPE = [
  RECORD_PROROGATION_SCOPE_TOTAL,
  RECORD_PROROGATION_SCOPE_PARTIAL
]

export const TITLE_PROROGATION_ORIGIN_FR = 'FR'
export const TITLE_PROROGATION_ORIGIN_INT = 'INT-FR'
