import React, { FC, useEffect, useState } from 'react'
import { SelectField, SelectOption, FieldProps } from '@inpi-dm/components'
import { Country } from '../..'

interface CountrySelectFieldProps extends FieldProps {
  required?: boolean,
  getCountries: () => Promise<void | Country[]>
  isPriority?: boolean
}

const CountrySelectField: FC<CountrySelectFieldProps> = (props) => {
  const [countries, setCountries] = useState<SelectOption[]>([])

  useEffect(() => {
    let unmounted = false
    if (typeof props.getCountries === 'function' && countries.length === 0) {
      props.getCountries().then(result => {
        if (!unmounted && result) {
          setCountries(
            !props.isPriority
              ? result.filter(country => !country.ompi).map(country => ({
                value: country.code,
                label: country.label
              })) : result.map(country => ({
                value: country.code,
                label: country.label
              }))
          )
        }
      })
    }
    return () => {
      unmounted = true
    }
  }, [props.getCountries])

  return (
    <SelectField
      {...props}
      options={countries.sort((a, b) => a.label > b.label ? 1 : -1)}
    />
  )
}

export default CountrySelectField
