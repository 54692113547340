import axios from 'axios'
import http from '../../network/http-common'
import { NatureCode } from '@inpi-dm/components'

const NATURE_CODE_BASE_URL = '/api/nature-codes'

/**
 * Classe permettant de récupérer les données des codes nature administrables en bo
 */
class NatureCodeService {
  constructor () {
    this.source = axios.CancelToken.source()
  }

    /**
     * Récupération des codes nature définis dans le BO
     * @returns Promise<NatureCode[]>
     */
    getNatureCodes = async (params: { type: string|undefined }): Promise<NatureCode[]|any> => {
      try {
        return await http.get(NATURE_CODE_BASE_URL, {
          params: params,
          cancelToken: this.source.token
        })
      } catch (error) {
        return Promise.resolve([])
      }
    }

    /**
     * Annule une requête en attente
     */
    cancelRequest = () => {
      this.source.cancel()
      this.source = axios.CancelToken.source()
    }
}

export default new NatureCodeService()
