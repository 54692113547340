import store from '../../../../../store/store'
import {
  containsErrors, DepositDocument, DOCUMENT_TYPES
} from '@inpi-dm/components'
import { storeInscriptionFieldStatusUpdate } from '../../../../../store/inscription/inscriptionActions'
import Message from '../../../../../constants/Message'
import DocumentValidator from '../../documents/validator/DocumentValidator'

class OwnershipValidator {
  /**
   * Mise a jour de InscriptionFieldStatus
   */
  validateOnChangeStep = (inscriptionProofDocuments: DepositDocument[][]|undefined) => {
    const inscription = store.getState().inscription

    let documents = []
    if (inscriptionProofDocuments) {
      documents = inscriptionProofDocuments.flat()
    } else {
      documents = inscription.documents || []
    }
    documents = documents.filter((document : DepositDocument) => document.type !== DOCUMENT_TYPES.DEPOSIT_AGENT_DOCUMENT)

    const fieldStatus = {
      natureCodes: inscription.inscription.natureCodes.length === 0 ? Message.ownership_form_nature_error : '',
      documents: DocumentValidator.hasErrorDocuments(documents, true)
    }

    store.dispatch(storeInscriptionFieldStatusUpdate(fieldStatus))

    return !containsErrors(fieldStatus)
  }
}

export default new OwnershipValidator()
