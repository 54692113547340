import { isFilled, isMailFormatValid, isTelephoneValid } from '@inpi-dm/components'
import { FoUserResponse } from '../../../interfaces/User'
import Message from '../../../constants/Message'
import { ContactMessage, ContactRequest } from '../../../interfaces/ContactInterfaces'

class ContactValidator {
    validateForm = (user: FoUserResponse, message?: ContactMessage) => {
      const fieldStatus: ContactRequest = {}
      const errorUser = this.validateFoUser(user)
      const errorMessage = this.validateMessage(message)
      Object.assign(fieldStatus, errorUser)
      Object.assign(fieldStatus, errorMessage)
      return fieldStatus
    }

    /**
     * Valide les infos de l'utilisateur voulant prendre contact
     * @param user
     */
    validateFoUser = (user: FoUserResponse) => {
      const fieldStatus: FoUserResponse = {}
      const errorEmail = this.validateEmail(user.email)
      if (isFilled(errorEmail)) {
        fieldStatus.email = errorEmail
      }
      if (!isFilled(user.lastname)) {
        fieldStatus.lastname = Message.required_field
      }
      if (!isFilled(user.firstname)) {
        fieldStatus.firstname = Message.required_field
      }
      if (!isFilled(user.mobilePhone) || (user.mobilePhone && !isTelephoneValid(user.mobilePhone))) {
        fieldStatus.mobilePhone = Message.error_phone_format
      }
      return fieldStatus
    }

    /**
     * Valide l'email du formulaire de contact
     * @param email
     */
    validateEmail = (email: string): string => {
      if (!isFilled(email)) {
        return Message.required_field
      }
      if (!isMailFormatValid(email)) {
        return Message.error_email_format
      }
      return ''
    }

    /**
     * Valide l'objet et le contenu du message à envoyer
     * @param message
     */
    validateMessage = (message?: ContactMessage) => {
      const fieldStatus: ContactMessage = {}
      if (!message) {
        fieldStatus.object = Message.required_field
        fieldStatus.content = Message.required_field
      } else {
        if (!message.object) {
          fieldStatus.object = Message.required_field
        }
        if (!isFilled(message.content)) {
          fieldStatus.content = Message.required_field
        }
      }
      return fieldStatus
    }
}

export default new ContactValidator()
