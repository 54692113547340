import Message from './Message'
import { STATUS_DEPOSIT } from '@inpi-dm/components/src/constants/DepositConstants'
import { NOTIF_STATUS, STATUS_APPEAL, STATUS_INSCRIPTION, STATUS_PROROGATION, STATUS_DO } from '@inpi-dm/components'

export const TABLE_TITLE_STATUS_DRAFT = [
  {
    label: Message.placeholder_procedure_type_option,
    className: 'text-center'
  },
  {
    label: Message.request_updated_time,
    className: 'text-center',
    value: 'lastUpdate'
  },
  {
    label: Message.field_internal_reference_label,
    className: 'text-center',
    value: 'internalReference'
  },
  {
    label: Message.request_depositor_name,
    className: 'text-center',
    value: ['file.depositor.companyName', 'file.depositor.lastname', 'applicants.companyName', 'applicants.lastname']
  },
  {
    label: Message.request_label_type,
    className: 'text-center',
    value: ['file.depositType', 'subProcedureType']
  }, {
    label: '',
    className: 'action-button-request'
  }
]

export const TABLE_TITLE_TITRES = [
  {
    className: 'w-5 text-center'
  },
  {
    label: Message.request_publication_time,
    className: 'text-center',
    value: 'publicationDate'
  },
  {
    label: Message.request_number,
    className: 'text-center',
    value: 'numNat'
  },
  {
    label: Message.field_internal_reference_label,
    className: 'text-center',
    value: 'internalReference'
  },
  {
    label: Message.request_depositor_name,
    className: 'text-center',
    value: ['holders.companyName', 'holders.lastname']
  }
]

export const TABLE_TITLE_TITRES_VALIDITY_END_DATE = [
  {
    className: 'vertical-banner p-0'
  },
  {
    className: 'w-5 text-center'
  },
  {
    label: Message.request_deposit_time,
    className: 'text-center',
    value: 'depositDate'
  },
  {
    label: Message.request_number,
    className: 'text-center',
    value: 'numNat'
  },
  {
    label: Message.field_internal_reference_label,
    className: 'text-center',
    value: 'internalReference'
  },
  {
    label: Message.request_depositor_name,
    className: 'text-center',
    value: ['holders.companyName', 'holders.lastname']
  },
  {
    label: Message.request_validity_end_date_time,
    className: 'text-center',
    value: 'validityEndDate'
  }
]

export const TABLE_TITLE = [
  {
    label: Message.placeholder_procedure_type_option,
    className: 'text-center'
  },
  {
    label: Message.request_deposit_time,
    className: 'text-center',
    value: 'file.depositDate'
  },
  {
    label: Message.request_number,
    className: 'text-center',
    value: 'numNat'
  },
  {
    label: Message.field_internal_reference_label,
    className: 'text-center',
    value: 'internalReference'
  },
  {
    label: Message.request_depositor_name,
    className: 'text-center',
    value: ['file.depositor.companyName', 'file.depositor.lastname', 'applicants.companyName', 'applicants.lastname']
  },
  {
    label: Message.request_label_type,
    className: 'text-center',
    value: ['file.depositType', 'subProcedureType']
  }
]

export const TABLE_TITLE_NOTIFS = [
  {
    label: Message.request_notif_deadline_time,
    className: 'text-center',
    value: 'notifications.deadline'
  },
  {
    label: Message.request_notif_sending_time,
    className: 'text-center'
  },
  {
    label: Message.request_number,
    className: 'text-center',
    value: 'numNat'
  },
  {
    label: Message.field_internal_reference_label,
    className: 'text-center',
    value: 'internalReference'
  },
  {
    label: Message.request_depositor_name,
    className: 'text-center',
    value: ['file.depositor.companyName', 'file.depositor.lastname', 'applicants.companyName', 'applicants.lastname']
  },
  {
    label: Message.placeholder_procedure_type_option,
    className: 'text-center'
  }
]

export const TABLE_TITLE_ASSIGN = [
  {
    label: Message.request_updated_time,
    className: 'text-center',
    value: 'lastUpdate'
  },
  {
    label: Message.request_number,
    className: 'text-center',
    value: 'numNat'
  },
  {
    label: Message.field_internal_reference_label,
    className: 'text-center',
    value: 'internalReference'
  },
  {
    label: Message.request_label_type,
    className: 'text-center'
  }
]

export const TABLE_PROVIDED_DOCUMENTS = [
  {
    label: Message.request_date_time,
    className: 'text-center',
    value: 'arrivalDate'
  },
  {
    label: Message.request_numnat,
    className: 'text-center',
    value: 'numNat'
  },
  {
    label: Message.request_customer_ref,
    className: 'text-center',
    value: 'internalReference'
  },
  {
    label: Message.request_applicant,
    className: 'text-center',
    value: ['depositor.companyName', 'depositor.lastname', 'applicants.companyName', 'applicants.lastname']
  },
  {
    label: Message.request_status,
    className: 'text-center',
    value: 'status'
  }
]

export const REQUEST_STATUS_REVIEWING = [
  STATUS_DEPOSIT.CHECK_ACCEPTABILITY,
  STATUS_DEPOSIT.REVIEW_IN_PROGRESS,
  STATUS_DEPOSIT.TO_ATTRIBUTE,
  STATUS_DEPOSIT.STATUS_OPBM,
  STATUS_DEPOSIT.REVIEW_STAND_BY,
  STATUS_DEPOSIT.TO_REVIEWED,
  STATUS_DEPOSIT.VALIDATED,
  STATUS_DEPOSIT.TO_REJECT
]

export const INSCRIPTION_STATUS_REVIEWING = [
  STATUS_INSCRIPTION.TO_ATTRIBUTE,
  STATUS_INSCRIPTION.TO_REVIEW,
  STATUS_INSCRIPTION.TO_REJECT,
  STATUS_INSCRIPTION.SUSPENDED,
  STATUS_INSCRIPTION.TO_PUBLISH,
  STATUS_INSCRIPTION.TO_REPORT
]

export const PROROGATION_STATUS_REVIEWING = [
  STATUS_PROROGATION.TO_ATTRIBUTE,
  STATUS_PROROGATION.TO_REVIEW,
  STATUS_PROROGATION.TO_REFUSE,
  STATUS_PROROGATION.TO_WITHDRAW,
  STATUS_PROROGATION.SUSPENDED,
  STATUS_PROROGATION.TO_PUBLISH,
  STATUS_PROROGATION.TO_REPORT
]

export const APPEAL_STATUS_REVIEWING = [
  STATUS_APPEAL.TO_ATTRIBUTE,
  STATUS_APPEAL.TO_REVIEW,
  STATUS_APPEAL.TO_REJECT,
  STATUS_APPEAL.SUSPENDED,
  STATUS_APPEAL.TO_REGISTER,
  STATUS_PROROGATION.TO_REPORT
]

export const DO_STATUS_REVIEWING = [
  STATUS_DO.TO_ATTRIBUTE,
  STATUS_DO.TO_REVIEW,
  STATUS_DO.TO_REJECT,
  STATUS_DO.SUSPENDED
]

export const REQUEST_STATUS_ACTION_REPRO = [
  STATUS_DEPOSIT.CHECK_ACCEPTABILITY,
  STATUS_DEPOSIT.REVIEW_IN_PROGRESS,
  STATUS_DEPOSIT.TO_ATTRIBUTE,
  STATUS_DEPOSIT.STATUS_OPBM,
  STATUS_DEPOSIT.REVIEW_STAND_BY,
  STATUS_DEPOSIT.TO_REVIEWED,
  STATUS_DEPOSIT.VALIDATED,
  STATUS_DEPOSIT.TO_REJECT,
  STATUS_DEPOSIT.ADJOURNED_PENDING,
  STATUS_DEPOSIT.SIMPLIFIED_PENDING
]

export const REQUEST_STATUS_CORRECTABLE = [
  STATUS_DEPOSIT.CHECK_ACCEPTABILITY,
  STATUS_DEPOSIT.REVIEW_IN_PROGRESS,
  STATUS_DEPOSIT.TO_ATTRIBUTE,
  STATUS_DEPOSIT.STATUS_OPBM,
  STATUS_DEPOSIT.REVIEW_STAND_BY,
  STATUS_DEPOSIT.TO_REVIEWED,
  STATUS_DEPOSIT.VALIDATED,
  STATUS_DEPOSIT.ADJOURNED_PENDING,
  STATUS_DEPOSIT.SIMPLIFIED_PENDING
]

export const STATUS_REQUEST = 'status'
export const NOTIF_REQUEST = 'notifications'
export const DOC_REQUEST = 'documents'
export const OTHER_REQUEST = 'other'

export const REQUEST_TYPES = [
  {
    id: 'projets_sauvegardes',
    label: 'dashboard_projets_sauvegardes',
    urlParam: 'sauvegardes',
    statusFiltres: [STATUS_DEPOSIT.DRAFT, STATUS_INSCRIPTION.DRAFT_CLIENT, STATUS_PROROGATION.DRAFT_CLIENT, STATUS_APPEAL.DRAFT_CLIENT, STATUS_DO.DRAFT_CLIENT],
    isDraft: true,
    headers: TABLE_TITLE_STATUS_DRAFT,
    typeRequest: STATUS_REQUEST
  }, {
    id: 'demande_en_cours',
    label: 'dashboard_demande_en_cours',
    urlParam: 'en-cours-examen',
    statusFiltres: [...REQUEST_STATUS_REVIEWING, ...INSCRIPTION_STATUS_REVIEWING, ...PROROGATION_STATUS_REVIEWING, ...APPEAL_STATUS_REVIEWING, ...DO_STATUS_REVIEWING],
    headers: TABLE_TITLE,
    typeRequest: STATUS_REQUEST
  }, {
    id: 'repondre_notifications',
    label: 'dashboard_repondre_notifications',
    urlParam: 'notifications',
    statusFiltres: [NOTIF_STATUS.NOTIF_STATUS_WAITING_ANSWER, NOTIF_STATUS.NOTIF_STATUS_SENT],
    headers: TABLE_TITLE_NOTIFS,
    isNotif: true,
    typeRequest: NOTIF_REQUEST
  }, {
    id: 'declarations_publiee',
    label: 'dashboard_declarations_publiee',
    urlParam: 'enregistrees-publiees',
    statusFiltres: [STATUS_DEPOSIT.PUBLISHED, STATUS_INSCRIPTION.PUBLISHED, STATUS_PROROGATION.PUBLISHED],
    headers: TABLE_TITLE,
    typeRequest: STATUS_REQUEST
  }, {
    id: 'demandes_ajournees',
    label: 'dashboard_demandes_ajournees',
    urlParam: 'ajournees-simplifiees',
    statusFiltres: [STATUS_DEPOSIT.ADJOURNED_PENDING, STATUS_DEPOSIT.SIMPLIFIED_PENDING],
    headers: TABLE_TITLE,
    typeRequest: STATUS_REQUEST
  }, {
    id: 'demandes_dechues',
    label: 'dashboard_demandes_dechues',
    urlParam: 'dechues',
    statusFiltres: [STATUS_DEPOSIT.DECOMMISSIONED],
    headers: TABLE_TITLE,
    typeRequest: STATUS_REQUEST
  }, {
    id: 'demandes_rejetees',
    label: 'dashboard_demandes_rejetees',
    urlParam: 'rejetees-irrecevable',
    statusFiltres: [STATUS_DEPOSIT.REJECTED, STATUS_DEPOSIT.NOT_ACCEPTED, STATUS_INSCRIPTION.REJECTED, STATUS_PROROGATION.REJECTED, STATUS_APPEAL.NOT_ACCEPTED, STATUS_APPEAL.REJECTED, STATUS_DO.REJECTED],
    headers: TABLE_TITLE,
    typeRequest: STATUS_REQUEST
  }, {
    id: 'demandes_retirees',
    label: 'dashboard_demandes_retirees',
    urlParam: 'retirees',
    statusFiltres: [STATUS_DEPOSIT.TOTAL_WITHDRAWAL, STATUS_INSCRIPTION.WITHDRAWN, STATUS_PROROGATION.WITHDRAWN, STATUS_APPEAL.DISCONTINUATION],
    headers: TABLE_TITLE,
    typeRequest: STATUS_REQUEST
  }, {
    id: 'titres',
    label: 'dashboard_titres',
    urlParam: 'titres',
    statusFiltres: ['titles'],
    isTitle: true,
    headers: TABLE_TITLE_TITRES,
    typeRequest: OTHER_REQUEST
  }, {
    id: 'titres_prorogation',
    label: 'dashboard_demandes_prorogeables',
    urlParam: 'titres-prorogation',
    statusFiltres: ['titles_prorogation'],
    isTitle: true,
    isForProrogation: true,
    headers: TABLE_TITLE_TITRES_VALIDITY_END_DATE,
    typeRequest: OTHER_REQUEST
  }, {
    id: 'documents_fournis',
    label: 'dashboard_documents_fournis',
    urlParam: 'documents-fournis',
    statusFiltres: ['available_official_documents'],
    isDocument: true,
    headers: TABLE_PROVIDED_DOCUMENTS,
    typeRequest: DOC_REQUEST
  }
]

export const REPRODUCTIONS_VIEW_MODE = {
  OVERVIEW: 'OVERVIEW',
  SELECT: 'SELECT'
}

export const DOCUMENTS_TABLE_TITLES = [
  {
    label: Message.request_document_createdAt,
    className: 'text-gris border-0'
  }, {
    label: Message.request_document_name,
    className: 'text-gris border-0 w-50'
  }, {
    label: Message.request_document_type,
    className: 'text-gris border-0 w-25'
  }
]

export const PROROGATION_INSCRIPTION_REQUEST_TABLE_TITLES = [
  {
    label: '',
    className: 'text-gris border-0 w-17'
  }, {
    label: Message.prorogation_order_number_table,
    className: 'text-gris border-0 w-24 text-truncate'
  }, {
    label: Message.prorogation_inscription_number_table,
    className: 'text-gris border-0 w-24 text-truncate'
  }, {
    label: Message.prorogation_document,
    className: 'text-gris border-0 w-30 text-truncate'
  }
]

export const PROROGATION_INSCRIPTION_REQUEST_TABLE_TITLES_WITHOUT_HELP = [
  {
    label: Message.prorogation_order_number_table,
    className: 'text-gris border-0 w-30'
  }, {
    label: Message.prorogation_inscription_number_table,
    className: 'text-gris border-0 w-30'
  }, {
    label: Message.prorogation_document,
    className: 'text-gris border-0 w-30'
  }
]

export const PROROGATION_ADDITIONAL_PARTS_TITLES = [
  {
    label: Message.prorogation_file_title,
    className: 'text-gris border-0 w-45'
  }, {
    label: Message.request_document_type,
    className: 'text-gris border-0 w-45'
  }
]
