import moment from 'moment'
import Message from '../constants/Message'
import { DepositIntervenant } from '../interfaces/DepositInterfaces'

export function isFilled (value: string|any[]|null|undefined): boolean {
  return !!value && value.length > 0
}

export function messageIsFilled (value: string|any[]|null|undefined): string {
  if (!isFilled(value)) {
    return Message.required_field
  }
  return ''
}

export function messageIsNumberValid (value: string): string {
  if (!isNumberValid(value)) {
    return Message.invalid_number
  }
  return ''
}

export function validateStartDateRange (firstDate: string|null|undefined, secondDate: string|null|undefined): string {
  if (!firstDate) {
    return Message.required_field
  }
  if (secondDate && moment(firstDate) > moment(secondDate)) {
    return Message.validation_start_date
  }
  return ''
}

export function validateEndDateRange (firstDate: string|null|undefined, secondDate: string|null|undefined): string {
  if (!secondDate) {
    return ''
  }
  if (moment(secondDate) < moment(firstDate)) {
    return Message.validation_end_date
  }
  return ''
}

const REGEX_CODE_POSTAL = /^((0[1-9])|([1-8][0-9])|(9[0-8]))[0-9]{3}$/
export function isCodePostalValid (value: string): boolean {
  return REGEX_CODE_POSTAL.test(value)
}

const REGEX_CODE_COMMUNE = /^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B))[0-9]{3}$/
export function isCodeCommuneValid (value: string): boolean {
  return REGEX_CODE_COMMUNE.test(value)
}

const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export function isMailFormatValid (value: string): boolean {
  return REGEX_EMAIL.test(value)
}

const REGEX_NUMBER = /^[0-9]*$/
export function isNumberValid (value: string): boolean {
  return REGEX_NUMBER.test(value)
}

const REGEX_CUT_DOCUMENT = /^[0-9-, ]*$/
export function isCuttingFormatValid (value: string): boolean {
  return REGEX_CUT_DOCUMENT.test(value)
}

const REGEX_TELEPHONE = /^(?:(?:\+|00)33|(?:\+|00)262|(?:\+|00)590|(?:\+|00)594|(?:\+|00)596|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/
export function isTelephoneValid (value: string): boolean {
  return REGEX_TELEPHONE.test(value)
}

/**
 * Valide une date au format ISO
 */
export function isDateFormatValid (value: string): boolean {
  return moment(value, 'YYYY-MM-DD', true).isValid()
}

const REGEX_SIREN = /^[0-9]{9}$/
export function isNumeroSiren (value: string): boolean {
  return REGEX_SIREN.test(value)
}

/**
 * Permet de verifier si le siren de l'intervenant en paramêtre est valide
 * Retourne une string de l'id de l'erreur ou null s'il n'y en a pas
 */
export function sirenValidity (intervenant: DepositIntervenant): string|null {
  // Si le siren n'existe pas
  if (!intervenant.siren) {
    return 'request_siren_invalid'
  }

  // Si c'est une personne morale de droit public le siren doit commencé par 1 ou 2
  if (intervenant.publicLaw && intervenant.siren[0] !== '1' && intervenant.siren[0] !== '2') {
    return 'request_public_siren_invalid'
  }
  const len = intervenant.siren.length
  const parity = len % 2
  let sum = 0
  for (let i = len - 1; i >= 0; i--) {
    var d = parseInt(intervenant.siren.charAt(i))
    if (i % 2 === parity) { d *= 2 }
    if (d > 9) { d -= 9 }
    sum += d
  }
  if ((sum % 10) !== 0) {
    return 'request_siren_invalid'
  }
  return null
}
