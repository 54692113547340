import React, { FC, useState, useEffect } from 'react'
import {
  Record, RequestListTable, TitleRow
} from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'
import { SUBDIVISION_TABLE_TITLES } from '../constants/InscriptionConstants'
import { Country } from '..'

interface ModalSelectTitleProps {
    records: Record[],
    selectTitle: (idRecord?: string, origin ?: string) => void,
    setShowModalSelectTitle: (showModalSelectTitle: boolean) => void,
    getCountries: () => Promise<void | Country[]>,
    getRecord: (idRecord: string) => Promise<Record>,
    boDisplay?: boolean
}

const ModalSelectTitle: FC<ModalSelectTitleProps> = ({ records, selectTitle, setShowModalSelectTitle, getCountries, getRecord, boDisplay }) => {
  const [uniqueRecordsIds, setUniqueRecordsIds] = useState<string[]>([])
  const [showSelection, setShowSelection] = useState(false)

  const getUniqueRecordsIds = () => {
    const newRecordIds: string[] = []
    records.forEach(record => {
      if (!record.id) {
        setShowSelection(true)
      }
      newRecordIds.push(record.id)
    })
    return newRecordIds
  }

  useEffect(() => {
    setUniqueRecordsIds(getUniqueRecordsIds)
  }, [records])

  return (
    <div>
      {records && records.length > 0 && (
        <div>
          <span>{uniqueRecordsIds.length}<FormattedMessage id='title_validation_modal_select_title_select_label' /></span>
          <RequestListTable
            tableTitle={SUBDIVISION_TABLE_TITLES}
          >
            {showSelection
              ? records.map((record, index) => (
                <TitleRow
                  key={index}
                  recordInfo={record}
                  isTitleSelection
                  getCountries={getCountries}
                  selectTitle={selectTitle}
                  boDisplay={boDisplay}
                  setShowModalSelectTitle={setShowModalSelectTitle}
                />
              ))
              : uniqueRecordsIds?.map(id => (
                <TitleRow
                  key={id}
                  idRecord={id}
                  isTitleSelection
                  selectTitle={selectTitle}
                  setShowModalSelectTitle={setShowModalSelectTitle}
                  getCountries={getCountries}
                  getRecord={getRecord}
                  boDisplay={boDisplay}
                />
              ))}
          </RequestListTable>
        </div>)}
    </div>
  )
}

export default ModalSelectTitle
