import React, { FC } from 'react'
import { Deposit, Transaction, CheckboxField, CardBlock, DepositDocument } from '@inpi-dm/components'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'
import NatureCodesBlock from '../natureCodes/NatureCodesBlock'
import InscriptionDocumentsBlock from '../documents/InscriptionDocumentsBlock'
import { useDispatch } from 'react-redux'
import { storeInscriptionUpdate } from '../../../../store/inscription/inscriptionActions'

interface OwnershipFormProps extends WrappedComponentProps {
  transaction:Transaction|Deposit,
  procedureType:string,
    inscriptionProofDocuments: DepositDocument[][],
    setInscriptionProofDocuments: (inscriptionProofDocuments: DepositDocument[][]) => void
}

const OwnershipForm:FC<OwnershipFormProps> = ({
  transaction,
  procedureType,
  inscriptionProofDocuments,
  setInscriptionProofDocuments,
  intl
}) => {
  const dispatch = useDispatch()

  return (
    <div className='select-type-depot'>
      <div className='row'>
        <header className='col-8 mb-4'>
          <h1><FormattedMessage id='select_inscription_ownership_title' /></h1>
          <span className='subtitle'><FormattedMessage id='select_inscription_ownership_description' /></span>
        </header>
        <InternalReferenceField
          value={transaction.internalReference}
          procedureType={procedureType}
          className='col-4 mb-4'
        />
      </div>
      <div className='mb-3'>
        <CardBlock shadow>
          <CheckboxField
            className='mb-1'
            inputId='acte-appeal'
            label={<FormattedMessage id='ownership_appeal_decommission' />}
            checked={transaction.inscription.isAppealOrDecommission}
            onChange={(e) => {
              dispatch(storeInscriptionUpdate({
                inscription: {
                  ...transaction.inscription,
                  isAppealOrDecommission: e.target.value
                }
              }))
            }}
          />
          <CheckboxField
            className='mb-0'
            inputId='acte-prorogation'
            label={<FormattedMessage id='ownership_prorogation' />}
            checked={transaction.inscription.isProrogation}
            onChange={(e) => {
              dispatch(storeInscriptionUpdate({
                inscription: {
                  ...transaction.inscription,
                  isProrogation: e.target.value
                }
              }))
            }}
          />
        </CardBlock>
      </div>
      <div className='mb-3'>
        <NatureCodesBlock
          transaction={transaction}
          title={intl.formatMessage({ id: 'ownership_form_nature_block_title' })}
          isMultiChoices={false}
        />
      </div>

      <InscriptionDocumentsBlock
        transaction={transaction}
        inscriptionProofDocuments={inscriptionProofDocuments}
        setInscriptionProofDocuments={setInscriptionProofDocuments}
      />
    </div>
  )
}

export default injectIntl(OwnershipForm)
