import React, { FC } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { DepositIntervenant, EventType, FieldStatus, TextField } from '@inpi-dm/components'

interface PersonneContactFieldsProps extends WrappedComponentProps{
    intervenant?: DepositIntervenant,
    onChange: (event: EventType) => void,
    fieldStatus?: FieldStatus | any,
    isEmailReadyOnly?: boolean|undefined,
    isEmailRequired?: boolean|undefined
}

const PersonneContactFields: FC<PersonneContactFieldsProps> = ({
  intervenant = {},
  onChange,
  fieldStatus,
  intl,
  isEmailReadyOnly = false,
  isEmailRequired = false
}) => (
  <div className='row'>
    <div className='col-12 col-md-4'>
      <TextField
        type='phone'
        inputId='phone'
        label={intl.formatMessage({ id: 'field_telephone_label' })}
        value={intervenant.phone || ''}
        onChange={onChange}
        fieldStatus={fieldStatus}
      />
    </div>
    <div className='col-12 col-md-4'>
      <TextField
        type='email'
        inputId='email'
        label={intl.formatMessage({ id: 'field_email_label' })}
        value={intervenant.email || ''}
        onChange={onChange}
        fieldStatus={fieldStatus}
        readOnly={isEmailReadyOnly}
        required={isEmailRequired}
      />
    </div>
  </div>
)

export default injectIntl(PersonneContactFields)
