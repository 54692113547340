import { Transaction } from '@inpi-dm/components'
import { InscriptionFieldStatus } from '../../interfaces/InscriptionInterfaces'

export const INSCRIPTION_UPDATE = 'INSCRIPTION_UPDATE'
export const INSCRIPTION_REMOVE = 'INSCRIPTION_REMOVE'
export const INSCRIPTION_FIELD_STATUS_UPDATE = 'INSCRIPTION_FIELD_STATUS_UPDATE'
export const INSCRIPTION_FIELD_STATUS_RESET = 'INSCRIPTION_FIELD_STATUS_RESET'

export interface InscriptionStoreAction {
  type: string,
  inscription?: Transaction,
}

export interface InscriptionFieldStatusStoreAction {
  type: string,
  fieldStatus?: InscriptionFieldStatus,
}
