import React, { useState, useEffect } from 'react'
import OverviewLabelText from './OverviewLabelText'
import { FormattedMessage } from 'react-intl'
import { Deposit, Transaction, ProtectionExtension, CONFORMITY_NOT_COMPLIANT, Record, EXTENDED_TRANSACTION_STATES } from '@inpi-dm/components'
import OverviewPriorities from './OverviewPriorities'
import RecordService from '../../../../services/record/RecordService'

interface OverviewOptionsProps {
  deposit: Deposit,
  transaction: Transaction,
  depositView?: boolean | null
}

const OverviewOptions = (props: OverviewOptionsProps) => {
  const {
    depositView,
    deposit,
    transaction
  } = props
  const [record, setRecord] = useState<Record>()

  useEffect(() => {
    if (transaction.numNat) {
      RecordService.searchRecords({ search: transaction.numNat }).then(r => {
        if (r.records && r.records.length > 0) {
          setRecord(r.records[0])
        }
      })
    }
  }, [transaction.numNat])

  const getIsExtended = () => {
    if (deposit.options && deposit.options.prorogation) {
      return <FormattedMessage id='overview_deposit_prorogation' />
    } else if (record && EXTENDED_TRANSACTION_STATES.includes(record.state || '')) {
      return <FormattedMessage id={`overview_prorogation_${record.state}`} />
    } else {
      return <FormattedMessage id='overview_deposit_no_boolean' />
    }
  }

  return (
    <div className={`${depositView ? 'w-50' : ''}`}>
      <OverviewLabelText idLabel='overview_deposit_prorogation_title'>
        {getIsExtended()}
      </OverviewLabelText>
      <OverviewLabelText idLabel='overview_deposit_protection_extension_title'>
        {deposit.options && deposit.options.protectionExtensions
          ? deposit.options.protectionExtensions.map((extension: ProtectionExtension, index: number) => {
            return (
              <div key={index}>
                <FormattedMessage id='options_depot_form_extensions_subtitle' /> {extension.label}
              </div>)
          })
          : <FormattedMessage id='overview_deposit_no_boolean' />}
      </OverviewLabelText>
      <OverviewPriorities depositId={transaction.id} priorities={deposit.priorities.filter(p => p.status !== CONFORMITY_NOT_COMPLIANT)} depositView={depositView} />
    </div>
  )
}

export default OverviewOptions
