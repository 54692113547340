import store from '../../../../../store/store'
import {
  containsErrors, DepositDocument, isFilled
} from '@inpi-dm/components'
import { storeInscriptionFieldStatusUpdate } from '../../../../../store/inscription/inscriptionActions'
import Message from '../../../../../constants/Message'
import DocumentValidator from '../../documents/validator/DocumentValidator'

class CorrectionValidator {
    /**
     * Mise a jour de InscriptionFieldStatus
     */
    validateOnChangeStep = (inscriptionProofDocuments: DepositDocument[][]|undefined) => {
      const inscription = store.getState().inscription

      const fieldStatus = {
        natureCodes: inscription.inscription.natureCodes.length === 0 ? Message.correction_form_nature_error : '',
        replaceText: !isFilled(inscription.inscription.changeText) && !isFilled(inscription.inscription.byText) ? Message.correction_form_replace_text_error : '',
        documents: DocumentValidator.hasErrorDocuments(inscriptionProofDocuments ? inscriptionProofDocuments.flat() : inscription.documents, false)
      }

      store.dispatch(storeInscriptionFieldStatusUpdate(fieldStatus))

      return !containsErrors(fieldStatus)
    }
}

export default new CorrectionValidator()
