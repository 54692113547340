import { createContext } from 'react'

export interface ThumbnailContextProps {
  thumbnailsImages: { [key: string]: string },
  setThumbnailsImages: (previousValues: { [key: string]: string }) => void
}

const ThumbnailsContext = createContext<ThumbnailContextProps>({
  thumbnailsImages: {},
  setThumbnailsImages: () => {}
})

export default ThumbnailsContext
