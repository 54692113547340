import Message from './Message'
import { DEPOSIT_TYPES_OPTIONS } from './DepositConstants'
import { INSCRIPTION_TYPES_OPTIONS } from './InscriptionConstants'

export const ORDER_ASC = 'ASC'
export const ORDER_DESC = 'DESC'

export const MODEL_OPEN = 'OPEN'
export const MODEL_CLOSE = 'CLOSE'

export const DEPOSIT_TYPE = {
  value: 'DEPOSIT_TYPE',
  label: Message.procedure_type_deposit
}

export const PROROGATION_TYPE = {
  value: 'PROROGATION_TYPE',
  label: Message.procedure_type_prorogation
}

export const APPEAL_TYPE = {
  value: 'APPEAL_TYPE',
  label: Message.procedure_type_appeal
}

export const INSCRIPTION_TYPE = {
  value: 'INSCRIPTION_TYPE',
  label: Message.procedure_type_inscription
}

export const OFFICIAL_DOCUMENTS_TYPE = {
  value: 'OFFICIAL_DOCUMENTS_TYPE',
  label: Message.procedure_type_official_documents
}

export const PATH_PROCEDURE = {
  [DEPOSIT_TYPE.value]: 'liste-demandes',
  [INSCRIPTION_TYPE.value]: 'liste-inscriptions',
  [PROROGATION_TYPE.value]: 'liste-prorogations',
  [APPEAL_TYPE.value]: 'liste-releves-de-decheance',
  [OFFICIAL_DOCUMENTS_TYPE.value]: 'liste-documents-officiels'
}

export const NEED_HIGHLIGHT = [
  'to_attribute',
  'to_attribute_inscription',
  'to_attribute_prorogation',
  'to_reviewed',
  'to_review_inscription',
  'to_review_prorogation'
]

export const EXAMEN_BASKETS = [
  'brouillon-client',
  'memoire-administratif',
  'memoire-administratif-expire',
  'creation-en-cours',
  'a-attribuer',
  'a-examiner',
  'suspendu',
  'a-retirer',
  'a-refuser',
  'a-rembourser',
  'a-inscrire',
  'a-rejeter',
  'en-réclamation',
  'en-validation',
  'a-retirer',
  'a-rapporter',
  'a-annuler',
  'annule',
  'a-rembourser',
  'sva-svr',
  'alerte-svr-un-mois',
  'alerte-svr-deux-semaines',
  'bermudes'
]

export const PROCEDURE_TYPE = [
  DEPOSIT_TYPE,
  INSCRIPTION_TYPE,
  PROROGATION_TYPE,
  APPEAL_TYPE,
  OFFICIAL_DOCUMENTS_TYPE
]

export const SELECT_TYPE_OPTIONS = {
  [DEPOSIT_TYPE.value]: DEPOSIT_TYPES_OPTIONS,
  [INSCRIPTION_TYPE.value]: INSCRIPTION_TYPES_OPTIONS
}
