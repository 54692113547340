import React, { FC } from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import {
  buildEventType,
  DATE_ISO,
  DateField,
  DateUtils,
  ErrorField,
  EventType,
  FieldStatus, SelectField,
  TextField,
  Title,
  RECORD_INSCRIPTION_ORIGIN
} from '@inpi-dm/components'
import IdPublicationRecordsField from './IdPublicationRecordsField'
import ModelRecordsField from './ModelRecordsField'

interface RecordFormManualProps extends WrappedComponentProps {
  title: Title,
  handleChange: (event: EventType) => void,
  fieldStatus: FieldStatus,
  setFieldStatus: (fieldStatus: FieldStatus) => void,
  publicationsIds?: string[],
  originShown?: boolean,
  classNameCol?: string,
  idPublicationShown?: boolean,
  modelsShown?: boolean,
  modelsSelected?: string[],
  procedure?: string
}

const RecordFormManual: FC<RecordFormManualProps> = ({
  title,
  handleChange,
  fieldStatus,
  setFieldStatus,
  publicationsIds,
  originShown = true,
  idPublicationShown = true,
  modelsShown = false,
  modelsSelected = [],
  classNameCol = 'col-12 col-md-3',
  procedure
}) => {
  /**
   * Ajoute 1 id de publication de reproduction vide
   */
  const addIdPublication = () => {
    const updatePublication = title.publications ? [...title.publications] : []
    updatePublication.push({
      numBopi: '',
      idPublication: ''
    })
    handleChange(buildEventType('publications', updatePublication))
  }

  /**
   * Modifier 1 id de publication de reproduction
   * @param index
   * @param value
   */
  const setIdPublication = (index: number, value: string) => {
    const updatePublication = title.publications ? [...title.publications] : []
    updatePublication[index] = {
      numBopi: '',
      idPublication: value
    }
    handleChange(buildEventType('publications', updatePublication))

    const updateFieldStatusPublication = fieldStatus.publications ? [...fieldStatus.publications] : []
    updateFieldStatusPublication[index] = ''
    setFieldStatus({ ...fieldStatus, publications: updateFieldStatusPublication })
  }

  /**
   * Supprimer 1 id de publication de reproduction
   * @param index
   */
  const deleteIdPublication = (index: number) => {
    const updatePublication = title.publications ? [...title.publications] : []
    updatePublication.splice(index, 1)
    handleChange(buildEventType('publications', updatePublication))

    const updateFieldStatusPublication = fieldStatus.publications ? [...fieldStatus.publications] : []
    updateFieldStatusPublication.splice(index, 1)
    setFieldStatus({ ...fieldStatus, publications: updateFieldStatusPublication })
  }

  /**
   * Ajoute 1 modèle vide
   */
  const addModel = () => {
    const updateModelsSelected: string[] = modelsSelected ? [...modelsSelected] : []
    updateModelsSelected.push('')
    handleChange(buildEventType('modelsSelected', updateModelsSelected))
  }

  /**
   * Modifier 1 modèle
   * @param index
   * @param value
   */
  const setModel = (index: number, value: string) => {
    const updateModelsSelected: string[] = modelsSelected ? [...modelsSelected] : []
    updateModelsSelected[index] = value
    handleChange(buildEventType('modelsSelected', updateModelsSelected))

    const updateFieldStatusModelsSelected: string[] = fieldStatus.modelsSelected ? [...fieldStatus.modelsSelected] : []
    updateFieldStatusModelsSelected[index] = ''
    setFieldStatus({ ...fieldStatus, modelsSelected: updateFieldStatusModelsSelected })
  }

  /**
   * Supprimer 1 modèle
   * @param index
   */
  const deleteModel = (index: number) => {
    const updateModelsSelected: string[] = modelsSelected ? [...modelsSelected] : []
    updateModelsSelected.splice(index, 1)
    handleChange(buildEventType('modelsSelected', updateModelsSelected))

    const updateFieldStatusModelsSelected: string[] = fieldStatus.modelsSelected ? [...fieldStatus.modelsSelected] : []
    updateFieldStatusModelsSelected.splice(index, 1)
    setFieldStatus({ ...fieldStatus, modelsSelected: updateFieldStatusModelsSelected })
  }

  return (
    <>
      {
        originShown &&
          <div className={classNameCol}>
            <SelectField
              inputId='origin'
              label={<FormattedMessage id='records_inscription_origin_label' />}
              value={title?.origin}
              onChange={handleChange}
              fieldStatus={fieldStatus}
              options={RECORD_INSCRIPTION_ORIGIN}
              required
            />
          </div>
      }
      <div className={classNameCol}>
        <DateField
          inputId='depositDate'
          label={<FormattedMessage id='records_inscription_deposit_date_label' />}
          value={title?.depositDate ? DateUtils.formateDateToFormat(title.depositDate, DATE_ISO) : ''}
          onChange={handleChange}
          fieldStatus={fieldStatus}
          required
        />
      </div>
      <div className={classNameCol}>
        <TextField
          inputId='numNat'
          label={<FormattedMessage id='records_inscription_numnat_label' />}
          value={title?.numNat}
          onChange={(event) => {
            handleChange(event)
            // remise a zéro des fieldStatus concernant les reproductions
            setFieldStatus({ ...fieldStatus, publications: '' })
          }}
          fieldStatus={fieldStatus}
          required
        />
      </div>
      {
        idPublicationShown &&
          <div className={classNameCol}>
            <IdPublicationRecordsField
              idPublications={publicationsIds}
              setIdPublication={setIdPublication}
              deleteIdPublication={deleteIdPublication}
              addIdPublication={addIdPublication}
              fieldStatus={fieldStatus.publications}
              displayLabel
              displayAddButton
              procedure={procedure}
            />
            <ErrorField message={fieldStatus.idPublication} />
          </div>
      }
      {
        modelsShown &&
          <div className={classNameCol}>
            <ModelRecordsField
              models={modelsSelected}
              setModel={setModel}
              deleteModel={deleteModel}
              addModel={addModel}
              displayLabel
              displayAddButton
            />
            <ErrorField message={fieldStatus.model} />
          </div>
      }
    </>
  )
}

export default injectIntl(RecordFormManual)
