import { containsErrors, FieldStatus, messageIsFilled, Publication, Title } from '@inpi-dm/components'
import Message from '../../../../../constants/Message'
import store from '../../../../../store/store'
import { storeInscriptionFieldStatusUpdate } from '../../../../../store/inscription/inscriptionActions'
import TitleService from '../../../../../services/transaction/TitleService'

class SelectRecordFormValidator {
  /**
   * Récupère le tableau contenant les informations sur la couleur du champs contenant l'idPublications
   * en fonction du numnat donné
   * @param numNat
   * @param origin
   * @param allPublicationToCheck
   * @param idRecordFo
   */
  getWarningReproduction = (numNat: string, origin: string, allPublicationToCheck : Publication[], idRecordFo: string) : Promise<string[]> => {
    return TitleService.getTitleInfos({ numNat: numNat, origin: origin, idRecordFO: idRecordFo }).then(result => {
      return this.validateExistReproduction(result?.publications, allPublicationToCheck)
    }).catch(() => {
      return Array(allPublicationToCheck.length).fill('warning')
    })
  }

  /**
   * Récupère le tableau contenant les informations sur la couleur du champs contenant l'idPublications
   * (success = vert, warning = orange)
   * @param publicationsExist
   * @param publicationsToTest
   */
  validateExistReproduction = (publicationsExist : Publication[], publicationsToTest : Publication[]) : string[] => {
    // Mise a jours des fieldStatus avec 'success' si nous avons trouver la publication, 'warning' sinon (par default null)
    const arrayOfIdPublication = publicationsExist?.map((publication) => publication.idPublication)

    // Afin de tester les id de publication en retirant les 0 significatifs on parse l'id de pub en int
    const arrayOfLeadingZeroesIdPublication = publicationsExist?.map((publication) => parseInt(publication.idPublication || '').toString())

    return publicationsToTest.map((onePublication: Publication) => {
      if (arrayOfIdPublication?.includes(onePublication.idPublication) || arrayOfLeadingZeroesIdPublication?.includes(onePublication.idPublication)) {
        return 'success'
      } else {
        return 'warning'
      }
    }) || []
  }

  /**
   * Valide le titre
   * @param title
   */
  validateMandatory = (title : Title) : FieldStatus => {
    let publicationIsOk = false

    if (title.publications && title.publications.length > 0) {
      publicationIsOk = true
    }

    return {
      origin: messageIsFilled(title.origin),
      depositDate: messageIsFilled(title.depositDate),
      numNat: messageIsFilled(title.numNat),
      idPublication: publicationIsOk ? '' : Message.required_field,
      notFound: ''
    }
  }

  /**
   * Valide tous les titres
   */
  validateOnChangeStep = () => {
    const titles = store.getState().inscription.inscription?.titles || []

    let allFieldStatus
    if (titles.length === 0) {
      const fieldStatus = this.validateMandatory({})
      allFieldStatus = [{ ...fieldStatus, notFound: Message.warning_at_least_one_title }]
    } else {
      allFieldStatus = titles?.map((title) => this.validateMandatory(title))
    }

    const error = containsErrors(allFieldStatus)

    store.dispatch(storeInscriptionFieldStatusUpdate({
      titles: allFieldStatus
    }))

    return !error
  }
}

export default new SelectRecordFormValidator()
