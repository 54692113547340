import React, { FC, ReactNode } from 'react'
import { EventType, FieldStatus } from '../FormInterfaces'
import { buildEventType, ErrorField } from '../../index'

interface CheckboxFieldProps {
  inputId: string,
  name?: string,
  label: ReactNode,
  checked?: boolean,
  onChange?: (event: EventType) => void
  fieldStatus?: FieldStatus,
  className?: string,
  labelClassName?: string,
  disabled?: boolean
}

const CheckboxField: FC<CheckboxFieldProps> = ({
  inputId,
  name,
  label,
  checked = false,
  onChange,
  fieldStatus = {},
  className = '',
  labelClassName = '',
  disabled
}) => {
  const handleChange = () => {
    onChange && onChange(buildEventType(name || inputId, !checked))
  }

  return (
    <div className={`form-group custom-control custom-checkbox ${className}`}>
      <input
        type='checkbox'
        className='custom-control-input'
        id={inputId}
        name={name || inputId}
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
      />
      <label className={`form-check-label custom-control-label ${labelClassName}`} htmlFor={inputId}>
        {label}
      </label>

      <ErrorField message={fieldStatus[inputId]} />
    </div>
  )
}

export default CheckboxField
