import React, { FC, useEffect, useState } from 'react'
import { Transaction, FilAriane } from '@inpi-dm/components'
import { Link, useHistory } from 'react-router-dom'
import { NotificationInterface } from '@inpi-dm/components/src/interfaces/DepositInterfaces'
import PaymentForm from './PaymentForm'
import { PriceDetails } from '../../../../../interfaces/Price'
import { FormattedMessage } from 'react-intl'
import { PAYMENT_OBJECT } from '../../constants/DepositConstants'
import TablePrices from './TablePrices'
import { PAYMENT_URL_NOTIFICATION } from '../../constants/PaymentConstants'
import TransactionService from '../../services/transaction/TransactionService'
import PriceService from '../../services/content/PriceService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

interface PaymentNotificationProps {
    id: string,
    idNotification: string,
}

const PaymentNotification: FC<PaymentNotificationProps> = ({
  id,
  idNotification
}) => {
  const history = useHistory()
  const [transaction, setTransaction] = useState<Transaction>()
  const [notification, setNotification] = useState<NotificationInterface>()

  useEffect(() => {
    TransactionService.getTransaction(id)
      .then((transaction) => {
        if (transaction.file && !transaction.file.idTransaction) {
          transaction = {
            ...transaction,
            file: {
              ...transaction.file,
              idTransaction: transaction.id
            }
          }
        }
        setTransaction(transaction)
        setNotification(transaction.notifications?.find((notification) => notification.id === idNotification))
      })
      .catch(() => {
        history.replace('/liste-demandes')
      })
  }, [id, idNotification, history])

  const getPricesNotification = () => {
    if (!notification) {
      return {}
    }
    const prices: PriceDetails = {}
    if (notification.prices) {
      notification.prices.forEach((price) => (
        prices[price.code] = {
          price: price.price,
          label: price.label,
          quantity: price.quantity
        }
      ))
      return prices
    } else {
      return {}
    }
  }

  return (
    <div>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <Link key={`folder-${id}`} to={`/liste-demandes/en-cours-examen/demande/${transaction?.id}`}><FormattedMessage id='request_title' />
          {transaction && (transaction.numNat ? transaction.numNat : transaction.id)}
        </Link>
        <span><FormattedMessage id='overview_deposit_notification_one_payment' /></span>
      </FilAriane>
      <div className='container'>
        {transaction ? (
          <PaymentForm
            label={'Paiement d\'une notification'}
            overviewPrices={<TablePrices prices={getPricesNotification()} />}
            object={transaction.file ? transaction.file : transaction}
            urlType={PAYMENT_URL_NOTIFICATION}
            objectName={PAYMENT_OBJECT.NOTIFICATION}
            objectId={notification?.id}
            totalAmount={PriceService.getTotalAmount(getPricesNotification())}
            title={
              <>
                <FormattedMessage id='overview_deposit_notification_one_payment' /> - <FormattedMessage id='request_title' />
                {transaction && (transaction.numNat ? transaction.numNat : transaction.id)}
              </>
            }
          >
            <div className='row mx-0 my-3 justify-content-center'>
              <Link className='col-2 btn btn-block btn-outline-gris' to={`/liste-demandes/en-cours-examen/demande/${transaction?.id}`}>
                <FormattedMessage id='button_return' />
              </Link>
            </div>
          </PaymentForm>
        )
          : (
            <div className='d-flex justify-content-center mt-4'>
              <FontAwesomeIcon className='loader' icon={faSpinner} />
            </div>
          )}
      </div>
    </div>
  )
}

export default PaymentNotification
