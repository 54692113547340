import React, { FC, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import {
  EventType,
  RequestListTable, SubmitButton,
  TextField, ProrogationInscriptionRequest, FileBrowserField,
  DepositDocument,
  Transaction, FieldStatus, SelectOption, SelectField
} from '@inpi-dm/components'
import { storeProrogationUpdate } from '../../../../store/prorogation/prorogationActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import {
  PROROGATION_INSCRIPTION_REQUEST_TABLE_TITLES,
  PROROGATION_INSCRIPTION_REQUEST_TABLE_TITLES_WITHOUT_HELP
} from '../../../../constants/RequestConstants'
import TransactionService from '../../../../services/transaction/TransactionService'
import cloneDeep from 'lodash.clonedeep'
import Message from '../../../../constants/Message'
import TitleService from '../../../../services/transaction/TitleService'

interface ProrogationInscriptionRequestsProps {
    transaction: Transaction,
    inscriptionRequestsToTransfer: ProrogationInscriptionRequest[],
    otherInscriptionRequests: ProrogationInscriptionRequest[],
    fieldStatus: FieldStatus[],
    inscriptionRequestsDocuments: DepositDocument[],
    setInscriptionRequestsDocuments: (inscriptionRequestsDocuments: DepositDocument[]) => void
}

const ProrogationInscriptionRequests: FC<ProrogationInscriptionRequestsProps> = ({
  transaction,
  otherInscriptionRequests,
  inscriptionRequestsToTransfer,
  fieldStatus,
  inscriptionRequestsDocuments,
  setInscriptionRequestsDocuments
}) => {
  const dispatch = useDispatch()
  const [inscriptionsOptions, setInscriptionsOptions] = useState<SelectOption[]>([])

  useEffect(() => {
    getInscriptionsOptions()
  }, [transaction.prorogation?.titles])

  /**
   * Récupère les numero d'ordre d'inscription lié aux titres
   */
  const getInscriptionsOptions = async (): SelectOption[] => {
    let options: SelectOption[] = []
    if (transaction.prorogation) {
      for (const title of transaction.prorogation.titles) {
        const result = await TitleService.getTitleInfos({ numNat: title.numNat, isForProrogation: true }, false).catch(() => {
          return undefined
        })
        if (result && result.records) {
          result.records.forEach(record => {
            if (record.inscriptions) {
              [...new Map([...record.inscriptions].map(item =>
                [item.numOrder, item])).values()]
                .filter(inscription => inscription.numOrder || inscription.numInscription)
                .map(inscription => {
                  options = [...options, {
                    label: `${inscription.numOrder || ' '}${inscription.numOrder && inscription.numInscription ? ' - ' : ''}${inscription.numInscription || ''}`,
                    value: `${inscription.numOrder} - ${inscription.numInscription}`
                  }]
                })
            }
          })
        }
      }
    }
    setInscriptionsOptions([...new Map([...options].map(option => [option.label, option])).values()])
  }

  /**
   * Ajout d'une nouvelle demande d'inscription
   */
  const handleNewRequest = () => {
    const newInscriptionRequest = {
      numOrder: '',
      numInscription: '',
      document: undefined,
      isEditable: true,
      documentInternalName: undefined,
      needPost: true,
      isOwnershipTransfer: true
    }
    setInscriptionRequestsDocuments([...inscriptionRequestsDocuments, newInscriptionRequest])
    dispatch(storeProrogationUpdate({
      prorogation: {
        ...transaction.prorogation,
        inscriptionRequests: [...inscriptionRequestsToTransfer, newInscriptionRequest].concat([...otherInscriptionRequests])
      }
    }))
  }

  /**
     * Suppression d'une demande d'inscription
     * @param index
     */
  const deleteInscriptionRequest = async (index: number) => {
    const newInscriptionRequests = cloneDeep(inscriptionRequestsToTransfer)
    const newDocs = [...inscriptionRequestsDocuments]
    let updateDocumentsList: DepositDocument[] = []
    newDocs.splice(index, 1)
    setInscriptionRequestsDocuments(newDocs)
    if (newInscriptionRequests[index].documentInternalName) {
      await TransactionService.deleteDocumentFile(transaction.id, newInscriptionRequests[index].documentInternalName)
      updateDocumentsList = [...transaction.documents.filter(doc => doc.internalName !== newInscriptionRequests[index].documentInternalName)]
      delete (newInscriptionRequests[index].documentInternalName)
    }
    newInscriptionRequests.splice(index, 1)
    dispatch(storeProrogationUpdate({
      documents: updateDocumentsList.length > 0 || [...transaction.documents || []],
      prorogation: {
        ...transaction.prorogation,
        inscriptionRequests: newInscriptionRequests.concat([...otherInscriptionRequests])
      }
    }))
  }

  /**
     * Modification du document associé à la demande d'inscription
     * @param event
     * @param index
     */
  const handleChangeInscriptionRequestDoc = async (event: EventType, index: number) => {
    const { value } = event.target
    const newDocument: DepositDocument = value !== null ? {
      name: value.name,
      file: value,
      needPost: true
    } : { needPost: true }

    const documents = [...inscriptionRequestsDocuments]
    documents[index] = newDocument
    setInscriptionRequestsDocuments(documents)
    if (value === null && inscriptionRequestsToTransfer[index].documentInternalName) {
      const newInscriptionRequests = cloneDeep(inscriptionRequestsToTransfer)
      return TransactionService.deleteDocumentFile(transaction.id, newInscriptionRequests[index].documentInternalName).then(() => {
        const updateDocumentsList = [...transaction.documents.filter(doc => doc.internalName !== newInscriptionRequests[index].documentInternalName)]
        delete (newInscriptionRequests[index].documentInternalName)
        dispatch(storeProrogationUpdate({
          documents: updateDocumentsList,
          prorogation: {
            ...transaction.prorogation,
            inscriptionRequests: newInscriptionRequests.concat([...otherInscriptionRequests])
          }
        }))
      })
    }
  }

  /**
   * Change la valeur du numéro d'ordre
   * @param event
   * @param index
   */
  const changeNumOrder = (event: EventType, index: number) => {
    const newInscriptionRequests = cloneDeep(inscriptionRequestsToTransfer)
    newInscriptionRequests[index].numOrder = event.target.value
    dispatch(storeProrogationUpdate({
      prorogation: {
        ...transaction.prorogation,
        inscriptionRequests: newInscriptionRequests.concat([...otherInscriptionRequests])
      }
    }))
  }

  /**
   * Change la valeur des champs numOrder et numInscription lors de la sélection de l'aide à la saisie
   * @param event
   * @param index
   */
  const changeInscriptionInfo = (event: EventType, index: number) => {
    const newInscriptionRequests = cloneDeep(inscriptionRequestsToTransfer)
    const newInfo = event.target.value.split(' - ')
    newInscriptionRequests[index].numOrder = newInfo[0] !== 'undefined' ? newInfo[0] : ''
    newInscriptionRequests[index].numInscription = newInfo[1] !== 'undefined' ? newInfo[1] : ''
    dispatch(storeProrogationUpdate({
      prorogation: {
        ...transaction.prorogation,
        inscriptionRequests: newInscriptionRequests.concat([...otherInscriptionRequests])
      }
    }))
  }

  return (
    <>
      <RequestListTable
        tableTitle={inscriptionsOptions.length > 0 ? PROROGATION_INSCRIPTION_REQUEST_TABLE_TITLES : PROROGATION_INSCRIPTION_REQUEST_TABLE_TITLES_WITHOUT_HELP}
        showPagination={false}
      >
        <thead>
          {inscriptionRequestsToTransfer.map((inscriptionRequest, index) => (
            <tr key={index}>
              {
                inscriptionsOptions.length > 0 &&
                  <td>
                    <SelectField
                      inputId='help-inscription'
                      options={inscriptionsOptions}
                      placeholder={Message.select_placeholder}
                      onChange={(e:EventType) => {
                        changeInscriptionInfo(e, index)
                      }}
                      value={inscriptionsOptions.find(inscription => inscription.value === inscriptionRequest.numOrder)?.value || null}
                    />
                  </td>
              }
              <td className='is-validated'>
                <TextField
                  inputId='numOrder'
                  value={inscriptionRequest.numOrder}
                  onChange={(e:EventType) => {
                    changeNumOrder(e, index)
                  }}
                  fieldStatus={fieldStatus[index]}
                />
              </td>
              <td className='is-validated'>
                <TextField
                  inputId='numInscription'
                  value={inscriptionRequest.numInscription}
                  onChange={(e:EventType) => {
                    const newInscriptionRequests = cloneDeep(inscriptionRequestsToTransfer)
                    newInscriptionRequests[index].numInscription = e.target.value
                    dispatch(storeProrogationUpdate({
                      prorogation: {
                        ...transaction.prorogation,
                        inscriptionRequests: newInscriptionRequests.concat([...otherInscriptionRequests])
                      }
                    }))
                  }}
                />
              </td>
              <td>
                <FileBrowserField
                  label=''
                  inputId={`file-inscription-${index}`}
                  value={inscriptionRequestsDocuments[index].name ? [inscriptionRequestsDocuments[index]] : []}
                  onChange={(e) => handleChangeInscriptionRequestDoc(e, index)}
                  maxNumberFile={1}
                  acceptApplication='application/pdf'
                  butonLabel={
                    <div className='form-control placeholder'>
                      <FormattedMessage id='button_find_file' />
                    </div>
                  }
                />
              </td>
              <td>
                <SubmitButton
                  className='btn-icon-secondary'
                  onClick={() => deleteInscriptionRequest(index)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </SubmitButton>
              </td>
            </tr>
          ))}
        </thead>
      </RequestListTable>
      <SubmitButton className='btn btn-link font-weight-bold text-primary' onClick={handleNewRequest}>
        <FontAwesomeIcon icon={faPlus} size='xs' className='mr-2' />
        <FormattedMessage id='prorogation_new_inscription_request_button' />
      </SubmitButton>
    </>
  )
}

export default ProrogationInscriptionRequests
