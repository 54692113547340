import React, { FC, useEffect, useState } from 'react'
import {
  APPEAL_TYPE,
  containsErrors,
  DATE_ISO,
  DateUtils,
  DepositDocument,
  FilAriane
  , ContributorTitle, ModalComponent
} from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import StepContainer from '../../stepper/StepContainer'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import TransactionService from '../../../services/transaction/TransactionService'
import qs from 'qs'
import RecordService from '../../../services/record/RecordService'
import { storeAppealFieldStatusReset, storeAppealRemove, storeAppealUpdate } from '../../../store/appeal/appealActions'
import AppealService from '../../../services/transaction/AppealService'
import SelectRecordFormValidator from './record/validator/SelectRecordFormValidator'
import RecipientForm from '../../intervenant/RecipientForm'
import SignatoryForm from '../../intervenant/SignatoryForm'
import RecipientValidator from './recipient/validator/RecipientValidator'
import SignatoryValidator from './signatory/validator/SignatoryValidator'
import DepositorForm from '../../intervenant/DepositorForm'
import DepositorValidator from './depositor/validator/DepositorValidator'
import DetailsForm from './details/DetailsForm'
import DetailsValidator from './details/validator/DetailsValidator'
import OverviewFormAppeal from './overview/OverviewFormAppeal'
import OverviewFormAppealValidator from './overview/validator/OverviewFormAppealValidator'
import PaymentAppeal from './payment/PaymentAppeal'
import SelectAppealRecords from './record/SelectAppealRecords'
import IntervenantService from '../../../services/transaction/IntervenantService'
import { storeContributorsTitleRemove, storeContributorsTitleUpdate } from '../../../store/contributorsTitle/contributorsTitleActions'
import ContentService from '../../../services/content/ContentService'

interface AppealFormProps extends RouteComponentProps<{id: string}> {
  isNew?: boolean
}

const AppealForm: FC<AppealFormProps> = ({
  isNew = false,
  match,
  history,
  location
}) => {
  const dispatch = useDispatch()
  const appeal = useSelector((state : RootStateOrAny) => state.appeal)
  const appealFieldStatus = useSelector((state : RootStateOrAny) => state.appealFieldStatus) || []
  const infosByNumNat = useSelector((state : RootStateOrAny) => state.contributorsTitle)

  const [isNewAppeal, setIsNewAppeal] = useState(isNew)
  const [isReady, setIsReady] = useState(false)
  const [appealDocuments, setAppealDocuments] = useState<DepositDocument[]>([])
  const [additionalParts, setAdditionalParts] = useState<DepositDocument[]>([])
  const [acteDocuments, setActeDocuments] = useState<DepositDocument[]>([])
  const [flatContributorsTitle, setFlatContributorsTitle] = useState<ContributorTitle[]>([])
  const [showRgpd, setShowRgpd] = useState(false)
  const [rgpdContent, setRdpdContent] = useState('')

  // Initialisation du relevé de déchéance à modifier
  useEffect(() => {
    dispatch(storeAppealFieldStatusReset())
    dispatch(storeAppealRemove())
    dispatch(storeContributorsTitleRemove())
    if (!isNew) {
      TransactionService.getTransaction(match.params.id)
        .then(result => {
          dispatch(storeAppealUpdate(result))
        }).finally(() => setIsReady(true))
    } else {
      ContentService.getEditorialBlockByCode('RGPD-REC').then((res) => {
        if (res.content) {
          setRdpdContent(res.content)
          setShowRgpd(true)
        }
      })
    }

    // Si le relevé de déchéance a été faite à partir de titres, on récupère les ids dans l'url
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (query.from) {
      const idRecords = query.from.split(',')
      if (idRecords.length > 0) {
        initAppeal(idRecords)
      } else {
        setIsReady(true)
      }
    } else {
      isNew &&
      setIsReady(true)
    }
  }, [isNew])

  useEffect(() => {
    setFlatContributorsTitle(IntervenantService.getFlatContributorsTitle())
  }, [infosByNumNat])

  /**
   * Initialise le relevé de déchéance si celui ci a été faite à partir de titres
   * @param idRecords
   */
  const initAppeal = (idRecords: string[]) => {
    let newContributorsTitle: [] = []
    const newTitlePromise = idRecords.map(async (idRecord: string) => {
      return RecordService.getRecord(idRecord).then(record => {
        if (record.numNat) {
          newContributorsTitle = {
            ...newContributorsTitle,
            [record.numNat]: {
              depositor: record.depositor,
              holders: record.holders,
              recipient: record.recipient
            }
          }
        }
        return {
          depositDate: DateUtils.formateDateToFormat(record.depositDate, DATE_ISO),
          numNat: record.numNat,
          publications: RecordService.getPublications(record),
          models: record.models,
          origin: record.country,
          state: record.state
        }
      }
      )
    })
    Promise.all(newTitlePromise).then(newTitles => {
      dispatch(storeAppealUpdate({ ...appeal, appeal: { titles: newTitles } }))
      dispatch(storeContributorsTitleUpdate(newContributorsTitle))
    }).finally(() => setIsReady(true))
  }

  /**
   * Si la dernière étape est valide, on submit le formulaire
   */
  const handleSubmit = () => {
    history.push('/')
  }

  /**
   * Sauvegarde du type du relevé de déchéance
   */
  const saveAppealFirstStep = () => {
    if (isNewAppeal) {
      setIsNewAppeal(false)
      // Creation puis sauvegarde
      return AppealService.createAppeal().then(() => {
        return AppealService.updateAppealBDDFromStore(['titles'])
      })
    } else {
      // sauvegarde
      return AppealService.updateAppealBDDFromStore(['titles'])
    }
  }

  /** Vérifie que tous les champs obligatoires sont remplis pour une étape du menu**/
  const checkValidity = (stepName: string): boolean => {
    const status = appealFieldStatus[stepName]
    if (status === undefined) {
      return false
    }
    return !containsErrors(status)
  }

  return (
    <>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id='breadcrumb_new_appeal' /></span>
      </FilAriane>

      <StepContainer
        className='mb-5 is-validated'
        listTitle={<FormattedMessage id='appeal_step_list_title' />}
        onCancel={() => history.push('/')}
        onSubmit={handleSubmit}
        views={[
          {
            id: 'titles',
            label: <FormattedMessage id='records_appeal' />,
            badge: '1',
            validateGoToStep: SelectRecordFormValidator.validateOnChangeStep,
            component: isReady ? <SelectAppealRecords validateMandatory={SelectRecordFormValidator.validateMandatory} /> : <div />,
            onGoToStep: saveAppealFirstStep,
            isValidated: checkValidity('titles')
          },
          {
            id: 'intervenant',
            label: <FormattedMessage id='intervenant_form_title' />,
            badge: '2',
            isValidated: checkValidity('signatory')
          },
          {
            id: 'intervenant_applicant',
            label: <FormattedMessage id='intervenant_applicant' />,
            level: 1,
            validateGoToStep: DepositorValidator.validateOnChangeStep,
            component: (
              <DepositorForm
                object={appeal}
                fieldStatus={appealFieldStatus}
                procedureType={APPEAL_TYPE.value}
                defaultIntervenantlist={flatContributorsTitle}
              />
            ),
            onGoToStep: () => AppealService.updateAppealBDDFromStore(['depositor'])
          }, {
            id: 'intervenant_destinataire',
            label: <FormattedMessage id='intervenant_onglet_destinataire' />,
            level: 1,
            validateGoToStep: RecipientValidator.validateOnChangeStep,
            component: (
              <RecipientForm
                object={appeal}
                fieldStatus={appealFieldStatus}
                procedureType={APPEAL_TYPE.value}
                findIntervenantsList={['recipient']}
                defaultIntervenantlist={flatContributorsTitle}
              />
            ),
            onGoToStep: () => AppealService.updateAppealBDDFromStore(['recipient'])
          }, {
            id: 'intervenant_signatory',
            label: <FormattedMessage id='intervenant_onglet_signatory' />,
            level: 1,
            validateGoToStep: SignatoryValidator.validateOnChangeStep,
            component: <SignatoryForm object={appeal} fieldStatus={appealFieldStatus} procedureType={APPEAL_TYPE.value} />,
            onGoToStep: () => AppealService.updateAppealBDDFromStore(['signatory'])
          },
          {
            id: 'details',
            label: <FormattedMessage id='appeal_details' />,
            badge: '3',
            validateGoToStep: () => DetailsValidator.validateOnChangeStep(appealDocuments, additionalParts, acteDocuments),
            component:
                (
                  <DetailsForm
                    additionalParts={additionalParts}
                    setAdditionalParts={setAdditionalParts}
                    acteDocuments={acteDocuments}
                    setActeDocuments={setActeDocuments}
                    appealDocuments={appealDocuments}
                    setAppealDocuments={setAppealDocuments}
                  />
                ),
            onGoToStep: () => AppealService.updateAppealBDDFromStore(['inscriptionRequests', 'appealDecommission', 'appealType'], acteDocuments.concat(additionalParts).concat(appealDocuments))
              .then(() => {
                setActeDocuments([])
                setAdditionalParts([])
                setAppealDocuments([])
              }
              ),
            isValidated: checkValidity('appealType')
          }, {
            id: 'overview',
            label: <FormattedMessage id='overview_deposit_form_short_title' />,
            badge: '4',
            validateGoToStep: OverviewFormAppealValidator.validateOnChangeStep,
            component: <OverviewFormAppeal />,
            isValidated: checkValidity('overviewSimilarInformation')
          },
          {
            id: 'payment',
            label: <FormattedMessage id='payment' />,
            badge: '5',
            component: <PaymentAppeal transaction={appeal} />,
            stepButtonsAsChildren: true
          }
        ]}
      />
      <ModalComponent
        title={<FormattedMessage id='rgpd_title' />}
        isNotCancellable
        customContent={() => <div dangerouslySetInnerHTML={{ __html: rgpdContent }} />}
        handleClose={() => setShowRgpd(false)}
        show={showRgpd}
        onClick={() => setShowRgpd(false)}
        customButtonId='common_start_form'
        size='xl'
      />
    </>
  )
}

export default withRouter(AppealForm)
