import store from '../../../../../store/store'
import {
  containsErrors,
  Transaction,
  IntervenantValidator,
  PERSONNE_PHYSIQUE,
  FieldStatus
} from '@inpi-dm/components'
import { storeProrogationFieldStatusUpdate } from '../../../../../store/prorogation/prorogationActions'

class AgentValidator {
  /**
   * Validation du Mandataire
   * @param transaction
   */
  validateMandatory = (transaction: Transaction) : FieldStatus => {
    let fieldStatus

    if (transaction.agent || IntervenantValidator.containsAgentDocument(transaction)) {
      fieldStatus = IntervenantValidator.validateAgent(transaction.agent || { type: PERSONNE_PHYSIQUE.value })
    } else {
      fieldStatus = {}
    }

    return fieldStatus
  }

  /**
   * Mise a jour de InscriptionFieldStatus
   */
  validateOnChangeStep = () => {
    const prorogation = store.getState().prorogation

    const fieldStatus = this.validateMandatory({ ...prorogation })

    store.dispatch(storeProrogationFieldStatusUpdate({
      agent: fieldStatus
    }))

    return !containsErrors(fieldStatus)
  }
}

export default new AgentValidator()
