import React, { FC, useEffect, useState } from 'react'
import {
  containsErrors,
  ModalComponent,
  ModelDeposit,
  ReproductionLightDeposit, SubmitButton,
  DOCUMENT_TYPES,
  DepositDocument
} from '@inpi-dm/components'
import ReproductionCompletionValidator from './validator/ReproductionCompletionValidator'
import DepositService from '../../../../../../services/transaction/DepositService'
import { FormattedMessage } from 'react-intl'
import ReproductionCompletionFields from './ReproductionCompletionFields'
import TransactionService from '../../../../../../services/transaction/TransactionService'

interface ModalReproductionCompletionProps {
    idTransaction: string,
    initialsModels: ModelDeposit[],
    onSubmit: (models: ModelDeposit[]) => void,
    show: boolean,
    handleClose: () => void,
}

const ModalReproductionCompletion: FC<ModalReproductionCompletionProps> = ({
  idTransaction,
  initialsModels,
  onSubmit,
  show,
  handleClose
}) => {
  const [models, setModels] = useState<ModelDeposit[]>(initialsModels)
  const [fieldStatus, setFieldStatus] = useState({})
  const [regularizationReproductions, setRegularizationReproductions] = useState(new Map())

  useEffect(() => {
    setModels(initialsModels)
  }, [initialsModels])

  /**
     * Met à jour une reproduction dans un modèle
     *
     * @param reproduction Reproduction à mettre à jour
     * @param indexModel Index du modèle de la reproduction
     * @param index Index dans le modèle de la reproduction
     */
  const handleChangeReproduction = (reproduction: ReproductionLightDeposit, indexModel: number, index: number) => {
    const updatedModel = { ...models[indexModel] }
    updatedModel.reproductions[index] = { ...reproduction }

    const updatedModels = [...models]
    updatedModels[indexModel] = updatedModel

    setModels(updatedModels)
  }

  /**
   * Met à jours les reproductions avec les informations renseignées
   */
  const updateReproduction = async () => {
    for (const model of models) {
      for (const reproduction of model.reproductions.filter(repro => repro.selected)) {
        await DepositService.updateReproduction(idTransaction, model.id, reproduction.file.internalName, reproduction.position, reproduction)
      }
    }
  }

  /**
   * Enregistre les nouvelles images
   */
  const uploadDocument = async () => {
    // Dans le cas où on a renseigné des nouvelles images, elles sont enregistrées comme repro à régulariser dans les documents
    if (regularizationReproductions) {
      for (const [, regulRepro] of regularizationReproductions) {
        await TransactionService.postDocumentFile(idTransaction, DOCUMENT_TYPES.DOCUMENT_TYPE_REGULARIZE_REPRO, regulRepro.file, regulRepro.srcName)
      }
    }
  }

  /**
     * Enregistre les reproductions sur le serveur si toutes ont été complétées
     */
  const handleSubmit = () => {
    const updatedFieldStatus = ReproductionCompletionValidator.validateAll(models)
    setFieldStatus(updatedFieldStatus)

    if (!containsErrors(updatedFieldStatus)) {
      return updateReproduction().then(() => uploadDocument()).then(() => onSubmit(models))
    } else {
      return updatedFieldStatus
    }
  }

  /**
   * Ajout d'une reproduction à régulariser
   * @param indexModel
   * @param indexRepro
   * @param document
   */
  const addRegularizationReproduction = (indexModel: string, indexRepro: string, document: DepositDocument) => {
    setRegularizationReproductions(prev => new Map([...prev, [`${indexModel}.${indexRepro}`, document]]))
  }

  /**
   * Suppression d'une reproduction à régulariser
   * @param indexModel
   * @param indexRepro
   */
  const deleteRegularizationReproduction = (indexModel: string, indexRepro: string) => {
    const regulReproductions = new Map<string, DepositDocument>(regularizationReproductions)
    if (regulReproductions) {
      regulReproductions.delete(`${indexModel}.${indexRepro}`)
      setRegularizationReproductions(regulReproductions)
    }
  }

  /**
     * Affiche le contenu de la modale de complétion de reproduction
     */
  const displayForm = () => {
    const reproductionsList = models.map((model, indexModel) =>
      model.reproductions.map((reproduction, indexRepro) => (
        reproduction.selected &&
            (
              <ReproductionCompletionFields
                key={`${indexModel}.${indexRepro}`}
                idTransaction={idTransaction}
                idModel={model.id}
                indexModel={indexModel}
                index={indexRepro}
                reproduction={reproduction}
                regularizationReproduction={regularizationReproductions && regularizationReproductions.get(`${indexModel}.${indexRepro}`)}
                addRegularizationReproduction={addRegularizationReproduction}
                deleteRegularizationReproduction={deleteRegularizationReproduction}
                onChange={handleChangeReproduction}
                fieldStatus={fieldStatus[`${indexModel}.${indexRepro}`]}
              />
            )
      ))
    )

    return (
      <div>
        <p>
          <FormattedMessage id='request_reproduction_completion_description' />
        </p>
        {reproductionsList}
        <div className='d-flex justify-content-center'>
          <SubmitButton
            className='btn-primary'
            onClick={handleSubmit}
          >
            <FormattedMessage id='button_validate' />
          </SubmitButton>
        </div>
      </div>
    )
  }

  return (
    <ModalComponent
      size='xl'
      title={<FormattedMessage id='request_reproduction_completion_title' />}
      show={show}
      customContent={displayForm}
      handleClose={handleClose}
      hideFooter
    />
  )
}

export default ModalReproductionCompletion
