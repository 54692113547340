import React from 'react'
import CountItems from './CountItems'

const Dashboard = () => {
  return (
    <div className='dashboard row'>
      <CountItems isForDashboard />
    </div>
  )
}

export default Dashboard
