import { DepositIntervenant, PERSONNE_PHYSIQUE } from '..'

class FormatUtils {
  /**
   * Formatte un prix en euros avec deux décimales
   *
   * @param price un prix entier en centimes
   */
  formatPrice = (price: number|null|undefined): string => {
    if (!price) {
      return ''
    }
    return (price / 100).toFixed(2).replace('.', ',')
  }

  formatContributors = (contributors: DepositIntervenant[]): string => {
    return contributors.map(r => {
      if (r.type === PERSONNE_PHYSIQUE.value) {
        const firstname = r.firstname || ''
        return (`${firstname} ${r.lastname}`)
      } else {
        return r.companyName
      }
    }).join(', ')
  }
}

export default new FormatUtils()
