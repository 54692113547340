import { faChevronDown, faChevronRight, faEdit, faExclamationTriangle, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Country,
  FieldStatus,
  MODEL_OPEN,
  Preview,
  ReproductionLightDeposit,
  STATUS_WITHDRAWN,
  SubmitButton,
  Transaction,
  containsErrors
} from '@inpi-dm/components'
import React, { FC, memo, useMemo } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import { FormattedMessage } from 'react-intl'
import { DepositDocument, DepositFile, ModelDeposit } from '../../../../../../components/src/interfaces/ModelInterfaces'
import DepositService from '../../../../services/transaction/DepositService'
import ReproductionOverview from './reproductions/ReproductionOverview'
import { DepositPriorityOptions } from '@inpi-dm/components/src/interfaces/PriorityInterfaces'

interface ContextAwareToggleProps {
    eventKey: string,
}

const ContextAwareToggle: FC<ContextAwareToggleProps> = memo(function ContextAwareToggle ({ eventKey }) {
  const isCurrentEventKey = eventKey === MODEL_OPEN

  return (
    <div className={isCurrentEventKey ? 'mr-1' : 'mr-2'}>
      <FontAwesomeIcon icon={isCurrentEventKey ? faChevronDown : faChevronRight} />
    </div>
  )
})

interface OverviewModelProps {
    oneModel: ModelDeposit,
    indexModel: number,
    isOpen: boolean,
    toogleCardModel: (indexModel: number) => void,
    nbCurrent?: number,
    depositId?: string,
    fieldStatus?: FieldStatus,
    countries?: Country[],
    getReproduction: (idDeposit: string, idModel: string, file: DepositFile| undefined) => Promise<string>,
    isEditable?: boolean,
    hasExchangeRepro?: (repro: ReproductionLightDeposit) => boolean | undefined,
    hasExchangeReproToAdd?: (repro: ReproductionLightDeposit) => boolean,
    changeModel?: (indexModel: number) => void,
    deleteModel?: (indexModel: number) => void,
    showPreview?: (model: ModelDeposit, repro: ReproductionLightDeposit) => void,
    documents?: DepositDocument[] | undefined,
    fileToAdd?: DepositDocument[],
    setfileToAdd?: (fileToAdd: DepositDocument[] | ((prevFiles: DepositDocument[]) => DepositDocument[])) => void,
    setShowReproModal?: (showReproModal: boolean) => void,
    setFileToShow?: React.Dispatch<React.SetStateAction<DepositDocument>>
    setReload?: () => void,
    deleteOneFile?: (elemToDelete: DepositDocument) => void
    supportDocDelete?: boolean | undefined,
    transaction: Transaction,
    priorities: DepositPriorityOptions[] | undefined
}

const OverviewModel: FC<OverviewModelProps> = ({
  oneModel,
  indexModel,
  isOpen,
  toogleCardModel,
  nbCurrent,
  depositId,
  fieldStatus,
  countries = [],
  getReproduction,
  isEditable = false,
  hasExchangeRepro = () => false,
  hasExchangeReproToAdd = () => false,
  changeModel,
  deleteModel,
  showPreview,
  documents = [],
  fileToAdd = [],
  setfileToAdd = () => { },
  setShowReproModal = () => { },
  setFileToShow = () => { },
  setReload = () => { },
  deleteOneFile = () => { },
  supportDocDelete,
  transaction,
  priorities
}) => {
  const reproductions: ReproductionLightDeposit[] = useMemo(() => oneModel.reproductions || [], [oneModel.reproductions])
  const withdrawedReproductions = useMemo(() => oneModel.reproductions?.filter(repro => repro.state === STATUS_WITHDRAWN) || [], [oneModel.reproductions])

  return (
    <Accordion key={indexModel} activeKey={indexModel.toString()}>
      <Card key={oneModel.id} className='h-auto'>
        {nbCurrent === undefined ? (indexModel !== 0 && <div className='border-top w-100 mt-3' />) : <div className='border-top w-100 mt-3' />}
        <div className='row'>
          <Accordion.Toggle
            as={Card.Header} className='d-flex align-items-center bg-transparent cursor-pointer col-11'
            variant='link' onClick={() => toogleCardModel(indexModel)} eventKey={indexModel.toString()}
          >
            <div className='d-flex align-items-center mr-5'>
              <ContextAwareToggle eventKey={indexModel.toString()} />
              {
                depositId && reproductions[0]?.thumbnail &&
                  <Preview
                    key={indexModel}
                    file={() => getReproduction(depositId, oneModel.id!, reproductions[0]?.thumbnail)}
                  />
              }
              {
                depositId && reproductions[0]?.url &&
                  <Preview
                    file={reproductions[0]?.url}
                    className='image-with-thumbnail'
                  />
              }
            </div>
            <div>
              <div>
                {
                  fieldStatus && fieldStatus.models && containsErrors(fieldStatus.models[indexModel]) &&
                    <div className='text-danger mr-3'>
                      <FontAwesomeIcon className='mr-1' icon={faExclamationTriangle} />
                      <FormattedMessage id='error_global_models' />
                    </div>
                }
                <FormattedMessage id='model_deposit_form_number' />{indexModel + 1}
                <span className='normal text-lowercase'> ({reproductions && reproductions.length} <FormattedMessage id='request_number_reproduction' />
                  {reproductions.length > 1 ? 's' : ''})
                </span>
                {withdrawedReproductions.length > 0 &&
                  <span className='normal text-lowercase text-warning'>
                    ({withdrawedReproductions.length} <FormattedMessage id='reproduction_withdrawn' />{withdrawedReproductions.length > 1 ? 's' : ''})
                  </span>}
              </div>
              {oneModel.priorityId && countries.length > 0 ? (
                <div>
                  <FormattedMessage id='overview_deposit_priority_title' />
                  {DepositService.getModelPriorityName(oneModel.priorityId, countries, priorities)}
                </div>
              ) : ''}
              <div className='normal'>
                {oneModel.modelDesignation && oneModel.modelDesignation.ref
                  ? `${oneModel.modelDesignation.ref}. ${oneModel.modelDesignation.name}`
                  : `${oneModel.modelDesignation.name}`}
              </div>
            </div>
          </Accordion.Toggle>
          {
            changeModel && deleteModel &&
              <div className='col-1 d-flex flex-row justify-content-end align-items-center'>
                <SubmitButton
                  className='btn-icon-primary mr-3'
                  onClick={() => changeModel(indexModel)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </SubmitButton>
                <SubmitButton
                  className='btn-icon-danger mr-3'
                  onClick={() => deleteModel(indexModel)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </SubmitButton>
              </div>
          }
        </div>
        {isOpen && (
          <Accordion.Collapse eventKey={indexModel.toString()}>
            <Card.Body className='p-0 float-right col-11'>
              {
                reproductions.map((oneRepro: ReproductionLightDeposit, index) => {
                  return (
                    <ReproductionOverview
                      key={`${oneModel.id}-${index}`}
                      oneRepro={oneRepro}
                      oneModel={oneModel}
                      indexModel={indexModel}
                      index={index}
                      nbCurrent={reproductions.length}
                      depositId={depositId}
                      transaction={transaction}
                      isEditable={isEditable}
                      documents={documents}
                      fileToAdd={fileToAdd}
                      setfileToAdd={setfileToAdd}
                      setShowReproModal={setShowReproModal}
                      setFileToShow={setFileToShow}
                      setReload={setReload}
                      deleteOneFile={deleteOneFile}
                      supportDocDelete={supportDocDelete}
                      getReproduction={getReproduction}
                      showPreview={showPreview}
                      hasExchangeRepro={hasExchangeRepro}
                      hasExchangeReproToAdd={hasExchangeReproToAdd}
                    />
                  )
                })
              }
            </Card.Body>
          </Accordion.Collapse>
        )}
      </Card>
    </Accordion>
  )
}

export default memo(OverviewModel)
