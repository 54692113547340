import store from '../../../../../store/store'
import { containsErrors, FieldStatus, IntervenantValidator, isFilled, PERSONNE_PHYSIQUE } from '@inpi-dm/components'
import { createIntl } from 'react-intl'
import Message from '../../../../../constants/Message'
import { storeProrogationFieldStatusUpdate } from 'store/prorogation/prorogationActions'
import { sirenValidity } from '@inpi-dm/components/src/utils/validationUtils'

class DepositorValidator {
  constructor () {
    this.intl = createIntl({ locale: 'fr', messages: Message })
  }

  validateOnChangeStep = () => {
    const prorogation = store.getState().prorogation

    let fieldStatus = IntervenantValidator.validateIntervenant(prorogation.depositor)
    if (prorogation.depositor?.type === PERSONNE_PHYSIQUE.value) {
      return this.dispatchAndValidate(fieldStatus)
    } else {
      if (prorogation.depositor?.siren && !isFilled(fieldStatus.siren)) {
        const errorMessage = sirenValidity(prorogation.depositor)
        if (errorMessage) {
          fieldStatus = {
            ...fieldStatus,
            siren: this.intl.formatMessage({ id: errorMessage })
          }
        }
      }
      return this.dispatchAndValidate(fieldStatus)
    }
  }

  dispatchAndValidate = (fieldStatus: FieldStatus): boolean => {
    store.dispatch(storeProrogationFieldStatusUpdate({
      depositor: fieldStatus
    }))
    return !containsErrors(fieldStatus)
  }
}

export default new DepositorValidator()
