import React, { FC } from 'react'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { APPEAL_TYPE, SubmitButton, TextField } from '@inpi-dm/components'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

interface IdPublicationRecordsFieldProps extends WrappedComponentProps {
  idPublications?: string[],
  setIdPublication: (index: number, value: string) => void,
  deleteIdPublication: (index: number) => void,
  addIdPublication: () => void,
  fieldStatus?: string[],
  displayLabel: boolean,
  displayAddButton: boolean,
  procedure?: string
}

const IdPublicationRecordsField: FC<IdPublicationRecordsFieldProps> = ({
  idPublications,
  setIdPublication,
  deleteIdPublication,
  addIdPublication,
  fieldStatus,
  displayLabel,
  displayAddButton,
  procedure
}) => {
  const getColorClassName = (index: number) => {
    return fieldStatus && fieldStatus.length > index && fieldStatus[index] ? `border-2 border-${fieldStatus[index]}` : ''
  }

  return (
    <div>
      {
        displayLabel &&
          <div className='text-gris mb-025'>
            <FormattedMessage id='records_inscription_publication_label' />
            {
              (!procedure || (procedure && procedure !== APPEAL_TYPE.value)) && <span className='text-danger'>*</span>
            }
          </div>
      }
      {
        idPublications?.map((publication, index) => (
          <div className='d-flex' key={`publications-${index}`}>
            <TextField
              inputId={`publications-${index}`}
              className={getColorClassName(index)}
              value={publication}
              onChange={(event => {
                const { value } = event.target
                setIdPublication(index, value)
              })}
            />
            <SubmitButton
              className='btn-icon-secondary ml-2 mb-3'
              onClick={() => deleteIdPublication(index)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </SubmitButton>
          </div>
        ))
      }
      {
        displayAddButton &&
          <SubmitButton
            className='btn-link-primary'
            onClick={addIdPublication}
          >
            <FormattedMessage id='records_inscription_publication_add_button' />
          </SubmitButton>
      }
    </div>
  )
}

export default injectIntl(IdPublicationRecordsField)
