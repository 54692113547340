import http from 'network/http-common'
import store from '../../store/store'
import { toast } from 'react-toastify'
import { createIntl } from 'react-intl'
import Message from '../../constants/Message'
import axios from 'axios'
import {
  APPEAL_FIELD,
  DepositDocument,
  Transaction
} from '@inpi-dm/components'
import { storeAppealUpdate } from '../../store/appeal/appealActions'
import TransactionService from './TransactionService'
import { Payment } from '../../interfaces/DepositInterfaces'

/**
 * Classe permettant de faire des opérations sur un relevé de déchéance
 */
class AppealService {
  constructor () {
    this.intl = createIntl({ locale: 'fr', messages: Message })
    this.source = axios.CancelToken.source()
  }

  /**
   * Création d'un nouveau relevé de déchéance
   * @returns Promise<Transaction|null>
   */
  createAppeal = async (): Promise<Transaction|null> => {
    try {
      const appealPost: Transaction = await http.post('/api/appeals')
      const appeal = { ...appealPost, ...store.getState().appeal }
      store.dispatch(storeAppealUpdate(appeal))
      return Promise.resolve(appeal)
    } catch (error) {
      toast.error(this.intl.formatMessage({ id: 'error_creation_appeal' }))
      return Promise.reject(error)
    }
  }

  /**
   * Permet de mettre à jour les documents du relevé de déchéance
   * @param appealDocuments
   * @param transaction
   */
  updateDocument = async (appealDocuments: DepositDocument[], transaction: Transaction) => {
    for (const document of appealDocuments) {
      if (document.toDelete) {
        await TransactionService.deleteDocumentFile(transaction.id, document.internalName)
      } else if (document.needPost) {
        await TransactionService.postDocumentFile(transaction.id, document.type, document.file, undefined, undefined, document.communicability, document.description, document.additionalType)
      }
    }
  }

  /**
   * Appel serveur pour mettre à jour le relevé de déchéance en bdd
   * @param id id de la transaction
   * @param patches liste des modifications
   * @returns Promise<Transaction|null>
   */
  updateAppeal = async (id: string, patches: any): Promise<Transaction|null> => {
    try {
      const appeal: Transaction = await http.put(`/api/appeals/${id}`,
        patches
      )
      store.dispatch(storeAppealUpdate(appeal))
      return Promise.resolve(appeal)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Mise à jour des propriétés d'un relevé de déchéance correspondant à une étape du formulaire
   * @returns Promise<Transaction|null>
   */
  updateAppealBDDFromStore = async (propertyNames: string[], appealDocuments?: DepositDocument[]|undefined): Promise<Transaction|null> => {
    const appeal = store.getState().appeal
    if (appealDocuments) {
      await this.updateDocument(appealDocuments, appeal)
    }
    if (appeal.id) {
      const patches = {}
      propertyNames.forEach((propertyName) => {
        if (APPEAL_FIELD.includes(propertyName)) {
          patches[propertyName] = appeal.appeal[propertyName]
        } else {
          patches[propertyName] = appeal[propertyName]
        }
      })
      // On ajoute la référence client saisie par l'utilisateur
      if (appeal.internalReference) {
        patches.internalReference = appeal.internalReference
      }
      try {
        const result = await this.updateAppeal(appeal.id, patches)
        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    } else {
      toast.error(this.intl.formatMessage({ id: 'error_update_appeal' }))
      return Promise.reject(new Error(this.intl.formatMessage({ id: 'error_update_appeal' })))
    }
  }

  /**
   * Appel serveur afin de connaitre les information concernant le paiement
   * @param idTransaction
   */
  getPayment = async (idTransaction: string): Promise<Payment|null> => {
    try {
      return await http.get(`/api/appeals/${idTransaction}/paiement`)
    } catch (error) {
      return Promise.resolve(null)
    }
  }

  /**
   * Appel serveur afin de connaitre les information concernant les types de relevé de déchéance
   */
  getAppealTypes = async (): Promise<any[]|null> => {
    try {
      return await http.get('/api/appeals/types')
    } catch (error) {
      return Promise.resolve(null)
    }
  }

  /**
   * Annule une requête en attente
   */
  cancelRequest = () => {
    this.source.cancel()
    this.source = axios.CancelToken.source()
  }
}

export default new AppealService()
