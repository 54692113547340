import store from '../../../../../store/store'
import { containsErrors, FieldStatus, ModelDeposit } from '@inpi-dm/components'
import { storeDepositFieldStatusUpdate } from '../../../../../store/deposit/depositActions'
import Message from '../../../../../constants/Message'
import ModelFormValidator from '../modelForm/validator/ModelFormValidator'

class ModelsFormDepositValidator {
    validateMandatory = (models: ModelDeposit[]) : FieldStatus[] => {
      // Il faut avoir au moins 1 modèle et au plus 100
      if (models.length > 100) {
        return models.map(() => ({ modelDesignation: Message.error_number_models }))
      }

      if (models.length < 1) {
        return [{ modelDesignation: Message.error_number_models }]
      }

      // Il faut avoir au maximum 100 reproductions
      const nbReproductions = models.reduce((total, currentModel) =>
        currentModel.reproductions ? total + currentModel.reproductions.length : total
      , 0)

      if (nbReproductions === 0 || nbReproductions > 100) {
        return [{ reproductions: Message.error_number_reproductions }]
      }

      return []
    }

    validateOnChangeStep = () => {
      const models = store.getState().deposit.models

      let fieldStatus = models.map(ModelFormValidator.validateMandatory)
      let error = containsErrors(fieldStatus)

      // Si il n'y a plus d'erreur sur chacun des modèles, on vérifie les règles générales
      if (!error) {
        fieldStatus = this.validateMandatory(models)
        error = containsErrors(fieldStatus)
      }

      store.dispatch(storeDepositFieldStatusUpdate({
        models: fieldStatus
      }))

      return !error
    }
}

export default new ModelsFormDepositValidator()
