import React, { FC, useState, useEffect } from 'react'
import {
  EventType,
  SelectField,
  TextField,
  PROCEDURE_TYPE,
  DEPOSIT_TYPES_OPTIONS,
  INSCRIPTION_TYPES_OPTIONS,
  DEPOSIT_TYPE,
  INSCRIPTION_TYPE,
  SelectOption,
  APPEAL_TYPE
} from '@inpi-dm/components'
import { useIntl } from 'react-intl'
import AppealService from 'back-office/src/services/transaction/AppealService'

type RequestListTableHeaderProps = {
  valueSearch?: string,
  onSearch: (event: EventType) => void,
  valueSelectProcedure?: string,
  onSelectProcedure: (event: EventType) => void,
  valueSelectSubProcedureType?: string,
  onSelectSubProcedureType: (event?: EventType|undefined) => void,
  isTitle?: boolean
  isDocument?: boolean
};

const RequestListTableHeader: FC<RequestListTableHeaderProps> = ({
  valueSearch,
  onSearch,
  valueSelectProcedure,
  onSelectProcedure,
  valueSelectSubProcedureType,
  onSelectSubProcedureType,
  isTitle,
  isDocument
}) => {
  const intl = useIntl()
  const [optionsSubType, setOptionsSubType] = useState<SelectOption[]>([])
  const [optionsAppealType, setOptionsAppealType] = useState<SelectOption[]>([])

  useEffect(() => {
    AppealService.getAppealTypes().then((appealTypes: {label: string, code: string}[]|null) => {
      const newType = appealTypes?.map(appealType => ({ label: appealType.label, value: appealType.code })) || []
      setOptionsAppealType(newType)
      setOptionsSubType([...DEPOSIT_TYPES_OPTIONS, ...INSCRIPTION_TYPES_OPTIONS, ...newType])
    })
  }, [])

  const onSelect = (event: EventType) => {
    // Filtrage des sous-types dans le dropdown en fonction de la procédure choisie
    if (event.target.value) {
      switch (event.target.value) {
        case DEPOSIT_TYPE.value:
          setOptionsSubType(DEPOSIT_TYPES_OPTIONS)
          break
        case INSCRIPTION_TYPE.value:
          setOptionsSubType(INSCRIPTION_TYPES_OPTIONS)
          break
        case APPEAL_TYPE.value:
          setOptionsSubType(optionsAppealType)
          break
        default:
          setOptionsSubType([])
          break
      }
    } else {
      setOptionsSubType([...DEPOSIT_TYPES_OPTIONS, ...INSCRIPTION_TYPES_OPTIONS, ...optionsAppealType])
    }
    onSelectSubProcedureType(undefined)
    onSelectProcedure(event)
  }

  return (
    <div className='row'>
      <div className='col-md-6 col-12'>
        <TextField
          inputId='recherche'
          placeholder={intl.formatMessage({ id: 'placeholder_file_search' })}
          onChange={onSearch}
          value={valueSearch}
        />
      </div>
      {!isTitle && !isDocument &&
        <>
          <div className='col-md-3 col-12'>
            <SelectField
              inputId='select'
              options={PROCEDURE_TYPE}
              placeholder={intl.formatMessage({ id: 'placeholder_procedure_type_option' })}
              onChange={onSelect}
              value={valueSelectProcedure}
            />
          </div>
          <div className='col-md-3 col-12'>
            <SelectField
              inputId='select'
              options={optionsSubType}
              placeholder={intl.formatMessage({ id: 'placeholder_subtype_option' })}
              onChange={onSelectSubProcedureType}
              value={valueSelectSubProcedureType}
              readOnly={!valueSelectProcedure || optionsSubType.length === 0}
            />
          </div>
        </>}
    </div>
  )
}

export default RequestListTableHeader
