import { DepositDocument, DOCUMENT_COMMUNICABILITIES } from '@inpi-dm/components'
import Message from '../../../../../constants/Message'
import { DOCUMENT_INSCRIPTION_OTHER_CORRECTION } from '../../../../../constants/DocumentConstants'
import { storeInscriptionFieldStatusUpdate } from '../../../../../store/inscription/inscriptionActions'
import store from '../../../../../store/store'

class DocumentValidator {
  /**
   * Retourne les erreurs liés aux documents
   */
  hasErrorDocuments = (documents: DepositDocument[], documentIsNeeded: boolean = true) => {
    if (documentIsNeeded && !documents.find(doc => doc.communicability === DOCUMENT_COMMUNICABILITIES.COMMUNICABLE && !doc.toDelete && (doc.file || (doc.internalName && doc.internalName.length > 0)))) {
      return Message.inscription_form_document_error
    }
    if (documents.find(doc => doc.type === DOCUMENT_INSCRIPTION_OTHER_CORRECTION && (!doc.description && doc.name))) {
      return Message.inscription_form_document_label_error
    }

    return null
  }

  /**
   * Vérification des documents joints
   * @param documents
   * @param documentIsNeeded
   */
  validateDocuments = (documents: DepositDocument[], documentIsNeeded: boolean = true) => {
    const error = this.hasErrorDocuments(documents, documentIsNeeded)

    if (error) {
      store.dispatch(storeInscriptionFieldStatusUpdate({
        documents: error
      }))
      return false
    }

    return true
  }
}

export default new DocumentValidator()
