
import { Preview, ReproductionLightDeposit, ThumbnailsContext } from '@inpi-dm/components'
import React, { FC, useContext, useEffect, useState } from 'react'
import DepositService from '../../../../../services/transaction/DepositService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
/* global File */

interface ThumbnailReproductionProps {
    repro: ReproductionLightDeposit,
    idTransaction?: string,
    idModel?: string,
}
/**
   * Permet d'afficher les reproductions en vignette
   */
const ThumbnailReproduction: FC<ThumbnailReproductionProps> = ({
  repro,
  idTransaction,
  idModel
}) => {
  const { thumbnailsImages, setThumbnailsImages } = useContext(ThumbnailsContext)
  const [loading, setLoading] = useState(true)
  const reproduction: ReproductionLightDeposit = { ...repro }
  const backendImage = idTransaction && idModel && reproduction.thumbnail && reproduction.thumbnail.internalName
  // Si l'image vient d'être uploadée uniquement sur le client et non sur le serveur
  const clientImage = !reproduction.thumbnail || !reproduction.file?.internalName

  const [file, setFile]: [undefined | string | File | (() => Promise<string>), React.Dispatch<React.SetStateAction<undefined | string | File | (() => Promise<string>)>>] = useState<string | File |(() => Promise<string>)>()

  const loadImageProperties = async () => {
    if (!reproduction.file) {
      setLoading(false)
      return
    }

    if ((reproduction.file instanceof File)) {
      setFile(reproduction.file)
      setLoading(false)
      return
    }

    if (thumbnailsImages[reproduction.thumbnail!.internalName]) {
      setFile(thumbnailsImages[reproduction.thumbnail!.internalName])
      setLoading(false)
      return
    }

    const newFile = await DepositService.getReproduction(idTransaction!, idModel!, reproduction.thumbnail!.internalName)
    setThumbnailsImages((previousValues: { [key: string]: string }) => ({
      ...previousValues,
      [reproduction.thumbnail!.internalName]: newFile
    }))
    setFile(newFile)
    setLoading(false)
  }

  useEffect(() => {
    loadImageProperties()
  }, [])

  if (loading || (!loading && !file)) {
    return <FontAwesomeIcon icon={faSpinner} className='loader my-auto' />
  }

  return (
    <Preview
      className={`m-auto ${backendImage || clientImage ? 'img-thumbnail' : ''}`}
      file={file!}
      filename={reproduction?.file?.name || reproduction?.thumbnail?.name}
    />
  )
}

export default ThumbnailReproduction
