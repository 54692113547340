import React, { FC, useEffect, useState } from 'react'
import { Country, OverviewIntervenant, Record } from '@inpi-dm/components'
import { faSpinner, faEye, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface TitleRowProps {
    recordInfo?: Record,
    idRecord?: string, /* Soit on donne record soit idRecord et getRecord */
    getRecord?: (idRecord: string) => Promise<Record>,
    isTitleSelection?: boolean,
    selectTitle?: (id?:string, origin?:string) => void,
    setShowModalSelectTitle?: (showModalSelectTitle: boolean) => void,
    getCountries: () => Promise<void | Country[]>,
    boDisplay?: boolean,
    classNameText?: string
}

const TitleRow:FC<TitleRowProps> = ({
  recordInfo,
  idRecord,
  getRecord,
  isTitleSelection,
  selectTitle,
  setShowModalSelectTitle,
  getCountries,
  boDisplay,
  classNameText
}) => {
  const [record, setRecord] = useState<Record>()
  const [countries, setCountries] = useState<Country[]>([])

  useEffect(() => {
    getCountries().then((r) => r && setCountries(r))
  }, [])

  useEffect(() => {
    recordInfo
      ? setRecord(recordInfo) : getRecord(idRecord).then(setRecord)
  }, [idRecord, recordInfo, getRecord])

  const iconSelectTitle = () => (
    <FontAwesomeIcon
      className='text-success cursor-pointer mr-1' icon={faCheck} onClick={() => {
        selectTitle && record && selectTitle(record.id, record.country)
        setShowModalSelectTitle && setShowModalSelectTitle(false)
      }}
    />
  )

  const iconShowRecord = () => {
    return record && record.id
      ? (
        <FontAwesomeIcon
          className='text-primary cursor-pointer'
          icon={faEye}
          onClick={() => window.open(`/dossiers/${record.id}`, '_blank')}
        />
      )
      : null
  }

  return record ? (
    <tr>
      <td className={classNameText}>{record.numNat}</td>
      <td className={classNameText}>
        {record.holders.map(contributor => (
          <OverviewIntervenant
            key={contributor.id}
            intervenant={contributor}
            countries={countries}
            onlyNeedNames
          />
        ))}
      </td>
      <td className={classNameText} title={record.publicationIds?.join(', ')}>
        {
                    record.publicationIds?.join(', ')
        }
      </td>
      <td>
        {isTitleSelection && selectTitle && iconSelectTitle()}
        {boDisplay && iconShowRecord()}
      </td>

    </tr>
  ) : <div><FontAwesomeIcon className='loader mr-1' icon={faSpinner} /></div>
}

export default TitleRow
