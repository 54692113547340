import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { Badge, CardBlock } from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

interface RequestItemProps {
  url: string,
  label: string,
  badge?: number,
  className?: string
}

const RequestItem: FC<RequestItemProps> = ({
  url,
  label,
  badge = 0,
  className = ''
}) => (
  <div className={`w-100 mb-3 ${className}`}>
    <Link to={url} className='text-decoration-none'>
      <CardBlock shadow className='cursor-pointer theme-secondary p-0'>
        <div className='item d-flex justify-content-between h-100'>
          <span className='text-body my-auto'>
            <FormattedMessage id={label} />
          </span>
          <Badge className={`my-auto ${badge <= 0 ? 'bg-light' : 'bg-secondary'}`}>
            {badge === -1
              ? <FontAwesomeIcon className='loader mr-1' icon={faSpinner} />
              : <span>{badge}</span>}
          </Badge>
        </div>
      </CardBlock>
    </Link>
  </div>
)

export default RequestItem
