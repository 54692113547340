import React, { FC } from 'react'
import {
  CardBlock,
  FieldStatus,
  MultipleRadioField, Preview,
  ReproductionLightDeposit,
  TextArea,
  TextField,
  DropArea,
  DepositDocument
} from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'
import { BLACK_AND_WHITE, COLORS } from '../../../../../../constants/DepositConstants'
import DepositService from '../../../../../../services/transaction/DepositService'
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons'
import PreviewAndTrashRectificatif from '../../PreviewAndTrashRectificatif'
/* global FormData */

interface ReproductionCompletionFieldsProps {
  idTransaction: string,
  idModel: string,
  indexModel: number,
  index: number,
  reproduction: ReproductionLightDeposit,
  regularizationReproduction: DepositDocument[],
  addRegularizationReproduction: () => void,
  deleteRegularizationReproduction: () => void,
  fieldStatus?: FieldStatus,
  onChange: (reproduction: ReproductionLightDeposit, indexModel: number, index: number) => void,
}

const ReproductionCompletionFields: FC<ReproductionCompletionFieldsProps> = ({
  idTransaction,
  idModel,
  indexModel,
  index,
  reproduction,
  regularizationReproduction,
  addRegularizationReproduction,
  deleteRegularizationReproduction,
  fieldStatus,
  onChange
}) => {
  /**
   * Effectue la mise à jour de la reproduction
   *
   * @param name Nom du champ mis à jour
   * @param value Nouvelle valeur du champ
   */
  const handleChange = (name: string, value: string) => {
    const updatedReproduction = {
      ...reproduction,
      [name]: value
    }
    onChange(updatedReproduction, indexModel, index)
  }

  return (
    <CardBlock
      className='mb-3 is-validated'
      shadow
      bodyClassName='row p-2 align-items-center'
    >
      <div className='px-3 mb-3'>
        <Preview
          file={() => DepositService.getReproduction(idTransaction, idModel, reproduction.thumbnail.internalName)}
          filename={reproduction.thumbnail.name}
        />
        <span className='ml-3 font-weight-bold'>{indexModel + 1}.{index + 1}</span>
      </div>
      <div className='col-12 col-sm-8 col-lg-5'>
        <TextField
          inputId={`reproductionLabel-${indexModel}-${index}`}
          label={<FormattedMessage id='reproductions_form_label' />}
          value={reproduction.label}
          fieldStatus={fieldStatus}
          nameFieldStatus='label'
          onChange={(e) => handleChange('label', e.target.value)}
          maxLength='200'
          required
        />
      </div>
      <div className='col-8 col-lg-4 d-flex justify-content-center align-items-center'>
        <MultipleRadioField
          inputId={`reproductionColor-${indexModel}-${index}`}
          fieldStatus={fieldStatus}
          nameFieldStatus='color'
          radio={[
            {
              label: <FormattedMessage id='reproductions_form_radio_colors' />,
              value: COLORS
            },
            {
              label: <FormattedMessage id='reproductions_form_radio_no_colors' />,
              value: BLACK_AND_WHITE
            }
          ]}
          value={reproduction.color}
          onChange={(e) => handleChange('color', e.target.value)}
        />
      </div>
      <div className='col-12'>
        <TextArea
          className='mb-0'
          inputId={`reproductionDescription-${indexModel}-${index}`}
          value={reproduction.description}
          onChange={(e) => handleChange('description', e.target.value)}
          label={<FormattedMessage id='reproductions_form_description' />}
          rows={1}
          maxLength='500'
        />
      </div>
      {!regularizationReproduction &&
        <div className='col-12'>
          <DropArea
            icon={faExchangeAlt}
            accept={process.env.REACT_APP_ACCEPTED_IMAGE_FORMAT}
            maxSize={process.env.REACT_APP_ACCEPTED_IMAGE_SIZE}
            text='request_reproduction_completion_new_repro'
            onDrop={(file: File[]) => {
              const theFile = file[0]
              addRegularizationReproduction(indexModel, index, {
                srcName: reproduction.file.internalName,
                file: theFile
              })
            }}
          />
        </div>}
      {regularizationReproduction &&
        <div className='col-12'>
          <PreviewAndTrashRectificatif
            key={index}
            loadFile={() => {
              const formData = new FormData()
              formData.append('file', regularizationReproduction.file)
              return DepositService.resizeFile(formData)
            }}
            onClick={() => {}}
            onTrashClick={() =>
              deleteRegularizationReproduction(indexModel, index)}
          />
        </div>}
    </CardBlock>
  )
}

export default ReproductionCompletionFields
