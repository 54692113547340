import { DEFAULT_INTERVENANT, Transaction } from '@inpi-dm/components'
import { ProrogationFieldStatus } from '../../interfaces/ProrogationInterfaces'
import {
  PROROGATION_FIELD_STATUS_RESET,
  PROROGATION_FIELD_STATUS_UPDATE,
  PROROGATION_REMOVE,
  PROROGATION_UPDATE, ProrogationFieldStatusStoreAction,
  ProrogationStoreAction
} from './prorogationStoreTypes'

const initialProrogationState: Transaction = {
  depositor: DEFAULT_INTERVENANT,
  recipient: DEFAULT_INTERVENANT
}

/* Contient les erreurs liées au formulaire de prorogation */
const initialProrogationStatusState: ProrogationFieldStatus = {}

export function reducerProrogationState (state = initialProrogationState, action: ProrogationStoreAction): Transaction {
  switch (action.type) {
    case PROROGATION_UPDATE:
      return {
        ...state,
        ...action.prorogation,
        prorogation: {
          ...state.prorogation,
          ...action.prorogation?.prorogation
        }
      }
    case PROROGATION_REMOVE:
      return initialProrogationState
    default:
      return state
  }
}

export function reducerProrogationFieldStatusState (state = initialProrogationStatusState, action: ProrogationFieldStatusStoreAction): ProrogationFieldStatus {
  switch (action.type) {
    case PROROGATION_FIELD_STATUS_UPDATE:
      return {
        ...state,
        ...action.fieldStatus
      }
    case PROROGATION_FIELD_STATUS_RESET:
      return initialProrogationStatusState
    default:
      return state
  }
}
