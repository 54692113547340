import store from '../../../../../store/store'
import { storeDepositFieldStatusUpdate } from '../../../../../store/deposit/depositActions'
import { containsErrors, IntervenantValidator } from '@inpi-dm/components'

class SignatoryValidator {
  validateOnChangeStep = () => {
    const deposit = store.getState().deposit

    const fieldStatus = IntervenantValidator.validateSignatory(deposit.signatory)
    store.dispatch(storeDepositFieldStatusUpdate({
      signatory: fieldStatus
    }))

    return !containsErrors(fieldStatus)
  }
}

export default new SignatoryValidator()
