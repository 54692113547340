export const APPEAL_FIELD = [
  'titles',
  'inscriptionRequests',
  'appealDecommission',
  'appealType'
]

export const STATUS_APPEAL = {
  /** Brouillon client */
  DRAFT_CLIENT: 'draft_client_appeal',
  /** Brouillon */
  DRAFT: 'draft_appeal',
  /** A attribuer */
  TO_ATTRIBUTE: 'to_attribute_appeal',
  /** A examiner */
  TO_REVIEW: 'to_review_appeal',
  /** Suspendu */
  SUSPENDED: 'suspended_appeal',
  /** A inscrire */
  TO_REGISTER: 'to_register_appeal',
  /** A rejeter */
  TO_REJECT: 'to_reject_appeal',
  /** Inscrit */
  REGISTERED: 'registered_appeal',
  /** Décision favorable */
  FAVORABLE_DECISION: 'favorable_decision_appeal',
  /** Irrecevable */
  NOT_ACCEPTED: 'not_accepted_appeal',
  /** Rejeté */
  REJECTED: 'rejected_appeal',
  /** Désistement */
  DISCONTINUATION: 'appeal_discontinuation',
  /** Affaires classées */
  CLOSED_CASES: 'closed_cases_appeal'
}
