import { containsErrors, FieldStatus, isDateFormatValid, messageIsFilled } from '@inpi-dm/components'
import Message from '../../../../../../constants/Message'
import { DepositPriorityOptions } from '../../../../../../interfaces/DepositInterfaces'

class PriorityFormValidator {
    validateDate = (priorite: DepositPriorityOptions) => {
      const day = priorite.originalDepositDateDay || ''
      const month = priorite.originalDepositDateMonth || ''
      const year = priorite.originalDepositDateYear || ''
      const dayfield = messageIsFilled(day)
      const monthfield = messageIsFilled(month)
      const yearfield = messageIsFilled(year)

      // si aucun champs date n'est initialisé mais que la priorité est datée
      // alors l'utilisateur n'est pas allé sur le formulaire de la priorité
      // donc pas d'erreur à signaler
      if (priorite.originalDepositDate &&
          priorite.originalDepositDateDay === undefined &&
          priorite.originalDepositDateMonth === undefined &&
          priorite.originalDepositDateYear === undefined
      ) {
        return ''
      }

      // si au moins un champs en erreur
      // ou format de date invalide
      if (containsErrors({ dayfield, monthfield, yearfield }) ||
            !isDateFormatValid(`${year}-${month}-${day}`)
      ) {
        return Message.error_field_date
      }

      return ''
    }

    validateMandatory = (priorite: DepositPriorityOptions): FieldStatus => {
      return {
        countryOrOrganization: messageIsFilled(priorite.countryOrOrganization),
        originalDepositNumber: messageIsFilled(priorite.originalDepositNumber),
        originalDepositDate: this.validateDate(priorite),
        originalDepositorName: messageIsFilled(priorite.originalDepositorName)
      }
    }
}

export default new PriorityFormValidator()
