import React, { FC } from 'react'
import { DepositDocument, Transaction } from '@inpi-dm/components'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'
import NatureCodesBlock from '../natureCodes/NatureCodesBlock'
import CorrectionStateBlock from './CorrectionStateBlock'
import InscriptionDocumentsBlock from '../documents/InscriptionDocumentsBlock'
import CorrectionPartRectified from './CorrectionPartRectified'

interface CorrectionFormProps extends WrappedComponentProps {
    transaction : Transaction,
    procedureType: string,
  inscriptionProofDocuments: DepositDocument[][],
  setInscriptionProofDocuments: (inscriptionProofDocuments: DepositDocument[][]) => void
}

const CorrectionForm: FC<CorrectionFormProps> = ({
  transaction,
  procedureType,
  inscriptionProofDocuments,
  setInscriptionProofDocuments,
  intl
}) => {
  return (
    <div className='select-type-depot'>
      <div className='row'>
        <header className='col-8 mb-4'>
          <h1><FormattedMessage id='select_inscription_correction_title' /></h1>
          <span className='subtitle'><FormattedMessage id='select_inscription_correction_description' /></span>
        </header>
        <InternalReferenceField
          value={transaction.internalReference}
          procedureType={procedureType}
          className='col-4 mb-4'
        />
      </div>
      <div className='d-flex flex-column flex-md-row mb-4'>
        <div className='w-50 mr-md-4'>
          <NatureCodesBlock
            transaction={transaction}
            title={intl.formatMessage({ id: 'correction_form_nature_block_title' })}
            isMultiChoices
          />
        </div>
        <div className='w-50 ml-md-4'>
          <CorrectionStateBlock
            transaction={transaction}
          />
        </div>
      </div>
      {
        transaction.inscription && transaction.inscription.natureCodes.filter(natureCode => natureCode.code === 'EM').length > 0 &&
          <CorrectionPartRectified transaction={transaction} />
      }
      <InscriptionDocumentsBlock
        transaction={transaction}
        inscriptionProofDocuments={inscriptionProofDocuments}
        setInscriptionProofDocuments={setInscriptionProofDocuments}
      />
    </div>
  )
}

export default injectIntl(CorrectionForm)
