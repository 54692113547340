import React, { FC, useState, useEffect } from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import {
  FieldStatus,
  SubmitButton,
  containsErrors,
  OfficialDocumentType,
  OfficialDocumentRequest
} from '@inpi-dm/components'
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux'
import SelectTransactionFormValidator from './validator/SelectTransactionFormValidator'
import {
  RECORD_OFFICIAL_DOCUMENT_INFORMATION_FROM_SELECT
} from '../../../../constants/OfficialDocumentConstants'
import OfficialDocumentRequestForm from '../record/OfficialDocumentRequestForm'
import SearchAndManualTransactionsForm from '../../../../component/title/SearchAndManualTransactionsForm'
import { storeContributorsTitleUpdate } from '../../../../store/contributorsTitle/contributorsTitleActions'

interface SelectRecordsFormProps extends WrappedComponentProps {
  request: OfficialDocumentRequest
  setRequest: (request: OfficialDocumentRequest) => void,
  initialFieldStatus?: FieldStatus,
  onSubmit?: (request: OfficialDocumentRequest, numNat: string, fieldStatus: FieldStatus) => void,
  typesOfficialsDocument: OfficialDocumentType[]
}

const SelectOfficialDocumentTransactionsForm: FC<SelectRecordsFormProps> = ({
  request,
  setRequest,
  initialFieldStatus,
  onSubmit,
  typesOfficialsDocument

}) => {
  const dispatch = useDispatch()
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>(initialFieldStatus || {})
  const [currentContributors, setCurrentContributors] = useState({})
  const infosByNumNat = useSelector((state: RootStateOrAny) => state.contributorsTitle)
  const [numNat, setNumNat] = useState<string|undefined>()

  useEffect(() => {
    initialFieldStatus && setFieldStatus(initialFieldStatus)
  }, [initialFieldStatus])

  /**
   * Vérifie que les champs obligatoire soient remplis,
   * enregistre dans le store les informations et ferme le formulaire
   */
  const submitForm = () => {
    // Vérifier s'il n'y a pas d'erreur
    const fieldStatusError = SelectTransactionFormValidator.validateMandatory(request)
    setFieldStatus(fieldStatusError)

    if (!containsErrors(fieldStatusError)) {
      if (currentContributors && request.copyNum) {
        const newContributors = {
          ...infosByNumNat,
          [request.copyNum]: currentContributors
        }

        // On enregistre les intervenants dans le store pour pouvoir les proposer lors des formulaires des intervenants
        dispatch(storeContributorsTitleUpdate({
          ...newContributors
        }))
      }
      onSubmit && onSubmit(request, numNat, fieldStatusError)
    }
  }

  return (
    <>
      <hr />
      <div className='mb-5'>
        <div className='mx-3 header-field'>
          <FormattedMessage id='records_official_document_service_label' />
        </div>
        <OfficialDocumentRequestForm
          request={request}
          setRequest={setRequest}
          fieldStatus={fieldStatus}
          typesOfficialsDocument={typesOfficialsDocument}
        />
      </div>
      <div>
        <div className='mx-3 header-field'>
          <FormattedMessage id='records_official_document_record_label' />
        </div>
        <SearchAndManualTransactionsForm
          request={request}
          officialDocumentType={request.officialDocumentType}
          setRequest={setRequest}
          fieldStatus={fieldStatus}
          setFieldStatus={setFieldStatus}
          setCurrentContributors={setCurrentContributors}
          optionSearch={RECORD_OFFICIAL_DOCUMENT_INFORMATION_FROM_SELECT}
          classNameCol='col-12 col-md-4'
          numNat={numNat}
          setNumNat={setNumNat}
        />
      </div>
      <div className='row m-0 d-flex justify-content-end mt-5'>
        {onSubmit &&
          <SubmitButton
            onClick={submitForm}
            className='btn-outline-primary m-2'
          >
            <FormattedMessage id='records_inscription_submit_form' />
          </SubmitButton>}
      </div>
    </>
  )
}

export default injectIntl(SelectOfficialDocumentTransactionsForm)
