import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { AutocompleteResult, EventType } from '../FormInterfaces'
import { TextFieldProps } from './TextField'
import { TextField } from '../../index'
import debounce from 'lodash/debounce'
import SelectHolder from '../../select/SelectHolder'

interface AutocompleteProps<T> extends TextFieldProps {
  search: (value: string) => Promise<AutocompleteResult<T>[] | void>,
  messageEmpty?: ReactNode,
  onSelect?: (selected: T) => void,
  onChange?: (event: EventType) => void,
  classNameAutocomplete?: string
}

function Autocomplete<T> ({
  inputId,
  className,
  placeholder,
  readOnly,
  label,
  fieldStatus,
  disabled,
  type,
  messageEmpty,
  classNameAutocomplete,
  onChange,
  search,
  onSelect
}: AutocompleteProps<T>
) {
  const [options, setOptions] = useState<AutocompleteResult<T>[]|undefined>(undefined)
  const [value, setValue] = useState(undefined)

  const updateSearch = () => {
    search(value || '').then(result => setOptions(result || undefined))
  }

  const delayedSearch = useCallback(debounce(updateSearch, 500), [value])

  useEffect(() => {
    if (value !== undefined) {
      delayedSearch()
    }
    return delayedSearch.cancel
  }, [value, delayedSearch])

  /** Recherche de suggestions lorsqu'un texte est renseigné */
  const handleChange = (event: EventType) => {
    setValue(event.target.value)
    onChange && onChange(event)
  }

  /* Gestion du clic sur une suggestion */
  const handleSelect = (value: T) => {
    setOptions(undefined)
    onSelect && onSelect(value)
    setValue(undefined)
  }

  return (
    <SelectHolder
      className={classNameAutocomplete}
      messageEmpty={messageEmpty}
      onSelect={handleSelect}
      onBlur={() => setOptions(undefined)}
      options={options}
    >
      <TextField
        inputId={inputId}
        value={value}
        placeholder={placeholder}
        label={label}
        fieldStatus={fieldStatus}
        type={type}
        disabled={disabled}
        readOnly={readOnly}
        className={className}
        onChange={handleChange}
      />
    </SelectHolder>
  )
}

export default Autocomplete
