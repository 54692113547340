import React, { FC } from 'react'
import { CardBlock, EventType, TextArea, Transaction, ErrorField } from '@inpi-dm/components'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux'
import { storeInscriptionUpdate } from '../../../../store/inscription/inscriptionActions'

interface CorrectionStateBlockProps extends WrappedComponentProps {
  transaction: Transaction
}

const CorrectionStateBlock: FC<CorrectionStateBlockProps> = ({ transaction, intl }) => {
  const dispatch = useDispatch()
  const fieldStatus = useSelector((state : RootStateOrAny) => state.inscriptionFieldStatus)

  return (
    <CardBlock
      header={<FormattedMessage id='correction_form_state_block_title' />}
      shadow
    >
      <TextArea
        label={intl.formatMessage({ id: 'correction_form_state_block_change_this' })}
        inputId='changeText'
        spellCheck={false}
        value={transaction.inscription ? transaction.inscription.changeText : ''}
        onChange={(event: EventType) => dispatch(storeInscriptionUpdate({ inscription: { ...transaction.inscription, changeText: event.target.value } }))}
        rows={4}
      />
      <TextArea
        label={intl.formatMessage({ id: 'correction_form_state_block_by_this' })}
        inputId='byText'
        value={transaction.inscription ? transaction.inscription.byText : ''}
        spellCheck={false}
        onChange={(event: EventType) => dispatch(storeInscriptionUpdate({ inscription: { ...transaction.inscription, byText: event.target.value } }))}
        rows={4}
      />
      <ErrorField message={fieldStatus.replaceText} className='relative-position' />
      <TextArea
        label={intl.formatMessage({ id: 'correction_form_state_block_comment' })}
        inputId='comment'
        value={transaction.inscription ? transaction.inscription.comment : ''}
        spellCheck={false}
        onChange={(event: EventType) => dispatch(storeInscriptionUpdate({ inscription: { ...transaction.inscription, comment: event.target.value } }))}
        rows={2}
      />
    </CardBlock>
  )
}

export default injectIntl(CorrectionStateBlock)
