import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux'
import {
  ErrorField,
  containsErrors,
  EventType,
  FieldStatus,
  FileBrowserField,
  INSCRIPTION_DISCLAIMER,
  INSCRIPTION_TYPE,
  ModalComponent,
  Pagination,
  ROWS,
  ROWS_25,
  SelectField,
  SubmitButton,
  Title,
  Transaction
} from '@inpi-dm/components'
import { FormattedMessage, useIntl } from 'react-intl'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'
import OverviewRecords from '../../../title/OverviewRecords'
import { InscriptionFieldStatus } from '../../../../interfaces/InscriptionInterfaces'
import {
  storeInscriptionFieldStatusUpdate,
  storeInscriptionUpdate
} from '../../../../store/inscription/inscriptionActions'
import InscriptionService from '../../../../services/transaction/InscriptionService'
import Message from '../../../../constants/Message'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import DownloadLink from '../../../deposit/download/DownloadLink'
import { storeContributorsTitleUpdate } from '../../../../store/contributorsTitle/contributorsTitleActions'
import SelectInscriptionRecordsForm from './SelectInscriptionRecordsForm'
/* global FormData */

const SelectInscriptionRecords = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const inscription:Transaction = useSelector((state:RootStateOrAny) => state.inscription)
  const inscriptionFieldStatus:InscriptionFieldStatus = useSelector((state:RootStateOrAny) => state.inscriptionFieldStatus)
  const contributors = useSelector((state: RootStateOrAny) => state.contributorsTitle)

  const [titles, setTitles] = useState<Title[]>(inscription.inscription.titles || [])
  const [fieldStatus, setFieldStatus] = useState<FieldStatus[]>(inscriptionFieldStatus.titles || [])
  const [curentIndexTitle, setCurentIndexTitle] = useState(inscription.inscription.titles.length || 0)
  const [showForm, setShowForm] = useState(!(inscription.inscription.titles.length > 0))
  const [showModalCsv, setShowModalCsv] = useState(false)

  const [documentCsv, setDocumentCsv] = useState()
  const [errorsCsv, setErrorsCsv] = useState<string[]>([])

  const [actualPage, setActualPage] = useState(1)
  const [nbTitleShown, setNbTitleShown] = useState(ROWS_25.value)
  const [totalPage, setTotalPage] = useState(inscription.inscription?.titles && Math.ceil(inscription.inscription?.titles?.length / ROWS_25.value))
  const [minIndexShown, setMinIndexShown] = useState(0)
  const [maxIndexShown, setMaxIndexShown] = useState(ROWS_25.value - 1)

  useEffect(() => {
    setTotalPage(Math.ceil(titles.length / nbTitleShown))
  }, [titles])

  useEffect(() => {
    inscriptionFieldStatus.titles && setFieldStatus(inscriptionFieldStatus.titles)
  }, [inscriptionFieldStatus.titles])

  // Met à jour l'inscription au fur et à mesure qu'un titre est validé
  useEffect(() => {
    InscriptionService.updateInscriptionBDDFromStore(['titles'])
  }, [curentIndexTitle])

  /**
   *
   * Mise a jours du state et store
   * @param title
   * @param newFieldStatus
   */
  const onSubmit = (title:Title, newFieldStatus:FieldStatus) => {
    const updateTitle = [...titles]
    updateTitle[curentIndexTitle] = title
    const updateTitleFieldStatus = [...fieldStatus]
    // si il y a un warning mettre le message global
    if (newFieldStatus.publications.includes('warning')) {
      newFieldStatus = { ...newFieldStatus, publicationsWarning: Message.records_inscription_warning_existence }
    }
    updateTitleFieldStatus[curentIndexTitle] = newFieldStatus

    setTitles(updateTitle)
    setFieldStatus(updateTitleFieldStatus)
    dispatch(storeInscriptionUpdate({ inscription: { ...inscription.inscription, titles: updateTitle } }))
    dispatch(storeInscriptionFieldStatusUpdate({
      titles: updateTitleFieldStatus
    }))

    setCurentIndexTitle(updateTitle.length)
    setShowForm(false)
  }

  /**
   * Enregistrement dans le store des fieldStatus lié aux publications
   * @param newFieldStatusPublications
   * @param indexTitle
   */
  const onValidFieldStatus = (newFieldStatusPublications: string[], indexTitle: number) => {
    const updateTitles = inscriptionFieldStatus.titles ? [...inscriptionFieldStatus.titles] : []
    updateTitles[indexTitle] = { ...updateTitles[indexTitle], publications: newFieldStatusPublications }
    // si il y a un warning mettre le message global
    if (newFieldStatusPublications.includes('warning')) {
      updateTitles[indexTitle] = { ...updateTitles[indexTitle], publicationsWarning: Message.records_inscription_warning_existence }
    }
    dispatch(storeInscriptionFieldStatusUpdate({
      titles: updateTitles
    }))
  }

  /**
   * Supprimer un Title du state et store
   * @param index
   */
  const deleteOneTitle = (index:number) => {
    const updateTitle = [...titles]
    const numnatToDelete = titles[index].numNat
    updateTitle.splice(index, 1)
    setTitles(updateTitle)
    dispatch(storeInscriptionUpdate({ inscription: { ...inscription.inscription, titles: updateTitle } }))
    setCurentIndexTitle(-1)

    const updateFielStatusTitle = [...fieldStatus]
    updateFielStatusTitle.splice(index, 1)
    setFieldStatus(updateFielStatusTitle)
    dispatch(storeInscriptionFieldStatusUpdate({ titles: updateFielStatusTitle }))

    if (contributors) {
      const c = { ...contributors }
      if (numnatToDelete && c[numnatToDelete]) {
        delete c[numnatToDelete]
      }
      // On enregistre les intervenants dans le store pour pouvoir les proposer lors des formulaires des intervenants
      dispatch(storeContributorsTitleUpdate(c))
    }
  }

  /**
   * Lors de l'import du Csv et récupération des informations concernant les titres
   */
  const onSubmitCsv = () => {
    const formData = new FormData()
    formData.append('file', documentCsv)
    return InscriptionService.getTitlesFromCsv(formData).then((result) => {
      // Si au moins 1 publication ne contient pas son numBopi alors
      // cela signifie que nous n'avons pas retrouver son existence
      const fieldStatus : FieldStatus[] = new Array(result.length)
      result.forEach((title, index) => {
        const publicationNotFind = title.publications?.filter(elem => !elem.numBopi) || []
        if (publicationNotFind.length > 0) {
          fieldStatus[index] = {
            publicationsWarning: Message.records_inscription_warning_existence
          }
        }
      })
      setCurentIndexTitle(result.length)
      setShowForm(false)
      dispatch(storeInscriptionUpdate({ inscription: { ...inscription.inscription, titles: result } }))
      dispatch(storeInscriptionFieldStatusUpdate({
        titles: fieldStatus
      }))
      setTitles(result)
      setShowModalCsv(false)
      setErrorsCsv([])
      InscriptionService.updateInscriptionBDDFromStore(['titles'])
    }).catch(response => {
      setErrorsCsv([...response.errors])
    })
  }

  /**
   * Losque l'on change la pagination
   */
  const onChangePagination = (newPage: number) => {
    setActualPage(newPage)
    setMinIndexShown((newPage - 1) * nbTitleShown)
    setMaxIndexShown((newPage * nbTitleShown) - 1)
  }

  /**
   * Contenu de la modal lors de l'import de titres par Csv
   */
  const contentModalCsv = () => (
    <div className='is-validated'>
      <FormattedMessage id='records_inscription_download_csv_explain' />
      <div className='mt-3'>
        <FileBrowserField
          inputId='file'
          required
          label={<FormattedMessage id='records_inscription_download_csv_label' />}
          butonLabel={
            <div className='border'>
              <div className='text-center text-nowrap my-1'>
                <FormattedMessage id='button_find_file' />
              </div>
            </div>
          }
          onDelete={() => setDocumentCsv(null)}
          value={documentCsv && [documentCsv]}
          onChange={(event: EventType) => {
            const { value } = event.target
            setDocumentCsv(value)
          }}
          acceptApplication='.csv'
          maxNumberFile={1}
        />
      </div>
      {
        errorsCsv.length > 0 &&
        errorsCsv.map(error => <><ErrorField message={error} /><br /></>)
      }
    </div>
  )

  return (
    <>
      <div className='row'>
        <header className='col-8 mb-4'>
          <h1><FormattedMessage id='records_inscription_title' /></h1>
          <span className='subtitle'><FormattedMessage id='records_inscription_subtitle' /></span>
        </header>
        <InternalReferenceField
          value={inscription.internalReference}
          procedureType={INSCRIPTION_TYPE.value}
          className='col-4 mb-4'
        />
      </div>
      <div className='row m-0 d-flex justify-content-end'>
        <SubmitButton
          className='btn-link-primary'
          onClick={() => setShowModalCsv(true)}
        >
          <FormattedMessage id='records_inscription_upload_csv' />
        </SubmitButton>
        <a className='ml-3' href='/inscriptions/Aide_fichier_titres.pdf' target='_blank'>
          <FontAwesomeIcon icon={faQuestionCircle} />
        </a>
      </div>

      {
        curentIndexTitle > 0 && curentIndexTitle > minIndexShown &&
          <OverviewRecords
            titles={titles}
            changeTitle={(index: number) => {
              setShowForm(true)
              setCurentIndexTitle(index)
            }}
            deleteTitle={deleteOneTitle}
            isBeforeForm
            currentIndex={curentIndexTitle}
            fieldStatus={fieldStatus}
            minIndex={minIndexShown}
            maxIndex={maxIndexShown}
          />
      }
      {
        showForm &&
          <SelectInscriptionRecordsForm
            key={curentIndexTitle}
            initialTitle={titles[curentIndexTitle]}
            initialFieldStatus={fieldStatus && { ...fieldStatus[curentIndexTitle] }}
            onSubmit={onSubmit}
            onValidFieldStatus={(fieldStatusPub) => onValidFieldStatus(fieldStatusPub, curentIndexTitle)}
          />
      }
      {
        titles?.length > curentIndexTitle + 1 && curentIndexTitle < maxIndexShown &&
          <OverviewRecords
            titles={titles}
            changeTitle={(index: number) => {
              setShowForm(true)
              setCurentIndexTitle(index)
            }}
            deleteTitle={deleteOneTitle}
            isBeforeForm={false}
            currentIndex={curentIndexTitle}
            fieldStatus={fieldStatus}
            minIndex={minIndexShown}
            maxIndex={maxIndexShown}
          />
      }
      {
        !showForm && inscription.subProcedureType !== INSCRIPTION_DISCLAIMER.code &&
          <div>
            <hr />
            <div className='d-flex align-items-center justify-content-between'>
              <div className='mr-4'>
                <SubmitButton
                  className='btn-outline-primary'
                  onClick={() => {
                    setShowForm(true)
                    setCurentIndexTitle(titles.length)
                  }}
                >
                  <FormattedMessage id='records_inscription_add' />
                </SubmitButton>
              </div>
              <div>
                <DownloadLink
                  id='export-csv'
                  name='titres-inpi.csv'
                  onClick={() => InscriptionService.createCSVFromTitles(inscription.inscription.titles)}
                  label={intl.formatMessage({ id: 'records_inscription_download_csv' })}
                  classname='justify-content-start'
                  format='csv'
                />
              </div>
            </div>
          </div>
      }
      {
        fieldStatus.length > 0 && fieldStatus[0] && containsErrors(fieldStatus[0]) &&
          <ErrorField message={fieldStatus[0].notFound} />
      }
      {
        totalPage !== 0 &&
          <div className='row justify-content-end mt-3'>
            <div className='col-md-7 col-12'>
              <Pagination
                nbPage={totalPage}
                currentPage={actualPage}
                onChange={onChangePagination}
              />
            </div>
            <div className='col-md-3 col-12'>
              <SelectField
                inputId='rows'
                options={ROWS}
                value={nbTitleShown}
                onChange={(event) => {
                  const newNb = parseInt(event.target.value, 10)
                  setActualPage(1)
                  setMinIndexShown(0)
                  setMaxIndexShown(newNb - 1)
                  setNbTitleShown(newNb)
                  setTotalPage(Math.ceil(titles.length / newNb))
                }}
                required
              />
            </div>
          </div>
      }
      <ModalComponent
        title={<FormattedMessage id='records_inscription_upload_csv' />}
        show={showModalCsv}
        handleClose={() => setShowModalCsv(false)}
        customContent={contentModalCsv}
        onClick={onSubmitCsv}
      />
    </>
  )
}

export default withRouter(SelectInscriptionRecords)
