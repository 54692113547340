import Message from './Message'

export const HELP_DEPOSIT_TYPE = {
  value: 'HELP_DEPOSIT_TYPE',
  label: Message.help_procedure_type_deposit
}

export const HELP_PROROGATION_TYPE = {
  value: 'HELP_PROROGATION_TYPE',
  label: Message.help_procedure_type_prorogation
}

export const HELP_INSCRIPTION_TYPE = {
  value: 'HELP_INSCRIPTION_TYPE',
  label: Message.help_procedure_type_inscription
}

export const HELP_DECISION_TYPE = {
  value: 'HELP_DECISION_TYPE',
  label: Message.help_procedure_type_decision
}

export const HELP_TYPE = [
  HELP_DEPOSIT_TYPE,
  HELP_INSCRIPTION_TYPE,
  HELP_DECISION_TYPE
]
