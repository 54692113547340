import store from '../../../../../store/store'
import { containsErrors, FieldStatus, IntervenantValidator, isFilled, PERSONNE_PHYSIQUE } from '@inpi-dm/components'
import { createIntl } from 'react-intl'
import Message from '../../../../../constants/Message'
import { sirenValidity } from '@inpi-dm/components/src/utils/validationUtils'
import { storeOfficialDocumentFieldStatusUpdate } from '../../../../../store/officialDocument/officialDocumentActions'

class DepositorValidator {
  constructor () {
    this.intl = createIntl({ locale: 'fr', messages: Message })
  }

  validateOnChangeStep = () => {
    const officialDocument = store.getState().officialDocument

    let fieldStatus = IntervenantValidator.validateIntervenant(officialDocument.depositor)
    if (!isFilled(officialDocument.depositor?.email)) {
      fieldStatus = { ...fieldStatus, email: Message.required_field }
    }
    if (officialDocument.depositor?.type === PERSONNE_PHYSIQUE.value) {
      return this.dispatchAndValidate(fieldStatus)
    } else {
      if (officialDocument.depositor?.siren && !isFilled(fieldStatus.siren)) {
        const errorMessage = sirenValidity(officialDocument.depositor)
        if (errorMessage) {
          fieldStatus = {
            ...fieldStatus,
            siren: this.intl.formatMessage({ id: errorMessage })
          }
        }
      }
      return this.dispatchAndValidate(fieldStatus)
    }
  }

  dispatchAndValidate = (fieldStatus: FieldStatus): boolean => {
    store.dispatch(storeOfficialDocumentFieldStatusUpdate({
      depositor: fieldStatus
    }))
    return !containsErrors(fieldStatus)
  }
}

export default new DepositorValidator()
