import React, { FC, ReactNode, useEffect, useState } from 'react'
import { Deposit, PersonneFieldValidator, Transaction, SubmitButton, CardBlock, APPEAL_TYPE, CheckboxField, FieldStatus } from '@inpi-dm/components'
import { PaymentParameters } from '../../interfaces/DepositInterfaces'
import InternalReferenceField from '../internalReference/InternalReferenceField'
import SelectPaymentBlocks from './SelectPaymentBlocks'
import PaymentService from '../../services/transaction/PaymentService'
import { useHistory } from 'react-router'
import { PAYMENT_METHOD, PAYMENT_OBJECT } from '../../constants/DepositConstants'
import { FormattedMessage } from 'react-intl'
import Message from '../../constants/Message'

interface PaymentFormProps {
  label: string,
  overviewPrices: ReactNode,
  object: Deposit | Transaction,
  title: string | ReactNode,
  urlType: string,
  objectName?: string,
  objectId?: string,
  totalAmount: number,
  isMultiTransactions?: boolean,
  hasSupplementExcluded?: boolean
}

const PaymentForm: FC<PaymentFormProps> = ({
  children,
  label,
  title,
  overviewPrices,
  object,
  urlType,
  objectName,
  objectId,
  totalAmount,
  isMultiTransactions,
  hasSupplementExcluded
}) => {
  const history = useHistory()
  const [canAccessManageableMemory, setCanAccessManageableMemory] = useState(false)
  const [askForFreeAppeal, setAskForFreeAppeal] = useState(false)
  const [errorRequired, setErrorRequired] = useState<FieldStatus>()
  const handleStartPayment = (payment: PaymentParameters) => {
    payment = {
      ...payment,
      label,
      objectName,
      hasSupplementExcluded
    }
    if (objectId) {
      payment = {
        ...payment,
        objectIds: [objectId]
      }
    }

    const idTransaction = object.idTransaction ? object.idTransaction : object.id
    return PaymentService.createPayment(idTransaction, payment).then(result => {
      if (payment.method === PAYMENT_METHOD.BLUE_CARD) {
        PaymentService.displayPayBox(result)
      } else {
        let url = `/${urlType}/${idTransaction}/paiement/confirmation`
        if (isMultiTransactions) {
          url += '?isMulti'
        }
        history.push(url)
      }
    }
    ).catch(() => {
      history.push(`/${urlType}/${idTransaction}/paiement/erreur`)
    })
  }

  useEffect(() => {
    const contributors = []
    if (object.depositor) {
      contributors.push({ ...object.depositor })
    }
    if (object.coDepositors) {
      contributors.push(...object.coDepositors)
    }
    if (object.applicants) {
      contributors.push(...object.applicants)
    }
    setCanAccessManageableMemory(contributors.find(user => user && PersonneFieldValidator.isPersonneMorale(user) && user.publicLaw))
  }, [object])

  return (
    <div className='payment-form'>
      <div className='view'>
        <div className='row ml-3'>
          <header className='col-8 align-self-center'>
            <h1>{title}</h1>
          </header>
          {objectName === PAYMENT_OBJECT.TRANSACTION
            ? (
              <InternalReferenceField
                className='col-4 align-self-center'
                readOnly
                value={object.internalReference}
                procedureType={object.procedureType}
              />
            )
            : null}
        </div>

        <div className='row mb-3'>
          <div className='col-12'>
            {overviewPrices}
          </div>
        </div>

        {totalAmount !== undefined && totalAmount !== 0 &&
          <SelectPaymentBlocks
            manageableMemoryAccessible={canAccessManageableMemory}
            onStartPayment={handleStartPayment}
            object={object}
          />}

        {(totalAmount === 0 || object.procedureType === APPEAL_TYPE.value) &&
          <div className='row'>
            <div className='col-12'>
              <CardBlock shadow>
                {object.procedureType === APPEAL_TYPE.value &&
                  <CheckboxField
                    inputId='appeal-free'
                    label={<FormattedMessage id='payment_ask_free_appeal' />}
                    checked={askForFreeAppeal}
                    onChange={() => setAskForFreeAppeal(!askForFreeAppeal)}
                    fieldStatus={errorRequired}
                  />}
                <FormattedMessage id={`payment_confirm_free_${object.procedureType}`} />
                <div className='d-flex w-25 align-self-center mt-4 justify-content-center'>
                  <SubmitButton
                    className='btn-block btn-secondary'
                    onClick={() => {
                      if (object.procedureType === APPEAL_TYPE.value && !askForFreeAppeal) {
                        setErrorRequired({
                          'appeal-free': Message.payment_ask_free_appeal_error
                        })
                      } else {
                        return handleStartPayment({
                          method: PAYMENT_METHOD.FREE
                        })
                      }
                    }}
                  >
                    <FormattedMessage id='button_confirm' />
                  </SubmitButton>
                </div>
              </CardBlock>
            </div>
          </div>}
      </div>
      {children}
    </div>
  )
}

export default PaymentForm
