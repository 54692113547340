import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import OneRecordSearchResult from './OneRecordSearchResult'
import { DATE_ISO, DateUtils, Publication, Record, SubmitButton, Title } from '@inpi-dm/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { ProrogationSearchTitle } from '../../../../interfaces/ProrogationInterfaces'
import ProrogationService from '../../../../services/transaction/ProrogationService'
import RecordService from '../../../../services/record/RecordService'

interface SelectOneRecordSearchResultProps {
    resultSearch: ProrogationSearchTitle,
    resetSearch: () => void,
    onSubmit: (title: Title) => void,
    indexRecordSelected: number,
    setIndexRecordSelected: (index:number)=>void,
    setShowFormSelection: (show: boolean)=>void,
    setCurrentContributors: (contributors: any) => void
}

const SelectRecordSearchResult: FC<SelectOneRecordSearchResultProps> = ({
  resultSearch,
  resetSearch,
  onSubmit,
  indexRecordSelected,
  setIndexRecordSelected,
  setShowFormSelection,
  setCurrentContributors
}) => {
  /**
     * Enregistre les informations du titre dans le store
     */
  const submitTitleTotal = async () => {
    if (indexRecordSelected !== undefined && resultSearch.results.length > indexRecordSelected && resultSearch.publications) {
      // on récupère le dossier sélectionné et ses id de publications
      const recordSelected = resultSearch.results[indexRecordSelected]
      let allPublicationsRecordSelected : Publication[] = []
      for (const model of recordSelected.models) {
        for (const reproduction of model.reproductions) {
          const uniquePublications = [...new Map([...reproduction.publications].map(item =>
            [item.idPublication, item])).values()]
          allPublicationsRecordSelected = [...allPublicationsRecordSelected, ...uniquePublications]
        }
      }

      // on récupère les id de publication venant de la recherche pour ne prendre comme publications que
      // celles qui proviennent de la recherche et qui ont été sélectionnées
      const idPublicationSearch = resultSearch.publications.map((publication: Publication) => publication.idPublication)
      const publicationFromSearchAndSelected = allPublicationsRecordSelected
        .filter(publicationRecord => publicationRecord.idPublication && idPublicationSearch.includes(publicationRecord.idPublication))

      const modelsSelected = RecordService.getAllSelectedModels(recordSelected, publicationFromSearchAndSelected)
      const typeDelay = await ProrogationService.getTypeDelay(modelsSelected, recordSelected)
      const protectionExtensions = await RecordService.fillProtectionExtensions(recordSelected)

      // on enregistre dans le store
      onSubmit({
        idRecord: recordSelected.id,
        numNat: recordSelected.numNat,
        status: recordSelected.state,
        origin: recordSelected.country,
        state: recordSelected.state,
        depositDate: DateUtils.formateDateToFormat(recordSelected.depositDate, DATE_ISO),
        publications: publicationFromSearchAndSelected,
        isScopePartial: publicationFromSearchAndSelected.length < idPublicationSearch.length,
        models: modelsSelected,
        typeDelay: typeDelay,
        isProrogationAlreadyAsk: resultSearch.isProrogationAlreadyAsk,
        protectionExtensions: protectionExtensions
      })
    }
  }

  return (
    <>
      <hr />
      <div className='text-gris text-uppercase'>
        <FormattedMessage id='records_prorogation_search_result' />
      </div>
      <div className='d-flex justify-content-center font-weight-bold m-3'>
        <FormattedMessage id={`records_prorogation_search_result_subtitle_${resultSearch.way}`} />
      </div>
      <div>
        {
          resultSearch.results.map((record: Record, indexRecord: number) => (
            <OneRecordSearchResult
              key={record.id || indexRecord}
              indexRecordSelected={indexRecordSelected}
              setIndexRecordSelected={setIndexRecordSelected}
              setCurrentContributors={setCurrentContributors}
              record={record}
              publicationsId={resultSearch.publications?.map(publication => publication.idPublication) || []}
              indexRecord={indexRecord}
            />
          ))
        }
        {
          indexRecordSelected !== undefined &&
            <div className='d-flex justify-content-around'>
              <SubmitButton
                className='btn-outline-primary'
                onClick={() => setShowFormSelection(true)}
              >
                <FormattedMessage id='records_prorogation_search_result_partial' />
              </SubmitButton>
              <SubmitButton
                className='btn-primary'
                onClick={submitTitleTotal}
              >
                <FormattedMessage id='records_prorogation_search_result_total' />
              </SubmitButton>
            </div>
        }
      </div>
      <div className='d-flex justify-content-start ml-3 mt-3'>
        <SubmitButton
          className='btn-link-primary'
          onClick={resetSearch}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          <span className='ml-2'>
            <FormattedMessage id='records_prorogation_return_search' />
          </span>
        </SubmitButton>
      </div>
    </>
  )
}

export default SelectRecordSearchResult
