import React, { FC, useEffect, useState } from 'react'
import OverviewLabelText from './OverviewLabelText'
import { FormattedMessage } from 'react-intl'
import { SubmitButton, Country } from '@inpi-dm/components'
import { DepositPriorityOptions } from '../../../../interfaces/DepositInterfaces'
import ContentService from '../../../../services/content/ContentService'
import DownloadLink from '../../download/DownloadLink'

interface OverviewPrioritiesProps {
  depositId: string,
  priorities?: DepositPriorityOptions[],
  depositView?: boolean | null
}

const OverviewPriorities: FC<OverviewPrioritiesProps> = ({
  depositId,
  priorities = [],
  depositView
}) => {
  const [countries, setCountries] = useState<Country[]>([])
  const [seeMore, setSeeMore] = useState(false)

  useEffect(() => {
    let unmounted = false
    ContentService.getCountries().then((res) => {
      if (!unmounted) {
        setCountries(res)
      }
    })

    return () => {
      unmounted = true
      ContentService.cancelRequest()
    }
  }, [])

  return (
    <OverviewLabelText idLabel='options_depot_form_priorites_title'>
      {
        priorities.slice(0, seeMore ? priorities.length : 3).map((priority, index) => {
          const country = countries.find(c => c.code === priority.countryOrOrganization)
          return (
            <div key={priority.id}>
              <div>
                <FormattedMessage id='options_depot_form_priorites' />
                {index + 1}
                {' - '}
                {country ? `${country.label} - ` : ''}
                {priority.originalDepositNumber}
              </div>
              {
                !depositView && priority.documents && priority.documents.map(doc => {
                  return (
                    <DownloadLink
                      key={doc.internalName}
                      id={depositId}
                      name={doc.name}
                      internalName={doc.internalName}
                      label={doc.name}
                      classname='justify-content-start'
                    />)
                })
              }
            </div>

          )
        })
      }
      {
        priorities.length > 3 &&
          <SubmitButton
            className='btn-link text-primary'
            onClick={() => setSeeMore(seeMore => !seeMore)}
          >
            {seeMore ? 'Voir moins' : 'Voir plus'}
          </SubmitButton>
      }
    </OverviewLabelText>
  )
}

export default OverviewPriorities
