import React, { FC } from 'react'
import {
  Preview
} from '@inpi-dm/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

interface PreviewAndTrashRectificatifProps {
    loadFile: ()=>Promise,
    onClick: ()=>void,
    onTrashClick: ()=>void,
    refreshAction?: boolean
}

const PreviewAndTrashRectificatif: FC<PreviewAndTrashRectificatifProps> = ({
  loadFile,
  onClick,
  onTrashClick,
  refreshAction
}) => {
  return (
    <div className='mr-5 reproduction border-0'>
      <Preview
        className='img-thumbnail mr-3 cursor-pointer'
        file={loadFile}
        onClick={onClick}
        refreshAction={refreshAction}
      />
      <FontAwesomeIcon
        icon={faTrash}
        className='text-secondary cursor-pointer'
        onClick={onTrashClick}
      />
    </div>
  )
}

export default PreviewAndTrashRectificatif
