import {
  CONTRIBUTORS_TITLE_REMOVE,
  CONTRIBUTORS_TITLE_UPDATE, ContributorsTitleStoreAction
} from './contributorsTitleStoreTypes'

export const storeContributorsTitleUpdate = (ContributorsTitle: any): ContributorsTitleStoreAction => ({
  type: CONTRIBUTORS_TITLE_UPDATE,
  infosByNumNat: ContributorsTitle
})

export const storeContributorsTitleRemove = () => ({
  type: CONTRIBUTORS_TITLE_REMOVE
})
