import React, { FC, useState, useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  CardBlock,
  Transaction,
  OverviewComparedIntervenant,
  Country,
  ManageableQuality,
  STATUS_DO, CheckboxField, FileBrowserField, EventType, DepositDocument,
  NOTIF_STATUS_VISIBLE_TO_FO, SENDING_PORTAL, OfficialDocumentRequest
} from '@inpi-dm/components'
import DepositService from '../../../../services/transaction/DepositService'
import ContentService from '../../../../services/content/ContentService'
import DocumentBlock from '../../../requests/DocumentBlock'
import AskedOfficialDocument from './AskedOfficialDocument'
import Message from '../../../../constants/Message'
import { storeOfficialDocumentUpdate } from '../../../../store/officialDocument/officialDocumentActions'
import TransactionService from '../../../../services/transaction/TransactionService'
import { useDispatch } from 'react-redux'
import OverviewNotifications from '../../../deposit/form/overview/notifications/OverviewNotifications'

interface OverviewOfficialDocumentProps {
  transaction: Transaction,
  isRequestView?: boolean | null,
  setReload?: () => void,
}

const OverviewOfficialDocument: FC<OverviewOfficialDocumentProps> = ({
  transaction,
  isRequestView = false,
  setReload
}) => {
  const dispatch = useDispatch()
  const [countries, setCountries] = useState<Country[]>()
  const [manageableQualities, setManageableQualities] = useState<ManageableQuality[]>()
  const [requests, setRequests] = useState<OfficialDocumentRequest[]>(transaction.officialDocument?.officialDocumentRequests || [])
  const notificationReference = useRef(null)

  useEffect(() => {
    let unmounted = false
    ContentService.getManageableQualities().then(result => {
      if (!unmounted) {
        setManageableQualities(result)
      }
    })

    ContentService.getCountries().then(result => {
      if (!unmounted) {
        setCountries(result)
      }
    })

    return () => {
      unmounted = true
      ContentService.cancelRequest()
      DepositService.cancelRequest()
    }
  }, [])

  useEffect(() => {
    setRequests(transaction.officialDocument?.officialDocumentRequests || [])
  }, [transaction.officialDocument?.officialDocumentRequests])

  /**
   * Modification de la pièce justificative
   * @param event
   */
  const handleChangeFile = async (event: EventType) => {
    const { value } = event.target
    const newDocument: DepositDocument = value !== null ? {
      name: value.name,
      file: value
    } : {}
    dispatch(storeOfficialDocumentUpdate({
      ...transaction,
      documents: [newDocument]
    }))
  }

  const handleIsAdministration = async (event: EventType) => {
    if (transaction.documents && transaction.documents[0] && transaction.documents[0].internalName) {
      await TransactionService.deleteDocumentFile(transaction.id, transaction.documents[0].internalName)
    }
    dispatch(storeOfficialDocumentUpdate({
      ...transaction,
      documents: [],
      officialDocument: {
        ...transaction.officialDocument,
        isAdministration: event.target.value
      }
    }))
  }

  return (
    <div className='row'>
      {(isRequestView && transaction.notifications && transaction.notifications
        .filter((notification) => NOTIF_STATUS_VISIBLE_TO_FO.includes(notification.status) && notification.sendingMode && notification.sendingMode.includes(SENDING_PORTAL))
        .length > 0)
        ? (
          <div className='col-12 mb-3'>
            <CardBlock
              header={<FormattedMessage id='overview_deposit_card_notification_title' />}
              shadow
            >
              <OverviewNotifications
                transaction={transaction}
                setReload={setReload}
                notificationReference={notificationReference}
              />
            </CardBlock>
          </div>
        )
        : null}
      {
        (isRequestView && transaction.status !== STATUS_DO.DRAFT_CLIENT) &&
          <div className='col-12 mb-3'>
            <DocumentBlock
              transaction={transaction}
              setReload={setReload}
              blockTitle={<FormattedMessage id='request_document_title' />}
            />
          </div>
      }

      <div className='col-12 mb-3'>
        <CardBlock
          header={
            <div className='d-flex justify-content-between'>
              <FormattedMessage id='overview_deposit_card_intervenant_title' />
            </div>
          }
          shadow
        >
          <div className='row'>
            {
              transaction.depositor && (
                <div className='col-12 mb-3 col-md-6'>
                  <h4><FormattedMessage id='overview_applicant_depositor' /></h4>
                  <OverviewComparedIntervenant
                    intervenant={transaction.depositor}
                    countries={countries}
                    qualities={manageableQualities}
                  />
                </div>
              )
            }
          </div>
          {!isRequestView && (
            <>
              <div className='d-flex mt-2'>
                <CheckboxField
                  labelClassName='font-weight-bold'
                  inputId='isAdministration'
                  label={<FormattedMessage id='official_document_is_administration' />}
                  checked={transaction.officialDocument?.isAdministration}
                  onChange={handleIsAdministration}
                />
              </div>
              {transaction.officialDocument?.isAdministration && (
                <div className='d-flex'>
                  <FileBrowserField
                    label={Message.official_document_justificatif_file}
                    inputId='file-justificatif'
                    value={transaction.documents && transaction.documents.length > 0 ? transaction.documents : []}
                    onChange={(e) => handleChangeFile(e)}
                    maxNumberFile={1}
                    acceptApplication='application/pdf'
                    butonLabel={
                      <div className='form-control placeholder'>
                        <FormattedMessage id='button_find_file' />
                      </div>
                    }
                  />
                </div>
              )}
            </>
          )}
        </CardBlock>
      </div>
      <div className='col-12 mb-3'>
        <CardBlock
          header={
            <div className='d-flex justify-content-between'>
              <FormattedMessage id='official_document_asked' />
            </div>
          }
          shadow
        >
          <AskedOfficialDocument idTransaction={transaction.id} requests={requests} setRequests={setRequests} setReload={setReload} />
        </CardBlock>
      </div>
    </div>
  )
}

export default OverviewOfficialDocument
