import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Badge, CardBlock } from '@inpi-dm/components'

interface ConstantDepositType {
  code: string,
  title: string,
  resume: string,
}

interface SelectTypeItemProps {
  type: ConstantDepositType,
  value?: string,
  onClick: (value: string) => void,
  classNameText?: string,
}

const SelectTypeItem: FC<SelectTypeItemProps> = ({
  type,
  value = '',
  onClick,
  classNameText
}) => (
  <CardBlock
    shadow
    onClick={() => onClick(type.code)}
    className={`${value === type.code ? 'active' : ''} theme-primary`}
  >
    {value === type.code && (
      <Badge className='shadow top-right text-primary'>
        <FontAwesomeIcon icon={faCheck} />
      </Badge>
    )}
    <h2>
      <FormattedMessage id={type.title} />
    </h2>
    <p className={classNameText || 'pt-4'}>
      <FormattedMessage
        id={type.resume}
        values={{ b: (...chunks) => (<b>{chunks}</b>) }}
      />
    </p>
  </CardBlock>
)

export default SelectTypeItem
