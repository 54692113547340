import React, { FC, useEffect, useState } from 'react'
import { Payment } from '../../../../interfaces/DepositInterfaces'
import OverviewRoyalties from '../../../payment/OverviewRoyalties'
import { PAYMENT_OBJECT } from '../../../../constants/DepositConstants'
import { FormattedMessage } from 'react-intl'
import PaymentForm from '../../../payment/PaymentForm'
import { Transaction } from '@inpi-dm/components'
import { PAYMENT_URL_APPEAL } from '../../../../constants/PaymentConstants'
import AppealService from '../../../../services/transaction/AppealService'

interface PaymentAppealProps {
    transaction: Transaction
}

const PaymentAppeal: FC<PaymentAppealProps> = ({
  transaction
}) => {
  const [infoPrice, setInfoPrice] = useState<Payment>()

  useEffect(() => {
    // Récupération des informations concernant le paiement
    AppealService.getPayment(transaction.id)
      .then((payment) => {
        return payment && setInfoPrice(payment)
      }
      )
  }, [transaction])

  return (
    <PaymentForm
      label='Demande initiale relevé de déchéance'
      overviewPrices={<OverviewRoyalties infoPrice={infoPrice} />}
      object={transaction}
      urlType={PAYMENT_URL_APPEAL}
      objectName={PAYMENT_OBJECT.TRANSACTION}
      title={<FormattedMessage id='payment' />}
      totalAmount={infoPrice?.totalAmount}
    />
  )
}

export default PaymentAppeal
