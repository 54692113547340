import { FieldStatus, messageIsFilled, ReproductionLightDeposit } from '@inpi-dm/components'
import { DEPOT_SIMPLIFIE } from '@inpi-dm/components/src/constants/DepositConstants'
import store from '../../../../../../store/store'

class ReproductionFormValidator {
    validateMandatory = (reproduction: ReproductionLightDeposit): FieldStatus => {
      return {
        reproductionLabel: store.getState().deposit?.depositType === DEPOT_SIMPLIFIE.code ? '' : messageIsFilled(reproduction.label),
        reproductionColor: store.getState().deposit?.depositType === DEPOT_SIMPLIFIE.code ? '' : messageIsFilled(reproduction.color)
      }
    }
}

export default new ReproductionFormValidator()
