import React, { FC, MutableRefObject, useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { DateUtils, Transaction, SubmitButton } from '@inpi-dm/components'
import OverviewNotificationsInfo from './OverviewNotificationsInfo'
import OverviewNotificationsAnswer from './OverviewNotificationsAnswer'
import { Accordion, AccordionContext, useAccordionToggle } from 'react-bootstrap'
import NotificationItem from './NotificationItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import fileDownload from 'js-file-download'
import NotificationService from '../../../../../services/transaction/NotificationService'
import { NOTIF_STATUS, NOTIF_STATUS_VISIBLE_TO_FO, NOTIF_TYPE_NO_RESPONSE_EXPECTED, SENDING_PORTAL } from '@inpi-dm/components/src/constants/NotificationsConstants'
import { NotificationInterface } from '@inpi-dm/components/src/interfaces/DepositInterfaces'
import moment from 'moment'

interface OverviewNotificationsProps {
  transaction: Transaction,
  setReload?: ()=>void,
  reproReference?: MutableRefObject<null>,
  setIsEditableRepro?: (isEditableRepro: boolean) => void,
  notificationReference: MutableRefObject<null>
}

const OverviewNotifications: FC<OverviewNotificationsProps> = ({
  transaction,
  setReload,
  reproReference,
  setIsEditableRepro,
  notificationReference
}) => {
  const intl = useIntl()

  /**
   * Les premiers champs qui seront toujours visible, si on clique sur le chevron on ouvre l'accordéon
   * */
  const ContextAwareToggle: FC = ({ eventKey }) => {
    const currentEventKey = useContext(AccordionContext)

    const decoratedOnClick = useAccordionToggle(
      eventKey
    )
    const isCurrentEventKey = currentEventKey === eventKey

    return (
      <button
        type='button'
        onClick={decoratedOnClick}
        className='bg-transparent border-0 col-1 btn btn-link-primary'
      >
        <FormattedMessage id={isCurrentEventKey ? 'overview_deposit_notification_close' : 'overview_deposit_notification_open'} />
      </button>
    )
  }

  /**
   * Lance le téléchargement de la notification au format pdf
   * */
  const downloadNotificationPDF = (notification: NotificationInterface) => {
    return NotificationService.downloadNotification(transaction.id, notification.id).then(response => {
      return fileDownload(response, `${intl.formatMessage({ id: 'overview_deposit_notification_filename' })}-${notification.id}.pdf`, 'pdf')
    })
  }

  return (
    <div ref={notificationReference} id='overview-notification'>
      {transaction?.notifications?.sort((a, b) => (a.sendingDate && !b.sendingDate) || moment(a.sendingDate) > moment(b.sendingDate) ? -1 : 1)
            .filter((notification) => NOTIF_STATUS_VISIBLE_TO_FO.includes(notification.status) && notification.sendingMode && notification.sendingMode.includes(SENDING_PORTAL))
            .map((notification, index) => {
              return (
                <div key={notification.id} className='row mx-0 mb-3'>
                  <Accordion
                    className='w-100' defaultActiveKey={notification.status === NOTIF_STATUS.NOTIF_STATUS_WAITING_ANSWER ||
                  notification.type === NOTIF_TYPE_NO_RESPONSE_EXPECTED ? `collapse-notification-${index}` : ''}
                  >
                    <div className='header-accordion d-flex justify-content-between border-top pt-2 mt-2'>
                      <NotificationItem className='col-3' label={<FormattedMessage id='overview_deposit_notification_title_label' />}>
                        {notification.title}
                      </NotificationItem>
                      <NotificationItem className='col-2' label={<FormattedMessage id='overview_deposit_notification_received' />}>
                        {DateUtils.formatDateFr(notification.sendingDate)}
                      </NotificationItem>
                      <NotificationItem className='col-2' label={<FormattedMessage id='overview_deposit_notification_due_to' />}>
                        {notification.deadline ? DateUtils.formatDateFr(notification.deadline) : '-'}
                      </NotificationItem>
                      <NotificationItem className='col-3' label={<FormattedMessage id='overview_deposit_notification_status_label' />}>
                        <FormattedMessage id={`overview_deposit_notification_status_${notification.tab}`} />
                      </NotificationItem>
                      <div className='col-1 d-flex justify-content-center align-items-center'>
                        {notification.notificationFile
                          ? (
                            <SubmitButton className='btn btn-link btn-override-width text-primary bg-transparent' onClick={() => downloadNotificationPDF(notification)}>
                              <FontAwesomeIcon icon={faDownload} className='mr-2' />
                            </SubmitButton>
                          ) : null}
                      </div>
                      <ContextAwareToggle eventKey={`collapse-notification-${index}`} />
                    </div>
                    <Accordion.Collapse eventKey={`collapse-notification-${index}`}>
                      <div className='row mt-3'>
                        <div className='col-6'>
                          <OverviewNotificationsInfo notification={notification} download={() => downloadNotificationPDF(notification)} />
                        </div>
                        <div className='col-6'>
                          <OverviewNotificationsAnswer
                            initialNotification={notification} index={index} transaction={transaction}
                            setReload={setReload} reproReference={reproReference} setIsEditableRepro={setIsEditableRepro}
                          />
                        </div>
                      </div>
                    </Accordion.Collapse>
                  </Accordion>
                </div>)
            })}
    </div>
  )
}

export default OverviewNotifications
