import React, { FC, useState, useEffect } from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import {
  FieldStatus,
  isFilled,
  Publication,
  SubmitButton,
  Title,
  containsErrors,
  OfficialDocumentType,
  TITLE_INSCRIPTION_ORIGIN_FR,
  OfficialDocumentRequest,
  CERTIFICATE_OF_IDENTITY_FRENCH_DESIGN,
  OFFICIAL_COPY_FRENCH_DESIGN
} from '@inpi-dm/components'
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux'
import SearchAndManualRecordsForm from '../../../title/SearchAndManualRecordsForm'
import SelectRecordFormValidator from './validator/SelectRecordFormValidator'
import { storeContributorsTitleUpdate } from '../../../../store/contributorsTitle/contributorsTitleActions'
import {
  RECORD_OFFICIAL_DOCUMENT_INFORMATION_FROM_SELECT,
  RECORD_OFFICIAL_DOCUMENT_INFORMATION_FROM_SELECT_WITH_MODEL
} from '../../../../constants/OfficialDocumentConstants'
import OfficialDocumentRequestForm from './OfficialDocumentRequestForm'

interface SelectRecordsFormProps extends WrappedComponentProps {
  request: OfficialDocumentRequest
  setRequest: (request: OfficialDocumentRequest) => void,
  initialFieldStatus?: FieldStatus,
  onSubmit?: (request: OfficialDocumentRequest, fieldStatus: FieldStatus) => void,
  typesOfficialsDocument: OfficialDocumentType[]
}

const SelectOfficialDocumentRecordsForm: FC<SelectRecordsFormProps> = ({
  request,
  setRequest,
  initialFieldStatus,
  onSubmit,
  typesOfficialsDocument

}) => {
  const dispatch = useDispatch()
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>(initialFieldStatus || {})
  const [currentContributors, setCurrentContributors] = useState({})
  const [publicationsIds, setPublicationsIds] = useState<string[]>([])
  const infosByNumNat = useSelector((state: RootStateOrAny) => state.contributorsTitle)

  useEffect(() => {
    if (request?.title?.publications && request?.title.publications.length > 0) {
      const publicationString = request.title.publications.map((publication : Publication) => {
        if (publication.idPublication) {
          return publication.idPublication
        }
        return ''
      })
      setPublicationsIds(publicationString)
    }
  }, [])

  /**
   * Vérifie que les champs obligatoire soient remplis,
   * enregistre dans le store les informations et ferme le formulaire
   */
  const submitForm = () => {
    // Enlever les publications vides
    let updateRequest : OfficialDocumentRequest = { ...request }
    // Récupérer toutes les publications et les mettre ensemble dans title
    const newPublication = request.title?.publications?.filter((publication: Publication) => isFilled(publication.idPublication)) || []
    updateRequest = { ...updateRequest, title: { ...updateRequest.title, publications: [...newPublication] } }
    setRequest(updateRequest)
    // Vérifier s'il n'y a pas d'erreur
    const fieldStatusError = SelectRecordFormValidator.validateMandatory(updateRequest)
    setFieldStatus(fieldStatusError)

    if (!containsErrors(fieldStatusError)) {
      if (currentContributors && updateRequest.title?.numNat) {
        const newContributorsTitle = {
          ...infosByNumNat,
          [updateRequest.title.numNat]: currentContributors
        }
        if (request?.title?.numNat && infosByNumNat[request.title?.numNat] && updateRequest.title?.numNat !== request.title.numNat) {
          delete newContributorsTitle[request.title?.numNat]
        }
        // On enregistre les intervenants dans le store pour pouvoir les proposer lors des formulaires des intervenants
        dispatch(storeContributorsTitleUpdate({
          ...newContributorsTitle
        }))
      }
      onSubmit && onSubmit(updateRequest, fieldStatusError)
    }
  }

  const isModelsShown = () : boolean => {
    return (request.officialDocumentType?.code === OFFICIAL_COPY_FRENCH_DESIGN || request.officialDocumentType?.code === CERTIFICATE_OF_IDENTITY_FRENCH_DESIGN)
  }

  return (
    <>
      <hr />
      <div className='mb-5'>
        <div className='mx-3 header-field'>
          <FormattedMessage id='records_official_document_service_label' />
        </div>
        <OfficialDocumentRequestForm
          request={request}
          setRequest={setRequest}
          fieldStatus={fieldStatus}
          typesOfficialsDocument={typesOfficialsDocument}
        />
      </div>
      <div>
        <div className='mx-3 header-field'>
          <FormattedMessage id='records_official_document_record_label' />
        </div>
        <SearchAndManualRecordsForm
          title={request.title}
          modelsSelected={request.modelsSelected}
          setModelsSelected={(newModelsSelected: string) => setRequest((oldRequest: OfficialDocumentRequest) => ({ ...oldRequest, modelsSelected: newModelsSelected }))}
          setTitle={(newTitle: Title) => setRequest((oldRequest: OfficialDocumentRequest) => ({ ...oldRequest, title: { ...newTitle, origin: newTitle.origin ? newTitle.origin : TITLE_INSCRIPTION_ORIGIN_FR } }))}
          publicationsIds={publicationsIds}
          setPublicationsIds={setPublicationsIds}
          fieldStatus={fieldStatus}
          setFieldStatus={setFieldStatus}
          setCurrentContributors={setCurrentContributors}
          optionSearch={isModelsShown() ? RECORD_OFFICIAL_DOCUMENT_INFORMATION_FROM_SELECT_WITH_MODEL : RECORD_OFFICIAL_DOCUMENT_INFORMATION_FROM_SELECT}
          allRecordRequestedForPublication
          idPublicationShown={false}
          modelsShown={isModelsShown()}
          originShown={false}
          classNameCol='col-12 col-md-4'
        />
      </div>
      <div className='row m-0 d-flex justify-content-end mt-5'>
        {onSubmit &&
          <SubmitButton
            onClick={submitForm}
            className='btn-outline-primary m-2'
          >
            <FormattedMessage id='records_inscription_submit_form' />
          </SubmitButton>}
      </div>
    </>
  )
}

export default injectIntl(SelectOfficialDocumentRecordsForm)
