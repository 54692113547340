export const LOGO_SRC = '/logo-INPI.png'
export const LOGO_WHITE_SRC = '/img/logo-inpi-blanc.png'
export const LOGO_HEADER = '/img/logo-dm.png'
export const PICTO_HEADER_HELP = '/img/picto-help.png'
export const PICTO_HEADER_PHONE = '/img/picto-phone.png'
export const PICTO_HEADER_USER = '/img/picto-user.png'
export const PICTO_HEADER_LOGOUT = '/img/picto-logout.png'

export const API_ADDRESS_SEARCH = 'https://api-adresse.data.gouv.fr/search/'

export const MAIN_FOOTER_LINK = [
  {
    id: 'personal_data',
    label: 'footer_personal_datas',
    url: 'https://www.inpi.fr/fr/donnees-personnelles'
  }, {
    id: 'cookies',
    label: 'footer_cookies',
    url: 'https://www.inpi.fr/fr/information-sur-les-cookies'
  }, {
    id: 'legal_notice',
    label: 'footer_legal_notice'
  }, {
    id: 'cgu',
    label: 'footer_cgu',
    url: '/aide/cgu-portail-procedures-INPI.pdf'
  }, {
    id: 'cpu',
    label: 'footer_cpu',
    url: '/aide/CPU_Portail_Dessins_et_modeles.pdf'
  }, {
    id: 'contact_us',
    label: 'footer_contact_us',
    url: 'https://www.inpi.fr/fr/contactez-nous'
  }
]
