import React, { useState } from 'react'
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux'
import { storeDepositUpdate } from '../../../../store/deposit/depositActions'
import { FormattedMessage, injectIntl } from 'react-intl'
import { CheckboxField, SubmitButton, EventType, CardBlock, ProtectionExtension, DEPOSIT_TYPE } from '@inpi-dm/components'
import OptionsPriorityItemList from './OptionsPriorityItemList'
import PriorityForm from './priorityForm/PriorityForm'
import DepositService from '../../../../services/transaction/DepositService'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'
import ProtectionExtensionForm from '../../../form/ProtectionExtensionForm'

const OptionsFormDeposit = () => {
  const [nbCurrent, setNbCurrent] = useState(-1)
  const dispatch = useDispatch()
  const deposit = useSelector((state : RootStateOrAny) => state.deposit)
  const { options = {}, priorities = [] } = deposit

  /**
   * Enregistrement des données dans le store à la saisie
   * @param event
   */
  const handleChange = (event: EventType) => {
    const { name, value } = event.target
    dispatch(storeDepositUpdate({
      options: {
        ...deposit.options,
        [name]: value
      }
    }))
  }

  /**
   * Modifie la priorité actuellement affichée
   * @param newCurrent
   */
  const changePriorities = (newCurrent : number) => {
    setNbCurrent(newCurrent)
  }

  /**
   * Supprime une priorité
   * @param index
   */
  const deletePriority = (index: number) => {
    const priority = priorities[index]

    if (priority) {
      return DepositService.deleteDepositPriority(deposit.idTransaction, priority.id)
        .then(transaction => {
          return dispatch(storeDepositUpdate({
            priorities: transaction.file.priorities
          }))
        })
    } else {
      return null
    }
  }

  const updateStore = (protectionExtensions: ProtectionExtension[]) => {
    dispatch(storeDepositUpdate({
      options: {
        ...deposit.options,
        protectionExtensions: protectionExtensions
      }
    }))
  }

  return (
    <div>
      <div className='row ml-3'>
        <header className='col-8 align-self-center'>
          <h1><FormattedMessage id='options_depot_form_title' /></h1>
        </header>
        <InternalReferenceField
          value={deposit.internalReference}
          procedureType={DEPOSIT_TYPE.value}
          className='col-4 align-self-center'
        />
      </div>

      <div>
        <CardBlock
          shadow
          className='mt-3'
          bodyClassName='pb-2 pt-3'
          header={<FormattedMessage id='options_depot_form_prorogation_title' />}
        >
          <FormattedMessage id='options_depot_form_prorogation' />
          <div className='mt-3'>
            <CheckboxField
              inputId='prorogation'
              label={<FormattedMessage id='options_depot_form_prorogation_checkbox_label' />}
              labelClassName='font-weight-bold pl-3'
              checked={options.prorogation}
              onChange={handleChange}
            />
          </div>
        </CardBlock>
        <ProtectionExtensionForm updateStore={updateStore} currentProtectionExtensions={options.protectionExtensions || []} />

        <CardBlock
          shadow
          className='mt-3'
          bodyClassName='pb-0 pt-3'
          header={<FormattedMessage id='options_depot_form_priorites_title' />}
        >
          <FormattedMessage id='options_depot_form_priorites_description' />
          {
            nbCurrent > 0 &&
            priorities.map((prio, index) => index < nbCurrent && (
              <OptionsPriorityItemList
                key={index}
                index={index}
                changePriorites={changePriorities}
                deletePriority={deletePriority}
                priority={prio}
              />
            ))
          }

          {
            nbCurrent > -1 &&
              <PriorityForm
                initialPriority={priorities[nbCurrent]}
                nbCurrent={nbCurrent}
                onSavePriority={() => setNbCurrent(-1)}
              />
          }
          {
            priorities.length > nbCurrent &&
            priorities.map((prio, index) => index > nbCurrent && (
              <OptionsPriorityItemList
                key={index}
                index={index}
                changePriorites={changePriorities}
                deletePriority={deletePriority}
                priority={prio}
              />
            ))
          }
          {
            nbCurrent === -1 &&
              <div className='col-4 mb-4 mt-3'>
                <SubmitButton
                  className='btn-block btn-outline-primary'
                  onClick={() => setNbCurrent(priorities.length)}
                >
                  <FormattedMessage id='options_depot_form_priorites_button_add' />
                </SubmitButton>
              </div>
          }
        </CardBlock>
      </div>
    </div>
  )
}

export default injectIntl(OptionsFormDeposit)
