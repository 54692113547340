import { DepositFieldStatus } from '../../interfaces/DepositInterfaces'
import { Deposit } from '@inpi-dm/components'

export const DEPOSIT_UPDATE = 'DEPOSIT_UPDATE'
export const DEPOSIT_REMOVE = 'DEPOSIT_REMOVE'
export const DEPOSIT_FIELD_STATUS_UPDATE = 'DEPOSIT_FIELD_STATUS_UPDATE'
export const DEPOSIT_FIELD_STATUS_RESET = 'DEPOSIT_FIELD_STATUS_RESET'

export interface DepositStoreAction {
  type: string,
  deposit?: Deposit,
}

export interface DepositFieldStatusStoreAction {
  type: string,
  fieldStatus?: DepositFieldStatus,
}
