import http, { DOWNLOAD_FILE } from '../../network/http-common'
import { toast } from 'react-toastify'
import axios from 'axios'
import { DepositDocument, Transaction, TransactionCountByStatus } from '@inpi-dm/components'
import { TransactionSearchParameters, TransactionSearchResult } from '../../interfaces/TransactionInterfaces'
/* global FormData */

class TransactionService {
  constructor () {
    this.source = axios.CancelToken.source()
  }

    /**
     * Récupère un dépôt à partir de son identifiant
     */
    getTransaction = async (id: string): Promise<Transaction> => {
      try {
        return await http.get(`/api/transactions/${id}`)
      } catch (error) {
        toast.error(error.message)
        return Promise.reject(error)
      }
    }

    /**
     * Récupère le nombre de transactions aggrégé
     */
    getTransactionsCountFo = async (type?: string): Promise<TransactionCountByStatus[]> => {
      try {
        return await http.get('/api/transactions/status/count-fo', { params: { type: type } })
      } catch (error) {
        toast.error(error.message)
        return Promise.reject(error)
      }
    }

    /**
     * Récupère une liste de transaction en fonction des paramètre de
     * recherche fourni
     */
    searchTransaction = async (params: TransactionSearchParameters): Promise<TransactionSearchResult> => {
      try {
        return await http.get('/api/transactions', {
          params: params,
          cancelToken: this.source.token
        })
      } catch (error) {
        toast.error(error.message)
        return Promise.reject(error)
      }
    }

    /**
     * Récupération des types de documents définis dans le BO
     * @returns Promise<DocumentType[]>
     */
    getDocumentTypes = async (procedureType?: string): Promise<any> => {
      try {
        return await http.get('/api/document-types', {
          params: { procedure: procedureType },
          cancelToken: this.source.token
        })
      } catch (error) {
        return Promise.resolve([])
      }
    }

    /**
     * Upload un document d'une transaction associé à un type en BDD
     */
    postDocumentFile = async (idTransaction: string,
      type: string,
      file: File,
      srcName: string|undefined = undefined,
      order: number|undefined = undefined,
      communicability: string|undefined = undefined,
      description: string|undefined = undefined,
      additionalType: string|undefined = undefined)
      : Promise<Transaction> => {
      try {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('type', type)
        if (srcName) {
          formData.append('srcName', srcName)
        }
        if (order) {
          formData.append('order', order.toString())
        }
        if (communicability) {
          formData.append('communicability', communicability)
        }
        if (description) {
          formData.append('description', description)
        }
        if (additionalType) {
          formData.append('additionalType', additionalType)
        }
        return await http.post(`/api/transactions/${idTransaction}/documents`, formData)
      } catch (error) {
        toast.error(error.message)
        return Promise.reject(error)
      }
    }

  /**
   * Met à jour les informations d'un document d'une transaction
   */
  updateDocument = async (idTransaction: string, internalName: string, document: DepositDocument): Promise<Transaction> => {
    try {
      return await http.put(`/api/transactions/${idTransaction}/documents/${internalName}`, document)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

    /**
     * Supprime un document d'une transaction associé à un type en BDD
     */
    deleteDocumentFile = async (idTransaction: string, internalName: string): Promise<Transaction> => {
      try {
        return await http.delete(`/api/transactions/${idTransaction}/documents/${internalName}`)
      } catch (error) {
        toast.error(error.message)
        return Promise.reject(error)
      }
    }

    /**
     * Récupère un document d'une transaction
     */
    getDocumentFile = async (idTransaction: string, internalName: string, base64 = false): Promise<Transaction> => {
      try {
        if (base64) {
          return await http.get(`/api/transactions/${idTransaction}/documents/${internalName}?base64=true`)
        } else {
          return await http.get(`/api/transactions/${idTransaction}/documents/${internalName}`, DOWNLOAD_FILE)
        }
      } catch (error) {
        toast.error(error.message)
        return Promise.reject(error)
      }
    }

  /**
   * Suppression d'une transaction
   * @param id
   * @returns Promise<>
   */
  deleteTransaction = async (id: string): Promise<any> => {
    try {
      return await http.delete(`/api/transactions/${id}`)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Télécharge de récapitulatif d'une transaction
   */
  downloadTransactionPDF = async (id: string, indexTitle?: number): Promise<any> => {
    try {
      return await http.get(`/api/transactions/${id}/overview`, {
        responseType: 'arraybuffer',
        params: {
          indexTitle: indexTitle
        }
      })
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

    /**
     * Annule une requête en attente
     */
    cancelRequest = () => {
      this.source.cancel()
      this.source = axios.CancelToken.source()
    }
}

export default new TransactionService()
