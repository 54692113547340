import React, { FC } from 'react'
import { DateUtils, DATE_WITH_HOUR_FR, Transaction } from '@inpi-dm/components'
import { faSpinner, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface TransactionRowProps {
    transaction: Transaction,
    selectTransaction: (transaction: Transaction) => void,
    setShowModalSelectTransaction: (showModalSelectTransaction: boolean) => void,
    classNameText?: string
}

const TransactionRow:FC<TransactionRowProps> = ({
  transaction,
  selectTransaction,
  setShowModalSelectTransaction,
  classNameText
}) => {
  return transaction ? (
    <tr>
      <td className={classNameText}>{transaction.numNat}</td>
      <td className={classNameText}>{DateUtils.formateDateToFormat(transaction.arrivalDate, DATE_WITH_HOUR_FR)}</td>
      <td>
        <FontAwesomeIcon
          className='text-success cursor-pointer mr-1' icon={faCheck} onClick={() => {
            selectTransaction(transaction)
            setShowModalSelectTransaction(false)
          }}
        />
      </td>
    </tr>
  ) : <div><FontAwesomeIcon className='loader mr-1' icon={faSpinner} /></div>
}

export default TransactionRow
