import store from '../../../../../store/store'
import {
  containsErrors,
  DepositDocument,
  DOCUMENT_TYPES,
  FieldStatus,
  isFilled,
  messageIsFilled
} from '@inpi-dm/components'
import { storeAppealFieldStatusUpdate } from '../../../../../store/appeal/appealActions'
import Message from '../../../../../constants/Message'

class DetailsValidator {
  /**
   * Valide une pièce complémentaire
   * @param additionalPart
   * @param keyFile
   * @param keyType
   */
  validateAdditionalPart = (additionalPart: DepositDocument, keyFile: string, keyType: string): FieldStatus => {
    return {
      [keyFile]: additionalPart.file ? '' : Message.required_field,
      [keyType]: messageIsFilled(additionalPart.type)
    }
  }

  validateOnChangeStep = (appealDocuments?: DepositDocument[], additionalParts?: DepositDocument[], acteDocuments?: DepositDocument[]) => {
    const appeal = store.getState().appeal

    let fieldStatus: FieldStatus = {
      appealType: messageIsFilled(appeal.appeal?.appealType)
    }

    // si on a pas remplis la demande de relevé de déchéance
    const allDocuments = [...appeal.documents, ...appealDocuments] || []
    if (!isFilled(appeal.appeal?.appealDecommission) &&
        allDocuments?.filter(
            (document: DepositDocument) => document.type === DOCUMENT_TYPES.DEPOSIT_CORRECTION_SUPPORT && !document.toDelete
        ).length === 0) {
      fieldStatus = {
        ...fieldStatus,
        appealDecommission: Message.appeal_relievedOfForfeiture_error
      }
    }

    // Remplir correctement les documents
    let additionalPartsFieldStatus
    if (additionalParts && additionalParts.length > 0) {
      additionalPartsFieldStatus = additionalParts.map((additionalPart, index) => ({ ...this.validateAdditionalPart(additionalPart, `file-${index}`, `type-${index}`) }))
    }
    let acteDocumentsFieldStatus
    if (acteDocuments && acteDocuments.length > 0) {
      acteDocumentsFieldStatus = acteDocuments.map((acteDocument, index) => ({ ...this.validateAdditionalPart(acteDocument, `file-${index}`, `type-${index}`) }))
    }

    store.dispatch(storeAppealFieldStatusUpdate({
      ...fieldStatus,
      additionalParts: additionalPartsFieldStatus,
      acteDocuments: acteDocumentsFieldStatus
    }))

    const detailsError = containsErrors(fieldStatus)
    const additionalPartsError = containsErrors(additionalPartsFieldStatus)
    const acteDocumentsError = containsErrors(acteDocumentsFieldStatus)

    return !detailsError && !additionalPartsError && !acteDocumentsError
  }
}

export default new DetailsValidator()
