import React, { FC } from 'react'
import {
  Country,
  DepositDocument,
  DepositIntervenant, Transaction,
  ManageableQuality,
  OverviewIntervenant,
  SelectOption
} from '..'
import { FormattedMessage, IntlProvider } from 'react-intl'
import Message from '../constants/Message'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface OverviewComparedIntervenantProps {
    intervenant?: DepositIntervenant | string | null,
    deposit?: Transaction | null,
    type?: SelectOption,
    correctedIntervenant?: DepositIntervenant | string | null,
    correctedDeposit?: Transaction | null,
    countries?: Country[],
    qualities?: ManageableQuality[],
    onScrollTo?: (blockId: string) => void,
    powerFile?: DepositDocument|null,
    proofFile?: DepositDocument|null,
    connectionRequestDisplay?: boolean|null,
    lightDisplay?: boolean|null,
    canBeBeneficiary?: boolean,
    handleIsBeneficiary?: () => void,
    beneficiaries?: DepositIntervenant[]
}

const OverviewComparedIntervenant: FC<OverviewComparedIntervenantProps> = ({
  intervenant,
  deposit,
  type,
  correctedIntervenant,
  correctedDeposit,
  countries = [],
  qualities = [],
  onScrollTo,
  powerFile,
  proofFile,
  connectionRequestDisplay,
  lightDisplay,
  canBeBeneficiary,
  handleIsBeneficiary,
  beneficiaries
}) => {
  const displayedIntervenant = (!intervenant && correctedDeposit)
    ? (
      <span className='font-weight-bold'>
        <FormattedMessage id={`${lightDisplay ? 'sso_account_undefined' : 'intervenant_correction_undefined'}`} />
      </span>
    ) : (
      <OverviewIntervenant
        deposit={deposit}
        intervenant={intervenant}
        type={type}
        countries={countries}
        qualities={qualities}
        onScrollTo={onScrollTo}
        lightDisplay={lightDisplay}
        canBeBeneficiary={canBeBeneficiary}
        handleIsBeneficiary={handleIsBeneficiary}
        beneficiaries={beneficiaries}
      />
    )

  return (
    <IntlProvider messages={Message} locale='fr'>
      {(correctedDeposit && correctedIntervenant !== intervenant)
        ? (
          <div className='row'>
            <div className={`${connectionRequestDisplay ? 'pt-4' : ''} col-12 col-sm-5`}>
              {displayedIntervenant}
            </div>
            <div className='col-1 my-auto d-sm-block'>
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
            <div className={`${connectionRequestDisplay ? 'pt-4' : 'text-primary'} col-12 col-sm-6`}>
              {correctedIntervenant && correctedIntervenant !== intervenant && (
                <OverviewIntervenant
                  deposit={correctedDeposit}
                  intervenant={correctedIntervenant}
                  type={type}
                  onScrollTo={onScrollTo}
                  countries={countries}
                  qualities={qualities}
                  powerFile={powerFile}
                  proofFile={proofFile}
                  connectionRequestDisplay={connectionRequestDisplay}
                  canBeBeneficiary={canBeBeneficiary}
                  handleIsBeneficiary={handleIsBeneficiary}
                  beneficiaries={beneficiaries}
                />
              )}
              {!correctedIntervenant && (
                <span className='font-weight-bold'>
                  <FormattedMessage id='intervenant_correction_deleted' />
                </span>
              )}
            </div>
          </div>
        ) : (
          displayedIntervenant
        )}
    </IntlProvider>
  )
}

export default OverviewComparedIntervenant
