import React, { FC, useEffect, useState } from 'react'
import { containsErrors, DEPOSIT_TYPE, FilAriane, isFilled, ModalComponent, ThumbnailsContext } from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import StepContainer from '../../stepper/StepContainer'
import SelectDepositTypeValidator from './depositType/validator/SelectDepositTypeValidator'
import DepositService from '../../../services/transaction/DepositService'
import SelectDepositType from './depositType/SelectDepositType'
import OptionsFormDeposit from './options/OptionsFormDeposit'
import OptionsFormDepositValidator from './options/validator/OptionsFormDepositValidator'
import ModelsFormDeposit from './models/ModelsFormDeposit'
import ModelsFormDepositValidator from './models/validator/ModelsFormDepositValidator'
import OverviewFormDepositValidator from './overview/validator/OverviewFormDepositValidator'
import OverviewFormDeposit from './overview/OverviewFormDeposit'
import DepositorValidator from './depositor/validator/DepositorValidator'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import ApplicantListForm from '../../intervenant/ApplicantListForm'
import CoDepositorValidator from './codepositor/validator/CoDepositorValidator'
import RecipientValidator from './recipient/validator/RecipientValidator'
import AgentValidator from './agent/validator/AgentValidator'
import CreatorsForm from './creator/CreatorsForm'
import SignatoryValidator from './signatory/validator/SignatoryValidator'
import {
  storeDepositFieldStatusReset,
  storeDepositRemove,
  storeDepositUpdate
} from '../../../store/deposit/depositActions'
import ContentService from '../../../services/content/ContentService'
import PaymentDeposit from './payment/PaymentDeposit'
import TransactionService from '../../../services/transaction/TransactionService'
import RecipientForm from '../../intervenant/RecipientForm'
import AgentProposalForm from '../../intervenant/AgentProposalForm'
import SignatoryForm from '../../intervenant/SignatoryForm'
import DepositorForm from '../../intervenant/DepositorForm'

interface DepositFormProps extends RouteComponentProps<{id: string}> {
  isNew?: boolean
}

const DepositForm: FC<DepositFormProps> = ({
  isNew = false,
  match,
  history
}) => {
  const dispatch = useDispatch()
  const depositFieldStatus = useSelector((state : RootStateOrAny) => state.depositFieldStatus) || []
  const deposit = useSelector((state : RootStateOrAny) => state.deposit)
  const [showRgpd, setShowRgpd] = useState(false)
  const [rgpdContent, setRdpdContent] = useState('')
  const [needConfirmation, setNeedConfirmation] = useState(false)
  const [isNewDeposit, setIsNewDeposit] = useState(isNew)
  const [thumbnailsImages, setThumbnailsImages] = useState({})

  // Initialisation du dépôt à modifier
  useEffect(() => {
    dispatch(storeDepositFieldStatusReset())
    dispatch(storeDepositRemove())
    if (!isNew) {
      TransactionService.getTransaction(match.params.id).then(result => {
        dispatch(storeDepositUpdate({
          ...result.file,
          idTransaction: result.id,
          documents: result.documents
        }))
      })
    } else {
      ContentService.getEditorialBlockByCode('RGPD-DEP').then((res) => {
        if (res.content) {
          setRdpdContent(res.content)
          setShowRgpd(true)
        }
      })
    }
  }, [isNew])

  const saveDepositType = () => {
    if (isNewDeposit) {
      setIsNewDeposit(false)
      return DepositService.createDeposit().then(() => DepositService.updateDepositorBDDFromStore('depositType'))
    } else {
      return DepositService.updateDepositorBDDFromStore('depositType')
    }
  }

  const handleSubmit = () => {
    // Si la dernière étape est valide, on submit le formulaire
    history.push('/')
  }

  /** Vérifie que tous les champs obligatoires sont remplis pour une étape du menu**/
  const checkValidity = (stepName: string): boolean => {
    const status = depositFieldStatus[stepName]
    if (status === undefined) {
      return false
    }
    return !containsErrors(status)
  }

  return (
    <ThumbnailsContext.Provider value={{ thumbnailsImages, setThumbnailsImages }}>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id='breadcrumb_new_depot' /></span>
      </FilAriane>

      <StepContainer
        className='mb-5 is-validated'
        listTitle={<FormattedMessage id='depot_step_list_title' />}
        onCancel={() => history.push('/')}
        onSubmit={handleSubmit}
        views={[
          {
            id: 'type_depot',
            label: <FormattedMessage id='select_depot_type_title' />,
            badge: '1',
            validateGoToStep: SelectDepositTypeValidator.validateOnChangeStep,
            onGoToStep: saveDepositType,
            component: <SelectDepositType />,
            isValidated: checkValidity('depositType')
          }, {
            id: 'intervenant',
            label: <FormattedMessage id='intervenant_form_title' />,
            badge: '2',
            isValidated: checkValidity('signatory')
          }, {
            id: 'intervenant_deposant',
            label: <FormattedMessage id='intervenant_onglet_deposant' />,
            level: 1,
            validateGoToStep: DepositorValidator.validateOnChangeStep,
            onGoToStep: () => DepositService.updateDepositorBDDFromStore('depositor'),
            component: (
              <DepositorForm
                object={deposit}
                procedureType={DEPOSIT_TYPE.value}
                fieldStatus={depositFieldStatus.depositor}
              />)
          }, {
            id: 'intervenant_co_deposant',
            label: <FormattedMessage id='intervenant_onglet_co_deposant' />,
            level: 1,
            validateGoToStep: CoDepositorValidator.validateOnChangeStep,
            onGoToStep: () => DepositService.updateDepositorBDDFromStore('coDepositors'),
            component: (
              <ApplicantListForm
                object={deposit}
                procedureType={DEPOSIT_TYPE.value}
                applicantList={deposit.coDepositors || []}
                fieldStatus={depositFieldStatus.coDepositors || []}
              />
            ),
            stepButtonsAsChildren: true
          }, {
            id: 'intervenant_destinataire',
            label: <FormattedMessage id='intervenant_onglet_destinataire' />,
            level: 1,
            validateGoToStep: RecipientValidator.validateOnChangeStep,
            onGoToStep: () => DepositService.updateDepositorBDDFromStore('recipient'),
            component: (
              <RecipientForm
                object={deposit}
                fieldStatus={depositFieldStatus}
                procedureType={DEPOSIT_TYPE.value}
                findIntervenantsList={['depositor', 'coDepositors']}
              />
            )
          }, {
            id: 'intervenant_mandataire',
            label: <FormattedMessage id='intervenant_onglet_mandataire' />,
            level: 1,
            validateGoToStep: AgentValidator.validateOnChangeStep,
            onGoToStep: () => DepositService.updateDepositorBDDFromStore('agent'),
            component: (
              <AgentProposalForm
                object={deposit}
                fieldStatus={depositFieldStatus}
                procedureType={DEPOSIT_TYPE.value}
                findIntervenantsList={['depositor', 'coDepositors', 'recipient']}
                viewFormInit={isFilled(deposit.coDepositors)}
                idTransaction={deposit.idTransaction}
                isDeletable={!isFilled(deposit.coDepositors)}
              />
            )
          }, {
            id: 'intervenant_creators',
            label: <FormattedMessage id='intervenant_onglet_createur' />,
            level: 1,
            onGoToStep: () => DepositService.updateDepositorBDDFromStore('creators'),
            component: <CreatorsForm />
          }, {
            id: 'intervenant_signatory',
            label: <FormattedMessage id='intervenant_onglet_signatory' />,
            level: 1,
            validateGoToStep: SignatoryValidator.validateOnChangeStep,
            onGoToStep: () => DepositService.updateDepositorBDDFromStore('signatory'),
            component: <SignatoryForm object={deposit} fieldStatus={depositFieldStatus} procedureType={DEPOSIT_TYPE.value} />
          }, {
            id: 'options',
            label: <FormattedMessage id='options_depot_form_title' />,
            badge: '3',
            validateGoToStep: OptionsFormDepositValidator.validateOnChangeStep,
            onGoToStep: () => DepositService.updateDepositorBDDFromStore('options'),
            component: <OptionsFormDeposit />,
            isValidated: checkValidity('priorities')
          }, {
            id: 'models',
            label: <FormattedMessage id='models_deposit_form_title' />,
            badge: '4',
            validateGoToStep: ModelsFormDepositValidator.validateOnChangeStep,
            onGoToStep: () => DepositService.updateDepositorBDDFromStore(''),
            textModalBeforeNextStep: needConfirmation ? (<FormattedMessage id='modification_discontinuation_popin_content' />) : undefined,
            component: <ModelsFormDeposit setNeedConfirmation={setNeedConfirmation} />,
            isValidated: checkValidity('models')
          }, {
            id: 'overview',
            label: <FormattedMessage id='overview_deposit_form_short_title' />,
            badge: '5',
            validateGoToStep: OverviewFormDepositValidator.validateOnChangeStep,
            component: <OverviewFormDeposit />,
            isValidated: checkValidity('overviewSimilarInformation')
          }, {
            id: 'payment',
            label: <FormattedMessage id='payment' />,
            badge: '6',
            component: <PaymentDeposit deposit={deposit} />,
            stepButtonsAsChildren: true
          }
        ]}
      />
      <ModalComponent
        title={<FormattedMessage id='rgpd_title' />}
        isNotCancellable
        customContent={() => <div dangerouslySetInnerHTML={{ __html: rgpdContent }} />}
        handleClose={() => setShowRgpd(false)}
        show={showRgpd}
        onClick={() => setShowRgpd(false)}
        customButtonId='common_start_form'
        size='xl'
      />
    </ThumbnailsContext.Provider>
  )
}

export default withRouter(DepositForm)
