import http from '../../network/http-common'
import { LocarnoProduct, LocarnoVersion } from '@inpi-dm/components'
import { toast } from 'react-toastify'

class LocarnoService {
    /**
     * Recherche une liste de produit par leur intitulé ou la référence de leur sous-classe.
     *
     * @param text Début d'intitulé des produits
     * @param refCategory Référence de leur sous-classe
     */
    searchProducts = async (text?: string, refCategory?: string): Promise<LocarnoProduct[]> => {
      try {
        const params = { text, refCategory }
        return await http.get('/api/locarno/products/search', { params })
      } catch (error) {
        toast.error(error.message)
        return Promise.reject(error)
      }
    }

    /**
     * Recupere les categories de locarno
     */
    getClassification = async () : Promise<LocarnoVersion> => {
      try {
        return await http.get('/api/locarno/categories/versions/last')
      } catch (error) {
        toast.error(error.message)
        return Promise.reject(error)
      }
    }
}

export default new LocarnoService()
