import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  DEPOSIT_TYPE,
  INSCRIPTION_TYPE,
  PROROGATION_TYPE,
  APPEAL_TYPE,
  OFFICIAL_DOCUMENTS_TYPE
} from '@inpi-dm/components'

const HeaderIntervenant = ({ procedureType = '' }) => {
  return (
    <header className='col-8 mb-4'>
      <h1><FormattedMessage id='intervenant_form_title' /></h1>
      {
        procedureType === DEPOSIT_TYPE.value &&
          <span className='subtitle'>
            <FormattedMessage
              id='intervenant_form_description'
              values={{ a: (...chunks) => (<a href={process.env.REACT_APP_URL_HELP_CONTRIBUTOR} target='_blank' rel='noopener noreferrer'>{chunks}</a>) }}
            />
          </span>
      }
      {
        (procedureType === INSCRIPTION_TYPE.value || procedureType === APPEAL_TYPE.value) &&
          <span className='subtitle'>
            <FormattedMessage id='intervenant_form_description_inscription_appeal' />
          </span>
      }
      {
        procedureType === PROROGATION_TYPE.value &&
          <span className='subtitle'>
            <FormattedMessage id='intervenant_form_description_prorogation' />
          </span>
      }
      {
        procedureType === OFFICIAL_DOCUMENTS_TYPE.value &&
          <span className='subtitle'>
            <FormattedMessage id='intervenant_form_description_official_document' />
          </span>
      }
    </header>
  )
}

export default HeaderIntervenant
