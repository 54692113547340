import React from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { DEPOSIT_TYPE, EventType, RenderCreatorForm } from '@inpi-dm/components'
import { storeDepositUpdate } from '../../../../store/deposit/depositActions'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'
import HeaderIntervenant from '../../../intervenant/HeaderIntervenant'

const CreatorsForm = (props: WrappedComponentProps) => {
  const dispatch = useDispatch()
  const deposit = useSelector((state : RootStateOrAny) => state.deposit)
  const fieldStatus = useSelector((state : RootStateOrAny) => state.depositFieldStatus)

  /**
   * Enregistrement des doinnées dans le store à la saisie
   * @param event
   */
  const handleChange = (event: EventType) => {
    const { name, value } = event.target
    dispatch(storeDepositUpdate({
      [name]: value
    }))
  }

  return (
    <div className='form-intervenant is-validated'>
      <div className='row'>
        <HeaderIntervenant procedureType={DEPOSIT_TYPE.value} />
        <InternalReferenceField
          value={deposit.internalReference}
          procedureType={DEPOSIT_TYPE.value}
          className='col-4 mb-4'
        />
      </div>
      <RenderCreatorForm
        handleChange={handleChange}
        fieldStatus={fieldStatus}
        depositCreators={deposit.creators}
        intl={props.intl}
        foDisplay
      />
    </div>
  )
}

export default injectIntl(CreatorsForm)
