import React, { FC, useState, useEffect } from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import {
  CardBlock,
  DepositDocument,
  DOCUMENT_TYPES, ErrorField,
  EventType, FieldStatus,
  FileBrowserField,
  SelectField, SelectOption,
  TextArea,
  Transaction
} from '@inpi-dm/components'
import { APPLICATION_PDF } from '../../../requests/DocumentBlock'
import AppealService from '../../../../services/transaction/AppealService'

interface AppealDecommissionProps extends WrappedComponentProps {
  transaction: Transaction,
  handleChanges: (event: EventType) => void,
  deleteDocument: (event: EventType, index: number) => void,
  addDocument: (event: EventType) => void
  fieldStatus: FieldStatus,
  appealDocuments: DepositDocument[]
}

const AppealDecommission : FC<AppealDecommissionProps> = ({
  transaction,
  handleChanges,
  deleteDocument,
  addDocument,
  fieldStatus,
  appealDocuments,
  intl
}) => {
  const [appealTypes, setAppealTypes] = useState<SelectOption[]>([])

  useEffect(() => {
    AppealService.getAppealTypes().then((appealTypes: {label: string, code: string}[]|null) => {
      const newAppealTypes = appealTypes?.map(type => ({ value: type.code, label: type.label })) || []
      setAppealTypes(newAppealTypes)
    })
  }, [])

  return (
    <CardBlock
      shadow
      header={<FormattedMessage id='appeal_details' />}
    >
      <div className='w-50 p-0'>
        <SelectField
          options={appealTypes}
          inputId='appealType'
          label={<FormattedMessage id='appeal_type_label' />}
          value={transaction.appeal?.appealType || ''}
          onChange={handleChanges}
          fieldStatus={fieldStatus || {}}
          required
          placeholder={intl.formatMessage({ id: 'select_placeholder' })}
        />
      </div>
      <span className='subtitle text-gris'><FormattedMessage id='appeal_relievedOfForfeiture_info' /></span>
      <div>
        <TextArea
          inputId='appealDecommission'
          label={<FormattedMessage id='appeal_relievedOfForfeiture_label' />}
          value={transaction.appeal?.appealDecommission || ''}
          onChange={handleChanges}
        />
      </div>
      <div className='col-12 col-md-6 p-0'>
        <FileBrowserField
          label={<FormattedMessage id='appeal_relievedOfForfeiture_document_label' />}
          inputId='file'
          acceptApplication={APPLICATION_PDF}
          butonLabel={
            <div className='form-control placeholder'>
              <FormattedMessage id='button_find_file' />
            </div>
          }
          value={[...transaction.documents?.filter((document: DepositDocument) => document.type === DOCUMENT_TYPES.DEPOSIT_CORRECTION_SUPPORT && !document.toDelete), ...appealDocuments] || []}
          onDelete={deleteDocument}
          onChange={addDocument}
        />
      </div>
      {fieldStatus && fieldStatus.appealDecommission &&
        <div><ErrorField message={fieldStatus.appealDecommission} /></div>}
    </CardBlock>
  )
}

export default injectIntl(AppealDecommission)
