import store from '../../../../../store/store'
import { containsErrors, IntervenantValidator } from '@inpi-dm/components'
import { storeProrogationFieldStatusUpdate } from '../../../../../store/prorogation/prorogationActions'

class CoDepositorValidator {
  validateOnChangeStep = () => {
    const prorogation = store.getState().prorogation
    const coDepositors = prorogation.coDepositors || []
    let hasNoError = true

    const allFieldStatus = coDepositors.map(coDepositor => {
      const fieldStatus = IntervenantValidator.validateIntervenant(coDepositor)
      hasNoError = hasNoError && !containsErrors(fieldStatus)
      return fieldStatus
    })

    store.dispatch(storeProrogationFieldStatusUpdate({
      coDepositors: allFieldStatus
    }))

    return hasNoError
  }
}

export default new CoDepositorValidator()
