import { isFilled, CPI, LIST_422_5, SOCIETE_CONTRACTUELLE } from '@inpi-dm/components'
import Message from '../../../constants/Message'
import { ACCESS_DEMAND_ITEM_ADD_AGENT } from '../../../constants/ETSAccountConstants'

import { ETSAccountNavInterface, ETSAccountRequest } from '../../../interfaces/ETSAccountInterface'

class ETSAccountRequestValidator {
    /**
     * Valide le formulaire de demande de modification sur les comptes ETS à partir de l'action passée
     * @param ETSAccountRequest
     * @param ETSAccountRequestNavItem
     */
    validateForm = (etsAccountRequest: ETSAccountRequest, ETSAccountRequestNavItem: ETSAccountNavInterface) => {
      const fieldStatus: ETSAccountRequest = {}
      if (!isFilled(etsAccountRequest.procedure)) {
        fieldStatus.procedure = Message.required_field
      }
      if (!isFilled(etsAccountRequest.numNat)) {
        fieldStatus.numNat = Message.required_field
      }
      if (ETSAccountRequestNavItem === ACCESS_DEMAND_ITEM_ADD_AGENT) {
        this.valideAgentChange(etsAccountRequest, fieldStatus)
      }
      return fieldStatus
    }

    valideAgentChange = (etsAccountRequest: ETSAccountRequest, fieldStatus: ETSAccountRequest) => {
      const manageableQuality = etsAccountRequest.manageableQuality
      if (!isFilled(manageableQuality)) {
        fieldStatus.manageableQuality = Message.required_field
      }
      switch (manageableQuality) {
        case CPI:
          if (!isFilled(etsAccountRequest.inscriptionNumber)) {
            fieldStatus.inscriptionNumber = Message.required_field
          }
          break
        case LIST_422_5:
          if (!isFilled(etsAccountRequest.inscriptionNumber)) {
            fieldStatus.inscriptionNumber = Message.required_field
          }
          if (!etsAccountRequest.powerFile) {
            fieldStatus.powerFile = Message.required_field
          }
          break
        case SOCIETE_CONTRACTUELLE:
          if (!etsAccountRequest.powerFile) {
            fieldStatus.powerFile = Message.required_field
          }
          break
        default:
          break
      }
    }
}

export default new ETSAccountRequestValidator()
