import React, { FC } from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router'
import { REQUEST_TYPES } from '../../constants/RequestConstants'
import RequestList from './RequestList'
import OneRequest from './OneRequest'
import OneInscription from './OneInscription'
import OneProrogation from './OneProrogation'
import OneAppeal from './OneAppeal'
import OneOfficialDocument from './OneOfficialDocument'

const RequestRoutes: FC<RouteComponentProps> = ({
  match
}) => (
  <Switch>
    {REQUEST_TYPES.map(type => (
      <Route
        key={type.id}
        path={`${match.url}/${type.urlParam}/demande/:id`}
        render={(props) => <OneRequest type={type} id={props.match.params.id} />}
      />
    ))}
    {REQUEST_TYPES.map(type => (
      <Route
        key={`${type.id}-inscription`}
        path={`${match.url}/${type.urlParam}/inscription/:id`}
        render={(props) => <OneInscription type={type} id={props.match.params.id} />}
      />
    ))}
    {REQUEST_TYPES.map(type => (
      <Route
        key={`${type.id}-prorogation`}
        path={`${match.url}/${type.urlParam}/prorogation/:id`}
        render={(props) => <OneProrogation type={type} id={props.match.params.id} />}
      />
    ))}
    {REQUEST_TYPES.map(type => (
      <Route
        key={`${type.id}-appeal`}
        path={`${match.url}/${type.urlParam}/releves-de-decheance/:id`}
        render={(props) => <OneAppeal type={type} id={props.match.params.id} />}
      />
    ))}
    {REQUEST_TYPES.map(type => (
      <Route
        key={`${type.id}-official-document`}
        path={`${match.url}/${type.urlParam}/documents-officiels/:id`}
        render={(props) => <OneOfficialDocument type={type} id={props.match.params.id} />}
      />
    ))}
    {REQUEST_TYPES.map(type => (
      <Route
        key={type.id}
        path={`${match.url}/${type.urlParam}`}
        render={() => <RequestList type={type} />}
      />
    ))}
  </Switch>
)

export default RequestRoutes
