import React, { FC, useState } from 'react'
import {
  DATE_ISO,
  DateUtils,
  DEPOSIT_TYPE_DEPOT_CLASSIQUE,
  Transaction,
  ModalComponent,
  ModelDeposit,
  ReproductionLightDeposit,
  STATUS_DEPOSIT,
  STATUS_WITHDRAWN,
  SubmitButton
} from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'
import DepositService from '../../../../../services/transaction/DepositService'
import { toast } from 'react-toastify'
import Message from '../../../../../constants/Message'
import { REQUEST_STATUS_REVIEWING } from '../../../../../constants/RequestConstants'
import NotificationService from '../../../../../services/transaction/NotificationService'
import {
  NOTIF_ANSWERED_TYPE_WITHDRAW
} from '@inpi-dm/components/src/constants/NotificationsConstants'

interface WithdrawReproductionActionProps {
    transaction: Transaction,
    models: ModelDeposit[],
    className?: string,
    onUpdateDeposit?: () => any,
}

const WithdrawReproductionAction: FC<WithdrawReproductionActionProps> = ({
  transaction,
  models,
  className = '',
  onUpdateDeposit
}) => {
  const [showModal, setShowModal] = useState(false)

  /**
     * Met a jours les statuts des reproductions
     */
  const updateReproduction = async () : Promise<ReproductionLightDeposit[]> => {
    const dateWithdraw = DateUtils.formateDateToFormat(new Date(), DATE_ISO)
    let withdrawnReproductions: ReproductionLightDeposit[] = []
    for (const model of models) {
      const filteredReproductions = model.reproductions.filter(reproduction => reproduction.selected && reproduction.state !== STATUS_WITHDRAWN)
      withdrawnReproductions = withdrawnReproductions.concat(filteredReproductions)
      for (const reproduction of filteredReproductions) {
        await DepositService.updateReproduction(
          transaction.id,
          model.id,
          reproduction.file.internalName,
          reproduction.position,
          {
            ...reproduction,
            state: STATUS_WITHDRAWN,
            dateState: dateWithdraw
          }
        )
      }
    }
    return Promise.resolve(withdrawnReproductions)
  }

  /**
     * Enregistre les reproductions à retirées de la transaction
     */
  const saveWithdrawReproductions = () => {
    return updateReproduction().then(withdrawnReproductions => {
      return NotificationService.postAnsweredNotification(transaction.id, NOTIF_ANSWERED_TYPE_WITHDRAW, withdrawnReproductions).then(() => {
        toast.success(Message.withdraw_reproduction_success)
        setShowModal(false)
        onUpdateDeposit && onUpdateDeposit()
      })
    })
  }

  /**
     * Retourne true si au moins une reproduction retirable a été sélectionnée.
     */
  const hasSelectedWithdrawableReproductions = () => {
    return models.length > 0 &&
            models.find(model =>
              model.reproductions.find(reproduction =>
                reproduction.selected && reproduction.state !== STATUS_WITHDRAWN)
            )
  }

  return (
    <>
      {(
      // Transaction en cours d'examen
        REQUEST_STATUS_REVIEWING.includes(transaction.status || '') ||
                // Transaction en attente de publication pour les dossier ajournés ou simplifié
                (
                  (transaction?.status === STATUS_DEPOSIT.ADJOURNED_PENDING || transaction?.status === STATUS_DEPOSIT.SIMPLIFIED_PENDING) &&
                    transaction.file.depositType !== DEPOSIT_TYPE_DEPOT_CLASSIQUE
                )
      ) && (
        <div className={className}>
          <SubmitButton
            className='btn-block btn-outline-primary'
            disabled={!hasSelectedWithdrawableReproductions()}
            onClick={() => setShowModal(true)}
          >
            <FormattedMessage id='withdraw_reproduction_button_delete' />
          </SubmitButton>
        </div>
      )}
      <ModalComponent
        title={<FormattedMessage id='withdraw_reproduction_modal_title' />}
        show={showModal}
        customContent={() => (
          <>
            <FormattedMessage id='withdraw_reproduction_warning' />
            <div className='d-flex justify-content-around mt-4'>
              <SubmitButton className='btn-primary' onClick={saveWithdrawReproductions}>
                <FormattedMessage id='withdraw_reproduction_button' />
              </SubmitButton>
            </div>
          </>
        )}
        handleClose={() => setShowModal(false)}
        hideFooter
      />

    </>
  )
}

export default WithdrawReproductionAction
