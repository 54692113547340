import { DepositDocument, Transaction, CONFORMITY_NOT_COMPLIANT } from '@inpi-dm/components'
import CorrectionService from './CorrectionService'
import DocumentUtils from '@inpi-dm/components/src/utils/DocumentUtils'

class DocumentService {
    /**
     * Récupère tous les documents d'un dépot
     *
     * @param deposit
     */
    getDocumentsFromDeposit = (deposit: Transaction): DepositDocument[] => {
      // Documents généraux au dossier
      const documents = deposit.documents || []

      // Documents des priorités
      const prioritiesDoc = deposit.file?.priorities?.map(priority => {
        // Affichage des documents de priorité que si elle n'a pas été déclarée comme non conforme
        if (priority.status !== CONFORMITY_NOT_COMPLIANT) {
          const priorityDocuments = priority.documents?.map(doc => ({
            ...doc,
            idPriority: priority.id
          }))
          return priorityDocuments || []
        }

        return []
      }).reduce((a, b) => a.concat(b), []) || []

      // Documents des notiifcations
      const notificationDoc = deposit.notifications?.map(notification => {
        const notificationDocuments = notification.supportingDocuments?.map(doc => ({
          ...doc,
          idNotification: notification.id
        }))
        return notificationDocuments || []
      }).reduce((a, b) => a.concat(b), []) || []

      // Documents présents dans la demande de correction à valider
      const correction = CorrectionService.findCorrectionInReview(deposit)
      const correctionDoc = correction?.documents?.map(doc => ({
        ...doc,
        idCorrection: correction.id
      })) || []

      // Documents des notifications
      const ETSAccountDocuments = deposit.ETSAccounts?.map(ETSAccount => {
        const ETSAccountDocuments = ETSAccount.documents?.map(doc => ({
          ...doc,
          idETSAccountDocument: ETSAccount.id
        }))
        return ETSAccountDocuments || []
      }).reduce((a, b) => a.concat(b), []) || []

      return DocumentUtils.removeDuplicateDocument([...correctionDoc, ...documents, ...prioritiesDoc, ...notificationDoc, ...ETSAccountDocuments])
    }
}

export default new DocumentService()
