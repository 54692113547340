import { Deposit, isFilled, STATUS_REJECTED, STATUS_WITHDRAWN } from '@inpi-dm/components'
import { Payment } from '../../interfaces/DepositInterfaces'
import http from '../../network/http-common'
import { toast } from 'react-toastify'

class DivisionService {
  /**
   * Sépare les modèles de différentes classes d'un dépôt dans des dépots différents.
   *
   * @param deposit
   */
  splitModelsInDeposits = (deposit?: Deposit) => {
    if (!deposit) {
      return []
    }

    const { models, options, priorities, depositType } = deposit
    const splittedModels = {}

    if (models) {
      models.forEach(model => {
        const refCategory = model.modelDesignation.ref?.substring(0, 2) || '_'
        const leftReproductions = model.reproductions.filter(repro => repro.state !== STATUS_WITHDRAWN && repro.state !== STATUS_REJECTED)

        if (isFilled(leftReproductions)) {
          const newModel = {
            ...model,
            reproductions: leftReproductions
          }

          if (splittedModels[refCategory]) {
            // Ajout d'un modèle dans une liste déjà existante
            splittedModels[refCategory].push(newModel)
          } else {
            // Ajout d'une nouvelle liste de modèles appartenant à la même classe
            splittedModels[refCategory] = [newModel]
          }
        }
      })

      // Object.values(splittedModels) casse l'ordre des positions des modèles
      // On doit trier les listes de modèles selon les positions de ces modèles
      const orderedModels = Object.values(splittedModels)
        .sort((ml1, ml2) => ml1[0].position - ml2[0].position)

      return orderedModels.map(childModels => ({
        depositType,
        options: {
          prorogation: !!options?.prorogation,
          protectionExtensions: options?.protectionExtensions && [...options?.protectionExtensions]
        },
        priorities: priorities && [...priorities],
        models: childModels
      }))
    } else {
      return []
    }
  }

  /**
   * Retourne le prix simulé d'une division de dépôt
   *
   * @param idTransaction Identifiant de la transaction divisée
   * @param withdrawnReproductions Noms internes des reproductions retirées
   */
  getSimulatedPrices = async (idTransaction: string, withdrawnReproductions: string[]): Promise<Payment> => {
    try {
      return await http.get(`/api/transactions/${idTransaction}/division/paiement`, {
        params: { internalNames: withdrawnReproductions }
      })
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }
}

export default new DivisionService()
