export const MAIL_MODEL_IRREGULARITY_CODE = 'IRREGULARITE'
export const MAIL_MODEL_PROPOSAL_DIVISION_CODE = 'DIVISION'
export const MAIL_MODEL_TOTAL_REJECT_CODE = 'REJET_TOTAL'
export const MAIL_MODEL_DECOMMISSIONNED_PARTIAL = 'DECHEANCE_PARTIELLE'
export const MAIL_MODEL_DECOMMISSIONNED_TOTAL = 'DECHEANCE_TOTALE'
export const MAIL_MODEL_REJECT_PARTIAL = 'REJET_PARTIEL'

export const MAIL_MODELS = {
  MAIL_MODEL_IRREGULARITY_CODE,
  MAIL_MODEL_PROPOSAL_DIVISION_CODE,
  MAIL_MODEL_TOTAL_REJECT_CODE,
  MAIL_MODEL_DECOMMISSIONNED_PARTIAL,
  MAIL_MODEL_DECOMMISSIONNED_TOTAL,
  MAIL_MODEL_REJECT_PARTIAL
}
