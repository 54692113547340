import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { SubmitButton, Country } from '@inpi-dm/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import ContentService from '../../../../services/content/ContentService'
import { DepositPriorityOptions } from '../../../../interfaces/DepositInterfaces'

interface OptionsPriorityItemListProps extends WrappedComponentProps {
  index: number,
  changePriorites: (index: number) => void,
  deletePriority: (index: number) => void,
  priority: DepositPriorityOptions
}

const OptionsPriorityItemList: FC<OptionsPriorityItemListProps> = ({
  index,
  changePriorites,
  deletePriority,
  priority,
  intl
}) => {
  const [country, setCountry] = useState<Country|undefined>()

  useEffect(() => {
    ContentService.getCountries()
      .then(result => result.find(country => country.code === priority.countryOrOrganization))
      .then(setCountry)
  }, [])

  return (
    <div className='row header-field'>
      <div className='col-10 d-flex flex-row align-items-center'>
        <FormattedMessage id='options_depot_form_priorites' />
        {index + 1}
        {' - '}
        {country ? `${country.label} - ` : ''}
        {priority.originalDepositNumber}
      </div>
      <div className='col-2 d-flex flex-row justify-content-end align-items-center'>
        <SubmitButton
          className='btn-icon-primary mr-3'
          onClick={() => changePriorites(index)}
        >
          <FontAwesomeIcon icon={faEdit} title={intl.formatMessage({ id: 'options_depot_form_priorites_button_change' })} />
        </SubmitButton>
        <SubmitButton
          className='btn-icon-danger mr-3'
          onClick={() => deletePriority(index)}
        >
          <FontAwesomeIcon icon={faTrashAlt} title={intl.formatMessage({ id: 'options_depot_form_priorites_button_delete' })} />
        </SubmitButton>
      </div>
    </div>
  )
}

export default injectIntl(OptionsPriorityItemList)
