import React, { FC, useState } from 'react'
import { Payment, PaymentParameters } from '../../../interfaces/DepositInterfaces'
import { FormattedMessage } from 'react-intl'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { CardBlock, Deposit, ModalComponent, PersonneFieldValidator, SubmitButton } from '@inpi-dm/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import OverviewRoyalties from '../../payment/OverviewRoyalties'
import SelectPaymentBlocks from '../../payment/SelectPaymentBlocks'

interface DivisionAnswerProps {
    deposit: Deposit,
    onAccept?: () => (Promise<void>|void),
    priceInfo?: Payment,
    onPay: (payment: PaymentParameters) => (Promise<void>|void),
    disableAcceptButton?: boolean
}

const DivisionAnswer: FC<DivisionAnswerProps> = ({
  onAccept,
  priceInfo,
  deposit,
  onPay,
  disableAcceptButton = false
}) => {
  const [showPaymentModal, setShowPaymentModal] = useState(false)

  const canAccessManageableMemory = deposit.depositor &&
        PersonneFieldValidator.isPersonneMorale(deposit.depositor) &&
        deposit.depositor.publicLaw

  return (
    <CardBlock className='h-auto mb-3'>
      <div className='d-flex justify-content-center'>
        {priceInfo && priceInfo.totalAmount > 0 ? (
          <SubmitButton
            className='btn-primary'
            onClick={() => setShowPaymentModal(true)}
            disabled={disableAcceptButton}
          >
            <FormattedMessage id='request_division_btn_accept_pay' />
            <FontAwesomeIcon className='ml-2' icon={faCreditCard} />
          </SubmitButton>
        ) : (
          <SubmitButton
            className='btn-primary'
            onClick={onAccept}
            disabled={disableAcceptButton}
          >
            <FormattedMessage id='request_division_btn_accept' />
          </SubmitButton>
        )}
      </div>

      <ModalComponent
        size='xl'
        handleClose={() => setShowPaymentModal(false)}
        title={<FormattedMessage id='request_division_modal_pay_title' />}
        show={showPaymentModal}
        hideFooter
        customContent={() =>
          <div className='payment-form'>
            <OverviewRoyalties infoPrice={priceInfo} className='mb-3' bodyClassName='p-3' />
            <SelectPaymentBlocks
              onStartPayment={onPay}
              manageableMemoryAccessible={canAccessManageableMemory}
              object={deposit}
            />
          </div>}
      />
    </CardBlock>
  )
}

export default DivisionAnswer
