import React, { FC } from 'react'
import { FormattedMessage, IntlProvider, WrappedComponentProps } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import {
  AutocompleteResult,
  Country,
  DepositIntervenant,
  isFilled,
  ManageableQuality,
  OverviewIntervenant, SelectButton
} from '..'
import Message from '../constants/Message'
import { Button } from 'react-bootstrap'

interface CoDepositorOrApplicantFormListProps extends WrappedComponentProps {
    depositCoDepositors: DepositIntervenant[],
    editedIndex?: number
    setEditedIndex: (index: number) => void,
    handleDeleteCoDepositor: (index: number) => void,
    foDisplay?: boolean | null,
    boDisplay?: boolean | null,
    handleCoDepositorForm?: (codepositors: DepositIntervenant[]) => void | null,
    children?: any,
    countries: Country[],
    qualities: ManageableQuality[],
    intervenantType: string,
    intervenants?: AutocompleteResult<DepositIntervenant>[] | null,
    handleSelectIntervenant?: (intervenant: DepositIntervenant, editedIndex?: number) => void
}

const RenderCoDepositorOrApplicantListForm: FC<CoDepositorOrApplicantFormListProps> = ({
  depositCoDepositors,
  editedIndex,
  setEditedIndex,
  handleDeleteCoDepositor,
  boDisplay,
  foDisplay,
  handleCoDepositorForm,
  children,
  countries,
  qualities,
  intervenantType,
  intervenants,
  handleSelectIntervenant
}) => {
  return (
    <IntlProvider locale='fr' messages={Message}>
      <div className='form-header row mx-0'>
        <h2 className={boDisplay && !foDisplay ? 'h4' : ''}>
          <FormattedMessage id={`intervenant_onglet_${intervenantType}`} />
        </h2>
        {editedIndex && editedIndex !== -1 && handleSelectIntervenant && (
          <div className='col options px-0 my-auto'>
            {
              isFilled(intervenants) && (
                <SelectButton
                  className='btn btn-link text-primary'
                  options={intervenants}
                  onSelect={handleSelectIntervenant}
                >
                  <FormattedMessage id='intervenant_copier_donnees' />
                </SelectButton>
              )
            }
          </div>
        )}
      </div>
      {depositCoDepositors && depositCoDepositors.length > 0 && (
        <ul className='list-unstyled'>
          {depositCoDepositors.map((depositCoDepositor, index) => (
            <li key={index} className='row my-2'>
              <div className='col-8'>
                <OverviewIntervenant
                  intervenant={depositCoDepositor}
                  countries={countries}
                  qualities={qualities}
                />
              </div>
              <div className='col'>
                <button className='btn btn-link text-primary' onClick={() => setEditedIndex(index)}>
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                <button className='btn btn-link text-danger ml-3' onClick={() => handleDeleteCoDepositor(index)}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
      <div className='codepositor-bottom-button'>
        {children}
      </div>
      {
        boDisplay && depositCoDepositors && depositCoDepositors.length !== undefined && depositCoDepositors.length !== 0 &&
          <div className='d-flex justify-content-around mt-2'>
            <Button className='primary' onClick={() => handleCoDepositorForm && handleCoDepositorForm(depositCoDepositors)}><FormattedMessage id='common_validate' />
            </Button>
          </div>
      }
    </IntlProvider>
  )
}

export default RenderCoDepositorOrApplicantListForm
