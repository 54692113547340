import React, { FC } from 'react'
import { EventType, FieldStatus, SelectField, TextField } from '@inpi-dm/components'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { CIVILITES, DepositIntervenant } from '../..'

interface PersonnePhysiqueFieldsProps extends WrappedComponentProps{
    intervenant?: DepositIntervenant,
    onChange: (event: EventType) => void,
    fieldStatus?: FieldStatus | any,
}

const PersonnePhysiqueFields: FC<PersonnePhysiqueFieldsProps> = ({
  intervenant = {},
  onChange,
  fieldStatus,
  intl
}) => (
  <div className='row'>
    <div className='col-12 col-md-4'>
      <SelectField
        inputId='civilityCode'
        label={intl.formatMessage({ id: 'field_civilite_label' })}
        placeholder={intl.formatMessage({ id: 'placeholder_select' })}
        value={intervenant.civilityCode || ''}
        options={CIVILITES}
        onChange={onChange}
        fieldStatus={fieldStatus}
        required
      />
    </div>
    <div className='col-12 col-md-4'>
      <TextField
        inputId='lastname'
        label={intl.formatMessage({ id: 'field_nom_label' })}
        value={intervenant.lastname || ''}
        onChange={onChange}
        fieldStatus={fieldStatus}
        maxLength='100'
        required
      />
    </div>
    <div className='col-12 col-md-4'>
      <TextField
        inputId='firstname'
        label={intl.formatMessage({ id: 'field_prenom_label' })}
        value={intervenant.firstname || ''}
        onChange={onChange}
        fieldStatus={fieldStatus}
        maxLength='100'
        required
      />
    </div>
  </div>
)

export default injectIntl(PersonnePhysiqueFields)
