import React, { FC, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import {
  CardBlock,
  containsErrors, Country,
  DepositCorrection, DepositDocument,
  DepositIntervenant, DOCUMENT_TYPES,
  EventType,
  FilAriane,
  FileBrowserField,
  INTERVENANT_CO_DEPOSANT,
  INTERVENANT_CREATEUR,
  INTERVENANT_DEPOSANT,
  INTERVENANT_DESTINATAIRE,
  INTERVENANT_MANDATAIRE, INTERVENANT_SIGNATAIRE, isFilled, ManageableQuality,
  PersonneFieldValidator, SelectOption, SubmitButton
} from '@inpi-dm/components'
import { Payment, PaymentParameters } from '../../../interfaces/DepositInterfaces'
import { REQUEST_TYPES } from '../../../constants/RequestConstants'
import SelectPaymentBlocks from '../../payment/SelectPaymentBlocks'
import DepositCorrectionValidator from './validator/DepositCorrectionValidator'
import DepositCorrectionIntervenant from './DepositCorrectionIntervenant'
import ModalEditIntervenant from './ModalEditIntervenant'
import CorrectionService from '../../../services/transaction/CorrectionService'
import { PAYMENT_METHOD, PAYMENT_OBJECT } from '../../../constants/DepositConstants'
import PaymentService from '../../../services/transaction/PaymentService'
import OverviewRoyalties from '../../payment/OverviewRoyalties'
import { toast } from 'react-toastify'
import Message from '../../../constants/Message'
import ContentService from '../../../services/content/ContentService'
import TransactionService from '../../../services/transaction/TransactionService'

interface DepositCorrectionFormProps {
    idTransaction: string,
}

const initialCorrection = {
  depositor: null,
  coDepositors: null,
  recipient: null,
  agent: null,
  creators: null,
  signatory: null,
  deletedAgent: false
}

const DepositCorrectionForm: FC<DepositCorrectionFormProps> = ({
  idTransaction
}) => {
  const history = useHistory()
  /** Dépôt initial */
  const [deposit, setDeposit] = useState()
  const [transaction, setTransaction] = useState()
  /** Demande de correction */
  const [correction, setCorrection] = useState<DepositCorrection>(initialCorrection)

  const [editedIntervenantKey, setEditedIntervenantKey] = useState<SelectOption>()
  const [index, setIndex] = useState(-1)
  const [supportingDocuments, setSupportingDocuments] = useState<DepositDocument[]>([])
  const [fieldStatus, setFieldStatus] = useState({})
  const [taxes, setTaxes] = useState<Payment>()
  const [countries, setCountries] = useState<Country[]>([])
  const [manageableQualities, setManageableQualities] = useState<ManageableQuality[]>()

  type Intervenant = DepositIntervenant|DepositIntervenant[]|string|null

  const typeDeposit = deposit && REQUEST_TYPES.find(type => type.statusFiltres.includes(deposit.status))

  const canAccessManageableMemory = deposit &&
        PersonneFieldValidator.isPersonneMorale(deposit.depositor) &&
        deposit.depositor.publicLaw

  const correctedDeposit = (correction && transaction)
    ? CorrectionService.mergeCorrectionInDeposit(transaction, correction)
    : undefined

  useEffect(() => {
    // Récupération de la transaction à corriger
    TransactionService.getTransaction(idTransaction)
      .then(transactionReponse => {
        const file = {
          ...transactionReponse.file,
          idTransaction: transactionReponse.id
        }
        setDeposit(file)
        setTransaction(transactionReponse)
      })

    // Récupération de la taxe de correction
    CorrectionService.getPayment(idTransaction)
      .then(setTaxes)
  }, [idTransaction])

  useEffect(() => {
    // Récupération des qualités administrables
    ContentService.getManageableQualities().then(result => {
      setManageableQualities(result)
    })

    // Récupération des pays
    ContentService.getCountries().then(result =>
      setCountries(result)
    )
  }, [])

  /**
     * Ajoute un document dans la liste des documents justificatifs
     * @param event
     */
  const handleChangeDocument = (event: EventType) => {
    const { value } = event.target
    const updatedDocuments = [...supportingDocuments]
    updatedDocuments.push({
      type: DOCUMENT_TYPES.DEPOSIT_CORRECTION_SUPPORT,
      internalName: '',
      name: value.name,
      file: value
    })
    setSupportingDocuments(updatedDocuments)
  }

  /**
     * Retire un document de la liste des documents justificatifs
     * @param event
     * @param index
     */
  const handleDeleteDocument = (event: EventType, index: number) => {
    const updatedDocuments = [...supportingDocuments]
    updatedDocuments.splice(index, 1)
    setSupportingDocuments(updatedDocuments)
  }

  /**
   * On doit s'assurer que tous les uploads de fichiers se terminent avant le paiement
   * et qu'ils soient tous fait les un a la suite des autres
   * @param correctionResult
   */
  const uploadDocument = async (correctionResult: DepositCorrection) => {
    for (const document of supportingDocuments) {
      await CorrectionService.uploadDocumentToCorrection(deposit.idTransaction, correctionResult.id, document.file, document.type)
    }
  }

  /**
     * Créer une demande de correction et l'envoi au serveur
     */
  const saveCorrection = () => {
    // Construction de la demande de correction avec les modifications sur les intervenants
    const correctionToSave = {
      depositor: correction.depositor,
      coDepositors: correction.coDepositors?.filter((coDepositor: DepositIntervenant) => !!coDepositor),
      recipient: correction.recipient,
      agent: correction.agent,
      creators: correction.creators,
      signatory: correction.signatory,
      agentDeleted: correction.agentDeleted
    }

    return CorrectionService.createCorrection(deposit.idTransaction, correctionToSave)
      .then(correctionResult => {
        return uploadDocument(correctionResult)
          .then(() => correctionResult)
      })
  }

  /**
     * Effectue le paiement d'une correction
     *
     * @param correction
     * @param payment
     */
  const payCorrection = (correction: DepositCorrection, payment: PaymentParameters) => {
    payment = {
      ...payment,
      label: 'Demande de correction',
      objectName: PAYMENT_OBJECT.CORRECTION,
      objectIds: [correction.id]
    }

    return PaymentService.createPayment(deposit.idTransaction, payment)
      .then(result => {
        if (payment.method === PAYMENT_METHOD.BLUE_CARD) {
          PaymentService.displayPayBox(result)
        } else {
          history.push(`/depots/${deposit.idTransaction}/paiement/confirmation`)
        }
      })
      .catch(() =>
        history.push(`/depots/${deposit.idTransaction}/paiement/erreur`)
      )
  }

  /**
     * Déclenche le paiement de la demande de correction
     * @param payment
     */
  const handleStartPayment = (payment: PaymentParameters) => {
    const depositMerged = CorrectionService.mergeCorrectionInDeposit(transaction, correction)
    depositMerged.file.coDepositors = depositMerged.file.coDepositors?.filter(cd => !!cd)
    const newFieldStatus = DepositCorrectionValidator.validateOnPayment(supportingDocuments, depositMerged)
    setFieldStatus(newFieldStatus)

    if (!containsErrors(newFieldStatus)) {
      return saveCorrection().then(
        correction => payCorrection(correction, payment)
      )
    } else {
      return newFieldStatus
    }
  }

  /**
     * Met à jour la correction avec l'intervenant modifié en paramètre
     *
     * @param intervenantKey     Champ intervenant du dépôt à modifier
     * @param updatedIntervenant Intervenant modifié à enregistrer
     * @param agentDocuments     Document de pouvoir du mandataire
     */
  const updateCorrection = (
    intervenantKey: SelectOption,
    updatedIntervenant?: Intervenant,
    agentDocuments?: DepositDocument[]
  ) => {
    const updatedCorrection = { ...correction }

    updatedCorrection[intervenantKey.value] = updatedIntervenant

    if (intervenantKey === INTERVENANT_MANDATAIRE) {
      // Mise à jour des documents du mandataire
      const updatedSupportingDocuments = [...supportingDocuments].filter(doc => doc.type !== DOCUMENT_TYPES.DEPOSIT_AGENT_DOCUMENT)

      if (agentDocuments) {
        updatedSupportingDocuments.push(...agentDocuments)
      }
      setSupportingDocuments(updatedSupportingDocuments)

      updatedCorrection.agentDeleted = false
    }

    setCorrection(updatedCorrection)
  }

  /**
     * Met à jour la liste des co-déposants
     *
     * @param index
     * @param updatedIntervenant
     */
  const updateCorrectionCoDepositors = (
    index: number,
    updatedIntervenant?: DepositIntervenant|string|null
  ) => {
    let updatedCorrectionCodepositors = correction.coDepositors || deposit.coDepositors || []
    updatedCorrectionCodepositors = [...updatedCorrectionCodepositors]

    if (!deposit.coDepositors[index] && !updatedIntervenant) {
      // Suppression d'un co-déposant n'étant pas de base présent dans le dépôt d'origine,
      // on retire complètement
      updatedCorrectionCodepositors.splice(index, 1)
    } else {
      // Ajout d'un nouveau co-déposant,
      // modification ou suppression d'un co-déposant présent de base dans le dépôt d'origine,
      // on remplace par l'intervenant fourni en paramètre
      updatedCorrectionCodepositors[index] = updatedIntervenant
    }

    // Si aucune différence entre les co-déposants d'un dépôt et de la correction,
    // alors on met le tableaux des co-déposants à null dans la correction
    const diffIntervenant = updatedCorrectionCodepositors.filter((coDepositor, i) =>
            deposit.coDepositors?.[i] !== coDepositor
    )
    if (!isFilled(diffIntervenant)) {
      updatedCorrectionCodepositors = null
    }

    updateCorrection(INTERVENANT_CO_DEPOSANT, updatedCorrectionCodepositors)
  }

  /**
     * Supprime l'intervenant sélectionné
     *
     * @param intervenantKey Champ intervenant du dépôt à modifier
     */
  const deleteIntervenant = (intervenantKey: SelectOption) => {
    if (intervenantKey === INTERVENANT_MANDATAIRE) {
      // Si on a des co-dépposants restants après avoir appliqué la correction,
      // on ne pourra pas supprimer le mandataire
      if (isFilled(correction.coDepositors?.filter(cd => cd)) ||
                (!correction.coDepositors && isFilled(deposit.coDepositors))
      ) {
        toast.error(Message.request_correction_error_undeletable_agent)
      } else {
        updateCorrection(intervenantKey)
        // Dans le cas d'un mandataire, on signale clairement que c'est une suppression
        setCorrection((correction: DepositCorrection) => ({
          ...correction,
          agentDeleted: true
        }))
      }
    } else {
      updateCorrection(intervenantKey)
    }
  }

  /**
     * Met à jour l'intervenant en cours de modification
     */
  const handleUpdateIntervenant = (updatedIntervenant: DepositIntervenant|string, agentDocuments?: DepositDocument[]) => {
    if (editedIntervenantKey === INTERVENANT_CO_DEPOSANT) {
      updateCorrectionCoDepositors(index, updatedIntervenant)
    } else {
      updateCorrection(editedIntervenantKey, updatedIntervenant, agentDocuments)
    }
    setEditedIntervenantKey(undefined)
  }

  /**
     * Ouvre la popup d'édition d'un intervenant
     * @param intervenantKey Champ intervenant du dépôt à modifier
     * @param index Index du co-déposant à modifier
     */
  const handleEditIntervenant = (intervenantKey: SelectOption, index = -1) => {
    setEditedIntervenantKey(intervenantKey)
    setIndex(index)
  }

  /**
     * Récupère l'intervenant à éditer
     */
  const getEditedIntervenant = () => {
    if (editedIntervenantKey === INTERVENANT_CO_DEPOSANT) {
      return (correction.coDepositors && correction.coDepositors[index]) ||
                (deposit.coDepositors && deposit.coDepositors[index])
    }
    if (editedIntervenantKey) {
      return correction[editedIntervenantKey.value] || deposit[editedIntervenantKey.value]
    }
    return undefined
  }

  /**
     * Affiche les co-déposants
     */
  const renderCoDepositors = () => {
    const originalLength = deposit?.coDepositors?.length || 0
    const updatedLength = correctedDeposit?.file?.coDepositors?.length || 0
    const arraySrc = originalLength > updatedLength
      ? deposit?.coDepositors
      : correctedDeposit?.file?.coDepositors

    return arraySrc?.map((p, index) =>
      <DepositCorrectionIntervenant
        key={index}
        title={<><FormattedMessage id='overview_deposit_codepositor_title' />{index + 1}</>}
        onEdit={() => handleEditIntervenant(INTERVENANT_CO_DEPOSANT, index)}
        onReset={() => updateCorrectionCoDepositors(index, deposit?.coDepositors?.[index])}
        onDelete={() => updateCorrectionCoDepositors(index)}
        intervenant={deposit.coDepositors?.[index]}
        updatedIntervenant={correctedDeposit?.file?.coDepositors?.[index]}
        correctedDeposit={correctedDeposit}
        countries={countries}
      />
        )
  }

  return (
    <>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        {typeDeposit && (
          <Link to={`/liste-demandes/${typeDeposit.urlParam}/demande/${deposit.idTransaction}`}>
            <FormattedMessage id='request_title' />{deposit.idTransaction}
          </Link>
        )}
        <span><FormattedMessage id='request_correction_button' /></span>
      </FilAriane>

      <div className='mb-4'>
        <CardBlock
          bodyClassName='row'
        >
          <header className='col-12 align-self-center mb-3'>
            <h1 className='ml-3'><FormattedMessage id='request_correction_title' /></h1>
          </header>

          <div className='col-12 col-md-8 mb-3'>
            <CardBlock
              header={<FormattedMessage id='intervenant_form_title' />}
              shadow
            >
              {deposit && correction && (
                <div className='row'>
                  <DepositCorrectionIntervenant
                    title={<FormattedMessage id='overview_deposit_depositor_title' />}
                    onEdit={() => handleEditIntervenant(INTERVENANT_DEPOSANT)}
                    onReset={() => updateCorrection(INTERVENANT_DEPOSANT, null)}
                    intervenant={deposit.depositor}
                    updatedIntervenant={correctedDeposit?.file.depositor}
                    correctedDeposit={correctedDeposit}
                    countries={countries}
                  />

                  {renderCoDepositors()}

                  <div className='col-12 col-md-4 mb-2'>
                    <SubmitButton
                      className='btn-link text-primary'
                      onClick={() => handleEditIntervenant(
                        INTERVENANT_CO_DEPOSANT,
                                                correction.coDepositors?.length || deposit.coDepositors?.length || 0
                      )}
                    >
                      <FormattedMessage id='intervenant_add_co_deposant' />
                    </SubmitButton>
                  </div>

                  <DepositCorrectionIntervenant
                    title={<FormattedMessage id='overview_deposit_receiver_title' />}
                    onEdit={() => handleEditIntervenant(INTERVENANT_DESTINATAIRE)}
                    onReset={() => updateCorrection(INTERVENANT_DESTINATAIRE, null)}
                    intervenant={deposit.recipient}
                    updatedIntervenant={correctedDeposit?.file.recipient}
                    correctedDeposit={correctedDeposit}
                    countries={countries}
                    deposit={{ file: deposit }}
                  />

                  <DepositCorrectionIntervenant
                    title={<FormattedMessage id='overview_deposit_mandatary_title' />}
                    onEdit={() => handleEditIntervenant(INTERVENANT_MANDATAIRE)}
                    onReset={() => updateCorrection(INTERVENANT_MANDATAIRE, null)}
                    onDelete={() => deleteIntervenant(INTERVENANT_MANDATAIRE)}
                    intervenant={deposit.agent}
                    updatedIntervenant={correctedDeposit?.file.agent}
                    correctedDeposit={correctedDeposit}
                    countries={countries}
                    qualities={manageableQualities}
                    errorMessage={containsErrors(fieldStatus.agent) ? 'request_correction_error_empty_mandataire' : ''}
                    deposit={{ file: deposit }}
                  />

                  <DepositCorrectionIntervenant
                    title={<FormattedMessage id='overview_deposit_creators_title' />}
                    onEdit={() => handleEditIntervenant(INTERVENANT_CREATEUR)}
                    onReset={() => updateCorrection(INTERVENANT_CREATEUR, null)}
                    intervenant={deposit.creators}
                    updatedIntervenant={correctedDeposit?.file.creators}
                    correctedDeposit={correctedDeposit}
                  />

                  <DepositCorrectionIntervenant
                    title={<FormattedMessage id='overview_deposit_signatory_title' />}
                    onEdit={() => handleEditIntervenant(INTERVENANT_SIGNATAIRE)}
                    onReset={() => updateCorrection(INTERVENANT_SIGNATAIRE, null)}
                    intervenant={deposit.signatory}
                    updatedIntervenant={correctedDeposit?.file.signatory}
                    correctedDeposit={correctedDeposit}
                    qualities={manageableQualities}
                  />
                </div>
              )}
            </CardBlock>
          </div>

          <div className='col-12 col-md-4 mb-3'>
            <CardBlock
              header={<FormattedMessage id='request_correction_supporting_documents' />}
              shadow
              bodyClassName='is-validated'
            >
              <FileBrowserField
                inputId='supportingDocuments'
                label={<FormattedMessage id='request_correction_supporting_documents' />}
                value={supportingDocuments
                  .filter(doc => doc.type === DOCUMENT_TYPES.DEPOSIT_CORRECTION_SUPPORT)
                  .map(doc => doc.file)}
                acceptApplication='application/pdf'
                onChange={handleChangeDocument}
                fieldStatus={fieldStatus}
                butonLabel={
                  <div className='border'>
                    <div className='text-center text-nowrap my-1'>
                      <FormattedMessage id='button_find_file' />
                    </div>
                  </div>
                }
                onDelete={handleDeleteDocument}
                required
              />
            </CardBlock>
          </div>

          <div className='col-12 mb-3'>
            <OverviewRoyalties infoPrice={taxes} />
          </div>

          {deposit &&
            <div className='col-12 mb-3'>
              <SelectPaymentBlocks
                onStartPayment={handleStartPayment}
                manageableMemoryAccessible={canAccessManageableMemory}
                object={deposit}
              />
            </div>}

        </CardBlock>

        {editedIntervenantKey && correctedDeposit && (
          <ModalEditIntervenant
            typeIntervenant={editedIntervenantKey}
            intervenant={getEditedIntervenant()}
            onChange={handleUpdateIntervenant}
            onClose={() => setEditedIntervenantKey(undefined)}
            showModal={!!editedIntervenantKey}
            deposit={correctedDeposit}
            correction={correction}
            documents={correctedDeposit.documents}
          />
        )}
      </div>
    </>
  )
}

export default DepositCorrectionForm
