import store from '../../../../../store/store'
import { containsErrors, IntervenantValidator } from '@inpi-dm/components'
import { storeInscriptionFieldStatusUpdate } from '../../../../../store/inscription/inscriptionActions'

class RecipientValidator {
  validateOnChangeStep = () => {
    const inscription = store.getState().inscription

    const fieldStatus = IntervenantValidator.validateRecipient(inscription.recipient)
    store.dispatch(storeInscriptionFieldStatusUpdate({
      recipient: fieldStatus
    }))

    return !containsErrors(fieldStatus)
  }
}

export default new RecipientValidator()
