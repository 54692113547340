import {
  containsErrors, DepositDocument,
  FieldStatus, isFilled, messageIsFilled,
  Prorogation,
  ProrogationInscriptionRequest
} from '@inpi-dm/components'
import Message from '../../../../../constants/Message'
import store from '../../../../../store/store'
import { storeProrogationFieldStatusUpdate } from 'store/prorogation/prorogationActions'

class RequestDetailsFormValidator {
  /**
   * Valide la demande d'inscription
   * @param inscriptionRequest
   */
  validateInscriptionRequest = (inscriptionRequest :ProrogationInscriptionRequest) : FieldStatus => {
    return {
      numOrder: !isFilled(inscriptionRequest.numInscription) && !isFilled(inscriptionRequest.numOrder)
        ? Message.prorogation_inscription_request_missing_number : ''
    }
  }

  /**
   * Valide une pièce complémentaire
   * @param additionalPart
   * @param keyFile
   * @param keyType
   */
  validateAdditionalPart = (additionalPart: DepositDocument, keyFile: string, keyType: string): FieldStatus => {
    return {
      [keyFile]: additionalPart.file ? '' : Message.required_field,
      [keyType]: messageIsFilled(additionalPart.type)
    }
  }

  /**
   * Valide toutes les infos de la page détails
   */
  validateOnChangeStep = async (additionalParts?: DepositDocument[]) => {
    const prorogation: Prorogation | undefined = store.getState().prorogation.prorogation
    const inscriptionRequests: ProrogationInscriptionRequest[] = prorogation?.inscriptionRequests || []

    let inscriptionRequestFieldStatus
    let inscriptionRequestsFieldStatus
    if (prorogation?.isNewDepositor && inscriptionRequests.length === 0) {
      inscriptionRequestFieldStatus = Message.prorogation_inscription_request_missing_inscription_request
    } else if (inscriptionRequests.length > 0) {
      inscriptionRequestsFieldStatus = inscriptionRequests?.map((inscriptionRequest) => ({ ...this.validateInscriptionRequest(inscriptionRequest) }))
    }

    let additionalPartsFieldStatus
    if (additionalParts && additionalParts.length > 0) {
      additionalPartsFieldStatus = additionalParts.map((additionalPart, index) => ({ ...this.validateAdditionalPart(additionalPart, `file-${index}`, `type-${index}`) }))
    }

    const inscriptionRequestError = containsErrors(inscriptionRequestFieldStatus)
    const inscriptionRequestsError = containsErrors(inscriptionRequestsFieldStatus)
    const additionalPartsError = containsErrors(additionalPartsFieldStatus)

    store.dispatch(storeProrogationFieldStatusUpdate({
      inscriptionRequest: inscriptionRequestFieldStatus || '',
      inscriptionRequests: inscriptionRequestsFieldStatus || '',
      additionalParts: additionalPartsFieldStatus || ''
    }))

    return !inscriptionRequestsError && !additionalPartsError && !inscriptionRequestError
  }
}

export default new RequestDetailsFormValidator()
