import { Country, ManageableQuality, ProrogationDelay } from '@inpi-dm/components'
import {
  ContentStoreAction,
  COUNTRIES_UPDATE,
  MANAGEABLE_QUALITIES_UPDATE,
  PROROGATION_DELAYS_UPDATE
} from './contentStoreTypes'

export const storeCountriesUpdate = (countries: Country[]): ContentStoreAction => ({
  type: COUNTRIES_UPDATE,
  countries
})

export const storeManageableQualitiesUpdate = (qualities: ManageableQuality[]): ContentStoreAction => ({
  type: MANAGEABLE_QUALITIES_UPDATE,
  qualities
})

export const storeProrogationDelaysUpdate = (prorogationDelays: ProrogationDelay[]): ContentStoreAction => ({
  type: PROROGATION_DELAYS_UPDATE,
  prorogationDelays
})
