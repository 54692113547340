import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { SubmitButton } from '@inpi-dm/components'
import TransactionService from '../../../services/transaction/TransactionService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import fileDownload from 'js-file-download'

interface DownloadLinkProps {
    id: string,
    name: string,
    internalName: string,
    generate?: boolean,
    classname?: string,
    label?: string
    onClick?: () => void,
    format?: string
}

const DownloadLink : FC<DownloadLinkProps> = ({
  id,
  name,
  internalName,
  generate,
  classname,
  label,
  onClick,
  format = 'pdf'
}) => {
  const downloadDepositPDF = () => {
    const downloadRequest = generate
    // Génération du fichier recap
      ? TransactionService.downloadTransactionPDF(id)
    // Récupération du recap déjà sauvegardé
      : (onClick ? onClick() : TransactionService.getDocumentFile(id, internalName))

    return downloadRequest.then(data => fileDownload(data, name, format))
  }

  return (
    <div className={`${classname || 'justify-content-end'} d-flex`}>
      <SubmitButton className='btn btn-link text-primary' onClick={downloadDepositPDF}>
        <FontAwesomeIcon icon={faDownload} size='xs' className='mr-2' />
        {label || <FormattedMessage id='overview_deposit_overview_download' />}
      </SubmitButton>
    </div>
  )
}

export default DownloadLink
