import React, { FC, ReactNode } from 'react'
import StepListItem, { StepListItemContent } from './StepListItem'

interface StepListProps {
  title?: ReactNode,
  className?: string,
  items: StepListItemContent[],
  idActive: string,
  onClickStep: (indexStepClicked: number) => void,
}

const StepList: FC<StepListProps> = ({
  title = '',
  className = '',
  items,
  idActive,
  onClickStep
}) => (
  <div className={`step-list ${className}`}>
    <div className='title'>{title}</div>
    <div className='list'>
      {
        items.map((item, index) => {
          if (item.condition == null || item.condition) {
            return (
              <StepListItem
                {...item}
                key={item.id}
                index={index}
                idActive={idActive}
                onClick={onClickStep}
              />
            )
          } else {
            return null
          }
        })
      }
    </div>
  </div>
)

export default StepList
