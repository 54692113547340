import { DepositDocument, DOCUMENT_TYPES, FieldStatus, isFilled } from '@inpi-dm/components'
import Message from '../../../constants/Message'

class DocumentBlockValidator {
    /**
     * Verifie en fct du type de document le format est le bon
     */
    isGoodLinkBetweenTypeAndFile = (documentToAdd : DepositDocument) : boolean => {
      if (documentToAdd) {
        if (documentToAdd.type === DOCUMENT_TYPES.DOCUMENT_TYPE_REGULARIZE_REPRO && (documentToAdd.file?.type === 'image/jpeg' ||
          documentToAdd.file?.type === 'image/jpg' || documentToAdd.file?.type === 'image/png')) {
          return true
        }
        if (documentToAdd.type !== DOCUMENT_TYPES.DOCUMENT_TYPE_REGULARIZE_REPRO && documentToAdd.file?.type === 'application/pdf') {
          return true
        }
      }
      return false
    }

    /**
     * Ajoute dans la liste local des documents a ajouter le document selectionné
     */
    handleAddOneDocument = (documentToAdd : DepositDocument|undefined) : FieldStatus => {
      let newFieldStatus : FieldStatus = {}
      if (documentToAdd && !this.isGoodLinkBetweenTypeAndFile(documentToAdd)) {
        newFieldStatus = { type: Message.request_type_pdf }
      }
      if (!documentToAdd) {
        newFieldStatus = { type: Message.required_field, file: Message.required_field }
      }
      if (documentToAdd && !isFilled(documentToAdd.type)) {
        newFieldStatus = { type: Message.required_field }
      }
      if (documentToAdd && !documentToAdd.file) {
        newFieldStatus = { file: Message.required_field }
      }

      return newFieldStatus
    }
}

export default new DocumentBlockValidator()
