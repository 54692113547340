import { Country, ManageableQuality, ProrogationDelay } from '@inpi-dm/components'
import {
  ContentStoreAction,
  COUNTRIES_UPDATE,
  MANAGEABLE_QUALITIES_UPDATE,
  PROROGATION_DELAYS_UPDATE
} from './contentStoreTypes'

interface ContentStore {
  countries: Country[],
  qualities: ManageableQuality[],
  prorogationDelays: ProrogationDelay[]
}

const initialContentState: ContentStore = {
  countries: [],
  qualities: [],
  prorogationDelays: []
}

export function reducerContentState (state = initialContentState, action: ContentStoreAction): ContentStore {
  switch (action.type) {
    case COUNTRIES_UPDATE:
      return { ...state, countries: action.countries }
    case MANAGEABLE_QUALITIES_UPDATE:
      return { ...state, qualities: action.qualities }
    case PROROGATION_DELAYS_UPDATE:
      return { ...state, prorogationDelays: action.prorogationDelays }
    default:
      return state
  }
}
