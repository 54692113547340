import { containsErrors, FieldStatus, messageIsFilled, OfficialDocumentRequest, COPY_OF_INSCRIPTION, COPY_OF_PROROGATION } from '@inpi-dm/components'
import Message from '../../../../../constants/Message'
import store from '../../../../../store/store'
import { storeOfficialDocumentFieldStatusUpdate } from '../../../../../store/officialDocument/officialDocumentActions'

class SelectRecordFormValidator {
  /**
   * Valide le titre
   * @param officialDocumentRequest
   */
  validateMandatory = (officialDocumentRequest : OfficialDocumentRequest) : FieldStatus => {
    let fieldStatus = {}
    if (officialDocumentRequest.officialDocumentType?.code === COPY_OF_INSCRIPTION || officialDocumentRequest.officialDocumentType?.code === COPY_OF_PROROGATION) {
      fieldStatus = {
        arrivalDate: messageIsFilled(officialDocumentRequest.arrivalDate),
        copyNum: messageIsFilled(officialDocumentRequest.copyNum)
      }
    } else if (officialDocumentRequest.title) {
      fieldStatus = {
        origin: messageIsFilled(officialDocumentRequest.title.origin),
        depositDate: messageIsFilled(officialDocumentRequest.title.depositDate),
        numNat: messageIsFilled(officialDocumentRequest.title.numNat)
      }
    }

    return {
      ...fieldStatus,
      officialDocumentType: messageIsFilled(officialDocumentRequest.officialDocumentType?.code),
      quantity: officialDocumentRequest.quantity && officialDocumentRequest.quantity > 0 ? '' : Message.records_official_document_service_quantity_error,
      notFound: ''
    }
  }

  /**
   * Valide tous les titres
   */
  validateOnChangeStep = async () => {
    const officialDocumentRequests = store.getState().officialDocument.officialDocument?.officialDocumentRequests || []

    let allFieldStatus
    if (officialDocumentRequests.length === 0) {
      const fieldStatus = this.validateMandatory({})
      allFieldStatus = [{ ...fieldStatus, notFound: Message.warning_at_least_one_title }]
    } else {
      allFieldStatus = officialDocumentRequests?.map((officialDocumentRequest) => this.validateMandatory(officialDocumentRequest))
    }

    // on vérifie que tous les documents appartiennent à la même famille
    const familyType = officialDocumentRequests.map(elem => elem.officialDocumentType?.family || '')
    const familyTypeDistinct = familyType.filter((n, i) => familyType.indexOf(n) === i)
    if (familyTypeDistinct.length > 1) {
      const message = Message.records_official_document_error_familly
      allFieldStatus = [{ ...allFieldStatus, officialDocumentFamily: message }]
    }

    const error = containsErrors(allFieldStatus)

    store.dispatch(storeOfficialDocumentFieldStatusUpdate({
      officialDocumentRequests: allFieldStatus
    }))

    return !error
  }
}

export default new SelectRecordFormValidator()
