import store from '../../../../../store/store'
import { containsErrors, FieldStatus } from '@inpi-dm/components'
import Message from '../../../../../constants/Message'
import { storeInscriptionFieldStatusUpdate } from '../../../../../store/inscription/inscriptionActions'
import { InscriptionFieldStatus } from '../../../../../interfaces/InscriptionInterfaces'

class OverviewFormInscriptionValidator {
    validateMandatory = (similarInformation: boolean): FieldStatus => {
      return {
        overviewSimilarInformation: similarInformation ? '' : Message.required_checkbox_field
      }
    }

    validateOnChangeStep = () => {
      const transaction = store.getState().inscription

      const fieldStatus : InscriptionFieldStatus = this.validateMandatory(transaction.overviewSimilarInformation || false)
      store.dispatch(storeInscriptionFieldStatusUpdate({
        overviewSimilarInformation: fieldStatus
      }))

      return !containsErrors(fieldStatus)
    }
}

export default new OverviewFormInscriptionValidator()
