import React, { FC } from 'react'
import ErrorField from '../errors/ErrorField'
import { TextFieldProps } from './TextField'
import { Dropdown, DropDownItem } from '../../index'

interface InlineTextFieldProps extends TextFieldProps {
  inputClassName?: string,
  needDropDown?: boolean,
  dropDownItems?: DropDownItem[],
  dropDownName?: string,
  onDropDownOnSelect?: (item: DropDownItem) => void,
  dropDownClassName?: string
}

const InlineTextField: FC<InlineTextFieldProps> = ({
  type = 'text',
  className = '',
  inputId,
  label,
  fieldStatus = {},
  value = '',
  onChange,
  placeholder = '',
  disabled = false,
  readOnly = false,
  children,
  inputClassName,
  needDropDown,
  dropDownItems,
  dropDownName,
  onDropDownOnSelect,
  dropDownClassName
}) => (
  <div className={`inline-text-field ${className}`}>
    {label && <label className='form-label' htmlFor={inputId}>{label}</label>}

    <div className='input-group'>
      {needDropDown && (
        <div className={`input-group-prepend ${dropDownClassName}`}>
          <Dropdown
            items={dropDownItems}
            name={dropDownName}
            onSelect={onDropDownOnSelect}
            buttonClassName='text-dark'
            className='custom-dropdown-header nav-white'
            arrow
          />
        </div>
      )}
      <input
        type={type}
        id={inputId}
        name={inputId}
        className={`form-control one-line-ellipsis ${inputClassName}`}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
      />
      <div className='input-group-append'>
        {children}
      </div>
    </div>

    <ErrorField message={fieldStatus[inputId]} />
  </div>
)

export default InlineTextField
