import React from 'react'
import OverviewLabelText from './OverviewLabelText'
import { FormattedMessage } from 'react-intl'
import { Deposit, DateUtils } from '@inpi-dm/components'

interface OverviewGeneralInformationProps {
  deposit: Deposit,
  depositView?: boolean | null
}

const OverviewGeneralInformation = (props: OverviewGeneralInformationProps) => {
  const {
    depositView,
    deposit
  } = props

  return (
    <div className={`${depositView ? 'w-50' : ''}`}>
      <OverviewLabelText idLabel='select_depot_type_title'>
        {
          deposit.depositType === 'DEPOT_CLASSIQUE'
            ? <FormattedMessage id='select_depot_type_classique_title' />
            : deposit.depositType === 'DEPOT_AJOURNE'
              ? <FormattedMessage id='select_depot_type_ajourne_title' />
              : <FormattedMessage id='select_depot_type_simplifie_title' />
        }
      </OverviewLabelText>
      <OverviewLabelText idLabel='overview_deposit_internal_reference_title'>
        {deposit.internalReference}
      </OverviewLabelText>
      {deposit.depositDate &&
        <OverviewLabelText idLabel='overview_deposit_date'>
          {DateUtils.formatDateFr(deposit.depositDate)}
        </OverviewLabelText>}
    </div>
  )
}

export default OverviewGeneralInformation
