import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux'
import {
  ErrorField,
  containsErrors,
  FieldStatus,
  SubmitButton,
  Transaction,
  OFFICIAL_DOCUMENTS_TYPE,
  OfficialDocumentType,
  OfficialDocumentRequest,
  DEFAULT_TITLE_OFFICIAL_DOCUMENT,
  COPY_OF_INSCRIPTION,
  COPY_OF_PROROGATION
} from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'
import { storeContributorsTitleUpdate } from '../../../../store/contributorsTitle/contributorsTitleActions'
import {
  storeOfficialDocumentFieldStatusUpdate,
  storeOfficialDocumentUpdate
} from '../../../../store/officialDocument/officialDocumentActions'
import { OfficialDocumentFieldStatus } from '../../../../interfaces/OfficialDocumentInterfaces'
import OverviewRecords from '../../../title/OverviewRecords'
import OfficialDocumentTypeService from '../../../../services/content/OfficialDocumentTypeService'
import SelectOfficialDocumentRecordsForm from './SelectOfficialDocumentRecordsForm'
import SelectOfficialDocumentTransactionsForm from '../transaction/SelectOfficialDocumentTransactionsForm'

const SelectOfficialDocumentRecords = () => {
  const dispatch = useDispatch()
  const officialDocument:Transaction = useSelector((state:RootStateOrAny) => state.officialDocument)
  const officialDocumentFieldStatus: OfficialDocumentFieldStatus = useSelector((state:RootStateOrAny) => state.officialDocumentFieldStatus)
  const contributors = useSelector((state: RootStateOrAny) => state.contributorsTitle)

  const [requests, setRequests] = useState<OfficialDocumentRequest[]>(officialDocument.officialDocument?.officialDocumentRequests || [])
  const [fieldStatus, setFieldStatus] = useState<FieldStatus[]>(officialDocumentFieldStatus.officialDocumentRequests || [])

  const [curentIndexRequest, setCurentIndexRequest] = useState(officialDocument.officialDocument?.officialDocumentRequests?.length || 0)
  const [request, setRequest] = useState<OfficialDocumentRequest>(curentIndexRequest > 0 ? requests[curentIndexRequest - 1] : DEFAULT_TITLE_OFFICIAL_DOCUMENT)
  const [showForm, setShowForm] = useState(!(officialDocument.officialDocument?.officialDocumentRequests && officialDocument.officialDocument.officialDocumentRequests.length > 0))

  const [typesOfficialsDocument, setTypesOfficialDocument] = useState<OfficialDocumentType[]>([])

  useEffect(() => {
    OfficialDocumentTypeService.getOfficialDocumentTypes().then((result) => {
      setTypesOfficialDocument(result.officialDocumentTypes)
    })
  }, [])

  useEffect(() => {
    officialDocumentFieldStatus.officialDocumentRequests && setFieldStatus(officialDocumentFieldStatus.officialDocumentRequests)
  }, [officialDocumentFieldStatus.officialDocumentRequests])

  /**
   *
   * Mise a jours du state et store
   * @param request
   * @param numNat
   * @param newFieldStatus
   */
  const onSubmit = (request:OfficialDocumentRequest, numNat: string, newFieldStatus:FieldStatus) => {
    const updateRequests = [...requests]
    if (request.officialDocumentType?.code === COPY_OF_PROROGATION || request.officialDocumentType?.code === COPY_OF_INSCRIPTION) {
      request.originalNumNat = numNat
    }
    updateRequests[curentIndexRequest] = request
    const updateRequestFieldStatus = [...fieldStatus]
    updateRequestFieldStatus[curentIndexRequest] = newFieldStatus

    setRequests(updateRequests)
    setFieldStatus(updateRequestFieldStatus)
    dispatch(storeOfficialDocumentUpdate({ officialDocument: { ...officialDocument.officialDocument, officialDocumentRequests: updateRequests } }))
    dispatch(storeOfficialDocumentFieldStatusUpdate({
      officialDocumentRequests: updateRequestFieldStatus
    }))

    setCurentIndexRequest(updateRequests.length)
    setShowForm(false)
  }

  /**
   * Modification de la quantité
   * @param quantity
   * @param index
   */
  const setQuantity = (index: number, quantity?: number) => {
    const updateRequest = [...requests]
    updateRequest[index] = { ...updateRequest[index], quantity: quantity }

    setRequests(updateRequest)
    dispatch(storeOfficialDocumentUpdate({ officialDocument: { ...officialDocument.officialDocument, officialDocumentRequests: updateRequest } }))
  }

  /**
   * Supprimer un Request du state et store
   * @param index
   */
  const deleteOneRequest = (index:number) => {
    const updateRequests = [...requests]
    const numnatToDelete = requests[index].title?.numNat
    updateRequests.splice(index, 1)
    setRequests(updateRequests)
    dispatch(storeOfficialDocumentUpdate({ officialDocument: { ...officialDocument.officialDocument, officialDocumentRequests: updateRequests } }))
    setCurentIndexRequest(-1)

    const updateFielStatusRequest = [...fieldStatus]
    updateFielStatusRequest.splice(index, 1)
    setFieldStatus(updateFielStatusRequest)
    dispatch(storeOfficialDocumentUpdate({ officialDocumentRequests: updateFielStatusRequest }))

    if (contributors) {
      const c = { ...contributors }
      if (numnatToDelete && c[numnatToDelete]) {
        delete c[numnatToDelete]
      }
      // On enregistre les intervenants dans le store pour pouvoir les proposer lors des formulaires des intervenants
      dispatch(storeContributorsTitleUpdate(c))
    }
  }

  return (
    <>
      <div className='row'>
        <header className='col-8 mb-4'>
          <h1><FormattedMessage id='records_request_title' /></h1>
          <span className='subtitle'><FormattedMessage id='records_request_subtitle' /></span>
        </header>
        <InternalReferenceField
          value={officialDocument.internalReference}
          procedureType={OFFICIAL_DOCUMENTS_TYPE.value}
          className='col-4 mb-4'
        />
      </div>
      {
        curentIndexRequest > 0 &&
          <OverviewRecords
            titles={requests}
            isOneTitle={false}
            changeTitle={(index: number) => {
              setShowForm(true)
              setCurentIndexRequest(index)
            }}
            deleteTitle={deleteOneRequest}
            isBeforeForm
            currentIndex={curentIndexRequest}
            fieldStatus={fieldStatus}
            isOriginShown={false}
            isDateShown={false}
            isTypeOfficialDocumentShown
            typesOfficialsDocument={typesOfficialsDocument}
            isQuantityShown
            setQuantity={setQuantity}
            idPublicationShown={false}
            selectedOfficialDocumentTypeCode={request.officialDocumentType?.code}
          />
      }
      {
        showForm && (
          request.officialDocumentType?.code === COPY_OF_INSCRIPTION || request.officialDocumentType?.code === COPY_OF_PROROGATION
            ? (
              <SelectOfficialDocumentTransactionsForm
                key={curentIndexRequest}
                request={request}
                setRequest={setRequest}
                initialFieldStatus={fieldStatus && { ...fieldStatus[curentIndexRequest] }}
                onSubmit={onSubmit}
                typesOfficialsDocument={typesOfficialsDocument}
              />
            )
            : (
              <SelectOfficialDocumentRecordsForm
                key={curentIndexRequest}
                request={request}
                setRequest={setRequest}
                initialFieldStatus={fieldStatus && { ...fieldStatus[curentIndexRequest] }}
                onSubmit={onSubmit}
                typesOfficialsDocument={typesOfficialsDocument}
              />
            )
        )
      }
      {
        requests?.length > curentIndexRequest + 1 &&
          <OverviewRecords
            titles={requests}
            isOneTitle={false}
            changeTitle={(index: number) => {
              setShowForm(true)
              setCurentIndexRequest(index)
            }}
            deleteTitle={deleteOneRequest}
            isBeforeForm={false}
            currentIndex={curentIndexRequest}
            fieldStatus={fieldStatus}
            isOriginShown={false}
            isDateShown={false}
            isTypeOfficialDocumentShown
            typesOfficialsDocument={typesOfficialsDocument}
            isQuantityShown
            setQuantity={setQuantity}
            idPublicationShown={false}
            selectedOfficialDocumentTypeCode={request.officialDocumentType?.code}
          />
      }
      {
        !showForm &&
          <div>
            <hr />
            <div className='d-flex align-items-center justify-content-between'>
              <div className='mr-4'>
                <SubmitButton
                  className='btn-outline-primary'
                  onClick={() => {
                    setShowForm(true)
                    setCurentIndexRequest(requests.length)
                    setRequest(DEFAULT_TITLE_OFFICIAL_DOCUMENT)
                  }}
                >
                  <FormattedMessage id='records_inscription_add' />
                </SubmitButton>
              </div>
            </div>
          </div>
      }
      {
        fieldStatus.length > 0 && fieldStatus[0] && containsErrors(fieldStatus[0]) &&
          <ErrorField message={fieldStatus[0].notFound} />
      }
      {
        fieldStatus.length > 0 && fieldStatus[0] && containsErrors(fieldStatus[0]) &&
          <div className='text-danger'>
            {fieldStatus[0].officialDocumentFamily}
          </div>
      }
    </>
  )
}

export default withRouter(SelectOfficialDocumentRecords)
