import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  APPEAL_TYPE_ACT, APPEAL_TYPE_SUPPORTING_DOCUMENT, DepositDocument, DOCUMENT_TYPES,
  Transaction
} from '@inpi-dm/components'
import { DocumentType } from 'back-office/src/interfaces/DocumentType'
import TransactionService from '../../../../services/transaction/TransactionService'

interface OverviewAppealDetailsProps {
  transaction: Transaction
}

const OverviewAppealDetails: FC<OverviewAppealDetailsProps> = ({
  transaction
}) => {
  const [documentTypeActe, setDocumentTypeActe] = useState<DocumentType[]>([])
  const [documentTypeSupporting, setDocumentTypeSupporting] = useState<DocumentType[]>([])

  useEffect(() => {
    TransactionService.getDocumentTypes(APPEAL_TYPE_ACT.value).then(setDocumentTypeActe)
    TransactionService.getDocumentTypes(APPEAL_TYPE_SUPPORTING_DOCUMENT.value).then(setDocumentTypeSupporting)
  }, [])

  /**
   * Compte le nombre de documents dans cette catégorie
   * @param documentTypesFilter
   * @param additionalType
   */
  const countDocumentInTheseTypes = (documentTypesFilter: DocumentType[], additionalType?: string) => {
    return transaction.documents?.filter(document => {
      let optionalCondition = true
      if (document.additionalType && additionalType) {
        optionalCondition = document.additionalType === additionalType
      }
      return (document.type && optionalCondition &&
      documentTypesFilter.map((type: DocumentType) => type.typeName
      ).includes(document.type)
      )
    }).length || 0
  }

  return (
    <div>
      <div>
        <span className='text-gris'>
          <FormattedMessage id='overview_appeal_details_title' />
        </span>
        <br />
        {
            transaction.appeal?.appealDecommission &&
              <>
                {transaction.appeal.appealDecommission}<br />
              </>
        }
        <div>
          <FormattedMessage id='overview_appeal_details_document' />
          {` : ${transaction.documents?.filter((document: DepositDocument) => document.type === DOCUMENT_TYPES.DEPOSIT_CORRECTION_SUPPORT).length} `}
          <FormattedMessage id='inscription_form_documents_attached_files' />
        </div>
      </div>
      <div className='mt-3'>
        {
          documentTypeSupporting && countDocumentInTheseTypes(documentTypeSupporting, APPEAL_TYPE_SUPPORTING_DOCUMENT.value) > 0 &&
            <div>
              <FormattedMessage id='appeal_document_supporting_label' />
              {` : ${countDocumentInTheseTypes(documentTypeSupporting, APPEAL_TYPE_SUPPORTING_DOCUMENT.value)} `}
              <FormattedMessage id='inscription_form_documents_attached_files' />
            </div>
        }
        {
          documentTypeActe && countDocumentInTheseTypes(documentTypeActe, APPEAL_TYPE_ACT.value) > 0 &&
            <div>
              <FormattedMessage id='appeal_document_acte_label' />
              {` : ${countDocumentInTheseTypes(documentTypeActe, APPEAL_TYPE_ACT.value)} `}
              <FormattedMessage id='inscription_form_documents_attached_files' />
            </div>
        }
      </div>

      {
        transaction.appeal && transaction.appeal.inscriptionRequests && transaction.appeal.inscriptionRequests.length > 0 &&
            transaction.appeal.inscriptionRequests[0].numOrder &&
              <div className='mt-3'>
                <FormattedMessage id='appeal_with_inscription_requests_recap' />
                {transaction.appeal.inscriptionRequests[0].numOrder}
              </div>

      }
    </div>
  )
}

export default OverviewAppealDetails
