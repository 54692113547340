import React, { FC } from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import OfficialDocumentForm from './form/OfficialDocumentForm'
import Payment from '../payment/Payment'
import { PAYMENT_URL_OFFICIAL_DOCUMENT } from '../../constants/PaymentConstants'

const OfficialDocumentRoutes: FC<RouteComponentProps> = ({
  match
}) => (
  <Switch>
    <Route path={`${match.url}/nouveau`} render={() => <OfficialDocumentForm isNew />} />
    <Route path={`${match.url}/:id/edition`} component={OfficialDocumentForm} />
    <Route
      path={`${match.url}/:id/paiement/:state`}
      render={(props) => <Payment id={props.match.params.id} state={props.match.params.state} matchUrl={PAYMENT_URL_OFFICIAL_DOCUMENT} />}
    />
  </Switch>
)

export default OfficialDocumentRoutes
