import { RequestType } from '../../interfaces/RequestInterfaces'
import { TransactionCountByStatus } from '@inpi-dm/components'

class RequestsService {
    /**
     * Calcule la somme des dépôts trouvés en les regroupant par
     * les status définis dans le type de demande fourni
     *
     * @param type Type de demande
     * @param depositCounts Objet associant un status au nombre de dépôt sous ce même status
     */
    sumCountByStatus = (type: RequestType, depositCounts: TransactionCountByStatus[]) => {
      let sum = 0

      depositCounts.filter(statusCount => type.statusFiltres.includes(statusCount.status))
        .forEach(statusCount => {
          sum = sum + statusCount.count
        })

      return sum
    }
}

export default new RequestsService()
