import React, { FC, ReactNode } from 'react'
import { Modal } from 'react-bootstrap'
import { FormattedMessage, IntlProvider } from 'react-intl'
import Message from '../constants/Message'
import { SubmitButton } from '../index'

interface ModalProps {
    title: any,
    show: boolean,
    handleClose: () => void,
    onClick?: () => void,
    titleContent?: any,
    customContent?: () => any,
    size?: 'sm' | 'lg' | 'xl' | undefined,
    hideFooter?: boolean | null
    customButtonId?: string | undefined,
    isNotCancellable?: boolean | undefined,
    validateLabel?: string | ReactNode,
    dialogClassName?: string | undefined,
    hideHeader?: boolean | undefined,
    link?: string,
    linkLabel?: string | ReactNode
}

/**
 * Création d'une modale customisable
 * @param show - Valeur qui définie si la modale doit être affichée
 * @param handleClose - CallBack appelé quand la modale doit se fermer
 * @param onClick
 * @param size - Taille de la modale
 * @param titleContent - Titre du content
 * @param customContent - Body de la modale customisable
 * @param title - Titre de la modale
 * @param hideFooter - Affichage le footer
 * @param customButtonId - Nom personnalisé du bouton de validation
 * @param dialogClassName
 * @param validateLabel
 * @param isNotCancellable
 * @param hideHeader
 * @param link
 * @param linkLabel
 */
const ModalComponent: FC<ModalProps> = ({
  show,
  handleClose,
  onClick,
  size,
  titleContent,
  customContent,
  hideFooter,
  title,
  customButtonId,
  dialogClassName,
  validateLabel,
  isNotCancellable = false,
  hideHeader = false,
  link,
  linkLabel
}) => (
  <IntlProvider locale='fr' messages={Message}>
    <Modal dialogClassName={dialogClassName} show={show} onHide={handleClose} size={size || undefined} scrollable backdrop={isNotCancellable ? 'static' : undefined}>
      {!hideHeader &&
        <Modal.Header closeButton className='bg-primary text-white border-bottom-0'>
          <Modal.Title>
            {title}
          </Modal.Title>
        </Modal.Header>}
      <Modal.Body>
        {titleContent && <label className='form-label'>{titleContent}</label>}
        {customContent && customContent()}
      </Modal.Body>
      {
        !hideFooter &&
          <Modal.Footer className='d-block'>
            <div className='d-flex justify-content-around'>
              <SubmitButton className='btn-primary' onClick={onClick}>
                {validateLabel || <FormattedMessage id={customButtonId || 'common_validate'} />}
              </SubmitButton>
            </div>
            {linkLabel && link &&
              <div className='d-flex justify-content-around'>
                <span className='cursor-pointer text-primary' onClick={() => window.open(link, '_blank')}>{linkLabel}</span>
              </div>}
          </Modal.Footer>
      }
    </Modal>
  </IntlProvider>
)

export default ModalComponent
