import React, { FC, memo, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

interface PreviewProps {
  file: File | (() => Promise<string>) | string | Promise<string>
  filename?: string,
  className?: string,
  onClick?: () => void,
  refreshAction?: boolean
}

const Preview: FC<PreviewProps> = ({
  file,
  filename,
  className,
  onClick,
  refreshAction
}) => {
  const [preview, setPreview] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const mounted = useRef(false)

  const initPreview = (image: string) => {
    mounted.current = true
    setPreview(image)
    setLoading(false)
  }

  useEffect(() => {
    if (loading) {
      return
    }

    // Création d'une preview de l'image
    setError(false)
    setLoading(true)
    if ((typeof file === 'object' && typeof file.then === 'function')) {
      file.then((r) => {
        if (!mounted.current) {
          initPreview(r)
        }
      }
      ).catch(() => {
        if (!mounted.current) {
          setLoading(false)
          setError(true)
        }
      })
    }
    // Si file est une fonction, on fetch l'image
    if (typeof file === 'function') {
      file().then((r) => {
        if (!mounted.current) {
          initPreview(r)
        }
      }
      ).catch(() => {
        if (!mounted.current) {
          setLoading(false)
          setError(true)
        }
      })

      // Si file est un string, on part du principe que soit c'est une URL
      // soit une image en base 64
    } else if (typeof file === 'string') {
      if (!mounted.current) {
        initPreview(file)
      }
      // Sinon on considère que c'est un fichier provenant de l'utilisateur
    } else if (!file.preview) {
      try {
        if (!mounted.current) {
          initPreview(URL.createObjectURL(file))
        }
      } catch (error) { }
    } else {
      if (!mounted.current) {
        initPreview(file.preview)
      }
    }

    // Suppression de la preview lors de la destruction du composant
    return () => {
      URL.revokeObjectURL(preview)
      mounted.current = false
    }
  }, [typeof file, typeof file === 'object' && file.name, filename, refreshAction])

  return loading ? (
    <FontAwesomeIcon icon={faSpinner} className='loader my-auto' />
  ) : (
    error
      ? <FontAwesomeIcon icon={faTimesCircle} className='text-danger my-auto' />
      : <img className={`${className || ''}`} src={preview} alt={filename || file.name || ''} onClick={onClick} />
  )
}

export default memo(Preview)
