import React, { FC } from 'react'
import {
  Transaction, RequestListTable, TransactionRow
} from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'
import { SELECT_TRANSACTION_TABLE_HEADER } from '../constants/TransactionConstants'

interface ModalSelectTransactionProps {
    transactions: Transaction[],
    selectTransaction: (transaction: Transaction) => void,
    setShowModalSelectTransaction: (showModalSelectTransaction: boolean) => void,
}

const ModalSelectTransaction: FC<ModalSelectTransactionProps> = ({
  transactions,
  selectTransaction,
  setShowModalSelectTransaction
}) => {
  return (
    <div>
      {transactions && transactions.length > 0 && (
        <div>
          <span>{transactions.length}<FormattedMessage id='transaction_selection_modal_select_transaction_subtitle' /></span>
          <RequestListTable
            tableTitle={SELECT_TRANSACTION_TABLE_HEADER}
          >
            <tbody>
              {transactions.map((transaction: Transaction, index: number) => (
                <TransactionRow
                  key={index}
                  transaction={transaction}
                  selectTransaction={selectTransaction}
                  setShowModalSelectTransaction={setShowModalSelectTransaction}
                />
              ))}
            </tbody>
          </RequestListTable>
        </div>)}
    </div>
  )
}

export default ModalSelectTransaction
