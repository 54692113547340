import http, { DOWNLOAD_FILE } from '../../network/http-common'
import { toast } from 'react-toastify'
import { Transaction } from '@inpi-dm/components'
import { AnswerNotification, NotificationInterface } from '@inpi-dm/components/src/interfaces/DepositInterfaces'
/* global FormData */

/**
 * Classe permettant de faire des opérations sur une notification
 */
class NotificationService {
  /**
   * Créée une notification répondue d'un type
   * @param idTransaction
   * @param type
   * @param params
   */
  postAnsweredNotification = async (idTransaction: string, type: string, params: any = null): Promise<NotificationInterface> => {
    try {
      return await http.post(`/api/transactions/${idTransaction}/notifications-answered/${type}`, params)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Télécharge la notification au format pdf
   * @param idTransaction
   * @param idNotification
   */
  downloadNotification = async (idTransaction: string, idNotification: string): Promise<Blob> => {
    try {
      return await http.get(`/api/transactions/${idTransaction}/notifications/${idNotification}/file`, { responseType: 'blob' })
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Upload un fichier de notification pour le dépôt courant
   * @param idTransaction
   * @param idNotification Id de la notification contenant le fichier
   * @param file Le fichier à uploader
   */
  uploadNotificationFile = async (idTransaction: string, idNotification: string, file: File): Promise<Transaction> => {
    try {
      const formData = new FormData()
      formData.append('file', file)

      return await http.post(`/api/transactions/${idTransaction}/notifications/${idNotification}/files`, formData)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Télécharge un fichier d'une notification d'un dépôt
   * @param idTransaction
   * @param idNotification
   * @param internalName
   */
  downloadNotificationFile = async (idTransaction: string, idNotification: string, internalName: string): Promise<Blob> => {
    try {
      return await http.get(`/api/transactions/${idTransaction}/notifications/${idNotification}/files/${internalName}`, DOWNLOAD_FILE)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Répond à une notification
   * @param idTransaction
   * @param idNotification
   * @param answerNotification
   */
  answerNotification = async (idTransaction: string, idNotification: string, answerNotification: AnswerNotification): Promise<NotificationInterface> => {
    try {
      return await http.post(`/api/transactions/${idTransaction}/notifications/${idNotification}/answer`, answerNotification)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  deleteNotificationFile = async (idTransaction: string, idNotification: string, internalName: string) => {
    try {
      return await http.delete(`/api/transactions/${idTransaction}/notifications/${idNotification}/files/${internalName}`)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }
}

export default new NotificationService()
