import React, { FC } from 'react'
import { DepositFile, EventType, FieldStatus, FileBrowserField, SelectField, TextField } from '@inpi-dm/components'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { DOCUMENT_COMMUNICABLE_OPTIONS } from '../../../../constants/DocumentConstants'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface InscriptionDocumentsItemProps extends WrappedComponentProps {
    fileInputId: string,
    selectInputId: string,
    labelInputId?: string | undefined,
    maxFileCount?: number | undefined,
    hasLabel?: boolean | undefined,
    files?: File[] | DepositFile[] | undefined,
    fileOnChange: (event: EventType) => void,
    handleDelete: () => void,
    fieldStatus?: FieldStatus|undefined,
    labelChange?: (event: EventType) => void,
    labelValue?: string | undefined,
    communicabilityValue?: string | undefined,
    communicabilityChange: (event: EventType) => void
}

const InscriptionDocumentsItem: FC<InscriptionDocumentsItemProps> = ({
  intl,
  fileInputId,
  maxFileCount,
  selectInputId,
  labelInputId,
  hasLabel,
  files,
  fileOnChange,
  handleDelete,
  fieldStatus,
  labelChange,
  labelValue,
  communicabilityValue,
  communicabilityChange
}) => {
  return (
    <div className='d-flex'>
      {hasLabel && labelInputId &&
        <div className='col-4 pl-0 mt-2'>
          <TextField
            value={labelValue}
            inputId={labelInputId}
            onChange={labelChange}
            fieldStatus={fieldStatus}
          />
        </div>}
      <div className='col-4 pl-0 mt-2'>
        <FileBrowserField
          value={files}
          onChange={fileOnChange}
          fieldStatus={fieldStatus}
          inputId={fileInputId}
          maxNumberFile={maxFileCount}
          acceptApplication='application/pdf'
          butonLabel={
            <div className='form-control placeholder'>
              <FormattedMessage id='button_find_file' />
            </div>
          }
        />
      </div>
      <div className='col-4 pl-0 mt-2'>
        <SelectField
          value={communicabilityValue}
          onChange={communicabilityChange}
          inputId={selectInputId}
          options={DOCUMENT_COMMUNICABLE_OPTIONS}
          placeholder={intl.formatMessage({ id: 'select_placeholder' })}
          fieldStatus={fieldStatus}
          required
        />
      </div>
      {files &&
        <span className='icon-delete cursor-pointer text-danger pt-3' onClick={() => handleDelete()}>
          <FontAwesomeIcon icon={faTrash} />
        </span>}
    </div>
  )
}

export default injectIntl(InscriptionDocumentsItem)
