import React, { FC, useState, useEffect } from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import {
  FieldStatus,
  isFilled,
  Publication,
  SubmitButton,
  Title,
  containsErrors, APPEAL_TYPE
} from '@inpi-dm/components'
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux'
import SearchAndManualRecordsForm from '../../../title/SearchAndManualRecordsForm'
import SelectRecordFormValidator from './validator/SelectRecordFormValidator'
import { storeContributorsTitleUpdate } from '../../../../store/contributorsTitle/contributorsTitleActions'
import { RECORD_APPEAL_INFORMATION_FROM_SELECT } from '../../../../constants/AppealConstants'

interface SelectRecordsFormProps extends WrappedComponentProps {
  initialTitle?: Title
  initialFieldStatus?: FieldStatus,
  onSubmit?: (title: Title, fieldStatus: FieldStatus) => void,
}

const SelectAppealRecordsForm: FC<SelectRecordsFormProps> = ({
  initialTitle,
  initialFieldStatus,
  onSubmit

}) => {
  const dispatch = useDispatch()
  const [title, setTitle] = useState<Title>(initialTitle || {})
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>(initialFieldStatus || {})
  const [currentContributors, setCurrentContributors] = useState({})
  const [publicationsIds, setPublicationsIds] = useState<string[]>([])
  const infosByNumNat = useSelector((state: RootStateOrAny) => state.contributorsTitle)

  useEffect(() => {
    if (initialTitle?.publications && initialTitle.publications.length > 0) {
      const publicationString = initialTitle.publications.map((publication : Publication) => {
        if (publication.idPublication) {
          return publication.idPublication
        }
        return ''
      })
      setPublicationsIds(publicationString)
    }
  }, [])

  useEffect(() => {
    initialFieldStatus && setFieldStatus(initialFieldStatus)
  }, [initialFieldStatus])

  /**
   * Vérifie que les champs obligatoire soient remplis,
   * enregistre dans le store les titres et ferme le formulaire
   */
  const submitForm = () => {
    // Enlever les publications vides
    const updateTitle = { ...title }
    const newPublication = title.publications?.filter((publication: Publication) => isFilled(publication.idPublication)) || []
    updateTitle.publications = [...newPublication]
    setTitle(updateTitle)
    // Récupérer toutes les publications et les mettre ensemble dans title
    const fieldStatusError = SelectRecordFormValidator.validateMandatory(updateTitle)
    setFieldStatus(fieldStatusError)

    if (!containsErrors(fieldStatusError)) {
      if (currentContributors) {
        if (initialTitle?.numNat && infosByNumNat[initialTitle?.numNat]) {
          delete infosByNumNat[initialTitle?.numNat]
        }
        // On enregistre les intervenants dans le store pour pouvoir les proposer lors des formulaires des intervenants
        dispatch(storeContributorsTitleUpdate({
          ...infosByNumNat,
          [title.numNat]: currentContributors
        }))
      }
      onSubmit && onSubmit(updateTitle, fieldStatusError)
    }
  }

  return (
    <>
      <hr />
      <SearchAndManualRecordsForm
        title={title}
        setTitle={setTitle}
        publicationsIds={publicationsIds}
        setPublicationsIds={setPublicationsIds}
        fieldStatus={fieldStatus}
        setFieldStatus={setFieldStatus}
        setCurrentContributors={setCurrentContributors}
        optionSearch={RECORD_APPEAL_INFORMATION_FROM_SELECT}
        originShown={false}
        classNameCol='col-12 col-md-4'
        procedure={APPEAL_TYPE.value}
      />
      <div className='row m-0 d-flex justify-content-end mt-5'>
        {onSubmit &&
          <SubmitButton
            onClick={submitForm}
            className='btn-outline-primary m-2'
          >
            <FormattedMessage id='records_inscription_submit_form' />
          </SubmitButton>}
      </div>
    </>
  )
}

export default injectIntl(SelectAppealRecordsForm)
