import { isFilled, isMailFormatValid } from '@inpi-dm/components'
import Message from '../../../constants/Message'

/**
 * Valide la présence d'un login email.
 */
export function validateUsername (user: any): string {
  const { username } = user

  if (!isFilled(username)) {
    return Message.required_field
  }

  if (!isMailFormatValid(username)) {
    return Message.error_email_format
  }

  return ''
}

/**
 * Valide la présence d'un mot de passe
 */
export function validatePassword (user: any): string {
  const { password } = user

  if (!isFilled(password)) {
    return Message.required_field
  }

  return ''
}
