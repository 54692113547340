import React, { FC, useEffect, useState } from 'react'
import {
  containsErrors,
  DATE_ISO,
  DateUtils,
  DEFAULT_TITLE_INSCRIPTION,
  DepositDocument,
  FilAriane,
  INSCRIPTION_CORRECTION,
  INSCRIPTION_DISCLAIMER,
  INSCRIPTION_OWNERSHIP,
  INSCRIPTION_TYPE,
  Transaction,
  Publication
  , ContributorTitle, ModalComponent
} from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import StepContainer from '../../stepper/StepContainer'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import SelectInscriptionType from './inscriptionType/SelectInscriptionType'
import {
  storeInscriptionFieldStatusReset,
  storeInscriptionFieldStatusUpdate,
  storeInscriptionRemove,
  storeInscriptionUpdate
} from '../../../store/inscription/inscriptionActions'
import SelectInscriptionTypeValidator from './inscriptionType/validator/SelectInscriptionTypeValidator'
import InscriptionService from '../../../services/transaction/InscriptionService'
import TransactionService from '../../../services/transaction/TransactionService'
import ApplicantValidator from './applicant/validator/ApplicantValidator'
import AgentProposalForm from '../../intervenant/AgentProposalForm'
import AgentValidator from './agent/validator/AgentValidator'
import RecipientForm from '../../intervenant/RecipientForm'
import RecipientValidator from './recipient/validator/RecipientValidator'
import SignatoryForm from '../../intervenant/SignatoryForm'
import ApplicantListForm from '../../intervenant/ApplicantListForm'
import SignatoryValidator from './signatory/validator/SignatoryValidator'
import OtherApplicantValidator from './otherApplicant/validator/OtherApplicantValidator'
import CorrectionForm from './correction/CorrectionForm'
import OwnershipForm from './ownership/OwnershipForm'
import PaymentInscription from './payment/PaymentInscription'
import SelectRecordFormValidator from './records/validator/SelectRecordFormValidator'
import CorrectionValidator from './correction/validator/CorrectionValidator'
import OwnershipValidator from './ownership/validator/OwnershipValidator'
import OverviewFormInscription from './overview/OverviewFormInscription'
import qs from 'qs'
import OverviewFormInscriptionValidator from './overview/validator/OverviewFormInscriptionValidator'
import Disclaimer from './disclaimer/Disclaimer'
import DisclaimerFormValidator from './disclaimer/validator/DisclaimerFormValidator'
import { storeContributorsTitleRemove, storeContributorsTitleUpdate } from '../../../store/contributorsTitle/contributorsTitleActions'
import RecordService from '../../../services/record/RecordService'
import IntervenantService from '../../../services/transaction/IntervenantService'
import SelectInscriptionRecords from './records/SelectInscriptionRecords'
import { InscriptionStoreAction } from '../../../store/inscription/inscriptionStoreTypes'
import ContentService from '../../../services/content/ContentService'

interface InscriptionFormProps extends RouteComponentProps<{ id: string }> {
  isNew?: boolean,
  isDisclaimer?: boolean
}

const InscriptionForm: FC<InscriptionFormProps> = ({
  isNew = false,
  isDisclaimer = false,
  match,
  history,
  location
}) => {
  const dispatch = useDispatch()
  const inscription: Transaction = useSelector((state: RootStateOrAny) => state.inscription)
  const inscriptionFieldStatus = useSelector((state: RootStateOrAny) => state.inscriptionFieldStatus) || []
  const infosByNumNat = useSelector((state: RootStateOrAny) => state.contributorsTitle)
  const [inscriptionProofDocuments, setInscriptionProofDocuments] = useState<DepositDocument[][]>([])
  const [flatContributorsTitle, setFlatContributorsTitle] = useState<ContributorTitle[]>([])

  const [isNewInscription, setIsNewInscription] = useState(isNew)
  const [idRecords, setIdRecords] = useState([])
  const [showRgpd, setShowRgpd] = useState(false)
  const [rgpdContent, setRdpdContent] = useState('')

  // Initialisation de l'inscription à modifier
  useEffect(() => {
    dispatch(storeInscriptionFieldStatusReset())
    dispatch(storeInscriptionRemove())
    dispatch(storeContributorsTitleRemove())
    if (!isNew) {
      TransactionService.getTransaction(match.params.id).then(result => {
        dispatch(storeInscriptionUpdate(result))
      })
    } else {
      // GAP 8515: BO - ADMIN - Menu RGPD - renonciations
      const response = isDisclaimer ? ContentService.getEditorialBlockByCode('RGPD-REN') : ContentService.getEditorialBlockByCode('RGPD-INS')

      response.then((res) => {
        if (res.content) {
          setRdpdContent(res.content)
          setShowRgpd(true)
        }
      })
    }

    // Si l'inscription a été faite à partir d'un ou plusieurs titre(s), on récupère le(s) id(s) dans l'url
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (query.from) {
      setIdRecords(query.from.split(','))
    }
  }, [isNew])

  useEffect(() => {
    if (isDisclaimer) {
      dispatch(storeInscriptionUpdate({ ...inscription, subProcedureType: INSCRIPTION_DISCLAIMER.code }))
      saveInscriptionType()
    }
  }, [isDisclaimer])

  useEffect(() => {
    const newProofDocuments = inscriptionProofDocuments
    if (inscription.documents && inscription.documents.length > 0) {
      inscription.documents.forEach(doc => newProofDocuments.push([formatDocument(doc)]))
    }
    setInscriptionProofDocuments(newProofDocuments)
  }, [inscription.documents])

  useEffect(() => {
    setFlatContributorsTitle(IntervenantService.getFlatContributorsTitle())
  }, [infosByNumNat])

  const formatDocument = (doc: DepositDocument) => {
    return { ...doc, file: { name: doc.name, size: doc.size, type: doc.type } }
  }

  /**
   * Si la dernière étape est valide, on submit le formulaire
   */
  const handleSubmit = () => {
    history.push('/')
  }

  /**
   * Ajoute des titres de record à l'inscription en cours
   * @param idRecords
   */
  const addTitlesToInscription = async (idRecords: string[]): Promise<InscriptionStoreAction | void> => {
    const fieldStatus = []
    let newContributorsTitle: [] = []
    let recordPublications: Publication[] = []
    const newTitlePromise = idRecords.map(async (idRecord: string) => {
      return RecordService.getRecord(idRecord).then(record => {
        recordPublications = RecordService.getPublications(record)
        fieldStatus.push({ titles: recordPublications.map(() => 'success') })
        if (record.numNat) {
          newContributorsTitle = {
            ...newContributorsTitle,
            [record.numNat]: {
              depositor: record.depositor,
              holders: record.holders,
              recipient: record.recipient
            }
          }
        }
        return {
          ...DEFAULT_TITLE_INSCRIPTION,
          depositDate: DateUtils.formateDateToFormat(record.depositDate, DATE_ISO),
          numNat: record.numNat,
          publications: recordPublications
        }
      })
    })
    return Promise.all(newTitlePromise)
      .then(newTitles => {
        dispatch(storeInscriptionUpdate({ ...inscription, inscription: { titles: newTitles } }))
        dispatch(storeContributorsTitleUpdate(newContributorsTitle))
        dispatch(storeInscriptionFieldStatusUpdate(fieldStatus))
      })
  }

  /**
   * Sauvegarde du type d'inscription
   */
  const saveInscriptionType = () => {
    if (isNewInscription) {
      setIsNewInscription(false)
      // Creation puis sauvegarde
      return InscriptionService.createInscription().then(() => {
        return InscriptionService.updateInscriptionBDDFromStore(['subProcedureType', 'inscriptionSuite']).then(() => {
          if (idRecords.length > 0) {
            return addTitlesToInscription(idRecords)
          }
        })
      })
    } else {
      // sauvegarde
      return InscriptionService.updateInscriptionBDDFromStore(['subProcedureType', 'inscriptionSuite'])
    }
  }

  /** Vérifie que tous les champs obligatoires sont remplis pour une étape du menu**/
  const checkValidity = (stepName: string): boolean => {
    const status = inscriptionFieldStatus[stepName]
    if (status === undefined) {
      return false
    }
    return !containsErrors(status)
  }

  return (
    <>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id='breadcrumb_new_inscription' /></span>
      </FilAriane>

      <StepContainer
        className='mb-5 is-validated'
        listTitle={<FormattedMessage id='inscription_step_list_title' />}
        onCancel={() => history.push('/')}
        onSubmit={handleSubmit}
        views={[
          {
            id: 'type_inscription',
            label: <FormattedMessage id='select_inscription_type_title' />,
            badge: '1',
            validateGoToStep: () => SelectInscriptionTypeValidator.validateOnChangeStep(idRecords),
            onGoToStep: saveInscriptionType,
            component: <SelectInscriptionType />,
            condition: !isDisclaimer,
            isValidated: checkValidity('subProcedureType')
          }, {
            id: 'titles',
            label: <FormattedMessage id='records_inscription' />,
            badge: '2',
            validateGoToStep: SelectRecordFormValidator.validateOnChangeStep,
            component: <SelectInscriptionRecords />,
            condition: !isDisclaimer,
            onGoToStep: () => InscriptionService.updateInscriptionBDDFromStore(['titles']),
            isValidated: checkValidity('titles')
          }, {
            id: 'disclaimer',
            label: <FormattedMessage id='disclaimer_inscription' />,
            badge: !isDisclaimer ? '2' : '1',
            validateGoToStep: () => DisclaimerFormValidator.validateOnChangeStep(inscriptionProofDocuments),
            component: <Disclaimer inscriptionProofDocuments={inscriptionProofDocuments} setInscriptionProofDocuments={setInscriptionProofDocuments} />,
            condition: isDisclaimer,
            onGoToStep: () => InscriptionService.updateInscriptionBDDFromStore(['titles', 'natureCodes'], inscriptionProofDocuments),
            isValidated: checkValidity('titles')
          }, {
            id: 'intervenant',
            label: <FormattedMessage id='intervenant_form_title' />,
            badge: !isDisclaimer ? '3' : '2',
            isValidated: checkValidity('signatory')
          }, {
            id: 'intervenant_applicants',
            label: <FormattedMessage id='intervenant_applicants' />,
            level: 1,
            validateGoToStep: ApplicantValidator.validateOnChangeStep,
            component: (
              <ApplicantListForm
                object={inscription}
                procedureType={INSCRIPTION_TYPE.value}
                applicantList={inscription.applicants || []}
                fieldStatus={inscriptionFieldStatus.applicants || []}
                defaultIntervenantlist={flatContributorsTitle}
              />
            ),
            onGoToStep: () => InscriptionService.updateInscriptionBDDFromStore(['applicants']),
            stepButtonsAsChildren: true
          }, {
            id: 'intervenant_other',
            label: <FormattedMessage id='intervenant_other' />,
            level: 1,
            validateGoToStep: OtherApplicantValidator.validateOnChangeStep,
            component: (
              <ApplicantListForm
                object={inscription}
                procedureType={INSCRIPTION_TYPE.value}
                applicantList={inscription.otherApplicants || []}
                fieldStatus={inscriptionFieldStatus.otherApplicants || []}
                defaultIntervenantlist={flatContributorsTitle}
                isOtherApplicant
              />
            ),
            condition: inscription.subProcedureType === INSCRIPTION_OWNERSHIP.code,
            onGoToStep: () => InscriptionService.updateInscriptionBDDFromStore(['otherApplicants']),
            stepButtonsAsChildren: true
          }, {
            id: 'intervenant_destinataire',
            label: <FormattedMessage id='intervenant_onglet_destinataire' />,
            level: 1,
            validateGoToStep: RecipientValidator.validateOnChangeStep,
            component: (
              <RecipientForm
                object={inscription}
                fieldStatus={inscriptionFieldStatus}
                procedureType={INSCRIPTION_TYPE.value}
                findIntervenantsList={['applicants', 'agent', 'recipient']}
                defaultIntervenantlist={flatContributorsTitle}
              />
            ),
            onGoToStep: () => InscriptionService.updateInscriptionBDDFromStore(['recipient'])
          }, {
            id: 'intervenant_mandataire',
            label: <FormattedMessage id='intervenant_onglet_mandataire' />,
            level: 1,
            validateGoToStep: AgentValidator.validateOnChangeStep,
            component: (
              <AgentProposalForm
                object={inscription}
                fieldStatus={inscriptionFieldStatus}
                procedureType={INSCRIPTION_TYPE.value}
                findIntervenantsList={['applicants', 'recipient']}
                defaultIntervenantlist={flatContributorsTitle}
                viewFormInit={false}
                isDeletable
                idTransaction={inscription.id}
              />
            ),
            onGoToStep: () => InscriptionService.updateInscriptionBDDFromStore(['agent'])
          }, {
            id: 'intervenant_signatory',
            label: <FormattedMessage id='intervenant_onglet_signatory' />,
            level: 1,
            validateGoToStep: SignatoryValidator.validateOnChangeStep,
            component: <SignatoryForm object={inscription} fieldStatus={inscriptionFieldStatus} procedureType={INSCRIPTION_TYPE.value} />,
            onGoToStep: () => InscriptionService.updateInscriptionBDDFromStore(['signatory'])
          }, {
            id: 'correction',
            label: <FormattedMessage id='select_inscription_correction_title' />,
            badge: '4',
            validateGoToStep: () => CorrectionValidator.validateOnChangeStep(inscriptionProofDocuments),
            component: (
              <CorrectionForm
                transaction={inscription}
                procedureType={INSCRIPTION_TYPE.value}
                inscriptionProofDocuments={inscriptionProofDocuments}
                setInscriptionProofDocuments={setInscriptionProofDocuments}
              />
            ),
            condition: inscription.subProcedureType === INSCRIPTION_CORRECTION.code,
            onGoToStep: () => InscriptionService.updateInscriptionBDDFromStore(['natureCodes', 'changeText', 'byText', 'comment', 'isDepositPartRectified', 'isActPartRectified', 'actPartRectifiedDate', 'actPartRectifiedNumber', 'isProrogationDeclaration', 'prorogationDeclarationNumber'], inscriptionProofDocuments),
            isValidated: checkValidity('natureCodes')
          }, {
            id: 'acte',
            label: <FormattedMessage id='select_inscription_ownership_title' />,
            badge: '4',
            validateGoToStep: () => OwnershipValidator.validateOnChangeStep(inscriptionProofDocuments),
            component: (
              <OwnershipForm
                transaction={inscription}
                procedureType={INSCRIPTION_TYPE.value}
                inscriptionProofDocuments={inscriptionProofDocuments}
                setInscriptionProofDocuments={setInscriptionProofDocuments}
              />
            ),
            condition: inscription.subProcedureType === INSCRIPTION_OWNERSHIP.code,
            onGoToStep: () => InscriptionService.updateInscriptionBDDFromStore(['natureCodes', 'isAppealOrDecommission', 'isProrogation'], inscriptionProofDocuments),
            isValidated: checkValidity('natureCodes')
          }, {
            id: 'overview',
            label: <FormattedMessage id='overview_deposit_form_short_title' />,
            badge: inscription.subProcedureType ? (inscription.subProcedureType === INSCRIPTION_DISCLAIMER.code ? '3' : '5') : '4',
            validateGoToStep: OverviewFormInscriptionValidator.validateOnChangeStep,
            component: <OverviewFormInscription />,
            isValidated: checkValidity('overviewSimilarInformation')
          }, {
            id: 'payment',
            label: <FormattedMessage id='payment' />,
            badge: inscription.subProcedureType ? (inscription.subProcedureType === INSCRIPTION_DISCLAIMER.code ? '4' : '6') : '5',
            component: <PaymentInscription transaction={inscription} />,
            stepButtonsAsChildren: true
          }
        ]}
      />
      <ModalComponent
        title={<FormattedMessage id='rgpd_title' />}
        isNotCancellable
        customContent={() => <div dangerouslySetInnerHTML={{ __html: rgpdContent }} />}
        handleClose={() => setShowRgpd(false)}
        show={showRgpd}
        onClick={() => setShowRgpd(false)}
        customButtonId='common_start_form'
        size='xl'
      />
    </>
  )
}

export default withRouter(InscriptionForm)
