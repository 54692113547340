import React, { useState, useEffect } from '../../../node_modules/react'
import { toast } from 'react-toastify'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import {
  CardBlock,
  EventType,
  FieldStatus,
  SubmitButton,
  SelectField,
  isFilled,
  RequestListTable,
  ModalComponent,
  NoResult,
  Transaction
} from '@inpi-dm/components'
import ETSAccountService from '../../services/transaction/ETSAccountService'
import { ETSAccountNavInterface } from '../../interfaces/ETSAccountInterface'
import Message from '../../constants/Message'
import { TABLE_TITLE_ASSIGN } from '../../constants/RequestConstants'
import ToAssignTableBody from './ToAssignTableBody'

import { FoUserResponse } from '../../interfaces/User'
import AssignPicker from './AssignPicker'

export interface ETSAccountPMInterface extends WrappedComponentProps {
  ETSAccountRequestNavItem: ETSAccountNavInterface
}

const ETSAccountPM = (props: ETSAccountPMInterface) => {
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>({})
  const [currentETSAccount, setCurrentETSAccount] = useState('')
  const [newETSAccount, setNewETSAccount] = useState('')
  const [transactions, setTransactions] = useState<Transaction[]>()
  const [checkedDeposit, setCheckedDeposit] = useState<Transaction[]>([])
  const [showAssignModal, setShowAssignModal] = useState(false)
  const [users, setUsers] = useState<FoUserResponse[]>(false)

  const {
    ETSAccountRequestNavItem
  } = props

  useEffect(() => {
    // Récupération des PP attachées à la PM
    ETSAccountService.getETSAccountForPM().then(result => {
      setUsers(result.map((r: FoUserResponse) => {
        return {
          label: `${r.firstname} ${r.lastname}  (${r.email})`,
          value: r.email
        }
      }))
    })
  }, [])

  const chooseUser = async () => {
    if (!isFilled(currentETSAccount)) {
      return setFieldStatus({ ...fieldStatus, currentETSAccount: Message.required_field })
    } else {
      setFieldStatus({})

      // Récupération des transactions liées au compte ETS sélectionné
      return ETSAccountService.getETSAccountTransactions(currentETSAccount).then(setTransactions)
    }
  }

  const submitAssign = () => {
    if (!isFilled(newETSAccount)) {
      return setFieldStatus({ ...fieldStatus, assign: Message.required_field })
    } else {
      return ETSAccountService.assignDeposit(newETSAccount, checkedDeposit).then(() => {
        toast.success(props.intl.formatMessage({ id: 'ets_account_request_success_request' }))
        setShowAssignModal(false)
        // Mise à jour des transactions attachées au compte
        ETSAccountService.getETSAccountTransactions(currentETSAccount).then(setTransactions)
      })
    }
  }

  /**
   * Ajoute ou supprime tous les dépôts affichés dans la liste
   * des dépôts sélectionnés
   * @param isAdd
   */
  const handleCheckAllDeposit = (isAdd: boolean) => {
    let updatedChecked = [...checkedDeposit]
    const depositIds = transactions.map(d => d.id)

    if (isAdd) {
      updatedChecked.push(
        ...depositIds.filter(id => !checkedDeposit.includes(id))
      )
    } else {
      updatedChecked = updatedChecked.filter(id => !depositIds.includes(id))
    }

    setCheckedDeposit(updatedChecked)
  }

  /**
   * Ajoute ou supprime un dépôt dans la liste des dépôts
   * sélectionnés
   * @param id
   */
  const handleCheckDeposit = (id: string) => {
    const updatedChecked = [...checkedDeposit]

    const indexId = updatedChecked.indexOf(id)
    if (indexId > -1) {
      updatedChecked.splice(indexId, 1)
    } else {
      updatedChecked.push(id)
    }

    setCheckedDeposit(updatedChecked)
  }

  /**
   * Vérifie que tous les dépôts affichés sont checkés
   */
  const areAllDepositChecked = () => {
    if (transactions.length === 0) {
      return false
    }

    return transactions.map((t: Transaction) => t.id)
      .filter((id: string) => !checkedDeposit.includes(id))
      .length === 0
  }

  return (
    <>
      <div className='d-flex mt-4 justify-content-center'>
        <CardBlock
          className='col-12 col-xl-6 p-0'
          header={ETSAccountRequestNavItem.label}
          shadow
        >
          <div className='is-validated row w-100'>
            <div className='col-12'>
              <SelectField
                label={<FormattedMessage id='ets_account_request_pm_current_account' />}
                inputId='currentETSAccount'
                placeholder={props.intl.formatMessage({ id: 'select_placeholder' })}
                value={currentETSAccount}
                onChange={(event: EventType) => setCurrentETSAccount(event.target.value)}
                fieldStatus={fieldStatus}
                options={users}
                required
              />
            </div>
            <div className='w-100 row'>
              <div className='col'>
                <div className='d-flex justify-content-end'>
                  <SubmitButton
                    children={<FormattedMessage id='button_confirm' />}
                    className='btn-primary'
                    onClick={chooseUser}
                  />
                </div>
              </div>
            </div>
          </div>
          {transactions && (transactions.length > 0
            ? (
              <div className='mt-4'>
                <SubmitButton
                  className='btn-outline-primary'
                  onClick={() => setShowAssignModal(true)}
                  disabled={!checkedDeposit.length}
                >
                  <FormattedMessage id='ets_account_request_pm_picker_button' />
                </SubmitButton>
                <RequestListTable
                  tableTitle={TABLE_TITLE_ASSIGN}
                  selectAll={areAllDepositChecked()}
                  onSelectAll={handleCheckAllDeposit}
                  showPagination={false}
                >
                  <ToAssignTableBody
                    list={transactions}
                    onSelect={handleCheckDeposit}
                    checkedList={checkedDeposit}
                  />
                </RequestListTable>
              </div>
            )
            : (
              <div className='mt-4'>
                <NoResult />
              </div>
            ))}
        </CardBlock>
      </div>
      <ModalComponent
        title={<FormattedMessage id='ets_account_request_pm_picker' />}
        customContent={() =>
          <AssignPicker
            users={users}
            onSelect={setNewETSAccount}
            fieldStatus={fieldStatus}
          />}
        show={showAssignModal}
        handleClose={() => setShowAssignModal(false)}
        onClick={submitAssign}
      />
    </>
  )
}

export default injectIntl(ETSAccountPM)
