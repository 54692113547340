import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  CardBlock, Title,
  Transaction
} from '@inpi-dm/components'
import OverviewProrogationSelectedTitle from './OverviewProrogationSelectedTitle'

interface OverviewProrogationSelectTitlesProps {
  transaction: Transaction,
  isRequestView: boolean
}

const OverviewProrogationSelectTitles: FC<OverviewProrogationSelectTitlesProps> = ({ transaction, isRequestView }) => {
  return (
    <CardBlock
      header={
        <div className='d-flex justify-content-between'>
          <FormattedMessage id={isRequestView ? 'selected_record_prorogation' : 'selected_records_prorogation'} />
        </div>
      }
      shadow
    >
      {
        transaction.prorogation?.titles.map((title: Title, indexTitle: number) => (
          <div className='mb-2' key={title.id}>
            <OverviewProrogationSelectedTitle transactionId={transaction.id} title={title} indexTitle={indexTitle} />
          </div>
        ))
      }
    </CardBlock>
  )
}

export default OverviewProrogationSelectTitles
