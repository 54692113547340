import React, { FC, useEffect, useState } from 'react'
import {
  containsErrors, ContributorTitle,
  DATE_ISO,
  DateUtils, DepositDocument,
  FilAriane, ModalComponent, PROROGATION_TYPE, ProrogationInscriptionRequest
} from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import StepContainer from '../../stepper/StepContainer'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import TransactionService from '../../../services/transaction/TransactionService'
import {
  storeProrogationFieldStatusReset,
  storeProrogationRemove,
  storeProrogationUpdate
} from '../../../store/prorogation/prorogationActions'
import ProrogationService from '../../../services/transaction/ProrogationService'
import SelectProrogationRecords from './record/SelectProrogationRecords'
import RequestDetailsForm from './requestDetails/RequestDetailsForm'
import {
  storeContributorsTitleRemove,
  storeContributorsTitleUpdate
} from '../../../store/contributorsTitle/contributorsTitleActions'
import qs from 'qs'
import RecordService from '../../../services/record/RecordService'
import OverviewFormProrogation from './overview/OverviewFormProrogation'
import OverviewFormProrogationValidator from './overview/validator/OverviewFormProrogationValidator'
import PaymentProrogation from '../payment/PaymentProrogation'
import SelectRecordFormValidator from './record/validator/SelectRecordFormValidator'
import RequestDetailsFormValidator from './requestDetails/validator/RequestDetailsFormValidator'
import DepositorValidator from './depositor/validator/DepositorValidator'
import DepositorForm from '../../intervenant/DepositorForm'
import CoDepositorValidator from './codepositor/validator/CoDepositorValidator'
import ApplicantListForm from '../../intervenant/ApplicantListForm'
import AgentValidator from './agent/validator/AgentValidator'
import AgentProposalForm from '../../intervenant/AgentProposalForm'
import RecipientValidator from './recipient/validator/RecipientValidator'
import RecipientForm from '../../intervenant/RecipientForm'
import SignatoryValidator from './agent/signatory/validator/SignatoryValidator'
import SignatoryForm from '../../intervenant/SignatoryForm'
import IntervenantService from '../../../services/transaction/IntervenantService'
import ContentService from '../../../services/content/ContentService'

interface ProrogationFormProps extends RouteComponentProps<{id: string}> {
  isNew?: boolean
}

const ProrogationForm: FC<ProrogationFormProps> = ({
  isNew = false,
  match,
  history,
  location
}) => {
  const dispatch = useDispatch()
  const prorogation = useSelector((state : RootStateOrAny) => state.prorogation)
  const prorogationFieldStatus = useSelector((state : RootStateOrAny) => state.prorogationFieldStatus) || []
  const infosByNumNat = useSelector((state : RootStateOrAny) => state.contributorsTitle)
  const [isNewProrogation, setIsNewProrogation] = useState(isNew)
  const [inscriptionRequestsDocuments, setInscriptionRequestsDocuments] = useState<DepositDocument[]>([])
  const [additionalParts, setAdditionalParts] = useState<DepositDocument[]>([])
  const [flatContributorsTitle, setFlatContributorsTitle] = useState<ContributorTitle[]>([])

  const [isReady, setIsReady] = useState(false)
  const [showRgpd, setShowRgpd] = useState(false)
  const [rgpdContent, setRdpdContent] = useState('')
  const [hasSupplementExcluded, setHasSupplmentExluded] = useState(false)
  // Initialisation de la prorogation à modifier
  useEffect(() => {
    dispatch(storeProrogationFieldStatusReset())
    dispatch(storeProrogationRemove())
    dispatch(storeContributorsTitleRemove())
    if (!isNew) {
      TransactionService.getTransaction(match.params.id).then(result => {
        dispatch(storeProrogationUpdate(result))
      }).finally(() => setIsReady(true))
    } else {
      ContentService.getEditorialBlockByCode('RGPD-PRO').then((res) => {
        if (res.content) {
          setRdpdContent(res.content)
          setShowRgpd(true)
        }
      })
    }

    // Si la prorogation a été faite à partir de titres, on récupère les ids dans l'url
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (query.from) {
      const idRecords = query.from.split(',')
      if (idRecords.length > 0) {
        initProrogation(idRecords)
      } else {
        setIsReady(true)
      }
    } else {
      isNew && setIsReady(true)
    }
  }, [isNew])

  // Si on retourne sur une transaction en cours de création, on récupère les infos des documents liées aux demandes de transfert de propriété
  useEffect(() => {
    if (prorogation.id && prorogation.prorogation && prorogation.prorogation.inscriptionRequests) {
      const newInscriptionRequestsDocuments = [...prorogation.prorogation.inscriptionRequests].filter(inscriptionRequest => inscriptionRequest.isOwnershipTransfer)
      newInscriptionRequestsDocuments.forEach((inscriptionRequest: ProrogationInscriptionRequest, index: number) => {
        if (inscriptionRequest.documentInternalName) {
          const doc = prorogation.documents.find((document: DepositDocument) => document.internalName === inscriptionRequest.documentInternalName)
          if (doc) {
            newInscriptionRequestsDocuments[index] = {
              ...newInscriptionRequestsDocuments[index],
              needPost: false,
              name: doc.name
            }
          }
        }
      })
      setInscriptionRequestsDocuments(newInscriptionRequestsDocuments)
    }
  }, [prorogation.id])

  useEffect(() => {
    setFlatContributorsTitle(IntervenantService.getFlatContributorsTitle())
  }, [infosByNumNat])

  /**
   * Initialise la prorogation si celle ci a été faite à partir de titres
   * @param idRecords
   */
  const initProrogation = (idRecords: string[]) => {
    let newContributorsTitle: [] = []
    const newTitlePromise = idRecords.map(async (idRecord: string) => {
      return RecordService.getRecord(idRecord).then(record => {
        if (record.numNat) {
          newContributorsTitle = {
            ...newContributorsTitle,
            [record.numNat]: {
              depositor: record.depositor,
              holders: record.holders,
              recipient: record.recipient
            }
          }
        }
        return {
          depositDate: DateUtils.formateDateToFormat(record.depositDate, DATE_ISO),
          numNat: record.numNat,
          publications: RecordService.getPublications(record),
          isScopePartial: false
        }
      })
    })
    Promise.all(newTitlePromise).then(newTitles => {
      dispatch(storeProrogationUpdate({ ...prorogation, prorogation: { titles: newTitles } }))
      dispatch(storeContributorsTitleUpdate(newContributorsTitle))
    }).finally(() => setIsReady(true))
  }

  /**
   * Si la dernière étape est valide, on submit le formulaire
   */
  const handleSubmit = () => {
    history.push('/')
  }

  /**
   * Sauvegarde du type de la prorogation
   */
  const saveProrogationFirstStep = () => {
    if (isNewProrogation) {
      setIsNewProrogation(false)
      // Creation puis sauvegarde
      return ProrogationService.createProrogation().then(() => {
        return ProrogationService.updateProrogationBDDFromStore(['titles'])
      })
    } else {
      // sauvegarde
      return ProrogationService.updateProrogationBDDFromStore(['titles'])
    }
  }

  /** Vérifie que tous les champs obligatoires sont remplis pour une étape du menu**/
  const checkValidity = (stepName: string): boolean => {
    const status = prorogationFieldStatus[stepName]
    if (status === undefined) {
      return false
    }
    return !containsErrors(status)
  }

  return (
    <>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id='breadcrumb_new_prorogation' /></span>
      </FilAriane>

      <StepContainer
        className='mb-5 is-validated'
        listTitle={<FormattedMessage id='prorogation_step_list_title' />}
        onCancel={() => history.push('/')}
        onSubmit={handleSubmit}
        views={[
          {
            id: 'titles',
            label: <FormattedMessage id='records_prorogation' />,
            badge: '1',
            component: isReady ? <SelectProrogationRecords validateMandatory={SelectRecordFormValidator.validateMandatory} /> : <div />,
            validateGoToStep: SelectRecordFormValidator.validateOnChangeStep,
            onGoToStep: saveProrogationFirstStep,
            isValidated: checkValidity('titles')
          }, {
            id: 'intervenant',
            label: <FormattedMessage id='intervenant_form_title' />,
            badge: '2',
            isValidated: checkValidity('signatory')
          }, {
            id: 'intervenant_depositor',
            label: <FormattedMessage id='prorogation_onglet_depositor' />,
            level: 1,
            validateGoToStep: DepositorValidator.validateOnChangeStep,
            onGoToStep: () => ProrogationService.updateProrogationBDDFromStore(['depositor']),
            component: (
              <DepositorForm
                object={prorogation}
                fieldStatus={prorogationFieldStatus}
                procedureType={PROROGATION_TYPE.value}
                defaultIntervenantlist={flatContributorsTitle}
              />
            )
          }, {
            id: 'intervenant_co_deposant',
            label: <FormattedMessage id='intervenant_onglet_co_declarant' />,
            level: 1,
            validateGoToStep: CoDepositorValidator.validateOnChangeStep,
            onGoToStep: () => ProrogationService.updateProrogationBDDFromStore(['coDepositors']),
            component: (
              <ApplicantListForm
                object={prorogation}
                fieldStatus={prorogationFieldStatus.coDepositors || []}
                procedureType={PROROGATION_TYPE.value}
                applicantList={prorogation.coDepositors || []}
                defaultIntervenantlist={flatContributorsTitle}
              />
            ),
            stepButtonsAsChildren: true
          }, {
            id: 'intervenant_mandataire',
            label: <FormattedMessage id='intervenant_onglet_mandataire' />,
            level: 1,
            validateGoToStep: AgentValidator.validateOnChangeStep,
            component: (
              <AgentProposalForm
                object={prorogation}
                fieldStatus={prorogationFieldStatus}
                procedureType={PROROGATION_TYPE.value}
                findIntervenantsList={['depositor', 'coDepositors']}
                defaultIntervenantlist={flatContributorsTitle}
                viewFormInit={false}
                isDeletable
                idTransaction={prorogation.id}
              />
            ),
            onGoToStep: () => ProrogationService.updateProrogationBDDFromStore(['agent'])
          },
          {
            id: 'intervenant_destinataire',
            label: <FormattedMessage id='intervenant_onglet_destinataire' />,
            level: 1,
            validateGoToStep: RecipientValidator.validateOnChangeStep,
            component: (
              <RecipientForm
                object={prorogation}
                fieldStatus={prorogationFieldStatus}
                procedureType={PROROGATION_TYPE.value}
                findIntervenantsList={['depositor', 'coDepositors', 'agent']}
                defaultIntervenantlist={flatContributorsTitle}
              />
            ),
            onGoToStep: () => ProrogationService.updateProrogationBDDFromStore(['recipient'])
          }, {
            id: 'intervenant_signatory',
            label: <FormattedMessage id='intervenant_onglet_signatory' />,
            level: 1,
            validateGoToStep: SignatoryValidator.validateOnChangeStep,
            component: <SignatoryForm object={prorogation} fieldStatus={prorogationFieldStatus} procedureType={PROROGATION_TYPE.value} />,
            onGoToStep: () => ProrogationService.updateProrogationBDDFromStore(['signatory'])
          }, {
            id: 'details',
            label: <FormattedMessage id='prorogation_request_details_title' />,
            badge: '3',
            isValidated: checkValidity('additionalParts'),
            onGoToStep: () => ProrogationService.updateProrogationBDDFromStore(['titles', 'isAppealOrDecommission', 'appealDecommissionNumber', 'inscriptionRequests', 'isNewDepositor', 'documents'], inscriptionRequestsDocuments, additionalParts)
              .then(() => {
                const updateDocs = [...inscriptionRequestsDocuments].map(inscriptionRequestsDocument => {
                  inscriptionRequestsDocument.needPost = false
                  return inscriptionRequestsDocument
                })
                setInscriptionRequestsDocuments(updateDocs)
                setAdditionalParts([])
              }),
            validateGoToStep: () => RequestDetailsFormValidator.validateOnChangeStep(additionalParts),
            component:
  <RequestDetailsForm
    inscriptionRequestsDocuments={inscriptionRequestsDocuments} setInscriptionRequestsDocuments={setInscriptionRequestsDocuments}
    additionalParts={additionalParts} setAdditionalParts={setAdditionalParts}
  />
          }, {
            id: 'overview',
            label: <FormattedMessage id='overview_deposit_form_short_title' />,
            badge: '4',
            validateGoToStep: OverviewFormProrogationValidator.validateOnChangeStep,
            component: <OverviewFormProrogation hasSupplementExcluded={hasSupplementExcluded} setHasSupplmentExluded={setHasSupplmentExluded} />,
            isValidated: checkValidity('overviewSimilarInformation')
          },
          {
            id: 'payment',
            label: <FormattedMessage id='payment' />,
            badge: '5',
            component: <PaymentProrogation transaction={prorogation} hasSupplementExcluded={hasSupplementExcluded} />,
            stepButtonsAsChildren: true
          }
        ]}
      />
      <ModalComponent
        title={<FormattedMessage id='rgpd_title' />}
        isNotCancellable
        customContent={() => <div dangerouslySetInnerHTML={{ __html: rgpdContent }} />}
        handleClose={() => setShowRgpd(false)}
        show={showRgpd}
        onClick={() => setShowRgpd(false)}
        customButtonId='common_start_form'
        size='xl'
      />
    </>
  )
}

export default withRouter(ProrogationForm)
