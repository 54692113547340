import React from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux'
import { DEPOSIT_TYPES } from '@inpi-dm/components/src/constants/DepositConstants'
import SelectTypeItem from '../../../selectTypeItem/SelectTypeItem'
import { Deposit, DEPOSIT_TYPE, ErrorField } from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'
import { storeDepositUpdate } from '../../../../store/deposit/depositActions'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'

const SelectDepositType = () => {
  const deposit = useSelector((state : RootStateOrAny) => state.deposit)
  const fieldStatus = useSelector((state : RootStateOrAny) => state.depositFieldStatus)
  const dispatch = useDispatch()

  const handleChange = (deposit: Deposit) => {
    dispatch(storeDepositUpdate(deposit))
  }

  return (
    <div className='select-type-depot'>
      <div className='row'>
        <header className='col-8 mb-4'>
          <h1><FormattedMessage id='select_depot_type_title' /></h1>
          <span className='subtitle'><FormattedMessage id='select_depot_type_description' /></span>
        </header>
        <InternalReferenceField
          value={deposit.internalReference}
          procedureType={DEPOSIT_TYPE.value}
          className='col-4 mb-4'
        />
      </div>

      <div className='row m-auto'>
        {DEPOSIT_TYPES.map(type => (
          <div key={type.code} className='col-4'>
            <SelectTypeItem
              type={type}
              value={deposit.depositType}
              onClick={(value) => handleChange({ depositType: value })}
            />
          </div>
        ))}
        <div className='col-12'>
          <ErrorField message={fieldStatus.depositType} className='fade alert alert-danger show position-relative mt-4' />
        </div>
      </div>
    </div>
  )
}

export default withRouter(SelectDepositType)
