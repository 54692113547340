import { EventType, FieldStatus, FieldValidator, isFilled, Validators } from '..'

export function buildEventType (name: string, value: any): EventType {
  return { target: { name, value } }
}

export function containsErrors (fieldStatus?: any): boolean {
  if (fieldStatus instanceof Array) {
    return !!fieldStatus.find(containsErrors)
  } else if (fieldStatus instanceof Object) {
    return !!Object.keys(fieldStatus)
      .find(key => containsErrors(fieldStatus[key]))
  }
  return isFilled(fieldStatus)
}

function isFieldNotValidated (fieldname: string, fieldStatus: FieldStatus) {
  return !fieldStatus[fieldname]
}

/* Execute les fonctions de validation sur un champ pour connaitre le statut du champ */
function executeValidation (fieldname: string, validator: Validators, objectToValidate: any, fieldStatus: FieldStatus) {
  const result: FieldStatus = {}

  if (isFieldNotValidated(fieldname, fieldStatus)) {
    if (typeof validator === 'function') {
      result[fieldname] = validator(objectToValidate)
    } else if (typeof validator === 'object') {
      Object.keys(validator)
        .filter(key => isFieldNotValidated(key, fieldStatus))
        .forEach((key) => {
          result[key] = validator[key](objectToValidate)
        })
    }
  }
  return result
}

/* Vérification de la validation de chaque champ du tableau fieldnames */
export function validateFields (fieldnames: string[], objectToValidate: any, validators: FieldValidator): FieldStatus {
  let newFieldStatus = {}

  fieldnames.forEach((fieldname) => {
    if (validators[fieldname]) {
      const fieldStatusUpdated = executeValidation(fieldname, validators[fieldname], objectToValidate, newFieldStatus)
      newFieldStatus = {
        ...newFieldStatus,
        ...fieldStatusUpdated
      }
    }
  })

  return newFieldStatus
}
