import { APPEAL_TYPE, DEPOSIT_TYPE, INSCRIPTION_TYPE, PROROGATION_TYPE } from '@inpi-dm/components'
import Message from './Message'
import { ETSAccountNavInterface } from '../interfaces/ETSAccountInterface'

export const ACTION_ADD_AGENT = 'TO_ADD'
export const ACTION_EDIT_AGENT = 'TO_EDIT'
export const ACTION_INACTIVATE_AGENT = 'TO_INACTIVATE'

export const PROCEDURE_ITEMS = [
  {
    value: DEPOSIT_TYPE.value,
    label: Message.procedure_depot
  },
  {
    value: INSCRIPTION_TYPE.value,
    label: Message.procedure_inscription
  },
  {
    value: PROROGATION_TYPE.value,
    label: Message.procedure_prorogation
  },
  {
    value: APPEAL_TYPE.value,
    label: Message.procedure_appeal
  }
]

export const ACCESS_DEMAND_ITEM_CREDENTIALS: ETSAccountNavInterface = {
  label: Message.ets_account_request_credentials,
  action: '',
  url: '/code-acces'
}

export const ACCESS_DEMAND_ITEM_ADD_AGENT: ETSAccountNavInterface = {
  label: Message.ets_account_request_new_agent,
  action: ACTION_ADD_AGENT,
  url: '/rattachement-nouveau-compte'
}

export const ACCESS_DEMAND_ITEM_EDIT_AGENT: ETSAccountNavInterface = {
  label: Message.ets_account_request_update_agent_infos,
  action: ACTION_EDIT_AGENT,
  url: '/rattachement-modification-compte'
}

export const ACCESS_DEMAND_ITEM_DEACTIVATE_AGENT: ETSAccountNavInterface = {
  label: Message.ets_account_request_remove_agent,
  action: ACTION_INACTIVATE_AGENT,
  url: '/detachement-compte'
}

export const ACCESS_DEMAND_ITEM_ATTACH_PM: ETSAccountNavInterface = {
  label: Message.ets_account_request_attach_pm,
  action: '',
  url: '/attachement-pm'
}

export const ACCESS_DEMAND_ITEMS = [
  ACCESS_DEMAND_ITEM_CREDENTIALS,
  ACCESS_DEMAND_ITEM_ADD_AGENT,
  ACCESS_DEMAND_ITEM_EDIT_AGENT,
  ACCESS_DEMAND_ITEM_DEACTIVATE_AGENT
]
