import http from 'network/http-common'
import { toast } from 'react-toastify'
import { createIntl } from 'react-intl'
import Message from '../../constants/Message'
import { TitleInfos } from '@inpi-dm/components'

/**
 * Classe permettant de faire des opérations sur une inscription
 */
class TitleService {
  constructor () {
    this.intl = createIntl({ locale: 'fr', messages: Message })
  }

  /**
   * Récupère des informations d'un dossier par son numnat
   * @param params
   * @param showError
   */
  getTitleInfos = async (params: any = null, showError: boolean = true): Promise<TitleInfos|null> => {
    try {
      return await http.get('/api/records/infos', {
        params: params
      })
    } catch (error) {
      if (showError) {
        if (error.code === '404') {
          toast.warning(this.intl.formatMessage({ id: 'warning_get_titles_null_result' }))
        } else {
          toast.error(this.intl.formatMessage({ id: 'error_get_titles_from_numnat_inscription' }))
        }
      }
      return Promise.reject(error)
    }
  }
}

export default new TitleService()
