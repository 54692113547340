import {
  APPEAL_FIELD_STATUS_RESET,
  APPEAL_FIELD_STATUS_UPDATE,
  APPEAL_REMOVE,
  APPEAL_UPDATE,
  AppealFieldStatusStoreAction,
  AppealStoreAction
} from './appealStoreTypes'

/**
 * @param appeal une partie de Transaction
 */
export const storeAppealUpdate = (appeal: any): AppealStoreAction => ({
  type: APPEAL_UPDATE,
  appeal
})

export const storeAppealRemove = () => ({
  type: APPEAL_REMOVE
})

/**
 * @param fieldStatus une partie de AppealFieldStatus
 */
export const storeAppealFieldStatusUpdate = (fieldStatus: any): AppealFieldStatusStoreAction => ({
  type: APPEAL_FIELD_STATUS_UPDATE,
  fieldStatus
})

export const storeAppealFieldStatusReset = () => ({
  type: APPEAL_FIELD_STATUS_RESET
})
