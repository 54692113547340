import React, { FC, useState, MutableRefObject, memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faEdit, faTrashAlt, faEye, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import ReproductionForm from './ReproductionForm'
import { BLACK_AND_WHITE, COLORS } from '../../../../../constants/DepositConstants'
import { FieldStatus, ModalComponent, Preview, ReproductionLightDeposit } from '@inpi-dm/components'
import DepositService from '../../../../../services/transaction/DepositService'
import ThumbnailReproduction from './ThumbnailReproduction'
/* global FormData */

interface ReproductionProps {
  repro: ReproductionLightDeposit,
  onDelete: (index: number) => void,
  idTransaction?: string,
  idModel?: string,
  indexModel: number,
  index: number,
  setAccordionIndex: (index: number) => void,
  isLastReproduction: boolean,
  validatorFormReproduction: (index: number) => boolean,
  handleChangeReproduction: (index: number, elemToChange: object) => void,
  fieldStatus: FieldStatus,
  reproRefs: MutableRefObject<any>[],
  accordionIndex: number,
}

const Reproduction: FC<ReproductionProps> = ({
  repro,
  onDelete,
  idTransaction,
  idModel,
  indexModel,
  index,
  setAccordionIndex,
  isLastReproduction,
  validatorFormReproduction,
  handleChangeReproduction,
  fieldStatus,
  reproRefs,
  accordionIndex

}) => {
  const [showReproModal, setShowReproModal] = useState(false)
  // on fait un state local pour éviter les re-renders inutiles
  const [reproduction, setReproduction] = useState<ReproductionLightDeposit>(repro)

  /**
     * Permet d'afficher les reproductions en preview
     */
  const previewReproduction = () => {
    const reproductionToPreview: ReproductionLightDeposit = { ...reproduction }

    // image provenant du back
    if (idTransaction && idModel && reproductionToPreview.preview && reproductionToPreview.preview.internalName
    ) {
      return (
        <div className='w-100 d-flex'>
          <Preview
            className='m-auto img-bopi'
            file={() => DepositService.getReproduction(idTransaction, idModel, reproductionToPreview.preview.internalName)}
            filename={reproductionToPreview.preview.name}
          />
        </div>

      )
    } else {
      // image provenant de l'utilisateur
      const formData = new FormData()
      formData.append('file', reproductionToPreview.file as File)
      return (
        <div className='w-100 d-flex'>
          <Preview
            className='m-auto img-bopi'
            file={() => DepositService.resizeFile(formData)}
            filename={(reproduction.file as File).name}
          />
        </div>
      )
    }
  }

  return (
    <Card className='reproduction'>
      <Card.Header>
        <div className='row d-flex align-items-center '>
          <div className='col-1 text-center'>
            <FontAwesomeIcon icon={faBars} />
          </div>
          <div className='col-2'>
            <ThumbnailReproduction
              repro={reproduction}
              idTransaction={idTransaction}
              idModel={idModel}
            />
          </div>
          <div className='col-7 d-flex flex-column justify-content-center'>
            <span>
              {`${indexModel + 1}.${index + 1} `}{reproduction?.label || '--'}
              {reproduction?.color && reproduction?.label
                ? <FontAwesomeIcon icon={faCheckCircle} className='greenIcon ml-2' /> : ''}
            </span>
            <span className='font-weight-normal text-normalcase'>
              {reproduction?.color === COLORS && <FormattedMessage id='reproductions_form_radio_colors' />}
              {reproduction?.color === BLACK_AND_WHITE && <FormattedMessage id='reproductions_form_radio_no_colors' />}
              {!reproduction?.color && '--'}
            </span>
          </div>
          <div className='col-2 d-flex'>
            <FontAwesomeIcon
              className='cursor-pointer mr-3 text-primary'
              icon={faEye}
              onClick={() => setShowReproModal(true)}
            />
            <Accordion.Toggle as={Button} variant='link' eventKey={index.toString()} onClick={() => setAccordionIndex(index)}>
              <FontAwesomeIcon
                className='mr-3 cursor-pointer'
                icon={faEdit}
              />
            </Accordion.Toggle>
            <FontAwesomeIcon
              className='cursor-pointer text-secondary'
              icon={faTrashAlt}
              onClick={() => onDelete(index)}
            />
            <ModalComponent
              title={<div className='text-capitalize'><FormattedMessage id='request_number_reproduction' /></div>}
              show={showReproModal}
              customContent={previewReproduction}
              handleClose={() => setShowReproModal(false)}
              onClick={() => setShowReproModal(false)}
              size='xl'
            />
          </div>
        </div>
      </Card.Header>
      <Accordion.Collapse eventKey={index.toString()}>
        <Card.Body>
          <ReproductionForm
            reproduction={reproduction}
            setReproduction={setReproduction}
            indexModel={indexModel}
            reproRefs={reproRefs}
            index={index}
            setAccordionIndex={setAccordionIndex}
            isLastReproduction={isLastReproduction}
            validatorFormReproduction={validatorFormReproduction}
            handleChangeReproduction={handleChangeReproduction}
            fieldStatus={fieldStatus}
            accordionIndex={accordionIndex}
          />
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  )
}

export default memo(Reproduction)
