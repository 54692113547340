import {
  INSCRIPTION_FIELD_STATUS_RESET,
  INSCRIPTION_FIELD_STATUS_UPDATE,
  INSCRIPTION_REMOVE,
  INSCRIPTION_UPDATE,
  InscriptionFieldStatusStoreAction,
  InscriptionStoreAction
} from './inscriptionStoreTypes'

/**
 * @param inscription une partie de Transaction
 */
export const storeInscriptionUpdate = (inscription: any): InscriptionStoreAction => ({
  type: INSCRIPTION_UPDATE,
  inscription
})

export const storeInscriptionRemove = () => ({
  type: INSCRIPTION_REMOVE
})

/**
 * @param fieldStatus une partie de InscriptionFieldStatus
 */
export const storeInscriptionFieldStatusUpdate = (fieldStatus: any): InscriptionFieldStatusStoreAction => ({
  type: INSCRIPTION_FIELD_STATUS_UPDATE,
  fieldStatus
})

export const storeInscriptionFieldStatusReset = () => ({
  type: INSCRIPTION_FIELD_STATUS_RESET
})
