import {
  DOCUMENT_COMMUNICABILITIES,
  INSCRIPTION_OWNERSHIP,
  INSCRIPTION_CORRECTION, INSCRIPTION_DISCLAIMER
} from '@inpi-dm/components'
import Message from './Message'
import { InscriptionDocumentsInterface } from '../interfaces/InscriptionInterfaces'

export const DOCUMENT_COMMUNICABLE_OPTIONS = [
  {
    label: Message.inscription_form_documents_block_documents_communicable,
    value: DOCUMENT_COMMUNICABILITIES.COMMUNICABLE
  }, {
    label: Message.inscription_form_documents_block_documents_non_communicable,
    value: DOCUMENT_COMMUNICABILITIES.NON_COMMUNICABLE
  }
]

export const DOCUMENT_INSCRIPTION_FEE_CORRECTION = 'Pièce justificative de redevance prescrite'
export const DOCUMENT_INSCRIPTION_OWNERSHIP_EDIT = 'Acte de modification de la propriété'
export const DOCUMENT_INSCRIPTION_OWNERSHIP_ROLLBACK = 'Acte de demande de restitution'
export const DOCUMENT_INSCRIPTION_OWNERSHIP_NO_PROOF = 'Impossibilité matérielle'
export const DOCUMENT_INSCRIPTION_AGENT = 'Pièce justificative du pouvoir du mandataire'
export const DOCUMENT_INSCRIPTION_OTHER_CORRECTION = 'Autre pièce justificative'

export const FILE_PROOF_ITEMS: InscriptionDocumentsInterface[] = [
  {
    fileInputId: 'proof_document-',
    selectInputId: 'proof_select-',
    label: 'inscription_form_documents_block_proof',
    type: DOCUMENT_INSCRIPTION_FEE_CORRECTION,
    inscriptionTypes: [INSCRIPTION_CORRECTION.code]
  }, {
    fileInputId: 'agent_proof_document-',
    selectInputId: 'agent_proof_select-',
    label: 'inscription_form_documents_block_agent_proof',
    type: DOCUMENT_INSCRIPTION_AGENT,
    inscriptionTypes: [INSCRIPTION_CORRECTION.code, INSCRIPTION_OWNERSHIP.code, INSCRIPTION_DISCLAIMER.code]
  }, {
    fileInputId: 'ownership_edit_document-',
    selectInputId: 'ownership_edit_select-',
    label: 'inscription_form_documents_block_ownership_edit',
    type: DOCUMENT_INSCRIPTION_OWNERSHIP_EDIT,
    inscriptionTypes: [INSCRIPTION_OWNERSHIP.code]
  }, {
    fileInputId: 'no_proof_document-',
    selectInputId: 'no_proof_select-',
    label: 'inscription_form_documents_block_no_proof',
    type: DOCUMENT_INSCRIPTION_OWNERSHIP_NO_PROOF,
    inscriptionTypes: [INSCRIPTION_OWNERSHIP.code]
  }, {
    fileInputId: 'other_proof_document-',
    selectInputId: 'other_proof_select-',
    labelInputId: 'other_proof_label-',
    label: 'inscription_form_documents_block_other_proof',
    type: DOCUMENT_INSCRIPTION_OTHER_CORRECTION,
    hasLabel: true,
    inscriptionTypes: [INSCRIPTION_CORRECTION.code, INSCRIPTION_OWNERSHIP.code, INSCRIPTION_DISCLAIMER.code]
  }
]
