import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { CardBlock, DateUtils, NotificationInterface, SubmitButton } from '@inpi-dm/components'
import NotificationItem from '../form/overview/notifications/NotificationItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import NotificationService from '../../../services/transaction/NotificationService'
import fileDownload from 'js-file-download'
import OverviewNotificationsInfo from '../form/overview/notifications/OverviewNotificationsInfo'
import Message from '../../../constants/Message'

interface DivisionNotificationBlockProps {
  idTransaction: string,
  notification: NotificationInterface,
}

const DivisionNotificationBlock: FC<DivisionNotificationBlockProps> = ({
  idTransaction,
  notification
}) => {
  /**
   * Lance le téléchargement de la notification au format pdf
   */
  const downloadNotificationPDF = (notification: NotificationInterface) => {
    return NotificationService.downloadNotification(idTransaction, notification.id).then(response => {
      fileDownload(response, `${Message.overview_deposit_notification_filename}-${notification.id}.pdf`, 'pdf')
    })
  }

  return (
    <CardBlock
      header={
        <div className='d-flex justify-content-between'>
          {notification.title}
          {notification.id
            ? (
              <SubmitButton
                className='btn btn-link text-primary bg-transparent'
                onClick={() => downloadNotificationPDF(notification)}
              >
                <FontAwesomeIcon icon={faDownload} className='mr-2' />
              </SubmitButton>
            )
            : null}
        </div>
      }
      shadow
      bodyClassName='p-3'
      className='mb-3 h-auto'
    >
      <div className='row mb-3'>
        <NotificationItem
          label={<FormattedMessage id='overview_deposit_notification_received' />}
          className='col-6'
        >
          {DateUtils.formatDateFr(notification.sendingDate)}
        </NotificationItem>
        <NotificationItem
          label={<FormattedMessage id='overview_deposit_notification_status_label' />}
          className='col-6'
        >
          <FormattedMessage id={`overview_deposit_notification_status_${notification.tab}`} />
        </NotificationItem>
      </div>
      <div className='row'>
        <div className='col-12'>
          <OverviewNotificationsInfo notification={notification} download={() => downloadNotificationPDF(notification)} />
        </div>
      </div>
    </CardBlock>
  )
}

export default DivisionNotificationBlock
