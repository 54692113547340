import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { buildEventType, CheckboxField, EventType } from '@inpi-dm/components'
import OverviewRoyalties from '../../../payment/OverviewRoyalties'
import { Payment } from '../../../../interfaces/DepositInterfaces'
import OfficialDocumentService from '../../../../services/transaction/OfficialDocumentService'
import OverviewOfficialDocument from './OverviewOfficialDocument'
import { storeOfficialDocumentUpdate } from '../../../../store/officialDocument/officialDocumentActions'

const OverviewFormOfficialDocument = () => {
  const dispatch = useDispatch()
  const transaction = useSelector((state : RootStateOrAny) => state.officialDocument)
  const fieldStatus = useSelector((state: RootStateOrAny) => state.officialDocumentFieldStatus)
  const [infoPrice, setInfoPrice] = useState<Payment>({ lines: [], totalAmount: 0 })

  /**
     * Enregistrement des données dans le store à la saisie
     * @param event
     */
  const handleChange = (event: EventType) => {
    const { value } = event.target

    dispatch(storeOfficialDocumentUpdate({
      overviewSimilarInformation: value
    }))
  }

  useEffect(() => {
    // Reset case à cocher
    handleChange(buildEventType('overviewSimilarInformation', false))

    // Récupération des informations concernant le paiement
    if (transaction.id) {
      OfficialDocumentService.getPayment(transaction.id, transaction.officialDocument.isAdministration)
        .then((payment) =>
          payment && setInfoPrice(payment)
        )
    }
  }, [transaction.officialDocument.isAdministration])

  return (
    <div className='is-validated'>
      {transaction &&
        <>
          <div className='row mx-3 d-flex justify-content-between'>
            <h1><FormattedMessage id='overview_deposit_form_title' /></h1>
          </div>
          <div className='col mt-3'>
            <OverviewOfficialDocument transaction={transaction} />

            <div className='row'>
              <div className='col-12 mb-3'>
                <OverviewRoyalties infoPrice={infoPrice} />
              </div>
            </div>

            <div className='d-flex mt-3'>
              <CheckboxField
                className='mx-auto'
                labelClassName='font-weight-bold'
                inputId='overviewSimilarInformation'
                label={<FormattedMessage id='overview_deposit_form_checkbox_similar_information' />}
                checked={transaction.overviewSimilarInformation}
                onChange={handleChange}
                fieldStatus={fieldStatus.overviewSimilarInformation}
              />
            </div>
          </div>
        </>}
    </div>
  )
}

export default OverviewFormOfficialDocument
