import React, { FC } from 'react'
import {
  CheckboxField, DateUtils,
  ModelDeposit,
  Preview,
  REPRODUCTION_STATE_OPTIONS,
  ReproductionLightDeposit
} from '@inpi-dm/components'
import DepositService from '../../../../../services/transaction/DepositService'

interface ListReproductionsLineProps {
    model: ModelDeposit,
    reproduction: ReproductionLightDeposit,
    onCheck: () => void,
    indexModel: number,
    indexRepro: number,
    idTransaction?: string
}

const ListReproductionsLine: FC<ListReproductionsLineProps> = ({
  model,
  reproduction,
  onCheck,
  indexModel,
  indexRepro,
  idTransaction
}) => {
  const state = REPRODUCTION_STATE_OPTIONS.find(state => state.value === reproduction.state || '')

  return (
    <tr key={`${model.id}.${indexRepro}`} className={`reproduction ${state?.textClassName || ''}`}>
      <td className='table-checkbox align-middle'>
        {!reproduction.state && (
          <CheckboxField
            inputId={`select-${reproduction.file.internalName}`}
            label=''
            onChange={onCheck}
            checked={reproduction.selected}
          />
        )}
      </td>
      <td className='align-middle font-weight-bold'>
        {`${indexModel + 1}.${indexRepro + 1}`}
      </td>
      <td className='align-middle'>
        {idTransaction && reproduction.thumbnail && (
          <Preview
            file={() => DepositService.getReproduction(idTransaction, model.id, reproduction.thumbnail.internalName)}
            filename={reproduction.thumbnail.name}
          />
        )}
      </td>
      <td className='align-middle font-weight-bold'>{reproduction.label}</td>
      <td className='align-middle'>{reproduction.description}</td>
      <td className='align-middle font-weight-bold'>{state && state.label}{reproduction.dateState && ` - ${DateUtils.formatDateFr(reproduction.dateState)}`}</td>
    </tr>
  )
}

export default ListReproductionsLine
