import { FoUserResponse } from '../../interfaces/User'
import {
  DEFAULT_INTERVENANT,
  Deposit,
  DepositIntervenant,
  PERSONNE_MORALE,
  PERSONNE_PHYSIQUE,
  Transaction
  , ContributorTitle
} from '@inpi-dm/components'
import store from '../../store/store'
import ContentService from '../content/ContentService'

class IntervenantService {
    /**
     * Construit un intervenant à partir de l'utilsiateur FO en paramètre
     * @param user Utilisateur FO
     */
    buildIntervenantFromUser = async (user: FoUserResponse): Promise<DepositIntervenant> => {
      let intervenant : DepositIntervenant = {
        idUser: user.id,
        email: user.email
      }
      if (user.hasCompany) {
        intervenant = {
          ...intervenant,
          type: PERSONNE_MORALE.value,
          phone: user.officePhone,
          companyName: user.companyName,
          legalForm: user.legalForm,
          siren: user.siren,
          address: {
            label: user.address1Company,
            complement: user.address2Company,
            city: user.cityCompany,
            zipCode: user.zipCodeCompany,
            country: user.countryCodeCompany
          }
        }
      } else {
        intervenant = {
          ...intervenant,
          type: PERSONNE_PHYSIQUE.value,
          civilityCode: user.civilityCode,
          lastname: user.lastname,
          firstname: user.firstname,
          phone: user.mobilePhone,
          address: {
            label: user.address1,
            complement: user.address2,
            city: user.city,
            zipCode: user.zipCode,
            country: user.countryCode
          }
        }
      }

      return this.validateCountryOfContributor(intervenant)
    }

    /**
     * On vérifie que le pays sélectionné appartient bien à la liste de pays administrable
     * (pour qu'il soit visible dans le select et valide)
     * @param contributor
     */
    validateCountryOfContributor = async (contributor: DepositIntervenant): Promise<DepositIntervenant> => {
      const countries = await ContentService.getCountries()
      const country = contributor.address?.country && countries.map(c => c.code).indexOf(contributor.address?.country) > -1
        ? contributor.address?.country : undefined
      return { ...contributor, address: { ...contributor.address, country: country } }
    }

    /**
     * Récupère le nom/prénom d'une personne physique ou le
     * nom de la société d'une personne morale
     *
     * @param intervenant
     */
    getName = (intervenant: DepositIntervenant): string => {
      const name = (intervenant.type === PERSONNE_PHYSIQUE.value)
        ? `${intervenant.lastname || ''} ${intervenant.firstname || ''}`
        : (intervenant.companyName || '')

      return name.trim()
    }

    /**
     * Retourne true si l'objet passer en paramètre est de type DepositIntervenant
     * @param object
     */
    isIntervenant = (object: any): object is DepositIntervenant => !!object && object !== DEFAULT_INTERVENANT

    /**
     * Récupère tous les intervenants distincts présents dans un dépôt ou transaction.
     *
     * @param transaction
     * @param fields liste des champs contenant les intervenants à récupérer
     */
    findIntervenants = (transaction: Transaction|Deposit, fields?: string[]) => {
      return Object.entries(transaction)
        .filter(([key]) => !fields || fields.includes(key))
        .map(([, value]) => value)
        .reduce((a, b) => a.concat(b), [])
        .filter(this.isIntervenant)
    }

    /**
     * Supprime les intervenant en double
     *
     * @param intervenants
     */
    removeDuplicateIntervenants = (intervenants: DepositIntervenant[]) => {
      return intervenants.reduce((accumulator: DepositIntervenant[], intervenant: DepositIntervenant) => {
        // Si l'intervenant à un companyName et que son companyName est différent de tous les intervenants (qui on un companyName)
        // Ou si l'intervenant à un firstName et lastname et que son firstName et son lastName sont différent de tous les intervenants (qui on un firstName et un lastName)
        // Alors on ajoute l'intervenant à la liste
        if ((intervenant.type === PERSONNE_MORALE.value && accumulator.every(accumulatorIntervenant => !accumulatorIntervenant.companyName || accumulatorIntervenant.companyName !== intervenant.companyName)) ||
                (intervenant.type === PERSONNE_PHYSIQUE.value && accumulator.every(accumulatorIntervenant => !accumulatorIntervenant.firstname || accumulatorIntervenant.firstname !== intervenant.firstname) &&
                    accumulator.every(accumulatorIntervenant => !accumulatorIntervenant.lastname || accumulatorIntervenant.lastname !== intervenant.lastname))) {
          return [...accumulator, intervenant]
        } else {
          return accumulator
        }
      }, [])
    }

    /**
     * Récupère les intervenants des titres
     */
    getFlatContributorsTitle = () : ContributorTitle[] => {
      const infosByNumNat = store.getState().contributorsTitle

      return Object.entries(infosByNumNat).reduce(
        (allContributor: ContributorTitle[], [key, currentContributor]) => {
          // On ajoute le numNat dans l'intervenant pour l'afficher lors de la liste de copie d'intervenant
          const holders: ContributorTitle[] = currentContributor.holders?.map(holder => ({ ...holder, numNat: key })) || []
          const recipient: ContributorTitle = {
            ...currentContributor.recipient,
            numNat: key
          }

          if (currentContributor.agent) {
            return [
              ...allContributor,
              {
                ...currentContributor.agent,
                numNat: key
              },
              ...holders,
              recipient
            ]
          } else {
            return [
              ...allContributor,
              ...holders,
              recipient
            ]
          }
        },
        [])
    }
}

export default new IntervenantService()
