import { Transaction } from '@inpi-dm/components'
import { AppealFieldStatus } from '../../interfaces/AppealInterfaces'

export const APPEAL_UPDATE = 'APPEAL_UPDATE'
export const APPEAL_REMOVE = 'APPEAL_REMOVE'
export const APPEAL_FIELD_STATUS_UPDATE = 'APPEAL_FIELD_STATUS_UPDATE'
export const APPEAL_FIELD_STATUS_RESET = 'APPEAL_FIELD_STATUS_RESET'

export interface AppealStoreAction {
  type: string,
  appeal?: Transaction,
}

export interface AppealFieldStatusStoreAction {
  type: string,
  fieldStatus?: AppealFieldStatus,
}
