import store from '../../../../../store/store'
import {
  containsErrors,
  Transaction,
  IntervenantValidator,
  PERSONNE_PHYSIQUE, FieldStatus
} from '@inpi-dm/components'
import { storeInscriptionFieldStatusUpdate } from '../../../../../store/inscription/inscriptionActions'

class AgentValidator {
  /**
   * Validation du Mandataire
   * @param transaction
   */
  validateMandatory = (transaction: Transaction) : FieldStatus => {
    let fieldStatus

    if (transaction.agent || IntervenantValidator.containsAgentDocument(transaction)) {
      fieldStatus = IntervenantValidator.validateAgent(transaction.agent || { type: PERSONNE_PHYSIQUE.value })
    } else {
      fieldStatus = {}
    }

    return fieldStatus
  }

  /**
   * Mise a jour de InscriptionFieldStatus
   */
  validateOnChangeStep = () => {
    const inscription = store.getState().inscription

    const fieldStatus = this.validateMandatory({ ...inscription })

    store.dispatch(storeInscriptionFieldStatusUpdate({
      agent: fieldStatus
    }))

    return !containsErrors(fieldStatus)
  }
}

export default new AgentValidator()
