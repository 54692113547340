import store from '../../../../../store/store'
import { storeDepositFieldStatusUpdate } from '../../../../../store/deposit/depositActions'
import {
  containsErrors,
  Transaction,
  IntervenantValidator,
  isFilled,
  PERSONNE_PHYSIQUE
} from '@inpi-dm/components'

class AgentValidator {
  /**
   * Valide la présence obligatoire d'un mandataire dans un dépôt
   * @param transaction
   */
  validateMandatory = (transaction: Transaction) => {
    let fieldStatus
    const deposit = transaction.file

    if (deposit.agent || IntervenantValidator.containsAgentDocument(transaction)) {
      fieldStatus = IntervenantValidator.validateAgent(deposit.agent || { type: PERSONNE_PHYSIQUE.value })
    } else if (isFilled(deposit.coDepositors)) {
      // mandataire obligatoire si des co-déposants sont présents
      fieldStatus = IntervenantValidator.validateAgent({ type: PERSONNE_PHYSIQUE.value })
    } else {
      fieldStatus = {}
    }

    return fieldStatus
  }

  validateOnChangeStep = () => {
    const deposit = store.getState().deposit

    const fieldStatus = this.validateMandatory({
      file: {
        ...deposit
      }
    })

    store.dispatch(storeDepositFieldStatusUpdate({
      agent: fieldStatus
    }))

    return !containsErrors(fieldStatus)
  }
}

export default new AgentValidator()
