import Message from './Message'
import {
  STATUS_EXTENDABLE_10_YEARS,
  STATUS_EXTENDABLE_15_YEARS,
  STATUS_EXTENDABLE_20_YEARS, STATUS_EXTENDABLE_25_YEARS,
  STATUS_REGISTERED_5_YEARS
} from '@inpi-dm/components'

export const PAYMENT_METHOD = {
  BLUE_CARD: 'CB',
  INPI_ACCOUNT: 'CCL',
  MANAGEABLE_MEMORY: 'VIR',
  FREE: 'FREE'
}

export const PAYMENT_OBJECT = {
  TRANSACTION: 'transaction',
  CORRECTION: 'correction_transaction',
  NOTIFICATION: 'transaction_notification',
  PUBLICATION_REPRODUCTION: 'transaction_reproduction',
  DIVISION: 'transaction_subdivision',
  TRANSFORMATION_TN_TA: 'transformation_ta_transaction'
}

export const COLORS = 'COULEUR'
export const BLACK_AND_WHITE = 'NB'

export const REPRODUCTION_PUBLISHED_STATE = [
  STATUS_REGISTERED_5_YEARS,
  STATUS_EXTENDABLE_10_YEARS,
  STATUS_EXTENDABLE_15_YEARS,
  STATUS_EXTENDABLE_20_YEARS,
  STATUS_EXTENDABLE_25_YEARS
]

export const REPRODUCTION_TABLE_TITLES = [
  {
    label: '',
    className: 'table-checkbox'
  }, {
    label: Message.reproduction_table_numero,
    className: 'reproduction-number'
  }, {
    label: Message.reproduction_table_image,
    className: 'reproduction-thumbnail'
  }, {
    label: Message.reproduction_table_title
  }, {
    label: Message.reproduction_table_description
  }, {
    label: Message.reproduction_table_state
  }
]
