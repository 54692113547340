import React, { FC, useState, useEffect } from 'react'
import { CardBlock, CheckboxField, ErrorField, NatureCode, Transaction, TextField, EventType } from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'
import NatureCodeService from '../../../../services/transaction/NatureCodeService'
import { storeInscriptionUpdate } from '../../../../store/inscription/inscriptionActions'
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux'
import { cloneDeep } from 'lodash'

interface NatureCodesBlockProps {
  transaction:Transaction,
  title:string,
  isMultiChoices:boolean
}

const NatureCodesBlock:FC<NatureCodesBlockProps> = ({
  transaction,
  title,
  isMultiChoices
}) => {
  const [natureCodes, setNatureCodes] = useState<NatureCode[]>([])
  const fieldStatus = useSelector((state:RootStateOrAny) => state.inscriptionFieldStatus)
  const dispatch = useDispatch()

  useEffect(() => {
    NatureCodeService.getNatureCodes({ type: transaction.subProcedureType }).then((naturesCodes) => {
      // Tri des codes natures par positions asc
      const sortedNatureCodes = naturesCodes.sort((a:NatureCode, b:NatureCode) => (a.position || Infinity) - (b.position || Infinity))

      // On map chaque code nature avec la liste des codes natures de l'inscription pour set les champs à true et ajouter la description
      sortedNatureCodes.map((sortedNatureCode:NatureCode) => {
        transaction.inscription && transaction.inscription.natureCodes.forEach((natureCode:NatureCode) => {
          if (sortedNatureCode.code === natureCode.code) {
            sortedNatureCode.value = true
            sortedNatureCode.description = natureCode.description
          }
        })
      })
      setNatureCodes(sortedNatureCodes)
    })
  }, [])

  /**
   * Permet de modifier la valeur d'un code nature
   * @param index
   * @param value
   */
  const onSelectNatureCodes = (index:number, value?:boolean|undefined) => {
    const natureCodesCopies = [...natureCodes]

    // Réinitialisation de la sélection
    // Si on clique sur une checkbox n'appartenant pas à la position des checkbox au préalable cochés, on les reset à false
    if (!isMultiChoices || !natureCodes.filter((natureCode) => natureCode.value === true).every((natureCode) => (natureCodesCopies[index].position && natureCode.position === natureCodesCopies[index].position))) {
      natureCodes.forEach((natureCodesCopie, index) => {
        natureCodesCopies[index].value = undefined
      })
    }

    natureCodesCopies[index].value = value
    dispatch(storeInscriptionUpdate({
      inscription: {
        ...transaction.inscription,
        natureCodes: cloneDeep(natureCodesCopies.filter((natureCode) => natureCode.value === true))
      }
    }))
  }

  /**
   * Permet de modifier la description de la nature de l'opération
   * @param index
   * @param value
   */
  const onChangeDescription = (index:number, value?:string|undefined) => {
    const natureCodesCopies = [...natureCodes]
    natureCodesCopies[index].description = value
    dispatch(storeInscriptionUpdate({
      inscription: {
        ...transaction.inscription,
        natureCodes: cloneDeep(natureCodesCopies.filter((natureCode) => natureCode.value === true))
      }
    }))
  }

  return (
    <CardBlock
      header={<>{title}<span className='text-danger'> *</span></>} shadow
      bodyClassName={!isMultiChoices ? 'flex-row flex-wrap align-items-center' : ''}
    >
      {
        natureCodes.map((natureCode:NatureCode, index:number) => (
          <div key={index} className={!isMultiChoices ? 'col-6' : ''}>
            {isMultiChoices && index !== 0 && ((natureCode.position !== natureCodes[index - 1].position) || (!natureCode.position)) &&
              <div className='d-flex mb-3'>
                <hr className='col-4' />
                <div className='col-2 text-center p-1 font-weight-bold text-uppercase'>
                  <span><FormattedMessage id='or' /></span>
                </div>
                <hr className='col-4' />
              </div>}
            {isMultiChoices
              ? (
                <CheckboxField
                  inputId={`codeNature-${index}`}
                  label={natureCode.label}
                  onChange={() => onSelectNatureCodes(index, !natureCode.value)}
                  checked={natureCode.value}
                />)
              : (
                <div key={index} className='form-check form-check-inline mb-3'>
                  <input
                    className='form-check-input'
                    type='radio'
                    id={`codeNature-radio-${index}`}
                    checked={!!natureCode.value}
                    onChange={() => onSelectNatureCodes(index, true)}
                  />
                  <label className='form-check-label' htmlFor={`codeNature-radio-${index}`}>{natureCode.label}</label>
                </div>
              )}
            {natureCode.displayText && natureCode.value &&
              <TextField
                inputId={`codeNatureDesc-${index}`}
                value={natureCode.description}
                onChange={(event: EventType) => onChangeDescription(index, event.target.value)}
              />}
          </div>
        ))
      }
      <ErrorField message={fieldStatus.natureCodes} className='relative-position' />
    </CardBlock>
  )
}

export default NatureCodesBlock
