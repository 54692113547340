import { DEFAULT_INTERVENANT, Transaction } from '@inpi-dm/components'
import {
  OFFICIAL_DOCUMENT_FIELD_STATUS_RESET,
  OFFICIAL_DOCUMENT_FIELD_STATUS_UPDATE,
  OFFICIAL_DOCUMENT_REMOVE,
  OFFICIAL_DOCUMENT_UPDATE, OfficialDocumentFieldStatusStoreAction,
  OfficialDocumentStoreAction

} from './officialDocumentStoreTypes'
import { OfficialDocumentFieldStatus } from '../../interfaces/OfficialDocumentInterfaces'

const initialOfficiDocumentState: Transaction = {
  officialDocument: {
    titles: []
  },
  depositor: DEFAULT_INTERVENANT
}

/* Contient les erreurs liées au formulaire de document officiel */
const initialOfficialDocumentStatusState: OfficialDocumentFieldStatus = {}

export function reducerOfficialDocumentState (state = initialOfficiDocumentState, action: OfficialDocumentStoreAction): Transaction {
  switch (action.type) {
    case OFFICIAL_DOCUMENT_UPDATE:
      return {
        ...state,
        ...action.officialDocument,
        officialDocument: {
          ...state.officialDocument,
          ...action.officialDocument?.officialDocument
        }
      }
    case OFFICIAL_DOCUMENT_REMOVE:
      return initialOfficiDocumentState
    default:
      return state
  }
}

export function reducerOfficialDocumentFieldStatusState (state = initialOfficialDocumentStatusState, action: OfficialDocumentFieldStatusStoreAction): OfficialDocumentFieldStatus {
  switch (action.type) {
    case OFFICIAL_DOCUMENT_FIELD_STATUS_UPDATE:
      return {
        ...state,
        ...action.fieldStatus
      }
    case OFFICIAL_DOCUMENT_FIELD_STATUS_RESET:
      return initialOfficialDocumentStatusState
    default:
      return state
  }
}
