import React, { FC, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  CardBlock, CheckboxField,
  ModelDeposit,
  Preview,
  ReproductionLightDeposit
} from '@inpi-dm/components'
import RecordService from '../../../../services/record/RecordService'
import { Accordion, Card, AccordionContext } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import OneReproductionSearchSelection from './OneReproductionSearchSelection'

interface ContextAwareToggleProps {
  eventKey: string,
}

const ContextAwareToggle : FC<ContextAwareToggleProps> = ({ eventKey }) => {
  const currentEventKey = useContext(AccordionContext)

  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <div className={isCurrentEventKey ? 'mr-1' : 'mr-2'}>
      <FontAwesomeIcon icon={isCurrentEventKey ? faChevronDown : faChevronRight} />
    </div>
  )
}

interface OneModelSearchSelectionProps {
  model: ModelDeposit,
  allPublications: (string|undefined)[],
  idRecord: string,
  indexModel: number,
  checkPublication: (indexModel: number|null, indexReproduction: number|null, value: boolean) => void,
  publicationSelected: string[]
}

const OneModelSearchSelection: FC<OneModelSearchSelectionProps> = ({
  model,
  allPublications,
  idRecord,
  indexModel,
  checkPublication,
  publicationSelected
}) => {
  /**
   * Récupère la 1ere image de ce modèle lié à cette recherche
   */
  const getFirstReproduction = async () => {
    for (const reproduction of model.reproductions) {
      for (const publication of reproduction.publications || []) {
        if (publication.idPublication && allPublications.includes(publication.idPublication)) {
          return RecordService.getReproductionPreview(reproduction, idRecord, model.id)
        }
      }
    }
  }

  /**
   * Récupère les reproductions a afficher
   * @param reproduction
   */
  const filterReproduction = (reproduction: ReproductionLightDeposit) => {
    for (const publication of reproduction.publications || []) {
      if (publication.idPublication && allPublications.includes(publication.idPublication)) {
        return true
      }
    }

    return false
  }

  /**
   * Récupère le nombre de reproductions
   */
  const getNumberReproduction = () => {
    return model.reproductions.filter(reproduction => filterReproduction(reproduction)).length
  }

  /**
   * Vérifie si le modèle est check
   */
  const isCheck = () : boolean => {
    for (const reproduction of model.reproductions) {
      for (const publication of reproduction.publications || []) {
        if (publication.idPublication && !publicationSelected.includes(publication.idPublication)) {
          return false
        }
      }
    }

    return true
  }

  return (
    <Accordion>
      <CardBlock
        shadow
        className='p-0 mb-3'
        bodyClassName='p-0 px-1'
      >
        <div className='row'>
          <Accordion.Toggle as={Card.Header} className='d-flex align-items-center bg-transparent cursor-pointer col-11' variant='link' eventKey={indexModel.toString()}>
            <ContextAwareToggle eventKey={indexModel.toString()} />
            <div className='col-1'>
              <CheckboxField
                inputId={`selectModel-${indexModel}`}
                label=''
                className='m-auto'
                checked={isCheck()}
                onChange={() => checkPublication(indexModel, null, !isCheck())}
              />
            </div>
            <div className='col-1 reproduction'>
              <Preview
                className='img-thumbnail m-auto'
                file={() => getFirstReproduction()}
              />
            </div>
            <div className='col-4'>
              <FormattedMessage id='model_deposit_form_number' />{indexModel + 1}
              <span className='normal text-lowercase'>
                {` (${getNumberReproduction()} `}
                <FormattedMessage id='request_number_reproduction' />
                {getNumberReproduction() > 1 ? 's) ' : ')'}
              </span>
            </div>
            <div className='normal col-4'>
              {model.modelDesignation && model.modelDesignation.ref
                ? `${model.modelDesignation.ref}. ${model.modelDesignation.name}`
                : `${model.modelDesignation.name}`}
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={indexModel.toString()} className='w-100'>
            <div className='d-flex justify-content-center'>
              <Card.Body className='p-0 col-10'>
                {
                  model.reproductions
                    .filter(reproduction => filterReproduction(reproduction))
                    .map((reproduction, index) => (
                      <OneReproductionSearchSelection
                        key={reproduction.id}
                        reproduction={reproduction}
                        idRecord={idRecord}
                        idModel={model.id}
                        indexModel={indexModel}
                        indexRepro={index}
                        checkPublication={checkPublication}
                        publicationSelected={publicationSelected}
                      />
                    ))
                }
              </Card.Body>
            </div>
          </Accordion.Collapse>
        </div>
      </CardBlock>
    </Accordion>
  )
}

export default OneModelSearchSelection
