export const STATUS_DO = {
  /** Brouillon client */
  DRAFT_CLIENT: 'draft_client_do',
  /** Brouillon */
  DRAFT: 'draft_do',
  /** A attribuer */
  TO_ATTRIBUTE: 'to_attribute_do',
  /** A examiner */
  TO_REVIEW: 'to_review_do',
  /** Suspendu */
  SUSPENDED: 'suspended_do',
  /** A rejeter */
  TO_REJECT: 'to_reject_do',
  /** Traité */
  PROCESSED: 'processed_do',
  /** Rejeté */
  REJECTED: 'rejected_do'
}
