import React, { FC } from 'react'
import { EventType, FieldStatus, TextField } from '@inpi-dm/components'
import { useIntl } from 'react-intl'
import { DepositIntervenant } from '../..'

interface PersonneFormationFieldsProps {
    intervenant?: DepositIntervenant,
    onChange: (event: EventType|EventType[]) => void,
    fieldStatus?: FieldStatus
}

const PersonneFormationFields: FC<PersonneFormationFieldsProps> = ({
  intervenant = {},
  onChange,
  fieldStatus
}) => {
  const intl = useIntl()

  return (
    <div className='row'>
      <div className='col-12 col-md-4'>
        <TextField
          inputId='companyNameFormation'
          label={intl.formatMessage({ id: 'field_raison_sociale_formation_label' })}
          value={intervenant.companyNameFormation || ''}
          onChange={onChange}
          fieldStatus={fieldStatus}
          maxLength='120'
          required
        />
      </div>
      <div className='col-12 col-md-4'>
        <TextField
          inputId='legalFormFormation'
          label={intl.formatMessage({ id: 'field_forme_juridique_formation_label' })}
          value={intervenant.legalFormFormation || ''}
          onChange={onChange}
          fieldStatus={fieldStatus}
          maxLength='100'
          required
        />
      </div>
    </div>
  )
}

export default PersonneFormationFields
