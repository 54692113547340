import React, { FC, ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrashAlt, faUndoAlt } from '@fortawesome/free-solid-svg-icons'
import {
  Country,
  DepositIntervenant, Transaction,
  ManageableQuality,
  OverviewComparedIntervenant
} from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'

interface DepositCorrectionIntervenantProps {
    title: ReactNode,
    intervenant?: DepositIntervenant|string,
    updatedIntervenant?: DepositIntervenant|string,
    correctedDeposit?: Transaction,
    onEdit: () => void,
    onReset: () => void,
    onDelete?: () => void,
    countries?: Country[],
    qualities?: ManageableQuality[],
    errorMessage?: string|null,
    deposit?: Transaction|null
}

const DepositCorrectionIntervenant: FC<DepositCorrectionIntervenantProps> = ({
  title,
  intervenant,
  updatedIntervenant,
  correctedDeposit,
  onEdit,
  onReset,
  onDelete,
  countries = [],
  qualities = [],
  errorMessage,
  deposit
}) => (
  <div className='col-12 mb-3'>
    <h4>
      {title}
      <button
        className='btn btn-icon-primary ml-3'
        onClick={onEdit}
      >
        <FontAwesomeIcon icon={faPen} />
      </button>
      {updatedIntervenant !== intervenant && (
        <button
          className='btn btn-icon-gris ml-2'
          onClick={onReset}
        >
          <FontAwesomeIcon icon={faUndoAlt} />
        </button>
      )}
      {intervenant && onDelete && (
        <button
          className='btn btn-icon-danger ml-2'
          onClick={onDelete}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      )}
    </h4>

    <OverviewComparedIntervenant
      intervenant={intervenant}
      correctedDeposit={correctedDeposit}
      correctedIntervenant={updatedIntervenant}
      countries={countries}
      qualities={qualities}
      deposit={deposit}
    />
    {errorMessage &&
      <div className='text-danger'>
        <FormattedMessage id={errorMessage} />
      </div>}
  </div>
)

export default DepositCorrectionIntervenant
