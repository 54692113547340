import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import {
  SubmitButton,
  EventType,
  TextField,
  buildEventType,
  FieldStatus,
  FileBrowserField,
  ErrorField,
  containsErrors,
  DepositFile,
  CountrySelectField, DateUtils
} from '@inpi-dm/components'
import OptionsFormDepositValidator from '../validator/OptionsFormDepositValidator'
import { DepositPriorityOptions } from '../../../../../interfaces/DepositInterfaces'
import DepositService from '../../../../../services/transaction/DepositService'
import { storeDepositFieldStatusUpdate, storeDepositUpdate } from '../../../../../store/deposit/depositActions'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import TransactionService from '../../../../../services/transaction/TransactionService'
import ContentService from '../../../../../services/content/ContentService'

interface PrioritesFormProps extends WrappedComponentProps {
  nbCurrent: number,
  initialPriority?: DepositPriorityOptions
  onSavePriority: () => void
}

const PriorityForm: FC<PrioritesFormProps> = ({
  initialPriority = {},
  nbCurrent,
  onSavePriority,
  intl
}) => {
  const dispatch = useDispatch()
  const deposit = useSelector((state: RootStateOrAny) => state.deposit)
  const depositFieldStatus = useSelector((state: RootStateOrAny) => state.depositFieldStatus)
  const prioritiesFieldStatus = depositFieldStatus.priorities || []
  const [priority, setPriority] = useState<DepositPriorityOptions>(initialPriority)
  const [deletedDocuments, setDeletedDocuments] = useState<DepositFile[]>([])
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>(prioritiesFieldStatus[nbCurrent] || {})

  /**
   * Enregistrement des données dans le state à la saisie
   * @param event
   */
  const handleChange = (event: EventType) => {
    const { name, value } = event.target

    const updatedPriority = {
      ...priority,
      [name]: value
    }

    setPriority(updatedPriority)
  }

  /**
   * Mise à jour du jour mois année, si les informations sont déjà remplis
   */
  const getDateDayMonthYear = () => {
    let day = ''
    let month = ''
    let year = ''

    if (priority.originalDepositDate) {
      const date = new Date(priority.originalDepositDate)
      day = date.getDate().toString().padStart(2, '0')
      // Le mois commence à 0 en JS
      month = (date.getMonth() + 1).toString().padStart(2, '0')
      year = date.getFullYear().toString()
    }

    const updatedPriority : DepositPriorityOptions = {
      ...priority,
      originalDepositDateDay: day,
      originalDepositDateMonth: month,
      originalDepositDateYear: year
    }

    setPriority(updatedPriority)
  }

  /**
   * Enregistrement des documents dans la priorité courante
   * @param event
   */
  const handleAddPriorityDocuments = (event: EventType) => {
    const { name, value } = event.target
    const depositDocuments : DepositFile[] = priority.documents || []

    const newDocument = {
      name: value.name,
      file: value
    }
    handleChange(buildEventType(name, [...depositDocuments, newDocument]))
  }

  /**
   * Supprime un document dans le store et sur le serveur
   * @param event
   * @param index
   */
  const handleDeletePriorityDocuments = (event: EventType, index: number) => {
    const { name } = event.target
    const depositDocuments : DepositFile[] = priority.documents || []

    const updatedDocuments = [...depositDocuments]
    const removedDocument = updatedDocuments.splice(index, 1)

    handleChange(buildEventType(name, updatedDocuments))

    // Si le document a déjà été enregistré, alors on le signale comme à supprimer
    if (removedDocument && removedDocument[0].internalName) {
      setDeletedDocuments([...deletedDocuments, removedDocument[0]])
    }
  }

  useEffect(getDateDayMonthYear, [])

  /**
   * Sauvegarde toutes les informations de la priorité en cours de modification
   */
  const savePriority = async () => {
    const idTransaction = deposit.idTransaction
    const priorityToSave = {
      ...priority,
      originalDepositDate: DateUtils.formateDateToFormat(`${priority.originalDepositDateYear}-${priority.originalDepositDateMonth}-${priority.originalDepositDateDay}`)
    }

    // Enregistrement de la priorité
    let transaction
    let priorityId = priority.id
    if (priority.id) {
      await DepositService.updateDepositPriority(idTransaction, priority.id, priorityToSave)
    } else {
      transaction = await DepositService.postDepositPriority(idTransaction, priorityToSave)
      priorityId = transaction.file.priorities[transaction.file.priorities.length - 1]?.id
    }

    // Suppression des anciens fichiers
    for (const file of deletedDocuments) {
      await TransactionService.deleteDocumentFile(idTransaction, file.internalName)
    }

    // Sauvegarde des fichiers ajoutés
    if (priority.documents) {
      for (const file of priority.documents.filter(doc => !doc.internalName)) {
        await DepositService.uploadDepositPriorityFile(idTransaction, priorityId, file.file)
      }
    }

    // On attend la fin de la mise à jour des fichiers, pour récupérer le dépôt à jour
    transaction = await TransactionService.getTransaction(idTransaction)

    // Mide à jour field status et liste des priorités
    dispatch(storeDepositUpdate({
      priorities: transaction.file.priorities
    }))

    // Si la sauvegarde est possible alors c'est qu'aucune erreur n'a été détectée
    const updatedFieldStatus = [...prioritiesFieldStatus]
    updatedFieldStatus[nbCurrent] = {}

    dispatch(storeDepositFieldStatusUpdate({
      priorities: updatedFieldStatus
    }))

    onSavePriority()
  }

  const validatePriorityFields = () => {
    const newFieldStatus = OptionsFormDepositValidator.validatePriority(priority)
    setFieldStatus(newFieldStatus)

    if (!containsErrors(newFieldStatus)) {
      return savePriority()
    } else {
      return newFieldStatus
    }
  }

  /**
   * Autorise uniquement les caractères digitaux dans un champ
   */
  const handleChangeDate = (event: EventType) => {
    if (/^[0-9]*$/.test(event.target.value)) {
      handleChange(event)
    }
  }

  return (
    <div className='mb-4 mt-3'>
      <h3>
        <FormattedMessage id='options_depot_form_priorites' />{nbCurrent + 1}
      </h3>
      <div className='row'>
        <div className='col-12 col-md-6'>
          <CountrySelectField
            inputId='countryOrOrganization'
            label={<FormattedMessage id='field_pays_organisme_label' />}
            value={priority.countryOrOrganization || ''}
            onChange={handleChange}
            fieldStatus={fieldStatus}
            getCountries={ContentService.getCountries}
            placeholder={intl.formatMessage({ id: 'field_pays_organisme_placeholder' })}
            required
            isPriority
          />
        </div>
        <div className='col-12 col-md-6'>
          <TextField
            inputId='originalDepositNumber'
            label={<FormattedMessage id='field_numero_depot_origine_label' />}
            value={priority.originalDepositNumber || ''}
            onChange={handleChange}
            fieldStatus={fieldStatus}
            maxLength='20'
            required
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-md-6'>
          <div className='form-group'>
            <label className='form-label'><FormattedMessage id='field_date_depot_origine_label' /><span className='text-danger'> *</span></label>
            <div className='row m-0'>
              <input
                type='text'
                id='originalDepositDateDay'
                name='originalDepositDateDay'
                className='form-control col-2'
                placeholder='JJ'
                maxLength={2}
                value={priority.originalDepositDateDay || ''}
                onChange={handleChangeDate}
              />
              <span className='my-auto mx-2'>/</span>
              <input
                type='text'
                id='originalDepositDateMonth'
                name='originalDepositDateMonth'
                className='form-control col-2'
                placeholder='MM'
                maxLength={2}
                value={priority.originalDepositDateMonth || ''}
                onChange={handleChangeDate}
              />
              <span className='my-auto mx-2'>/</span>
              <input
                type='text'
                id='originalDepositDateYear'
                name='originalDepositDateYear'
                className='form-control col-3'
                placeholder='AAAA'
                value={priority.originalDepositDateYear || ''}
                onChange={handleChangeDate}
              />
            </div>
            <ErrorField message={fieldStatus.originalDepositDate} />
          </div>

        </div>
        <div className='col-12 col-md-6'>
          <TextField
            inputId='originalDepositorName'
            label={<FormattedMessage id='field_nom_deposant_origine_label' />}
            value={priority.originalDepositorName || ''}
            onChange={handleChange}
            fieldStatus={fieldStatus}
            maxLength='50'
            required
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-md-4'>
          <FileBrowserField
            inputId='documents'
            label={<FormattedMessage id='field_document_priorites_label' />}
            butonLabel={
              <div className='border'>
                <div className='text-center my-1'>
                  <FormattedMessage id='button_find_file' />
                </div>
              </div>
            }
            acceptApplication='application/pdf'
            value={priority.documents}
            onChange={handleAddPriorityDocuments}
            onDelete={handleDeletePriorityDocuments}
            fieldStatus={fieldStatus}
          />
        </div>
      </div>
      <div className='row justify-content-between'>
        <div className='col-12 col-md-4'>
          <SubmitButton
            className='btn-block btn-outline-gris'
            onClick={() => onSavePriority()}
          >
            <FormattedMessage id='button_cancel' />
          </SubmitButton>
        </div>
        <div className='col-12 col-md-4'>
          <SubmitButton
            className='btn-block btn-outline-primary'
            onClick={validatePriorityFields}
          >
            <FormattedMessage id='options_depot_form_priorites_button_validate' />
          </SubmitButton>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(PriorityForm)
