import React, { FC, useState, useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import OverviewModels from './OverviewModels'
import {
  CardBlock,
  Transaction, DOCUMENT_TYPES,
  OverviewComparedIntervenant,
  isFilled,
  Country,
  ManageableQuality, STATUS_DEPOSIT, DepositDocument, MODEL_OPEN, MODEL_CLOSE
} from '@inpi-dm/components'
import OverviewNotifications from './notifications/OverviewNotifications'
import ContentService from '../../../../services/content/ContentService'
import DepositService from '../../../../services/transaction/DepositService'
import OverviewHeaderModels from './reproductions/OverviewHeaderModels'
import { REPRODUCTIONS_VIEW_MODE } from '../../../../constants/RequestConstants'
import ListReproductions from './reproductions/ListReproductions'
import CorrectionService from '../../../../services/transaction/CorrectionService'
import DocumentBlock from '../../../requests/DocumentBlock'
import {
  NOTIF_STATUS_VISIBLE_TO_FO,
  NOTIF_STATUS_WAITING_ANSWER,
  SENDING_PORTAL
} from '@inpi-dm/components/src/constants/NotificationsConstants'
import DownloadLink from '../../download/DownloadLink'
import ReproductionService from '../../../../services/transaction/ReproductionService'
import OverviewGeneralInformation from './OverviewGeneralInformation'
import OverviewOptions from './OverviewOptions'

interface OverviewDepositProps {
  transaction: Transaction,
  modeReproduction?: string
  onChangeModeReproduction?: (mode: string) => void,
  onUpdateDeposit?: () => any,
  setReload?: () => void,
  depositView?: boolean | null
}

const OverviewDeposit: FC<OverviewDepositProps> = ({
  transaction,
  modeReproduction = REPRODUCTIONS_VIEW_MODE.OVERVIEW,
  onChangeModeReproduction,
  onUpdateDeposit,
  setReload,
  depositView
}) => {
  const [countries, setCountries] = useState<Country[]>()
  const [manageableQualities, setManageableQualities] = useState<ManageableQuality[]>()
  const [hasReproRegularization, setHasReproRegularization] = useState(false)
  const [agentDocument, setAgentDocument] = useState()
  const [isEditableRepro, setIsEditableRepro] = useState(false)
  const [isAllOpen, setIsAllOpen] = useState()
  const reproReference = useRef(null)
  const notificationReference = useRef(null)

  useEffect(() => {
    let unmounted = false
    ContentService.getManageableQualities().then(result => {
      if (!unmounted) {
        setManageableQualities(result)
      }
    })

    ContentService.getCountries().then(result => {
      if (!unmounted) {
        setCountries(result)
      }
    })

    if (!unmounted) {
      setAgentDocument(DepositService.getAgentDocument(transaction))
    }

    return () => {
      unmounted = true
      ContentService.cancelRequest()
      DepositService.cancelRequest()
    }
  }, [])

  useEffect(() => {
    const notifRegularization = transaction?.notifications?.find(element => element.hasReproRegularization && element.status === NOTIF_STATUS_WAITING_ANSWER)
    setHasReproRegularization(!!notifRegularization)
  }, [transaction.notifications])

  const deposit = transaction.file
  const correction = CorrectionService.findCorrectionInReview(transaction)
  const correctedDeposit = correction ? CorrectionService.mergeCorrectionInDeposit(transaction, correction) : undefined

  const coDepositorsLength = deposit?.coDepositors?.length || 0
  const correctedCoDepositorsLength = correctedDeposit?.file.coDepositors?.length || 0
  const coDepositorsSrc = coDepositorsLength > correctedCoDepositorsLength
    ? deposit?.coDepositors
    : correctedDeposit?.file.coDepositors

  /**
   * Permet de récupérer les reproductions à regulariser
   */
  const getRegularizeReproduction = () => {
    const docs: DepositDocument[] = []

    transaction.notifications && transaction.notifications.forEach((notification) => {
      if (notification.supportingDocuments) {
        docs.push(...notification.supportingDocuments.filter(element => element.type === DOCUMENT_TYPES.DOCUMENT_TYPE_REGULARIZE_REPRO))
      }
    })
    return docs
  }

  return (
    <div className='row'>
      {(!depositView && transaction.notifications && transaction.notifications
        .filter((notification) => NOTIF_STATUS_VISIBLE_TO_FO.includes(notification.status) && notification.sendingMode && notification.sendingMode.includes(SENDING_PORTAL))
        .length > 0)
        ? (
          <div className='col-12 mb-3'>
            <CardBlock
              header={<FormattedMessage id='overview_deposit_card_notification_title' />}
              shadow
            >
              <OverviewNotifications
                transaction={transaction}
                setReload={setReload}
                reproReference={reproReference}
                setIsEditableRepro={setIsEditableRepro}
                notificationReference={notificationReference}
              />
            </CardBlock>
          </div>
        )
        : null}
      {
        (!depositView && transaction.status !== STATUS_DEPOSIT.DRAFT) &&
          <div className='col-12 mb-3'>
            <DocumentBlock
              transaction={transaction}
              setReload={() => setReload && setReload()}
              blockTitle={<FormattedMessage id='request_document_title' />}
            />
          </div>
      }
      {!depositView
        ? (
          <>
            <div className='col-12 col-md-6 mb-3'>
              <CardBlock
                header={<FormattedMessage id='overview_deposit_card_general_info_title' />}
                shadow
              >
                <div className='row'>
                  <OverviewGeneralInformation deposit={deposit} depositView={depositView} />
                </div>
              </CardBlock>
            </div>
            <div className='col-12 col-md-6 mb-3'>
              <CardBlock
                header={<FormattedMessage id='overview_deposit_card_options_title' />}
                shadow
              >
                <div className='row'>
                  <OverviewOptions deposit={deposit} depositView={depositView} transaction={transaction} />
                </div>
              </CardBlock>
            </div>
          </>
        )
        : (
          <div className='col-12 mb-3'>
            <CardBlock
              header={<FormattedMessage id='overview_deposit_card_general_info_title' />}
              shadow
            >
              <div className='row'>
                <OverviewGeneralInformation deposit={deposit} depositView={depositView} />
                <OverviewOptions deposit={deposit} depositView={depositView} transaction={transaction} />
              </div>
            </CardBlock>
          </div>
        )}
      <div className='col-12 mb-3'>
        <CardBlock
          header={
            <div className='d-flex justify-content-between'>
              <FormattedMessage id='overview_deposit_card_intervenant_title' />
              {correctedDeposit?.file && <span className='text-normalcase text-primary'><FormattedMessage id='overview_deposit_card_intervenant_title_corrected' /></span>}
            </div>
          }
          shadow
        >
          {deposit &&
            <div className='row'>
              {deposit.depositor && (
                <div className={`col-12 mb-3 ${correctedDeposit?.file ? '' : 'col-md-6'}`}>
                  <h4><FormattedMessage id='overview_deposit_depositor_title' /></h4>
                  <OverviewComparedIntervenant
                    intervenant={deposit.depositor}
                    correctedDeposit={correctedDeposit}
                    correctedIntervenant={correctedDeposit?.file.depositor}
                    countries={countries}
                  />
                </div>
              )}
              {isFilled(coDepositorsSrc) && (
                <div className='col-12 mb-3 col-md-6'>
                  <h4><FormattedMessage id='overview_deposit_codepositor_title' /></h4>
                  {
                      coDepositorsSrc?.map((p, index) => (
                        <OverviewComparedIntervenant
                          key={index}
                          intervenant={deposit?.coDepositors?.[index]}
                          correctedDeposit={correctedDeposit}
                          correctedIntervenant={correctedDeposit?.file.coDepositors?.[index]}
                          countries={countries}
                        />
                      ))
                  }
                </div>
              )}
              {deposit.recipient && (
                <div className={`col-12 mb-3 ${correctedDeposit ? '' : 'col-md-6'}`}>
                  <h4><FormattedMessage id='overview_deposit_receiver_title' /></h4>
                  <OverviewComparedIntervenant
                    intervenant={deposit.recipient}
                    correctedDeposit={correctedDeposit}
                    correctedIntervenant={correctedDeposit?.file.recipient}
                    countries={countries}
                    deposit={{ file: deposit }}
                  />
                </div>
              )}
              {(deposit.agent || correctedDeposit?.file.agent) && (
                <div className={`col-12 mb-3 ${correctedDeposit ? '' : 'col-md-6'}`}>
                  <h4><FormattedMessage id='overview_deposit_mandatary_title' /></h4>
                  <OverviewComparedIntervenant
                    intervenant={deposit.agent}
                    correctedDeposit={correctedDeposit}
                    correctedIntervenant={correctedDeposit?.file.agent}
                    countries={countries}
                    qualities={manageableQualities}
                    deposit={{ file: deposit }}
                  />
                  {
                    agentDocument &&
                      <DownloadLink
                        key={agentDocument.internalName}
                        id={transaction.id}
                        name={agentDocument.name}
                        internalName={agentDocument.internalName}
                        label={agentDocument.name}
                        classname='justify-content-start'
                      />
                  }
                </div>
              )}
              {(deposit.creators || correctedDeposit?.file.creators) && (
                <div className={`col-12 mb-3 ${correctedDeposit ? '' : 'col-md-6'}`}>
                  <h4><FormattedMessage id='overview_deposit_creators_title' /></h4>
                  <OverviewComparedIntervenant
                    intervenant={deposit.creators}
                    correctedDeposit={correctedDeposit}
                    correctedIntervenant={correctedDeposit?.file.creators}
                  />
                </div>
              )}
              {(deposit.signatory || correctedDeposit?.file.signatory) && (
                <div className={`col-12 mb-3 ${correctedDeposit?.file ? '' : 'col-md-6'}`}>
                  <h4><FormattedMessage id='overview_deposit_signatory_title' /></h4>
                  <OverviewComparedIntervenant
                    intervenant={deposit.signatory}
                    correctedDeposit={correctedDeposit}
                    correctedIntervenant={correctedDeposit?.file.signatory}
                    qualities={manageableQualities}
                  />
                </div>
              )}
            </div>}
        </CardBlock>
      </div>
      <div className='col-12 mb-3' id='overviewModel'>
        <CardBlock
          header={
            <OverviewHeaderModels
              mode={modeReproduction}
              onChangeMode={onChangeModeReproduction}
              hasReproRegularization={hasReproRegularization}
              reproReference={reproReference}
              isEditable={isEditableRepro}
              setIsEditable={setIsEditableRepro}
              transactionStatus={transaction.status}
              depositView={depositView}
              isAllOpen={isAllOpen}
              setIsAllOpen={setIsAllOpen}
            />
          }
          shadow
        >
          {modeReproduction === REPRODUCTIONS_VIEW_MODE.OVERVIEW && (
            <OverviewModels
              depositId={transaction.id!}
              models={depositView ? ReproductionService.filterPublishedReproductions(deposit?.models) : deposit?.models}
              priorities={deposit?.priorities}
              countries={countries}
              documents={getRegularizeReproduction()}
              notifications={transaction.notifications}
              setReload={setReload || (() => {})}
              hasReproRegularization={hasReproRegularization}
              isEditable={isEditableRepro && !depositView}
              setIsEditable={setIsEditableRepro}
              transaction={transaction}
              notificationReference={notificationReference}
              depositView={depositView}
              toggle={isAllOpen ? MODEL_OPEN : MODEL_CLOSE}
            />
          )}
          {modeReproduction === REPRODUCTIONS_VIEW_MODE.SELECT && (
            <ListReproductions
              transaction={transaction}
              initialModels={deposit.models}
              onUpdateDeposit={onUpdateDeposit}
            />
          )}
        </CardBlock>
      </div>
    </div>
  )
}

export default OverviewDeposit
