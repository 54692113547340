import React, { FC, useEffect, useState } from 'react'
import {
  CheckboxField, Transaction,
  ModelDeposit,
  RequestListTable
} from '@inpi-dm/components'
import { REPRODUCTION_TABLE_TITLES } from '../../../../../constants/DepositConstants'
import ListReproductionsTableBody from './ListReproductionsTableBody'
import WithdrawReproductionAction from './WithdrawReproductionAction'
import { FormattedMessage } from 'react-intl'
import ReproductionService from '../../../../../services/transaction/ReproductionService'
import PublishReproductionAction from './PublishReproductionAction'

interface ListReproductionsProps {
    transaction: Transaction,
    initialModels?: ModelDeposit[],
    onUpdateDeposit?: () => any
}

const ListReproductions: FC<ListReproductionsProps> = ({
  transaction,
  initialModels = [],
  onUpdateDeposit
}) => {
  const [models, setModels] = useState<ModelDeposit[]>([])

  useEffect(() => setModels(initialModels), [initialModels])

  /**
     * Sélectionne ou déselectionne une reproduction ou une partie des reproductions
     *
     * @param checked La nouvelle valeur de sélection
     * @param indexModel Index du modèle contenant les reproductions à modifier
     * @param indexReproduction Index de la reproduction dans le modèle à modifier
     */
  const handleCheckReproductions = (checked: boolean, indexModel?: number, indexReproduction?: number) => {
    setModels(
      models.map((model, iModel) => (
        (indexModel === iModel || indexModel === undefined)
          ? ({
            ...model,
            reproductions: model.reproductions.map((repro, iRepro) => (
              // L'utilisateur FO ne peut agir que sur les reproductions sans état
              (!repro.state && (indexReproduction === iRepro || indexReproduction === undefined))
                ? ({ ...repro, selected: checked })
                : repro
            ))
          })
          : model
      ))
    )
  }

  return (
    <div className='reproduction-list'>
      <CheckboxField
        className='mb-0 select-all'
        inputId='selectAllReproductions'
        label={<FormattedMessage id='select_all' />}
        onChange={({ target }) => handleCheckReproductions(target.value)}
        checked={ReproductionService.hasAllReproductionsSelected(models)}
      />
      <RequestListTable
        tableTitle={REPRODUCTION_TABLE_TITLES}
      >
        <ListReproductionsTableBody
          models={models}
          onCheck={handleCheckReproductions}
          idTransaction={transaction.id}
        />
      </RequestListTable>

      <div className='row justify-content-center'>
        <WithdrawReproductionAction
          className='col-12 col-sm-6 col-md-4'
          transaction={transaction}
          models={models}
          onUpdateDeposit={onUpdateDeposit}
        />

        <PublishReproductionAction
          className='col-12 col-sm-6 col-md-4'
          transaction={transaction}
          models={models}
          onUpdateDeposit={onUpdateDeposit}
          onUpdateModels={setModels}
        />
      </div>
    </div>
  )
}

export default ListReproductions
