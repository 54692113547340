import React, { FC, useState, useEffect } from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import {
  buildEventType,
  containsErrors,
  EventType,
  FieldStatus,
  isFilled, MultipleRadioField,
  Publication,
  SubmitButton,
  ProtectionExtension,
  Title,
  TITLE_INSCRIPTION_ORIGIN_FR,
  TITLE_PROROGATION_SCOPE_PARTIAL,
  TITLE_PROROGATION_SCOPE_TOTAL,
  RECORD_PROROGATION_SCOPE,
  TITLE_PROROGATION_ORIGIN_FR,
  CheckboxField,
  TYPE_DELAY_GRACE,
  TYPE_DELAY_AFTER_GRACE
} from '@inpi-dm/components'
import RecordFormManual from '../../../title/RecordFormManual'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import RecordService from '../../../../services/record/RecordService'
import ContentService from '../../../../services/content/ContentService'
import ProrogationService from '../../../../services/transaction/ProrogationService'

interface SelectRecordsFormProps extends WrappedComponentProps {
  initialTitle?: Title
  initialFieldStatus?: FieldStatus,
  onSubmit: (title: Title) => void,
  setShowFormManual?: (isShowManual: boolean) => void,
  scopeRequired?: boolean,
  taxeRequired?: boolean,
  validateMandatory : (title : Title) => FieldStatus
}

const SelectRecordsFormManual: FC<SelectRecordsFormProps> = ({
  initialTitle,
  initialFieldStatus,
  onSubmit,
  setShowFormManual,
  scopeRequired = false,
  taxeRequired = false,
  validateMandatory
}) => {
  const [title, setTitle] = useState<Title>(initialTitle || { origin: TITLE_INSCRIPTION_ORIGIN_FR })
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>(initialFieldStatus || {})
  const [extensions, setExtensions] = useState<ProtectionExtension[]>([])

  useEffect(() => {
    initialFieldStatus && setFieldStatus(initialFieldStatus)
  }, [initialFieldStatus])

  useEffect(() => {
    // Si c'est un titre renseigné manuellement, on a accès à toutes les protections d'extension
    if (!initialTitle) {
      ContentService.getProtectionExtensions().then(r => setExtensions(r))
    }
  }, [])

  /**
   * Enregistrement des données dans le state à la saisie
   * @param event
   */
  const handleChange = (event: EventType) => {
    const { name, value } = event.target

    const updatedTitle = {
      ...title,
      [name]: value
    }
    setTitle(updatedTitle)
  }

  /**
   * Vérifie que les champs obligatoire soient remplis,
   * enregistre dans le store les titres et ferme le formulaire
   */
  const submitForm = async () => {
    // Enlever les publications vides
    const newPublication = title.publications?.filter ((publication: Publication) => isFilled(publication.idPublication)) || []
    const newModels = RecordService.getAllSelectedModels(title, newPublication)
    const typeDelay = title.typeDelay || await ProrogationService.getTypeDelay(newModels)
    const protectionExtensions = title.protectionExtensions ? [...title.protectionExtensions] : extensions
    const updateTitle = {
      ...title,
      origin: TITLE_PROROGATION_ORIGIN_FR,
      publications: [...newPublication],
      models: newModels,
      typeDelay: typeDelay,
      protectionExtensions: protectionExtensions
    }
    setTitle(updateTitle)
    // Récupérer toutes les publications et les mettre ensemble dans title
    const fieldStatusError = validateMandatory(updateTitle)
    setFieldStatus(fieldStatusError)
    if (!containsErrors({ ...fieldStatusError, publicationsWarning: '' })) {
      return onSubmit(updateTitle)
    }
  }

  /**
   * Gère la selection du radioButton concernant la porté de la prorogation
   * @param event
   */
  const handleChangeScope = (event: EventType) => {
    let isScopePartial = null
    switch (event.target.value) {
      case TITLE_PROROGATION_SCOPE_PARTIAL:
        isScopePartial = true
        break
      case TITLE_PROROGATION_SCOPE_TOTAL:
        isScopePartial = false
        break
      default :
        break
    }
    handleChange(buildEventType(event.target.name, isScopePartial))
  }

  /**
   * Gère la selection de l'ajout de taxe
   * @param event
   */
  const handleAddRemoveTaxe = (event: EventType) => {
    const { value } = event.target
    const typeDelay = value ? TYPE_DELAY_GRACE : null
    const updateTitle = {
      ...title,
      typeDelay: typeDelay
    }
    setTitle(updateTitle)
  }

  return (
    <>
      <hr />
      {
        scopeRequired &&
          <div className='row m-0 mb-4 px-3'>
            <div className='text-gris'>
              <FormattedMessage id='records_prorogation_isScopePartial' />
              <span className='text-danger'>*</span>
            </div>
            <div className='mx-3'>
              <MultipleRadioField
                inputId='isScopePartial'
                value={
                  title.isScopePartial === false
                    ? TITLE_PROROGATION_SCOPE_TOTAL
                    : (title.isScopePartial === true ? TITLE_PROROGATION_SCOPE_PARTIAL : null)
                }
                radio={RECORD_PROROGATION_SCOPE}
                onChange={handleChangeScope}
                fieldStatus={fieldStatus}
              />
            </div>
          </div>
      }
      <div className='row m-0'>
        <RecordFormManual
          title={title}
          handleChange={handleChange}
          fieldStatus={fieldStatus}
          setFieldStatus={setFieldStatus}
          publicationsIds={title.publications?.map((publication: Publication) => publication.idPublication) || []}
          classNameCol='col-12 col-md-4'
          originShown={false}
        />
      </div>
      {
        taxeRequired &&
          <div className='mx-3'>
            <CheckboxField
              inputId='taxe'
              label={<FormattedMessage id='records_prorogation_add_taxe' />}
              onChange={handleAddRemoveTaxe}
              checked={title.typeDelay === TYPE_DELAY_GRACE || title.typeDelay === TYPE_DELAY_AFTER_GRACE}
            />
          </div>
      }
      {
        setShowFormManual &&
          <div className='d-flex justify-content-start ml-3 mt-3'>
            <SubmitButton
              className='btn-link-primary'
              onClick={() => setShowFormManual(false)}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
              <span className='ml-2'>
                <FormattedMessage id='records_prorogation_return_search' />
              </span>
            </SubmitButton>
          </div>
      }
      <div className='row m-0 d-flex justify-content-end'>
        {
          onSubmit &&
            <SubmitButton
              onClick={submitForm}
              className='btn-outline-primary m-2'
            >
              <FormattedMessage id='records_inscription_submit_form' />
            </SubmitButton>
        }
      </div>
    </>
  )
}

export default injectIntl(SelectRecordsFormManual)
