import Message from './Message'

export const CONFORMITY_TO_DETERMINED = 'to_determined'
export const CONFORMITY_COMPLIANT = 'compliant'
export const CONFORMITY_NOT_COMPLIANT = 'not_compliant'

export const PRIORITY_CLAIM_STATUS_OPTIONS = [
  {
    value: CONFORMITY_TO_DETERMINED,
    label: Message.to_determined
  },
  {
    value: CONFORMITY_COMPLIANT,
    label: Message.compliant
  },
  {
    value: CONFORMITY_NOT_COMPLIANT,
    label: Message.not_compliant
  }
]
