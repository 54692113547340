import http from '../../network/http-common'
import { toast } from 'react-toastify'
import {
  ETSAccountNavInterface,
  ETSAccountRequest,
  ETSAccountRequestCredentials
} from '../../interfaces/ETSAccountInterface'
import { FoUserResponse } from '../../interfaces/User'
/* global FormData */

class ETSAccountService {
    postETSAccountRequestCredentials = async (ETSAccountCredentials: ETSAccountRequestCredentials) => {
      try {
        return await http.post('/api/transactions/ets-accounts/credentials', ETSAccountCredentials)
      } catch (error) {
        toast.error(error.message)
        return Promise.reject(error)
      }
    }

    /**
     * Permet de vérifier si la transaction concernée par les paramètres existent, si oui, on fait le demande de modification sur les comptes ETS
     * @param ETSAccountRequestData
     * @param ETSAccountRequestNavItem
     */
    postETSAccountRequestDeposit = async (ETSAccountRequestData: ETSAccountRequest, ETSAccountRequestNavItem: ETSAccountNavInterface) => {
      const formData = new FormData()
      formData.append('procedure', ETSAccountRequestData.procedure)
      formData.append('isRecipient', ETSAccountRequestData.isRecipient)
      formData.append('numNat', ETSAccountRequestData.numNat)
      if (ETSAccountRequestData.manageableQuality) {
        formData.append('manageableQuality', ETSAccountRequestData.manageableQuality)
      }
      if (ETSAccountRequestData.inscriptionNumber) {
        formData.append('inscriptionNumber', ETSAccountRequestData.inscriptionNumber)
      }
      if (ETSAccountRequestData.powerFile) {
        formData.append('powerFile', ETSAccountRequestData.powerFile.file, ETSAccountRequestData.powerFile.name)
      }
      if (ETSAccountRequestData.proofFile) {
        formData.append('proofFile', ETSAccountRequestData.proofFile.file, ETSAccountRequestData.proofFile.name)
      }
      if (ETSAccountRequestData.idRecord) {
        formData.append('idRecord', ETSAccountRequestData.idRecord)
      }
      formData.append('action', ETSAccountRequestNavItem.action)
      try {
        return await http.post('/api/transactions/ets-accounts', formData)
      } catch (error) {
        toast.error(error.message)
        return Promise.reject(error)
      }
    }

    getETSAccountTransactions = async (email: string) => {
      try {
        return await http.get(`/api/transactions/ets-accounts/${email}/deposits`)
      } catch (error) {
        toast.error(error.message)
        return Promise.reject(error)
      }
    }

    getETSAccountForPM = async () : Promise<FoUserResponse[]> => {
      try {
        return await http.get('/api/transactions/ets-accounts')
      } catch (error) {
        return Promise.reject(error)
      }
    }

    assignDeposit = async (email: string, idTransactions: string[]) : Promise<FoUserResponse[]> => {
      try {
        return await http.put('/api/transactions/ets-accounts/deposits', {
          email: email,
          idTransactions: idTransactions
        })
      } catch (error) {
        return Promise.reject(error)
      }
    }
}

export default new ETSAccountService()
