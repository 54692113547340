import React, { FC } from 'react'
import { Autocomplete } from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'
import { Address, AddressApiResult } from '../..'

interface AddressSearchFieldProps {
  inputId: string,
  placeholder?: string,
  onSelect?: (event: Address) => void,
  disabled?: boolean,
  className?: string,
  searchAddress: (query: string, type?: string|null, limit?: number|null) => Promise<void | AddressApiResult[]>
}

const AddressSearchField: FC<AddressSearchFieldProps> = ({
  inputId,
  placeholder = '',
  onSelect,
  className = '',
  disabled = false,
  searchAddress
}) => (
  <Autocomplete
    inputId={inputId}
    placeholder={placeholder}
    search={searchAddress}
    messageEmpty={<FormattedMessage id='autocomplete_no_resultat' />}
    onSelect={onSelect}
    disabled={disabled}
    className={className}
  />
)

export default AddressSearchField
