import {
  containsErrors,
  FieldStatus,
  messageIsFilled,
  STATUS_EXTENDABLE_25_YEARS,
  Title,
  TYPE_DELAY_AFTER_GRACE,
  TYPE_DELAY_BEFORE_PROROGATION
} from '@inpi-dm/components'
import Message from '../../../../../constants/Message'
import store from '../../../../../store/store'
import { storeProrogationFieldStatusUpdate } from 'store/prorogation/prorogationActions'

class SelectRecordFormValidator {
  /**
   * Valide le titre
   * @param title
   */
  validateMandatory = (title : Title) : FieldStatus => {
    let publicationsWarning = ''
    if (title.typeDelay === TYPE_DELAY_BEFORE_PROROGATION) {
      publicationsWarning = Message.records_prorogation_not_yet
    } else if (title.typeDelay === TYPE_DELAY_AFTER_GRACE) {
      publicationsWarning = Message.records_prorogation_to_late
    } else if (title.status === STATUS_EXTENDABLE_25_YEARS) {
      publicationsWarning = Message.records_prorogation_max_prorogation
    } else if (title.isProrogationAlreadyAsk) {
      publicationsWarning = Message.records_prorogation_is_already_ask
    }

    return {
      isScopePartial: (title.isScopePartial === true || title.isScopePartial === false) ? '' : Message.required_field,
      depositDate: messageIsFilled(title.depositDate),
      numNat: messageIsFilled(title.numNat),
      idPublication: title.publications && title.publications.length > 0 ? '' : Message.required_field,
      publicationsWarning: publicationsWarning,
      notFound: ''
    }
  }

  /**
   * Valide tous les titres
   */
  validateOnChangeStep = () => {
    const titles = store.getState().prorogation.prorogation?.titles || []

    let allFieldStatus
    if (titles.length === 0) {
      const fieldStatus = this.validateMandatory({})
      allFieldStatus = [{ ...fieldStatus, notFound: Message.warning_at_least_one_title }]
    } else {
      allFieldStatus = titles?.map((title) => ({ ...this.validateMandatory(title), publicationsWarning: '' }))
    }

    const error = containsErrors({ ...allFieldStatus })

    store.dispatch(storeProrogationFieldStatusUpdate({
      titles: allFieldStatus
    }))

    return !error
  }
}

export default new SelectRecordFormValidator()
