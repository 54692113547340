import store from '../../../../../store/store'
import { containsErrors, IntervenantValidator } from '@inpi-dm/components'
import { storeAppealFieldStatusUpdate } from '../../../../../store/appeal/appealActions'

class RecipientValidator {
  validateOnChangeStep = () => {
    const appeal = store.getState().appeal

    const fieldStatus = IntervenantValidator.validateRecipient(appeal.recipient)
    store.dispatch(storeAppealFieldStatusUpdate({
      recipient: fieldStatus
    }))

    return !containsErrors(fieldStatus)
  }
}

export default new RecipientValidator()
