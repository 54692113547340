import React, { FC, useEffect, useState } from 'react'
import {
  Deposit,
  DepositIntervenant,
  IntervenantValidator,
  Transaction
} from '@inpi-dm/components'
import AgentForm from './AgentForm'
import { FormattedMessage } from 'react-intl'
import InternalReferenceField from '../internalReference/InternalReferenceField'
import { InscriptionFieldStatus } from '../../interfaces/InscriptionInterfaces'
import { DepositFieldStatus } from '../../interfaces/DepositInterfaces'
import HeaderIntervenant from './HeaderIntervenant'
import StoreService from '../../services/StoreService'

interface AgentProposalFormProps {
  object : Transaction|Deposit,
  fieldStatus: InscriptionFieldStatus| DepositFieldStatus,
  procedureType: string,
  findIntervenantsList: string[],
  viewFormInit: boolean,
  isDeletable: boolean,
  idTransaction: string,
  defaultIntervenantlist: DepositIntervenant[]
}

const AgentProposalForm : FC<AgentProposalFormProps> = ({
  object,
  procedureType,
  fieldStatus,
  findIntervenantsList,
  viewFormInit,
  isDeletable,
  idTransaction,
  defaultIntervenantlist = []
}) => {
  const [viewForm, setViewForm] = useState<boolean>(!!object.agent || viewFormInit)

  useEffect(() => {
    const hasAgentDocument = IntervenantValidator.containsAgentDocument(object)
    setViewForm(hasAgentDocument || !!object.agent || viewFormInit)
  }, [object])

  const handleDeleteAgent = () => {
    StoreService.changeStore(procedureType, { agent: undefined })
  }

  return viewForm ? (
    <div>
      <AgentForm
        object={object}
        fieldStatus={fieldStatus}
        procedureType={procedureType}
        findIntervenantsList={findIntervenantsList}
        defaultIntervenantlist={defaultIntervenantlist}
        idTransaction={idTransaction}
      />
      {
        isDeletable &&
          <div className='row justify-content-center mt-3'>
            <button className='col-4 btn btn-block btn-outline-gris' onClick={handleDeleteAgent}>
              <FormattedMessage id='intervenant_remove_mandataire' />
            </button>
          </div>
      }
    </div>
  ) : (
    <div className='form-intervenant is-validated'>
      <div className='row'>
        <HeaderIntervenant procedureType={procedureType} />
        <InternalReferenceField
          value={object.internalReference}
          procedureType={procedureType}
          className='col-4 mb-4'
        />
      </div>

      <div className='form-header row mx-0'>
        <h2>
          <FormattedMessage id='intervenant_onglet_mandataire' />
        </h2>
      </div>

      <div className='row justify-content-center mt-3'>
        <div className='col-12 col-md-4'>
          <button className='btn btn-block btn-outline-primary' onClick={() => setViewForm(true)}>
            <FormattedMessage id='intervenant_add_mantataire' />
          </button>
        </div>
      </div>
    </div>
  )
}

export default AgentProposalForm
