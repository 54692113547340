import { DEFAULT_INTERVENANT, Transaction } from '@inpi-dm/components'
import {
  APPEAL_FIELD_STATUS_RESET,
  APPEAL_FIELD_STATUS_UPDATE,
  APPEAL_REMOVE,
  APPEAL_UPDATE,
  AppealFieldStatusStoreAction,
  AppealStoreAction
} from './appealStoreTypes'
import { AppealFieldStatus } from '../../interfaces/AppealInterfaces'

const initialAppealState: Transaction = {
  appeal: {
    titles: []
  },
  depositor: DEFAULT_INTERVENANT,
  recipient: DEFAULT_INTERVENANT
}

/* Contient les erreurs liées au formulaire de relevé de déchéance */
const initialAppealStatusState: AppealFieldStatus = {}

export function reducerAppealState (state = initialAppealState, action: AppealStoreAction): Transaction {
  switch (action.type) {
    case APPEAL_UPDATE:
      return {
        ...state,
        ...action.appeal,
        appeal: {
          ...state.appeal,
          ...action.appeal?.appeal
        }
      }
    case APPEAL_REMOVE:
      return initialAppealState
    default:
      return state
  }
}

export function reducerAppealFieldStatusState (state = initialAppealStatusState, action: AppealFieldStatusStoreAction): AppealFieldStatus {
  switch (action.type) {
    case APPEAL_FIELD_STATUS_UPDATE:
      return {
        ...state,
        ...action.fieldStatus
      }
    case APPEAL_FIELD_STATUS_RESET:
      return initialAppealStatusState
    default:
      return state
  }
}
