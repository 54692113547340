import store from '../../../../../store/store'
import { storeDepositFieldStatusUpdate } from '../../../../../store/deposit/depositActions'
import { containsErrors, FieldStatus } from '@inpi-dm/components'
import PriorityFormValidator from '../priorityForm/validator/PriorityFormValidator'
import { DepositPriorityOptions } from '../../../../../interfaces/DepositInterfaces'

class OptionsFormDepositValidator {
    validateMandatory = (priorites: DepositPriorityOptions[]) => {
      return priorites.map((onePriorite) => PriorityFormValidator.validateMandatory(onePriorite))
    }

    validateOnChangeStep = () => {
      const deposit = store.getState().deposit
      const { priorities = [] } = deposit

      const fieldStatus = this.validateMandatory(priorities)
      store.dispatch(storeDepositFieldStatusUpdate({
        priorities: fieldStatus
      }))

      const allContainsErrors = fieldStatus.filter((onefield) => containsErrors(onefield))

      return allContainsErrors.length === 0
    }

    validatePriority = (priority: DepositPriorityOptions) : FieldStatus => {
      return PriorityFormValidator.validateMandatory(priority || {})
    }
}

export default new OptionsFormDepositValidator()
