import { Transaction } from '@inpi-dm/components'
import { OfficialDocumentFieldStatus } from '../../interfaces/OfficialDocumentInterfaces'

export const OFFICIAL_DOCUMENT_UPDATE = 'OFFICIAL_DOCUMENT_UPDATE'
export const OFFICIAL_DOCUMENT_REMOVE = 'OFFICIAL_DOCUMENT_REMOVE'
export const OFFICIAL_DOCUMENT_FIELD_STATUS_UPDATE = 'OFFICIAL_DOCUMENT_FIELD_STATUS_UPDATE'
export const OFFICIAL_DOCUMENT_FIELD_STATUS_RESET = 'OFFICIAL_DOCUMENT_FIELD_STATUS_RESET'

export interface OfficialDocumentStoreAction {
  type: string,
  officialDocument?: Transaction
}

export interface OfficialDocumentFieldStatusStoreAction {
  type: string,
  fieldStatus?: OfficialDocumentFieldStatus
}
