import React, { FC } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { buildEventType, EventType, FieldStatus, TextField } from '@inpi-dm/components'
import AddressSearchField from './AddressSearchField'
import { Address, AddressApiResult, Country, FRANCE } from '../..'
import CountrySelectField from './CountrySelectField'

interface AddressFieldsProps extends WrappedComponentProps {
  address?: Address,
  onChange: (event: EventType) => void,
  fieldStatus?: FieldStatus | any,
  searchAddress: (query: string, type?: string|null, limit?: number|null) => Promise<void | AddressApiResult[]>,
  getCountries: () => Promise<void | Country[]>
}

const EMPTY_ADDRESS = {
  building: '',
  label: '',
  complement: '',
  zipCode: '',
  city: '',
  country: ''
}

const AddressFields: FC<AddressFieldsProps> = ({
  address = EMPTY_ADDRESS,
  onChange,
  fieldStatus = {},
  intl,
  searchAddress,
  getCountries
}) => {
  /**
   * A la selection d'une adresse dans l'autocompletion
   * @param address
   */
  const handleSelectSearch = (address: Address) => {
    onChange(buildEventType('address', address))
  }

  /**
   * A la saisie de l'adresse dans les champs du formulaire
   * @param event
   */
  const handleChange = (event: EventType) => {
    const { name, value } = event.target

    const updatedAddress = {
      ...address,
      [name]: value
    }

    onChange(buildEventType('address', updatedAddress))
  }

  return (
    <>
      <div className='header-field'>
        {intl.formatMessage({ id: 'field_adresse_label' })}
      </div>

      <div className='row'>
        <div className='col-12 col-md-8'>
          <AddressSearchField
            inputId='addressSearch'
            placeholder={intl.formatMessage({ id: 'placeholder_adresse_search' })}
            onSelect={handleSelectSearch}
            searchAddress={searchAddress}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-12 col-md-4'>
          <TextField
            inputId='building'
            label={intl.formatMessage({ id: 'field_adresse_batiment_label' })}
            value={address.building}
            onChange={handleChange}
            fieldStatus={fieldStatus}
            maxLength='100'
          />
        </div>
        <div className='col-12 col-md-4'>
          <TextField
            inputId='label'
            label={intl.formatMessage({ id: 'field_adresse_libelle_label' })}
            value={address.label}
            onChange={handleChange}
            fieldStatus={fieldStatus}
            maxLength='100'
            required
          />
        </div>
        <div className='col-12 col-md-4'>
          <TextField
            inputId='complement'
            label={intl.formatMessage({ id: 'field_adresse_mention_label' })}
            value={address.complement}
            onChange={handleChange}
            fieldStatus={fieldStatus}
            maxLength='100'
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-12 col-md-4'>
          <TextField
            inputId='zipCode'
            label={intl.formatMessage({ id: 'field_adresse_code_postal_label' })}
            value={address.zipCode}
            onChange={handleChange}
            fieldStatus={fieldStatus}
            maxLength='10'
            required={address?.country === FRANCE}
          />
        </div>
        <div className='col-12 col-md-4'>
          <TextField
            inputId='city'
            label={intl.formatMessage({ id: 'field_adresse_ville_label' })}
            value={address.city}
            onChange={handleChange}
            fieldStatus={fieldStatus}
            maxLength='50'
            required
          />
        </div>
        <div className='col-12 col-md-4'>
          <CountrySelectField
            inputId='country'
            label={intl.formatMessage({ id: 'field_adresse_pays_label' })}
            value={address.country}
            onChange={handleChange}
            fieldStatus={fieldStatus}
            required
            placeholder={intl.formatMessage({ id: 'placeholder_select' })}
            getCountries={getCountries}
          />
        </div>
      </div>
    </>
  )
}

export default injectIntl(AddressFields)
