export const ETS_ACCOUNT_STATE_ACTIVE = 'ACTIVE'
export const ETS_ACCOUNT_STATE_INACTIVE = 'INACTIVE'
export const ETS_ACCOUNT_STATE_REJECTED = 'REJECTED'
export const ETS_ACCOUNT_STATE_TO_ADD = 'TO_ADD'
export const ETS_ACCOUNT_STATE_TO_EDIT = 'TO_EDIT'
export const ETS_ACCOUNT_STATE_TO_INACTIVATE = 'TO_INACTIVATE'

export const ETS_ACCOUNT_STATE_LABEL = [
  {
    state: ETS_ACCOUNT_STATE_ACTIVE,
    label: 'sso_account_state_active',
    className: 'bg-primary'
  },
  {
    state: ETS_ACCOUNT_STATE_TO_ADD,
    label: 'sso_account_state_new',
    className: 'bg-success'
  }, {
    state: ETS_ACCOUNT_STATE_TO_EDIT,
    label: 'sso_account_state_edit',
    className: 'bg-warning'
  }, {
    state: ETS_ACCOUNT_STATE_TO_INACTIVATE,
    label: 'sso_account_state_inactivate',
    className: 'bg-secondary'
  }
]
