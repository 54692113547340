import React, { FC } from 'react'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SubmitButton, TextField } from '@inpi-dm/components'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

interface ModelRecordsFieldProps extends WrappedComponentProps {
  models?: string[],
  setModel: (index: number, value: string) => void,
  deleteModel: (index: number) => void,
  addModel: () => void,
  fieldStatus?: string[],
  displayLabel: boolean,
  displayAddButton: boolean,
}

const ModelRecordsField: FC<ModelRecordsFieldProps> = ({
  models,
  setModel,
  deleteModel,
  addModel,
  fieldStatus,
  displayLabel,
  displayAddButton
}) => {
  const getColorClassName = (index: number) => {
    return fieldStatus && fieldStatus.length > index && fieldStatus[index] ? `border-2 border-${fieldStatus[index]}` : ''
  }

  return (
    <div>
      {
        displayLabel &&
          <div className='text-gris mb-025'>
            <FormattedMessage id='records_official_document_model_label' />
            <span className='text-danger'>*</span>
          </div>
      }
      {
        models?.map((model, index) => (
          <div className='d-flex' key={`models-${index}`}>
            <TextField
              inputId={`models-${index}`}
              className={getColorClassName(index)}
              value={model}
              onChange={(event => {
                const { value } = event.target
                setModel(index, value)
              })}
            />
            <SubmitButton
              className='btn-icon-secondary ml-2 mb-3'
              onClick={() => deleteModel(index)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </SubmitButton>
          </div>
        ))
      }
      {
        displayAddButton &&
          <SubmitButton
            className='btn-link-primary'
            onClick={addModel}
          >
            <FormattedMessage id='records_official_document_model_add_button' />
          </SubmitButton>
      }
    </div>
  )
}

export default injectIntl(ModelRecordsField)
