import React, { FC, MutableRefObject } from 'react'
import { FormattedMessage } from 'react-intl'
import { REPRODUCTIONS_VIEW_MODE } from '../../../../../constants/RequestConstants'
import { STATUS_DEPOSIT, SubmitButton } from '@inpi-dm/components'

interface OverviewHeaderModelsProps {
  mode: string,
  onChangeMode?: (mode: string) => void,
  hasReproRegularization : boolean,
  isEditable : boolean,
  reproReference: MutableRefObject<null>,
  setIsEditable : (value: boolean)=>void,
  transactionStatus: string|undefined,
  depositView?: boolean | null,
  isAllOpen: boolean,
  setIsAllOpen: (isAllOpen: boolean) => void
}

const OverviewHeaderModels: FC<OverviewHeaderModelsProps> = ({
  mode,
  onChangeMode,
  hasReproRegularization,
  isEditable,
  reproReference,
  setIsEditable,
  transactionStatus,
  depositView,
  isAllOpen,
  setIsAllOpen
}) => {
  return (
    <div className='d-flex justify-content-between' ref={reproReference}>
      <FormattedMessage id='overview_deposit_card_models_title' />
      <div className='d-flex justify-content-end align-items-center'>
        <SubmitButton
          className='btn-link-primary font-weight-bold text-normalcase'
          onClick={() => setIsAllOpen(!isAllOpen)}
        >
          <FormattedMessage id={isAllOpen ? 'request_reproduction_toogle_close' : 'request_reproduction_toogle_open'} />
        </SubmitButton>
        {(!depositView && onChangeMode && mode !== REPRODUCTIONS_VIEW_MODE.OVERVIEW) && (
          <SubmitButton className='btn-link-primary ml-3 font-weight-bold text-normalcase' onClick={() => onChangeMode(REPRODUCTIONS_VIEW_MODE.OVERVIEW)}>
            <FormattedMessage id='overview_deposit_card_models_btn_mode_overview' />
          </SubmitButton>
        )}
        {(!depositView && onChangeMode && mode !== REPRODUCTIONS_VIEW_MODE.SELECT) && (
          <div>
            {
              hasReproRegularization && !isEditable &&
                <SubmitButton
                  className='ml-3 btn-link-primary font-weight-bold text-normalcase'
                  onClick={() => { setIsEditable(true) }}
                >
                  <FormattedMessage id='overview_deposit_regularize_reproductions' />
                </SubmitButton>
            }
            {
              transactionStatus && transactionStatus !== STATUS_DEPOSIT.DRAFT &&
                <SubmitButton className='ml-3 btn-link-primary font-weight-bold text-normalcase' onClick={() => onChangeMode(REPRODUCTIONS_VIEW_MODE.SELECT)}>
                  <FormattedMessage id='overview_deposit_card_models_btn_mode_select' />
                </SubmitButton>
            }
          </div>
        )}
      </div>

    </div>
  )
}

export default OverviewHeaderModels
