import Message from './Message'
import { INFORMATION_FROM_MODEL, INFORMATION_FROM_NUM_NAT, INFORMATION_FROM_PUBLICATION } from './InscriptionConstants'

export const RECORD_APPEAL_INFORMATION_FROM_SELECT = [{
  label: Message.records_inscription_information_from_num_nat,
  value: INFORMATION_FROM_NUM_NAT
}, {
  label: Message.records_inscription_information_from_model,
  value: INFORMATION_FROM_MODEL
}, {
  label: Message.records_inscription_information_from_publication,
  value: INFORMATION_FROM_PUBLICATION
}]
