import React, { FC, useEffect, useState } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import {
  Deposit,
  EventType,
  ManageableQuality,
  PERSONNE_PHYSIQUE,
  RenderSignatoryForm,
  Transaction
} from '@inpi-dm/components'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import InternalReferenceField from '../internalReference/InternalReferenceField'
import { InscriptionFieldStatus } from '../../interfaces/InscriptionInterfaces'
import { DepositFieldStatus } from '../../interfaces/DepositInterfaces'
import ContentService from '../../services/content/ContentService'
import HeaderIntervenant from './HeaderIntervenant'
import StoreService from '../../services/StoreService'

interface SignatoryFormProps extends WrappedComponentProps {
  object : Transaction|Deposit,
  fieldStatus: InscriptionFieldStatus| DepositFieldStatus,
  procedureType: string,
}

const SignatoryForm: FC<SignatoryFormProps> = (props: SignatoryFormProps) => {
  const { object, fieldStatus, procedureType } = props

  const user = useSelector((state : RootStateOrAny) => state.user.user)
  const signatory = object.signatory || {}

  const [manageableQualities, setManageableQualities] = useState<ManageableQuality[]>()

  useEffect(() => {
    ContentService.getManageableQualities().then(result => {
      setManageableQualities(result)
    })

    return () => {
      ContentService.cancelRequest()
    }
  }, [])

  useEffect(() => {
    // Préremplissage du formulaire avec l'utilisateur FO courant
    const updatedSignatory = {
      ...signatory,
      type: PERSONNE_PHYSIQUE.value,
      civilityCode: user.civilityCode,
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email
    }

    if (!signatory.email && user) {
      StoreService.changeStore(procedureType, { signatory: updatedSignatory })
    }
  }, [])

  const handleChange = (event: EventType) => {
    const { name, value } = event.target
    const updatedSignatory = {
      ...signatory,
      type: PERSONNE_PHYSIQUE.value,
      [name]: value
    }
    StoreService.changeStore(procedureType, { signatory: updatedSignatory })
  }

  return (
    <div className='form-intervenant is-validated'>
      <div className='row'>
        <HeaderIntervenant procedureType={procedureType} />
        <InternalReferenceField
          value={object.internalReference}
          procedureType={procedureType}
          className='col-4 mb-4'
        />
      </div>
      <RenderSignatoryForm
        handleChange={handleChange}
        fieldStatus={fieldStatus.signatory}
        depositSignatory={signatory}
        foDisplay
        intl={props.intl}
        manageableQualities={manageableQualities}
      />
    </div>
  )
}

export default injectIntl(SignatoryForm)
