import React, { FC } from 'react'
import {
  FieldStatus,
  Publication,
  SubmitButton,
  Title,
  BASIC_DATE,
  DateUtils,
  OfficialDocumentType,
  TextField,
  OfficialDocumentRequest,
  COPY_OF_INSCRIPTION, BY_MAIL
} from '@inpi-dm/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'
import InscriptionService from '../../services/transaction/InscriptionService'
import ShowMoreText from 'react-show-more-text'
import Message from '../../constants/Message'
import ProrogationService from '../../services/transaction/ProrogationService'

interface OverviewRecordsProps {
  currentIndex: number,
  minIndex?: number,
  maxIndex?: number,
  isBeforeForm?: boolean,
  titles?: Title[]|OfficialDocumentRequest[],
  isOneTitle?: boolean,
  changeTitle?: (index: number) => void,
  deleteTitle?: (index: number) => void,
  isOverview?:boolean,
  fieldStatus?: FieldStatus[],
  isOriginShown?: boolean,
  isScopeShown?: boolean,
  isDateShown?: boolean,
  isTypeOfficialDocumentShown?: boolean,
  typesOfficialsDocument?: OfficialDocumentType[]
  isQuantityShown?: boolean,
  setQuantity?: (index: number, quantity?: number) => void,
  idPublicationShown?: boolean,
  selectedOfficialDocumentTypeCode?: string,
}

const OverviewRecords: FC<OverviewRecordsProps> = ({
  currentIndex,
  isBeforeForm,
  titles,
  isOneTitle = true,
  changeTitle,
  deleteTitle,
  isOverview = false,
  fieldStatus,
  minIndex = 0,
  maxIndex = 1000,
  isOriginShown = true,
  isScopeShown = false,
  isDateShown = true,
  isTypeOfficialDocumentShown = false,
  isQuantityShown = false,
  setQuantity,
  idPublicationShown = true,
  selectedOfficialDocumentTypeCode
}) => {
  const getSizeCol = () => {
    return isOriginShown || isScopeShown || isQuantityShown ? 'col-md-2' : 'col-md-3'
  }

  const displayNumPublication = (publications: Publication[], index: number) => (
    <div className='row m-0'>
      <div className='col-12 m-0 p-0'>
        <ShowMoreText
          lines={1}
          more={isOverview ? Message.overview_records_inscription_show_more : ''}
          less={isOverview ? Message.overview_records_inscription_show_less : ''}
          expanded={false}
        >
          {
            publications.map((publication) => (publication.idPublication)).join(', ')
          }
        </ShowMoreText>
      </div>
      <div className='text-danger'>
        {
          fieldStatus && fieldStatus[index] && fieldStatus[index].publicationsWarning
        }
      </div>
    </div>
  )

  const displayOrigin = (title: Title) => (
    <div className='col-12 col-md-2 p-0'>
      <div className='text-gris'>
        <FormattedMessage id='records_inscription_origin_label' />
      </div>
      {
        title.origin &&
        InscriptionService.getOriginLabel(title.origin)
      }
    </div>
  )

  const displayScope = (title: Title) => (
    <div className='col-12 col-md-2 p-0'>
      <div className='text-gris'>
        <FormattedMessage id='records_prorogation_scope_label' />
      </div>
      {
        ProrogationService.getScopeLabel(title.isScopePartial)
      }
    </div>
  )

  const displayDate = (title: Title) => (
    <div className={`col-12 ${getSizeCol()} p-0`}>
      <div className='text-gris'>
        <FormattedMessage id='records_inscription_deposit_date_label' />
      </div>
      {DateUtils.formateDateToFormat(title.depositDate, BASIC_DATE)}
    </div>
  )

  const displayNumNat = (title: Title) => (
    <div className={`col-12 ${getSizeCol()} p-0`}>
      <div className='text-gris'>
        <FormattedMessage id='records_inscription_numnat_label' />
      </div>
      {title.numNat}
    </div>
  )

  const displayCopyNum = (officialDocumentRequest: OfficialDocumentRequest, officialDocumentTypeCode?: string) => (
    <div className={`col-12 ${getSizeCol()} p-0`}>
      <div className='text-gris'>
        <FormattedMessage id={officialDocumentTypeCode === COPY_OF_INSCRIPTION
          ? 'records_inscription_order_num_label'
          : 'records_inscription_prorogation_num_label'}
        />
      </div>
      {officialDocumentRequest.copyNum || ''}
    </div>
  )

  const displayPublications = (title: Title, index:number) => (
    <div className={`col-12 ${isOverview ? 'col-md-6' : 'col-md-4'}`}>
      <div className='text-gris'>
        <FormattedMessage id='records_inscription_publication_label' />
      </div>
      {displayNumPublication(title.publications || [], index)}
      <div className='text-danger'>
        {
          fieldStatus && fieldStatus[index] && fieldStatus[index].idPublication
        }
      </div>
    </div>
  )

  const displayOfficialDocumentType = (request: OfficialDocumentRequest, index: number) => (
    <div className={`col-12 ${isOverview ? 'col-md-8' : 'col-md-6'} p-0`}>
      <div className='text-gris'>
        <FormattedMessage id='records_official_document_service_type' />
      </div>
      {request.officialDocumentType?.label}
      {fieldStatus && fieldStatus.length > 0 && fieldStatus[index] && fieldStatus[index].officialDocumentType && (
        <div className='text-danger'><FormattedMessage id='official_document_required_type' /></div>
      )}
    </div>
  )

  const displayQuantity = (request: OfficialDocumentRequest, index: number) => (
    <div className={`col-12 ${getSizeCol()} p-0`}>
      <div className='text-gris'>
        <FormattedMessage id='records_official_document_service_quantity' />
      </div>
      {
        request.desiredFormatOfficialDocument === BY_MAIL
          ? (
            <TextField
              fieldStatus={fieldStatus && fieldStatus.length > index && fieldStatus[index]}
              inputId='quantity'
              type='number'
              className='d-flex justify-content-end'
              required
              value={request.quantity}
              onChange={(event) => setQuantity && setQuantity(index, event.target.value)}
              step='1'
              min='1'
            />
          ) : request.quantity
      }

    </div>
  )

  const displayElement = (element: Title|OfficialDocumentRequest, index: number) => (
    <div key={index}>
      {(index !== 0 || !isOverview) && <hr />}
      <div className='row m-0' key={index}>
        {isOriginShown && displayOrigin(isOneTitle ? element : element.title)}
        {isScopeShown && displayScope(isOneTitle ? element : element.title)}
        {isDateShown && displayDate(isOneTitle ? element : element.title)}
        {isTypeOfficialDocumentShown && !isOneTitle && displayOfficialDocumentType(element, index)}
        {isTypeOfficialDocumentShown &&
        !isOneTitle &&
        !!element.copyNum
          ? displayCopyNum(element, selectedOfficialDocumentTypeCode)
          : displayNumNat(isOneTitle ? element : element.title)}
        {idPublicationShown && displayPublications(isOneTitle ? element : element.title, index)}
        {isQuantityShown && !isOneTitle && displayQuantity(element, index)}
        {
          !isOverview &&
            <div className='col-12 col-md-2 d-flex align-items-center justify-content-center'>
              <SubmitButton
                className='btn-icon-primary'
                onClick={() => changeTitle && changeTitle(index)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </SubmitButton>
              <SubmitButton
                className='btn-icon-secondary ml-3'
                onClick={() => deleteTitle && deleteTitle(index)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </SubmitButton>
            </div>
        }
      </div>
    </div>
  )

  return (
    <>
      {
        isBeforeForm && currentIndex !== undefined &&
            titles && titles.map((oneTitle: Title, indexTitle: number) => (
          indexTitle < currentIndex && indexTitle >= minIndex && indexTitle <= maxIndex && displayElement(oneTitle, indexTitle))
        )
      }
      {
        !isBeforeForm && currentIndex !== undefined &&
            titles && titles.map((oneTitle: Title, indexTitle: number) => (
          indexTitle > currentIndex && indexTitle >= minIndex && indexTitle <= maxIndex && displayElement(oneTitle, indexTitle))
        )
      }
    </>
  )
}

export default OverviewRecords
