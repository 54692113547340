import React, { FC, useContext, useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import { FormattedMessage } from 'react-intl'
import {
  CardBlock,
  Preview,
  ReproductionLightDeposit,
  ModelDeposit,
  Country,
  SwitchField,
  buildEventType, EventType
} from '@inpi-dm/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import AccordionContext from 'react-bootstrap/AccordionContext'
import { DepositPriorityOptions } from '../../../interfaces/DepositInterfaces'
import ContentService from '../../../services/content/ContentService'
import DepositService from '../../../services/transaction/DepositService'
import { BLACK_AND_WHITE, COLORS } from '../../../constants/DepositConstants'

interface ContextAwareToggleProps {
  eventKey: string,
}

const ContextAwareToggle : FC<ContextAwareToggleProps> = ({ eventKey }) => {
  const currentEventKey = useContext(AccordionContext)

  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <div className={`my-auto ${isCurrentEventKey ? 'mr-1' : 'mr-2'}`}>
      <FontAwesomeIcon icon={isCurrentEventKey ? faChevronDown : faChevronRight} />
    </div>
  )
}

interface DivisionDepositChildReproductionProps {
  indexChildDeposit: number,
  models?: ModelDeposit[],
  depositId?:string,
  priorities?: DepositPriorityOptions[],
  onChange?: (event: EventType) => void
}

const DivisionDepositChildReproduction: FC<DivisionDepositChildReproductionProps> = ({
  indexChildDeposit,
  models = [],
  depositId,
  priorities,
  onChange
}) => {
  const [countries, setCountries] = useState<Country[]>([])

  useEffect(() => {
    ContentService.getCountries().then(setCountries)
  }, [])

  /**
   * Met à jour les demandes de retrait des reproductions d'un modèle
   */
  const handleWithdrawReproductions = (checked: boolean, event: any, indexModel: number, indexRepro?: number) => {
    // Ne pas toggle l'accordeon au clique sur le bouton switch
    event.stopPropagation()

    const updatedModels = [...models]
    const updatedModel = { ...models[indexModel] }

    updatedModel.reproductions = updatedModel.reproductions
      .map((repro, index) => {
        if (indexRepro === undefined || indexRepro === index) {
          return {
            ...repro,
            selected: checked
          }
        }
        return repro
      })

    updatedModels[indexModel] = updatedModel

    onChange && onChange(buildEventType('models', updatedModels))
  }

  return (
    <Accordion>
      {
        models && models.map((oneModel: ModelDeposit, indexModel: number) => {
          const withdrawedReproductions = oneModel.reproductions?.filter(repro => repro.selected) || []

          return (
            <Card key={indexModel} className='h-auto'>
              <Accordion.Toggle as={Card.Header} className='bg-transparent cursor-pointer' variant='link' eventKey={indexModel.toString()}>
                <div className='row align-items-center'>
                  {indexModel !== 0 && <div className='border-top w-100 mb-3' /> /* Ligne qui sépare les modèles */}
                  <div className='col-4 col-sm-3 col-lg-2 d-flex'>
                    <ContextAwareToggle eventKey={indexModel.toString()} />
                    {
                      depositId && oneModel.reproductions[0]?.thumbnail &&
                        <Preview
                          file={() => DepositService.getReproduction(depositId, oneModel.id, oneModel.reproductions[0]?.thumbnail.internalName)}
                          className=''
                        />
                    }
                  </div>
                  <div className='col-8 col-sm-9 col-lg-7'>
                    <div>
                      <FormattedMessage id='model_deposit_form_number' />{indexModel + 1}
                      <span className='normal text-lowercase'> ({oneModel.reproductions && oneModel.reproductions.length} <FormattedMessage id='request_number_reproduction' />
                        {oneModel.reproductions.length > 1 ? 's' : ''})
                      </span>
                      {withdrawedReproductions.length > 0 &&
                        <span className='normal text-lowercase text-warning'>
                          ({withdrawedReproductions.length} <FormattedMessage id='request_division_reproduction_withdrawn' />{oneModel.reproductions.length > 1 ? 's' : ''})
                        </span>}
                    </div>
                    {oneModel.priorityId && countries.length > 0 ? (
                      <div>
                        <FormattedMessage id='overview_deposit_priority_title' />
                        {DepositService.getModelPriorityName(oneModel.priorityId, countries, priorities)}
                      </div>
                    ) : ''}
                    <div className='normal'>
                      {oneModel.modelDesignation?.ref
                        ? `${oneModel.modelDesignation.ref}. ${oneModel.modelDesignation.name}`
                        : `${oneModel.modelDesignation.name}`}
                    </div>
                  </div>

                  <div className='col d-flex justify-content-end text-normalcase font-weight-normal'>
                    <SwitchField
                      inputId={`withdraw.${indexChildDeposit}.${indexModel + 1}`}
                      label={<FormattedMessage id='request_division_reproduction_withdraw_all' />}
                      onChange={(checked, event) => handleWithdrawReproductions(checked, event, indexModel)}
                      value={withdrawedReproductions.length === oneModel.reproductions.length}
                    />
                  </div>

                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={indexModel.toString()}>
                <Card.Body className='p-0 float-right col-sm-11'>
                  {
                    oneModel.reproductions?.map((oneRepro : ReproductionLightDeposit, index) => (
                      <CardBlock
                        key={index}
                        className={`m-3 ${oneRepro.selected ? 'border border-warning' : ''}`}
                        bodyClassName='p-2'
                        shadow
                      >
                        <div className='row align-items-center'>
                          <div className='col-12 col-sm-3 col-lg-2'>
                            {
                              depositId && oneRepro.thumbnail &&
                                <Preview
                                  file={() => DepositService.getReproduction(depositId, oneModel.id, oneRepro.thumbnail.internalName)}
                                />
                            }
                          </div>
                          <div className='col-12 col-sm-7 d-flex flex-column justify-content-center'>
                            <span className='font-weight-bold'>
                              {`${indexModel + 1}.${index + 1} `}
                              {oneRepro?.label || '--'}
                              {oneRepro?.selected &&
                                <span className='font-weight-normal text-warning ml-1'>
                                  <FormattedMessage id='request_division_reproduction_withdrawn' />
                                </span>}
                            </span>
                            <span className='font-weight-normal text-normalcase'>
                              {oneRepro?.color === COLORS && <FormattedMessage id='reproductions_form_radio_colors' />}
                              {oneRepro?.color === BLACK_AND_WHITE && <FormattedMessage id='reproductions_form_radio_no_colors' />}
                              {!oneRepro?.color && '--'}
                            </span>
                          </div>

                          <div className='col'>
                            <SwitchField
                              inputId={`withdraw.${indexChildDeposit}.${indexModel + 1}.${index + 1}`}
                              label={<FormattedMessage id='request_division_reproduction_withdraw' />}
                              onChange={(checked, event) => handleWithdrawReproductions(checked, event, indexModel, index)}
                              value={oneRepro?.selected}
                            />
                          </div>
                        </div>
                      </CardBlock>
                    ))
                  }
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          )
        })
      }
    </Accordion>
  )
}

export default DivisionDepositChildReproduction
