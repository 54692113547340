import React, { FC, useCallback, useEffect, useState } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import Modal from 'react-bootstrap/Modal'
import { EventType, TextField } from '@inpi-dm/components'
import debounce from 'lodash/debounce'
import Message from '../constants/Message'
import { LocarnoAccordionCategories, LocarnoCategory, LocarnoProduct, ModelDesignation } from '..'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface LocarnoModalProp {
  show:boolean,
  handleClose:() => void,
  onClick?:(value:ModelDesignation) => void
  listLocarno?:LocarnoCategory[],
  searchProducts?:(text?:string, refCategory?:string) => Promise<LocarnoProduct[]>,
}

const LocarnoModal:FC<LocarnoModalProp> = ({
  show,
  handleClose,
  onClick,
  listLocarno,
  searchProducts
}) => {
  const [value, setValue] = useState('')
  const [list, setList] = useState<LocarnoProduct[]>([])
  const [loading, setLoading] = useState(false)

  const updateQuery = () => {
    setLoading(true)
    // Recherche uniquement sur les produits
    return searchProducts && searchProducts(value)
      .then(result => {
        setList(result)
        setLoading(false)
      })
  }

  const delayedQuery = useCallback(debounce(updateQuery, 500), [value])

  /**
   * A la saisie d'un texte dans le champs de recherche
   * @param event
   */
  const handleChange = (event:EventType) => {
    setValue(event.target.value)
  }

  useEffect(() => {
    if (value && value.length > 0) {
      delayedQuery()
    } else if (list.length > 0) {
      setList([])
    }
    // Cancel the debounce on useEffect cleanup.
    return delayedQuery.cancel
  }, [value, listLocarno, delayedQuery])

  /**
   * Création de modèle de désignation à envoyer au clique sur un produit
   * @param product
   */
  const handleClickProduct = (product:LocarnoProduct) => {
    onClick && onClick({
      ...product,
      id: undefined,
      version: product.versionName
    })
    handleClose()
  }

  /**
   * Met à jour les catégories de Locarno en associant leurs produits dans chaque
   * sous-classe
   *
   * @param category
   */
  const preloadProductsForCategory = (category:LocarnoCategory) => {
    if (searchProducts && !category.filled) {
      category.filled = true
      searchProducts(undefined, category.ref)
        .then((products) => {
          if (category.subCategories) {
            category.subCategories.forEach(subCategory => {
              subCategory.products = products.filter(p => p.ref === subCategory.ref)
            })
          }
          // Déclenche un rafraichissement du composant
          setList([...list])
        })
    }
  }

  const displayCategories = () => {
    let itemsLocarno

    if (value && value !== '') {
      // Cas de recherche
      if (loading) {
        itemsLocarno = <span><FontAwesomeIcon className='loader mr-1' icon={faSpinner} /></span>
      } else if (!list || list.length === 0) {
        itemsLocarno = <span>Aucun résultat, veuillez modifier votre recherche</span>
      } else {
        itemsLocarno =
          <div className='list-group'>
            {
              list.map((product, index) => (
                <div
                  key={index}
                  onClick={() => handleClickProduct(product)}
                  className='list-group-item list-group-item-action border-0 cursor-pointer'
                >
                  {`${product.ref} - `}<span className='text-dark'>{product.name}</span>
                </div>
              ))
            }
          </div>
      }
    } else if (listLocarno && listLocarno.length > 0) {
      // Cas d'affichage des catégories (arborescence)
      itemsLocarno = (
        <LocarnoAccordionCategories
          onClickCategory={preloadProductsForCategory}
          onClickProduct={onClick && handleClickProduct}
          categories={listLocarno}
        />
      )
    }
    return itemsLocarno
  }

  return (
    <IntlProvider locale='fr' messages={Message}>
      <Modal show={show} onHide={handleClose} size='xl' scrollable>
        <Modal.Header closeButton className='bg-primary text-white border-bottom-0'>
          <Modal.Title><FormattedMessage id='placeholder_locarno_search' /></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextField
            inputId='valueModal'
            value={value}
            onChange={handleChange}
            required
            placeholder={Message.placeholder_locarno_search_popup}
          />
          {displayCategories()}
        </Modal.Body>
      </Modal>
    </IntlProvider>
  )
}

export default LocarnoModal
