import { SelectOption } from '..'
import Message from './Message'

export const TAB_DRAFT = 'TAB_DRAFT'
export const TAB_TO_SEND = 'TAB_TO_SEND'
export const TAB_WAITING_ANSWER = 'TAB_WAITING_ANSWER'
export const TAB_REPLIED = 'TAB_REPLIED'
export const TAB_CLOSED_REPLIED = 'TAB_CLOSED_REPLIED'
export const TAB_CONFIRMED_RECEIPT = 'TAB_CONFIRMED_RECEIPT'
export const TAB_EXPIRED_PERIOD = 'TAB_EXPIRED_PERIOD'
export const TAB_NO_RESPONSE_EXPECTED = 'TAB_NO_RESPONSE_EXPECTED'
export const TAB_CLOSED_NO_REPLIED = 'TAB_CLOSED_NO_REPLIED'
export const TAB_ALL = 'TAB_ALL'

export const NOTIFICATIONS_TABS = [
  TAB_ALL,
  TAB_DRAFT,
  TAB_TO_SEND,
  TAB_NO_RESPONSE_EXPECTED,
  TAB_WAITING_ANSWER,
  TAB_REPLIED,
  TAB_EXPIRED_PERIOD,
  TAB_CONFIRMED_RECEIPT,
  TAB_CLOSED_REPLIED,
  TAB_CLOSED_NO_REPLIED
]

export const NOTIF_STATUS_DRAFT = 'DRAFT'
export const NOTIF_STATUS_PROOFREADING = 'PROOFREADING'
export const NOTIF_STATUS_PROOFREADING_VALIDATE = 'PROOFREADING_VALIDATE'
export const NOTIF_STATUS_SIGNED = 'SIGNED'
export const NOTIF_STATUS_SENT = 'SENT'
export const NOTIF_STATUS_ANSWERED = 'ANSWERED'
export const NOTIF_STATUS_CLOSED = 'CLOSED'
export const NOTIF_STATUS_WAITING_ANSWER = 'WAITING_ANSWERED'
export const NOTIF_STATUS_EXPIRED_PERIOD = 'EXPIRED_PERIOD'

export const NOTIF_TYPE_EXPECTED_RESPONSE = 'EXPECTED_RESPONSE'
export const NOTIF_TYPE_AGREEMENT_NOTIFICATION = 'AGREEMENT_NOTIFICATION'
export const NOTIF_TYPE_NO_RESPONSE_EXPECTED = 'NO_RESPONSE_EXPECTED'

export const NOTIF_ANSWERED_TYPE_PUBLICATION_REPRODUCTION = 'PUBLICATION_REPRO'
export const NOTIF_ANSWERED_TYPE_WITHDRAW = 'WITHDRAW'

export const NOTIF_STATUS_VISIBLE_TO_FO = [
  NOTIF_STATUS_SENT,
  NOTIF_STATUS_ANSWERED,
  NOTIF_STATUS_CLOSED,
  NOTIF_STATUS_WAITING_ANSWER
]

export const NOTIF_TYPE_SELECT_OPTION: SelectOption[] = [
  {
    value: NOTIF_TYPE_NO_RESPONSE_EXPECTED,
    label: Message.request_notification_no_response_expected
  },
  {
    value: NOTIF_TYPE_AGREEMENT_NOTIFICATION,
    label: Message.request_notification_agreement_notification
  },
  {
    value: NOTIF_TYPE_EXPECTED_RESPONSE,
    label: Message.request_notification_response_expected
  }
]

export const NOTIF_TYPE_TRANSLATIONS = {
  [NOTIF_TYPE_NO_RESPONSE_EXPECTED]: Message.request_notification_no_response_expected,
  [NOTIF_TYPE_AGREEMENT_NOTIFICATION]: Message.request_notification_agreement_notification,
  [NOTIF_TYPE_EXPECTED_RESPONSE]: Message.request_notification_response_expected
}

export const NOTIF_STATUS_PROOFREADING_DISPLAY = [
  NOTIF_STATUS_DRAFT,
  NOTIF_STATUS_PROOFREADING,
  NOTIF_STATUS_PROOFREADING_VALIDATE,
  NOTIF_STATUS_SIGNED
]

export const NOTIF_STATUS = {
  NOTIF_STATUS_DRAFT: NOTIF_STATUS_DRAFT,
  NOTIF_STATUS_PROOFREADING: NOTIF_STATUS_PROOFREADING,
  NOTIF_STATUS_SIGNED: NOTIF_STATUS_SIGNED,
  NOTIF_STATUS_SENT: NOTIF_STATUS_SENT,
  NOTIF_STATUS_ANSWERED: NOTIF_STATUS_ANSWERED,
  NOTIF_STATUS_CLOSED: NOTIF_STATUS_CLOSED,
  NOTIF_STATUS_WAITING_ANSWER: NOTIF_STATUS_WAITING_ANSWER,
  NOTIF_STATUS_EXPIRED_PERIOD: NOTIF_STATUS_EXPIRED_PERIOD,
  NOTIF_STATUS_PROOFREADING_VALIDATE: NOTIF_STATUS_PROOFREADING_VALIDATE
}

export const EDITABLE_TAB = [
  TAB_DRAFT,
  TAB_TO_SEND,
  TAB_ALL
]

export const SENDING_PORTAL = 'PORTAL'
export const SENDING_EDITIQUE = 'EDITIQUE'

export const OPTION_EDITIQUE_SIMPLE = 'EDITIQUE_SIMPLE'
export const OPTION_EDITIQUE_REGISTERED = 'EDITIQUE_REGISTERED'
export const OPTION_EDITIQUE_REGISTERED_AR = 'EDITIQUE_REGISTERED_AR'
