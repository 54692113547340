import React, { FunctionComponent } from 'react'
import { FieldProps, SelectOption } from '../FormInterfaces'
import ErrorField from '../errors/ErrorField'

interface SelectFieldProps extends FieldProps {
    options: SelectOption[],
    multiple?: boolean,
    required?: boolean
}

const SelectField: FunctionComponent<SelectFieldProps> = ({
  className = '',
  inputId,
  classNameFormGroup,
  label,
  options,
  fieldStatus = {},
  value = '',
  onChange,
  onFocus,
  placeholder = '',
  disabled = false,
  readOnly = false,
  multiple = false,
  required = false
}) => (
  <div className={`form-group ${classNameFormGroup || ''}`}>
    {label && <label className='form-label' htmlFor={inputId}>{label}{required && <span className='text-danger'> *</span>}</label>}
    <select
      id={inputId}
      name={inputId}
      className={`form-control custom-select  one-line-ellipsis ${value === '' ? 'placeholder' : ''} ${className}`}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      disabled={disabled || readOnly}
      multiple={multiple}
      onFocus={onFocus}
    >
      {placeholder && (
        <option key='placeholder' value='' className={required ? 'd-none' : ''}>{placeholder}</option>
      )}
      {options && options.length > 0 && options.map((option, index) => (
        <option key={`${index}-${option.value}`} value={option.value} disabled={option.disabled}>
          {option.label}
        </option>
      ))}
    </select>

    <ErrorField message={fieldStatus[inputId]} />
  </div>
)

export default SelectField
