import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { buildEventType, CheckboxField, EventType } from '@inpi-dm/components'
import { storeAppealUpdate } from 'store/appeal/appealActions'
import OverviewAppeal from './OverviewAppeal'
import OverviewRoyalties from '../../../payment/OverviewRoyalties'
import { Payment } from '../../../../interfaces/DepositInterfaces'
import AppealService from '../../../../services/transaction/AppealService'
import DownloadLink from '../../../deposit/download/DownloadLink'
import Message from '../../../../constants/Message'

const OverviewFormProrogation = () => {
  const dispatch = useDispatch()
  const appeal = useSelector((state : RootStateOrAny) => state.appeal)
  const fieldStatus = useSelector((state: RootStateOrAny) => state.appealFieldStatus)
  const [infoPrice, setInfoPrice] = useState<Payment>({ lines: [], totalAmount: 0 })

  /**
     * Enregistrement des données dans le store à la saisie
     * @param event
     */
  const handleChange = (event: EventType) => {
    const { value } = event.target

    dispatch(storeAppealUpdate({
      overviewSimilarInformation: value
    }))
  }

  useEffect(() => {
    // Reset case à cocher
    handleChange(buildEventType('overviewSimilarInformation', false))

    // Récupération des informations concernant le paiement
    if (appeal.id) {
      AppealService.getPayment(appeal.id)
        .then((payment) =>
          payment && setInfoPrice(payment)
        )
    }
  }, [])

  return (
    <div className='is-validated'>
      {appeal &&
        <>
          <div className='row mx-3 d-flex justify-content-between'>
            <h1><FormattedMessage id='overview_deposit_form_title' /></h1>
            <DownloadLink id={appeal.id} generate name={`${Message.overview_deposit_pdf_title}.pdf`} internalName={`${Message.overview_deposit_pdf_title}.pdf`} />
          </div>

          <div className='col mt-3'>
            <OverviewAppeal transaction={appeal} />

            <div className='row'>
              <div className='col-12 mb-3'>
                <OverviewRoyalties infoPrice={infoPrice} />
              </div>
            </div>

            <div className='d-flex mt-3'>
              <CheckboxField
                className='mx-auto'
                labelClassName='font-weight-bold'
                inputId='overviewSimilarInformation'
                label={<FormattedMessage id='overview_deposit_form_checkbox_similar_information' />}
                checked={appeal.overviewSimilarInformation}
                onChange={handleChange}
                fieldStatus={fieldStatus.overviewSimilarInformation}
              />
            </div>
          </div>
        </>}
    </div>
  )
}

export default OverviewFormProrogation
