import {
  FieldStatus,
  containsErrors,
  ModelDeposit,
  ModelDesignation,
  ReproductionLightDeposit,
  messageIsFilled, isFilled
} from '@inpi-dm/components'
import Message from '../../../../../../constants/Message'
import store from '../../../../../../store/store'
import { storeDepositFieldStatusUpdate } from '../../../../../../store/deposit/depositActions'
import ReproductionFormValidator from '../../reproduction/validator/ReproductionFormValidator'

class ModelFormValidator {
    /**
     * Valide le contenu d'un import de modèles en masse
     * @param nbRepros: nombre de reproductions déjà importées
     * @param designation: La désignation à donner aux modèles
     * @param reproductions: Les fichiers de reproduction importés
     */
    validateModelMassImport = (nbRepros: number, designation?: ModelDesignation, reproductions?: File[]) => ({
      reproductions: reproductions && isFilled(reproductions) && reproductions.length + nbRepros <= 100 ? '' : Message.error_number_reproductions,
      modelDesignation: isFilled(designation?.name) ? '' : Message.mass_import_error_zero_designation
    })

    /**
     * Vérifie que toutes les reproductions d'un modèle sont valides
     * @param reproduction
     */
    validateAllReproductions = (reproduction : ReproductionLightDeposit[]) : string => {
      const fieldStatus = reproduction.map(oneRepro => ReproductionFormValidator.validateMandatory(oneRepro))
      const error = fieldStatus.filter(oneReproFieldStatus => containsErrors(oneReproFieldStatus))

      if (error.length > 0) {
        // Alors au moins 1 reproduction n'est pas valide
        return Message.error_unvalidate_reproductions
      }

      return ''
    }

    /**
   * Vérifie les infos d'un modèle avant de le valider
   * @param model: model en cours de modification/création
   * @param index: index du model actuel
   * @param models: liste des models de la demande
   * @param nbRepros: nombre de reproductions déjà importées
   */
    validateMandatory = (model: ModelDeposit, index: number, models : ModelDeposit[], nbRepros = 0): FieldStatus => {
      return {
        modelDesignation: messageIsFilled(model.modelDesignation?.name) ||
                this.validateDesignationLocarno(index, models, model.modelDesignation),

        // Le modèle doit contenir au minimum 1 reproductions et au maximum 100 reproductions
        // si le modele a le bon nombre de reproduction, il doit avoir toutes ces reproductions valides
        reproductions: model.reproductions && model.reproductions.length > 0 && model.reproductions.length + nbRepros <= 100
          ? this.validateAllReproductions(model.reproductions) : Message.error_number_reproductions
      }
    }

    /**
     * Vérifie que la designation du modèle a la même classe principale que les autres modèles
     * si le référentiel Locarno a été utilisé
     */
    validateDesignationLocarno = (index: number, models : ModelDeposit[], designation?: ModelDesignation) => {
      const ref = designation?.ref

      if (ref) {
        // on cherche le premier modèle avec une désignation différente
        const error = !!models?.find((model, i) => (
                model.modelDesignation?.ref &&
                i !== index &&
                model.modelDesignation.ref.slice(0, 2) !== ref.slice(0, 2)
        ))

        if (error) {
          return Message.error_locarno_category
        }
      }

      return ''
    }

    /**
     * Vérifie que la designation du modèle a la même classe principale que les autres modèles
     * si le référentiel Locarno a été utilisé
     *
     * @param designation désignation du modèle
     * @param index index du modèle dans la liste
     * @param models
     * @returns {boolean} true si il y a une erreur, false sinon
     */
    onChangeDesignationLocarno = (designation: ModelDesignation, index: number, models : ModelDeposit[]) => {
      const fieldStatus = store.getState().depositFieldStatus

      const error = this.validateDesignationLocarno(index, models, designation)

      // Ajout du message d'erreur sur le modèle courant
      const updateField = fieldStatus.models ? [...fieldStatus.models] : []
      updateField[index] = {
        ...updateField[index],
        modelDesignation: error
      }

      store.dispatch(storeDepositFieldStatusUpdate({
        models: updateField
      }))

      return !!error
    }

    /**
     * Vérification de tous les champs pour valider le modèle
     * @param model
     * @param nbCurrent
     * @param models
     * @param nbRepros
     * @returns {boolean} true si il n'y a pas d'erreur, false sinon
     */
    validateOnNext = (model: ModelDeposit, nbCurrent: number, models: ModelDeposit[], nbRepros: number) : boolean => {
      const fieldStatus = this.validateMandatory(model, nbCurrent, models, nbRepros)

      const allFieldStatusModel: FieldStatus[] = store.getState().depositFieldStatus.models || []
      const modelsCopy = [...allFieldStatusModel]
      modelsCopy[nbCurrent] = fieldStatus

      store.dispatch(storeDepositFieldStatusUpdate({
        models: modelsCopy
      }))

      return !containsErrors(fieldStatus)
    }
}

export default new ModelFormValidator()
