import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Transaction } from '@inpi-dm/components'
import ProrogationService from '../../../services/transaction/ProrogationService'
import { Payment } from '../../../interfaces/DepositInterfaces'
import PaymentForm from '../../payment/PaymentForm'
import OverviewRoyalties from '../../payment/OverviewRoyalties'
import { PAYMENT_URL_PROROGATION } from '../../../constants/PaymentConstants'
import { PAYMENT_OBJECT } from '../../../constants/DepositConstants'

interface PaymentProrogationProps {
  transaction: Transaction,
  hasSupplementExcluded?: boolean
}

const PaymentProrogation: FC<PaymentProrogationProps> = ({
  transaction,
  hasSupplementExcluded
}) => {
  const [infoPrice, setInfoPrice] = useState<Payment>()

  useEffect(() => {
    // Récupération des informations concernant le paiement
    ProrogationService.getPayment(transaction.id, hasSupplementExcluded)
      .then((payment) => {
        return payment && setInfoPrice(payment)
      })
  }, [transaction])

  return (
    <PaymentForm
      label='Demande initiale'
      overviewPrices={<OverviewRoyalties infoPrice={infoPrice} />}
      object={transaction}
      urlType={PAYMENT_URL_PROROGATION}
      objectName={PAYMENT_OBJECT.TRANSACTION}
      title={<FormattedMessage id='payment' />}
      totalAmount={infoPrice?.totalAmount}
      isMultiTransactions={transaction?.prorogation.titles.length > 1}
      hasSupplementExcluded={!!hasSupplementExcluded}
    />
  )
}

export default PaymentProrogation
