import React, { FC } from 'react'
import { EventType, FieldStatus, SubmitButton, TextField } from '@inpi-dm/components'
import { FormattedMessage, useIntl } from 'react-intl'
import { DepositIntervenant } from '../..'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

interface PersonMoraleSirenModalProps {
  intervenant?: DepositIntervenant,
  onChange: (event: EventType|EventType[]) => void,
  handleClose: () => void,
  fieldStatus?: FieldStatus,
  fillContributorInfo?: (siren: string) => void,
}

const PersonMoraleSirenModal: FC<PersonMoraleSirenModalProps> = ({
  intervenant = {},
  handleClose,
  onChange,
  fieldStatus,
  fillContributorInfo
}) => {
  const intl = useIntl()
  return (
    <>
      <div className='my-2 w-100 row justify-content-md-center '>
        <div className='col-12 d-flex justify-content-between'>
          <TextField
            inputId='siren'
            classNameFormGroup='col-11 pl-0'
            label={intl.formatMessage({ id: 'field_numero_siren_label' })}
            value={intervenant.siren || ''}
            onChange={onChange}
            fieldStatus={fieldStatus}
            maxLength='100'
          />
          <button
            className='btn btn-outline-primary min-width-auto col-1 px-0 py-1 mb-3' type='button'
            onClick={() => {
              fillContributorInfo && fillContributorInfo(intervenant?.siren || '')
              handleClose && handleClose()
            }}
          >
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
        <SubmitButton className='btn btn-outline-primary' onClick={handleClose}>
          <FormattedMessage id='intervenant_siren_close' />
        </SubmitButton>
      </div>
    </>
  )
}

export default PersonMoraleSirenModal
