import React, { FC, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  BY_MAIL, ModalComponent, NoResult,
  OfficialDocumentRequest,
  RequestListTable, SubmitButton
} from '@inpi-dm/components'
import { ASKED_DOCUMENTS_TABLE_TITLE } from '../../../../constants/OfficialDocumentConstants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import TransactionService from '../../../../services/transaction/TransactionService'
import fileDownload from 'js-file-download'
import OfficialDocumentService from '../../../../services/transaction/OfficialDocumentService'
import Message from '../../../../constants/Message'

interface AskedOfficialDocumentProps {
  idTransaction: string,
  requests: OfficialDocumentRequest[]
  setRequests: (requests: OfficialDocumentRequest[]) => void,
  setReload: () => void
}

const AskedOfficialDocument: FC<AskedOfficialDocumentProps> = ({
  idTransaction,
  requests,
  setRequests,
  setReload
}) => {
  const intl = useIntl()
  const [showReadModal, setShowReadModal] = useState<boolean>(false)
  const [deleteIndex, setDeleteIndex] = useState<number>(0)

  /**
     * Si on affiche ou non le l'icône pour télécharger le document
     * @param request
     */
  const isDownloadLinkVisible = (request: OfficialDocumentRequest) => {
    return request.internalNameDocument && !request.deletedByFo && request.availabilityDate && new Date() >= new Date(request.availabilityDate) && request.desiredFormatOfficialDocument !== BY_MAIL
  }

  /**
   * Contenu de la modale de vérification de suppression
   */
  const readContent = () => {
    return (
      <div><FormattedMessage id='official_document_read_modal_body' /></div>
    )
  }

  const handleFoDelete = () => {
    return OfficialDocumentService.updateOfficialDocument(idTransaction, { deleteOfficialDocIndex: deleteIndex }).then(() => setShowReadModal(false))
  }

  const smallScreenDisplay = (request: OfficialDocumentRequest, numNatToShow: string, index: number, modelSelected?: string) => {
    return (
      <td className='d-block d-lg-none'>
        <ul>
          <li><FormattedMessage id='official_document_type' /> : <FormattedMessage id={`official_document_type_${request.officialDocumentType.code}`} /></li>
          <li><FormattedMessage id='official_document_numnat' />{` : ${numNatToShow}`}</li>
          {modelSelected && <li><FormattedMessage id='official_document_model_number' />{` : ${modelSelected}`}</li>}
          <li><FormattedMessage id='official_document_quantity' />{` : ${request.quantity}`}</li>
          <li><FormattedMessage id='official_document_format' /> : {intl.formatMessage({ id: `official_document_sending_${request.desiredFormatOfficialDocument}` })}</li>
          {isDownloadLinkVisible(request) &&
            <div>
              <li>
                <SubmitButton
                  className='btn-actions-download btn-link text-primary' onClick={() => {
                    setDeleteIndex(index)
                    setShowReadModal(true)
                  }}
                >
                  <FormattedMessage id='official_document_read' />
                  <span className='text-success'><FontAwesomeIcon icon={faCheckCircle} size='xs' className='ml-2' /></span>
                </SubmitButton>
              </li>
              <li>
                <SubmitButton
                  className='btn-actions-download btn-link text-primary' onClick={() => TransactionService.getDocumentFile(idTransaction, request.internalNameDocument).then(data => {
                    return fileDownload(data, `${intl.formatMessage({ id: 'overview_official_document_filename' })}-${index + 1}.pdf`, 'pdf')
                  })}
                >
                  <FormattedMessage id='common_download' />
                  <FontAwesomeIcon icon={faDownload} size='xs' className='ml-2' />
                </SubmitButton>
              </li>
            </div>}
        </ul>
      </td>
    )
  }

  return (
    <div>
      {requests.length > 0 ? (
        <RequestListTable
          tableTitle={ASKED_DOCUMENTS_TABLE_TITLE}
          showPagination={false}
        >
          <tbody>
            {requests.map((request, index) => {
              let numNatToShow = request.title?.numNat || ''
              if (request.copyNum) {
                numNatToShow = request.copyNum
              }

              return (request.modelsSelected && request.modelsSelected.length !== 0)
                ? (
                  request.modelsSelected.map((modelSelected, modelIndex: number) => (
                    <tr key={`request-${index}-${modelIndex}`}>
                      <td
                        title={intl.formatMessage({ id: `official_document_type_${request.officialDocumentType.code}` })}
                        className='d-none d-lg-table-cell w-36 text-truncate'
                      ><FormattedMessage id={`official_document_type_${request.officialDocumentType.code}`} />
                      </td>
                      <td title={numNatToShow} className='d-none d-lg-table-cell w-16 text-truncate'>{numNatToShow}</td>
                      <td title={modelSelected} className='d-none d-lg-table-cell w-15 text-truncate'>{modelSelected || '-'}</td>
                      <td title={request.quantity?.toString()} className='d-none d-lg-table-cell w-15 text-truncate'>{request.quantity}</td>
                      <td
                        title={intl.formatMessage({ id: `official_document_sending_${request.desiredFormatOfficialDocument}` })}
                        className='d-none d-lg-table-cell w-15 text-truncate'
                      >{intl.formatMessage({ id: `official_document_sending_${request.desiredFormatOfficialDocument}` })}
                      </td>
                      <td className='d-none d-lg-table-cell text-right'>
                        {isDownloadLinkVisible(request) &&
                          <div className='d-flex justify-content-start'>
                            <SubmitButton
                              className='btn btn-link text-success bg-transparent mr-2'
                              replaceOnLoad
                              onClick={() => {
                                setDeleteIndex(index)
                                setShowReadModal(true)
                              }}
                            >
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </SubmitButton>
                            <SubmitButton
                              className='btn btn-link text-primary bg-transparent'
                              replaceOnLoad
                              onClick={() => TransactionService.getDocumentFile(idTransaction, request.internalNameDocument).then(data => {
                                return fileDownload(data, `${intl.formatMessage({ id: 'overview_official_document_filename' })}-${index + 1}.pdf`, 'pdf')
                              })}
                            >
                              <FontAwesomeIcon icon={faDownload} />
                            </SubmitButton>
                          </div>}
                      </td>
                      {smallScreenDisplay(request, numNatToShow, index, modelSelected)}
                    </tr>
                  ))
                )
                : (
                  <tr key={`request-${index}`}>
                    <td
                      title={intl.formatMessage({ id: `official_document_type_${request.officialDocumentType.code}` })}
                      className='d-none d-lg-table-cell w-36 text-truncate'
                    ><FormattedMessage id={`official_document_type_${request.officialDocumentType.code}`} />
                    </td>
                    <td title={numNatToShow} className='d-none d-lg-table-cell w-16 text-truncate'>{numNatToShow}</td>
                    <td className='d-none d-lg-table-cell w-15 text-truncate'>-</td>
                    <td title={request.quantity?.toString()} className='d-none d-lg-table-cell w-15 text-truncate'>{request.quantity}</td>
                    <td
                      title={intl.formatMessage({ id: `official_document_sending_${request.desiredFormatOfficialDocument}` })}
                      className='d-none d-lg-table-cell w-15 text-truncate'
                    >{intl.formatMessage({ id: `official_document_sending_${request.desiredFormatOfficialDocument}` })}
                    </td>
                    <td className='d-none d-lg-table-cell text-right'>
                      {isDownloadLinkVisible(request) &&
                        <div className='d-flex justify-content-start'>
                          <SubmitButton
                            className='btn btn-link text-success bg-transparent mr-2'
                            replaceOnLoad
                            onClick={() => {
                              setDeleteIndex(index)
                              setShowReadModal(true)
                            }}
                          >
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </SubmitButton>
                          <SubmitButton
                            className='btn btn-link text-primary bg-transparent'
                            replaceOnLoad
                            onClick={() => TransactionService.getDocumentFile(idTransaction, request.internalNameDocument).then(data => {
                              return fileDownload(data, `${intl.formatMessage({ id: 'overview_official_document_filename' })}-${index + 1}.pdf`, 'pdf')
                            })}
                          >
                            <FontAwesomeIcon icon={faDownload} />
                          </SubmitButton>
                        </div>}
                    </td>
                    {smallScreenDisplay(request, numNatToShow, index)}
                  </tr>
                )
            })}
          </tbody>
        </RequestListTable>
      ) : (
        <NoResult message={Message.official_document_no_result} />
      )}
      <ModalComponent
        title={<FormattedMessage id='official_document_read_modal_title' />}
        show={showReadModal}
        customContent={readContent}
        onClick={() => {
          return handleFoDelete().then(() => {
            setRequests(requests)
            return setReload()
          })
        }}
        handleClose={() => setShowReadModal(false)}
      />
    </div>
  )
}

export default AskedOfficialDocument
