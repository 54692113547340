import React, { useState, useEffect, FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { CheckboxField, EventType, CardBlock, ProtectionExtension, Title } from '@inpi-dm/components'
import ContentService from '../../services/content/ContentService'
import cloneDeep from 'lodash.clonedeep'

interface ProtectionExtensionFormProps {
  updateStore: (toUpdate: ProtectionExtension[]|Title[]) => void,
  currentProtectionExtensions: ProtectionExtension[]
  titles?: Title[],
  selectedInitProtectionExtensions?: Title[]
}

const ProtectionExtensionForm: FC<ProtectionExtensionFormProps> = ({ updateStore, currentProtectionExtensions, titles }) => {
  const [extensions, setExtensions] = useState<ProtectionExtension[]>([])

  useEffect(() => {
    // Récupération de la liste des extensions de protections possibles
    ContentService.getProtectionExtensions().then((extensions: ProtectionExtension[]) =>
      setExtensions(extensions)
    )
  }, [])

  /**
   * Mise à jour dans le state des extensions de protection
   * @param event
   * @param titles
   * @param indexTitle
   */
  const handleExtensionChange = (event: EventType, titles?: Title[], indexTitle?: number) => {
    const { name, value } = event.target

    if (titles) {
      const idProtectionExtension = name.split('-')[1]
      const newTitles = cloneDeep(titles)
      if (!value) {
        newTitles[indexTitle] = {
          ...newTitles[indexTitle],
          protectionExtensions: newTitles[indexTitle].protectionExtensions.filter((item: ProtectionExtension) => item.id.toString() !== idProtectionExtension)
        }
      } else {
        const ext = extensions && extensions.find(ext => ext.id.toString() === idProtectionExtension)
        newTitles[indexTitle] = {
          ...newTitles[indexTitle],
          protectionExtensions: [...newTitles[indexTitle].protectionExtensions, ext]
        }
      }
      updateStore(newTitles)
    } else {
      let protectionExtensions = [...currentProtectionExtensions]

      // Si l'extension est décochée, on la supprime des extensions choisies
      if (!value) {
        protectionExtensions = protectionExtensions.filter(item => item.label !== name)
      } else {
        // Sinon, on récupère les propriétés de l'extension à partir de son libellé et on l'ajoute dans les options choisies
        const ext = extensions && extensions.find(ext => ext.label === name)
        if (ext) {
          protectionExtensions.push(ext)
        }
      }
      updateStore(protectionExtensions)
    }
  }

  const renderTitlesExtensions = (renderedTitles: Title[]) =>
    renderedTitles.map((title: Title, indexTitle: number) => {
      return (
        <div key={`titles-${title.id}`}>
          {
            extensions.map((protectionExtension) => (
              <div key={`extension-${title.id}-${protectionExtension.id}`}>
                <CheckboxField
                  inputId={`${title.id}-${protectionExtension.id}`}
                  key={`${title.id}-${protectionExtension.id}`}
                  label={<span><FormattedMessage id='options_depot_form_extensions_title_nb' />{title.numNat} - <FormattedMessage id='options_depot_form_extensions_subtitle' /> {protectionExtension.label}</span>}
                  labelClassName='font-weight-bold pl-3'
                  checked={title.protectionExtensions &&
                      title.protectionExtensions.filter((protectionExtensionTitle:ProtectionExtension) => {
                        return protectionExtensionTitle && protectionExtensionTitle.id === protectionExtension.id
                      }).length > 0}
                  onChange={(e: EventType) => handleExtensionChange(e, titles, indexTitle)}
                />
              </div>
            ))
          }
        </div>
      )
    })

  return (
    <div>
      {(!titles || titles.length > 0) && (
        <CardBlock
          shadow
          className='mt-3'
          bodyClassName='pb-2 pt-3'
          header={<FormattedMessage id='options_depot_form_extensions_title' />}
        >
          <span>
            <FormattedMessage
              id={!titles ? 'options_depot_form_extensions_description' : 'options_prorogation_form_extensions_description'}
              values={{ a: (...chunks) => (<a href={process.env.REACT_APP_URL_HELP_EXTENSION} target='_blank' rel='noopener noreferrer'>{chunks}</a>) }}
            />
          </span>
          {!titles ? (
            <div className='mt-3'>
              {extensions && extensions.map(extension => (
                <CheckboxField
                  inputId={extension.label}
                  key={extension.id}
                  label={<span><FormattedMessage id='options_depot_form_extensions_subtitle' /> {extension.label}</span>}
                  labelClassName='font-weight-bold pl-3'
                  checked={!!(currentProtectionExtensions && currentProtectionExtensions.find((ext : ProtectionExtension) => ext.id === extension.id))}
                  onChange={handleExtensionChange}
                />
              ))}
            </div>
          ) : (
            <div className='mt-3'>
              {renderTitlesExtensions(titles)}
            </div>
          )}
        </CardBlock>
      )}
    </div>
  )
}

export default ProtectionExtensionForm
