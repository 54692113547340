export const DOCUMENT_STATUS = {
  NEW: 'NEW',
  WAITING_EXAM: 'WAITING_EXAM',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  DELETED: 'DELETED'
}

export const DOCUMENT_COMMUNICABILITIES = {
  COMMUNICABLE: 'COMMUNICABLE',
  NON_COMMUNICABLE: 'NON_COMMUNICABLE',
  PUBLIC: 'PUBLIC',
  INTERNAL: 'INTERNAL'
}

export const APPEAL_TYPE_ACT = {
  value: 'APPEAL_TYPE_ACT'
}

export const APPEAL_TYPE_SUPPORTING_DOCUMENT = {
  value: 'APPEAL_TYPE_SUPPORTING_DOCUMENT'
}
