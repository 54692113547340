export const OFFICIAL_DOCUMENT_FIELD = [
  'officialDocumentRequests',
  'isAdministration'
]

export const DEFAULT_TITLE_OFFICIAL_DOCUMENT = {
  quantity: 1
}

export const OFFICIAL_COPY_FRENCH_DESIGN = 'OFFICIAL_COPY_FRENCH_DESIGN'
export const CERTIFICATE_OF_IDENTITY_FRENCH_DESIGN = 'CERTIFICATE_OF_IDENTITY_FRENCH_DESIGN'
export const STATUS_OF_INSCRIPTION = 'STATUS_INSCRIPTION'
export const COPY_OF_INSCRIPTION = 'COPY_OF_INSCRIPTION'
export const COPY_OF_PROROGATION = 'COPY_OF_PROROGATION'

export const BY_PORTAL = 'BY_PORTAL'
export const BY_MAIL = 'BY_MAIL'
export const BY_DAS = 'BY_DAS'
