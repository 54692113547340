import React, { FC, useEffect, useState } from 'react'
import {
  FilAriane
} from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import HelpCard from './HelpCard'
import { HelpInfo } from '../../interfaces/HelpInterfaces'
import HelpService from '../../services/content/HelpService'
import { HELP_TYPE } from '../../constants/HelpConstants'

const Help: FC = () => {
  const [helpsInfo, setHelpsInfo] = useState<HelpInfo[]>([])

  useEffect(() => {
    HelpService.getHelps().then(helpsPromise => {
      const helpsInfo: HelpInfo[] = HELP_TYPE.map(helpType => ({
        type: helpType.value,
        items: helpsPromise.filter(help => help.type === helpType.value)
      }))
      setHelpsInfo(helpsInfo)
    })
  }, [])

  return (
    <div id='help'>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id='help_breadcrumb_title' /></span>
      </FilAriane>
      <div className='row justify-content-center'>
        {helpsInfo.map(helpInfo =>
          <div key={helpInfo.type} className='col-md-3'>
            <HelpCard helpInfo={helpInfo} />
          </div>)}
      </div>
    </div>
  )
}

export default Help
