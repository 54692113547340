import store from '../../../../../store/store'
import { storeDepositFieldStatusUpdate } from '../../../../../store/deposit/depositActions'
import { containsErrors, FieldStatus } from '@inpi-dm/components'
import Message from '../../../../../constants/Message'

class OverviewFormDepositValidator {
    validateMandatory = (similarInformation: boolean): FieldStatus => {
      return {
        overviewSimilarInformation: similarInformation ? '' : Message.required_checkbox_field
      }
    }

    validateOnChangeStep = () => {
      const deposit = store.getState().deposit

      const fieldStatus = this.validateMandatory(deposit.overviewSimilarInformation || false)
      store.dispatch(storeDepositFieldStatusUpdate({
        overviewSimilarInformation: fieldStatus
      }))

      return !containsErrors(fieldStatus)
    }
}

export default new OverviewFormDepositValidator()
