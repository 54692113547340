import { DepositDocument } from '../interfaces/ModelInterfaces'

class DocumentUtils {
  /**
   * Supprime les duplications de document
   *
   * @param documents DepositDocument[] Tableau de document à supprimer les duplications
   */
  removeDuplicateDocument = (documents: DepositDocument[]): string => {
    const removedDuplicateDocuments = {}
    documents.forEach((document) => {
      if (!Object.keys(removedDuplicateDocuments).includes(document.internalName)) {
        removedDuplicateDocuments[document.internalName] = document
      }
    })
    return Object.values(removedDuplicateDocuments)
  }
}

export default new DocumentUtils()
