import React, { FC } from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { toast } from 'react-toastify'
import {
  buildEventType,
  DATE_ISO,
  DateUtils,
  EventType,
  FieldStatus,
  Title,
  TitleInfos,
  Publication,
  SelectOption,
  ModelDeposit
} from '@inpi-dm/components'
import SearchRecordsForm from './SearchRecordsForm'
import RecordFormManual from './RecordFormManual'

interface SearchAndManualRecordsFormProps extends WrappedComponentProps {
  title: Title
  setTitle: (title: Title) => void,
  modelsSelected?: string[],
  setModelsSelected?: (newModelsSelected: string[]) => void,
  publicationsIds: string[],
  setPublicationsIds: (publicationsIds: string[]) => void,
  fieldStatus: FieldStatus,
  setFieldStatus: (fieldStatus: FieldStatus) => void,
  setDisclaimerTitle?: (title: Title) => void,
  setCurrentContributors: (curentContributors: any) => void,
  setIdRecordFO?: (idRecord: string) => void,
  optionSearch: SelectOption[],
  allRecordRequestedForPublication?: boolean,
  idPublicationShown?: boolean,
  modelsShown?: boolean,
  originShown?: boolean,
  classNameCol?: string,
  procedure?: string
}

const SearchAndManualRecordsForm: FC<SearchAndManualRecordsFormProps> = ({
  title,
  setTitle,
  setModelsSelected,
  modelsSelected,
  publicationsIds,
  setPublicationsIds,
  fieldStatus,
  setFieldStatus,
  setDisclaimerTitle,
  setCurrentContributors,
  setIdRecordFO,
  optionSearch,
  allRecordRequestedForPublication,
  idPublicationShown,
  modelsShown,
  originShown,
  classNameCol,
  procedure
}) => {
  /**
   * Enregistrement des données dans le state à la saisie
   * @param event
   */
  const handleChange = (event: EventType) => {
    const { name, value } = event.target

    let updatedTitle
    if (name === 'modelsSelected') {
      updatedTitle = { ...title }
      setModelsSelected && setModelsSelected(value)
    } else {
      updatedTitle = {
        ...title,
        [name]: value
      }
    }
    if (name === 'publications') {
      setPublicationsIds(value ? value.map((publication: Publication) => publication.idPublication) : [])
      // remise a zéro des fieldStatus concernant les reproductions
      setFieldStatus({ ...fieldStatus, publications: [] })
    }
    setTitle(updatedTitle)
    setDisclaimerTitle && setDisclaimerTitle(updatedTitle)
  }

  const handleSearchResult = (result: TitleInfos, numModel?: string|undefined) => {
    // si titre a été trouvé
    if (result) {
      const publicationsWhithoutThisModel = numModel && title && title.publications ? title.publications.filter((publication) => publication.numModel !== numModel) : []
      const publications = [...publicationsWhithoutThisModel, ...result.publications || []]
      const modelsSelected: string[] = []
      if (modelsShown && result.records?.length === 1) {
        result.records[0].models.forEach((model: ModelDeposit) => {
          if (model && model.position !== undefined) {
            modelsSelected.push(`${result.numNat}-${parseInt(model.position) + 1}`)
          }
        })
      }
      modelsSelected.sort()
      handleChange(buildEventType('publications', publications))

      setCurrentContributors({
        agent: result.agent || null,
        holders: result.holders || [],
        recipient: result.recipient || null
      })
      const updateTitle = {
        ...title,
        origin: result.origin,
        numNat: result.numNat,
        publications,
        depositDate: DateUtils.formateDateToFormat(result.depositDate, DATE_ISO)
      }
      setTitle(updateTitle)
      setModelsSelected && setModelsSelected(modelsSelected)
      setDisclaimerTitle && setDisclaimerTitle(updateTitle)
    } else {
      toast.warning(<FormattedMessage id='warning_get_titles_null_result' />)
    }
  }

  return (
    <>
      <SearchRecordsForm
        handleChange={handleChange}
        handleSearchResult={handleSearchResult}
        setIdRecordFO={setIdRecordFO}
        optionSearch={optionSearch}
        allRecordRequestedForPublication={allRecordRequestedForPublication}
      />
      <div className='row m-0'>
        <RecordFormManual
          title={title}
          handleChange={handleChange}
          fieldStatus={fieldStatus}
          setFieldStatus={setFieldStatus}
          publicationsIds={publicationsIds}
          originShown={originShown}
          idPublicationShown={idPublicationShown}
          modelsShown={modelsShown}
          modelsSelected={modelsSelected}
          classNameCol={classNameCol}
          procedure={procedure}
        />
      </div>
    </>
  )
}

export default injectIntl(SearchAndManualRecordsForm)
