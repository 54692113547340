import React, { Component } from 'react'
import { EventType, FieldValidator, FormManager } from '@inpi-dm/components'
import LoginFormContent from './LoginFormContent'
import { validatePassword, validateUsername } from './validator/LoginValidators'
import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import AuthService from '../../services/user/AuthService'
import { storeLogin } from '../../store/user/userActions'

interface LoginFormState {
  user: {
    username: string,
    password: string,
  },
  validators: FieldValidator,
  alerteCredentials: string,
}

const connector = connect(
  null,
  {
    login: storeLogin
  }
)

class LoginForm extends Component<ConnectedProps<typeof connector>, LoginFormState> {
  constructor (props: any) {
    super(props)
    this.state = {
      user: {
        username: '',
        password: ''
      },
      validators: {
        username: validateUsername,
        password: validatePassword
      },
      alerteCredentials: ''
    }
  }

  handleChange = (event: EventType) => {
    const { name, value } = event.target

    this.setState(oldState => ({
      user: {
        ...oldState.user,
        [name]: value
      }
    }))
  };

  alerteCredentials = (value: string) => {
    this.setState({
      alerteCredentials: value
    })
  };

  handleSubmit = () => {
    const { user } = this.state
    const { login } = this.props

    this.alerteCredentials('')

    return AuthService.getUserInfo(user)
      .then(login)
      .catch(() => this.alerteCredentials('login_invalid_couple'))
  };

  render () {
    const {
      user, validators, alerteCredentials
    } = this.state
    return (
      <>
        <div className='row'>
          <div className='col-6 offset-3'>
            <FormManager
              onChange={this.handleChange}
              onSubmit={this.handleSubmit}
              object={user}
              validators={validators}
              view={<LoginFormContent />}
            />
          </div>
        </div>
        <div className='row justify-content-center mt-4'>
          <div className='col-12 col-md-10'>
            {alerteCredentials && (
              <div className='fade alert alert-danger show'>
                <FormattedMessage id='login_invalid_couple' />
              </div>
            )}
          </div>
        </div>
      </>
    )
  }
}

export default connector(LoginForm)
