import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { buildEventType, CheckboxField, EventType, PROCESS_EXPEDITED, PROCESS_NORMAL } from '@inpi-dm/components'
import { storeInscriptionUpdate } from '../../../../store/inscription/inscriptionActions'
import OverviewInscription from './OverviewInscription'
import OverviewRoyalties from '../../../payment/OverviewRoyalties'
import InscriptionService from '../../../../services/transaction/InscriptionService'
import { Payment } from '../../../../interfaces/DepositInterfaces'

const OverviewFormInscription = () => {
  const dispatch = useDispatch()
  const inscription = useSelector((state: RootStateOrAny) => state.inscription)
  const fieldStatus = useSelector((state: RootStateOrAny) => state.inscriptionFieldStatus)
  const [infoPrice, setInfoPrice] = useState<Payment>()
  const [isTaChecked, setIsTaChecked] = useState(inscription.process === PROCESS_EXPEDITED)
  const [isPaymentLoading, setIsPaymentLoading] = useState(false)

  const getPrices = () => {
    InscriptionService.getPayment(inscription.id)
      .then((payment) => {
        payment && setInfoPrice(payment)
        setIsPaymentLoading(false)
      })
  }

  /**
   * Enregistrement des données dans le store à la saisie
   * @param event
   */
  const handleChange = (event: EventType) => {
    const { value } = event.target

    dispatch(storeInscriptionUpdate({
      overviewSimilarInformation: value
    }))
  }

  /**
   * Modification du process dans le store
   * @param event
   */
  const handleChangeProcess = (event: EventType) => {
    const { value } = event.target

    setIsTaChecked(!isTaChecked)
    setIsPaymentLoading(true)
    InscriptionService.updateInscription(inscription.id, {
      process: value ? PROCESS_EXPEDITED : PROCESS_NORMAL
    }).then(getPrices)
  }

  useEffect(() => {
    // Reset case à cocher
    handleChange(buildEventType('overviewSimilarInformation', false))

    getPrices()

    return () => {
      InscriptionService.cancelRequest()
    }
  }, [])

  return (
    <div className='is-validated'>
      {inscription &&
        <>
          <div className='row mx-3 d-flex justify-content-between'>
            <h1><FormattedMessage id='overview_deposit_form_title' /></h1>
          </div>

          <div className='col mt-3'>
            <OverviewInscription transaction={inscription} />

            <div className='row d-flex justify-content-centera align-items-center mb-3'>
              <CheckboxField
                className='mx-auto'
                labelClassName='font-weight-bold'
                inputId='processExpedited'
                label={<FormattedMessage id='process_inscription' />}
                checked={isTaChecked}
                onChange={handleChangeProcess}
              />
            </div>

            <div className='row'>
              <div className='col-12 mb-3'>
                <OverviewRoyalties infoPrice={infoPrice} isPaymentLoading={isPaymentLoading} />
              </div>
            </div>

            <div className='d-flex mt-3'>
              <CheckboxField
                className='mx-auto'
                labelClassName='font-weight-bold'
                inputId='overviewSimilarInformation'
                label={<FormattedMessage id='overview_deposit_form_checkbox_similar_information' />}
                checked={inscription.overviewSimilarInformation}
                onChange={handleChange}
                fieldStatus={fieldStatus.overviewSimilarInformation}
              />
            </div>
          </div>
        </>}
    </div>
  )
}

export default OverviewFormInscription
