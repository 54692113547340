import React, { useState, useEffect, FC } from 'react'
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import {
  DepositDocument,
  CardBlock,
  CheckboxField,
  EventType,
  TextField,
  Title, ProrogationInscriptionRequest, PROROGATION_TYPE
} from '@inpi-dm/components'
import { storeProrogationUpdate } from '../../../../store/prorogation/prorogationActions'
import ProtectionExtensionForm from '../../../form/ProtectionExtensionForm'
import ProrogationInscriptionRequests from './ProrogationInscriptionRequests'
import TransactionService from '../../../../services/transaction/TransactionService'
import { ProrogationFieldStatus } from '../../../../interfaces/ProrogationInterfaces'
import DocumentAdditionalParts from './DocumentAdditionalParts'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'

interface RequestDetailsFormProps {
  inscriptionRequestsDocuments: DepositDocument[],
  setInscriptionRequestsDocuments: (inscriptionRequestsDocuments: DepositDocument[]) => void,
  additionalParts: DepositDocument[],
  setAdditionalParts: (additionalParts: DepositDocument[]) => void
}

const RequestDetailsForm: FC<RequestDetailsFormProps> = ({ inscriptionRequestsDocuments, setInscriptionRequestsDocuments, additionalParts, setAdditionalParts }) => {
  const dispatch = useDispatch()
  const transaction = useSelector((state : RootStateOrAny) => state.prorogation)
  const prorogationFieldStatus: ProrogationFieldStatus = useSelector((state:RootStateOrAny) => state.prorogationFieldStatus)
  const [isNewDepositor, setIsNewDepositor] = useState(false)

  useEffect(() => {
    setIsNewDepositor(transaction.prorogation.isNewDepositor)
  }, [])

  const updateExtensionStore = (titles: Title[]) => {
    dispatch(storeProrogationUpdate({
      ...transaction,
      prorogation: {
        ...transaction.prorogation,
        titles: [...titles]
      }
    }))
  }

  /**
   * Si on décoche la case de l'accompagnement de demande d'inscription, supprime toutes les demandes d'inscriptions et tous les documents qu'elles ont pu ajouter à la transaction
   * @param event
   */
  const handleIsNewDepositor = async (event: EventType) => {
    if (!event.target.value) {
      for (const inscriptionRequest of transaction.prorogation.inscriptionRequests) {
        if (inscriptionRequest.document && inscriptionRequest.document.internalName) {
          await TransactionService.deleteDocumentFile(transaction.id, inscriptionRequest.document.internalName)
        }
      }
      let updatedDocuments: DepositDocument[]|undefined = []
      TransactionService.getTransaction(transaction.id).then(r => {
        updatedDocuments = r.documents
      })
      dispatch(storeProrogationUpdate({
        ...transaction,
        documents: updatedDocuments,
        prorogation: {
          ...transaction.prorogation,
          isNewDepositor: event.target.value,
          inscriptionRequests: transaction.prorogation.inscriptionRequests.filter(inscriptionRequest => !inscriptionRequest.isOwnershipTransfer)
        }
      }))
    } else {
      dispatch(storeProrogationUpdate({
        ...transaction,
        prorogation: {
          ...transaction.prorogation,
          isNewDepositor: event.target.value
        }
      }))
    }
    setIsNewDepositor(event.target.value)
  }

  /**
   * Gestion de la demande d'inscription avec numéro d'ordre
   * @param event
   */
  const handleNumOrder = async (event: EventType) => {
    let newInscriptionRequests = [...transaction.prorogation.inscriptionRequests]
    const numOrderIndex = newInscriptionRequests.findIndex(inscriptionRequest => !inscriptionRequest.isOwnershipTransfer)
    if (!event.target.value) {
      if (numOrderIndex !== -1) {
        newInscriptionRequests.splice(numOrderIndex, 1)
      }
    } else {
      if (numOrderIndex !== -1) {
        newInscriptionRequests[numOrderIndex] = { numOrder: '' }
      } else {
        newInscriptionRequests = [...transaction.prorogation.inscriptionRequests
          .filter((inscriptionRequest: ProrogationInscriptionRequest) => inscriptionRequest.isOwnershipTransfer)].concat([{ numOrder: '' }])
      }
    }
    dispatch(storeProrogationUpdate({
      ...transaction,
      prorogation: {
        ...transaction.prorogation,
        inscriptionRequests: newInscriptionRequests
      }
    }))
  }

  /**
   * Savoir si il y a une demande d'inscription qui vient du champs avec numéro d'ordre
   */
  const isWithNumOrder = (): boolean => {
    return transaction.prorogation.inscriptionRequests.length > 0 &&
    transaction.prorogation.inscriptionRequests.find((inscriptionRequest: ProrogationInscriptionRequest) => !inscriptionRequest.isOwnershipTransfer)
  }

  /**
   * Récupère tous les titres qui ont des extensions de protections
   */
  const getEligibleTitles = (): Title[] => {
    return transaction.prorogation.titles.filter((title: Title) => !title.protectionExtensions || title.protectionExtensions.length > 0)
  }

  return (
    <div>
      <div className='row ml-3'>
        <header className='col-8 align-self-center'>
          <h1><FormattedMessage id='prorogation_request_details_title' /></h1>
        </header>
        <InternalReferenceField
          value={transaction.internalReference}
          procedureType={PROROGATION_TYPE.value}
          className='col-4 mb-4'
        />
      </div>
      <div className='mb-3'>
        <CardBlock shadow header={<FormattedMessage id='prorogation_linked_details_title' />}>
          <CheckboxField
            className='mb-1'
            inputId='prorogation-appeal'
            label={<FormattedMessage id='prorogation_appeal_decommission' />}
            checked={transaction.prorogation.isAppealOrDecommission}
            onChange={(e) => {
              dispatch(storeProrogationUpdate({
                ...transaction,
                prorogation: {
                  ...transaction.prorogation,
                  isAppealOrDecommission: e.target.value,
                  appealDecommissionNumber: undefined
                }
              }))
            }}
          />
          {transaction.prorogation.isAppealOrDecommission && (
            <div className='d-flex align-items-center'>
              <TextField
                inputId='appealDecommissionNumber'
                value={transaction.prorogation.appealDecommissionNumber || ''}
                onChange={(e:EventType) =>
                  dispatch(storeProrogationUpdate({
                    ...transaction,
                    prorogation: {
                      ...transaction.prorogation,
                      appealDecommissionNumber: e.target.value
                    }
                  }))}
                label={<FormattedMessage id='appeal_decommission_number' />}
              />
            </div>
          )}
          <CheckboxField
            className='mb-1'
            inputId='checkboxNumOrder'
            label={<FormattedMessage id='prorogation_inscription_request' />}
            checked={isWithNumOrder()}
            onChange={(e) => handleNumOrder(e)}
          />
          {isWithNumOrder() && (
            <div className='d-flex align-items-center'>
              <TextField
                inputId='numOrder'
                value={transaction.prorogation.inscriptionRequests.find((inscriptionRequest: ProrogationInscriptionRequest) => !inscriptionRequest.isOwnershipTransfer)?.numOrder}
                onChange={(e:EventType) =>
                  dispatch(storeProrogationUpdate({
                    ...transaction,
                    prorogation: {
                      ...transaction.prorogation,
                      inscriptionRequests: [...transaction.prorogation.inscriptionRequests
                        .filter((inscriptionRequest: ProrogationInscriptionRequest) => inscriptionRequest.isOwnershipTransfer)].concat([{ numOrder: e.target.value }])
                    }
                  }))}
                fieldStatus={prorogationFieldStatus.inscriptionRequests ? prorogationFieldStatus.inscriptionRequests[prorogationFieldStatus.inscriptionRequests.length - 1] : {}}
                label={<FormattedMessage id='prorogation_details_order_number' />}
              />
            </div>
          )}
          <CheckboxField
            className='mb-1'
            inputId='inscriptionRequest'
            label={<FormattedMessage id='prorogation_different_depositor' />}
            checked={isNewDepositor}
            onChange={(e) => handleIsNewDepositor(e)}
            fieldStatus={prorogationFieldStatus}
          />
          {isNewDepositor && (
            <ProrogationInscriptionRequests
              transaction={transaction}
              inscriptionRequestsDocuments={inscriptionRequestsDocuments}
              setInscriptionRequestsDocuments={setInscriptionRequestsDocuments}
              otherInscriptionRequests={transaction.prorogation.inscriptionRequests.filter(inscriptionRequest => !inscriptionRequest.isOwnershipTransfer)}
              inscriptionRequestsToTransfer={transaction.prorogation.inscriptionRequests.filter(inscriptionRequest => inscriptionRequest.isOwnershipTransfer)}
              fieldStatus={prorogationFieldStatus.inscriptionRequests || []}
            />
          )}
        </CardBlock>
      </div>
      <ProtectionExtensionForm
        updateStore={updateExtensionStore}
        currentProtectionExtensions={[]}
        titles={transaction.prorogation.titles}
        selectedInitProtectionExtensions={getEligibleTitles()}
      />
      <DocumentAdditionalParts
        transaction={transaction} fieldStatus={prorogationFieldStatus.additionalParts || {}}
        additionalParts={additionalParts} setAdditionalParts={setAdditionalParts}
      />
    </div>
  )
}

export default injectIntl(RequestDetailsForm)
