import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { ProrogationSearchTitle } from '../../../../interfaces/ProrogationInterfaces'
import {
  containsErrors,
  DATE_ISO,
  DateUtils,
  ModelDeposit,
  Publication,
  Record,
  ReproductionLightDeposit,
  SubmitButton,
  Title
} from '@inpi-dm/components'
import OneModelSearchSelection from './OneModelSearchSelection'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SelectRecordFormValidator from './validator/SelectRecordFormValidator'
import RecordService from '../../../../services/record/RecordService'
import ProrogationService from '../../../../services/transaction/ProrogationService'

interface SelectRecordSearchPartialSelectProps {
  resultSearch: ProrogationSearchTitle,
  recordSelected?: Record,
  publicationSearch?: (string|undefined)[],
  onSubmit: (title: Title) => void,
  setShowFormSelection: (show: boolean) => void
}

const SelectRecordSearchPartialSelect: FC<SelectRecordSearchPartialSelectProps> = ({
  resultSearch,
  recordSelected,
  publicationSearch,
  setShowFormSelection,
  onSubmit
}) => {
  const [publicationSelected, setPublicationSelected] = useState<string[]>([])

  /**
     * Filtre les publications possible
     * @param publication
     */
  const filterPublication = (publication: Publication): boolean => {
    return publication.idPublication && publicationSearch && publicationSearch.includes(publication.idPublication)
  }

  /**
   * Recupère l'id de Publication de la reproduction
   * @param reproduction
   */
  const getIdPublication = (reproduction: ReproductionLightDeposit): string => {
    return reproduction.publications?.find(pub => filterPublication(pub))?.idPublication || ''
  }

  /**
   * Vérifie si il y a au moins 1 reproduction a afficher
   * @param model
   */
  const hasAtLeastOneReproductionInSearchResult = (model: ModelDeposit): boolean => {
    for (const reproduction of model.reproductions) {
      if (reproduction.publications && reproduction.publications.filter(pub => filterPublication(pub)).length > 0) {
        return true
      }
    }

    return false
  }

  /**
   * Sélectionne ou désélectionne les publications d'une reproduction
   * @param updatePublication
   * @param reproduction
   * @param value
   */
  const selectOrNotReproduction = (updatePublication: string[], reproduction: ReproductionLightDeposit, value: boolean): string[] => {
    const idPublication = getIdPublication(reproduction)
    if (value) {
      if (!updatePublication.includes(idPublication)) {
        updatePublication.push(idPublication)
        updatePublication.push(idPublication.padStart(9, '0'))
      }
    } else {
      updatePublication = updatePublication.filter(id => id !== idPublication && id !== idPublication.padStart(9, '0'))
    }
    return updatePublication
  }

  /**
   * Sélectionne ou désélectionne les publications d'un modèle
   * @param updatePublication
   * @param model
   * @param value
   */
  const selectOrNotModel = (updatePublication: string[], model: ModelDeposit, value: boolean): string[] => {
    for (const reproduction of model.reproductions) {
      updatePublication = selectOrNotReproduction(updatePublication, reproduction, value)
    }
    return updatePublication
  }

  /**
   * Sélectionne toutes les publications
   */
  const selectAll = (): string[] => {
    let updatePublication : string[] = []
    if (recordSelected) {
      for (const model of recordSelected.models) {
        for (const reproduction of model.reproductions) {
          updatePublication = selectOrNotReproduction(updatePublication, reproduction, true)
        }
      }
    }
    return updatePublication
  }

  /**
   * Ajoute la/les publication(s) pour le titre
   *  - Toutes les publications si indexModel= null & indexReproduction = null
   *  - Toutes les publications d'un modèle si indexReproduction = null
   *  - La publication d'une reproduction sinon
   * @param indexModel
   * @param indexReproduction
   * @param value
   */
  const checkPublication = (indexModel: number|null, indexReproduction: number|null, value: boolean) => {
    let updatePublication = publicationSelected ? [...publicationSelected] : []

    if (recordSelected) {
      if (indexModel !== null && indexReproduction !== null) {
        // on sélectionne la reproduction si elle ne l'est pas
        // sinon on la désélectionne
        const reproduction = recordSelected.models[indexModel].reproductions[indexReproduction]
        updatePublication = selectOrNotReproduction(updatePublication, reproduction, value)
      } else if (indexModel !== null) {
        // on sélectionne toutes les reproductions qui ne le sont pas si au moins 1 ne l'est pas
        // sinon on les désélectionne toutes
        const model = recordSelected.models[indexModel]
        updatePublication = selectOrNotModel(updatePublication, model, value)
      } else {
        // on sélectionne toutes les reproductions
        updatePublication = selectAll()
      }
    }

    setPublicationSelected(updatePublication)
  }

  /**
   * Enregistre dans le store le titre et ferme la selection
   */
  const submitSelection = async () => {
    const publicationFromSearchAndSelected = resultSearch.publications?.filter(publicationSearch => publicationSelected.includes(publicationSearch.idPublication)) || []
    const modelsFromSearchAndSelected = RecordService.getAllSelectedModels(recordSelected, publicationFromSearchAndSelected)
    const protectionExtensions = await RecordService.fillProtectionExtensions(recordSelected)
    const typeDelay = await ProrogationService.getTypeDelay(modelsFromSearchAndSelected, recordSelected)
    const title: Title = {
      idRecord: recordSelected.id,
      numNat: recordSelected.numNat,
      status: recordSelected.state,
      origin: recordSelected.country,
      state: recordSelected.state,
      depositDate: DateUtils.formateDateToFormat(recordSelected.depositDate, DATE_ISO),
      publications: publicationFromSearchAndSelected,
      isScopePartial: publicationFromSearchAndSelected.length < (resultSearch.publications?.length || 0),
      models: modelsFromSearchAndSelected,
      typeDelay: typeDelay,
      isProrogationAlreadyAsk: resultSearch.isProrogationAlreadyAsk,
      protectionExtensions: protectionExtensions
    }

    // Récupérer toutes les publications et les mettre ensemble dans title
    const fieldStatusError = SelectRecordFormValidator.validateMandatory(title)
    if (!containsErrors({ ...fieldStatusError, publicationsWarning: '' })) {
      return onSubmit(title)
    }
  }

  return (
    <>
      <hr />
      <div className='text-gris text-uppercase mb-3'>
        <FormattedMessage id='records_prorogation_select_result' />
      </div>
      <SubmitButton
        className='btn-link-primary'
        onClick={() => checkPublication(null, null, true)}
      >
        <FormattedMessage id='records_prorogation_select_all' />
      </SubmitButton>
      {
        recordSelected && publicationSearch && recordSelected.models.map((model, index) => {
          return hasAtLeastOneReproductionInSearchResult(model)
            ? (
              <OneModelSearchSelection
                key={model.id}
                model={model}
                allPublications={publicationSearch}
                idRecord={recordSelected.id}
                indexModel={index}
                checkPublication={checkPublication}
                publicationSelected={publicationSelected}
              />
            ) : null
        })
      }
      <div className='d-flex justify-content-start ml-3 mt-3'>
        <SubmitButton
          className='btn-link-primary'
          onClick={() => setShowFormSelection(false)}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          <span className='ml-2'>
            <FormattedMessage id='records_prorogation_return_select' />
          </span>
        </SubmitButton>
      </div>
      <div className='row m-0 d-flex justify-content-end'>
        {
          onSubmit &&
            <SubmitButton
              onClick={submitSelection}
              className='btn-outline-primary m-2'
            >
              <FormattedMessage id='records_inscription_submit_form' />
            </SubmitButton>
        }
      </div>
    </>
  )
}

export default SelectRecordSearchPartialSelect
