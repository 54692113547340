import http from '../../network/http-common'
import { toast } from 'react-toastify'
import { createIntl } from 'react-intl'
import Message from '../../constants/Message'
import {
  DepositFile,
  Transaction,
  Publication, ReproductionLightDeposit, Title
} from '@inpi-dm/components'
import axios from 'axios'
import { Record } from '@inpi-dm/components/src/interfaces/RecordInterfaces'
import { RecordSearchParameters, RecordSearchResult } from '../../interfaces/RecordInterfaces'
import { REPRODUCTION_PUBLISHED_STATE } from '../../constants/DepositConstants'
import { RECORD_FROM_INSCRIPTION } from '../../constants/InscriptionConstants'
import ContentService from '../content/ContentService'

const RECORD_BASE_URL = '/api/records'

/**
 * Classe permettant de faire des opérations sur un dossier
 */
class RecordService {
  constructor () {
    this.intl = createIntl({ locale: 'fr', messages: Message })
    this.source = axios.CancelToken.source()
  }

  /**
   * Récupère un dossier à partir de son identifiant
   */
  getRecord = async (id: string): Promise<Record> => {
    try {
      return await http.get(`/api/records/${id}`)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Cherche des records
   * @param params
   */
  searchRecords = async (params: RecordSearchParameters): Promise<RecordSearchResult> => {
    try {
      return await http.get('/api/records', {
        params: params,
        cancelToken: this.source.token
      })
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Transforme un objet Record en Transaction
   */
  recordToDepositModel = (record: Record): Transaction => {
    const depositParentField = {
      id: record.id,
      status: record.status,
      createdAt: record.createdAt,
      numNat: record.numNat,
      internalReference: record.internalReference,
      holders: record.holders,
      validityEndDate: record.validityEndDate,
      publications: record.publications,
      depositDate: record.depositDate
    }

    return {
      ...depositParentField,
      file: {
        ...record
      }
    }
  }

  /**
   * Récupère la reproduction
   */
  getReproductionPreview = async (reproduction: ReproductionLightDeposit, idRecord?: string, idModel?: string) => {
    if (reproduction.url) {
      return reproduction.url
    } else if (idRecord && idModel && reproduction.thumbnail) {
      return this.getReproduction(idRecord, idModel, reproduction.thumbnail)
    }
  }

  /**
   * Récuperation de la reproduction
   * @param idRecord - identifiant du dossier
   * @param idModel - identifiant du model
   * @param file - fichier à récupérer
   */
  getReproduction = async (idRecord: string, idModel:string, file: DepositFile) : Promise<string> => {
    try {
      if (!file.fileSource) {
        file.fileSource = await http.get(`${RECORD_BASE_URL}/${idRecord}/models/${idModel}/reproductions/${file.internalName}`, {
          cancelToken: this.source.token
        })
      }
      return Promise.resolve(file.fileSource || '')
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Récupération de la dernière publication de chaque reproduction
   * @param record
   */
  getPublications = (record: Record): Publication[] => {
    const publications = []

    record.models.forEach((model) => {
      const reproductions = model.reproductions.filter((reproduction) => REPRODUCTION_PUBLISHED_STATE.includes(reproduction.state || ''))
      if (reproductions) {
        reproductions.forEach((repro) => {
          if (repro.publications) {
            // Récupération de la publication la plus récente de la reproduction
            publications.push(repro.publications.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())[0])
          }
        })
      }
    })

    return publications
  }

  /**
   * Récupère les modèles avec seulement les publications sélectionnées
   * @param record
   * @param publicationsSelected
   */
  getAllSelectedModels = (record: Record|Title, publicationsSelected: Publication[]) => {
    const selectedIdPublications = publicationsSelected.map(publication => publication.idPublication)
    return record.models ? record.models.map((model, indexModel) => {
      const newReproductions = model.reproductions
        .filter((reproduction) => reproduction.publications && (
          reproduction.publications?.filter((publication) =>
            selectedIdPublications && selectedIdPublications.includes(publication.idPublication)).length > 0))
      return {
        ...record.models[indexModel],
        reproductions: newReproductions.filter(newReproduction => newReproduction !== undefined)
      }
    }).filter(newModel => newModel.reproductions.length > 0) : []
  }

  /**
   * Renvoie les extensions de protection d'un titre
   * @param record
   */
  fillProtectionExtensions = async (record: Record) => {
    if (record.options && record.options.protectionExtensions) {
      return record.options.protectionExtensions.map(protectionExtension => {
        protectionExtension.checked = true
        return protectionExtension
      })
    } else if (record.from === RECORD_FROM_INSCRIPTION) {
      return await ContentService.getProtectionExtensions()
    }
    return []
  }

  /**
   * Annule une requête en attente
   */
  cancelRequest = () => {
    this.source.cancel()
    this.source = axios.CancelToken.source()
  }
}

export default new RecordService()
