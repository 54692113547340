import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import {
  CardBlock,
  CheckboxField,
  containsErrors,
  DEPOSIT_TYPE,
  EventType,
  FieldStatus,
  FileBrowserField,
  ManageableQualitySelectField,
  SelectField,
  SubmitButton,
  TextField,
  CPI,
  LIST_422_5,
  SOCIETE_CONTRACTUELLE,
  INSCRIPTION_TYPE,
  ModalComponent,
  ModalSelectTitle,
  TitleInfos,
  DepositIntervenant,
  FormatUtils,
  PROROGATION_TYPE,
  APPEAL_TYPE
} from '@inpi-dm/components'
import {
  ACCESS_DEMAND_ITEM_ADD_AGENT, ACCESS_DEMAND_ITEM_EDIT_AGENT,
  PROCEDURE_ITEMS
} from '../../constants/ETSAccountConstants'
import ETSAccountRequestValidator from './validator/ETSAccountRequestValidator'
import ETSAccountService from '../../services/transaction/ETSAccountService'
import { ETSAccountNavInterface, ETSAccountRequest } from '../../interfaces/ETSAccountInterface'
import ContentService from '../../services/content/ContentService'
import RecordService from '../../services/record/RecordService'
import TitleService from '../../services/transaction/TitleService'

export interface ETSAccountInterface extends WrappedComponentProps{
  ETSAccountRequestNavItem: ETSAccountNavInterface
}

const ETSAccount = (props: ETSAccountInterface) => {
  const [redirect, setRedirect] = useState<string|null>(null)
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>({})
  const [showSelectTitleModal, setShowSelectTitleModal] = useState(false)
  const [records, setRecords] = useState<TitleInfos[]>()
  const [holders, setHolders] = useState<DepositIntervenant[]>()
  const [etsAccountRequest, setEtsAccountRequest] = useState<ETSAccountRequest>(
    {
      procedure: DEPOSIT_TYPE.value,
      isRecipient: false
    }
  )

  const {
    ETSAccountRequestNavItem
  } = props

  /**
   * Validation du formulaire de rattachement
   */
  const handleSubmit = async () => {
    const fieldStatus = ETSAccountRequestValidator.validateForm(etsAccountRequest, ETSAccountRequestNavItem)
    if (containsErrors(fieldStatus)) {
      return setFieldStatus(fieldStatus)
    } else {
      setFieldStatus({})
      // Dans le cas où nous sommes sur une transaction de dépôt et une demande de changement de destinataire du titre
      // Il faut chercher si plusieurs titres existent avec ce numnat dans le référentiel (cas suite à une scission)
      if (etsAccountRequest.procedure === DEPOSIT_TYPE.value && etsAccountRequest.isRecipient && !etsAccountRequest.idRecord) {
        return TitleService.getTitleInfos({ numNat: etsAccountRequest.numNat }).then(result => {
          if (result && result.records.length > 0) {
            setRecords(result.records)
            setShowSelectTitleModal(true)
          } else {
            sendRequest()
          }
        })
      } else {
        return sendRequest()
      }
    }
  }

  /**
   * Sélection d'un titre dans la popin, si plusieurs titres ont le numnat souhaité
   * @param idRecord
   */
  const selectTitle = (idRecord: string) => {
    setShowSelectTitleModal(false)
    const request = { ...etsAccountRequest, idRecord }
    setEtsAccountRequest(request)
    setHolders(records?.find(r => r.id === idRecord)?.holders)
  }

  const sendRequest = () => {
    return ETSAccountService.postETSAccountRequestDeposit(etsAccountRequest, ETSAccountRequestNavItem).then(() => {
      toast.success(props.intl.formatMessage({ id: 'ets_account_request_success_request' }))
      setRedirect('/')
    })
  }

  /**
   * Enregistrement du fichier joint
   * @param event
   * @param field
   */
  const handleFile = (event: EventType, field: string) => {
    const file = event.target.value
    setEtsAccountRequest({
      ...etsAccountRequest,
      [field]: {
        name: file.name,
        file: file
      }
    })
  }

  /**
   * Récupération du label
   */
  const getNumnatFieldLabel = () => {
    switch (etsAccountRequest.procedure) {
      case INSCRIPTION_TYPE.value :
        return <FormattedMessage id='ets_account_request_procedure_inscription_numNat' />
      case PROROGATION_TYPE.value :
        return <FormattedMessage id='ets_account_request_procedure_prorogation_numNat' />
      case APPEAL_TYPE.value :
        return <FormattedMessage id='ets_account_request_procedure_appeal_numNat' />
      case DEPOSIT_TYPE.value :
      default :
        return <FormattedMessage id='ets_account_request_procedure_numNat' />
    }
  }

  return (
    <>
      {redirect &&
        <Redirect to={{
          pathname: redirect
        }}
        />}
      <div className='d-flex mt-4 justify-content-center'>
        <CardBlock
          className='col-6 p-0'
          header={ETSAccountRequestNavItem.label}
          shadow
        >
          <div className='is-validated row w-100'>
            <div className='col-12'>
              <SelectField
                inputId='procedure'
                label={<FormattedMessage id='ets_account_request_procedure_label' />}
                onChange={(event: EventType) => setEtsAccountRequest({ ...etsAccountRequest, procedure: event.target.value })}
                placeholder={props.intl.formatMessage({ id: 'ets_account_request_procedure_select' })}
                fieldStatus={fieldStatus}
                value={etsAccountRequest.procedure}
                options={PROCEDURE_ITEMS}
                required
              />
            </div>
            <div className='col-12'>
              <TextField
                inputId='numNat'
                label={getNumnatFieldLabel()}
                fieldStatus={fieldStatus}
                value={etsAccountRequest.numNat}
                onChange={(event: EventType) => setEtsAccountRequest({ ...etsAccountRequest, numNat: event.target.value })}
                required
              />
            </div>
            {
              ETSAccountRequestNavItem === ACCESS_DEMAND_ITEM_ADD_AGENT &&
                <div className='col-12'>
                  <ManageableQualitySelectField
                    inputId='manageableQuality'
                    fieldStatus={fieldStatus}
                    value={etsAccountRequest.manageableQuality}
                    onChange={(event: EventType) => setEtsAccountRequest({ ...etsAccountRequest, manageableQuality: event.target.value })}
                    label={<FormattedMessage id='ets_account_request_contributor_type_label' />}
                    placeholder={props.intl.formatMessage({ id: 'placeholder_select' })}
                    required
                    getManageableQualities={ContentService.getManageableQualities}
                  />
                </div>
            }
            {
              (ETSAccountRequestNavItem === ACCESS_DEMAND_ITEM_ADD_AGENT &&
                    (etsAccountRequest.manageableQuality === CPI || etsAccountRequest.manageableQuality === LIST_422_5)) &&
                      <div className='col-12'>
                        <TextField
                          inputId='inscriptionNumber'
                          label={<FormattedMessage id='ets_account_request_inscription_number' />}
                          fieldStatus={fieldStatus}
                          value={etsAccountRequest.inscriptionNumber}
                          onChange={(event: EventType) => setEtsAccountRequest({
                            ...etsAccountRequest,
                            inscriptionNumber: event.target.value
                          })}
                          required
                        />
                      </div>
            }
            {
              (ETSAccountRequestNavItem === ACCESS_DEMAND_ITEM_ADD_AGENT &&
                    (etsAccountRequest.manageableQuality === LIST_422_5 || etsAccountRequest.manageableQuality === SOCIETE_CONTRACTUELLE)) &&
                      <div className='col-12'>
                        <FileBrowserField
                          inputId='powerFile'
                          label={<FormattedMessage id='ets_account_request_power_file' />}
                          maxNumberFile={1}
                          butonLabel={
                            <div className='form-control'>
                              <div className='text-center cursor-pointer'>
                                <FormattedMessage id='button_find_file' />
                              </div>
                            </div>
                          }
                          acceptApplication='application/pdf'
                          value={(etsAccountRequest.powerFile && [etsAccountRequest.powerFile]) || []}
                          onChange={(event) => handleFile(event, 'powerFile')}
                          onDelete={() => setEtsAccountRequest({
                            ...etsAccountRequest,
                            powerFile: undefined
                          })}
                          fieldStatus={fieldStatus}
                          required
                        />
                      </div>
            }
            <div className='col-12'>
              <FileBrowserField
                inputId='proofFile'
                label={<FormattedMessage id='ets_account_request_proof_file' />}
                maxNumberFile={1}
                butonLabel={
                  <div className='form-control'>
                    <div className='text-center cursor-pointer'>
                      <FormattedMessage id='button_find_file' />
                    </div>
                  </div>
                }
                acceptApplication='application/pdf'
                value={(etsAccountRequest.proofFile && [etsAccountRequest.proofFile]) || []}
                onChange={(event) => handleFile(event, 'proofFile')}
                onDelete={() => setEtsAccountRequest({
                  ...etsAccountRequest,
                  proofFile: undefined
                })}
              />
            </div>
            {
              (ETSAccountRequestNavItem === ACCESS_DEMAND_ITEM_ADD_AGENT || ETSAccountRequestNavItem === ACCESS_DEMAND_ITEM_EDIT_AGENT) &&
                <div className='col-12'>
                  <CheckboxField
                    inputId='isRecipient'
                    className='mt-4'
                    checked={etsAccountRequest.isRecipient}
                    label={props.intl.formatMessage({ id: 'ets_account_request_is_recipient' })}
                    onChange={(event: EventType) => setEtsAccountRequest({
                      ...etsAccountRequest,
                      isRecipient: event.target.value
                    })}
                    fieldStatus={fieldStatus}
                  />
                  {holders &&
                    <div className='text-primary ml-4 mt-n2'>
                      <FormattedMessage id='ets_record_holders' /> {FormatUtils.formatContributors(holders)}
                    </div>}
                </div>
            }
            <div className='w-100 row'>
              <div className='col'>
                <div className='d-flex justify-content-end'>
                  <SubmitButton
                    children={<FormattedMessage id='button_confirm' />}
                    className='btn-primary'
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </CardBlock>
      </div>
      <ModalComponent
        size='xl'
        title={<FormattedMessage id='title_modal_select_title' />}
        show={showSelectTitleModal}
        customContent={() =>
          <ModalSelectTitle
            records={records}
            selectTitle={selectTitle}
            setShowModalSelectTitle={setShowSelectTitleModal}
            getCountries={ContentService.getCountries}
            getRecord={RecordService.getRecord}
          />}
        handleClose={() => setShowSelectTitleModal(false)}
        hideFooter
      />
    </>
  )
}

export default injectIntl(ETSAccount)
