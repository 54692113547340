import { containsErrors, DepositDocument, FieldStatus, isFilled, messageIsFilled, Title } from '@inpi-dm/components'
import Message from '../../../../../constants/Message'
import store from '../../../../../store/store'
import { storeInscriptionFieldStatusUpdate } from '../../../../../store/inscription/inscriptionActions'
import DocumentValidator from '../../documents/validator/DocumentValidator'

class DisclaimerFormValidator {
  /**
   * Valide le titre
   * @param title
   */
  validateTitleMandatory = (title : Title) : FieldStatus => {
    let publicationIsOk = false

    if (title.publications && title.publications.length > 0) {
      publicationIsOk = true
    }

    return {
      depositDate: messageIsFilled(title.depositDate),
      numNat: messageIsFilled(title.numNat),
      idPublication: publicationIsOk ? '' : Message.required_field,
      notFound: ''
    }
  }

  /**
   * Valide tous les titres
   */
  validateOnChangeStep = (inscriptionProofDocuments?: DepositDocument[][]|undefined) => {
    const inscription = store.getState().inscription.inscription
    const titles = inscription?.titles || []
    const natureCodes = inscription?.natureCodes || []

    if (inscriptionProofDocuments) {
      DocumentValidator.validateDocuments(inscriptionProofDocuments.flat(), false)
    }

    const allTitleFieldStatus = titles?.map((title) => this.validateTitleMandatory(title))

    const fieldStatus = {
      titles: allTitleFieldStatus,
      natureCodes: isFilled(natureCodes) ? '' : Message.required_field
    }

    const error = containsErrors(fieldStatus)

    store.dispatch(storeInscriptionFieldStatusUpdate(fieldStatus))

    return !error
  }
}

export default new DisclaimerFormValidator()
