import React, { FC } from 'react'
import { SirenDenomination } from '../../interfaces/OpenDataRNCSInterfaces'
import { RequestListTable } from '../../index'
import { TABLE_TITLE_SIREN_DENOMINATION } from '../../constants/OpenDataRNCSConstants'

interface ListSirenWithDenominationProps {
    listSirenDenomination: SirenDenomination[],
    onSelectCompany: (siren: string) => void
}

const ListSirenWithDenomination: FC<ListSirenWithDenominationProps> = (
  {
    listSirenDenomination,
    onSelectCompany
  }) =>
  (
    <div className='row'>
      <RequestListTable
        tableTitle={TABLE_TITLE_SIREN_DENOMINATION}
      >
        <tbody>
          {
            listSirenDenomination.map((sirenDenomination: SirenDenomination, index: number) => (
              <tr
                key={`-${index}`} className='cursor-pointer'
                onClick={() => onSelectCompany(sirenDenomination.siren)}
              >
                <td className='border-0 '>{sirenDenomination.siren}</td>
                <td className='border-0 '>{sirenDenomination.denominationSociale}</td>
              </tr>
            ))
          }
        </tbody>
      </RequestListTable>

    </div>
  )

export default ListSirenWithDenomination
