import { containsErrors, FieldStatus, messageIsFilled, Title } from '@inpi-dm/components'
import Message from '../../../../../constants/Message'
import store from '../../../../../store/store'
import { storeAppealFieldStatusUpdate } from '../../../../../store/appeal/appealActions'

class SelectRecordFormValidator {
  /**
   * Valide le titre
   * @param title
   */
  validateMandatory = (title : Title) : FieldStatus => {
    return {
      depositDate: messageIsFilled(title.depositDate),
      numNat: messageIsFilled(title.numNat),
      notFound: ''
    }
  }

  /**
   * Valide tous les titres
   */
  validateOnChangeStep = () => {
    const titles = store.getState().appeal.appeal?.titles || []

    let allFieldStatus
    if (titles.length === 0) {
      const fieldStatus = this.validateMandatory({})
      allFieldStatus = [{ ...fieldStatus, notFound: Message.warning_at_least_one_title }]
    } else {
      allFieldStatus = titles?.map((title) => this.validateMandatory(title))
    }

    const error = containsErrors(allFieldStatus)

    store.dispatch(storeAppealFieldStatusUpdate({
      titles: allFieldStatus
    }))

    return !error
  }
}

export default new SelectRecordFormValidator()
