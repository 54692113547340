import React, { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import Message from '../constants/Message'

interface NoResultProps {
    message?: string
}
const NoResult: FC<NoResultProps> = ({
  message
}) => (
  <IntlProvider locale='fr' messages={Message}>
    <div className='d-flex justify-content-center align-items-center text-gris'>
      <FontAwesomeIcon className='mr-2' icon={faTimesCircle} />
      {message
        ? <span>{message}</span>
        : <FormattedMessage id='request_no_result' />}
    </div>
  </IntlProvider>
)

export default NoResult
