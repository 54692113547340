import React, { FC, useEffect, useState } from 'react'
import {
  computeOrder,
  FilAriane,
  RequestListTable,
  ROWS,
  ROWS_25,
  TableOrderBy,
  CardBlock, NoResult
} from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'
import qs from 'qs'
import RequestListTableBody from '../requests/RequestListTableBody'
import { TABLE_TITLE } from '../../constants/RequestConstants'
import TransactionService from '../../services/transaction/TransactionService'
import { TransactionSearchResult } from '../../interfaces/TransactionInterfaces'

const SearchList: FC = () => {
  const search = qs.parse(useLocation().search, { ignoreQueryPrefix: true }).search
  const [resultsResponse, setResultsResponse] = useState<TransactionSearchResult|undefined>()
  const [pageSize, setPageSize] = useState(ROWS_25.value)
  const [page, setPage] = useState(0)
  const [maxPage, setMaxPage] = useState(1)
  const [sortBy, setSortBy] = useState<TableOrderBy|undefined>()

  /**
     * Effectue une nouvelle recherche
     */
  const updateSearch = () => {
    if (!search || search.length === 0) {
      setResultsResponse(undefined)
      setMaxPage(0)
    } else {
      const params = {
        pageSize,
        page,
        order: sortBy?.order,
        sortBy: sortBy?.field,
        search
      }

      TransactionService.searchTransaction(params)
        .then(result => {
          setMaxPage(result.maxPage)
          setPage(result.page)
          setPageSize(result.pageSize)
          setResultsResponse(result)
        })
    }
  }

  useEffect(() => {
    updateSearch()
    return () => {
      TransactionService.cancelRequest()
    }
  }, [pageSize, page, sortBy, search])

  /**
     * Tri sur une colonne du tableau
     * @param field nom de la colonne à trier
     */
  const handleChangeOrderBy = (field?: string) => {
    let updatedSort

    if (field) {
      updatedSort = computeOrder(field, sortBy)
      setPage(0)
    }

    setSortBy(updatedSort)
  }

  return (
    <div>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id='record_breadcrumb_list' /></span>
      </FilAriane>
      <CardBlock>
        {
          resultsResponse && resultsResponse.transactions.length > 0
            ? (
              <RequestListTable
                tableTitle={TABLE_TITLE}
                onSort={handleChangeOrderBy}
                sortBy={sortBy}
                totalPage={maxPage}
                actualPage={page + 1}
                onChangePagination={(page) => setPage(page - 1)}
                nbShown={ROWS}
                valueSelect={pageSize}
                onChangeSelect={(event) => {
                  setPage(0)
                  setPageSize(parseInt(event.target.value, 10))
                }}
              >
                <RequestListTableBody list={resultsResponse?.transactions || []} />
              </RequestListTable>
            )
            : <NoResult />
        }
      </CardBlock>
    </div>
  )
}

export default SearchList
