import {
  Deposit,
  ModelDeposit,
  NOT_COMPLIANT,
  ReproductionLightDeposit
} from '@inpi-dm/components'
import { REPRODUCTION_PUBLISHED_STATE } from '../../constants/DepositConstants'

class ReproductionService {
  /**
   * Retourne true si la reproduction peut faire l'objet d'une demande de retrait
   *
   * @param reproduction
   */
  isWithdrawable = (reproduction: ReproductionLightDeposit) => (
    !reproduction.state && reproduction.compliantMorality !== NOT_COMPLIANT
  )

  /**
   * Retourne true si le dépôt contient au moins une reproduction pouvant faire
   * l'objet d'une demande de retrait
   *
   * @param deposit
   */
  containsWithdrawableReproductions = (deposit?: Deposit) => {
    return !!deposit?.models?.find(
      model => !!model.reproductions?.find(this.isWithdrawable)
    )
  }

  /**
   * Retourne true si au moins une reproduction est sélectionnée dans la liste de
   * modèles
   *
   * @param models
   */
  hasSelectedReproductions = (models: ModelDeposit[]) => {
    return models.length > 0 &&
      models.find(
        model => model.reproductions.find(
          reproduction => reproduction.selected
        ))
  }

  /**
   * Retourne true si toutes les reproductions des modèles fournies
   * ont été séletcionnées
   *
   * @param models
   */
  hasAllReproductionsSelected = (models: ModelDeposit[]) => {
    return models.length > 0 &&
      !models.find(model =>
        model.reproductions.length > 0 &&
        model.reproductions.find(repro => !repro.selected)
      )
  }

  /**
   * Permet de filtrer sur les reproductions publiées
   * @param models
   */
  filterPublishedReproductions = (models: ModelDeposit[]|undefined): ModelDeposit[]|undefined => {
    if (models) {
      // Filtrage des reproductions publiées
      models.forEach((model) => {
        model.reproductions = model.reproductions.filter((reproduction) => REPRODUCTION_PUBLISHED_STATE.includes(reproduction.state || ''))
      })
      // Suppression des modèles qui n'ont aucunes reproductions
      const updatedModels: ModelDeposit[] = []
      models.forEach((model) => {
        if (model.reproductions.length !== 0) {
          updatedModels.push(model)
        }
      })
      return updatedModels
    }
    return undefined
  }
}

export default new ReproductionService()
