import React, { useState, useEffect, FC } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux'
import {
  ErrorField,
  containsErrors,
  SubmitButton,
  Transaction,
  Title,
  PROROGATION_TYPE,
  FieldStatus,
  Publication
} from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'
import {
  storeProrogationFieldStatusUpdate,
  storeProrogationUpdate
} from '../../../../store/prorogation/prorogationActions'
import { ProrogationSearchTitle, ProrogationFieldStatus } from '../../../../interfaces/ProrogationInterfaces'
import OverviewRecords from '../../../title/OverviewRecords'
import SelectRecordsFormSearch from './SelectRecordsFormSearch'
import SelectRecordSearchResult from './SelectRecordSearchResult'
import SelectRecordSearchPartialSelect from './SelectRecordSearchPartialSelect'
import { storeContributorsTitleUpdate } from '../../../../store/contributorsTitle/contributorsTitleActions'
import cloneDeep from 'lodash.clonedeep'
import SelectRecordsFormManual from './SelectRecordsFormManual'

interface SelectRecordsProps {
  validateMandatory : (title : Title) => FieldStatus
}

const SelectProrogationRecords : FC<SelectRecordsProps> = ({ validateMandatory }) => {
  const dispatch = useDispatch()
  const prorogation:Transaction = useSelector((state:RootStateOrAny) => state.prorogation)
  const prorogationFieldStatus:ProrogationFieldStatus = useSelector((state:RootStateOrAny) => state.prorogationFieldStatus)

  const contributorsByNumNat = useSelector((state: RootStateOrAny) => state.contributorsTitle)
  const [currentContributors, setCurrentContributors] = useState({})

  const [curentIndexTitle, setCurentIndexTitle] = useState(prorogation.prorogation?.titles?.length || 0)
  const [showForm, setShowForm] = useState(true)
  const [showFormManual, setShowFormManual] = useState(false)
  const [resultSearch, setResultSearch] = useState<ProrogationSearchTitle|undefined>(undefined)
  const [indexRecordSelected, setIndexRecordSelected] = useState()
  const [showFormSelection, setShowFormSelection] = useState(false)

  useEffect(() => {
    if (prorogation.prorogation && prorogation.prorogation.titles?.length > 0) {
      setShowForm(false)
    }
  }, [])

  useEffect(() => {
    if (prorogation.prorogation && prorogation.prorogation.titles.length > 0) {
      setCurentIndexTitle(-1)
      setShowForm(false)
      setShowFormManual(false)
    }
  }, [prorogation.prorogation?.titles])

  /**
   * Supprimer un Title du state et store
   * @param index
   */
  const deleteOneTitle = (index:number) => {
    const updateTitle = prorogation.prorogation?.titles ? [...prorogation.prorogation.titles] : []
    updateTitle.splice(index, 1)
    dispatch(storeProrogationUpdate({ prorogation: { ...prorogation.prorogation, titles: updateTitle } }))
    setCurentIndexTitle(-1)

    const updateTitleFieldStatus = prorogationFieldStatus.titles ? [...prorogationFieldStatus.titles] : []
    updateTitleFieldStatus.splice(index, 1)
    dispatch(storeProrogationFieldStatusUpdate({ titles: updateTitleFieldStatus }))
  }

  /**
   *
   * Mise a jours du state et store
   * @param title
   */
  const onSubmit = (title:Title) => {
    const updateTitle = prorogation.prorogation?.titles ? [...prorogation.prorogation.titles] : []
    updateTitle[curentIndexTitle] = title
    dispatch(storeProrogationUpdate({ prorogation: { ...prorogation.prorogation, titles: cloneDeep(updateTitle) } }))

    const updateTitleFieldStatus = prorogationFieldStatus.titles ? [...prorogationFieldStatus.titles] : []
    updateTitleFieldStatus[curentIndexTitle] = validateMandatory(title)
    dispatch(storeProrogationFieldStatusUpdate({ titles: updateTitleFieldStatus }))

    if (currentContributors && title?.numNat) {
      if (contributorsByNumNat?.numNat && contributorsByNumNat[title?.numNat]) {
        delete contributorsByNumNat[title?.numNat]
      }
      // On enregistre les intervenants dans le store pour pouvoir les proposer lors des formulaires des intervenants
      dispatch(storeContributorsTitleUpdate({
        ...contributorsByNumNat,
        [title.numNat]: currentContributors
      }))
    }

    setCurentIndexTitle(updateTitle.length)
    setShowForm(false)
    setShowFormManual(false)
    setShowFormSelection(false)
    setResultSearch(undefined)
    setIndexRecordSelected(undefined)
  }

  return (
    <>
      <div className='row'>
        <header className='col-8 mb-4'>
          <h1><FormattedMessage id='records_prorogation_title' /></h1>
          <span className='subtitle'><FormattedMessage id='records_prorogation_subtitle' /></span>
        </header>
        <InternalReferenceField
          value={prorogation.internalReference}
          procedureType={PROROGATION_TYPE.value}
          className='col-4 mb-4'
        />
      </div>
      {
        curentIndexTitle > 0 &&
          <OverviewRecords
            titles={prorogation.prorogation?.titles}
            changeTitle={(index: number) => {
              setShowFormManual(true)
              setShowForm(true)
              setCurentIndexTitle(index)
            }}
            deleteTitle={deleteOneTitle}
            isBeforeForm
            currentIndex={curentIndexTitle}
            fieldStatus={prorogationFieldStatus.titles}
            isScopeShown
            isOriginShown={false}
          />
      }
      {
        showForm && !showFormManual && resultSearch === undefined &&
          <SelectRecordsFormSearch
            setShowFormManual={setShowFormManual}
            setResultSearch={setResultSearch}
            setCurrentContributors={setCurrentContributors}
            setIndexRecordSelected={setIndexRecordSelected}
          />
      }
      {
        showForm && !showFormManual && resultSearch && !showFormSelection &&
          <SelectRecordSearchResult
            resultSearch={resultSearch}
            setCurrentContributors={setCurrentContributors}
            resetSearch={() => setResultSearch(undefined)}
            onSubmit={onSubmit}
            indexRecordSelected={indexRecordSelected}
            setIndexRecordSelected={setIndexRecordSelected}
            setShowFormSelection={setShowFormSelection}
          />
      }
      {
        showFormSelection && resultSearch && indexRecordSelected !== undefined && resultSearch.results.length > indexRecordSelected &&
          <SelectRecordSearchPartialSelect
            resultSearch={resultSearch}
            setShowFormSelection={setShowFormSelection}
            onSubmit={onSubmit}
            publicationSearch={resultSearch.publications?.map((publication: Publication) => publication.idPublication)}
            recordSelected={resultSearch.results[indexRecordSelected]}
          />
      }
      {
        showFormManual &&
          <SelectRecordsFormManual
            setShowFormManual={setShowFormManual}
            initialTitle={prorogation.prorogation && prorogation.prorogation.titles[curentIndexTitle]}
            initialFieldStatus={prorogationFieldStatus.titles && { ...prorogationFieldStatus.titles[curentIndexTitle] }}
            onSubmit={onSubmit}
            scopeRequired
            taxeRequired
            validateMandatory={validateMandatory}
          />
      }
      {
        prorogation.prorogation && prorogation.prorogation.titles?.length > curentIndexTitle + 1 &&
          <OverviewRecords
            titles={prorogation.prorogation?.titles}
            changeTitle={(index: number) => {
              setShowFormManual(true)
              setShowForm(true)
              setCurentIndexTitle(index)
            }}
            deleteTitle={deleteOneTitle}
            isBeforeForm={false}
            currentIndex={curentIndexTitle}
            fieldStatus={prorogationFieldStatus.titles}
            isScopeShown
            isOriginShown={false}
          />
      }
      {
        !showForm &&
          <div>
            <hr />
            <div className='d-flex align-items-center justify-content-between'>
              <div className='mr-4'>
                <SubmitButton
                  className='btn-outline-primary'
                  onClick={() => {
                    setShowForm(true)
                    setResultSearch(undefined)
                    setShowFormSelection(false)
                    setCurentIndexTitle(prorogation.prorogation?.titles.length || 0)
                  }}
                >
                  <FormattedMessage id='records_inscription_add' />
                </SubmitButton>
              </div>
            </div>
          </div>
      }
      {
        prorogationFieldStatus.titles && prorogationFieldStatus.titles.length > 0 && prorogationFieldStatus.titles[0] && containsErrors(prorogationFieldStatus.titles[0]) &&
          <ErrorField message={prorogationFieldStatus.titles[0].notFound} />
      }
    </>
  )
}

export default withRouter(SelectProrogationRecords)
