import store from '../../../../../store/store'
import { isFilled, Transaction, Record, INSCRIPTION_DISCLAIMER } from '@inpi-dm/components'
import Message from '../../../../../constants/Message'
import {
  storeInscriptionFieldStatusUpdate
} from '../../../../../store/inscription/inscriptionActions'

class SelectInscriptionTypeValidator {
  validateInscriptionType = (inscription: Transaction, idRecords?: Record[]) : string => {
    if (idRecords && idRecords.length > 1 && inscription.subProcedureType === INSCRIPTION_DISCLAIMER.code) {
      return Message.select_inscription_no_disclaimer
    }
    if (!isFilled(inscription.subProcedureType)) {
      return Message.select_inscription_type_required
    }
    return ''
  }

  validateOnChangeStep = (idRecords?: Record[]) => {
    const inscription = store.getState().inscription

    const fieldStatus = {
      subProcedureType: this.validateInscriptionType(inscription, idRecords)
    }

    store.dispatch(storeInscriptionFieldStatusUpdate(fieldStatus))

    return !fieldStatus.subProcedureType
  }
}

export default new SelectInscriptionTypeValidator()
