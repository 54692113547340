import store from '../../../../../store/store'
import { containsErrors, IntervenantValidator, DEFAULT_INTERVENANT } from '@inpi-dm/components'
import { storeInscriptionFieldStatusUpdate } from '../../../../../store/inscription/inscriptionActions'
import Message from '../../../../../constants/Message'

class ApplicantValidator {
  validateOnChangeStep = () => {
    const inscription = store.getState().inscription
    const applicants = inscription.applicants || []
    let hasNoError = true

    // Il doit y avoir au moins 1 demandeur
    let allFieldStatus
    if (applicants.length === 0) {
      const fieldStatus = IntervenantValidator.validateIntervenant(DEFAULT_INTERVENANT)
      allFieldStatus = [{ ...fieldStatus, notFound: Message.intervenant_warning_applicant_needed }]
      hasNoError = hasNoError && !containsErrors(fieldStatus)
    } else {
      allFieldStatus = applicants.map(applicant => {
        const fieldStatus = IntervenantValidator.validateIntervenant(applicant)
        hasNoError = hasNoError && !containsErrors(fieldStatus)
        return fieldStatus
      })
    }

    store.dispatch(storeInscriptionFieldStatusUpdate({
      applicants: allFieldStatus
    }))

    return hasNoError
  }
}

export default new ApplicantValidator()
