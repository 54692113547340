import store from '../../../../../store/store'
import { containsErrors, IntervenantValidator } from '@inpi-dm/components'
import { storeAppealFieldStatusUpdate } from '../../../../../store/appeal/appealActions'

class SignatoryValidator {
  validateOnChangeStep = () => {
    const appeal = store.getState().appeal

    const fieldStatus = IntervenantValidator.validateSignatory(appeal.signatory)
    store.dispatch(storeAppealFieldStatusUpdate({
      signatory: fieldStatus
    }))

    return !containsErrors(fieldStatus)
  }
}

export default new SignatoryValidator()
