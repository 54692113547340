import React, { useState, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const DragDrop = (props) => {
  const [items, setItems] = useState([...props.items])
  const { onChangeOrder } = props

  useEffect(() => {
    setItems(props.items)
  }, [props.items])

  const onDragEnd = (result) => {
    // si l'élément est lâché en dehors d'une div droppable
    if (!result.destination) {
      return
    }

    // si l'élément n'a pas bougé de place
    if (result.destination.index === result.source.index) {
      return
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    )

    setItems(newItems)
    // Propagation au parent des changements d'ordre sur les éléments
    onChangeOrder(newItems)
  }

  // Modifie l'ordre des éléments dans la liste
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className='mb-2'
                  >
                    {item.content}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DragDrop
