import React, { FC, useEffect, useState, useCallback } from 'react'
import {
  CardBlock,
  SubmitButton,
  INSCRIPTION_TYPE,
  PROROGATION_TYPE,
  APPEAL_TYPE,
  OFFICIAL_DOCUMENTS_TYPE
} from '@inpi-dm/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faExclamationCircle, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'
import { RouteComponentProps, useHistory, withRouter, Prompt } from 'react-router-dom'
import qs from 'qs'
import PaymentService from '../../services/transaction/PaymentService'
import DownloadLink from '../deposit/download/DownloadLink'
import { REQUEST_TYPES } from '../../constants/RequestConstants'
import {
  PAYMENT_URL_INSCRIPTION,
  PAYMENT_URL_DEPOSIT,
  PAYMENT_URL_DIVISION,
  PAYMENT_URL_NOTIFICATION,
  PAYMENT_URL_TRANSFORMATION_TN_TA,
  PAYMENT_URL_PROROGATION, PAYMENT_URL_APPEAL, PAYMENT_URL_OFFICIAL_DOCUMENT
} from '../../constants/PaymentConstants'
import Message from '../../constants/Message'
import TransactionService from '../../services/transaction/TransactionService'
import { PAYMENT_METHOD } from '../../constants/DepositConstants'

interface PaymentProps extends RouteComponentProps {
  state: string,
  id: string,
  matchUrl: string
}

const Payment:FC<PaymentProps> = (props) => {
  const history = useHistory()
  const [transaction, setTransaction] = useState()
  const [method, setMethod] = useState()
  const [isMultiTransactions, setIsMultiTransactions] = useState()

  const { state, id, matchUrl } = props

  const alertUser = (event) => {
    if (!transaction) {
      event.preventDefault()
      event.returnValue = ''
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [transaction])

  const infoState = {
    [PAYMENT_URL_DEPOSIT]: [
      {
        state: 'confirmation',
        className: 'text-success',
        icon: faCheckCircle,
        idText: method === PAYMENT_METHOD.MANAGEABLE_MEMORY ? 'payment_success_VIR' : 'payment_success',
        text: method === PAYMENT_METHOD.MANAGEABLE_MEMORY ? 'payment_text_success_VIR' : 'payment_text_success',
        showDownloadLink: true,
        idGouv: '861'
      },
      {
        state: 'annulation',
        className: 'text-warning',
        icon: faExclamationCircle,
        idText: 'payment_annulation',
        text: 'payment_text_annulation',
        showDownloadLink: false
      },
      {
        state: 'erreur',
        className: 'text-danger',
        icon: faTimesCircle,
        idText: 'payment_error',
        text: 'payment_text_error',
        showDownloadLink: false
      }
    ],
    [PAYMENT_URL_INSCRIPTION]: [
      {
        state: 'confirmation',
        className: 'text-success',
        icon: faCheckCircle,
        idText: 'payment_inscription_success',
        text: 'payment_text_inscription_success',
        showDownloadLink: true,
        idGouv: '863'
      },
      {
        state: 'annulation',
        className: 'text-warning',
        icon: faExclamationCircle,
        idText: 'payment_annulation',
        text: 'payment_text_annulation',
        showDownloadLink: false
      },
      {
        state: 'erreur',
        className: 'text-danger',
        icon: faTimesCircle,
        idText: 'payment_error',
        text: 'payment_text_error',
        showDownloadLink: false
      }
    ],
    [PAYMENT_URL_PROROGATION]: [
      {
        state: 'confirmation',
        className: 'text-success',
        icon: faCheckCircle,
        idText: 'payment_prorogation_success',
        text: 'payment_text_prorogation_success',
        showDownloadLink: !isMultiTransactions,
        idGouv: '869'
      },
      {
        state: 'annulation',
        className: 'text-warning',
        icon: faExclamationCircle,
        idText: 'payment_annulation',
        text: 'payment_text_annulation',
        showDownloadLink: false
      },
      {
        state: 'erreur',
        className: 'text-danger',
        icon: faTimesCircle,
        idText: 'payment_error',
        text: 'payment_text_error',
        showDownloadLink: false
      }
    ],
    [PAYMENT_URL_APPEAL]: [
      {
        state: 'confirmation',
        className: 'text-success',
        icon: faCheckCircle,
        idText: 'payment_appeal_success',
        text: 'payment_text_appeal_success',
        showDownloadLink: !isMultiTransactions
      },
      {
        state: 'annulation',
        className: 'text-warning',
        icon: faExclamationCircle,
        idText: 'payment_annulation',
        text: 'payment_text_annulation',
        showDownloadLink: false
      },
      {
        state: 'erreur',
        className: 'text-danger',
        icon: faTimesCircle,
        idText: 'payment_error',
        text: 'payment_text_error',
        showDownloadLink: false
      }
    ],
    [PAYMENT_URL_DIVISION]: [
      {
        state: 'confirmation',
        className: 'text-success',
        icon: faCheckCircle,
        idText: 'payment_division_success',
        text: 'payment_division_text_success',
        showDownloadLink: false
      },
      {
        state: 'annulation',
        className: 'text-warning',
        icon: faExclamationCircle,
        idText: 'payment_division_annulation',
        text: 'payment_division_text_annulation',
        showDownloadLink: false
      },
      {
        state: 'erreur',
        className: 'text-danger',
        icon: faTimesCircle,
        idText: 'payment_division_error',
        text: 'payment_division_text_error',
        showDownloadLink: false
      }
    ],
    [PAYMENT_URL_NOTIFICATION]: [
      {
        state: 'confirmation',
        className: 'text-success',
        icon: faCheckCircle,
        idText: 'payment_notification_success',
        text: 'payment_notification_text_success',
        showDownloadLink: false
      },
      {
        state: 'annulation',
        className: 'text-warning',
        icon: faExclamationCircle,
        idText: 'payment_notification_annulation',
        text: 'payment_notification_text_annulation',
        showDownloadLink: false
      },
      {
        state: 'erreur',
        className: 'text-danger',
        icon: faTimesCircle,
        idText: 'payment_notification_error',
        text: 'payment_notification_text_error',
        showDownloadLink: false
      }
    ],
    [PAYMENT_URL_TRANSFORMATION_TN_TA]: [
      {
        state: 'confirmation',
        className: 'text-success',
        icon: faCheckCircle,
        idText: 'payment_transformation_tn_ta_success',
        text: 'payment_transformation_tn_ta_text_success',
        showDownloadLink: false,
        goToTransformationTa: true
      },
      {
        state: 'annulation',
        className: 'text-warning',
        icon: faExclamationCircle,
        idText: 'payment_transformation_tn_ta_annulation',
        text: 'payment_transformation_tn_ta_annulation',
        showDownloadLink: false
      },
      {
        state: 'erreur',
        className: 'text-danger',
        icon: faTimesCircle,
        idText: 'payment_transformation_tn_ta_error',
        text: 'payment_transformation_tn_ta_text_error',
        showDownloadLink: false
      }
    ],
    [PAYMENT_URL_OFFICIAL_DOCUMENT]: [
      {
        state: 'confirmation',
        className: 'text-success',
        icon: faCheckCircle,
        idText: 'payment_official_document_success',
        text: 'payment_text_official_document_success',
        showDownloadLink: true
      },
      {
        state: 'annulation',
        className: 'text-warning',
        icon: faExclamationCircle,
        idText: 'payment_annulation',
        text: 'payment_text_annulation',
        showDownloadLink: false
      },
      {
        state: 'erreur',
        className: 'text-danger',
        icon: faTimesCircle,
        idText: 'payment_error',
        text: 'payment_text_error',
        showDownloadLink: false
      }
    ]
  }

  const actual = infoState[matchUrl].find(element => element.state === state)

  const { className, icon, idText, text, showDownloadLink, idGouv, goToTransformationTa = false } = actual

  const goToTransaction = () => {
    const typeRequest = REQUEST_TYPES.find(type => type.statusFiltres.includes(transaction.status))
    if (typeRequest) {
      if (transaction.procedureType === INSCRIPTION_TYPE.value) {
        if (goToTransformationTa) {
          return TransactionService.getTransaction(id).then(transaction => {
            return TransactionService.getTransaction(transaction.inscription.transformationTa?.idTransaction).then(transactionTA => {
              const newTypeRequest = REQUEST_TYPES.find(type => type.statusFiltres.includes(transactionTA.status))
              history.push(`/liste-demandes/${newTypeRequest.urlParam}/inscription/${transactionTA.id}`)
            })
          })
        } else {
          history.push(`/liste-demandes/${typeRequest.urlParam}/inscription/${id}`)
        }
      } else if (transaction.procedureType === PROROGATION_TYPE.value) {
        if (!isMultiTransactions) {
          history.push(`/liste-demandes/${typeRequest.urlParam}/prorogation/${id}`)
        } else {
          history.push('/liste-demandes/en-cours-examen')
        }
      } else if (transaction.procedureType === APPEAL_TYPE.value) {
        if (!isMultiTransactions) {
          history.push(`/liste-demandes/${typeRequest.urlParam}/releves-de-decheance/${id}`)
        } else {
          history.push('/liste-demandes/en-cours-examen')
        }
      } else if (transaction.procedureType === OFFICIAL_DOCUMENTS_TYPE.value) {
        history.push(`/liste-demandes/${typeRequest.urlParam}/documents-officiels/${id}`)
      } else {
        history.push(`/liste-demandes/${typeRequest.urlParam}/demande/${id}`)
      }
    }
  }

  const checkPayment = useCallback(async () => {
    // Récupération de l'id du paiement dans l'url
    const query = qs.parse(props.location.search, {
      ignoreQueryPrefix: true
    })
    const idPayment = query.id
    setMethod(query.method)

    // Dans le cas d'une prorogation, il se peut qu'un transaction donne lieu à plusieurs transactions
    const isMulti = query.isMulti
    setIsMultiTransactions(isMulti !== undefined)

    // Mise à jour statut du paiement et récupération du nouveau statut du dépôt
    if (state === 'annulation') {
      await PaymentService.cancelPayment(id, idPayment)
    } else {
      // Vérification du paiement auprès de Paybox si c'est un paiement par carte
      if (idPayment) {
        await PaymentService.checkPayment(id, idPayment)
      }
    }

    // Récupération du statut du dépôt
    TransactionService.getTransaction(id)
      .then(transaction => setTransaction(transaction))
  }, [id, props.location.search, state])

  useEffect(() => {
    checkPayment()
  }, [state, checkPayment])

  return (
    <div className='d-flex justify-content-center mt-5 mb-5'>
      <Prompt when={!transaction} message={Message.payment_prompt} />
      {transaction ? (
        <CardBlock flexBody={false} className='payment-div'>
          <div className={`${className} text-center mb-3`}>
            <FontAwesomeIcon icon={icon} size='5x' />
          </div>
          <h1 className='mb-5 text-center'><FormattedMessage id={idText} /></h1>
          <div className='text-center'><FormattedMessage id={text} /></div>
          <div className={`d-flex ${showDownloadLink ? 'justify-content-between' : 'justify-content-center'} align-items-center p-4`}>
            {showDownloadLink &&
              <DownloadLink
                id={id}
                name={`${Message.overview_deposit_pdf_title}.pdf`}
                internalName={`${Message.overview_deposit_pdf_title}.pdf`}
              />}
            {idGouv &&
              <a href={`https://voxusagers.numerique.gouv.fr/Demarches/${idGouv}?&view-mode=formulaire-avis&nd_mode=en-ligne-enti%C3%A8rement&nd_source=button&key=8ea4b71aed19487ec7c5a74979594fbf`}>
                <img src='https://voxusagers.numerique.gouv.fr/static/bouton-blanc.svg' alt='Je donne mon avis' />
              </a>}
            <div>
              <SubmitButton
                className='btn-link-primary '
                onClick={goToTransaction}
              >
                {isMultiTransactions
                  ? <FormattedMessage id='payment_redirection_multi' />
                  : <FormattedMessage id='payment_redirection' />}
              </SubmitButton>
              {transaction.inscription && transaction.inscription.inscriptionSuite &&
                <div>
                  <SubmitButton
                    className='btn-link-primary '
                    onClick={() => history.push(`/inscriptions/nouveau?suite=${transaction.id}`)}
                  >
                    <FormattedMessage id='payment_new_inscription' />
                  </SubmitButton>
                </div>}
            </div>
          </div>
        </CardBlock>
      ) : (
        <CardBlock flexBody={false} className='payment-div'>
          <div className='d-flex justify-content-center mt-3 mb-3'>
            <FontAwesomeIcon className='loader' icon={faSpinner} />
          </div>
          <div className='waiting-msg'>
            <FormattedMessage id='payment_waiting' />
          </div>
        </CardBlock>
      )}
    </div>
  )
}

export default withRouter(Payment)
