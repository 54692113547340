export const DOCUMENT_TYPES = {
  DOCUMENT_TYPE_REGULARIZE_REPRO: 'Reproduction à régulariser',
  DOCUMENT_TYPE_REGULARIZE_REPRO_DELETE: 'Reproduction supprimée',
  DOCUMENT_TYPE_REPRO: 'Reproduction',
  DEPOSIT_CORRECTION_SUPPORT: 'Pièce justificative',
  DEPOSIT_AGENT_DOCUMENT: 'Document de pouvoir',
  DOCUMENTS_PRIORITES: 'Priorité autre',
  DOCUMENT_TYPE_RECAP: 'Récapitulatif(s)',
  DOCUMENT_TYPE_COVER_PAGE: 'Page de garde'
}
