import React, { useState, useEffect, FC } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux'
import {
  ErrorField,
  containsErrors,
  INSCRIPTION_TYPE,
  Title,
  Transaction,
  NatureCode,
  MultipleRadioField,
  CardBlock, SwitchField, DepositDocument, TITLE_INSCRIPTION_ORIGIN_FR,
  DISCLAIMER_PARTIAL_TYPE, DISCLAIMER_TOTAL_TYPE
} from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'
import { InscriptionFieldStatus } from '../../../../interfaces/InscriptionInterfaces'
import {
  storeInscriptionFieldStatusUpdate, storeInscriptionUpdate
} from '../../../../store/inscription/inscriptionActions'
import Message from '../../../../constants/Message'
import NatureCodeService from '../../../../services/transaction/NatureCodeService'
import InscriptionDocumentsBlock from '../documents/InscriptionDocumentsBlock'
import SelectInscriptionRecordsForm from '../records/SelectInscriptionRecordsForm'

interface DisclaimerProps {
  inscriptionProofDocuments: DepositDocument[][],
  setInscriptionProofDocuments: (inscriptionProofDocuments: DepositDocument[][]) => void
}

const emptyTitle: Title = {
  origin: TITLE_INSCRIPTION_ORIGIN_FR,
  depositDate: '',
  numNat: '',
  publications: []
}

const Disclaimer: FC<DisclaimerProps> = ({ inscriptionProofDocuments, setInscriptionProofDocuments }) => {
  const dispatch = useDispatch()
  const inscription: Transaction = useSelector((state: RootStateOrAny) => state.inscription)
  const inscriptionFieldStatus: InscriptionFieldStatus = useSelector((state: RootStateOrAny) => state.inscriptionFieldStatus)

  const [showWithdrawMessage, setShowWithdrawMessage] = useState<boolean>(true)
  const [natureCodes, setNatureCodes] = useState<NatureCode[]>([])
  const [disclaimerCode, setDisclaimerCode] = useState<NatureCode | undefined>()
  const [title, setTitle] = useState<Title | undefined>((inscription.inscription?.titles && inscription.inscription.titles[0]) || emptyTitle)
  const [fieldStatus, setFieldStatus] = useState<InscriptionFieldStatus>(inscriptionFieldStatus)
  useEffect(() => {
    NatureCodeService.getNatureCodes({ type: inscription.subProcedureType }).then((results: NatureCode[]) => {
      setNatureCodes(results)
      // Si il y a un code nature on le set sinon on prend le premier de la liste
      if (inscription.inscription?.natureCodes && inscription.inscription?.natureCodes.length > 0) {
        setDisclaimerCode(inscription.inscription.natureCodes[0])
      }
    })
  }, [])

  useEffect(() => {
    inscriptionFieldStatus && setFieldStatus(inscriptionFieldStatus)
  }, [inscriptionFieldStatus])

  useEffect(() => {
    dispatch(storeInscriptionUpdate(
      {
        ...inscription,
        inscription: {
          ...inscription.inscription,
          titles: [title],
          natureCodes: disclaimerCode ? [disclaimerCode] : []
        }
      }
    ))
  }, [title, disclaimerCode])

  /**
   * Enregistrement dans le store des fieldStatus lié aux publications
   * @param newFieldStatusPublications
   * @param indexTitle
   */
  const onValidFieldStatus = (newFieldStatusPublications: string[], indexTitle: number) => {
    const updateTitles = inscriptionFieldStatus.titles ? [...inscriptionFieldStatus.titles] : []
    updateTitles[indexTitle] = { ...updateTitles[indexTitle], publications: newFieldStatusPublications }
    // si il y a un warning mettre le message global
    if (newFieldStatusPublications.includes('warning')) {
      updateTitles[indexTitle] = { ...updateTitles[indexTitle], publicationsWarning: Message.records_inscription_warning_existence }
    }
    dispatch(storeInscriptionFieldStatusUpdate({
      titles: updateTitles
    }))
  }

  return (
    <>
      <div className='row'>
        <header className='col-8 mb-4'>
          <h1><FormattedMessage id='records_inscription_title' /></h1>
          <span className='subtitle'><FormattedMessage id='records_inscription_subtitle' /></span>
        </header>
        <InternalReferenceField
          value={inscription.internalReference}
          procedureType={INSCRIPTION_TYPE.value}
          className='col-4 mb-4'
        />
      </div>
      <div>
        <CardBlock
          shadow
          header={<FormattedMessage id='disclaimer_inscription' />}
        >
          <div className='row'>
            <MultipleRadioField
              inputId='natureCodes'
              classNameError='position-relative'
              value={disclaimerCode?.code}
              radio={natureCodes
                .sort((a) => a.code === DISCLAIMER_TOTAL_TYPE.code ? -1 : 1)
                .map(allDisclaimerCode => ({
                  value: allDisclaimerCode.code,
                  label: allDisclaimerCode.label
                }))}
              onChange={(event) => setDisclaimerCode(natureCodes.find(natureCode => natureCode.code === event.target.value))}
              fieldStatus={fieldStatus}
              classNameRadioGroup='d-flex flex-column col-5'
              className='mb-2'
            />
            <div className='col-7'>
              <SwitchField
                onChange={(checked) => setShowWithdrawMessage(checked)}
                inputId='switch-withdraw-message'
                value={showWithdrawMessage}
                label={<FormattedMessage id='records_inscription_disclaimer_switch_label' />}
              />
              <span className={`text-danger ${showWithdrawMessage ? 'invisible' : ''}`}>
                <FormattedMessage id='records_inscription_disclaimer_transaction_not_published' />
              </span>
            </div>
          </div>
          {disclaimerCode && disclaimerCode.code === DISCLAIMER_PARTIAL_TYPE.code &&
            <span className='subtitle'><FormattedMessage id='disclaimer_inform_publication_ids_label' /></span>}
        </CardBlock>
      </div>
      <div className='mt-3'>
        <CardBlock
          shadow
          header={<FormattedMessage id='records_inscription_disclaimer_title' />}
          bodyClassName='pt-0'
        >
          <SelectInscriptionRecordsForm
            initialTitle={title}
            setDisclaimerTitle={setTitle}
            initialFieldStatus={fieldStatus && fieldStatus.titles && { ...fieldStatus.titles[0] }}
            onValidFieldStatus={(fieldStatusPub) => onValidFieldStatus(fieldStatusPub, 0)}
            isDisclaimer
          />
        </CardBlock>

      </div>
      <div className='mt-3'>
        <InscriptionDocumentsBlock
          transaction={inscription}
          inscriptionProofDocuments={inscriptionProofDocuments}
          setInscriptionProofDocuments={setInscriptionProofDocuments}
        />
      </div>
      {
        fieldStatus && fieldStatus.titles && fieldStatus.titles.length > 0 && fieldStatus.titles[0] && containsErrors(fieldStatus.titles[0]) &&
          <ErrorField message={fieldStatus.titles[0].notFound} />
      }
    </>
  )
}

export default withRouter(Disclaimer)
