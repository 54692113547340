import {
  FieldStatus,
  messageIsFilled,
  ModelDeposit,
  ReproductionLightDeposit
} from '@inpi-dm/components'

class ReproductionCompletionValidator {
    /**
     * Valide qu'une reproduction contient un libellé et une couleur
     *
     * @param reproduction
     */
    validate = (reproduction: ReproductionLightDeposit): FieldStatus => ({
      label: messageIsFilled(reproduction.label),
      color: messageIsFilled(reproduction.color)
    })

    /**
     * Valide pour toutes les reproductions sélectionnées que leurs libellés et
     * leurs couleurs sont tous remplis
     *
     * @param models
     */
    validateAll = (models: ModelDeposit[]): FieldStatus => {
      const fieldStatus = {}

      models.forEach((model, indexModel) => {
        model.reproductions.forEach((reproduction, indexRepro) => {
          if (reproduction.selected) {
            fieldStatus[`${indexModel}.${indexRepro}`] = this.validate(reproduction)
          }
        })
      })

      return fieldStatus
    }
}

export default new ReproductionCompletionValidator()
