import React, { FC, useState } from 'react'
import { EventType, FieldStatus, TextArea, TextField } from '@inpi-dm/components'
import { FormattedMessage, useIntl } from 'react-intl'
import { DepositIntervenant, ModalComponent } from '../..'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import { SirenDenomination } from '../../interfaces/OpenDataRNCSInterfaces'
import ListSirenWithDenomination from './ListSirenWithDenomination'

interface PersonneMoraleFieldsProps {
    intervenant?: DepositIntervenant,
    onChange: (event: EventType|EventType[]) => void,
    fieldStatus?: FieldStatus,
    showSiren: boolean,
    fillContributorInfo?: (siren: string) => void,
    findListSirenByNamePromise?: (companyName: string) => Promise<SirenDenomination[]|null>,
    isRecipient?: boolean | null,
}

const PersonneMoraleFields: FC<PersonneMoraleFieldsProps> = ({
  intervenant = {},
  onChange,
  fieldStatus,
  showSiren,
  fillContributorInfo,
  findListSirenByNamePromise,
  isRecipient
}) => {
  const [listDenominationModal, setListDenominationModal] = useState(false)
  const [listSirenDenomination, setListSirenDenomination] = useState<SirenDenomination[]>([])
  const intl = useIntl()

  const openListDenominationModal = () => {
    if (findListSirenByNamePromise && intervenant.companyName && intervenant.companyName.length > 0) {
      findListSirenByNamePromise(intervenant.companyName).then((result: SirenDenomination[]|null) => {
        if (result) {
          setListSirenDenomination(result)
          setListDenominationModal(true)
        } else {
          toast.error(intl.formatMessage({ id: 'intervenant_error_api_error' }))
        }
      })
    } else {
      toast.error(intl.formatMessage({ id: 'intervenant_error_denomination_not_empty' }))
    }
  }

  const onSelectCompany = (siren: string) => {
    fillContributorInfo && fillContributorInfo(siren)
    setListDenominationModal(false)
  }

  return (
    <>
      <div className='row'>
        <div className={`col-12 col-md-4 ${showSiren ? 'd-flex justify-content-between' : ''}`}>
          <TextField
            inputId='companyName'
            classNameFormGroup={`${showSiren ? 'col-11 pl-0' : ''}`}
            label={intl.formatMessage({ id: 'field_raison_sociale_label' })}
            value={intervenant.companyName || ''}
            onChange={onChange}
            fieldStatus={fieldStatus}
            maxLength='120'
            required
          />
          {(showSiren && fillContributorInfo && findListSirenByNamePromise) &&
            <button
              className='btn btn-outline-primary min-width-auto col-1 px-0 py-1 mb-3' type='button'
              onClick={() => openListDenominationModal()}
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>}
        </div>
        <div className='col-12 col-md-4'>
          <TextField
            inputId='legalForm'
            label={intl.formatMessage({ id: 'field_forme_juridique_label' })}
            value={intervenant.legalForm || ''}
            onChange={onChange}
            fieldStatus={fieldStatus}
            maxLength='100'
            required={!(isRecipient || intervenant?.idFromBeneficiary)}
          />
        </div>
        {
          showSiren &&
            <div className='col-12 col-md-4 d-flex justify-content-between'>
              <TextField
                inputId='siren'
                classNameFormGroup='col-11 pl-0'
                label={intl.formatMessage({ id: 'field_numero_siren_label' })}
                value={intervenant.siren || ''}
                onChange={onChange}
                fieldStatus={fieldStatus}
                maxLength='20'
              />
              {fillContributorInfo &&
                <button
                  className='btn btn-outline-primary min-width-auto col-1 px-0 py-1 mb-3' type='button'
                  onClick={() => fillContributorInfo && fillContributorInfo(intervenant?.siren || '')}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </button>}
            </div>
        }
        <ModalComponent
          title={<FormattedMessage id='intervenant_select_company' />}
          handleClose={() => setListDenominationModal(false)}
          show={listDenominationModal}
          hideFooter
          customContent={() => <ListSirenWithDenomination listSirenDenomination={listSirenDenomination} onSelectCompany={onSelectCompany} />}
          dialogClassName='modal-companies-list'
        />
      </div>
      {
        (intervenant.fullCompanyName || (intervenant?.companyName && intervenant.companyName.length > 119)) &&
          <div className='row'>
            <div className='col-12'>
              <div className='text-warning'>
                <FormattedMessage id='field_raison_sociale_label_long_text_needed' />
              </div>
              <TextArea
                inputId='fullCompanyName'
                value={intervenant.fullCompanyName || ''}
                onChange={onChange}
                fieldStatus={fieldStatus}
              />
            </div>
          </div>
      }
    </>
  )
}

export default PersonneMoraleFields
