import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { CardBlock, SubmitButton, Dropdown } from '@inpi-dm/components'
import Dashboard from './dashboard/Dashboard'
import { FormattedMessage } from 'react-intl'
import { ACCESS_DEMAND_ITEMS, ACCESS_DEMAND_ITEM_ATTACH_PM } from '../../constants/ETSAccountConstants'
import { RootStateOrAny, useSelector } from 'react-redux'

const Home = (props) => {
  const user = useSelector((state : RootStateOrAny) => state.user.user)
  const [etsOptions, setEtsOptions] = useState(ACCESS_DEMAND_ITEMS)

  const createDeposit = () => {
    props.history.push('/depots/nouveau')
  }

  const createInscription = () => {
    props.history.push('/inscriptions/nouveau')
  }

  const createDisclaimer = () => {
    props.history.push('/inscriptions/renonciation')
  }

  const createProrogation = () => {
    props.history.push('/prorogations/nouveau')
  }

  const createAppeal = () => {
    props.history.push('/releves-de-decheance/nouveau')
  }

  const createOfficialDocument = () => {
    props.history.push('/documents-officiels/nouveau')
  }

  useEffect(() => {
    if (user.isManager) {
      setEtsOptions(e => [...e, ACCESS_DEMAND_ITEM_ATTACH_PM])
    }
  }, [user.isManager])

  return (
    <div className='home mt-4'>
      <CardBlock>
        <header className='row mb-3'>
          <div className='d-flex justify-content-between w-100'>
            <div className='w-40'>
              <h1>
                <FormattedMessage id='home_title' />
              </h1>
              <span className='subtitle'><FormattedMessage id='home_description' /></span>
            </div>
            <div className='w-60 text-right'>
              <SubmitButton className='btn btn-primary mr-3 mb-2 procedure-btn' onClick={createDeposit}>
                <FormattedMessage id='home_button_depot' />
              </SubmitButton>
              <SubmitButton className='btn btn-primary mr-3 mb-2 procedure-btn' onClick={createProrogation}>
                <FormattedMessage id='home_button_prorogation' />
              </SubmitButton>
              <SubmitButton className='btn btn-primary mr-3 mb-2 procedure-btn' onClick={createInscription}>
                <FormattedMessage id='home_button_inscription' />
              </SubmitButton>
              <SubmitButton className='btn btn-primary mr-3 mb-2 procedure-btn' onClick={createDisclaimer}>
                <FormattedMessage id='home_button_disclaimer' />
              </SubmitButton>
              <SubmitButton className='btn btn-primary procedure-btn mr-3 mb-2' onClick={createAppeal}>
                <FormattedMessage id='home_button_appeal' />
              </SubmitButton>
            </div>
          </div>
          <div className='d-flex justify-content-end w-100'>
            <SubmitButton className='btn btn-link-primary mr-3' onClick={createOfficialDocument}>
              <FormattedMessage id='home_button_official_documents' />
            </SubmitButton>
            <Dropdown
              className='nav-primary'
              items={etsOptions}
              name={<FormattedMessage id='ets_account_request_ask_for_access' />}
              buttonClassName='text-right text-primary'
              onSelect={(demand) => props.history.push(demand.url)}
              arrow
            />
          </div>
        </header>
        <Dashboard />
      </CardBlock>
    </div>
  )
}

export default withRouter(Home)
