import React, { FC, useState, useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  CardBlock,
  Transaction,
  OverviewComparedIntervenant,
  Country,
  ManageableQuality,
  STATUS_APPEAL,
  NOTIF_STATUS_VISIBLE_TO_FO, SENDING_PORTAL
} from '@inpi-dm/components'
import DepositService from '../../../../services/transaction/DepositService'
import ContentService from '../../../../services/content/ContentService'
import OverviewRecords from '../../../title/OverviewRecords'
import OverviewAppealDetails from './OverviewAppealDetails'
import DocumentBlock from '../../../requests/DocumentBlock'
import OverviewNotifications from '../../../deposit/form/overview/notifications/OverviewNotifications'

interface OverviewAppealProps {
  transaction: Transaction,
  isRequestView?: boolean | null,
  setReload?: () => void,
}

const OverviewAppeal: FC<OverviewAppealProps> = ({
  transaction,
  isRequestView = false,
  setReload
}) => {
  const [countries, setCountries] = useState<Country[]>()
  const [manageableQualities, setManageableQualities] = useState<ManageableQuality[]>()
  const notificationReference = useRef(null)

  useEffect(() => {
    let unmounted = false
    ContentService.getManageableQualities().then(result => {
      if (!unmounted) {
        setManageableQualities(result)
      }
    })

    ContentService.getCountries().then(result => {
      if (!unmounted) {
        setCountries(result)
      }
    })

    return () => {
      unmounted = true
      ContentService.cancelRequest()
      DepositService.cancelRequest()
    }
  }, [])

  return (
    <div className='row'>
      {(isRequestView && transaction.notifications && transaction.notifications
        .filter((notification) => NOTIF_STATUS_VISIBLE_TO_FO.includes(notification.status) && notification.sendingMode && notification.sendingMode.includes(SENDING_PORTAL))
        .length > 0)
        ? (
          <div className='col-12 mb-3'>
            <CardBlock
              header={<FormattedMessage id='overview_deposit_card_notification_title' />}
              shadow
            >
              <OverviewNotifications
                transaction={transaction}
                setReload={setReload}
                notificationReference={notificationReference}
              />
            </CardBlock>
          </div>
        )
        : null}
      {
        (isRequestView && transaction.status !== STATUS_APPEAL.DRAFT_CLIENT) &&
          <div className='col-12 mb-3'>
            <DocumentBlock
              transaction={transaction}
              setReload={setReload}
              blockTitle={<FormattedMessage id='request_document_title' />}
            />
          </div>
      }

      <div className='col-12 mb-3'>
        <CardBlock
          header={
            <div className='d-flex justify-content-between'>
              <FormattedMessage id='overview_deposit_card_intervenant_title' />
            </div>
          }
          shadow
        >
          <div className='row'>
            {
              transaction.depositor && (
                <div className='col-12 mb-3 col-md-6'>
                  <h4><FormattedMessage id='overview_applicant_depositor' /></h4>
                  <OverviewComparedIntervenant
                    intervenant={transaction.depositor}
                    countries={countries}
                    qualities={manageableQualities}
                  />
                </div>
              )
            }
            {
              transaction.recipient && (
                <div className='col-12 mb-3 col-md-6'>
                  <h4><FormattedMessage id='overview_deposit_receiver_title' /></h4>
                  <OverviewComparedIntervenant
                    intervenant={transaction.recipient}
                    countries={countries}
                    qualities={manageableQualities}
                  />
                </div>
              )
            }
            {
              transaction.signatory && (
                <div className='col-12 mb-3 col-md-6'>
                  <h4><FormattedMessage id='overview_deposit_signatory_title' /></h4>
                  <OverviewComparedIntervenant
                    intervenant={transaction.signatory}
                    countries={countries}
                    qualities={manageableQualities}
                  />
                </div>
              )
            }
          </div>
        </CardBlock>
      </div>
      <div className='col-12 mb-3'>
        <CardBlock
          header={
            <div className='d-flex justify-content-between'>
              <FormattedMessage id='overview_records_inscription' />
            </div>
          }
          shadow
        >
          <OverviewRecords
            currentIndex={-1}
            isOverview
            titles={transaction.appeal?.titles}
            isOriginShown={false}
          />
        </CardBlock>
      </div>
      <div className='col-12 mb-3'>
        <CardBlock
          header={
            <div className='d-flex justify-content-between'>
              <FormattedMessage id='overview_details_appeal' />
            </div>
          }
          shadow
        >
          <OverviewAppealDetails transaction={transaction} />
        </CardBlock>
      </div>
    </div>
  )
}

export default OverviewAppeal
