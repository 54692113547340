import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { CardBlock, FormatUtils } from '@inpi-dm/components'
import OverviewTableRow from './OverviewTableRow'
import { PriceDetails } from '../../interfaces/Price'
import PriceService from '../../services/content/PriceService'

interface TablePricesProps {
  prices: PriceDetails
}

const TablePrices: FC<TablePricesProps> = ({
  prices
}) => {
  return (
    <CardBlock
      header={<FormattedMessage id='overview_deposit_card_amount_title' />}
      shadow
    >
      <table className='table table-sm'>
        <thead>
          <tr className='d-flex'>
            <th className='border-0 col-6'><FormattedMessage id='overview_deposit_service_title' /></th>
            <th className='border-0 col'><FormattedMessage id='overview_deposit_rate_title' /></th>
            <th className='border-0 col'><FormattedMessage id='overview_deposit_quantity_title' /></th>
            <th className='border-0 col'><FormattedMessage id='overview_deposit_total_title' /></th>
          </tr>
        </thead>
        <tbody>
          {
            Object.entries(prices).length > 0 && Object.entries(prices)
              .map(([, value], index) => (
                <OverviewTableRow
                  key={index} label={value.label} amount={value.price || 0}
                  quantity={value.quantity || 0}
                />
              ))
          }
        </tbody>
      </table>
      <h3>
        <FormattedMessage id='overview_deposit_amount_total' />
        <span className='ml-4'>{FormatUtils.formatPrice(PriceService.getTotalAmount(prices))}€</span>
      </h3>
    </CardBlock>
  )
}

export default TablePrices
