import {
  Deposit, DepositDocument,
  DepositIntervenant, Transaction, DOCUMENT_TYPES,
  FieldStatus,
  INTERVENANT_CO_DEPOSANT,
  INTERVENANT_DEPOSANT,
  INTERVENANT_DESTINATAIRE,
  INTERVENANT_MANDATAIRE,
  INTERVENANT_SIGNATAIRE,
  IntervenantValidator,
  messageIsFilled, SelectOption
} from '@inpi-dm/components'
import AgentValidator from '../../form/agent/validator/AgentValidator'

class DepositCorrectionValidator {
    /**
     * Valide les informations d'un intervenant
     * @param typeIntervenant
     * @param deposit
     * @param updatedIntervenant
     * @param updatedAgentDocuments
     */
    validateIntervenant = (
      typeIntervenant: SelectOption,
      deposit: Deposit,
      updatedIntervenant?: DepositIntervenant|string,
      updatedAgentDocuments?: DepositDocument[]
    ) => {
      switch (typeIntervenant) {
        case INTERVENANT_DEPOSANT:
        case INTERVENANT_CO_DEPOSANT:
          return IntervenantValidator.validateIntervenant(updatedIntervenant, typeIntervenant.value)
        case INTERVENANT_DESTINATAIRE:
          return IntervenantValidator.validateRecipient(updatedIntervenant)
        case INTERVENANT_MANDATAIRE:
          return AgentValidator.validateMandatory({
            file: {
              ...deposit,
              agent: updatedIntervenant
            },
            documents: updatedAgentDocuments
          })
        case INTERVENANT_SIGNATAIRE:
          return IntervenantValidator.validateSignatory(updatedIntervenant)
        default:
          return {}
      }
    }

    /**
     * Valide qu'au moins un document justificatif est fourni
     */
    validateOnPayment = (documents: File[], deposit: Transaction): FieldStatus => ({
      supportingDocuments: messageIsFilled(documents.filter(doc => doc.type === DOCUMENT_TYPES.DEPOSIT_CORRECTION_SUPPORT)),
      agent: AgentValidator.validateMandatory(deposit)
    })
}

export default new DepositCorrectionValidator()
