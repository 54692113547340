import React, { FunctionComponent, ReactElement } from 'react'
import ErrorField from '../errors/ErrorField'
import { FieldProps, FieldStatus } from '../FormInterfaces'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { OverlayTrigger, Popover } from 'react-bootstrap'

export interface TextFieldProps extends FieldProps {
    type?: string,
    required?: boolean,
    fieldStatus?: FieldStatus | any,
    min?: string,
    labelInInput?: ReactElement,
    max?: number,
    infoHelp?: string
}

const TextField: FunctionComponent<TextFieldProps> = ({
  className = '',
  classNameFormGroup = '',
  classNameLabel = '',
  inputId,
  label,
  fieldStatus = {},
  nameFieldStatus,
  value = '',
  type = 'text',
  min,
  onChange,
  onFocus,
  placeholder = '',
  disabled = false,
  readOnly = false,
  required = false,
  maxLength,
  max,
  step = '',
  labelInInput,
  infoHelp
}) => {
  return (
    <div className={`form-group ${labelInInput ? 'd-flex text-field-inline' : ''} ${classNameFormGroup}`}>
      {label &&
        <div className={`d-flex align-items-center ${classNameLabel}`}>
          <label className='form-label' htmlFor={inputId}>
            {label}
            {required && <span className='text-danger'> *</span>}
          </label>
          {infoHelp &&
            <OverlayTrigger
              trigger='click'
              placement='bottom'
              overlay={
                <Popover id='popover-positioned-bottom'>
                  <Popover.Content>
                    {infoHelp}
                  </Popover.Content>
                </Popover>
              }
              rootClose
              rootCloseEvent='click'
            >
              <span className='cursor-pointer ml-2 text-primary'>
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
            </OverlayTrigger>}
        </div>}
      {labelInInput &&
        <span className='input-group-text' id={inputId}>{labelInInput}</span>}
      <input
        type={type}
        id={inputId}
        name={inputId}
        min={min}
        max={max}
        className={`form-control ${className} one-line-ellipsis`}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
        step={step}
        onFocus={onFocus}
        maxLength={maxLength}
      />

      <ErrorField message={fieldStatus[nameFieldStatus || inputId]} />
    </div>
  )
}

export default TextField
