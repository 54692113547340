import { INTERVENANT_DESTINATAIRE, isFilled, isMailFormatValid, isNumeroSiren, isTelephoneValid } from '@inpi-dm/components'
import { FRANCE, PERSONNE_MORALE, PERSONNE_PHYSIQUE, messageIsFilled, DepositIntervenant } from '../../..'
import Message from '../../../constants/Message'

class PersonneFieldValidator {
  isPersonnePhysique = (intervenant: DepositIntervenant) => intervenant.type === PERSONNE_PHYSIQUE.value

  isPersonneMorale = (intervenant: DepositIntervenant) => intervenant.type === PERSONNE_MORALE.value

  validateCivilite = (intervenant: DepositIntervenant) => {
    if (this.isPersonnePhysique(intervenant) && !isFilled(intervenant.civilityCode)) {
      return Message.required_field
    }
    return ''
  }

  validateNom = (intervenant: DepositIntervenant) => {
    if (this.isPersonnePhysique(intervenant) && !isFilled(intervenant.lastname)) {
      return Message.required_field
    }
    return ''
  }

  validatePrenom = (intervenant: DepositIntervenant) => {
    if (this.isPersonnePhysique(intervenant) && !isFilled(intervenant.firstname)) {
      return Message.required_field
    }
    return ''
  }

  validateRaisonSociale = (intervenant: DepositIntervenant) => {
    if (this.isPersonneMorale(intervenant) && !isFilled(intervenant.companyName)) {
      return Message.required_field
    }
    return ''
  }

  validateFormeJuridique = (intervenant: DepositIntervenant) => {
    if (this.isPersonneMorale(intervenant) && !isFilled(intervenant.legalForm)) {
      return Message.required_field
    }
    return ''
  }

  validateSiren = (intervenant: DepositIntervenant) => {
    const { siren = '' } = intervenant

    if (this.isPersonneMorale(intervenant) &&
      isFilled(siren) &&
      !isNumeroSiren(siren)
    ) {
      return Message.error_numero_siren_format
    }
    return ''
  }

  validateTelephone = (intervenant: DepositIntervenant) => {
    const { phone = '' } = intervenant
    const country = intervenant.address?.country

    if (isFilled(phone) && country === FRANCE && !isTelephoneValid(phone)) {
      return Message.error_phone_format
    }
    return ''
  }

  validateEmail = (intervenant: DepositIntervenant) => {
    const { email = '' } = intervenant

    if (isFilled(email) && !isMailFormatValid(email)) {
      return Message.error_email_format
    }
    return ''
  }

  validateAll = (intervenant: DepositIntervenant, intervenantType?: string, isDepositCoOrDepositor: boolean = false, fromBo: boolean = false) => {
    const isRecipient: boolean = intervenantType === INTERVENANT_DESTINATAIRE.value

    return {
      type: messageIsFilled(intervenant.type),
      civilityCode: this.validateCivilite(intervenant),
      lastname: this.validateNom(intervenant),
      firstname: this.validatePrenom(intervenant),
      companyName: this.validateRaisonSociale(intervenant),
      companyNameFormation: intervenant.personFormation && !isFilled(intervenant.companyNameFormation) ? Message.required_field : '',
      legalForm: !(isRecipient || intervenant?.idFromBeneficiary) && this.validateFormeJuridique(intervenant),
      legalFormFormation: !isRecipient && intervenant.personFormation && !isFilled(intervenant.legalFormFormation) ? Message.required_field : '',
      siren: this.validateSiren(intervenant),
      phone: this.validateTelephone(intervenant),
      email: this.validateEmail(intervenant),
      nationality: isDepositCoOrDepositor && !fromBo && !isFilled(intervenant.nationality) ? Message.required_field : ''
    }
  }
}

export default new PersonneFieldValidator()
