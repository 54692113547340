import React, { FC, useState, useEffect } from 'react'
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl'
import ShowMoreText from 'react-show-more-text'
import {
  BY_DAS,
  BY_MAIL, BY_PORTAL, CERTIFICATE_OF_IDENTITY_FRENCH_DESIGN,
  COPY_OF_INSCRIPTION, COPY_OF_PROROGATION,
  EventType,
  FieldStatus, MultipleRadioField, OFFICIAL_COPY_FRENCH_DESIGN,
  OfficialDocumentRequest,
  OfficialDocumentType,
  SelectField
} from '@inpi-dm/components'
import Message from '../../../../constants/Message'

interface OfficialDocumentRequestFormProps extends WrappedComponentProps {
  request: OfficialDocumentRequest,
  setRequest: (request: OfficialDocumentRequest) => void,
  fieldStatus: FieldStatus,
  typesOfficialsDocument: OfficialDocumentType[]
}

const OfficialDocumentRequestForm: FC<OfficialDocumentRequestFormProps> = ({
  request,
  setRequest,
  fieldStatus,
  typesOfficialsDocument,
  intl
}) => {
  const [radioButton, setRadioButton] = useState([])

  useEffect(() => {
    const newRadio = [
      { label: <FormattedMessage id='records_official_document_service_copyOfficialByPortal' />, value: BY_PORTAL },
      { label: <FormattedMessage id='records_official_document_service_copyOfficialByMail' />, value: BY_MAIL }
    ]
    if (request.officialDocumentType?.code === OFFICIAL_COPY_FRENCH_DESIGN ||
        request.officialDocumentType?.code === CERTIFICATE_OF_IDENTITY_FRENCH_DESIGN) {
      newRadio.push({ label: <FormattedMessage id='records_official_document_service_copyOfficialByDAS' />, value: BY_DAS })
    }
    setRadioButton(newRadio)
  }, [request.officialDocumentType])

  /**
   * Récupère les informations du type de document officiel choisie
   */
  const getGoodType = (code?: string) => {
    return typesOfficialsDocument.find(type => (code && code === type.code) || (!code && request?.officialDocumentType?.code === type.code))
  }

  /**
 * Changement à faire lors de la modification des type de prestation
 * @param event
 */
  const setType = (event: EventType) => {
    let newRequest = {
      ...request,
      officialDocumentType: getGoodType(event.target.value),
      desiredFormatOfficialDocument: BY_PORTAL,
      modelsSelected: undefined
    }
    // Si c'est une copie d'inscription ou de prorogation alors on supprime le title
    if (event.target.value === COPY_OF_PROROGATION || event.target.value === COPY_OF_INSCRIPTION) {
      newRequest = { ...newRequest, title: undefined }
    } else {
      newRequest = { ...newRequest, copyNum: undefined }
    }
    setRequest(newRequest)
  }

  return (
    <>
      <div>
        <div className='row m-0 align-items-center is-validated'>
          <div className='col-12 col-md-6'>
            <SelectField
              inputId='officialDocumentType'
              label={<FormattedMessage id='records_official_document_service_type' />}
              options={typesOfficialsDocument?.map((type : OfficialDocumentType) => ({ label: type.label, value: type.code }))}
              placeholder={intl.formatMessage({ id: 'select_placeholder' })}
              value={request.officialDocumentType?.code}
              onChange={setType}
              fieldStatus={fieldStatus}
              required
            />
          </div>
          <div className='col-12 col-md-6 mt-3'>
            <MultipleRadioField
              inputId='desiredFormatOfficialDocument'
              value={request.desiredFormatOfficialDocument}
              radio={radioButton}
              onChange={(event: EventType) => setRequest({
                ...request,
                desiredFormatOfficialDocument: event.target.value
              })}
            />
          </div>
          {
            request.officialDocumentType && getGoodType() &&
              <div className='col-12'>
                <ShowMoreText
                  lines={1}
                  more={Message.overview_records_inscription_show_more}
                  less={Message.overview_records_inscription_show_less}
                  expanded={false}
                >
                  <div>
                    <div dangerouslySetInnerHTML={{ __html: getGoodType()?.description }} />
                    <span className='font-weight-bold'><FormattedMessage id='records_official_document_service_delay' /></span>
                    {getGoodType()?.delay}
                  </div>
                </ShowMoreText>
              </div>
          }
        </div>
      </div>
    </>
  )
}

export default injectIntl(OfficialDocumentRequestForm)
