import {
  OFFICIAL_DOCUMENT_FIELD_STATUS_RESET,
  OFFICIAL_DOCUMENT_FIELD_STATUS_UPDATE,
  OFFICIAL_DOCUMENT_REMOVE,
  OFFICIAL_DOCUMENT_UPDATE,
  OfficialDocumentFieldStatusStoreAction,
  OfficialDocumentStoreAction
} from './officialDocumentStoreTypes'

/**
 * @param officialDocument une partie de Transaction
 */
export const storeOfficialDocumentUpdate = (officialDocument: any): OfficialDocumentStoreAction => ({
  type: OFFICIAL_DOCUMENT_UPDATE,
  officialDocument
})

export const storeOfficialDocumentRemove = () => ({
  type: OFFICIAL_DOCUMENT_REMOVE
})

/**
 * @param fieldStatus une partie de OfficialDocumentFieldStatus
 */
export const storeOfficialDocumentFieldStatusUpdate = (fieldStatus: any): OfficialDocumentFieldStatusStoreAction => ({
  type: OFFICIAL_DOCUMENT_FIELD_STATUS_UPDATE,
  fieldStatus
})

export const storeOfficialDocumentFieldStatusReset = () => ({
  type: OFFICIAL_DOCUMENT_FIELD_STATUS_RESET
})
