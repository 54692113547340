import React, { FC, useEffect, useState } from 'react'
import Requests from './Requests'
import {
  Transaction,
  FilAriane,
  STATUS_INSCRIPTION,
  SubmitButton,
  PROCESS_NORMAL,
  ModalComponent
} from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'
import { Link, useHistory } from 'react-router-dom'
import { RequestType } from '../../interfaces/RequestInterfaces'
import TransactionService from '../../services/transaction/TransactionService'
import OverviewInscription from '../inscription/form/overview/OverviewInscription'
import { PAYMENT_METHOD, PAYMENT_OBJECT } from '../../constants/DepositConstants'
import InscriptionService from '../../services/transaction/InscriptionService'
import OverviewRoyalties from '../payment/OverviewRoyalties'
import SelectPaymentBlocks from '../payment/SelectPaymentBlocks'
import { Payment, PaymentParameters } from '../../interfaces/DepositInterfaces'
import PaymentService from '../../services/transaction/PaymentService'
import { PAYMENT_URL_TRANSFORMATION_TN_TA } from '../../constants/PaymentConstants'

interface OneInscriptionProps {
  type: RequestType,
  id: string,
}

const OneInscription: FC<OneInscriptionProps> = ({
  type,
  id
}) => {
  const history = useHistory()
  const [transaction, setTransaction] = useState<Transaction>()
  const [reload, setReload] = useState(false)
  const [showModalPayment, setShowModalPayment] = useState(false)
  const [payment, setPayment] = useState<Payment>({ lines: [], totalAmount: 0 })

  /**
   * Met à jour l'inscription affiché
   */
  const updateInscription = () => {
    TransactionService.getTransaction(id)
      .then(setTransaction)
      .catch(() => {
        history.replace(`/liste-demandes/${type.urlParam}`)
      })
  }

  useEffect(updateInscription, [id, history, type, reload])

  useEffect(() => {
    if (showModalPayment) {
      updatePriceTransformation()
    }
  }, [showModalPayment])
  /**
   * Prépare et se rend sur le formulaire d'édition d'un dépot
   */
  const editInscription = () => {
    history.push(`/inscriptions/${transaction?.id}/edition`)
  }

  /**
     * Récupère le prix de la transformation
     */
  const updatePriceTransformation = () => {
    InscriptionService.getPaymentTransformation(transaction.id)
      .then(setPayment)
  }

  /**
     * Enregistre une demande de paiement pour une demande de transformation d'une TN en TA
     * @param payment
     */
  const payTransformation = (payment: PaymentParameters) => {
    const paramsPayment = {
      ...payment,
      label: 'Transformation TN en TA',
      objectName: PAYMENT_OBJECT.TRANSFORMATION_TN_TA
    }
    return PaymentService.createPayment(transaction.id, paramsPayment)
      .then(result => {
        if (payment.method === PAYMENT_METHOD.BLUE_CARD) {
          PaymentService.displayPayBox(result)
        } else {
          history.push(`/${PAYMENT_URL_TRANSFORMATION_TN_TA}/${transaction.id}/paiement/confirmation`)
        }
      })
      .catch(() =>
        history.push(`/${PAYMENT_URL_TRANSFORMATION_TN_TA}/${transaction.id}/paiement/erreur`)
      )
  }

  const modaleContent = () => (
    <div>
      <p><FormattedMessage id='request_transform_tn_ta_inscription_modal_content' /></p>
      <OverviewRoyalties infoPrice={payment} className='mb-3' />
      <SelectPaymentBlocks
        onStartPayment={payTransformation}
        object={transaction}
      />
    </div>
  )

  return (
    <div>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <Link to={`/liste-demandes/${type.urlParam}`}><FormattedMessage id={type.label} /></Link>
        <span><FormattedMessage id='request_title_inscription' />{transaction && (transaction.numNat ? transaction.numNat : transaction.id)}</span>
      </FilAriane>
      <Requests currentIdBlock={type.id}>
        {transaction &&
          <div>
            <header className='mx-3 mb-4 d-flex justify-content-between'>
              <h1><FormattedMessage id='request_title_inscription' />{transaction.numNat ? transaction.numNat : transaction.id}</h1>
              {
                transaction.process === PROCESS_NORMAL && transaction.status === STATUS_INSCRIPTION.TO_ATTRIBUTE &&
                  <SubmitButton
                    className='btn-link-primary'
                    onClick={() => setShowModalPayment(true)}
                  >
                    <FormattedMessage id='request_transform_tn_ta_inscription' />
                  </SubmitButton>
              }
            </header>
            <OverviewInscription
              transaction={transaction}
              isRequestView
              setReload={() => setReload(!reload)}
            />
            <div className='row justify-content-center mt-4'>
              {transaction?.status === STATUS_INSCRIPTION.DRAFT_CLIENT && (
                <div className='col-12 col-md-4'>
                  <button
                    className='btn btn-block btn-outline-primary'
                    onClick={editInscription}
                    disabled={!!(transaction?.payments?.find(payment => payment.type === PAYMENT_METHOD.MANAGEABLE_MEMORY && payment.status === 'pending'))}
                  >
                    <FormattedMessage id='request_go_edit' />
                  </button>
                </div>
              )}
            </div>
          </div>}
        <ModalComponent
          size='xl'
          title={<FormattedMessage id='request_transform_tn_ta_inscription_modal_title' />}
          show={showModalPayment}
          customContent={modaleContent}
          handleClose={() => setShowModalPayment(false)}
          hideFooter
        />
      </Requests>
    </div>
  )
}

export default OneInscription
