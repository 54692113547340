import React, { FC, useEffect, useState } from 'react'
import Requests from './Requests'
import {
  Transaction,
  FilAriane,
  STATUS_DO
} from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'
import { Link, useHistory } from 'react-router-dom'
import { RequestType } from '../../interfaces/RequestInterfaces'
import TransactionService from '../../services/transaction/TransactionService'
import { PAYMENT_METHOD } from '../../constants/DepositConstants'
import OverviewOfficialDocument from '../officialDocument/form/overview/OverviewOfficialDocument'

interface OneOfficialDocumentProps {
  type: RequestType,
  id: string,
}

const OneOfficialDocument: FC<OneOfficialDocumentProps> = ({
  type,
  id
}) => {
  const history = useHistory()
  const [transaction, setTransaction] = useState<Transaction>()
  const [reload, setReload] = useState(false)

  /**
   * Met à jour la demande de relevé de déchéance affichée
   */
  const updateOfficialDocument = () => {
    TransactionService.getTransaction(id)
      .then(setTransaction)
      .catch(() => {
        history.replace(`/liste-demandes/${type.urlParam}`)
      })
  }

  useEffect(updateOfficialDocument, [id, history, type, reload])

  /**
   * Prépare et se rend sur le formulaire d'édition d'une demande de relevé de déchéance
   */
  const editOfficialDocument = () => {
    history.push(`/documents-officiels/${transaction?.id}/edition`)
  }

  return (
    <div>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <Link to={`/liste-demandes/${type.urlParam}`}><FormattedMessage id={type.label} /></Link>
        <span><FormattedMessage id='request_title_do' />{transaction && (transaction.numNat ? transaction.numNat : transaction.id)}</span>
      </FilAriane>
      <Requests currentIdBlock={type.id}>
        {transaction &&
          <div>
            <header className='mx-3 mb-4 d-flex justify-content-between'>
              <h1><FormattedMessage id='request_title_do' />{transaction.numNat ? transaction.numNat : transaction.id}</h1>
            </header>
            <OverviewOfficialDocument
              transaction={transaction}
              isRequestView
              setReload={() => setReload(!reload)}
            />
            <div className='row justify-content-center mt-4'>
              {transaction?.status === STATUS_DO.DRAFT_CLIENT && (
                <div className='col-12 col-md-4'>
                  <button
                    className='btn btn-block btn-outline-primary'
                    onClick={editOfficialDocument}
                    disabled={!!(transaction?.payments?.find(payment => payment.type === PAYMENT_METHOD.MANAGEABLE_MEMORY && payment.status === 'pending'))}
                  >
                    <FormattedMessage id='request_go_edit' />
                  </button>
                </div>
              )}
            </div>
          </div>}
      </Requests>
    </div>
  )
}

export default OneOfficialDocument
