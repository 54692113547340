import React from 'react'
import Logo from '../../common/Logo'
import { FormattedMessage } from 'react-intl'
import LoginForm from './LoginForm'

const Login = () => (
  <div className='container fluid'>
    <div className='login-page'>
      <div className='row justify-content-md-center align-items-center'>
        <div className='col-md-6'>
          <div className='row'>
            <div className='col-8 offset-2 col-md-6 offset-3'>
              <span id='logo'>
                <Logo />
              </span>
            </div>
          </div>

          <div className='row'>
            <div className='col'>
              <header>
                <span>
                  <FormattedMessage id='module_name' />
                  {' '}
                </span>
                <span className='text-inpi-dm'>
                  <FormattedMessage id='module_service' />
                </span>
              </header>
            </div>
          </div>
          <LoginForm />
        </div>
      </div>
    </div>
  </div>
)

export default Login
