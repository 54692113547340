import React, { FC, ReactNode } from 'react'
import { EventType, FieldStatus } from '../FormInterfaces'
import { buildEventType, ErrorField } from '../../index'

interface MultipleRadioFieldProps {
    inputId: string,
    value: string,
    radio: { label: ReactNode, value: string}[]
    onChange: (event: EventType) => void
    fieldStatus?: FieldStatus,
    className?: string,
    nameFieldStatus?: string,
    classNameRadioGroup?: string,
    classNameError?: string
}

const MultipleRadioField: FC<MultipleRadioFieldProps> = ({
  inputId, radio,
  fieldStatus = {},
  className = '', onChange, value,
  nameFieldStatus,
  classNameRadioGroup = '',
  classNameError = ''
}) => {
  const handleChange = (value : string) => {
    onChange(buildEventType(inputId, value))
  }

  return (
    <div className={`${classNameRadioGroup}`}>
      {
        radio.map((oneRadio, index) => (
          <div key={index} className={`form-check form-check-inline ${className}`}>
            <input
              className='form-check-input'
              type='radio'
              id={`${inputId}${index}`}
              checked={oneRadio.value === value}
              onChange={() => handleChange(oneRadio.value)}
            />
            <label className='form-check-label' htmlFor={`${inputId}${index}`}>{oneRadio.label}</label>
          </div>
        ))
      }
      <ErrorField message={fieldStatus[nameFieldStatus || inputId]} className={classNameError} />
    </div>
  )
}

export default MultipleRadioField
