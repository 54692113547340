import http from '../../network/http-common'
import { toast } from 'react-toastify'
import { createIntl } from 'react-intl'
import Message from '../../constants/Message'
import { PaymentParameters } from '../../interfaces/DepositInterfaces'

/**
 * Classe permettant de gérer les paiements
 */
class PaymentService {
  constructor () {
    this.intl = createIntl({ locale: 'fr', messages: Message })
  }

  /**
   * Création d'un paiement
   * Retourne le formulaire Paybox à afficher si paiement par CB ou ko/ok sinon
   */
  createPayment = async (id: string, payment: PaymentParameters): Promise<any> => {
    try {
      return await http.post(`/api/transactions/${id}/payments`, {
        method: payment.method,
        account: payment.inpiAccount,
        label: payment.label,
        objectName: payment.objectName,
        objectIds: payment.objectIds,
        payer: payment.payer,
        hasSupplementExcluded: payment.hasSupplementExcluded
      })
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Vérifie le statut du paiement
   */
  checkPayment = async (id: string, idPayment: string): Promise<any> => {
    try {
      return await http.get(`/api/transactions/${id}/payments/${idPayment}`)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Annulation d'un paiement
   */
  cancelPayment = async (id: string, idPayment: string): Promise<any> => {
    try {
      return await http.delete(`/api/transactions/${id}/payments/${idPayment}`)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Affiche le formulaire paybox de paiement en pleine page
   * @param result
   */
  displayPayBox = (result: any) => {
    const w = window.open('', '_self', '')
    const document = w.document
    document.open()
    document.write(result)
    document.close()
  }
}

export default new PaymentService()
