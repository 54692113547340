import React, { FC, Fragment } from 'react'
import { CheckboxField, ModelDeposit } from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'
import ListReproductionsLine from './ListReproductionsLine'
import ReproductionService from '../../../../../services/transaction/ReproductionService'

interface ListReproductionsTableBodyProps {
    models: ModelDeposit[],
    onCheck: (checked: boolean, indexModel?: number, index?: number) => void,
    idTransaction?: string,
}

const ListReproductionsTableBody: FC<ListReproductionsTableBodyProps> = ({
  models,
  onCheck,
  idTransaction
}) => (
  <tbody>
    {models.map((model, indexModel) => (
      <Fragment key={model.id}>
        <tr className='tr-model'>
          <td colSpan={6}>
            <div className='row'>
              <div className='col-12 col-md-3'>
                <CheckboxField
                  className='mb-0'
                  inputId={`checkAll-${indexModel}`}
                  label={<FormattedMessage id='select_all' />}
                  onChange={({ target }) => onCheck(target.value, indexModel)}
                  checked={ReproductionService.hasAllReproductionsSelected([model])}
                />
              </div>
              <div className='col-12 col-md-6 text-center'>
                <div className='font-weight-bold'>
                  <FormattedMessage id='reproduction_model_number' />{indexModel + 1} : {model.modelDesignation.name}
                  <span className='text-lowercase'> - {model.reproductions.length} <FormattedMessage id='request_number_reproduction' />
                    {model.reproductions.length > 1 ? 's' : ''}
                  </span>
                </div>
              </div>
            </div>
          </td>
        </tr>
        {model.reproductions.map((reproduction, index) => (
          <ListReproductionsLine
            key={reproduction.file.internalName}
            idTransaction={idTransaction}
            model={model}
            reproduction={reproduction}
            indexModel={indexModel}
            indexRepro={index}
            onCheck={() => onCheck(!reproduction.selected, indexModel, index)}
          />
        ))}
        {
          model.reproductions.length % 2 === 0 && <tr />
        }
      </Fragment>
    ))}
  </tbody>
)

export default ListReproductionsTableBody
