import http from '../../network/http-common'
import store from '../../store/store'
import {
  isFilled,
  Country,
  ManageableQuality,
  ProtectionExtension,
  EditorialBlock,
  ProrogationDelay
} from '@inpi-dm/components'
import {
  storeCountriesUpdate,
  storeManageableQualitiesUpdate,
  storeProrogationDelaysUpdate
} from '../../store/content/contentActions'
import Message from '../../constants/Message'
import { createIntl } from 'react-intl'
import axios from 'axios'

/**
 * Classe permettant de récupérer les données administrables en bo
 */
class ContentService {
  constructor () {
    this.intl = createIntl({ locale: 'fr', messages: Message })
    this.source = axios.CancelToken.source()
  }

    /**
     * Récupération des extensions de protection définies dans le BO
     * @returns Promise<ProtectionExtension[]
     */
    getProtectionExtensions = async (): Promise<ProtectionExtension[]> => {
      try {
        return await http.get('/api/protection-extensions')
      } catch (error) {
        return Promise.resolve([])
      }
    }

    /**
     * Récupération de la liste des pays possibles
     * @returns Promise<Country[]>
     */
    getCountries = async (): Promise<Country[]> => {
      let countries = store.getState().adminContent.countries
      if (!isFilled(countries)) {
        try {
          countries = await http.get('/api/countries', {
            cancelToken: this.source.token
          })
          store.dispatch(storeCountriesUpdate(countries))
        } catch (error) {
          countries = []
        }
      }
      return Promise.resolve(countries)
    }

  /**
   * Récupération des variables administratives des délai de prorogation
   * @returns Promise<Country[]>
   */
  getProrogationDelays = async (): Promise<ProrogationDelay[]> => {
    let delays = store.getState().adminContent.prorogationDelays
    if (!isFilled(delays)) {
      try {
        delays = await http.get('/api/configurations/prorogation-delays', {
          cancelToken: this.source.token
        })
        store.dispatch(storeProrogationDelaysUpdate(delays))
      } catch (error) {
        delays = []
      }
    }
    return Promise.resolve(delays)
  }

    /**
     * Récupération de la liste des qualités administrables
     * @return Promise<ManageableQuality[]>
     */
    getManageableQualities = async (): Promise<ManageableQuality[]> => {
      let qualities = store.getState().adminContent.qualities
      if (!isFilled(qualities)) {
        try {
          qualities = await http.get('/api/manageable-qualities', {
            cancelToken: this.source.token
          })
          store.dispatch(storeManageableQualitiesUpdate(qualities))
        } catch (error) {
          qualities = []
        }
      }
      return Promise.resolve(qualities)
    }

    /**
     * Récupération d'un bloc éditorial par son code
     * @returns Promise<EditorialBlock[]>
     */
    getEditorialBlockByCode = async (code: string): Promise<EditorialBlock[]> => {
      try {
        return code ? await http.get(`/api/editorial-blocks/${code}`) : await http.get('/api/editorial-blocks')
      } catch (error) {
        return Promise.resolve([])
      }
    }

    /**
     * Annule une requête en attente
     */
    cancelRequest = () => {
      this.source.cancel()
      this.source = axios.CancelToken.source()
    }
}

export default new ContentService()
