import React, { useEffect, useState, FC } from 'react'
import DashboardItem from './DashboardItem'
import {
  DOC_REQUEST,
  NOTIF_REQUEST,
  OTHER_REQUEST,
  REQUEST_TYPES,
  STATUS_REQUEST
} from '../../../constants/RequestConstants'
import RequestsService from '../../../services/requests/RequestsService'
import { TransactionCountByStatus } from '@inpi-dm/components'
import TransactionService from '../../../services/transaction/TransactionService'
import RequestItem from '../../requests/RequestItem'

interface CountItemsProps {
  isForDashboard?: boolean,
  currentIdBlock?: string
}

const CountItems : FC<CountItemsProps> = ({
  isForDashboard = false,
  currentIdBlock
}) => {
  const [countDepositByStatus, setCountDepositByStatus] = useState<TransactionCountByStatus[]>()
  const [requestsDone, setRequestsDone] = useState([])

  useEffect(() => {
    let requests = []
    let count = []
    setRequestsDone([])
    setCountDepositByStatus([])

    // Pour des raisons de performances, nous sommes obligés de découper en 4 requêtes consécutives
    TransactionService.getTransactionsCountFo(STATUS_REQUEST).then((resultStatus) => {
      count = [...count, ...resultStatus]
      setCountDepositByStatus(count)
      requests = [...requests, STATUS_REQUEST]
      setRequestsDone(requests)
    })
    TransactionService.getTransactionsCountFo(NOTIF_REQUEST).then((resultNotif) => {
      count = [...count, ...resultNotif]
      setCountDepositByStatus(count)
      requests = [...requests, NOTIF_REQUEST]
      setRequestsDone(requests)
    })
    TransactionService.getTransactionsCountFo(DOC_REQUEST).then((resultDoc) => {
      count = [...count, ...resultDoc]
      setCountDepositByStatus(count)
      requests = [...requests, DOC_REQUEST]
      setRequestsDone(requests)
    })
    TransactionService.getTransactionsCountFo(OTHER_REQUEST).then((resultOther) => {
      count = [...count, ...resultOther]
      setCountDepositByStatus(count)
      requests = [...requests, OTHER_REQUEST]
      setRequestsDone(requests)
    })

    return () => {
      TransactionService.cancelRequest()
    }
  }, [])

  return (
    <div className='dashboard row'>
      {REQUEST_TYPES.map(type => (
        isForDashboard
          ? (
            <DashboardItem
              key={type.id}
              label={type.label}
              badge={countDepositByStatus && requestsDone.filter(typeDone => typeDone === type.typeRequest).length > 0
                ? RequestsService.sumCountByStatus(type, countDepositByStatus)
                : -1}
              url={`/liste-demandes/${type.urlParam}`}
            />
          )
          : (
            <RequestItem
              key={type.id}
              url={`/liste-demandes/${type.urlParam}`}
              label={type.label}
              badge={countDepositByStatus && requestsDone.filter(typeDone => typeDone === type.typeRequest).length > 0
                ? RequestsService.sumCountByStatus(type, countDepositByStatus) : -1}
              className={currentIdBlock === type.id ? 'active' : ''}
            />
          )
      ))}
    </div>
  )
}

export default CountItems
