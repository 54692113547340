import {
  PROROGATION_FIELD_STATUS_RESET,
  PROROGATION_FIELD_STATUS_UPDATE,
  PROROGATION_REMOVE, PROROGATION_UPDATE, ProrogationFieldStatusStoreAction,
  ProrogationStoreAction
} from './prorogationStoreTypes'

/**
 * @param prorogation une partie de Transaction
 */
export const storeProrogationUpdate = (prorogation: any): ProrogationStoreAction => ({
  type: PROROGATION_UPDATE,
  prorogation
})

export const storeProrogationRemove = () => ({
  type: PROROGATION_REMOVE
})

/**
 * @param fieldStatus une partie de ProrogationFieldStatus
 */
export const storeProrogationFieldStatusUpdate = (fieldStatus: any): ProrogationFieldStatusStoreAction => ({
  type: PROROGATION_FIELD_STATUS_UPDATE,
  fieldStatus
})

export const storeProrogationFieldStatusReset = () => ({
  type: PROROGATION_FIELD_STATUS_RESET
})
