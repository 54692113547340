import React, { FC, useEffect, useState } from 'react'
import {
  DATE_ISO,
  DateUtils,
  Deposit,
  Transaction,
  EventType,
  FilAriane,
  MAIL_MODELS,
  STATUS_WITHDRAWN,
  NotificationInterface,
  ReproductionLightDeposit
} from '@inpi-dm/components'
import DepositService from '../../../services/transaction/DepositService'
import { Link, useHistory } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { REQUEST_TYPES } from '../../../constants/RequestConstants'
import DivisionDepositChild from './DivisionDepositChild'
import { RequestType } from '../../../interfaces/RequestInterfaces'
import { toast } from 'react-toastify'
import Message from '../../../constants/Message'
import DivisionService from '../../../services/transaction/DivisionService'
import DivisionNotificationBlock from './DivisionNotificationBlock'
import OverviewRoyalties from '../../payment/OverviewRoyalties'
import DivisionAnswer from './DivisionAnswer'
import { PaymentParameters } from '../../../interfaces/DepositInterfaces'
import PaymentService from '../../../services/transaction/PaymentService'
import { PAYMENT_METHOD, PAYMENT_OBJECT } from '../../../constants/DepositConstants'
import NotificationService from '../../../services/transaction/NotificationService'
import {
  NOTIF_ANSWERED_TYPE_WITHDRAW
} from '@inpi-dm/components/src/constants/NotificationsConstants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import PriceService from '../../../services/content/PriceService'
import TransactionService from '../../../services/transaction/TransactionService'

interface DivisionDepositFormProps {
  idTransaction: string,
  idNotification: string,
}

const DivisionDepositForm: FC<DivisionDepositFormProps> = ({
  idTransaction,
  idNotification
}) => {
  const history = useHistory()

  const [transaction, setTransaction] = useState<Transaction>()
  const [notification, setNotification] = useState<NotificationInterface>()
  const [type, setType] = useState<RequestType>()
  const [depositChildren, setDepositChildren] = useState()
  const [price, setPrice] = useState()
  const [answerComment] = useState('')
  const [isPriceUpdated, setIsPriceUpdated] = useState(false)

  useEffect(() => {
    // Récupération du dépôt à diviser
    TransactionService.getTransaction(idTransaction)
      .then(setTransaction)
      .catch(() => history.push('/'))
  }, [])

  useEffect(() => {
    if (transaction) {
      setType(REQUEST_TYPES.filter(type => type.statusFiltres.includes(transaction.status))[0])

      // Divise le dépôt parent selon les reproductions restantes dans chaque modèle
      setDepositChildren(DivisionService.splitModelsInDeposits(transaction.file))

      // Récupère la notification à l'origine de la proposition de division
      const notification = transaction.notifications?.find (notif => notif.id === idNotification && notif.codeMailModel === MAIL_MODELS.MAIL_MODEL_PROPOSAL_DIVISION_CODE)
      if (notification) {
        setNotification(notification)
        setPrice({
          lines: notification.prices,
          totalAmount: PriceService.getTotalAmount(notification.prices)
        })
        setIsPriceUpdated(true)
      } else {
        toast.error(Message.request_division_notification_not_found)
      }
    }
  }, [transaction])

  /**
   * Met à jour les taxes à payer pour la proposition de division
   */
  const updatePrice = (deposits: Transaction[]) => {
    setIsPriceUpdated(false)
    DivisionService.getSimulatedPrices(transaction.id, getSelectedReproductionInternalNames(deposits))
      .then(setPrice)
      .then(() => setIsPriceUpdated(true))
  }

  /**
   * Applique une modification sur un dépôt enfant
   * @param indexChild
   * @param event
   */
  const handleChange = (indexChild: number, event: EventType) => {
    const { value, name } = event.target

    const deposits = [...depositChildren]
    deposits[indexChild] = {
      ...deposits[indexChild],
      [name]: value
    }

    setDepositChildren(deposits)
    updatePrice(deposits)
  }

  /**
   * Récupère les noms internes de toutes les reproductions
   * sélectionnées
   */
  const getSelectedReproductionInternalNames = (deposits: Transaction[]): string[] => {
    const internalNamesSelected = []
    deposits.forEach((deposit: Deposit) => {
      if (deposit.models) {
        deposit.models.forEach(model => (
          model.reproductions
            .filter(repro => repro.selected)
            .map(repro => repro.file.internalName)
            .forEach(internalName =>
              internalNamesSelected.push(internalName)
            )
        ))
      }
    })

    return internalNamesSelected
  }

  /**
   * Retire toutes les reproductions sélectionnées
   */
  const withdrawAllSelectedReproductions = async () => {
    let withdrawnReproductions: ReproductionLightDeposit[] = []
    const dateWithdraw = DateUtils.formateDateToFormat(new Date(), DATE_ISO)
    for (const deposit of depositChildren) {
      for (const model of deposit.models) {
        const filteredReproductions = model.reproductions.filter((reproduction: ReproductionLightDeposit) => reproduction.selected)
        withdrawnReproductions = withdrawnReproductions.concat(filteredReproductions)
        for (const reproduction of filteredReproductions) {
          await DepositService.updateReproduction(
            transaction.id,
            model.id,
            reproduction.file.internalName,
            reproduction.position,
            {
              ...reproduction,
              state: STATUS_WITHDRAWN,
              dateState: dateWithdraw
            }
          )
        }
      }
    }

    if (withdrawnReproductions.length > 0) {
      await NotificationService.postAnsweredNotification(transaction.id, NOTIF_ANSWERED_TYPE_WITHDRAW, withdrawnReproductions)
    }

    return Promise.resolve()
  }

  /**
   * Retire toutes les reproductions sélectionnées et répond immédiatement
   * à la notification dans le cas où l'utilisateur n'a aucun paiement a effectuer
   */
  const handleWithdrawnAndAnswer = () => {
    return withdrawAllSelectedReproductions()
      .then(() => {
        // Réponse à la notification
        return answerNotification()
      })
      .then(() => {
        toast.success(Message.withdraw_reproduction_success)
        history.push(`/liste-demandes/${type.urlParam}/demande/${idTransaction}`)
      })
  }

  /**
   * Mise à jour du commentaire de réponse de la notification
   */
  const answerNotification = async () => {
    return await NotificationService.answerNotification(transaction.id, notification.id, {
      answerComment
    })
  }

  /**
   * 1 - Retire les reproductions sélectionnées,
   * 2 - Enregistre le commentaire de réponse à la notification de proposition de division,
   * 3 - et démarre le paiement de la proposition de division
   *
   * @param payment
   */
  const handlePayDivision = (payment: PaymentParameters) => {
    // Paiement de la division
    payment = {
      ...payment,
      label: 'Division de dépôt',
      objectName: PAYMENT_OBJECT.DIVISION,
      objectIds: [
        notification.id
      ]
    }
    return withdrawAllSelectedReproductions()
      .then(() => answerNotification())
      .then(() => PaymentService.createPayment(transaction.id, payment))
      .then(result => {
        if (payment.method === PAYMENT_METHOD.BLUE_CARD) {
          PaymentService.displayPayBox(result)
        } else {
          history.push(`/division/${transaction.id}/paiement/confirmation`)
        }
      })
      .catch(() => {
        history.push(`/division/${transaction.id}/paiement/erreur`)
      })
  }

  const deposit = transaction?.file

  return (transaction && deposit ? (
    <div>
      {type && (
        <FilAriane>
          <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
          <Link to={`/liste-demandes/${type.urlParam}`}><FormattedMessage id={type.label} /></Link>
          <Link to={`/liste-demandes/${type.urlParam}/demande/${idTransaction}`}>
            <FormattedMessage id='request_title' />{transaction.file.numNat}
          </Link>
          <span><FormattedMessage id='request_division_title' /></span>
        </FilAriane>
      )}

      <div className='row'>
        <div className='col-12 col-md-8 mb-3'>
          {depositChildren && depositChildren.map((child, index) =>
            <DivisionDepositChild
              key={index}
              indexChild={index}
              deposit={child}
              parent={transaction}
              header={index === 0
                ? <><FormattedMessage id='request_division_parent_title' />{transaction.file.numNat}</>
                : <><FormattedMessage id='request_division_child_title' />{index}</>}
              onChange={event => handleChange(index, event)}
            />
          )}
        </div>

        <div className='col-12 col-md-4 mb-3'>
          {notification && (
            <DivisionNotificationBlock idTransaction={transaction.id} notification={notification} />
          )}

          {price ? (
            <div>
              <OverviewRoyalties infoPrice={price} className='h-auto mb-3' bodyClassName='p-3' />
              {notification &&
              (
                <DivisionAnswer
                  priceInfo={price}
                  onAccept={handleWithdrawnAndAnswer}
                  deposit={transaction.file}
                  onPay={handlePayDivision}
                  disableAcceptButton={!isPriceUpdated}
                />
              )}
            </div>
          ) : (
            <div className='d-flex justify-content-center mt-4'>
              <FontAwesomeIcon className='loader' icon={faSpinner} />
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className='d-flex justify-content-center mt-4'>
      <FontAwesomeIcon className='loader' icon={faSpinner} />
    </div>
  ))
}

export default DivisionDepositForm
