import React, { FC } from 'react'
import { Payment } from '../../interfaces/DepositInterfaces'
import { FormattedMessage } from 'react-intl'
import { CardBlock } from '@inpi-dm/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

interface OverviewRoyaltiesProps {
  infoPrice?: Payment,
  className?: string,
  bodyClassName?: string,
  isPaymentLoading?: boolean,
  hasSupplementExcluded: boolean,
  setHasSupplmentExluded: (hasSupplementExcluded: boolean) => void
}

const OverviewRoyalties: FC<OverviewRoyaltiesProps> = ({
  infoPrice,
  className,
  bodyClassName,
  isPaymentLoading,
  hasSupplementExcluded,
  setHasSupplmentExluded
}) => {
  /**
   * Formatte les prix qui sont en centimes
   * @param number
   * @returns {number}
   */
  const formatAmount = (number: number) => {
    return number ? (number / 100) : 0
  }

  /**
 * Formatte les prix qui sont en centimes
 * @returns void
 */
  const handleCheckBoxChange = () => {
    setHasSupplmentExluded(!hasSupplementExcluded)
  }

  return (
    <CardBlock
      className={className}
      header={<FormattedMessage id='overview_deposit_card_amount_title' />}
      bodyClassName={bodyClassName}
      shadow
    >
      {isPaymentLoading ? <FontAwesomeIcon className='loader mr-1' icon={faSpinner} />
        : (infoPrice && infoPrice.totalAmount !== 0
          ? (
            <div>
              <table className='table table-sm'>
                <thead>
                  <tr className='d-flex'>
                    <th className='border-0 col-6'><FormattedMessage id='overview_deposit_service_title' /></th>
                    <th className='border-0 col'><FormattedMessage id='overview_deposit_rate_title' /></th>
                    <th className='border-0 col'><FormattedMessage id='overview_deposit_quantity_title' /></th>
                    <th className='border-0 col'><FormattedMessage id='overview_deposit_total_title' /></th>
                    <th className='border-0 col'><FormattedMessage id='overview_deposit_action_title' /></th>
                  </tr>
                </thead>
                <tbody>
                  {infoPrice.lines?.map((row) => {
                    const amount = row.amount || row.price
                    const isDisabled = row.code !== '132A'
                    return (
                      <tr className='d-flex'>
                        <td className='d-none d-md-table-cell col-6'>{row.label}</td>
                        <td className='d-none d-md-table-cell col'>{formatAmount(amount)}€</td>
                        <td className='d-none d-md-table-cell col'>{row.quantity}</td>
                        <td className='d-none d-md-table-cell col'>{formatAmount(amount * row.quantity)}€</td>
                        <td className='d-block d-md-none'>
                          <h4>{row.label}</h4>
                          <ul>
                            <li><FormattedMessage id='overview_deposit_rate_title' />{` : ${formatAmount(amount)}€`}</li>
                            <li><FormattedMessage id='overview_deposit_quantity_title' />{` : ${row.quantity}`}</li>
                            <li><FormattedMessage id='overview_deposit_total_title' />{` : ${formatAmount(amount * row.quantity)}€`}</li>
                          </ul>
                        </td>
                        <td className='d-none d-md-table-cell col'>
                          <input
                            type='checkbox'
                            id='inputHasSupplementExcluded'
                            name='input'
                            checked={isDisabled ? true : !hasSupplementExcluded}
                            onChange={handleCheckBoxChange}
                            disabled={isDisabled}
                          />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              <h3>
                <FormattedMessage id='overview_deposit_amount_total' />
                <span className='ml-4'>{infoPrice.totalAmount ? (infoPrice.totalAmount / 100) : 0}€</span>
              </h3>
            </div>
          ) : <FormattedMessage id='payment_free' />)}
    </CardBlock>)
}

export default OverviewRoyalties
