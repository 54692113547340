import React, { FC } from 'react'
import {
  CardBlock, DateUtils,
  Transaction,
  ModelDeposit, ReproductionLightDeposit,
  Publication
} from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'

interface PublicationsBlockProps {
  transaction: Transaction
}

const PublicationsBlock: FC<PublicationsBlockProps> = ({
  transaction
}) => {
  return (
    <CardBlock
      header={<FormattedMessage id='overview_deposit_publications' />}
      shadow
    >
      <div className='row'>
        <table className='table'>
          <thead>
            <tr>
              <th scope='col' className='border-0'>
                <span><FormattedMessage id='overview_deposit_publications_num_repro' /></span>
              </th>
              <th scope='col' className='border-0'>
                <span><FormattedMessage id='overview_deposit_publications_id_publication' /></span>
              </th>
              <th scope='col' className='border-0'>
                <span><FormattedMessage id='overview_deposit_publications_date' /></span>
              </th>
              <th scope='col' className='border-0'>
                <span><FormattedMessage id='overview_deposit_publications_bopi' /></span>
              </th>
            </tr>
          </thead>
          <tbody>
            {
              transaction.file.models && transaction.file.models.map((model: ModelDeposit, indexModel) => (
                model.reproductions.map((reproduction : ReproductionLightDeposit, indexReproduction) => {
                  return (
                    reproduction.publications && reproduction.publications.map((publication : Publication, index: number) => (
                      <tr key={index}>
                        <td>{`${indexModel + 1}.${indexReproduction + 1}`}</td>
                        <td>{publication.idPublication}</td>
                        <td>{DateUtils.formatDateFr(publication.date)}</td>
                        <td>{publication.numBopi}</td>
                      </tr>
                    )))
                })
              ))
            }
          </tbody>
        </table>
      </div>
    </CardBlock>
  )
}

export default PublicationsBlock
