import React, { FC, useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  EventType,
  RequestListTable, SubmitButton,
  FileBrowserField,
  DepositDocument,
  Transaction, FieldStatus, SelectField, DepositDocumentType, CardBlock
} from '@inpi-dm/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import {
  PROROGATION_ADDITIONAL_PARTS_TITLES
} from '../../../../constants/RequestConstants'
import TransactionService from '../../../../services/transaction/TransactionService'
import Message from '../../../../constants/Message'
import StoreService from '../../../../services/StoreService'

interface DocumentAdditionalPartsProps {
  idLabelBloc?: string,
  procedureType?: string,
  transaction: Transaction,
  fieldStatus: FieldStatus[],
  additionalParts: DepositDocument[],
  setAdditionalParts: (additionalParts: DepositDocument[]) => void,
}

const DocumentAdditionalParts: FC<DocumentAdditionalPartsProps> = ({
  idLabelBloc,
  procedureType,
  transaction,
  fieldStatus,
  additionalParts,
  setAdditionalParts
}) => {
  const [typeDocumentList, setTypeDocumentList] = useState<DepositDocumentType[]>()

  useEffect(() => {
    // Récupération des types de documents
    TransactionService.getDocumentTypes(procedureType || transaction.procedureType).then((result) => {
      setTypeDocumentList(result)
    })

    return () => {
      TransactionService.cancelRequest()
    }
  }, [transaction.procedureType])

  /**
   * Vérifie si on doit afficher ou non ce document
   * @param document
   */
  const isDocumentToShow = (document: DepositDocument) => {
    let optionalCondition = true
    if (document.additionalType && procedureType) {
      optionalCondition = document.additionalType === procedureType
    }
    return !document.toDelete && document.type && typeDocumentList && optionalCondition &&
        typeDocumentList.map((type: DepositDocumentType) => type.typeName).includes(document.type)
  }

  /**
     * Ajout d'une nouvelle pièce complémentaire
     */
  const handleNewAdditionalPart = () => {
    const newDocument = {
      type: undefined,
      documentInternalName: undefined,
      additionalType: undefined,
      needPost: true
    }
    setAdditionalParts([...additionalParts, newDocument])
  }

  /**
     * Suppression d'une pièce complémentaire pas encore postée
     * @param index
     */
  const deleteNewAdditionalPart = (index: number) => {
    const newDocs = [...additionalParts]
    newDocs.splice(index, 1)
    setAdditionalParts(newDocs)
  }

  /**
   * Suppression d'un document déjà posté
   * @param index
   */
  const deleteDoc = async (index: number) => {
    return await TransactionService.deleteDocumentFile(transaction.id, transaction.documents[index].internalName).then((transactionWithDocument: Transaction) => {
      StoreService.changeStore(transactionWithDocument.procedureType, { ...transaction, documents: transactionWithDocument.documents })
    }
    )
  }

  /**
     * Modification d'une pièce complémentaire
     * @param event
     * @param index
     */
  const handleChangeAdditionalPart = async (event: EventType, index: number) => {
    const { value } = event.target
    const newDocument: DepositDocument = value !== null ? {
      name: value.name,
      file: value,
      additionalType: procedureType,
      needPost: true
    } : { needPost: true }

    const documents = [...additionalParts]
    documents[index] = { ...documents[index], ...newDocument }
    setAdditionalParts(documents)
  }

  return (
    <CardBlock
      shadow
      className='mt-3'
      bodyClassName='pt-3'
      header={<FormattedMessage id={idLabelBloc || 'prorogation_additional_parts'} />}
    >
      <RequestListTable
        tableTitle={PROROGATION_ADDITIONAL_PARTS_TITLES}
        showPagination={false}
      >
        <thead>
          {transaction.documents && transaction.documents.length > 0 && transaction.documents.map((document, indexDocument) => {
            return isDocumentToShow(document)
              ? (
                <tr key={`documents-${indexDocument}`}>
                  <td>{document.name}</td>
                  <td>{document.type}</td>
                  <td>
                    <SubmitButton
                      className='btn-icon-secondary'
                      onClick={() => deleteDoc(indexDocument)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </SubmitButton>
                  </td>
                </tr>
              ) : null
          }
          )}
          {additionalParts.map((additionalPart, index) => (
            <tr key={index}>
              <td className='is-validated'>
                <FileBrowserField
                  label=''
                  inputId={`file-${index}`}
                  value={additionalPart.file ? [additionalPart.file] : []}
                  onChange={(e) => handleChangeAdditionalPart(e, index)}
                  maxNumberFile={1}
                  acceptApplication='application/pdf'
                  required
                  butonLabel={
                    <div className='form-control placeholder'>
                      <FormattedMessage id='button_find_file' />
                    </div>
                  }
                  fieldStatus={fieldStatus[index]}
                />
              </td>
              <td className='is-validated'>
                <SelectField
                  label=''
                  placeholder={Message.placeholder_select}
                  inputId={`type-${index}`}
                  value={additionalPart.type}
                  onChange={(e) => {
                    const newAdditionalParts = [...additionalParts]
                    newAdditionalParts[index].type = e.target.value
                    setAdditionalParts(newAdditionalParts)
                  }}
                  options={typeDocumentList?.map(type => ({
                    label: type.typeName,
                    value: type.typeName
                  })) || []}
                  required
                  fieldStatus={fieldStatus[index]}
                />
              </td>
              <td>
                <SubmitButton
                  className='btn-icon-secondary'
                  onClick={() => deleteNewAdditionalPart(index)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </SubmitButton>
              </td>
            </tr>
          ))}
        </thead>
        <td className='border-0'>
          <SubmitButton className='btn btn-link font-weight-bold text-primary' onClick={handleNewAdditionalPart}>
            <FontAwesomeIcon icon={faPlus} size='xs' className='mr-2' />
            <FormattedMessage id='request_add_document_button' />
          </SubmitButton>
        </td>
      </RequestListTable>
    </CardBlock>
  )
}

export default DocumentAdditionalParts
