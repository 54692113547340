import React, { FC, useEffect, useState } from 'react'
import Requests from './Requests'
import {
  Transaction,
  FilAriane,
  STATUS_DEPOSIT,
  SubmitButton
} from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'
import { Link, useHistory } from 'react-router-dom'
import { RequestType } from '../../interfaces/RequestInterfaces'
import OverviewDeposit from '../deposit/form/overview/OverviewDeposit'
import DepositService from '../../services/transaction/DepositService'
import DownloadLink from '../deposit/download/DownloadLink'
import CorrectionService from '../../services/transaction/CorrectionService'
import { REPRODUCTIONS_VIEW_MODE, REQUEST_STATUS_CORRECTABLE } from '../../constants/RequestConstants'
import Message from '../../constants/Message'
import PublicationsBlock from './PublicationsBlock'
import { PAYMENT_METHOD } from '../../constants/DepositConstants'
import TransactionService from '../../services/transaction/TransactionService'
import RecordService from '../../services/record/RecordService'

interface OneRequestProps {
  type: RequestType,
  id: string
}

const OneRequest: FC<OneRequestProps> = ({
  type,
  id
}) => {
  const history = useHistory()
  const [transaction, setTransaction] = useState<Transaction>()
  const [reproductionMode, setReproductionMode] = useState(REPRODUCTIONS_VIEW_MODE.OVERVIEW)
  const [reload, setReload] = useState(false)

  /**
   * Met à jour le dépôt affiché
   */
  const updateDeposit = () => {
    if (type.isTitle) {
      RecordService.getRecord(id)
        .then((record) => setTransaction(RecordService.recordToDepositModel(record)))
        .catch(() => {
          history.replace(`/liste-demandes/${type.urlParam}`)
        })
    } else {
      TransactionService.getTransaction(id)
        .then(setTransaction)
        .catch(() => {
          history.replace(`/liste-demandes/${type.urlParam}`)
        })
    }

    return TransactionService.cancelRequest
  }

  useEffect(updateDeposit, [id, history, type, reload])

  /**
   * Prépare et se rend sur le formulaire d'édition d'un dépot
   */
  const editDeposit = () => {
    history.push(`/depots/${transaction?.id}/edition`)
  }

  /**
   * Prépare et se rend sur le formulaire de demande de correction
   * du dépôt
   */
  const requestDepositCorrection = () => {
    history.push(`/depots/${transaction?.id}/demande-correction`)
  }

  /**
   * Création d'une inscription à partir du titre
   */
  const createInscription = () => {
    history.push(`/inscriptions/nouveau?from=${transaction?.id}`)
  }

  /**
   * Création d'un document officiel à partir du titre
   */
  const createOfficialDocument = () => {
    history.push(`/documents-officiels/nouveau?from=${transaction?.id}`)
  }

  /**
   * Création d'un document officiel à partir du titre
   */
  const createAppeal = () => {
    history.push(`/releves-de-decheance/nouveau?from=${transaction?.id}`)
  }

  return (
    <div>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <Link to={`/liste-demandes/${type.urlParam}`}><FormattedMessage id={type.label} /></Link>
        <span>
          <FormattedMessage id='request_title' />
          {transaction && (transaction.numNat ? transaction.numNat : transaction.id)}
        </span>
      </FilAriane>
      <Requests currentIdBlock={type.id}>
        {transaction &&
          <div>
            <header className='mx-3 mb-4 d-flex justify-content-between'>
              <h1><FormattedMessage id='request_title' />{transaction.numNat ? transaction.numNat : transaction.id}</h1>
              {!type.isTitle
                ? (
                  <DownloadLink
                    id={transaction.id}
                    generate={transaction.status === STATUS_DEPOSIT.DRAFT}
                    name={`${Message.overview_deposit_pdf_title}.pdf`}
                    internalName={DepositService.getLastRecapPDFName(transaction)}
                  />
                )
                : (
                  <div className='justify-content-end d-flex'>
                    <SubmitButton className='btn btn-link text-primary mr-2' onClick={createAppeal}>
                      <FormattedMessage id='do_appeal' />
                    </SubmitButton>
                    <SubmitButton className='btn btn-link text-primary mr-2' onClick={createInscription}>
                      <FormattedMessage id='do_inscription' />
                    </SubmitButton>
                    <SubmitButton className='btn btn-link text-primary' onClick={createOfficialDocument}>
                      <FormattedMessage id='do_official_document' />
                    </SubmitButton>
                  </div>
                )}
            </header>
            <OverviewDeposit
              transaction={transaction}
              modeReproduction={reproductionMode}
              onChangeModeReproduction={setReproductionMode}
              onUpdateDeposit={updateDeposit}
              setReload={() => setReload(!reload)}
              depositView={type.isTitle}
            />
            {type.isTitle &&
              <div className='mb-3'>
                <PublicationsBlock transaction={transaction} />
              </div>}
            <div className='row justify-content-center mt-4'>
              {transaction?.status === STATUS_DEPOSIT.DRAFT && (
                <div className='col-12 col-md-4'>
                  <button
                    className='btn btn-block btn-outline-primary'
                    onClick={editDeposit}
                    disabled={!!(transaction?.payments?.find(payment => payment.type === PAYMENT_METHOD.MANAGEABLE_MEMORY && payment.status === 'pending'))}
                  >
                    <FormattedMessage id='request_go_edit' />
                  </button>
                </div>
              )}
              {
                REQUEST_STATUS_CORRECTABLE.includes(transaction.status) && !CorrectionService.findCorrectionInReview(transaction) &&
              (
                <div className='col-12 col-md-6'>
                  <button
                    className='btn btn-block btn-outline-primary'
                    onClick={requestDepositCorrection}
                  >
                    <FormattedMessage id='request_correction_button' />
                  </button>
                </div>
              )
              }
            </div>
          </div>}
      </Requests>
    </div>
  )
}

export default OneRequest
