import React, { useState, useEffect, FC } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux'
import {
  ErrorField,
  containsErrors,
  SubmitButton,
  Transaction,
  Title, APPEAL_TYPE, FieldStatus
} from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'
import OverviewRecords from '../../../title/OverviewRecords'
import { storeAppealFieldStatusUpdate, storeAppealUpdate } from '../../../../store/appeal/appealActions'
import { AppealFieldStatus } from '../../../../interfaces/AppealInterfaces'
import SelectAppealRecordsForm from './SelectAppealRecordsForm'

interface SelectRecordsProps {
  validateMandatory : (title : Title) => FieldStatus
}

const SelectAppealRecords : FC<SelectRecordsProps> = ({ validateMandatory }) => {
  const dispatch = useDispatch()
  const appeal:Transaction = useSelector((state:RootStateOrAny) => state.appeal)
  const appealFieldStatus:AppealFieldStatus = useSelector((state:RootStateOrAny) => state.appealFieldStatus)

  const [curentIndexTitle, setCurentIndexTitle] = useState((appeal.appeal?.titles && appeal.appeal.titles.length) || 0)
  const [showForm, setShowForm] = useState(!(appeal.appeal?.titles && appeal.appeal.titles.length > 0) || true)

  useEffect(() => {
    if (appeal.appeal && appeal.appeal.titles.length > 0) {
      setCurentIndexTitle(-1)
      setShowForm(false)
    }
  }, [appeal.appeal?.titles])

  /**
   * Supprimer un Title du state et store
   * @param index
   */
  const deleteOneTitle = (index:number) => {
    const updateTitle = appeal.appeal?.titles ? [...appeal.appeal.titles] : []
    updateTitle.splice(index, 1)
    dispatch(storeAppealUpdate({ appeal: { ...appeal.appeal, titles: updateTitle } }))
    setCurentIndexTitle(-1)

    const updateTitleFieldStatus = appealFieldStatus.titles ? [...appealFieldStatus.titles] : []
    updateTitleFieldStatus.splice(index, 1)
    dispatch(storeAppealFieldStatusUpdate({ titles: updateTitleFieldStatus }))
  }

  /**
   *
   * Mise a jours du state et store
   * @param title
   */
  const onSubmit = (title:Title) => {
    const updateTitle = appeal.appeal?.titles ? [...appeal.appeal.titles] : []
    updateTitle[curentIndexTitle] = title
    dispatch(storeAppealUpdate({ appeal: { ...appeal.appeal, titles: updateTitle } }))

    const updateTitleFieldStatus = appealFieldStatus.titles ? [...appealFieldStatus.titles] : []
    updateTitleFieldStatus[curentIndexTitle] = validateMandatory(title)
    dispatch(storeAppealFieldStatusUpdate({ titles: updateTitleFieldStatus }))

    setCurentIndexTitle(updateTitle.length)
    setShowForm(false)
  }

  return (
    <>
      <div className='row'>
        <header className='col-8 mb-4'>
          <h1><FormattedMessage id='records_prorogation_title' /></h1>
          <span className='subtitle'><FormattedMessage id='records_appeal_subtitle' /></span>
        </header>
        <InternalReferenceField
          value={appeal.internalReference}
          procedureType={APPEAL_TYPE.value}
          className='col-4 mb-4'
        />
      </div>
      {
        curentIndexTitle > 0 &&
          <OverviewRecords
            titles={appeal.appeal?.titles}
            changeTitle={(index: number) => {
              setShowForm(true)
              setCurentIndexTitle(index)
            }}
            deleteTitle={deleteOneTitle}
            isBeforeForm
            currentIndex={curentIndexTitle}
            fieldStatus={appealFieldStatus.titles}
            isOriginShown={false}
          />
      }
      {
        showForm &&
          <SelectAppealRecordsForm
            initialTitle={appeal.appeal && appeal.appeal.titles[curentIndexTitle]}
            initialFieldStatus={appealFieldStatus.titles && { ...appealFieldStatus.titles[curentIndexTitle] }}
            onSubmit={onSubmit}
          />
      }
      {
        appeal.appeal && appeal.appeal.titles?.length > curentIndexTitle + 1 &&
          <OverviewRecords
            titles={appeal.appeal?.titles}
            changeTitle={(index: number) => {
              setShowForm(true)
              setCurentIndexTitle(index)
            }}
            deleteTitle={deleteOneTitle}
            isBeforeForm={false}
            currentIndex={curentIndexTitle}
            fieldStatus={appealFieldStatus.titles}
            isOriginShown={false}
          />
      }
      {
        !showForm &&
          <div>
            <hr />
            <div className='d-flex align-items-center justify-content-between'>
              <div className='mr-4'>
                <SubmitButton
                  className='btn-outline-primary'
                  onClick={() => {
                    setShowForm(true)
                    setCurentIndexTitle(appeal.appeal?.titles.length || 0)
                  }}
                >
                  <FormattedMessage id='records_inscription_add' />
                </SubmitButton>
              </div>
            </div>
          </div>
      }
      {
        appealFieldStatus.titles && appealFieldStatus.titles.length > 0 && appealFieldStatus.titles[0] && containsErrors(appealFieldStatus.titles[0]) &&
          <ErrorField message={appealFieldStatus.titles[0].notFound} />
      }
    </>
  )
}

export default withRouter(SelectAppealRecords)
