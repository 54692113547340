import http from '../../network/http-common'
import Message from '../../constants/Message'
import { createIntl } from 'react-intl'
import { FoUserResponse } from '../../interfaces/User'
import { ContactMessage, ContactRequest } from '../../interfaces/ContactInterfaces'
import { toast } from 'react-toastify'
import { DATE_WITH_HOUR, DateUtils } from '@inpi-dm/components'
import { MailObject } from '../../constants/ContactConstants'

/**
 * Classe permettant de gérer les demandes de contact FO
 */
class ContactService {
  constructor () {
    this.intl = createIntl({ locale: 'fr', messages: Message })
  }

    /** Envoie du mail */
    sendMessage = async (user: FoUserResponse, message: ContactMessage) => {
      const messageRequest: ContactRequest = {
        user: user,
        object: message.object,
        content: message.content,
        requestDate: DateUtils.formateDateToFormat(new Date(), DATE_WITH_HOUR)
      }
      try {
        await http.post('/api/contacts',
          messageRequest
        ).then(() => {
          toast.success(this.intl.formatMessage({ id: 'contact_success_message' }))
        })
        return Promise.resolve()
      } catch (error) {
        toast.error(error.message)
        return Promise.reject(error)
      }
    }

    /**
     * Récupération des objets de mail définis dans le BO
     * @returns Promise<MailObject[]
     */
    getMailObjects = async (): Promise<MailObject[]> => {
      try {
        return await http.get('/api/contact-mail-objects')
      } catch (error) {
        return Promise.resolve([])
      }
    }
}

export default new ContactService()
