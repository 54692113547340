import http from 'network/http-common'
import { toast } from 'react-toastify'
import {
  Transaction
} from '@inpi-dm/components'
import { UpdateAppealBoRequest } from '../../interfaces/AppealInterfaces'
import store from 'front-office/src/store/store'
import { storeAppealUpdate } from 'front-office/src/store/appeal/appealActions'

/**
 * Classe permettant de faire des opérations sur un relevé de déchéance
 */
class AppealService {
  /**
   * Appel serveur pour mettre à jour la prorogation BO en bdd
   * @param id id de la transaction
   * @param transactionRequest
   * @returns Promise<Transaction>
   */
  updateBoAppeal = async (id: string, transactionRequest: UpdateAppealBoRequest): Promise<Transaction> => {
    try {
      const appeal: Transaction = await http.put(`/api/appeals/bo/${id}`,
        transactionRequest
      )
      return Promise.resolve(appeal)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Appel serveur pour mettre à jour le relevé de déchéance en bdd
   * @param id id de la transaction
   * @param patches liste des modifications
   * @returns Promise<Transaction|null>
   */
  updateAppeal = async (id: string, patches: any): Promise<Transaction|null> => {
    try {
      const appeal: Transaction = await http.put(`/api/appeals/${id}`,
        patches
      )
      store.dispatch(storeAppealUpdate(appeal))
      return Promise.resolve(appeal)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Appel serveur afin de connaitre les information concernant les types de relevé de déchéance
   */
  getAppealTypes = async (): Promise<any[]|null> => {
    try {
      return await http.get('/api/appeals/types')
    } catch (error) {
      return Promise.resolve(null)
    }
  }
}

export default new AppealService()
