import React from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import Message from '../../constants/Message'

interface SubtitleIntervenantProps {
  idText?: string
}

const SubtitleIntervenant = (props: SubtitleIntervenantProps) => {
  return (
    <IntlProvider locale='fr' messages={Message}>
      <span className='small'>
        <FormattedMessage
          id={props.idText}
          values={{ a: (...chunks) => (<a href={process.env.REACT_APP_URL_HELP} target='_blank' rel='noopener noreferrer'>{chunks}</a>) }}
        />
      </span>
    </IntlProvider>
  )
}

export default SubtitleIntervenant
