import { useDispatch, useSelector, RootStateOrAny } from 'react-redux'
import { buildEventType, CheckboxField, EventType } from '@inpi-dm/components'
import React, { FC, useEffect, useState } from 'react'
import { storeProrogationUpdate } from '../../../../store/prorogation/prorogationActions'
import { FormattedMessage } from 'react-intl'
import OverviewProrogation from './OverviewProrogation'
import OverviewRoyalties from '../../../payment/OverviewRoyaltiesProro'
import { Payment } from '../../../../interfaces/DepositInterfaces'
import ProrogationService from '../../../../services/transaction/ProrogationService'

interface OverviewFormProrogationProps {
  hasSupplementExcluded?: boolean,
  setHasSupplmentExluded?: (hasSupplementExcluded: boolean) => void
}

const OverviewFormProrogation: FC<OverviewFormProrogationProps> = ({
  hasSupplementExcluded,
  setHasSupplmentExluded
}) => {
  const dispatch = useDispatch()
  const prorogation = useSelector((state: RootStateOrAny) => state.prorogation)
  const fieldStatus = useSelector((state: RootStateOrAny) => state.prorogationFieldStatus)
  const [infoPrice, setInfoPrice] = useState<Payment>()
  const [isPaymentLoading, setIsPaymentLoading] = useState(false)

  const getPrices = () => {
    ProrogationService.getPayment(prorogation.id, hasSupplementExcluded)
      .then((payment) => {
        payment && setInfoPrice(payment)
        setIsPaymentLoading(false)
      })
  }

  /**
     * Enregistrement des données dans le store à la saisie
     * @param event
     */
  const handleChange = (event: EventType) => {
    const { value } = event.target

    dispatch(storeProrogationUpdate({
      overviewSimilarInformation: value
    }))
  }

  useEffect(() => {
    // Reset case à cocher
    handleChange(buildEventType('overviewSimilarInformation', false))
    getPrices()

    return () => {
      ProrogationService.cancelRequest()
    }
  }, [])

  return (
    <div className='is-validated'>
      {prorogation &&
        <>
          <div className='row mx-3 d-flex justify-content-between'>
            <h1><FormattedMessage id='overview_deposit_form_title' /></h1>
          </div>
          <div className='col mt-3'>
            <OverviewProrogation transaction={prorogation} />
            <div className='row'>
              <div className='col-12 mb-3'>
                <OverviewRoyalties infoPrice={infoPrice} isPaymentLoading={isPaymentLoading} hasSupplementExcluded={hasSupplementExcluded} setHasSupplmentExluded={setHasSupplmentExluded} />
              </div>
            </div>

            <div className='d-flex mt-3'>
              <CheckboxField
                className='mx-auto'
                labelClassName='font-weight-bold'
                inputId='overviewSimilarInformation'
                label={<FormattedMessage id='overview_deposit_form_checkbox_similar_information' />}
                checked={prorogation.overviewSimilarInformation}
                onChange={handleChange}
                fieldStatus={fieldStatus.overviewSimilarInformation}
              />
            </div>
          </div>
        </>}
    </div>
  )
}

export default OverviewFormProrogation
