import store from '../../../../../store/store'
import { containsErrors, FieldStatus, IntervenantValidator, isFilled, PERSONNE_PHYSIQUE } from '@inpi-dm/components'
import { createIntl } from 'react-intl'
import Message from '../../../../../constants/Message'
import { sirenValidity } from '@inpi-dm/components/src/utils/validationUtils'
import { storeAppealFieldStatusUpdate } from '../../../../../store/appeal/appealActions'

class DepositorValidator {
  constructor () {
    this.intl = createIntl({ locale: 'fr', messages: Message })
  }

  validateOnChangeStep = () => {
    const appeal = store.getState().appeal

    let fieldStatus = IntervenantValidator.validateIntervenant(appeal.depositor)
    if (appeal.depositor?.type === PERSONNE_PHYSIQUE.value) {
      return this.dispatchAndValidate(fieldStatus)
    } else {
      if (appeal.depositor?.siren && !isFilled(fieldStatus.siren)) {
        const errorMessage = sirenValidity(appeal.depositor)
        if (errorMessage) {
          fieldStatus = {
            ...fieldStatus,
            siren: this.intl.formatMessage({ id: errorMessage })
          }
        }
      }
      return this.dispatchAndValidate(fieldStatus)
    }
  }

  dispatchAndValidate = (fieldStatus: FieldStatus): boolean => {
    store.dispatch(storeAppealFieldStatusUpdate({
      depositor: fieldStatus
    }))
    return !containsErrors(fieldStatus)
  }
}

export default new DepositorValidator()
