import React, { FC, useEffect, useState, useCallback } from 'react'
import Requests from './Requests'
import debounce from 'lodash.debounce'
import {
  ModalComponent,
  Transaction,
  FilAriane,
  ORDER_ASC,
  ORDER_DESC,
  RequestListTable,
  ROWS,
  ROWS_25,
  NOTIF_STATUS,
  STATUS_ON_BOPI_PUBLISHED_REQUEST,
  TableOrderBy,
  NoResult, SubmitButton
} from '@inpi-dm/components'
import { FormattedMessage, injectIntl, WrappedComponentProps, useIntl } from 'react-intl'
import { Link, useHistory } from 'react-router-dom'
import RequestListTableBody from './RequestListTableBody'
import RequestListTableHeader from './RequestListTableHeader'
import { RequestType } from '../../interfaces/RequestInterfaces'
import TransactionService from '../../services/transaction/TransactionService'
import RecordService from '../../services/record/RecordService'
import ConfigurationService from '../../services/transaction/ConfigurationService'

interface DepositRequestListProps extends WrappedComponentProps {
  type: RequestType
}

const DEFAULT_SORT_NOTIF = {
  field: 'notifications.deadline',
  order: ORDER_ASC
}

const DEFAULT_SORT = {
  field: 'createdAt',
  order: ORDER_DESC
}

const RequestList: FC<DepositRequestListProps> = ({ type }) => {
  const history = useHistory()
  const [pageSize, setPageSize] = useState(ROWS_25.value)
  const [page, setPage] = useState(0)
  const [maxPage, setMaxPage] = useState(1)
  const [list, setList] = useState<Transaction[]|undefined>(undefined)
  const [search, setSearch] = useState(undefined)
  const [procedure, setProcedure] = useState(undefined)
  const [subProcedureType, setSubProcedureType] = useState(undefined)
  const [sortBy, setSortBy] = useState<TableOrderBy|undefined>(type.isNotif ? DEFAULT_SORT_NOTIF : DEFAULT_SORT)
  const [toDelete, setToDelete] = useState<string>(undefined)
  const [selectedElements, setSelectedElements] = useState<string[]>([])
  const [subtitle, setSubtitle] = useState<string>('')
  const intl = useIntl()

  useEffect(() => {
    updateSearch()

    return () => {
      TransactionService.cancelRequest()
    }
  }, [pageSize, procedure, page, sortBy, subProcedureType])

  const fetchDraftDisplayPeriod = async () => {
    const draftDisplayPeriod = await ConfigurationService.getDraftDisplayPeriod()
    if (draftDisplayPeriod != null && draftDisplayPeriod.value) {
      setSubtitle(intl.formatMessage({ id: 'dashboard_projets_sauvegardes_subtitle' }, { draftDisplayPeriodValue: draftDisplayPeriod.value }))
    }
  }

  useEffect(() => {
    if (type.id === 'projets_sauvegardes') { fetchDraftDisplayPeriod() }
  }, [])

  /**
   * Effectue une nouvelle recherche
   */
  const updateSearch = () => {
    if (type.isTitle) {
      searchRecord()
    } else {
      searchTransactions()
    }
  }

  /**
   * Recherche les transactions
   */
  const searchTransactions = () => {
    let params = {
      pageSize,
      page,
      procedure,
      subProcedureType: subProcedureType ? [subProcedureType] : null,
      search,
      order: sortBy?.order,
      sortBy: sortBy?.field
    }

    if (type.statusFiltres.includes(NOTIF_STATUS.NOTIF_STATUS_SENT) || type.statusFiltres.includes(NOTIF_STATUS.NOTIF_STATUS_WAITING_ANSWER)) {
      params = { ...params, notificationStatus: type.statusFiltres }
    } else if (type.statusFiltres.includes(STATUS_ON_BOPI_PUBLISHED_REQUEST)) {
      params = { ...params, hasOnBopiPublishedRequest: true }
    } else {
      params = { ...params, status: type.statusFiltres }
    }

    if (type.id === 'projets_sauvegardes') {
      params = { ...params, draftDisplayPeriod: true }
    } else if (type.id === 'documents_fournis') {
      params = { ...params, hasAvailableDocument: true }
    }

    TransactionService.searchTransaction(params)
      .then(result => {
        setMaxPage(result.maxPage)
        setPage(result.page)
        setPageSize(result.pageSize)
        setList(result.transactions || [])
      })
  }

  /**
   * Recherche les titres
   */
  const searchRecord = () => {
    const params = {
      pageSize,
      page,
      search,
      order: sortBy?.order,
      sortBy: sortBy?.field,
      isProrogeable: type.isForProrogation
    }

    RecordService.searchRecords(params)
      .then(result => {
        setMaxPage(result.maxPage)
        setPage(result.page)
        setPageSize(result.pageSize)
        setList(result.records.map(record => RecordService.recordToDepositModel(record)) || [])
      })
  }

  const delayedSearch = useCallback(debounce(updateSearch, 500), [search])
  useEffect(() => {
    // Pas besoin de lancer la recherche la première fois
    if (search !== undefined) {
      delayedSearch()
    }
    return delayedSearch.cancel
  }, [delayedSearch])

  const handleChangeOrderBy = (field?: string) => {
    let updatedSort

    if (field) {
      if (!sortBy || sortBy.field !== field) {
        updatedSort = {
          field,
          order: ORDER_ASC
        }
      } else if (sortBy.order === ORDER_ASC) {
        updatedSort = {
          field,
          order: ORDER_DESC
        }
      }

      setPage(0)
    }

    setSortBy(updatedSort)
  }

  /**
   * Sélectionne/désélectionne des lignes (pour selections des titres pour prorogation)
   * @param id
   */
  const selectOneElem = (id: string) => {
    let selectedUpdate = [...selectedElements]
    // si l'id est déjà présent alors on l'enlève sinon on l'ajoute
    if (selectedUpdate.includes(id)) {
      selectedUpdate = selectedUpdate.filter(idSelected => idSelected !== id)
    } else {
      selectedUpdate.push(id)
    }
    setSelectedElements(selectedUpdate)
  }

  /**
   * Suppression d'un dossier brouillon
   */
  const onDelete = () => {
    return TransactionService.deleteTransaction(toDelete).then(() => {
      updateSearch()
      setToDelete(undefined)
    })
  }

  const deleteContent = () => {
    return (<FormattedMessage id='request_modal_delete' />)
  }

  /**
   * Création d'une prorogation à partir de titres
   */
  const createProrogation = () => {
    history.push(`/prorogations/nouveau?from=${selectedElements}`)
  }

  /**
   * Création d'une demande de relevé de déchéance à partir de titres
   */
  const createAppeal = () => {
    history.push(`/releves-de-decheance/nouveau?from=${selectedElements}`)
  }

  /**
   * Création d'une demande de document officiel à partir de titres
   */
  const createOfficialDocument = () => {
    history.push(`/documents-officiels/nouveau?from=${selectedElements}`)
  }

  /**
   * Création d'une demande d'inscription à partir de titres
   */
  const createInscription = () => {
    history.push(`/inscriptions/nouveau?from=${selectedElements}`)
  }

  return (
    <div>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id={type.label} /></span>
      </FilAriane>
      <Requests
        currentIdBlock={type.id}
      >
        <div>
          <header className='ml-3 mb-4 d-flex justify-content-between'>
            <div>
              <h1><FormattedMessage id={type.label} /></h1>
              <div className='subtitle'>{subtitle}</div>
            </div>
            {
              type.isForProrogation &&
                <SubmitButton
                  className='btn-outline-primary'
                  disabled={selectedElements.length === 0}
                  onClick={createProrogation}
                >
                  <FormattedMessage id='request_create_prorogation' />
                </SubmitButton>
            }
            {
              type.isTitle && !type.isForProrogation && (
                <div>
                  <SubmitButton
                    className='btn-outline-primary mr-2'
                    disabled={selectedElements.length === 0}
                    onClick={createAppeal}
                  >
                    <FormattedMessage id='request_create_appeal' />
                  </SubmitButton>
                  <SubmitButton
                    className='btn-outline-primary mr-2'
                    disabled={selectedElements.length === 0}
                    onClick={createOfficialDocument}
                  >
                    <FormattedMessage id='request_create_official_document' />
                  </SubmitButton>
                  <SubmitButton
                    className='btn-outline-primary'
                    disabled={selectedElements.length === 0}
                    onClick={createInscription}
                  >
                    <FormattedMessage id='request_create_inscription' />
                  </SubmitButton>
                </div>
              )
            }
          </header>
          <RequestListTableHeader
            onSearch={(event) => setSearch(event.target.value)}
            valueSearch={search}
            onSelectProcedure={(event) => setProcedure(event.target.value)}
            valueSelectProcedure={procedure}
            onSelectSubProcedureType={(event) => setSubProcedureType(event ? event.target.value : undefined)}
            valueSelectSubProcedureType={subProcedureType}
            isTitle={type.isTitle}
            isDocument={type.isDocument}
          />
          {list && list.length > 0
            ? (
              <RequestListTable
                tableTitle={type.headers}
                onSort={handleChangeOrderBy}
                totalPage={maxPage}
                actualPage={page + 1}
                onChangePagination={(page) => setPage(page - 1)}
                nbShown={ROWS}
                valueSelect={pageSize}
                onChangeSelect={(event) => {
                  setPage(0)
                  setPageSize(parseInt(event.target.value, 10))
                }}
                sortBy={sortBy}
              >
                <RequestListTableBody requestType={type} list={list} onDelete={setToDelete} selectedLines={selectedElements} selectOneElem={selectOneElem} />
              </RequestListTable>
            )
            : (list && list.length === 0 && <div className='mt-2'><NoResult /></div>)}
        </div>
      </Requests>
      <ModalComponent
        title={<FormattedMessage id='button_delete' />}
        customContent={deleteContent}
        handleClose={() => setToDelete(undefined)}
        show={toDelete}
        onClick={onDelete}
      />
    </div>
  )
}

export default injectIntl(RequestList)
