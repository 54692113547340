export const SELECT_TRANSACTION_TABLE_HEADER = [
  {
    label: 'N°',
    className: 'text-gris text-left border-0 w-40'
  }, {
    label: 'Date de demande',
    className: 'text-gris text-left border-0 w-50'
  }, {
    label: '',
    className: 'border-0 w-10'
  }
]
