import React, { FC, useEffect } from 'react'
import {
  CardBlock,
  DepositDocument,
  DOCUMENT_COMMUNICABILITIES,
  DOCUMENT_STATUS,
  EventType,
  isFilled,
  Transaction,
  ErrorField
} from '@inpi-dm/components'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { FILE_PROOF_ITEMS } from '../../../../constants/DocumentConstants'
import { useSelector, RootStateOrAny } from 'react-redux'
import InscriptionDocumentsItem from './InscriptionDocumentsItem'
import { InscriptionDocumentsInterface } from '../../../../interfaces/InscriptionInterfaces'
import { cloneDeep } from 'lodash'

interface InscriptionDocumentsBlockProps extends WrappedComponentProps {
  transaction: Transaction,
  inscriptionProofDocuments: DepositDocument[][],
  setInscriptionProofDocuments: (inscriptionProofDocuments: DepositDocument[][]) => void,
}

const InscriptionDocumentsBlock: FC<InscriptionDocumentsBlockProps> = ({ transaction, inscriptionProofDocuments, setInscriptionProofDocuments }) => {
  const fieldStatus = useSelector((state:RootStateOrAny) => state.inscriptionFieldStatus)

  useEffect(() => {
    if (transaction.documents) {
      const documents = []
      FILE_PROOF_ITEMS.forEach(item => {
        if (item.inscriptionTypes.includes(transaction.subProcedureType)) {
          documents.push(transaction.documents.filter(doc => doc.type === item.type))
        }
      })
      // Initialisation avec les pièces déjà présentes dans l'inscription
      setInscriptionProofDocuments(documents)
    }
  }, [transaction.documents])

  /**
   * Permet de mettre à jour le fichier, la communicabilité ou la description d'un document
   * @param document
   * @param file
   * @param communicability
   * @param description
   */
  const updateFileInformation = (document: DepositDocument,
    file?: any|undefined,
    communicability?: string|undefined,
    description?: string|undefined): DepositDocument => {
    // MàJ du fichier tranmis
    if (file) {
      document.name = file.name
      document.format = file.format
      document.file = file
    } else if (communicability !== undefined) { // MàJ de la communicabilité
      document.communicability = communicability
    } else {
      document.description = description
    }
    return document
  }

  /**
   * Enregistre un document justificatif
   * @param proofType
   * @param indexItem
   * @param fileEventType
   * @param communicabilityEventType
   * @param index
   * @param descriptionEventType
   */
  const addProofFile = async (proofType: string,
    indexItem: number,
    index: number,
    fileEventType: EventType|undefined = undefined,
    communicabilityEventType: EventType|undefined = undefined,
    descriptionEventType: EventType|undefined = undefined
  ) => {
    const file = fileEventType && fileEventType.target.value
    const communicability = communicabilityEventType && communicabilityEventType.target.value
    const description = descriptionEventType && descriptionEventType.target.value
    const inscriptionProofDocumentsCopy = [...inscriptionProofDocuments]
    // Si le document existe déjà
    if (inscriptionProofDocuments[indexItem] && inscriptionProofDocuments[indexItem][index]) {
      const updatedDocument = updateFileInformation({ ...inscriptionProofDocuments[indexItem][index] }, file, communicability, description)
      if (updatedDocument.internalName) {
        updatedDocument.toEdit = true
      }
      inscriptionProofDocumentsCopy[indexItem][index] = updatedDocument
      setInscriptionProofDocuments(inscriptionProofDocumentsCopy)
    }
  }

  /**
   * Permet de supprimer le fichier associé au type de pièce justificative
   * @param proofType
   * @param indexItem
   * @param index
   */
  const deleteProofFile = async (proofType: string, indexItem: number, index: number) => {
    if (inscriptionProofDocuments[indexItem] && inscriptionProofDocuments[indexItem][index]) {
      const inscriptionProofDocumentsCopy = cloneDeep(inscriptionProofDocuments)
      if (!inscriptionProofDocuments[indexItem][index].internalName) {
        inscriptionProofDocumentsCopy[indexItem].splice(index, 1)
        setInscriptionProofDocuments(inscriptionProofDocumentsCopy)
      } else {
        inscriptionProofDocumentsCopy[indexItem][index].toDelete = true
        setInscriptionProofDocuments(inscriptionProofDocumentsCopy)
      }
    }
  }

  /**
   * Permet de vérifier si on render le document ou pas (cas où il est supprimé - toDelete à true)
   * @param indexItem
   * @param index
   * @param fileProofItem
   */
  const getDocumentItem = (indexItem: number, index: number, fileProofItem: InscriptionDocumentsInterface) => {
    if (!(inscriptionProofDocuments[indexItem] || [])[index] || !(inscriptionProofDocuments[indexItem] || [])[index].toDelete) {
      return renderDocumentItem(indexItem, index, fileProofItem, inscriptionProofDocuments[indexItem] || [])
    }
    return null
  }

  /**
   * Permet de render une ligne de document correctif
   * @param indexItem
   * @param index
   * @param fileProofItem
   * @param proofFiles
   */
  const renderDocumentItem = (indexItem: number, index: number, fileProofItem: InscriptionDocumentsInterface, proofFiles: DepositDocument[]) => (
    <InscriptionDocumentsItem
      key={`${indexItem}-${index}`}
      fileInputId={`${fileProofItem.fileInputId}${index}`}
      selectInputId={`${fileProofItem.selectInputId}${index}`}
      labelInputId={`${fileProofItem.labelInputId}${index}`}
      handleDelete={() => deleteProofFile(fileProofItem.type, indexItem, index)}
      fileOnChange={(event: EventType) => addProofFile(fileProofItem.type, indexItem, index, event)}
      labelChange={(event: EventType) => addProofFile(fileProofItem.type, indexItem, index, undefined, undefined, event)}
      communicabilityChange={(event: EventType) => addProofFile(fileProofItem.type, indexItem, index, undefined, event)}
      labelValue={!proofFiles[index] ? undefined : proofFiles[index].description}
      communicabilityValue={!proofFiles[index] ? DOCUMENT_COMMUNICABILITIES.COMMUNICABLE : proofFiles[index].communicability}
      files={(!proofFiles[index] || !isFilled(proofFiles[index].name)) ? undefined : [proofFiles[index]]}
      maxFileCount={1}
      hasLabel={fileProofItem.hasLabel}
    />
  )

  /**
   * Permet de créer une nouvelle ligne si on en a besoin
   * @param indexItem
   * @param type
   */
  const createNewItem = (indexItem: number, type: string) => {
    if ((inscriptionProofDocuments[indexItem] || []).every((proofFile) => proofFile.name !== undefined || proofFile.toDelete)) {
      const inscriptionProofDocumentsCopy = [...inscriptionProofDocuments]

      if (!inscriptionProofDocumentsCopy[indexItem]) {
        inscriptionProofDocumentsCopy[indexItem] = []
      }

      inscriptionProofDocumentsCopy[indexItem].push({
        type: type,
        status: DOCUMENT_STATUS.NEW,
        archivability: true,
        communicability: DOCUMENT_COMMUNICABILITIES.COMMUNICABLE,
        internalName: ''
      })
      setInscriptionProofDocuments(inscriptionProofDocumentsCopy)
    }
  }

  /**
   * Render d'un formulaire de document
   * @param indexItem
   * @param fileProofItem
   */
  const renderDocumentForm = (indexItem: number, fileProofItem: InscriptionDocumentsInterface) => {
    createNewItem(indexItem, fileProofItem.type)
    return (
      <div key={indexItem}>
        <div className='col-12 mb-3'>
          <span className='font-weight-bold'>
            <FormattedMessage id={fileProofItem.label} />
          </span>
          {(inscriptionProofDocuments[indexItem] || []).map((proofFiles, index) => getDocumentItem(indexItem, index, fileProofItem))}
        </div>
      </div>
    )
  }

  return (
    <CardBlock
      header={<FormattedMessage id='inscription_form_documents_block_title' />}
      shadow
    >
      {FILE_PROOF_ITEMS.filter((item : InscriptionDocumentsInterface) => item.inscriptionTypes.includes(transaction.subProcedureType)).map((fileProofItem: InscriptionDocumentsInterface, indexItem: number) => renderDocumentForm(indexItem, fileProofItem))}
      <div className='col-12'>
        <ErrorField message={fieldStatus.documents} />
      </div>
    </CardBlock>
  )
}

export default injectIntl(InscriptionDocumentsBlock)
