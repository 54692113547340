import React, { FC, useState, useEffect } from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import {
  containsErrors,
  FieldStatus, isFilled,
  Publication,
  SubmitButton,
  Title,
  DEFAULT_TITLE_INSCRIPTION
} from '@inpi-dm/components'
import SelectRecordFormValidator from './validator/SelectRecordFormValidator'
import Message from '../../../../constants/Message'
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux'
import { storeContributorsTitleUpdate } from '../../../../store/contributorsTitle/contributorsTitleActions'
import TitleService from '../../../../services/transaction/TitleService'
import SearchAndManualRecordsForm from '../../../title/SearchAndManualRecordsForm'
import { RECORD_INSCRIPTION_INFORMATION_FROM_SELECT } from '../../../../constants/InscriptionConstants'

interface SelectRecordsFormProps extends WrappedComponentProps {
  initialTitle?: Title
  initialFieldStatus?: FieldStatus,
  onSubmit?: (title: Title, fieldStatus: FieldStatus) => void,
  onValidFieldStatus: (newFieldStatusPublications: string[]) => void,
  setDisclaimerTitle?: (title: Title) => void,
  isDisclaimer?: boolean
}

const SelectInscriptionRecordsForm: FC<SelectRecordsFormProps> = ({
  initialTitle,
  initialFieldStatus,
  onSubmit,
  onValidFieldStatus,
  setDisclaimerTitle,
  isDisclaimer
}) => {
  const dispatch = useDispatch()
  const [title, setTitle] = useState<Title>(isDisclaimer ? DEFAULT_TITLE_INSCRIPTION : initialTitle)
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>(initialFieldStatus || {})
  const infosByNumNat = useSelector((state: RootStateOrAny) => state.contributorsTitle)
  const [currentContributors, setCurrentContributors] = useState({})
  const [publicationsIds, setPublicationsIds] = useState<string[]>([])
  const [idRecordFO, setIdRecordFO] = useState<string>()

  useEffect(() => {
    if (title?.publications && title.publications.length > 0) {
      const publicationString = title.publications.map((publication : Publication) => {
        if (publication.idPublication) {
          return publication.idPublication
        }
        return ''
      })
      setPublicationsIds(publicationString)
    }
  }, [])

  useEffect(() => {
    initialFieldStatus && setFieldStatus(initialFieldStatus)
  }, [initialFieldStatus])

  /**
   * Vérifie l'existence des publications
   */
  const validForm = () => {
    if (title.numNat && isFilled(title.numNat)) {
      return TitleService.getTitleInfos({ numNat: title.numNat, origin: title.origin, idRecordFO: idRecordFO }).then(result => {
        setCurrentContributors({
          agent: result?.agent,
          holders: result?.holders,
          recipient: result?.recipient
        })
        const fieldStatusPublicationInTitle = SelectRecordFormValidator.validateExistReproduction(result?.publications, title.publications || [])
        setFieldStatus({ ...fieldStatus, publications: fieldStatusPublicationInTitle })
        onValidFieldStatus(fieldStatusPublicationInTitle)
      }).catch(() => {
        const fieldStatusPublicationInTitle = Array((title.publications || []).length).fill('warning')
        setFieldStatus({ ...fieldStatus, publications: fieldStatusPublicationInTitle })
        onValidFieldStatus(fieldStatusPublicationInTitle)
      })
    } else {
      setFieldStatus({ ...fieldStatus, numNat: Message.required_field })
      return Promise.resolve()
    }
  }

  /**
   * Vérifie que les champs obligatoire soient remplis,
   * enregistre dans le store les titres et ferme le formulaire
   */
  const submitForm = () => {
    // Enlever les publications vides
    const updateTitle = { ...title }
    const newPublication = title.publications?.filter(publication => isFilled(publication.idPublication)) || []
    updateTitle.publications = [...newPublication]
    setTitle(updateTitle)
    setDisclaimerTitle && setDisclaimerTitle(updateTitle)
    // Récupérer toutes les publications et les mettre ensemble dans title
    const fieldStatusError = SelectRecordFormValidator.validateMandatory(updateTitle)

    // Récupérer les warning pour les reproductions
    return SelectRecordFormValidator.getWarningReproduction(updateTitle.numNat || '', updateTitle.origin, updateTitle.publications, idRecordFO)
      .then((fieldStatusPublication : string[]) => {
        // Relier tout
        const newFieldStatus = { ...fieldStatusError, publications: fieldStatusPublication }
        setFieldStatus(newFieldStatus)

        if (!containsErrors(fieldStatusError)) {
          if (currentContributors) {
            if (title?.numNat && infosByNumNat[title?.numNat]) {
              delete infosByNumNat[title?.numNat]
            }
            // On enregistre les intervenants dans le store pour pouvoir les proposer lors des formulaires des intervenants
            dispatch(storeContributorsTitleUpdate({
              ...infosByNumNat,
              [title.numNat]: currentContributors
            }))
          }
          onSubmit && onSubmit(title, newFieldStatus)
        }
      })
  }

  return (
    <>
      <hr />
      <SearchAndManualRecordsForm
        title={title}
        setTitle={setTitle}
        publicationsIds={publicationsIds}
        setPublicationsIds={setPublicationsIds}
        fieldStatus={fieldStatus}
        setFieldStatus={setFieldStatus}
        setDisclaimerTitle={setDisclaimerTitle}
        setCurrentContributors={setCurrentContributors}
        setIdRecordFO={setIdRecordFO}
        optionSearch={RECORD_INSCRIPTION_INFORMATION_FROM_SELECT}
      />
      <div className='row m-0 d-flex justify-content-end mt-5'>
        <SubmitButton
          onClick={validForm}
          className='btn-outline-gris m-2'
        >
          <FormattedMessage id='records_inscription_valid_form' />
        </SubmitButton>
        {onSubmit &&
          <SubmitButton
            onClick={submitForm}
            className='btn-outline-primary m-2'
          >
            <FormattedMessage id='records_inscription_submit_form' />
          </SubmitButton>}
      </div>
    </>
  )
}

export default injectIntl(SelectInscriptionRecordsForm)
