import React, { FC, useState, useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  CardBlock,
  Transaction,
  OverviewComparedIntervenant,
  DepositIntervenant,
  NOTIF_STATUS_VISIBLE_TO_FO,
  SENDING_PORTAL,
  STATUS_INSCRIPTION,
  Country,
  ManageableQuality,
  ROWS_10,
  Pagination,
  SelectField,
  ROWS,
  INSCRIPTION_CORRECTION,
  isFilled
} from '@inpi-dm/components'
import DepositService from '../../../../services/transaction/DepositService'
import DownloadLink from '../../../deposit/download/DownloadLink'
import OverviewRecords from '../../../title/OverviewRecords'
import OverviewNotifications from '../../../deposit/form/overview/notifications/OverviewNotifications'
import DocumentBlock from '../../../requests/DocumentBlock'
import ContentService from '../../../../services/content/ContentService'
import { FILE_PROOF_ITEMS } from '../../../../constants/DocumentConstants'
import { InscriptionDocumentsInterface } from '../../../../interfaces/InscriptionInterfaces'

interface OverviewInscriptionProps {
  transaction: Transaction,
  isRequestView?: boolean | null,
  setReload?: () => void,
}

const OverviewInscription: FC<OverviewInscriptionProps> = ({
  transaction,
  isRequestView = false,
  setReload
}) => {
  const [countries, setCountries] = useState<Country[]>()
  const [manageableQualities, setManageableQualities] = useState<ManageableQuality[]>()
  const [agentDocument, setAgentDocument] = useState()
  const [actualPage, setActualPage] = useState(1)
  const [nbTitleShown, setNbTitleShown] = useState(ROWS_10.value)
  const [totalPage, setTotalPage] = useState(transaction.inscription?.titles && Math.ceil(transaction.inscription?.titles?.length / ROWS_10.value))
  const [minIndexShown, setMinIndexShown] = useState(0)
  const [maxIndexShown, setMaxIndexShown] = useState(ROWS_10.value - 1)
  const notificationReference = useRef(null)

  useEffect(() => {
    let unmounted = false
    ContentService.getManageableQualities().then(result => {
      if (!unmounted) {
        setManageableQualities(result)
      }
    })

    ContentService.getCountries().then(result => {
      if (!unmounted) {
        setCountries(result)
      }
    })

    if (!unmounted) {
      setAgentDocument(DepositService.getAgentDocument(transaction))
    }

    return () => {
      unmounted = true
      ContentService.cancelRequest()
      DepositService.cancelRequest()
    }
  }, [])

  /**
   * Losque l'on change la pagination
   */
  const onChangePagination = (newPage: number) => {
    setActualPage(newPage)
    setMinIndexShown((newPage - 1) * nbTitleShown)
    setMaxIndexShown((newPage * nbTitleShown) - 1)
  }

  return (
    <div className='row'>
      {(isRequestView && transaction.notifications && transaction.notifications
        .filter((notification) => NOTIF_STATUS_VISIBLE_TO_FO.includes(notification.status) && notification.sendingMode && notification.sendingMode.includes(SENDING_PORTAL))
        .length > 0)
        ? (
          <div className='col-12 mb-3'>
            <CardBlock
              header={<FormattedMessage id='overview_deposit_card_notification_title' />}
              shadow
            >
              <OverviewNotifications
                transaction={transaction}
                setReload={setReload}
                notificationReference={notificationReference}
              />
            </CardBlock>
          </div>
        )
        : null}
      {
        (isRequestView && transaction.status !== STATUS_INSCRIPTION.DRAFT_CLIENT) &&
          <div className='col-12 mb-3'>
            <DocumentBlock
              transaction={transaction}
              setReload={setReload}
              blockTitle={<FormattedMessage id='request_document_title' />}
            />
          </div>
      }
      <div className='col-12 mb-3'>
        <CardBlock
          header={
            <div className='d-flex justify-content-between'>
              <FormattedMessage id='overview_deposit_card_intervenant_title' />
            </div>
          }
          shadow
        >
          <div className='row'>
            {
              transaction.applicants && transaction.applicants.map((applicant: DepositIntervenant, index: number) => (
                <div key={`${applicant.id}-${index}`} className='col-12 mb-3 col-md-6'>
                  <h4><FormattedMessage id='intervenant_applicants' /></h4>
                  <OverviewComparedIntervenant
                    intervenant={applicant}
                    countries={countries}
                    qualities={manageableQualities}
                  />
                </div>
              ))
            }
            {
              transaction.recipient && (
                <div className='col-12 mb-3 col-md-6'>
                  <h4><FormattedMessage id='overview_deposit_receiver_title' /></h4>
                  <OverviewComparedIntervenant
                    intervenant={transaction.recipient}
                    countries={countries}
                    qualities={manageableQualities}
                  />
                </div>
              )
            }
            {
              transaction.otherApplicants && transaction.otherApplicants.map((otherApplicants: DepositIntervenant, index: number) => (
                <div key={`${otherApplicants.id}-${index}`} className='col-12 mb-3 col-md-6'>
                  <h4><FormattedMessage id='overview_deposit_other_applicant_title' /></h4>
                  <OverviewComparedIntervenant
                    intervenant={otherApplicants}
                    countries={countries}
                    qualities={manageableQualities}
                  />
                </div>
              ))
            }
            {
              transaction.signatory && (
                <div className='col-12 mb-3 col-md-6'>
                  <h4><FormattedMessage id='overview_deposit_signatory_title' /></h4>
                  <OverviewComparedIntervenant
                    intervenant={transaction.signatory}
                    countries={countries}
                    qualities={manageableQualities}
                  />
                </div>
              )
            }
            {
              transaction.agent && (
                <div className='col-12 mb-3 col-md-6'>
                  <h4><FormattedMessage id='overview_deposit_mandatary_title' /></h4>
                  <OverviewComparedIntervenant
                    deposit={transaction}
                    intervenant={transaction.agent}
                    countries={countries}
                    qualities={manageableQualities}
                  />
                  {
                    agentDocument && transaction.id &&
                      <DownloadLink
                        key={agentDocument.internalName}
                        id={transaction.id}
                        name={agentDocument.name}
                        internalName={agentDocument.internalName}
                        label={agentDocument.name}
                        classname='justify-content-start'
                      />
                  }
                </div>
              )
            }
          </div>
        </CardBlock>
      </div>
      <div className='col-12 mb-3'>
        <CardBlock
          header={
            <div className='d-flex justify-content-between'>
              <FormattedMessage id='overview_records_inscription' />
            </div>
          }
          shadow
        >
          <OverviewRecords
            currentIndex={-1}
            isOverview
            titles={transaction.inscription?.titles}
            minIndex={minIndexShown}
            maxIndex={maxIndexShown}
          />
          {
            totalPage !== 0 &&
              <div className='row justify-content-end mt-3'>
                <div className='col-md-7 col-12'>
                  <Pagination
                    nbPage={totalPage}
                    currentPage={actualPage}
                    onChange={onChangePagination}
                  />
                </div>
                <div className='col-md-3 col-12'>
                  <SelectField
                    inputId='rows'
                    options={ROWS}
                    value={nbTitleShown}
                    onChange={(event) => {
                      const newNb = parseInt(event.target.value, 10)
                      setActualPage(1)
                      setMinIndexShown(0)
                      setMaxIndexShown(newNb - 1)
                      setNbTitleShown(newNb)
                      setTotalPage(Math.ceil(transaction.inscription ? (transaction.inscription.titles.length / newNb) : 1))
                    }}
                    required
                  />
                </div>
              </div>
          }
        </CardBlock>
      </div>
      <div className='col-12 mb-3'>
        <CardBlock
          header={
            <div className='d-flex justify-content-between'>
              <FormattedMessage id='overview_inscription' />
            </div>
          }
          shadow
        >
          <div className='row m-0'>
            <div className='col-12 col-md-6 p-0'>
              <div className='text-gris'>
                <FormattedMessage id='overview_type_inscription' />
              </div>
              {
                transaction.subProcedureType &&
                  <FormattedMessage id={`select_type_${transaction.subProcedureType.toLowerCase()}_title`} />
              }
            </div>
            <div className='col-12 col-md-6 p-0'>
              <div className='text-gris'>
                <FormattedMessage id='overview_nature_inscription' />
              </div>
              {
                transaction.inscription?.natureCodes &&
                  <ul>
                    {
                      transaction.inscription.natureCodes.map(code => (
                        <li key={code.code}>{code.label}{isFilled(code.description) ? ` (${code.description})` : ''}</li>
                      ))
                    }
                  </ul>
              }
            </div>
            <div className='col-12 p-0'>
              <div className='text-gris'>
                <FormattedMessage id='inscription_form_documents_block_title' />
              </div>
              {
                FILE_PROOF_ITEMS.filter((item: InscriptionDocumentsInterface) => item.inscriptionTypes.includes(transaction.subProcedureType))
                  .map((fileProofItem: InscriptionDocumentsInterface) => {
                    const nbDoc = transaction.documents?.filter (document => document.type === fileProofItem.type).length || 0
                    if (nbDoc > 0 && !fileProofItem.hasLabel) {
                      return (
                        <div>
                          <FormattedMessage id={fileProofItem.label} />
                          {` : ${nbDoc} `}
                          <FormattedMessage id='inscription_form_documents_attached_files' />
                        </div>
                      )
                    } else if (nbDoc > 0 && fileProofItem.hasLabel) {
                      return transaction.documents?.filter (document => document.type === fileProofItem.type).map (otherDoc => (
                        <div>
                          {`${otherDoc.description} : 1 `}
                          <FormattedMessage id='inscription_form_documents_attached_files' />
                        </div>
                      ))
                    }
                    return null
                  })

              }
            </div>
            {(transaction.inscription?.isAppealOrDecommission || transaction.inscription?.isProrogation) && (
              <div className='col-12 p-0'>
                <div className='text-gris'>
                  <FormattedMessage id='overview_association' />
                </div>
                <ul>
                  {transaction.inscription?.isAppealOrDecommission && (
                    <li key='isAppealOrDecommission'><FormattedMessage id='ownership_is_appeal_decommission' /></li>
                  )}
                  {transaction.inscription?.isProrogation && (
                    <li key='isProrogation'><FormattedMessage id='ownership_is_prorogation' /></li>
                  )}
                </ul>
              </div>)}
            {
              transaction.subProcedureType === INSCRIPTION_CORRECTION.code &&
                <div className='col-12 p-0 mt-3'>
                  <div className='text-gris'>
                    <FormattedMessage id='correction_form_state_block_title' />
                  </div>
                  <div>
                    <span className='text-gris'>
                      <FormattedMessage id='correction_form_state_block_change_this' /> {': '}
                    </span>
                    {transaction.inscription?.changeText}
                  </div>
                  <div>
                    <span className='text-gris'>
                      <FormattedMessage id='correction_form_state_block_by_this' /> {': '}
                    </span>
                    {transaction.inscription?.byText}
                  </div>
                </div>
            }
          </div>
        </CardBlock>
      </div>
    </div>
  )
}

export default OverviewInscription
