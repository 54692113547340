import React, { useEffect, useState } from 'react'
import { LOGO_WHITE_SRC, MAIN_FOOTER_LINK } from '../../constants'
import HeaderNavItem from '../header/HeaderNavItem'
import { FormattedMessage } from 'react-intl'
import { version } from '../../../package.json'
import VersionApiService from '../../services/VersionApiService'
import { ModalComponent } from '@inpi-dm/components'
import ContentService from '../../services/content/ContentService'

const Footer = () => {
  const [versionApi, setVersionApi] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [legalNoticeContent, setLegalNoticeContent] = useState('')
  useEffect(() => {
    // Récupération de la version de l'api
    VersionApiService.getVersion().then(setVersionApi)
  }, [])

  useEffect(() => {
    ContentService.getEditorialBlockByCode('LEGAL_NOTICE').then((res) => {
      if (res.content) {
        setLegalNoticeContent(res.content)
      }
    })
  }, [])
  return (
    <div id='mainFooter' className='px-3'>
      <ul className='nav d-flex'>
        <li className='nav-item d-flex flex-row align-items-center py-3 version'>
          <div>
            <img src={LOGO_WHITE_SRC} alt='Logo INPI' height='30px' />
          </div>
          <div className='d-flex flex-column ml-4'>
            <span><FormattedMessage id='fo_version_api' /> {versionApi}</span>
            <span><FormattedMessage id='fo_version_front' /> {version}</span>
          </div>
        </li>
        <li className='nav-item m-auto'>
          <ul className='nav'>
            {MAIN_FOOTER_LINK.map((link) => (
              link.id === 'legal_notice' ? <HeaderNavItem key={link.id} label={link.label} onClick={() => setShowModal(true)} className='separated' noRedirection />
                : <HeaderNavItem key={link.id} label={link.label} url={link.url} className='separated' targetBlank />
            ))}
          </ul>
        </li>
      </ul>

      <ModalComponent
        title={<FormattedMessage id='footer_legal_notice' />}
        customContent={() => <div dangerouslySetInnerHTML={{ __html: legalNoticeContent }} />}
        handleClose={() => setShowModal(false)}
        show={showModal}
        hideFooter
        size='xl'
      />
    </div>)
}

export default Footer
