import React, { FC, useState } from 'react'
import { FormattedMessage, IntlProvider, WrappedComponentProps } from 'react-intl'
import { Button } from 'react-bootstrap'
import {
  AutocompleteResult,
  DepositIntervenant,
  EventType,
  FieldStatus, isFilled, SelectButton,
  TextArea
} from '..'
import Message from '../constants/Message'
import SubtitleIntervenant from './intervenant/SubtitleIntervenant'

interface CreatorFormProps extends WrappedComponentProps {
  foDisplay?: boolean | null
  boDisplay? : boolean | null
  depositCreators?: string | undefined | null,
  fieldStatus: FieldStatus,
  handleCreatorsForm?: (signatory: DepositIntervenant) => void | null
  handleChange?: (event: EventType) => void,
  intervenants?: AutocompleteResult<DepositIntervenant>[] | null,
  handleSelectIntervenant?: (intervenant: DepositIntervenant) => void
}

const RenderCreatorForm: FC<CreatorFormProps> = ({
  handleChange,
  intl,
  fieldStatus,
  foDisplay,
  boDisplay,
  depositCreators,
  handleCreatorsForm,
  intervenants,
  handleSelectIntervenant
}) => {
  const [currentCreators, setCurrentCreators] = useState(depositCreators)

  /**
   * Enregistrement des modifications du depositeur
   * @param event
   */
  const handleChanges = (event: EventType) => {
    const { value } = event.target

    setCurrentCreators(value)
    if (foDisplay) {
      handleChange && handleChange(event)
    }
  }

  return (
    <IntlProvider locale='fr' messages={Message}>

      <div className='form-header'>
        <h2 className='mb-0'>
          <FormattedMessage id='intervenant_onglet_createur' />
        </h2>
        <div className='col options px-0 my-auto'>
          {isFilled(intervenants) && (
            <SelectButton
              className='btn btn-link text-primary'
              options={intervenants}
              onSelect={handleSelectIntervenant}
            >
              <FormattedMessage id='intervenant_copier_donnees' />
            </SelectButton>
          )}
        </div>
        {foDisplay &&
          <SubtitleIntervenant idText='intervenant_creators_subtitle' />}
      </div>
      <div className='row'>
        <div className='col-12'>
          <TextArea
            label={intl.formatMessage({ id: 'field_creators_label' })}
            inputId='creators'
            labelClassName='mt-3'
            value={currentCreators}
            placeholder={intl.formatMessage({ id: 'field_creators_placeholder' })}
            fieldStatus={fieldStatus}
            onChange={handleChanges}
            rows={8}
            spellCheck={false}
            maxLength='200'
          />
        </div>
      </div>
      {
        boDisplay &&
          <div className='d-flex justify-content-around mt-2'>
            <Button className='primary' onClick={() => handleCreatorsForm && handleCreatorsForm(currentCreators)}>
              <FormattedMessage id='common_validate' />
            </Button>
          </div>
      }
    </IntlProvider>
  )
}

export default RenderCreatorForm
