import PersonneFieldValidator from './PersonneFieldValidator'
import AddressFieldsValidator from './AddressFieldsValidator'
import { CPI, DepositIntervenant, Transaction, DOCUMENT_TYPES, isFilled, messageIsFilled, DOCUMENT_STATUS } from '../../..'
import Message from '../../../constants/Message'
import { PERSONNE_PHYSIQUE, PERSONNE_MORALE, INTERVENANT_DESTINATAIRE, INTERVENANT_MANDATAIRE } from '../../../constants/DepositConstants'

class IntervenantValidator {
    /**
     * Retourne true si la liste de documents d'un dépôt contient au moins un
     * document de pouvoir
     */
    containsAgentDocument = (transaction?: Transaction) => {
      return !!transaction?.documents?.find(doc => doc.type === DOCUMENT_TYPES.DEPOSIT_AGENT_DOCUMENT && doc.status !== DOCUMENT_STATUS.REJECTED)
    }

    /**
     * Valide les informations d'un intervenant d'un dépôt
     *
     * @param intervenant L'intervenant à valider
     * @param isRecipient
     * @return Un objet FieldStatus contenant le résultat de la validation de chaque champ
     */
    validateIntervenant = (intervenant: DepositIntervenant = {}, intervenantType?: string, isDepositCoOrDepositor: boolean = false, isBo: boolean = false) => {
      const intervenantAddress = intervenant.address || {}

      return {
        ...PersonneFieldValidator.validateAll(intervenant, intervenantType, isDepositCoOrDepositor, isBo),
        ...AddressFieldsValidator.validateMandatory(intervenantAddress)
      }
    }

  /**
   * Valide les informations d'un destinataire
   * @param recipient
   */
  validateRecipient = (recipient: DepositIntervenant = {}) => ({
    ...this.validateIntervenant(recipient, INTERVENANT_DESTINATAIRE.value),
    firstname: messageIsFilled(recipient.firstname),
    lastname: messageIsFilled(recipient.lastname),
    civilityCode: messageIsFilled(recipient.civilityCode)
  })

  /**
   * Valide les informations d'un mandataire
   * @param agent
   */
  validateAgent = (agent: DepositIntervenant) => {
    return {
      ...this.validateIntervenant(agent, INTERVENANT_MANDATAIRE.value),
      firstname: messageIsFilled(agent.firstname),
      lastname: messageIsFilled(agent.lastname),
      civilityCode: messageIsFilled(agent.civilityCode),
      manageableQuality: messageIsFilled(agent.manageableQuality),
      inscriptionNumber: (!isFilled(agent.inscriptionNumber) && agent.manageableQuality === CPI) ? Message.required_field : ''
    }
  }

  /**
   * Valide les informations d'un signataire
   * @param signatory
   */
  validateSignatory = (signatory: DepositIntervenant = {}) => ({
    firstname: PersonneFieldValidator.validatePrenom(signatory),
    lastname: PersonneFieldValidator.validateNom(signatory),
    civilityCode: PersonneFieldValidator.validateCivilite(signatory),
    email: PersonneFieldValidator.validateEmail(signatory),
    manageableQuality: messageIsFilled(signatory.manageableQuality),
    inscriptionNumber: (!isFilled(signatory.inscriptionNumber) && signatory.manageableQuality === CPI) ? Message.required_field : ''
  })

  /**
   * Supprime les attributs qui ne correspondent pas au type de l'intervenant
   * @param intervenant
   * @param isRecipient
   */
  cleanIntervenant = (intervenant: DepositIntervenant, isRecipient = false): DepositIntervenant => {
    if (intervenant.type === PERSONNE_PHYSIQUE.value) {
      intervenant.companyName = undefined
      intervenant.legalForm = undefined
      intervenant.siren = undefined
    } else if (intervenant.type === PERSONNE_MORALE.value && !isRecipient) {
      intervenant.firstname = undefined
      intervenant.lastname = undefined
      intervenant.civilityCode = undefined
    }

    return intervenant
  }
}

export default new IntervenantValidator()
