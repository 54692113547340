import React, { FC, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { NotificationInterface } from '@inpi-dm/components/src/interfaces/DepositInterfaces'
import { SubmitButton } from '@inpi-dm/components'
import Message from '../../../../../constants/Message'
import Truncate from 'react-truncate-html'

interface OverviewNotificationsInfoProps {
  notification:NotificationInterface,
  download:() => void
}

const OverviewNotificationsInfo:FC<OverviewNotificationsInfoProps> = ({
  notification,
  download
}) => {
  const contentRef = useRef(null)

  return (
    <div>
      <div>
        <h3><FormattedMessage id='overview_deposit_notification_content_label' /></h3>
      </div>
      <div ref={contentRef} className='border p-2 mb-2'>
        <Truncate
          lines={14} ellipsis=' ...' dangerouslySetInnerHTML={{
            __html: `${notification.content}`
          }}
        />
      </div>
      {contentRef?.current?.offsetHeight >= 50 &&
        <SubmitButton className='btn btn-link notif-ellipsis' onClick={download}>
          {Message.overview_deposit_notification_ellipsis}
        </SubmitButton>}
    </div>
  )
}

export default OverviewNotificationsInfo
