import store from '../../../../../store/store'
import { containsErrors, FieldStatus } from '@inpi-dm/components'
import Message from '../../../../../constants/Message'
import { ProrogationFieldStatus } from '../../../../../interfaces/ProrogationInterfaces'
import { storeProrogationFieldStatusUpdate } from '../../../../../store/prorogation/prorogationActions'

class OverviewFormProrogationValidator {
    validateMandatory = (similarInformation: boolean): FieldStatus => {
      return {
        overviewSimilarInformation: similarInformation ? '' : Message.required_checkbox_field
      }
    }

    validateOnChangeStep = () => {
      const transaction = store.getState().prorogation

      const fieldStatus : ProrogationFieldStatus = this.validateMandatory(transaction.overviewSimilarInformation || false)
      store.dispatch(storeProrogationFieldStatusUpdate({
        overviewSimilarInformation: fieldStatus
      }))

      return !containsErrors(fieldStatus)
    }
}

export default new OverviewFormProrogationValidator()
