import React, { FC, useEffect, useState } from 'react'
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux'
import { useIntl, FormattedMessage } from 'react-intl'
import Logo from '../../common/Logo'
import {
  PICTO_HEADER_HELP,
  PICTO_HEADER_LOGOUT,
  PICTO_HEADER_PHONE, PICTO_HEADER_USER
} from '../../constants'
import SearchField from './search/SearchField'
import HeaderNavItem from './HeaderNavItem'

import { storeLogout } from '../../store/user/userActions'

import { ModalComponent } from '@inpi-dm/components'
import Contact from '../contact/Contact'
import ContentService from '../../services/content/ContentService'

const Header: FC = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const currentUser = useSelector((state : RootStateOrAny) => state.user.user)
  const [showContact, setShowContact] = useState(false)
  const [headerMessage, setHeaderMessage] = useState('')

  useEffect(() => {
    ContentService.getEditorialBlockByCode('HEAD').then((res) => {
      if (res.content) {
        setHeaderMessage(res.content)
      }
    })
  }, [])
  return (
    <div>
      <div id='mainHeader' className='px-3'>
        <ul className='nav'>
          <li className='nav-item'>
            <Logo />
          </li>
          <li className='nav-item ml-auto mr-5'>
            <SearchField />
          </li>
          <HeaderNavItem
            key='help'
            img={PICTO_HEADER_HELP}
            url='/aide'
            infoHelp={intl.formatMessage({ id: 'help_hover' })}
          />
          <HeaderNavItem
            key='contact'
            img={PICTO_HEADER_PHONE}
            onClick={() => setShowContact(true)}
            noRedirection
            infoHelp={intl.formatMessage({ id: 'contact_hover' })}
          />
          <HeaderNavItem
            key='user'
            img={PICTO_HEADER_USER}
            url={process.env.REACT_APP_URL_PROCEDURES}
            infoHelp={`${intl.formatMessage({ id: 'user_hover' })} ${currentUser.firstname} ${currentUser.lastname}`}
          />
          <HeaderNavItem
            key='logout'
            img={PICTO_HEADER_LOGOUT}
            onClick={() => dispatch(storeLogout())}
            url={process.env.REACT_APP_URL_PROCEDURES_HOME}
            infoHelp={intl.formatMessage({ id: 'logout_hover' })}
          />
        </ul>
        <ModalComponent
          title={<FormattedMessage id='contact_title' />}
          customContent={() => <Contact setShowContact={setShowContact} />}
          handleClose={() => setShowContact(false)}
          show={showContact}
          hideFooter
          size='xl'
        />
      </div>
      {headerMessage && <div className='header-msg p-2 shadow'><div dangerouslySetInnerHTML={{ __html: headerMessage }} /></div>}
    </div>
  )
}

export default Header
