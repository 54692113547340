import React, { FC, useContext, Fragment } from 'react'
import { Accordion, AccordionContext, Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { isFilled, LocarnoCategory, LocarnoProduct } from '..'
import { FormattedMessage, IntlProvider } from 'react-intl'
import Message from '../constants/Message'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle'

interface ContextAwareToggleProps {
  eventKey: string,
  toggle?: () => void
}

const ContextAwareToggleCategory : FC<ContextAwareToggleProps> = ({ eventKey, category, toogle }) => {
  const currentEventKey = useContext(AccordionContext)

  const isCurrentEventKey = currentEventKey === eventKey

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => {
      toogle()
      // Scroll la catégorie au milieu de la modale pour que les sous-catégories soient visibles sans avoir à scroller
      const scrollToElement = setInterval(function () {
        const element = document.getElementById(category.subCategories.sort((a, b) => (a.ref > b.ref) ? 1 : -1)[0].ref)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' })
          clearInterval(scrollToElement)
        }
      }, 100)
    }
  )

  return (
    <div
      className='cursor-pointer row align-items-center flex-nowrap'
      id={eventKey}
      onClick={decoratedOnClick}
    >
      <FontAwesomeIcon className='mx-1 text-primary' icon={isCurrentEventKey ? faMinus : faPlus} />
      <div className='subtitle pl-2 text-truncate'>
        {`${category.ref} ${category.name}`}
      </div>
    </div>
  )
}

const ContextAwareToggleSubCategory : FC<ContextAwareToggleProps> = ({ eventKey, subCategory }) => {
  const currentEventKey = useContext(AccordionContext)
  const isCurrentEventKey = currentEventKey === eventKey

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => {
      // Scroll pour que les produits soient visibles au clic sur une sous catégorie
      const scrollToElement = setInterval(function () {
        const element = subCategory.products ? document.getElementById(subCategory.products.sort((a, b) => (a.ref > b.ref) ? 1 : -1)[0].id) : undefined
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' })
          clearInterval(scrollToElement)
        }
      }, 100)
    }
  )

  return (
    <div
      id={eventKey}
      className='text-gris font-weight-bold text-uppercase m-2 cursor-pointer'
      onClick={decoratedOnClick}
    >
      <FontAwesomeIcon className='mx-1 text-primary' icon={isCurrentEventKey ? faMinus : faPlus} />
      <span className='subtitle pl-2'>{`${subCategory.ref}. `}</span>
      {subCategory.name}
    </div>
  )
}

interface LocarnoAccordionCategoriesProps {
  categories?: LocarnoCategory[],
  onClickCategory: (category: LocarnoCategory) => void,
  onClickProduct?: (product: LocarnoProduct) => void,
}

const LocarnoAccordionCategories: FC<LocarnoAccordionCategoriesProps> = ({
  categories = [],
  onClickCategory,
  onClickProduct
}) => (
  <IntlProvider locale='fr' messages={Message}>
    <Accordion className='pb-4'>
      {categories.sort((a, b) => (a.ref > b.ref) ? 1 : -1).map((category) => (
        <Card key={category.ref}>
          <Card.Header className='bg-transparent' variant='link'>
            <ContextAwareToggleCategory eventKey={category.ref} category={category} toogle={() => onClickCategory(category)} />
          </Card.Header>
          <Accordion.Collapse eventKey={category.ref} className='pl-4'>
            <Accordion>
              {category.subCategories && category.subCategories.sort((a, b) => (a.ref > b.ref) ? 1 : -1).map((subCategory) => (
                <Fragment key={subCategory.ref}>
                  <Accordion.Toggle as='div' className='cursor-pointer bg-transparent py-0' eventKey={subCategory.ref}>
                    <ContextAwareToggleSubCategory eventKey={subCategory.ref} subCategory={subCategory} />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={subCategory.ref} className='pl-4'>
                    <div className='list-group'>
                      {isFilled(subCategory.products)
                        ? subCategory.products?.map(product => (
                          <div
                            key={product.id}
                            id={product.id}
                            className={`${onClickProduct ? 'list-group-item-action cursor-pointer' : ''} pl-4 mb-1`}
                            onClick={() => onClickProduct && onClickProduct(product)}
                          >
                            <span className='font-weight-bold'>{product.name}</span>
                          </div>
                        ))
                        : (
                          <div key='not-found'><FormattedMessage id='locarno_empty_products' /></div>
                        )}
                    </div>
                  </Accordion.Collapse>
                </Fragment>
              ))}
            </Accordion>
          </Accordion.Collapse>
        </Card>
      ))}
    </Accordion>
  </IntlProvider>
)

export default LocarnoAccordionCategories
