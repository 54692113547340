import {
  Country,
  DOCUMENT_TYPES,
  DepositDocument,
  DepositFile,
  DropArea,
  FieldStatus,
  MODEL_CLOSE, MODEL_OPEN,
  ModalComponent,
  ModelDeposit,
  Preview,
  ReproductionLightDeposit,
  SubmitButton,
  Transaction,
  ThumbnailsContext
} from '@inpi-dm/components'
import React, { FC, MutableRefObject, useContext, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import { DepositPriorityOptions } from '../../../../interfaces/DepositInterfaces'
import RecordService from '../../../../services/record/RecordService'
import DepositService from '../../../../services/transaction/DepositService'
import NotificationService from '../../../../services/transaction/NotificationService'
import TransactionService from '../../../../services/transaction/TransactionService'
import PreviewAndTrashRectificatif from './PreviewAndTrashRectificatif'
import OverviewModel from './OverviewModel'
/* global FormData */
/* global File */

interface OverviewModelsProps {
  models?: ModelDeposit[],
  depositId: string,
  priorities?: DepositPriorityOptions[] | undefined,
  countries?: Country[]
  documents?: DepositDocument[],
  setReload?: () => {}
  hasReproRegularization: boolean,
  isEditable?: boolean,
  setIsEditable?: (value: boolean) => void,
  changeModel?: (index: number) => void,
  deleteModel?: (index: number) => void,
  fieldStatus?: FieldStatus,
  nbCurrent?: number,
  isBeforeForm?: boolean,
  transaction: Transaction,
  notificationReference?: MutableRefObject<HTMLDivElement | null> | null,
  depositView: boolean,
  toggle: string,
}

const OverviewModels: FC<OverviewModelsProps> = ({
  models = [],
  depositId,
  priorities,
  countries = [],
  documents,
  setReload,
  hasReproRegularization,
  isEditable,
  setIsEditable,
  changeModel,
  deleteModel,
  fieldStatus,
  nbCurrent,
  isBeforeForm,
  transaction,
  notificationReference,
  depositView,
  toggle
}) => {
  const [fileToAdd, setfileToAdd] = useState<DepositDocument[]>([])
  const [showReproModal, setShowReproModal] = useState(false)
  const [showPreviewModal, setShowPreviewModal] = useState<{ model: ModelDeposit, repro: ReproductionLightDeposit }>()
  const [fileToShow, setFileToShow] = useState<DepositDocument>()
  const [fileToDelete, setFileToDelete] = useState<DepositDocument[]>([])
  const [supportDocDelete, setSupportDocDelete] = useState(false)
  const [isOpen, setIsOpen] = useState<string[]>(models.map(() => MODEL_CLOSE))
  const intl = useIntl()
  const { thumbnailsImages, setThumbnailsImages } = useContext(ThumbnailsContext)

  useEffect(() => {
    setIsOpen(models.map(() => toggle ?? MODEL_CLOSE))
  }, [toggle])

  const toogleCardModel = (indexModel: number) => {
    const newOpen = [...isOpen]
    newOpen[indexModel] = newOpen[indexModel] === MODEL_OPEN ? MODEL_CLOSE : MODEL_OPEN
    setIsOpen(newOpen)
  }

  /**
   * Ajout du document dans le dépot
   */
  const addFileToDocuments = async () => {
    for (const file of fileToAdd) {
      await TransactionService.postDocumentFile(depositId, DOCUMENT_TYPES.DOCUMENT_TYPE_REGULARIZE_REPRO, file.file, file.srcName)
    }
  }

  useEffect(() => setFileToDelete([]), [transaction])
  /**
   * Permet d'auto scroll vers le bloc notification
   */
  const scrollToNotification = () => {
    if (notificationReference && notificationReference.current) {
      window.scrollTo({
        top: notificationReference.current.offsetParent.offsetParent.offsetTop,
        behavior: 'smooth'
      })
    }
  }

  const submitRegularisation = () => {
    if (fileToAdd && fileToAdd.length > 0) {
      return addFileToDocuments().then(() => {
        toast.success(intl.formatMessage({ id: 'overview_deposit_regularize_reproductions_save' }))
        setIsEditable && setIsEditable(false)
        setfileToAdd([])
        setReload && setReload()
        scrollToNotification()
      })
    } else {
      setIsEditable && setIsEditable(false)
      setfileToAdd([])
      return scrollToNotification()
    }
  }

  /**
   * Supprime le document
   * @param elemToDelete
   */
  const deleteOneFile = (elemToDelete: DepositDocument) => {
    const newFile = [...fileToAdd].filter(element => element !== elemToDelete)
    setFileToDelete([...fileToDelete, elemToDelete])
    setfileToAdd(newFile)
    if (transaction.notifications) {
      transaction.notifications.forEach(async (notification) => {
        const notifToDelete = notification.supportingDocuments?.find(doc => doc.internalName === elemToDelete.internalName)
        if (notifToDelete) {
          setSupportDocDelete(!supportDocDelete)
          return await NotificationService.deleteNotificationFile(depositId, notification.id, elemToDelete.internalName)
        }
      })
    }
  }

  /**
   * Permet d'afficher les reproductions en preview
   */
  const previewReproduction = async () => {
    if (!fileToShow) {
      return <></>
    }

    let file = null

    if (!(fileToShow instanceof File) && fileToShow.internalName) {
      file = await DepositService.resizeDocument(depositId, fileToShow.internalName)
    } else {
      const formData = new FormData()
      formData.append('file', fileToShow.file)
      file = await DepositService.resizeFile(formData)
    }

    return (
      <div className='w-100 d-flex'>
        <Preview
          className='m-auto img-bopi'
          file={file}
        />
      </div>
    )
  }

  /**
   * Vérifie si une reproduction est 'reliée' a un document dans le dépot
   */
  const hasExchangeRepro = (oneRepro: ReproductionLightDeposit) => {
    return documents?.filter(element => element.srcName === oneRepro.file.internalName) &&
      documents?.filter(element => element.srcName === oneRepro.file.internalName).length > 0
  }

  /**
   * Vérifie si une reproduction est 'reliée' a un document localement
   */
  const hasExchangeReproToAdd = (oneRepro: ReproductionLightDeposit) => {
    return fileToAdd.filter(element => element.srcName === oneRepro.file.internalName).length > 0
  }

  /**
   *
   * Affiche une modale contenant la reproduction en grand
   * @param oneModel
   * @param oneRepro
   */
  const showPreview = (oneModel: ModelDeposit, oneRepro: ReproductionLightDeposit) => {
    setShowPreviewModal({
      model: oneModel,
      repro: oneRepro
    })
  }

  /**
   * Affiche la reproduction
   * @param idDeposit
   * @param idModel
   * @param file
   */
  const getReproduction = async (idDeposit: string, idModel: string, file: DepositFile) => {
    if (thumbnailsImages?.[file.internalName]) {
      return thumbnailsImages[file.internalName]
    }

    let newFile = null
    if (depositView) {
      newFile = await RecordService.getReproduction(idDeposit, idModel, file)
    } else {
      newFile = await DepositService.getReproduction(idDeposit, idModel, file.internalName)
    }

    setThumbnailsImages((previousValues: { [key: string]: string }) => ({
      ...previousValues,
      [file.internalName]: newFile
    }))

    return newFile
  }

  return (
    <>
      {
        isBeforeForm && nbCurrent !== undefined &&
        models && models.map((oneModel: ModelDeposit, indexModel: number) => indexModel < nbCurrent && (
          <OverviewModel
            key={`overview_1_${indexModel}`}
            priorities={priorities}
            oneModel={oneModel}
            indexModel={indexModel}
            isOpen={isOpen[indexModel] !== MODEL_CLOSE}
            toogleCardModel={toogleCardModel}
            nbCurrent={nbCurrent}
            depositId={depositId}
            fieldStatus={fieldStatus}
            countries={countries}
            getReproduction={getReproduction}
            isEditable={isEditable}
            hasExchangeRepro={hasExchangeRepro}
            hasExchangeReproToAdd={hasExchangeReproToAdd}
            changeModel={changeModel}
            deleteModel={deleteModel}
            showPreview={showPreview}
            documents={documents}
            fileToAdd={fileToAdd}
            setfileToAdd={setfileToAdd}
            setShowReproModal={setShowReproModal}
            setFileToShow={setFileToShow}
            setReload={setReload}
            deleteOneFile={deleteOneFile}
            supportDocDelete={supportDocDelete}
            transaction={transaction}
          />
        ))
      }
      {
        !isBeforeForm && nbCurrent !== undefined &&
        models && models.map((oneModel: ModelDeposit, indexModel: number) => indexModel > nbCurrent && (
          <OverviewModel
            key={`overview_2_${indexModel}`}
            priorities={priorities}
            oneModel={oneModel}
            indexModel={indexModel}
            isOpen={isOpen[indexModel] !== MODEL_CLOSE}
            toogleCardModel={toogleCardModel}
            nbCurrent={nbCurrent}
            depositId={depositId}
            fieldStatus={fieldStatus}
            countries={countries}
            getReproduction={getReproduction}
            isEditable={isEditable}
            hasExchangeRepro={hasExchangeRepro}
            hasExchangeReproToAdd={hasExchangeReproToAdd}
            changeModel={changeModel}
            deleteModel={deleteModel}
            showPreview={showPreview}
            documents={documents}
            fileToAdd={fileToAdd}
            setfileToAdd={setfileToAdd}
            setShowReproModal={setShowReproModal}
            setFileToShow={setFileToShow}
            setReload={setReload}
            deleteOneFile={deleteOneFile}
            supportDocDelete={supportDocDelete}
            transaction={transaction}
          />
        ))
      }
      {
        nbCurrent === undefined &&
        models && models.map((oneModel: ModelDeposit, indexModel: number) => (
          <OverviewModel
            key={`overview_3_${indexModel}`}
            priorities={priorities}
            oneModel={oneModel}
            indexModel={indexModel}
            isOpen={isOpen[indexModel] !== MODEL_CLOSE}
            toogleCardModel={toogleCardModel}
            nbCurrent={nbCurrent}
            depositId={depositId}
            fieldStatus={fieldStatus}
            countries={countries}
            getReproduction={getReproduction}
            isEditable={isEditable}
            hasExchangeRepro={hasExchangeRepro}
            hasExchangeReproToAdd={hasExchangeReproToAdd}
            changeModel={changeModel}
            deleteModel={deleteModel}
            showPreview={showPreview}
            documents={documents}
            fileToAdd={fileToAdd}
            setfileToAdd={setfileToAdd}
            setShowReproModal={setShowReproModal}
            setFileToShow={setFileToShow}
            setReload={setReload}
            deleteOneFile={deleteOneFile}
            supportDocDelete={supportDocDelete}
            transaction={transaction}
          />
        ))
      }
      {
        isEditable &&
          <div>
            <DropArea
              text='overview_deposit_regularize_add_reproductions'
              accept={process.env.REACT_APP_ACCEPTED_IMAGE_FORMAT}
              maxSize={process.env.REACT_APP_ACCEPTED_IMAGE_SIZE}
              onDrop={(file: File[]) => {
                const files = []
                file.forEach(f => {
                  files.push({ file: f })
                })
                setfileToAdd(fileToAdd => [...fileToAdd, ...files])
              }}
            />
            <div className='d-flex align-items-center'>
              {
              documents?.filter(doc => !fileToDelete.includes(doc)).map((file, index) =>
                <PreviewAndTrashRectificatif
                  key={index}
                  loadFile={() => TransactionService.getDocumentFile(depositId, file.internalName, true)}
                  onClick={() => {
                    setShowReproModal(true)
                    setFileToShow(file)
                  }}
                  onTrashClick={() => {
                    deleteOneFile(file)
                    setReload && setReload()
                  }}
                  refreshAction={supportDocDelete}
                />
              )
              }
              {
                fileToAdd.map((file, index) => {
                  return (
                    <PreviewAndTrashRectificatif
                      key={`to-add-${index}`}
                      loadFile={file.file}
                      onClick={() => {
                        setShowReproModal(true)
                        setFileToShow(file)
                      }}
                      onTrashClick={() => deleteOneFile(file)}
                      refreshAction={supportDocDelete}
                    />
                  )
                })
              }
            </div>
          </div>

      }
      {
        hasReproRegularization && isEditable &&
          <div className='d-flex justify-content-end'>
            <SubmitButton
              className='btn-outline-primary mr-3'
              onClick={() => {
                setfileToAdd([])
                setIsEditable && setIsEditable(false)
              }}
            >
              <FormattedMessage id='button_cancel' />
            </SubmitButton>
            <SubmitButton
              className='btn-primary'
              onClick={submitRegularisation}
            >
              <FormattedMessage id='button_validate' />
            </SubmitButton>
          </div>
      }
      <ModalComponent
        title={<div className='text-capitalize'><FormattedMessage id='request_number_reproduction' /></div>}
        show={showReproModal}
        customContent={previewReproduction}
        handleClose={() => setShowReproModal(false)}
        onClick={() => setShowReproModal(false)}
        size='xl'
      />
      {showPreviewModal &&
        <ModalComponent
          title={<FormattedMessage id='modal_repro_preview' />}
          customContent={() => (
            <div className='text-center mb-3'>
              <Preview
                className='img-bordered'
                key={`preview-modal-${showPreviewModal.model.id}`}
                file={() => getReproduction(transaction.id, showPreviewModal.model.id, showPreviewModal.repro.preview)}
                filename={showPreviewModal.repro.preview.name}
              />
            </div>
          )}
          size='xl'
          handleClose={() => setShowPreviewModal()}
          show={showPreviewModal}
          hideFooter
        />}
    </>
  )
}

export default OverviewModels
