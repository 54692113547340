import React, { FC, isValidElement, ReactElement, ReactNode, useEffect, useState } from 'react'
import StepList from './StepList'
import { CardBlock, SubmitButton, ModalComponent } from '@inpi-dm/components'
import { StepListItemContent } from './StepListItem'
import Message from '../../constants/Message'
import StepContainerUtils from './StepContainerUtils'
import { FormattedMessage } from 'react-intl'

export interface StepView extends StepListItemContent {
  /**
   *  Composant à afficher au clique sur l'étape.
   *  Dans le cas où un constructeur de composant fonctionnel est passé en paramètre,
   *   celui-ci se verra transferé dans son paramètre children les boutons de changement d'étape
   *  Si undefined, on avancera jusqu'à la prochaine étape contenant un composant non vide.
   */
  component?: ReactElement|FC<any>,

  /**
   * Ajoute les boutons de changements d'étape en tant que children du composant
   */
  stepButtonsAsChildren?: boolean,

  /**
   * Fonction validant la possiblilité de continuer vers l'étape d'id fourni en paramètre
   * Retourner false pour bloquer l'utilisateur sur l'étape courante
   */
  validateGoToStep?: () => boolean|Promise<boolean>,

  /**
   * Fonction appelée lorsque le changement d'étape est possible.
   * Retourner un string permet de modifier l'étape de destination.
   */
  onGoToStep?: () => Promise<any|void>,

  /**
   * Si il faut afficher une popin avant le passage à l'étape suivante
   * on passe le texte de la popin à afficher
   */
  textModalBeforeNextStep?: string,

  /**
   * Si cette étape est necessaire que dans certain cas : condition pour afficher l'etape
   */
  condition?: boolean,
}

export interface StepContainerButtonLabels {
  cancel?: ReactNode,
  previousStep?: ReactNode,
  nextStep?: ReactNode,
  confirm?: ReactNode,
}

interface StepContainerProps {
  className?: string,
  listClassName?: string,
  listTitle?: ReactNode,
  views: StepView[],
  buttons?: StepContainerButtonLabels,
  onCancel?: () => void,
  onSubmit?: (idLastStep: string) => void
}

const StepContainer: FC<StepContainerProps> = ({
  className = '',
  listClassName = '',
  listTitle = undefined,
  views,
  buttons = {
    cancel: Message.button_cancel,
    previousStep: Message.button_previous_step,
    nextStep: Message.button_next_step,
    confirm: Message.button_confirm
  },
  onCancel,
  onSubmit
}) => {
  const [currentStep, setCurrentStep] = useState('')
  const [currentStepIndex, setCurrentStepIndex] = useState(0)
  const [previousStepIndex, setPreviousStepIndex] = useState(0)
  const [nextStepIndex, setNextStepIndex] = useState(0)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [goToIndex, setGoToIndex] = useState(0)

  /**
   * Affichage de la prochaine étape
   * @param newStepId id de la prochaine étape souhaitée
   */
  const changeStep = (newStepId: string) => {
    let newStepIndex = StepContainerUtils.findStepIndexById(views, newStepId)
    newStepIndex = StepContainerUtils.findDisplayableStepIndex(views, newStepIndex)

    setCurrentStep(views[newStepIndex].id)
    setCurrentStepIndex(newStepIndex)
    setNextStepIndex(StepContainerUtils.findNextDisplayableStep(views, newStepIndex))
    setPreviousStepIndex(StepContainerUtils.findPreviousDisplayableStep(views, newStepIndex))
  }

  useEffect(() => {
    changeStep(views[0].id)
  }, [])

  /**
   * Vérification du formulaire de l'étape avant de passer à l'étape suivante
   * @param nextIndex index de l'étape cible
   * @returns boolean savoir si on peut passer à l'étape suivante
     */
  const canChangeStep = async (nextIndex: number) => {
    let index = currentStepIndex

    while (index < nextIndex) {
      let isValidated = false
      const currentView = views[index]
      // Si la conditionn d'affichage n'est pas remplie, on saute l'étape
      if (currentView.condition === false) {
        isValidated = true
      } else if (currentView.validateGoToStep && typeof currentView.validateGoToStep().then === 'function') {
        // Si la fonction de validation doit se faire coté back c'est alors une promise
        isValidated = await currentView.validateGoToStep()
      } else {
        isValidated = !currentView.validateGoToStep || currentView.validateGoToStep()
      }
      // Si une étape intermédiaire n'est pas valide, on redirige vers cette étape à compléter
      if (!isValidated) {
        return views[index].id
      }
      index++
    }

    return views[nextIndex].id
  }

  /**
   * Passe à l'étape suivante
   * @param nextStepId id de l'étape cible
   * @param modalConfirm savoir si la popin a été validée par l'utilisateur
   */
  const goToStep = (nextStepId: string, modalConfirm? = false) => {
    setGoToIndex(nextStepId)

    const currentView = views[currentStepIndex]
    // Si il faut afficher une popin avant la validation de l'étape et si l'utilisateur ne l'a pas déjà validée
    if (currentView.textModalBeforeNextStep && !modalConfirm) {
      setShowConfirmation(true)
    } else {
      if (currentView.onGoToStep) {
        return currentView.onGoToStep().then(() => {
          setShowConfirmation(false)
          changeStep(nextStepId)
        })
      } else {
        setShowConfirmation(false)
        return changeStep(nextStepId)
      }
    }
  }

  /**
   * Au clic pour le changement d'une étape
   * @param index index de l'étape cible
   * @param submit savoir si on vient d'un clic sur Enregistrer (true) ou si on navigue depuis le menu (false)
   */
  const handleChangeStep = (index: number, submit = false) => {
    return canChangeStep(index).then(targetId => {
      // Si l'id est différent de l'id courant, c'est qu'on peut changer d'étape
      if (targetId !== views[currentStepIndex].id) {
        return submit ? goToStep(targetId) : changeStep(targetId)
      } else {
        return null
      }
    })
  }

  /**
   * Validation finale du formulaire
   * @param finalStepId
   */
  const handleSubmit = (finalStepId: string) => {
    if (onSubmit) {
      // Si une des étapes n'est pas valide, on redirige vers l'étape à compléter
      return canChangeStep(views.length - 1).then(targetId => {
        if (targetId !== finalStepId) {
          return goToStep(targetId)
        }

        // si tout est valide, on submit le formulaire
        return onSubmit(finalStepId)
      })
    } else {
      return null
    }
  }

  // Fonction pour afficher la popin prévenant de la perte des modifications si l'utilisateur passe à l'étape suivante
  const modalBeforeNextStep = () => {
    return (
      <div className='row'>
        <span className='col col-12'>{views[currentStepIndex].textModalBeforeNextStep}</span>
        <div className='col-12 d-flex justify-content-between align-items-center'>
          <SubmitButton
            className='btn btn-outline-secondary mt-4'
            onClick={() => {
              setShowConfirmation(false)
            }}
          >
            <FormattedMessage id='button_cancel' />
          </SubmitButton>
          <SubmitButton
            className='btn btn-outline-primary mt-4'
            onClick={() => {
              return goToStep(goToIndex, true)
            }}
          >
            <FormattedMessage id='button_continue' />
          </SubmitButton>
        </div>
      </div>
    )
  }

  const isFirstStep = currentStepIndex === previousStepIndex
  const isLastStep = currentStepIndex === nextStepIndex

  const stepButtons = (
    <div className='step-buttons mt-4'>
      <div className='row m-auto justify-content-between'>
        <div className='col-4'>
          <SubmitButton
            className='btn-block btn-outline-gris'
            onClick={
              isFirstStep
                ? () => onCancel && onCancel()
                : () => handleChangeStep(previousStepIndex)
            }
          >
            {isFirstStep ? buttons?.cancel : buttons?.previousStep}
          </SubmitButton>
        </div>

        <div className='col-4 next-step'>
          <SubmitButton
            className='btn-block bg-secondary'
            onClick={
              isLastStep
                ? () => handleSubmit(currentStep)
                : () => handleChangeStep(nextStepIndex, true)
            }
          >
            {isLastStep ? buttons?.confirm : buttons?.nextStep}
          </SubmitButton>
        </div>

      </div>
    </div>
  )

  const renderView = () => {
    const view = views[currentStepIndex]
    let displayedView = (<></>)

    if (view.component) {
      if (isValidElement(view.component)) {
        if (view.stepButtonsAsChildren) {
          const Component = {
            ...view.component,
            props: {
              ...view.component.props,
              children: stepButtons
            }
          }
          displayedView = Component
        } else {
          displayedView = <div className='view'>{view.component}</div>
        }
      } else {
        const Component = view.component
        displayedView = <Component onSubmit={() => handleSubmit(currentStep)}>{stepButtons}</Component>
      }
    }

    return displayedView
  }

  return (
    <div className={`container-step ${className}`}>
      <div className='row'>
        <div className='col-md-3'>
          <CardBlock bodyClassName='p-3'>
            <StepList
              title={listTitle}
              className={listClassName}
              items={views}
              idActive={currentStep}
              onClickStep={(index) => handleChangeStep(index, index > currentStepIndex)}
            />
          </CardBlock>
        </div>

        <div className='col-md-9'>
          <CardBlock className='step-view'>
            {renderView()}
            {!views[currentStepIndex].stepButtonsAsChildren && stepButtons}
          </CardBlock>
        </div>
      </div>
      <ModalComponent
        title={<FormattedMessage id='modification_discontinuation' />}
        customContent={() => modalBeforeNextStep()}
        handleClose={() => {
          setShowConfirmation(false)
        }}
        show={showConfirmation}
        hideFooter
      />
    </div>
  )
}

export default StepContainer
