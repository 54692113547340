import http from '../../network/http-common'
import { createIntl } from 'react-intl'
import Message from '../../constants/Message'
import axios from 'axios'
import {
  OfficialDocumentType,
  OfficialDocumentTypeSearchParameters,
  OfficialDocumentTypeSearchResult
} from '@inpi-dm/components'

/**
 * Classe permettant de récupérer les données administrables en bo
 */
class OfficialDocumentTypeService {
  constructor () {
    this.intl = createIntl({ locale: 'fr', messages: Message })
    this.source = axios.CancelToken.source()
  }

    /**
     * Récupération des types de document officiel définis dans le BO
     * @returns Promise<OfficialDocumentTypeSearchResult|any>
     */
    getOfficialDocumentTypes = async (params?: OfficialDocumentTypeSearchParameters): Promise<OfficialDocumentTypeSearchResult|any> => {
      try {
        return await http.get('/api/official-documents-types', {
          params: params,
          cancelToken: this.source.token
        })
      } catch (error) {
        return Promise.resolve([])
      }
    }

    /**
     * Récupération d'un type de document officiel défini dans le BO
     * @returns Promise<OfficialDocumentType>
     */
    getOfficialDocumentType = async (id: number): Promise<OfficialDocumentType> => {
      try {
        return await http.get(`/api/official-documents-types/${id}`, {
          cancelToken: this.source.token
        })
      } catch (error) {
        return Promise.reject(error)
      }
    }

    /**
     * Annule une requête en attente
     */
    cancelRequest = () => {
      this.source.cancel()
      this.source = axios.CancelToken.source()
    }
}

export default new OfficialDocumentTypeService()
