import React, { FC, ReactNode, useContext } from 'react'
import {
  CardBlock,
  Deposit,
  Transaction,
  EventType
} from '@inpi-dm/components'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import DivisionDepositChildReproduction from './DivisionDepositChildReproduction'
import Accordion from 'react-bootstrap/Accordion'
import AccordionContext from 'react-bootstrap/AccordionContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'

interface ContextAwareToggleProps {
    eventKey: string,
}

const ContextAwareToggle : FC<ContextAwareToggleProps> = ({ eventKey }) => {
  const currentEventKey = useContext(AccordionContext)

  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <div className={isCurrentEventKey ? 'mr-1' : 'mr-2'}>
      <FontAwesomeIcon icon={isCurrentEventKey ? faChevronDown : faChevronRight} />
    </div>
  )
}

interface DivisionDepositChildProps extends WrappedComponentProps {
    deposit: Deposit,
    parent: Transaction,
    header: ReactNode,
    onChange?: (event: EventType) => void,
    indexChild: number,
}

const DivisionDepositChild: FC<DivisionDepositChildProps> = ({
  deposit,
  parent,
  header,
  onChange,
  indexChild
}) => {
  return (
    <Accordion defaultActiveKey='1'>
      <CardBlock className='mb-3' bodyClassName='p-3'>
        <Accordion.Toggle as='header' className='mx-3 d-flex justify-content-between cursor-pointer' eventKey='1'>
          <h1>{header}</h1>
          <ContextAwareToggle eventKey='1' />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey='1'>
          <>
            <CardBlock
              header={<FormattedMessage id='overview_deposit_card_models_title' />}
              bodyClassName='p-2'
              shadow
            >
              <DivisionDepositChildReproduction
                indexChildDeposit={indexChild}
                depositId={parent.id}
                models={deposit.models}
                priorities={parent.file.priorities}
                onChange={onChange}
              />
            </CardBlock>
          </>
        </Accordion.Collapse>
      </CardBlock>
    </Accordion>
  )
}

export default injectIntl(DivisionDepositChild)
