import { FormattedMessage } from 'react-intl'
import React, { FC } from 'react'
import {
  TextField,
  EventType,
  DEPOSIT_TYPE,
  INSCRIPTION_TYPE,
  PROROGATION_TYPE,
  APPEAL_TYPE,
  OFFICIAL_DOCUMENTS_TYPE
} from '@inpi-dm/components'
import { storeDepositUpdate } from '../../store/deposit/depositActions'
import { useDispatch } from 'react-redux'
import { storeInscriptionUpdate } from '../../store/inscription/inscriptionActions'
import { storeProrogationUpdate } from '../../store/prorogation/prorogationActions'
import { storeAppealUpdate } from '../../store/appeal/appealActions'
import { storeOfficialDocumentUpdate } from '../../store/officialDocument/officialDocumentActions'

interface InternalReferenceFieldProps {
  readOnly?: boolean,
  className?:string,
  procedureType: string,
  value?: string,
}

const InternalReferenceField: FC<InternalReferenceFieldProps> = ({
  readOnly = false,
  className,
  procedureType,
  value
}) => {
  const dispatch = useDispatch()
  /**
   * Enregistrement de la référence interne dans le store à la saisie
   * @param event
   */
  const handleChangeReference = (event: EventType) => {
    const { name, value } = event.target

    if (procedureType === DEPOSIT_TYPE.value) {
      dispatch(storeDepositUpdate({
        [name]: value
      }))
    } else if (procedureType === INSCRIPTION_TYPE.value) {
      dispatch(storeInscriptionUpdate({
        [name]: value
      }))
    } else if (procedureType === PROROGATION_TYPE.value) {
      dispatch(storeProrogationUpdate({
        [name]: value
      }))
    } else if (procedureType === APPEAL_TYPE.value) {
      dispatch(storeAppealUpdate({
        [name]: value
      }))
    } else if (procedureType === OFFICIAL_DOCUMENTS_TYPE.value) {
      dispatch(storeOfficialDocumentUpdate({
        [name]: value
      }))
    }
  }
  return (
    <div className={className}>
      <TextField
        type='internalReference'
        inputId='internalReference'
        label={<FormattedMessage id='field_internal_reference_label' />}
        value={value}
        onChange={handleChangeReference}
        readOnly={readOnly}
      />
    </div>
  )
}

export default InternalReferenceField
