import React, { FC, useEffect, useState } from 'react'
import {
  containsErrors,
  DATE_ISO,
  DateUtils,
  DEFAULT_TITLE_OFFICIAL_DOCUMENT,
  FilAriane,
  OFFICIAL_DOCUMENTS_TYPE,
  TITLE_INSCRIPTION_ORIGIN_FR,
  ContributorTitle, ModalComponent
} from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import StepContainer from '../../stepper/StepContainer'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import TransactionService from '../../../services/transaction/TransactionService'
import qs from 'qs'
import RecordService from '../../../services/record/RecordService'
import {
  storeOfficialDocumentFieldStatusReset,
  storeOfficialDocumentRemove, storeOfficialDocumentUpdate
} from '../../../store/officialDocument/officialDocumentActions'
import OfficialDocumentService from '../../../services/transaction/OfficialDocumentService'
import DepositorForm from '../../intervenant/DepositorForm'
import DepositorValidator from './depositor/validator/DepositorValidator'
import SelectRecordFormValidator from './record/validator/SelectRecordFormValidator'
import SelectOfficialDocumentRecords from './record/SelectOfficialDocumentRecords'
import OverviewFormOfficialDocument from './overview/OverviewFormOfficialDocument'
import OverviewFormOfficialDocumentValidator from './overview/validator/OverviewFormOfficialDocumentValidator'
import PaymentOfficialDocument from './payment/PaymentOfficialDocument'
import IntervenantService from '../../../services/transaction/IntervenantService'

import { storeContributorsTitleRemove, storeContributorsTitleUpdate } from '../../../store/contributorsTitle/contributorsTitleActions'
import ContentService from '../../../services/content/ContentService'

interface OfficialDocumentFormProps extends RouteComponentProps<{id: string}> {
  isNew?: boolean
}

const OfficialDocumentForm: FC<OfficialDocumentFormProps> = ({
  isNew = false,
  match,
  history,
  location
}) => {
  const dispatch = useDispatch()
  const officialDocument = useSelector((state : RootStateOrAny) => state.officialDocument)
  const officialDocumentFieldStatus = useSelector((state : RootStateOrAny) => state.officialDocumentFieldStatus) || []

  const [isNewOfficialDocument, setIsNewOfficialDocument] = useState(isNew)
  const [isReady, setIsReady] = useState(false)
  const [flatContributorsTitle, setFlatContributorsTitle] = useState<ContributorTitle[]>([])
  const [showRgpd, setShowRgpd] = useState(false)
  const [rgpdContent, setRdpdContent] = useState('')

  // Initialisation du document officiel à modifier
  useEffect(() => {
    dispatch(storeOfficialDocumentFieldStatusReset())
    dispatch(storeOfficialDocumentRemove())
    dispatch(storeContributorsTitleRemove())
    if (!isNew) {
      TransactionService.getTransaction(match.params.id)
        .then(result => {
          dispatch(storeOfficialDocumentUpdate(result))
        }).finally(() => setIsReady(true))
    } else {
      ContentService.getEditorialBlockByCode('RGPD-DO').then((res) => {
        if (res.content) {
          setRdpdContent(res.content)
          setShowRgpd(true)
        }
      })
    }

    // Si le document officiel a été faite à partir de titres, on récupère les ids dans l'url
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (query.from) {
      const idRecords = query.from.split(',')
      if (idRecords.length > 0) {
        initOfficialDocument(idRecords)
      } else {
        setIsReady(true)
      }
    } else {
      isNew && setIsReady(true)
    }
  }, [isNew])

  /**
   * Initialise le document officiel si celui ci a été faite à partir de titres
   * @param idRecords
   */
  const initOfficialDocument = (idRecords: string[]) => {
    let newContributorsTitle: [] = []
    const newTitlePromise = idRecords.map(async (idRecord: string) => {
      return RecordService.getRecord(idRecord).then(record => {
        if (record.numNat) {
          newContributorsTitle = {
            ...newContributorsTitle,
            [record.numNat]: {
              depositor: record.depositor,
              holders: record.holders,
              recipient: record.recipient
            }
          }
        }
        return {
          ...DEFAULT_TITLE_OFFICIAL_DOCUMENT,
          title: {
            origin: TITLE_INSCRIPTION_ORIGIN_FR,
            depositDate: DateUtils.formateDateToFormat(record.depositDate, DATE_ISO),
            numNat: record.numNat,
            publications: RecordService.getPublications(record)
          }
        }
      }
      )
    })
    Promise.all(newTitlePromise)
      .then(newTitles => {
        dispatch(storeOfficialDocumentUpdate({ ...officialDocument, officialDocument: { officialDocumentRequests: newTitles } }))
        dispatch(storeContributorsTitleUpdate(newContributorsTitle))
        // On récupère tous les intervenants de chaque titre
        setFlatContributorsTitle(IntervenantService.getFlatContributorsTitle())
      }
      ).finally(() => setIsReady(true))
  }

  /**
   * Si la dernière étape est valide, on submit le formulaire
   */
  const handleSubmit = () => {
    history.push('/')
  }

  /**
   * Sauvegarde des titres du document officiel
   */
  const saveOfficialDocumentFirstStep = () => {
    if (isNewOfficialDocument) {
      setIsNewOfficialDocument(false)
      // Creation puis sauvegarde
      return OfficialDocumentService.createOfficialDocument().then(() => {
        return OfficialDocumentService.updateOfficialDocumentBDDFromStore(['officialDocumentRequests'])
      })
    } else {
      // sauvegarde
      return OfficialDocumentService.updateOfficialDocumentBDDFromStore(['officialDocumentRequests'])
    }
  }

  /** Vérifie que tous les champs obligatoires sont remplis pour une étape du menu**/
  const checkValidity = (stepName: string): boolean => {
    const status = officialDocumentFieldStatus[stepName]
    if (status === undefined) {
      return false
    }
    return !containsErrors(status)
  }

  return (
    <>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id='breadcrumb_new_official_document' /></span>
      </FilAriane>
      <StepContainer
        className='mb-5 is-validated'
        listTitle={<FormattedMessage id='official_document_step_list_title' />}
        onCancel={() => history.push('/')}
        onSubmit={handleSubmit}
        views={[
          {
            id: 'titles',
            label: <FormattedMessage id='records_official_document' />,
            badge: '1',
            component: isReady ? <SelectOfficialDocumentRecords /> : <div />,
            validateGoToStep: SelectRecordFormValidator.validateOnChangeStep,
            onGoToStep: saveOfficialDocumentFirstStep,
            isValidated: checkValidity('officialDocumentRequests')
          },
          {
            id: 'intervenant',
            label: <FormattedMessage id='intervenant_form_title' />,
            badge: '2',
            isValidated: checkValidity('depositor')
          },
          {
            id: 'intervenant_applicant',
            label: <FormattedMessage id='intervenant_applicant' />,
            level: 1,
            validateGoToStep: DepositorValidator.validateOnChangeStep,
            component: (
              <DepositorForm
                object={officialDocument}
                fieldStatus={officialDocumentFieldStatus}
                procedureType={OFFICIAL_DOCUMENTS_TYPE.value}
                defaultIntervenantlist={flatContributorsTitle}
              />
            ),
            onGoToStep: () => OfficialDocumentService.updateOfficialDocumentBDDFromStore(['depositor'])
          },
          {
            id: 'overview',
            label: <FormattedMessage id='overview_deposit_form_short_title' />,
            badge: '3',
            component: <OverviewFormOfficialDocument />,
            isValidated: checkValidity('overviewSimilarInformation'),
            validateGoToStep: OverviewFormOfficialDocumentValidator.validateOnChangeStep,
            onGoToStep: () => OfficialDocumentService.updateOfficialDocumentBDDFromStore(['isAdministration', 'documents'])
          },
          {
            id: 'payment',
            label: <FormattedMessage id='payment' />,
            badge: '4',
            component: <PaymentOfficialDocument transaction={officialDocument} />,
            stepButtonsAsChildren: true
          }
        ]}
      />
      <ModalComponent
        title={<FormattedMessage id='rgpd_title' />}
        isNotCancellable
        customContent={() => <div dangerouslySetInnerHTML={{ __html: rgpdContent }} />}
        handleClose={() => setShowRgpd(false)}
        show={showRgpd}
        onClick={() => setShowRgpd(false)}
        customButtonId='common_start_form'
        size='xl'
      />
    </>
  )
}

export default withRouter(OfficialDocumentForm)
