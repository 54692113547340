import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage, IntlProvider, WrappedComponentProps } from 'react-intl'
import {
  AddressApiResult,
  AddressFields,
  AutocompleteResult, Country,
  DepositIntervenant,
  EventType,
  FieldStatus,
  PERSONNE_MORALE,
  PERSONNE_TYPES,
  PersonneContactFields,
  PersonneMoraleFields,
  PersonnePhysiqueFields,
  SelectButton,
  SelectField
} from '..'
import { Button } from 'react-bootstrap'
import Message from '../constants/Message'

interface PayerFormProps extends WrappedComponentProps {
    depositPayer: DepositIntervenant,
    intervenants?: AutocompleteResult<any>[] | null,
    handleSelectIntervenant: (intervenant: DepositIntervenant) => void,
    handlePayerForm: (payer: DepositIntervenant) => void,
    fieldStatus: FieldStatus,
    searchAddress: (query: string, type?: string|null, limit?: number|null) => Promise<void | AddressApiResult[]>,
    getCountries: () => Promise<void | Country[]>
}

const RenderPayerForm: FC<PayerFormProps> = ({
  intl,
  intervenants,
  handleSelectIntervenant,
  depositPayer,
  fieldStatus,
  handlePayerForm,
  searchAddress,
  getCountries
}) => {
  const [currentPayer, setCurrentPayer] = useState(depositPayer)

  useEffect(() => {
    setCurrentPayer(depositPayer)
  }, [depositPayer])

  /**
     * Enregistrement des modifications du payeur
     * @param event
     */
  const handleChanges = (event: EventType) => {
    const { name, value } = event.target

    const depositorPatches = {
      ...currentPayer,
      [name]: value
    }
    setCurrentPayer(depositorPatches)
  }

  return (
    <IntlProvider locale='fr' messages={Message}>
      <div className='form-header row mx-0'>
        <h2>
          <FormattedMessage id='intervenant_onglet_payer' />
        </h2>
        <div className='col options px-0 my-auto'>
          <SelectButton
            className='btn btn-link text-primary'
            options={intervenants || []}
            onSelect={handleSelectIntervenant}
          >
            <FormattedMessage id='intervenant_copier_donnees' />
          </SelectButton>
        </div>
      </div>

      <div className='header-field'>
        <FormattedMessage id='field_personne_type_label' />
      </div>

      <div className='row'>
        <div className='col-12 col-md-4'>
          <SelectField
            inputId='type'
            placeholder={intl.formatMessage({ id: 'placeholder_select' })}
            value={currentPayer.type || ''}
            options={PERSONNE_TYPES}
            onChange={handleChanges}
            fieldStatus={fieldStatus}
            required
          />
        </div>
      </div>

      {currentPayer.type === PERSONNE_MORALE.value && (
        <PersonneMoraleFields
          intervenant={currentPayer}
          onChange={handleChanges}
          fieldStatus={fieldStatus}
          intl={intl}
          showSiren
        />
      )}

      {currentPayer.type !== PERSONNE_MORALE.value && (
        <PersonnePhysiqueFields
          intervenant={currentPayer}
          onChange={handleChanges}
          fieldStatus={fieldStatus}
          intl={intl}
        />
      )}

      <PersonneContactFields
        intervenant={currentPayer}
        onChange={handleChanges}
        fieldStatus={fieldStatus}
      />

      <AddressFields
        onChange={handleChanges}
        address={(currentPayer && currentPayer.address) || undefined}
        fieldStatus={fieldStatus}
        intl={intl}
        searchAddress={searchAddress}
        getCountries={getCountries}
      />
      <div className='d-flex justify-content-around mt-2'>
        <Button className='primary' onClick={() => handlePayerForm(currentPayer)}>
          <FormattedMessage id='common_validate' />
        </Button>
      </div>
    </IntlProvider>
  )
}

export default RenderPayerForm
