import React, { useState, useEffect, FC } from 'react'
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import {
  APPEAL_TYPE,
  APPEAL_TYPE_ACT, APPEAL_TYPE_SUPPORTING_DOCUMENT,
  buildEventType,
  CardBlock,
  CheckboxField,
  DepositDocument,
  DOCUMENT_TYPES,
  EventType,
  TextField, Transaction
} from '@inpi-dm/components'
import { storeAppealUpdate } from '../../../../store/appeal/appealActions'
import AppealDecommission from './AppealDecommission'
import DocumentAdditionalParts from '../../../prorogation/form/requestDetails/DocumentAdditionalParts'
import StoreService from '../../../../services/StoreService'
import TransactionService from '../../../../services/transaction/TransactionService'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'

interface DetailsFormProps {
  additionalParts: DepositDocument[],
  setAdditionalParts: (additionalParts: DepositDocument[]) => void,
  acteDocuments: DepositDocument[],
  setActeDocuments: (acteDocuments: DepositDocument[]) => void,
  appealDocuments: DepositDocument[],
  setAppealDocuments: (appealDocuments: DepositDocument[]) => void
}

const DetailsForm : FC<DetailsFormProps> = ({
  additionalParts,
  setAdditionalParts,
  acteDocuments,
  setActeDocuments,
  appealDocuments,
  setAppealDocuments
}) => {
  const dispatch = useDispatch()
  const transaction = useSelector((state : RootStateOrAny) => state.appeal)
  const fieldStatus = useSelector((state : RootStateOrAny) => state.appealFieldStatus) || {}

  const [isCheckAppealInscription, setIsCheckAppealInscription] = useState<boolean>()

  useEffect(() => {
    if (transaction.appeal.inscriptionRequests?.length > 0) {
      setIsCheckAppealInscription(true)
    }
  }, [])

  /**
   * Enregistrement à la saisie dans le store
   * @param event
   */
  const handleChanges = (event: EventType) => {
    const { name, value } = event.target

    dispatch(storeAppealUpdate({
      ...transaction,
      appeal: {
        ...transaction.appeal,
        [name]: value
      }
    }))
  }

  /**
   * Permet de supprimer le fichier associé au type de pièce justificative
   * @param event
   * @param index
   */
  const deleteDocument = async (event:EventType, index:number) => {
    const updateDocuments = [...transaction.documents, ...appealDocuments]
    if (updateDocuments[index].internalName) {
      return await TransactionService.deleteDocumentFile(transaction.id, transaction.documents[index].internalName).then((transactionWithDocument: Transaction) => {
        StoreService.changeStore(transactionWithDocument.procedureType, { ...transaction, documents: transactionWithDocument.documents })
      }
      )
    } else {
      updateDocuments.splice(index, 1)
      setAppealDocuments(updateDocuments)
    }
  }

  /**
   * Permet d'ajouter le fichier
   * @param event
   */
  const addDocument = (event:EventType) => {
    const file = event.target.value
    const document : DepositDocument = {
      file: file,
      name: file.name,
      format: file.format,
      type: DOCUMENT_TYPES.DEPOSIT_CORRECTION_SUPPORT,
      needPost: true
    }
    setAppealDocuments([...appealDocuments, document])
  }

  return (
    <div>
      <div className='row ml-3'>
        <header className='col-8 align-self-center'>
          <h1><FormattedMessage id='appeal_details_title' /></h1>
        </header>
        <InternalReferenceField
          value={transaction.internalReference}
          procedureType={APPEAL_TYPE.value}
          className='col-4 mb-4'
        />
      </div>
      <div className='row'>
        <div className='col-12 mb-3'>
          <CardBlock shadow>
            <div className='row'>
              <div className='col-12'>
                <CheckboxField
                  className='mb-1'
                  inputId='checkAppealInscriptionRNDM'
                  label={<FormattedMessage id='appeal_inscription_rndm' />}
                  checked={isCheckAppealInscription}
                  onChange={(e) => {
                    setIsCheckAppealInscription(e.target.value)
                    handleChanges(buildEventType('inscriptionRequests', []))
                  }}
                />
              </div>
              {
                isCheckAppealInscription &&
                  <div className='col-12 col-md-6'>
                    <TextField
                      inputId='inscriptionRequests'
                      value={(transaction.appeal?.inscriptionRequests && transaction.appeal?.inscriptionRequests[0] && transaction.appeal?.inscriptionRequests[0].numOrder) || ''}
                      onChange={(eventText:EventType) => {
                        const { name, value } = eventText.target
                        handleChanges(buildEventType(name, [{ numOrder: value }]))
                      }}
                      label={<FormattedMessage id='appeal_inscription_rndm_label' />}
                    />
                  </div>
              }
            </div>
          </CardBlock>
        </div>
        <div className='col-12 mb-3'>
          <AppealDecommission
            transaction={transaction}
            deleteDocument={deleteDocument}
            addDocument={addDocument}
            handleChanges={handleChanges}
            fieldStatus={fieldStatus}
            appealDocuments={appealDocuments}
          />
        </div>
        <div className='col-12 mb-3'>
          <div>
            <DocumentAdditionalParts
              idLabelBloc='appeal_document_acte_label'
              procedureType={APPEAL_TYPE_ACT.value}
              transaction={transaction}
              fieldStatus={fieldStatus.acteDocuments || []}
              additionalParts={acteDocuments}
              setAdditionalParts={setActeDocuments}
            />
          </div>
        </div>
        <div className='col-12 mb-3'>
          <DocumentAdditionalParts
            idLabelBloc='appeal_document_supporting_label'
            procedureType={APPEAL_TYPE_SUPPORTING_DOCUMENT.value}
            transaction={transaction}
            fieldStatus={fieldStatus.additionalParts || []}
            additionalParts={additionalParts}
            setAdditionalParts={setAdditionalParts}
          />
        </div>
      </div>
    </div>
  )
}

export default injectIntl(DetailsForm)
