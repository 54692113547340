import React, { FC, useState } from 'react'
import { SelectField, EventType, FieldStatus } from '@inpi-dm/components'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'

interface AssignPickerProps extends WrappedComponentProps {
    users: { value: string, label: string }[]
    onSelect: (id: string| undefined) => void,
    fieldStatus: FieldStatus
}

const AssignPicker: FC<AssignPickerProps> = ({
  users,
  onSelect,
  fieldStatus,
  intl
}) => {
  const [user, setUser] = useState()

  const handleChange = (id: string) => {
    setUser(id)
    onSelect(id)
  }

  return (
    <div className='is-validated row w-100'>
      <div className='col-12'>
        <SelectField
          label={<FormattedMessage id='ets_account_request_pm_new_account' />}
          inputId='assign'
          placeholder={intl.formatMessage({ id: 'select_placeholder' })}
          value={user}
          onChange={(event: EventType) => handleChange(event.target.value)}
          fieldStatus={fieldStatus}
          options={users}
          required
        />
      </div>
      <div className='col-12 text-primary mt-2'>
        <FormattedMessage className='text-danger' id='ets_account_request_pm_no_account' />
      </div>
    </div>
  )
}

export default injectIntl(AssignPicker)
