import React, { FC, ReactNode, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { DropDownItem } from '..'
import Message from '../constants/Message'

interface HeaderDropdownProps {
  items?:DropDownItem[],
  name?:ReactNode,
  className?:string,
  replaceTitleOnSelect?:boolean,
  onSelect?:(item:DropDownItem) => void,
  arrow?:boolean | null,
  buttonClassName?:string | null
}

const Dropdown:FC<HeaderDropdownProps> = ({
  items,
  name,
  className,
  replaceTitleOnSelect = false,
  onSelect,
  arrow,
  buttonClassName
}) => {
  const [buttonLabel, setButtonLabel] = useState(name)
  const [dropped, setDropped] = useState(false)

  useEffect(() => { setButtonLabel(name) }, [name])

  const handleClickItem = (item:DropDownItem) => {
    setDropped(false)
    replaceTitleOnSelect && setButtonLabel(item.label)
    onSelect && onSelect(item)
  }

  return (
    <IntlProvider locale='fr' messages={Message}>
      <li className={'nav-dropdown ' + (className ?? '')} onBlur={() => setDropped(false)}>
        <button
          className={'btn btn-link ' + (buttonClassName || '')} type='button'
          onClick={() => setDropped(!dropped)}
        >
          {buttonLabel &&
            <span className={arrow ? 'mr-1' : ''}>
              {buttonLabel}
            </span>}
          {
            arrow && <FontAwesomeIcon icon={faChevronDown} size='xs' />
          }
        </button>

        <div className='position-relative'>
          {dropped
            ? (
              <div className='header-dropdown-menu'>
                {
                  items && items.map((item, index) => {
                    if (item.failedCondition && item.failedCondition.length > 0) {
                      return (
                        <OverlayTrigger
                          placement='bottom'
                          key={index}
                          overlay={
                            <Popover id='popover-positioned-bottom'>
                              <Popover.Title as='h3'><FormattedMessage id='request_actions_error' /></Popover.Title>
                              <Popover.Content>
                                {
                                  <dl>{item.failedCondition.map((condition, i) => <dd key={i} className='ml-0 pl-0'>{condition}</dd>)}</dl>
                                }
                              </Popover.Content>
                            </Popover>
                          }
                        >
                          <div
                            key={index}
                            className='header-dropdown-item disabled cursor-default'
                            onMouseDown={() => null}
                          >
                            {item.label}
                          </div>
                        </OverlayTrigger>)
                    } else {
                      return (
                        <div
                          key={index}
                          className='header-dropdown-item cursor-pointer'
                          onMouseDown={() => handleClickItem(item)}
                        >
                          {item.label}
                        </div>
                      )
                    }
                  }
                  )
                }
              </div>
            )
            : null}
        </div>
      </li>
    </IntlProvider>
  )
}

export default Dropdown
