import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Modal from 'react-bootstrap/Modal'
import {
  DropArea,
  LocarnoModal,
  SubmitButton,
  TextField,
  ModelDeposit,
  ModelDesignation,
  containsErrors, MultipleRadioField
} from '@inpi-dm/components'
import { LocarnoVersion } from '../../../../../interfaces/LocarnoInterfaces'
import ModelFormValidator from './validator/ModelFormValidator'
import LocarnoService from '../../../../../services/content/LocarnoService'
import { BLACK_AND_WHITE, COLORS } from '../../../../../constants/DepositConstants'
import { isIE } from 'react-device-detect'

interface ModelModalMassImportProp {
    show: boolean,
    handleClose: () => void,
    onClick: (models: ModelDeposit[]) => void
    versionLocarno: LocarnoVersion,
    progress: number,
    setProgress: (progress: number) => void,
    nbRepros: number
}

const ModelModalMassImport: FC<ModelModalMassImportProp> = ({
  show,
  handleClose,
  onClick,
  versionLocarno,
  progress,
  nbRepros,
  setProgress
}) => {
  const [reproductions, setReproductions] = useState<File[]>([])
  const [designation, setDesignation] = useState<ModelDesignation>()
  const [reproLabel, setReproLabel] = useState('')
  const [reproColor, setReproColor] = useState('')
  const [showLocarno, setShowLocarno] = useState(false)
  const [fieldStatus, setFieldStatus] = useState({})

  useEffect(() => {
    // reset de la fenêtre à sa réouverture
    setReproductions([])
    setReproLabel('')
    setReproColor('')
    setDesignation(undefined)
    setProgress(0)
  }, [show])

  /**
     * Création des modeles contenants : designation et 1 file
     * @param files
     */
  const onDropReproductions = (files: File[]) => {
    setReproductions(reproductions => [...reproductions, ...files])
  }

  /**
     * Envoyer les modeles
     */
  const sendModels = () => {
    const newFieldStatus = ModelFormValidator.validateModelMassImport(nbRepros, designation, reproductions)
    setFieldStatus(newFieldStatus)

    if (!containsErrors(newFieldStatus)) {
      const models = reproductions.map(repro => ({
        modelDesignation: designation,
        priorityId: '',
        reproductions: [{
          file: repro,
          color: reproColor,
          label: reproLabel,
          description: ''
        }]
      }))
      return onClick([...models])
    } else {
      return newFieldStatus
    }
  }

  // On sort la modale Locarno de la modale d'import sinon le champs de recherche
  // de Locarno ne fonctionne plus
  return (
    <>
      <LocarnoModal
        show={showLocarno}
        handleClose={() => setShowLocarno(false)}
        onClick={setDesignation}
        listLocarno={versionLocarno.categories}
        searchProducts={LocarnoService.searchProducts}
      />

      <Modal show={show && !showLocarno} onHide={handleClose} size='xl' scrollable>
        <Modal.Header closeButton className='bg-primary text-white border-bottom-0'>
          <Modal.Title>
            <FormattedMessage id='mass_import_modeles' />
            <div className='subtitle text-white'><FormattedMessage id='mass_import_modeles_description' /></div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='is-validated'>
          <div className='row'>
            {
              designation && designation.ref &&
                <div className='col-2'>
                  <TextField
                    inputId='modelDesignationRef'
                    label={<FormattedMessage id='field_model_classification_label' />}
                    value={designation.ref}
                    maxLength='50'
                    readOnly
                  />
                </div>
            }
            <div className='col-10 col-md-6'>
              <TextField
                inputId='modelDesignation'
                label={<FormattedMessage id='field_model_designation_label' />}
                value={(designation && designation.name) || ''}
                onChange={(event) => setDesignation({ name: event.target.value })}
                fieldStatus={fieldStatus}
                maxLength='255'
                required
              />
            </div>
            <div className='col-12 col-md-4 d-flex mt-07'>
              <SubmitButton
                className='btn-outline-primary'
                onClick={() => setShowLocarno(true)}
              >
                <FormattedMessage id='button_locarno_popup' />
              </SubmitButton>
            </div>
          </div>
          <div className='col-12'>
            <DropArea
              accept={isIE ? undefined : process.env.REACT_APP_ACCEPTED_ZIP_FORMAT}
              onDrop={onDropReproductions}
            />
          </div>
          <div className='row align-items-center'>
            <div className='col-7'>
              <TextField
                inputId='reproductionsLabel'
                label={<FormattedMessage id='mass_reproductions_form_label' />}
                value={reproLabel}
                fieldStatus={fieldStatus}
                nameFieldStatus='reproductionLabel'
                onChange={(e) => setReproLabel(e.target.value)}
                maxLength='100'
              />
            </div>
            <div className='col-5'>
              <MultipleRadioField
                inputId='reproductionsColor'
                fieldStatus={fieldStatus}
                nameFieldStatus='reproductionColor'
                radio={[
                  {
                    label: <FormattedMessage id='reproductions_form_radio_colors' />,
                    value: COLORS
                  },
                  {
                    label: <FormattedMessage id='reproductions_form_radio_no_colors' />,
                    value: BLACK_AND_WHITE
                  }]}
                value={reproColor}
                onChange={(e) => setReproColor(e.target.value)}
              />
            </div>
          </div>
          {
            fieldStatus.reproductions &&
              <div className='validation-error mt-n4'>{fieldStatus.reproductions}</div>
          }
          {
            reproductions && reproductions.length !== 0 &&
              <div>
                <div>{reproductions.length}<FormattedMessage id='mass_import_modeles_info' /></div>
                <div className='progress'>
                  <div
                    className='progress-bar' role='progressbar' aria-valuenow={progress} aria-valuemin={0}
                    aria-valuemax={reproductions.length} style={{ width: progress === 0 ? 0 : `${(progress + 1) / reproductions.length * 100}%` }}
                  />
                </div>
              </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton
            className='btn-primary'
            onClick={() => sendModels()}
          >
            <FormattedMessage id='button_validate' />
          </SubmitButton>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModelModalMassImport
