import store from '../../../../../store/store'
import { containsErrors, IntervenantValidator } from '@inpi-dm/components'
import { storeProrogationFieldStatusUpdate } from '../../../../../store/prorogation/prorogationActions'

class RecipientValidator {
  validateOnChangeStep = () => {
    const prorogation = store.getState().prorogation

    const fieldStatus = IntervenantValidator.validateRecipient(prorogation.recipient)
    store.dispatch(storeProrogationFieldStatusUpdate({
      recipient: fieldStatus
    }))

    return !containsErrors(fieldStatus)
  }
}

export default new RecipientValidator()
