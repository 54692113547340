import Message from './Message'

export const ROWS_10 = {
  value: 10,
  label: Message.request_10_rows
}

export const ROWS_25 = {
  value: 25,
  label: Message.request_25_rows
}

export const ROWS_50 = {
  value: 50,
  label: Message.request_50_rows
}

export const ROWS_ALL_RESULT = {
  value: -1,
  label: Message.request_all_result
}

export const ROWS = [
  ROWS_10,
  ROWS_25,
  ROWS_50
]
