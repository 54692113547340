import { isFilled } from '@inpi-dm/components'
import Message from '../../../constants/Message'
import { ETSAccountRequestCredentials } from '../../../interfaces/ETSAccountInterface'

class ETSAccountRequestCredentialsValidator {
    /**
     * Valide le formulaire de demande de rattachement par code d'accès
     * @param ETSAccountRequestCredentials
     */
    validateForm = (etsAccountRequestCredentials: ETSAccountRequestCredentials) => {
      const fieldStatus: ETSAccountRequestCredentials = {}
      if (!isFilled(etsAccountRequestCredentials.code)) {
        fieldStatus.code = Message.required_field
      }
      if (!isFilled(etsAccountRequestCredentials.password)) {
        fieldStatus.password = Message.required_field
      }
      return fieldStatus
    }
}

export default new ETSAccountRequestCredentialsValidator()
