import React, { useState, useEffect, FC, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  TextField,
  SubmitButton,
  DropArea,
  DragDrop,
  SelectField,
  ErrorField,
  ModalComponent,
  FieldStatus,
  LocarnoModal,
  SelectOption,
  isFilled,
  ReproductionLightDeposit,
  ModelDeposit,
  ModelDesignation,
  LocarnoVersion,
  containsErrors
} from '@inpi-dm/components'
import ModelFormValidator from './validator/ModelFormValidator'
import { RootStateOrAny, useSelector } from 'react-redux'
import Reproduction from '../reproduction/Reproduction'
import Accordion from 'react-bootstrap/Accordion'
import Message from '../../../../../constants/Message'
import { DepositPriorityOptions } from '../../../../../interfaces/DepositInterfaces'
import ContentService from '../../../../../services/content/ContentService'
import DepositService from '../../../../../services/transaction/DepositService'
import LocarnoService from '../../../../../services/content/LocarnoService'
import ReproductionFormValidator from '../reproduction/validator/ReproductionFormValidator'
import { storeDepositUpdate } from '../../../../../store/deposit/depositActions'
import store from '../../../../../store/store'

interface ModelFormProps {
    index: number,
    model?: ModelDeposit,
    changeModel: boolean,
    fieldStatus: FieldStatus,
    submitModel: (model: ModelDeposit) => void,
    onCancelFormModel: () => void,
    versionLocarno : LocarnoVersion,
    models: ModelDeposit[],
    nbRepros: number,
}

const ModelForm : FC<ModelFormProps> = ({
  models,
  index,
  model = { position: index },
  changeModel,
  fieldStatus,
  submitModel,
  onCancelFormModel,
  versionLocarno,
  nbRepros
}) => {
  const [reproductions, setReproductions] = useState(model.reproductions || [])
  const [designation, setDesignation] = useState<ModelDesignation>(model.modelDesignation || { name: '' })
  const [priorityId, setPriorityId] = useState(model.priorityId || '')

  const [prioritiesOptions, setPrioritiesOptions] = useState<SelectOption[]>([])

  const [show, setShow] = useState(false)
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [accordionIndex, setAccordionIndex] = useState()
  const [isNew] = useState(index > 1 && !model)

  const [fieldStatusReproForm, setFieldStatusReproForm] = useState<FieldStatus[]>()
  const reproRefs = useRef([])

  const deposit = useSelector((state : RootStateOrAny) => state.deposit)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useEffect(() => {
    let unmounted = false
    // Initialisation des options de priorités
    if (deposit.priorities) {
      ContentService.getCountries().then(countries => {
        // la liste est composée de toute les priorites et on affiche : label(numero) - Pays : numero origianl du dépot
        if (!unmounted) {
          setPrioritiesOptions(deposit.priorities.map(
            (elem: DepositPriorityOptions, index: number) => {
              const country = countries.find(c => c.code === elem.countryOrOrganization)
              return {
                value: elem.id,
                label: `${index + 1} - ${country && country.label} : ${elem.originalDepositNumber}`
              }
            }) || []
          )
        }
      })
    }
    return () => {
      ContentService.cancelRequest()
      unmounted = true
    }
  }, [])

  useEffect(() => {
    setReproductions(model.reproductions || [])
    setDesignation(model.modelDesignation || { name: '' })
    setPriorityId(model.priorityId || '')
    setFieldStatusReproForm(new Array(model.reproductions?.length || 0))
    let indexAccordion = model?.reproductions?.length
    for (const [indexRepro, reproduction] of model?.reproductions?.entries() || []) {
      if ((!isFilled(reproduction.label) || !isFilled(reproduction.color))) {
        indexAccordion = indexRepro
        break
      }
    }
    setAccordionIndex(indexAccordion)
  }, [changeModel])

  /**
     * Sélection d'une classe secondaire pour la désignation du modèle ou d'une saisie libre
     * @param value
     */
  const handleChangeLocarno = (value: ModelDesignation) => {
    setDesignation(value)

    // Tous les modèles doivent avoir la même classe principale
    if (models.length > 0) {
      ModelFormValidator.onChangeDesignationLocarno(value, index, models)
    }
  }

  /**
     * Fonction appelée lors du drop des images par l'utilisateur
     * @param files images acceptées par le drag and drop
     */
  const onDropReproductions = (files: File[]) => {
    const newRepros = files.map((file) => ({ file: file }))
    setReproductions(reproductions => [...reproductions, ...newRepros])
    setFieldStatusReproForm(old => [...old, ...new Array(files.length)])
  }

  /**
     * Fonction appelée lors d'un changement d'ordre dans les reproductions (drag and drop)
     * @param items les reproductions
     */
  const onChangeOrder = (items) => {
    const newItems = items.map(item => item.content.props.children.props.repro)
    setReproductions([...newItems])
  }

  /**
     * Fonction appelée lors de la suppression de toutes les reproductions
     */
  const deleteAllReproduction = () => {
    setShowModalDelete(false)

    // Suppression des reproductions qui sont dans le back
    if (model.id && model.reproductions) {
      model.reproductions.forEach((repro) => {
        if (repro.file && repro.file.internalName) {
          DepositService.deleteReproduction(deposit.idTransaction, model.id, repro.file.internalName)
        }
      })
    }
    setReproductions([])
    setAccordionIndex(0)
    setFieldStatusReproForm([])
  }

  /**
     * Fonction appelée lors de la suppression d'une reproduction dans la liste
     * @param indexRepro
     */
  const deleteReproduction = (indexRepro: number) => {
    const items = [...reproductions]
    items.splice(indexRepro, 1)
    setReproductions(items)
    const fieldStatus = [...fieldStatusReproForm]
    fieldStatus.splice(indexRepro, 1)
    setFieldStatusReproForm(fieldStatus)

    if (model.id && model.reproductions && model.reproductions[indexRepro] &&
            model.reproductions[indexRepro].file && model.reproductions[indexRepro].file.internalName) {
      DepositService.deleteReproduction(deposit.idTransaction, model.id, model.reproductions[indexRepro].file.internalName)
        .then(transactionAfterDelete => {
          const newDepositInStore = { ...store.getState().deposit, models: transactionAfterDelete?.file?.models }
          store.dispatch(storeDepositUpdate(newDepositInStore))
        })
    }
  }

  /**
     * Contenu de la liste des reproductions
     * @returns {any}
     */
  const displayReproductions = () => {
    return reproductions.map((repro, i) => (
      {
        id: repro.file?.name,
        content: (
          <div ref={(element) => reproRefs.current.push(element)}>
            <Reproduction
              key={i}
              reproRefs={reproRefs}
              idTransaction={deposit.idTransaction}
              idModel={model?.id}
              indexModel={index}
              index={i}
              repro={repro}
              onDelete={deleteReproduction}
              accordionIndex={accordionIndex}
              setAccordionIndex={(index) => setAccordionIndex(index.toString())}
              isLastReproduction={i + 1 === reproductions.length}
              validatorFormReproduction={validatorFormReproduction}
              handleChangeReproduction={handleChangeReproduction}
              fieldStatus={(fieldStatusReproForm && fieldStatusReproForm[i]) || {}}
            />
          </div>
        )
      }
    ))
  }

  /**
     * Ajout de la reproduction
     * @param index
     * @param information
     */
  const addReproduction = (index: number, information: ReproductionLightDeposit) => {
    const repros = [...reproductions]
    repros[index] = {
      ...repros[index],
      position: index.toString(),
      label: information.label || '',
      color: information.color || '',
      description: information.description || ''
    }
    setReproductions(repros)
    setAccordionIndex(index + 1)
  }

  /**
     * Valide un modele et enregistre le fieldStatus dans le store
     */
  const validatorFormModel = () => {
    reproductions.forEach((repro, indexRepro) => validatorFormReproduction(indexRepro))

    const modelToValidate = {
      id: model.id,
      priorityId: priorityId,
      modelDesignation: designation,
      reproductions: reproductions.map((r, indexRepro) => ({ position: indexRepro, ...r }))
    }
    const okValidate = ModelFormValidator.validateOnNext(modelToValidate, index, models, nbRepros)

    if (okValidate) {
      return submitModel(modelToValidate)
    } else {
      return null
    }
  }

  /**
     * Changer le label/description/couleur d'une reproduction
     * @param index
     * @param elemToChange
     */
  const handleChangeReproduction = (index: number, elemToChange: object) => {
    if (reproductions && reproductions[index]) {
      const withChange = { ...reproductions[index], ...elemToChange }
      setReproductions(old => old?.map((elem, indexRepro) => {
        if (indexRepro === index) {
          elem = withChange
        }
        return elem
      }))
    }
  }

  /**
     * Verifie si la reproduction est correcte
     * @param index
     */
  const validatorFormReproduction = (index: number, reproductionOverride: ReproductionLightDeposit | undefined = undefined) : boolean => {
    const reproduction : ReproductionLightDeposit = { position: index, ...(reproductionOverride ?? reproductions[index]) }
    const fieldStatus = ReproductionFormValidator.validateMandatory(reproduction)
    setFieldStatusReproForm(old => old?.map((elem, indexRepro) => {
      if (indexRepro === index) {
        elem = fieldStatus
      }
      return elem
    }))

    const isOk = !containsErrors(fieldStatus)

    if (isOk) {
      // Ajouter cette reproduction dans le model
      addReproduction(index, reproduction)
    }

    return isOk
  }

  return (
    <div className='list-group-item form-only-top-border mt-2'>
      <div className='row'>
        <h3 className='col-12 col-md-6'>
          <FormattedMessage id='model_deposit_form_number' />{index + 1}
        </h3>
        {isFilled(deposit.priorities) &&
          <div className='col-12 col-md-6 d-flex flex-row justify-content-between'>
            <div className='col-6 mt-2 text-right'>
              <FormattedMessage id='field_number_priority_label' />
            </div>
            <div className='col-6 w-100'>
              <SelectField
                inputId='priorityId'
                value={priorityId}
                onChange={(e) => setPriorityId(e.target.value)}
                options={prioritiesOptions}
                fieldStatus={fieldStatus}
                placeholder={Message.select_priority}
              />
            </div>
          </div>}
      </div>
      <div className='row'>
        {
          designation.ref &&
            <div className='col-12 col-md-2'>
              <TextField
                inputId='modelDesignation'
                label={<FormattedMessage id='field_model_classification_label' />}
                value={designation.ref}
                maxLength='50'
                readOnly
              />
            </div>
        }
        <div className='col-12 col-md-6'>
          <TextField
            inputId='modelDesignation'
            label={<FormattedMessage id='field_model_designation_label' />}
            value={designation.name}
            fieldStatus={fieldStatus}
            onChange={(e) => handleChangeLocarno({ name: e.target.value })}
            maxLength='100'
            required
            infoHelp={<FormattedMessage id='field_model_designation_info' />}
          />
        </div>
        <div className='col-12 col-md-4 d-flex mt-07'>
          <SubmitButton
            className='btn-outline-primary'
            onClick={handleShow}
          >
            <FormattedMessage id='button_locarno_popup' />
          </SubmitButton>
          <LocarnoModal
            show={show}
            handleClose={handleClose}
            onClick={handleChangeLocarno}
            listLocarno={versionLocarno.categories}
            searchProducts={LocarnoService.searchProducts}
          />
        </div>
      </div>
      {
        reproductions.length > 0 &&
          <div className='w-100 d-flex flex-row justify-content-end'>
            <SubmitButton
              className='btn-link text-primary'
              onClick={() => setShowModalDelete(true)}
            >
              <FormattedMessage id='delete_all_reproductions' />
            </SubmitButton>
            <ModalComponent
              title={<FormattedMessage id='delete_all_reproductions' />}
              show={showModalDelete}
              customContent={() => (<FormattedMessage id='delete_all_reproductions_body' />)}
              onClick={() => deleteAllReproduction()}
              handleClose={() => setShowModalDelete(false)}
            />
          </div>
      }
      <div className='row'>
        <div className='col-12'>
          <DropArea
            accept={process.env.REACT_APP_ACCEPTED_IMAGE_FORMAT}
            maxSize={process.env.REACT_APP_ACCEPTED_IMAGE_SIZE}
            onDrop={onDropReproductions}
          />
        </div>
      </div>
      <div className='row mb-4'>
        <div className='col-12'>
          <Accordion activeKey={accordionIndex?.toString()} defaultActiveKey='0'>
            <DragDrop items={displayReproductions()} onChangeOrder={onChangeOrder} />
          </Accordion>
          {fieldStatus && <ErrorField message={fieldStatus.reproductions} />}
        </div>
      </div>
      <div className='row justify-content-end'>
        {isNew && (
          <div className='col-4 mr-auto'>
            <SubmitButton
              className='btn-block btn-outline-gris'
              onClick={onCancelFormModel}
            >
              <FormattedMessage id='button_cancel' />
            </SubmitButton>
          </div>
        )}
        <div className='col-4'>
          <SubmitButton
            className='btn-block btn-outline-primary'
            onClick={validatorFormModel}
          >
            <FormattedMessage id='button_validate_model' />
          </SubmitButton>
        </div>
      </div>
    </div>
  )
}

export default ModelForm
