import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  CardBlock,
  Transaction,
  ProtectionExtension, DepositDocument, Title, STATUS_PROROGATION
} from '@inpi-dm/components'
import OverviewLabelText from '../../../deposit/form/overview/OverviewLabelText'

interface OverviewProrogationRequestDetailsProps {
  transaction: Transaction
}

const OverviewProrogationRequestDetails: FC<OverviewProrogationRequestDetailsProps> = ({
  transaction
}) => {
  /**
   * Vérifie qu'il y ait au moins une extension de protection d'extension active dans la transaction
   */
  const hasProtectionExtension = () => {
    const bool = transaction.prorogation?.titles.filter(title => title.protectionExtensions && title.protectionExtensions.filter(extension => extension).length > 0)
    return bool && bool.length > 0
  }
  return (
    <CardBlock
      header={
        <div className='d-flex justify-content-between'>
          <FormattedMessage id='prorogation_request_details_title' />
        </div>
      }
      shadow
    >
      {transaction.prorogation && transaction.prorogation.isAppealOrDecommission && (
        <div className='col-12 mb-3'>
          {transaction.prorogation.appealDecommissionNumber && transaction.prorogation.appealDecommissionNumber !== '' ? (
            <span><FormattedMessage id='prorogation_appeal_decommission_with_number_recap' />{transaction.prorogation.appealDecommissionNumber}</span>
          ) : (
            <FormattedMessage id='prorogation_appeal_decommission_recap' />
          )}
        </div>
      )}
      {transaction.prorogation && transaction.prorogation.inscriptionRequests.length > 0 && (
        <div className='col-12'>
          <FormattedMessage id='prorogation_with_inscription_requests_recap' />
          <ul>
            {transaction.prorogation.inscriptionRequests.map((inscriptionRequest, index) => (
              <li key={`inscription-request-${index}`}>
                {inscriptionRequest.numOrder && inscriptionRequest.numOrder !== '' && <span><FormattedMessage id='prorogation_order_number' /> : {inscriptionRequest.numOrder}</span>}
                {inscriptionRequest.numInscription && inscriptionRequest.numInscription !== '' && <span>{inscriptionRequest.numOrder ? ' / ' : ' '}<FormattedMessage id='prorogation_inscription_number' /> : {inscriptionRequest.numInscription}</span>}
              </li>
            ))}
          </ul>
        </div>
      )}
      <div>
        {hasProtectionExtension() && (
          <OverviewLabelText idLabel='overview_deposit_protection_extension_title'>
            {transaction.prorogation && transaction.prorogation.titles.map((title: Title, indexTitle: number) =>
              title.protectionExtensions && title.protectionExtensions.filter(extension => extension).map((extension: ProtectionExtension, index: number) => {
                return (
                  <div key={`${indexTitle}-${index}`}>
                    {transaction.status === STATUS_PROROGATION.DRAFT_CLIENT &&
                      <FormattedMessage id='prorogation_numnat' values={{ numnat: title.numNat }} />}
                    <FormattedMessage id='details_prorogation_form_extensions_subtitle' /> {extension.label}
                  </div>)
              }))}
          </OverviewLabelText>
        )}
      </div>
      <div className='col-12'>
        <h4><FormattedMessage id='prorogation_additional_parts' /></h4>
        {transaction.documents && transaction.documents.length > 0
          ? (
            <ul>
              {
                transaction.documents.filter((document: DepositDocument) => document.isAddByFo).map((document: DepositDocument, index: number) => {
                  return (
                    <li key={index}>
                      {document.name} - {document.type}
                    </li>
                  )
                })
              }
            </ul>)
          : (<span className='subtitle'><FormattedMessage id='overview_prorogation_no_boolean' /></span>)}
      </div>
    </CardBlock>
  )
}

export default OverviewProrogationRequestDetails
