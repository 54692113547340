import React, { FC, useEffect, useState } from 'react'
import { Payment } from '../../../../interfaces/DepositInterfaces'
import OverviewRoyalties from '../../../payment/OverviewRoyalties'
import { PAYMENT_OBJECT } from '../../../../constants/DepositConstants'
import { FormattedMessage } from 'react-intl'
import PaymentForm from '../../../payment/PaymentForm'
import { Transaction } from '@inpi-dm/components'
import InscriptionService from '../../../../services/transaction/InscriptionService'
import { PAYMENT_URL_INSCRIPTION } from '../../../../constants/PaymentConstants'

interface PaymentInscriptionProps {
    transaction: Transaction
}

const PaymentInscription: FC<PaymentInscriptionProps> = ({
  transaction
}) => {
  const [infoPrice, setInfoPrice] = useState<Payment>()

  useEffect(() => {
    // Récupération des informations concernant le paiement
    InscriptionService.getPayment(transaction.id)
      .then((payment) => {
        return payment && setInfoPrice(payment)
      }
      )
  }, [transaction])

  return (
    <PaymentForm
      label='Demande initiale inscription'
      overviewPrices={<OverviewRoyalties infoPrice={infoPrice} />}
      object={transaction}
      urlType={PAYMENT_URL_INSCRIPTION}
      objectName={PAYMENT_OBJECT.TRANSACTION}
      title={<FormattedMessage id='payment' />}
      totalAmount={infoPrice?.totalAmount}
    />
  )
}

export default PaymentInscription
