import React, { FC, useEffect, useState } from 'react'
import {
  DATE_ISO,
  DateUtils,
  DEPOSIT_TYPE_DEPOT_AJOURNE,
  DEPOSIT_TYPE_DEPOT_SIMPLIFIE,
  Transaction,
  ModalComponent,
  ModelDeposit,
  STATUS_PUBLICATION_REQUESTED,
  SubmitButton
} from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'
import DepositService from '../../../../../services/transaction/DepositService'
import { toast } from 'react-toastify'
import Message from '../../../../../constants/Message'
import OverviewRoyalties from '../../../../payment/OverviewRoyalties'
import SelectPaymentBlocks from '../../../../payment/SelectPaymentBlocks'
import { Payment, PaymentParameters } from '../../../../../interfaces/DepositInterfaces'
import { PAYMENT_METHOD, PAYMENT_OBJECT } from '../../../../../constants/DepositConstants'
import PaymentService from '../../../../../services/transaction/PaymentService'
import { useHistory } from 'react-router'
import { REQUEST_STATUS_ACTION_REPRO } from '../../../../../constants/RequestConstants'
import ModalReproductionCompletion from './modals/ModalReproductionCompletion'
import NotificationService from '../../../../../services/transaction/NotificationService'
import {
  NOTIF_ANSWERED_TYPE_PUBLICATION_REPRODUCTION
} from '@inpi-dm/components/src/constants/NotificationsConstants'

interface PublishReproductionActionProps {
    transaction: Transaction,
    models: ModelDeposit[],
    className?: string,
    onUpdateDeposit?: () => any,
    onUpdateModels: (models: ModelDeposit[]) => void,
}

const PublishReproductionAction: FC<PublishReproductionActionProps> = ({
  transaction,
  models,
  className = '',
  onUpdateDeposit,
  onUpdateModels
}) => {
  const history = useHistory()
  const [showModalCompletion, setShowModalCompletion] = useState(false)
  const [showModalPayment, setShowModalPayment] = useState(false)
  const [payment, setPayment] = useState<Payment>({ lines: [], totalAmount: 0 })

  /**
     * Filtre des reproductions sélectionnées et acceptables pour une demande de publication
     *
     * Dans le cas d'un dossier ajourné, toutes les reproductions sans statut sont
     * acceptables pour une publication
     *
     * @param reproduction
     */
  const filterReproductionPublishable = transaction.file.depositType === DEPOSIT_TYPE_DEPOT_AJOURNE
    ? (reproduction) => !reproduction.state
    : (reproduction) => reproduction.selected && !reproduction.state

  /**
     * Retourne true si au moins une reproduction est publiable
     */
  const hasPublishableReproductions = () => {
    return models.length > 0 &&
            models.find(
              model => model.reproductions.find(filterReproductionPublishable)
            )
  }

  /**
     * Met à jour les taxes de demande de publication dans la modale
     */
  const updatePricePublication = () => {
    // Récupère les reproductions publiables
    const internalNames = models.reduce((prev, curr) => {
      const publishableReproductions = curr.reproductions.filter(filterReproductionPublishable)
      publishableReproductions.push(...prev)
      return publishableReproductions
    }, [])
      .map(repro => repro.file.internalName)

    DepositService.getReproductionsSimulatedPayment(transaction.id, internalNames)
      .then(setPayment)
  }

  useEffect(() => {
    if (showModalPayment && transaction.file.depositType === DEPOSIT_TYPE_DEPOT_SIMPLIFIE) {
      updatePricePublication()
    } else {
      setPayment()
    }
  }, [showModalPayment])

  /**
     * Met a jours les statuts des reproductions
     */
  const updateReproduction = async () => {
    const dateState = DateUtils.formateDateToFormat(new Date(), DATE_ISO)
    for (const model of models) {
      for (const reproduction of model.reproductions.filter(filterReproductionPublishable)) {
        await DepositService.updateReproduction(
          transaction.id,
          model.id,
          reproduction.file.internalName,
          reproduction.position,
          {
            ...reproduction,
            dateState,
            state: STATUS_PUBLICATION_REQUESTED
          }
        )
      }
    }
  }

  /**
     * Enregistre les reproductions à publier directement dans la transaction
     */
  const savePublishedReproductions = () => {
    return updateReproduction().then(() => {
      return NotificationService.postAnsweredNotification(transaction.id, NOTIF_ANSWERED_TYPE_PUBLICATION_REPRODUCTION).then(() => {
        toast.success(Message.publish_reproduction_success)
        setShowModalPayment(false)
        onUpdateDeposit && onUpdateDeposit()
      })
    })
  }

  /**
     * Enregistre une demande de paiement pour une demande de publication de reproduction
     * @param payment
     */
  const payPublicationReproductions = (payment: PaymentParameters) => {
    // On utilise les nom interne des reproductions pour les récupérées une fois le paiement effectué
    const internalNames = models.reduce((prev, curr) => {
      const publishableInternalNames = curr.reproductions
        .filter(filterReproductionPublishable)
        .map(repro => repro.file.internalName)
      publishableInternalNames.push(...prev)
      return publishableInternalNames
    }, [])

    const paramsPayment = {
      ...payment,
      label: 'Publication de reproductions',
      objectName: PAYMENT_OBJECT.PUBLICATION_REPRODUCTION,
      objectIds: internalNames
    }
    return PaymentService.createPayment(transaction.id, paramsPayment)
      .then(result => {
        if (payment.method === PAYMENT_METHOD.BLUE_CARD) {
          PaymentService.displayPayBox(result)
        } else {
          history.push(`/depots/${transaction.id}/paiement/confirmation`)
        }
      })
      .catch(() =>
        history.push(`/depots/${transaction.id}/paiement/erreur`)
      )
  }

  /**
     * Ouvre la modale de complétion de reproductions pour un dépôt simplifié
     * Ouvre la modale de paiement sinon
     */
  const handlePublishReproduction = () => {
    if (transaction.file.depositType === DEPOSIT_TYPE_DEPOT_SIMPLIFIE) {
      setShowModalCompletion(true)
    } else {
      setShowModalPayment(true)
    }
  }

  /**
     * Contenu de la fenêtre modale de confirmation
     */
  const modaleContent = () => {
    const listReproSelected = models?.map((model, indexModel) =>
      model.reproductions
        .map((repro, indexRepro) => (
          filterReproductionPublishable(repro) &&
            <li key={`${indexModel}.${indexRepro}`}>
              {`${indexModel + 1}.${indexRepro + 1} - `}
              <strong>{repro.label}</strong>
            </li>
        ))
        )

    return (
      <div>
        <p><FormattedMessage id='publish_reproduction_modal_content' /></p>
        <ul>
          {listReproSelected}
        </ul>
        {transaction.file.depositType === DEPOSIT_TYPE_DEPOT_SIMPLIFIE && (
          <>
            <OverviewRoyalties infoPrice={payment} className='mb-3' />
            <SelectPaymentBlocks
              onStartPayment={payPublicationReproductions}
              object={transaction.file}
            />
          </>
        )}
        {transaction.file.depositType === DEPOSIT_TYPE_DEPOT_AJOURNE && (
          <div className='d-flex justify-content-center mt-3'>
            <SubmitButton className='btn-primary' onClick={savePublishedReproductions}>
              <FormattedMessage id='publish_reproduction_button' />
            </SubmitButton>
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      {(
      // pour demander la publication de repros, il faut être en simplifié ou ajourné et avoir des repros publiables
        (transaction.file.depositType === DEPOSIT_TYPE_DEPOT_SIMPLIFIE || transaction.file.depositType === DEPOSIT_TYPE_DEPOT_AJOURNE) &&
                REQUEST_STATUS_ACTION_REPRO.includes(transaction.status || '')
      ) && (
        <div className={className}>
          <SubmitButton
            className='btn-block btn-primary'
            disabled={!hasPublishableReproductions()}
            onClick={handlePublishReproduction}
          >
            {transaction.file.depositType === DEPOSIT_TYPE_DEPOT_AJOURNE
              ? <FormattedMessage id='publish_reproduction_button_all' />
              : <FormattedMessage id='publish_reproduction_button_select' />}

          </SubmitButton>
        </div>
      )}
      <ModalComponent
        size={transaction.file.depositType === DEPOSIT_TYPE_DEPOT_SIMPLIFIE ? 'xl' : undefined}
        title={<FormattedMessage id='publish_reproduction_modal_title' />}
        show={showModalPayment}
        customContent={modaleContent}
        handleClose={() => setShowModalPayment(false)}
        hideFooter
      />
      <ModalReproductionCompletion
        idTransaction={transaction.id}
        initialsModels={models}
        onSubmit={(models) => {
          onUpdateModels(models)
          setShowModalCompletion(false)
          setShowModalPayment(true)
        }}
        show={showModalCompletion}
        handleClose={() => setShowModalCompletion(false)}
      />
    </>
  )
}

export default PublishReproductionAction
