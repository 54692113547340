import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux'
import {
  CheckboxField,
  ErrorField,
  EventType,
  INSCRIPTION_TYPE,
  INSCRIPTION_TYPES, STATUS_INSCRIPTION,
  TextField,
  Transaction
} from '@inpi-dm/components'
import { FormattedMessage } from 'react-intl'
import SelectTypeItem from '../../../selectTypeItem/SelectTypeItem'
import { storeInscriptionUpdate } from '../../../../store/inscription/inscriptionActions'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'
import qs from 'qs'
import TransactionService from '../../../../services/transaction/TransactionService'

const SelectInscriptionType = (props) => {
  const inscription = useSelector((state : RootStateOrAny) => state.inscription)
  const fieldStatus = useSelector((state : RootStateOrAny) => state.inscriptionFieldStatus)
  const dispatch = useDispatch()
  const [suiteTransactions, setSuiteTransactions] = useState()
  const [querySuiteId, setQuerySuiteId] = useState()
  const [previousTransactions, setPreviousTransactions] = useState<Transaction[]>()

  useEffect(() => {
    // Récupération de l'id du de la transaction d'origine dans le cas d'une suite
    const query = qs.parse(props.location.search, {
      ignoreQueryPrefix: true
    })
    setQuerySuiteId(query.suite)
    if (query.suite) {
      setSuiteTransactions([query.suite])
      TransactionService.getTransaction(query.suite).then(result => {
        const params = {
          suiteInscription: result.id,
          noStatus: [STATUS_INSCRIPTION.DRAFT, STATUS_INSCRIPTION.DRAFT_CLIENT],
          sortBy: 'createdAt',
          order: 'ASC'
        }
        TransactionService.searchTransaction(params).then(res => {
          setPreviousTransactions(res.transactions)
        })
      })
    }

    // Dans le cas d'une inscription déjà existante
    if (inscription.inscription?.inscriptionSuite?.position) {
      setSuiteTransactions(inscription.inscription.inscriptionSuite.transactionsIds)
    }
  }, [inscription.inscription])

  const handleChange = (inscription: Transaction) => {
    dispatch(storeInscriptionUpdate(inscription))
  }

  const handleChangePosition = (event: EventType) => {
    dispatch(storeInscriptionUpdate({
      inscription: {
        inscriptionSuite: {
          position: event.target.value,
          transactionsIds: suiteTransactions
        }
      }
    }))
  }

  return (
    <div className='select-type-depot'>
      <div className='row'>
        <header className='col-8 mb-4'>
          <h1><FormattedMessage id='select_inscription_type_title' /></h1>
          <span className='subtitle'><FormattedMessage id='select_inscription_type_description' /></span>
        </header>
        <InternalReferenceField
          value={inscription.internalReference}
          procedureType={INSCRIPTION_TYPE.value}
          className='col-4 mb-4'
        />
      </div>

      <div className='col-12 m-auto'>
        {INSCRIPTION_TYPES.map(type => (
          <div key={type.code} className='col-12 mb-3'>
            <SelectTypeItem
              type={type}
              value={inscription.subProcedureType}
              onClick={(value) => handleChange({ ...inscription, subProcedureType: value })}
              classNameText='p-0 m-0'
            />
          </div>
        ))}
        <div className='col-12'>
          <ErrorField message={fieldStatus.subProcedureType} className='fade alert alert-danger show position-relative mt-4' />
        </div>
      </div>
      <div className='row d-flex justify-content-center align-items-center mt-5'>
        <CheckboxField
          className='mx-auto'
          labelClassName='font-weight-bold'
          inputId='showPositionField'
          label={<FormattedMessage id='linked_inscription_message' />}
          checked={!!suiteTransactions}
          onChange={() => setSuiteTransactions(querySuiteId ? [querySuiteId] : (!suiteTransactions ? [] : undefined))}
        />
      </div>
      {suiteTransactions && (
        <div className='row d-flex justify-content-center align-items-center'>
          <div>
            <TextField
              label={<FormattedMessage id='position_inscription_message' />}
              inputId='position'
              value={inscription.inscription?.inscriptionSuite?.position || ''}
              onChange={handleChangePosition}
              fieldStatus={fieldStatus}
            />
            {previousTransactions && previousTransactions.length > 0 &&
              <div className='text-center'>
                <span className='large'><FormattedMessage id='previous_inscriptions_label' /></span>
                {
                  previousTransactions.map((transaction) =>
                    <div key={`previous-transaction-${transaction.id}`}>{transaction.inscription?.inscriptionSuite?.position} - {transaction.numNat}</div>
                  )
                }
              </div>}
          </div>
        </div>
      )}
    </div>
  )
}

export default withRouter(SelectInscriptionType)
