import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  BASIC_DATE,
  CardBlock,
  DateUtils,
  Preview,
  SubmitButton,
  Title
} from '@inpi-dm/components'
import DepositService from '../../../../services/transaction/DepositService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import TransactionService from '../../../../services/transaction/TransactionService'
import fileDownload from 'js-file-download'
import Message from '../../../../constants/Message'

interface OverviewProrogationSelectedTitleProps {
  transactionId: string
  title: Title,
  indexTitle: number
}

const OverviewProrogationSelectedTitle: FC<OverviewProrogationSelectedTitleProps> = ({ transactionId, title, indexTitle }) => {
  const showFirstReproduction = (title: Title) => {
    if (title.models[0].reproductions[0].thumbnail && title.models[0].reproductions[0].thumbnail.internalName) {
      return (
        <Preview
          className='m-auto img-recap'
          file={() => DepositService.getReproduction(transactionId, title.models[0].id, title.models[0].reproductions[0].thumbnail.internalName)}
        />
      )
    } else if (title.models[0].reproductions[0].url) {
      return (
        <img className='m-auto img-recap' src={title.models[0].reproductions[0].url} alt='' />
      )
    }
  }

  return (
    <CardBlock
      shadow
    >
      <div>
        <div className='mb-3'>
          <span className='font-weight-bold'><FormattedMessage id='record_prorogation' /> {title.numNat}</span> : <FormattedMessage id={title.isScopePartial ? 'partial_prorogation' : 'total_prorogation'} />
        </div>
        <div className='row'>
          <div className='col-9 row'>
            {title.models && title.models[0] && title.models[0].reproductions && title.models[0].reproductions[0] &&
          (
            <div className='col-2'>
              {showFirstReproduction(title)}
            </div>
          )}
            <div className='col-3'>
              <div title={Message.overview_deposit_date} className='text-gris text-truncate'>
                <FormattedMessage id='overview_deposit_date' />
              </div>
              <div className='w-100 form-group'>
                <div title={DateUtils.formateDateToFormat(title.depositDate, BASIC_DATE)} className='text-truncate'>
                  {DateUtils.formateDateToFormat(title.depositDate, BASIC_DATE)}
                </div>
              </div>
            </div>
            <div className='col-7'>
              <div title={Message.overview_deposit_publications} className='text-gris text-truncate'>
                <FormattedMessage id='overview_deposit_publications' />
              </div>
              <div className='w-100 form-group'>
                <div title={title.publications?.map(publication => publication.idPublication).join(', ')} className='text-truncate'>
                  {title.publications?.map(publication => publication.idPublication).join(', ')}
                </div>
              </div>
            </div>
          </div>
          <div className='col-3 mt-3'>
            <SubmitButton
              className='btn btn-link text-primary' onClick={() =>
                TransactionService.downloadTransactionPDF(transactionId, indexTitle).then(data => fileDownload(data, 'récapitulatif.pdf', 'pdf'))}
            >
              <FontAwesomeIcon icon={faDownload} className='mr-2' />
              <FormattedMessage id='records_prorogation_overview_download' />
            </SubmitButton>
          </div>
        </div>
      </div>
    </CardBlock>
  )
}

export default OverviewProrogationSelectedTitle
