import { Transaction } from '@inpi-dm/components'
import { ProrogationFieldStatus } from '../../interfaces/ProrogationInterfaces'

export const PROROGATION_UPDATE = 'PROROGATION_UPDATE'
export const PROROGATION_REMOVE = 'PROROGATION_REMOVE'
export const PROROGATION_FIELD_STATUS_UPDATE = 'PROROGATION_FIELD_STATUS_UPDATE'
export const PROROGATION_FIELD_STATUS_RESET = 'PROROGATION_FIELD_STATUS_RESET'

export interface ProrogationStoreAction {
  type: string,
  prorogation?: Transaction,
}

export interface ProrogationFieldStatusStoreAction {
  type: string,
  fieldStatus?: ProrogationFieldStatus,
}
