import { FieldStatus, messageIsFilled, OfficialDocumentRequest } from '@inpi-dm/components'
import Message from '../../../../../constants/Message'

class SelectTransactionFormValidator {
  /**
   * Valide la transaction
   * @param officialDocumentRequest
   */
  validateMandatory = (officialDocumentRequest : OfficialDocumentRequest) : FieldStatus => {
    let fieldStatus = {}
    fieldStatus = {
      arrivalDate: messageIsFilled(officialDocumentRequest.arrivalDate),
      copyNum: messageIsFilled(officialDocumentRequest.copyNum)
    }

    return {
      ...fieldStatus,
      officialDocumentType: messageIsFilled(officialDocumentRequest.officialDocumentType?.code),
      quantity: officialDocumentRequest.quantity && officialDocumentRequest.quantity > 0 ? '' : Message.records_official_document_service_quantity_error,
      notFound: ''
    }
  }
}

export default new SelectTransactionFormValidator()
