import Message from './Message'
import { INFORMATION_FROM_MODEL, INFORMATION_FROM_NUM_NAT, INFORMATION_FROM_PUBLICATION } from './InscriptionConstants'

export const RECORD_OFFICIAL_DOCUMENT_INFORMATION_FROM_SELECT = [{
  label: Message.records_inscription_information_from_num_nat,
  value: INFORMATION_FROM_NUM_NAT
}, {
  label: Message.records_inscription_information_from_publication,
  value: INFORMATION_FROM_PUBLICATION
}]

export const RECORD_OFFICIAL_DOCUMENT_INFORMATION_FROM_SELECT_WITH_MODEL = [{
  label: Message.records_inscription_information_from_num_nat,
  value: INFORMATION_FROM_NUM_NAT
}, {
  label: Message.records_inscription_information_from_model,
  value: INFORMATION_FROM_MODEL
}, {
  label: Message.records_inscription_information_from_publication,
  value: INFORMATION_FROM_PUBLICATION
}]

export const ASKED_DOCUMENTS_TABLE_TITLE = [
  {
    label: Message.official_document_type,
    className: 'w-36 text-left d-none d-lg-table-cell'
  },
  {
    label: Message.official_document_numnat,
    className: 'w-16 text-left d-none d-lg-table-cell'
  },
  {
    label: Message.official_document_model_number,
    className: 'w-13 text-left d-none d-lg-table-cell'
  },
  {
    label: Message.official_document_quantity,
    className: 'w-13 text-left d-none d-lg-table-cell'
  },
  {
    label: Message.official_document_format,
    className: 'w-15 text-left d-none d-lg-table-cell'
  },
  {
    className: 'w-7'
  }
]
