import React, { FC } from 'react'
import { Payment } from '../../interfaces/DepositInterfaces'
import { FormattedMessage } from 'react-intl'
import OverviewTableRow from './OverviewTableRow'
import { CardBlock } from '@inpi-dm/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

interface OverviewRoyaltiesProps {
  infoPrice?: Payment,
  className?: string,
  bodyClassName?: string,
  isPaymentLoading?: boolean
}

const OverviewRoyalties: FC<OverviewRoyaltiesProps> = ({
  infoPrice,
  className,
  bodyClassName,
  isPaymentLoading
}) => (
  <CardBlock
    className={className}
    header={<FormattedMessage id='overview_deposit_card_amount_title' />}
    bodyClassName={bodyClassName}
    shadow
  >
    {isPaymentLoading ? <FontAwesomeIcon className='loader mr-1' icon={faSpinner} />
      : (infoPrice && infoPrice.totalAmount !== 0
        ? (
          <div>
            <table className='table table-sm'>
              <thead>
                <tr className='d-flex'>
                  <th className='border-0 col-6'><FormattedMessage id='overview_deposit_service_title' /></th>
                  <th className='border-0 col'><FormattedMessage id='overview_deposit_rate_title' /></th>
                  <th className='border-0 col'><FormattedMessage id='overview_deposit_quantity_title' /></th>
                  <th className='border-0 col'><FormattedMessage id='overview_deposit_total_title' /></th>
                </tr>
              </thead>
              <tbody>
                {infoPrice.lines?.map ((row, index) => (
                  <OverviewTableRow key={index} label={row.label} amount={row.amount || row.price} quantity={row.quantity} />))}
              </tbody>
            </table>
            <h3>
              <FormattedMessage id='overview_deposit_amount_total' />
              <span className='ml-4'>{infoPrice.totalAmount ? (infoPrice.totalAmount / 100) : 0}€</span>
            </h3>
          </div>
        ) : <FormattedMessage id='payment_free' />)}
  </CardBlock>
)

export default OverviewRoyalties
