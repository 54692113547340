import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  EventType,
  SubmitButton,
  TextField,
  TitleInfos
} from '@inpi-dm/components'
import { faSearch, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TitleService from '../../../../services/transaction/TitleService'
import { ProrogationSearchTitle } from '../../../../interfaces/ProrogationInterfaces'

interface SelectOneRecordSearchProps {
    setShowFormManual: (isShowManual: boolean) => void,
    setResultSearch: (newResult: ProrogationSearchTitle) => void,
    setCurrentContributors: (contributors: any) => void,
    setIndexRecordSelected: (index:number)=>void
}

const SelectRecordsFormSearch: FC<SelectOneRecordSearchProps> = ({
  setShowFormManual,
  setResultSearch,
  setCurrentContributors,
  setIndexRecordSelected
}) => {
  const [byNumNat, setByNumNat] = useState()
  const [byIdPublication, setByIdPublication] = useState()

  /**
   * Récupération des informations des titres par numnat
   */
  const searchByNumNat = () => {
    return TitleService.getTitleInfos({ numNat: byNumNat, isForProrogation: true })
  }

  /**
   * Récupération des informations des titres par id reproduction
   */
  const searchByReproduction = () => {
    return TitleService.getTitleInfos({ idPublication: byIdPublication, allRecordRequested: true, isForProrogation: true })
  }

  const searchTitles = (name: string) => {
    let promise : Promise<TitleInfos|null> = Promise.resolve(null)
    switch (name) {
      case 'byNumNat':
        promise = searchByNumNat()
        break
      case 'byIdPublication':
        promise = searchByReproduction()
        break
      default:
        break
    }

    return promise.then((result: TitleInfos|null) => {
      if (result) {
        setResultSearch({ way: name, results: result?.records || [], publications: result?.publications, isProrogationAlreadyAsk: result?.isProrogationAlreadyAsk })
        if (result.records?.length === 1) {
          setIndexRecordSelected(0)
        }
        setCurrentContributors({
          agent: result?.agent,
          holders: result?.holders,
          recipient: result?.recipient
        })
      }
    })
  }

  /**
   * @param label
   * @param name
   * @param value
   * @param setter
   */
  const itemSearch = (label: string, name: string, value: string, setter) => (
    <div className='row m-0 d-flex justify-content-center'>
      <div className='col-12 col-md-4 d-flex justify-content-end text-gris mt-2'>
        <FormattedMessage id={label} />
      </div>
      <div className='col-10 col-md-3'>
        <TextField
          inputId={name}
          value={value}
          onChange={(event: EventType) => setter(event.target.value)}
        />
      </div>
      <div className='col-2 mt-2'>
        <SubmitButton
          className='btn-link-primary'
          onClick={() => searchTitles(name)}
        >
          <FontAwesomeIcon icon={faSearch} />
        </SubmitButton>

      </div>
    </div>
  )

  return (
    <>
      <hr />
      <div className='text-gris text-uppercase'>
        <FormattedMessage id='records_prorogation_search' />
      </div>
      <div className='d-flex flex-column justify-content-center'>
        {itemSearch(
          'records_prorogation_search_numNat',
          'byNumNat',
          byNumNat,
          setByNumNat
        )}
        <div className='d-flex justify-content-center mb-3 text-gris text-uppercase'>
          <FormattedMessage id='or' />
        </div>
        {itemSearch(
          'records_prorogation_search_idPublication',
          'byIdPublication',
          byIdPublication,
          setByIdPublication
        )}
      </div>
      <div className='d-flex justify-content-end'>
        <SubmitButton
          className='btn-link-primary'
          onClick={() => setShowFormManual(true)}
        >
          <FontAwesomeIcon icon={faArrowRight} />
          <span className='mr-3 ml-2'>
            <FormattedMessage id='records_prorogation_manual' />
          </span>
        </SubmitButton>
      </div>
    </>
  )
}

export default SelectRecordsFormSearch
