import { faExchangeAlt, faSearchPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  CardBlock,
  DateUtils,
  DepositDocument,
  DepositFile,
  DropArea,
  ModelDeposit,
  Preview,
  REPRODUCTION_STATE_OPTIONS,
  ReproductionLightDeposit,
  STATUS_DEPOSIT,
  SubmitButton,
  Transaction
} from '@inpi-dm/components'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { BLACK_AND_WHITE, COLORS } from '../../../../../constants/DepositConstants'
import PreviewAndTrashRectificatif from '../PreviewAndTrashRectificatif'
import DepositService from '../../../../../services/transaction/DepositService'
import TransactionService from '../../../../../services/transaction/TransactionService'
/* global File */
/* global FormData */

interface ReproductionOverviewProps {
    oneRepro: ReproductionLightDeposit,
    oneModel: ModelDeposit,
    indexModel: number,
    index: number,
    nbCurrent?: number,
    depositId?: string,
    transaction: Transaction,
    isEditable?: boolean,
    documents: DepositDocument[],
    fileToAdd: DepositDocument[],
    setfileToAdd: (fileToAdd: DepositDocument[] | ((prevFiles: DepositDocument[]) => DepositDocument[])) => void
    setShowReproModal?: (showReproModal: boolean) => void,
    setFileToShow?: React.Dispatch<React.SetStateAction<DepositDocument>>
    setReload?: () => void,
    deleteOneFile?: (elemToDelete: DepositDocument) => void,
    supportDocDelete?: boolean,
    getReproduction: (idDeposit: string, idModel: string, file: DepositFile) => Promise<string>,
    showPreview?: (model: ModelDeposit, repro: ReproductionLightDeposit) => void,
    hasExchangeRepro: (repro: ReproductionLightDeposit) => boolean | undefined
    hasExchangeReproToAdd: (repro: ReproductionLightDeposit) => boolean,
}

const ReproductionOverview: FC<ReproductionOverviewProps> = ({
  oneRepro,
  oneModel,
  indexModel,
  index,
  nbCurrent,
  depositId,
  transaction,
  isEditable,
  documents,
  fileToAdd,
  setfileToAdd,
  setShowReproModal,
  setFileToShow,
  setReload,
  deleteOneFile,
  supportDocDelete,
  getReproduction,
  showPreview,
  hasExchangeRepro,
  hasExchangeReproToAdd

}) => {
  const stateOption = REPRODUCTION_STATE_OPTIONS.find(state => state.value === oneRepro.state)

  return (
    <CardBlock
      className={`m-3 ${(stateOption?.borderClassName && !nbCurrent) || ''}`}
      bodyClassName='p-3'
      shadow
    >
      <div className='d-flex w-100 align-items-center'>
        <div className='image-with-thumbnail'>
          {
            depositId && oneRepro.thumbnail &&
              <Preview
                file={() => getReproduction(depositId, oneModel.id!, oneRepro.thumbnail)}
              />
          }
          {
            depositId && oneRepro.url &&
              <Preview
                file={oneRepro.url}
                className='image-with-thumbnail'
              />
          }
        </div>
        <div className={`${isEditable ? 'col-4' : 'col-8'} d-flex flex-column justify-content-center`}>
          <span className='font-weight-bold'>
            {`${indexModel + 1}.${index + 1} `}
            {oneRepro?.label || '--'}
          </span>
          <span className='font-weight-normal text-normalcase'>
            {oneRepro?.color === COLORS && <FormattedMessage id='reproductions_form_radio_colors' />}
            {oneRepro?.color === BLACK_AND_WHITE && <FormattedMessage id='reproductions_form_radio_no_colors' />}
            {!oneRepro?.color && '--'}
            {stateOption && ` - ${stateOption.label}`}
            {oneRepro.dateState && ` ${DateUtils.formatDateFr(oneRepro.dateState)}`}
          </span>
          <span className='font-weight-normal text-normalcase'>
            {oneRepro?.description}
          </span>
        </div>
        {!isEditable && transaction.status && transaction.status !== STATUS_DEPOSIT.DRAFT &&
                    oneRepro.preview &&
                      <div className='col-2 d-flex justify-content-center'>
                        <SubmitButton
                          className='btn-icon-primary'
                          onClick={() => showPreview && showPreview(oneModel, oneRepro)}
                        >
                          <FontAwesomeIcon icon={faSearchPlus} />
                        </SubmitButton>
                      </div>}
        {
          isEditable &&
                    !hasExchangeRepro(oneRepro) &&
                    !hasExchangeReproToAdd(oneRepro) &&
                      <div className='col-6 my-n4'>
                        <DropArea
                          icon={faExchangeAlt}
                          accept={process.env.REACT_APP_ACCEPTED_IMAGE_FORMAT}
                          maxSize={process.env.REACT_APP_ACCEPTED_IMAGE_SIZE}
                          onDrop={(file: File[]) => {
                            const theFile: File = file[0]
                            setfileToAdd((prevFiles: DepositDocument[]) => [
                              ...prevFiles,
                                    {
                                      srcName: (oneRepro.file as DepositFile).internalName,
                                      file: theFile
                                    } as DepositDocument
                            ])
                          }}
                        />
                      </div>
        }
        {
          isEditable &&
                    !hasExchangeRepro(oneRepro) &&
                    hasExchangeReproToAdd(oneRepro) &&
                      <div className='row align-items-center'>
                        <FontAwesomeIcon
                          icon={faExchangeAlt}
                          className='text-primary mr-5'
                        />
                        {
                          fileToAdd.filter(element => !(oneRepro.file instanceof File) && oneRepro.file && element.srcName === oneRepro.file.internalName)
                            .map((file: DepositDocument, index) => {
                              const formData = new FormData()
                              formData.append('file', file.file!)
                              return (
                                <PreviewAndTrashRectificatif
                                  key={index}
                                  loadFile={() => DepositService.resizeFile(formData)}
                                  onClick={() => {
                                    setShowReproModal && setShowReproModal(true)
                                    setFileToShow && setFileToShow(file)
                                  }}
                                  onTrashClick={() => file && deleteOneFile && deleteOneFile(file as DepositDocument)}
                                  refreshAction={supportDocDelete}
                                />
                              )
                            })
                        }
                      </div>
        }
        {
          isEditable &&
                    !hasExchangeReproToAdd(oneRepro) &&
                    hasExchangeRepro(oneRepro) &&
                      <div className='row align-items-center'>
                        <FontAwesomeIcon
                          icon={faExchangeAlt}
                          className='text-primary mr-5'
                        />
                        {
                          documents.filter((element: DepositDocument) => element.srcName === (oneRepro.file as DepositFile)?.internalName)
                            .map((file, index) =>
                              <PreviewAndTrashRectificatif
                                key={index}
                                loadFile={() => TransactionService.getDocumentFile(depositId!, file.internalName, true)}
                                onClick={() => {
                                  setShowReproModal && setShowReproModal(true)
                                  setFileToShow && setFileToShow(file)
                                }}
                                onTrashClick={() => {
                                  TransactionService.deleteDocumentFile(depositId!, file.internalName)
                                  deleteOneFile && deleteOneFile(file)
                                  setReload && setReload()
                                }}
                                refreshAction={supportDocDelete}
                              />
                            )
                        }
                      </div>
        }
      </div>
    </CardBlock>
  )
}

export default ReproductionOverview
