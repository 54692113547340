import store from '../../../../../store/store'
import { containsErrors, FieldStatus } from '@inpi-dm/components'
import Message from '../../../../../constants/Message'
import { AppealFieldStatus } from '../../../../../interfaces/AppealInterfaces'
import { storeAppealFieldStatusUpdate } from 'store/appeal/appealActions'

class OverviewFormAppealValidator {
    validateMandatory = (similarInformation: boolean): FieldStatus => {
      return {
        overviewSimilarInformation: similarInformation ? '' : Message.required_checkbox_field
      }
    }

    validateOnChangeStep = () => {
      const transaction = store.getState().appeal

      const fieldStatus : AppealFieldStatus = this.validateMandatory(transaction.overviewSimilarInformation || false)
      store.dispatch(storeAppealFieldStatusUpdate({
        overviewSimilarInformation: fieldStatus
      }))

      return !containsErrors(fieldStatus)
    }
}

export default new OverviewFormAppealValidator()
